.tools_sec
{
    padding: 60px 0px;
    background-color: #171717;


    .main_heading
    {
        font-size: 32px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
    }

    .desc
    {
        color: #fff;
        text-align: center;
    }
    
    .pt_50
    {
        padding-top: 50px;
    }

    .card
    {

margin-bottom: 25px;
background-color: #fff;
padding: 35px 25px;
min-height: 200px;
transition: all 0.4s ease;
transform: translateY(0px);

        .image
        {
            position: relative;
            width:67px;
            height: 67px;
            margin: 0 auto;
margin-bottom: 15px;

img
{
    width: 100%;
    height: 100%;
}
            
        }

        h5
        {
           
            color: #171717;
            text-align: center;
            font-weight: 400;
            font-size: 20px;
            letter-spacing: 0.5px;
            padding-top: 25px;
        }

        &:hover
        {
            transition: all 0.4s ease;
            transform: translateY(-15px);
        }
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .tools_sec
    {
        padding: 20px 0px;

        .main_heading
        {
            font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
        }

        .desc
        {
            font-size: 13px;
        }

        .pt_50
        {
            padding-top: 20px;
        }

        .card {
            margin-bottom: 10px;
            padding: 15px 10px;
            min-height: 155px;

            h5
            {
                font-weight: 500;
    font-size: 18px;
    padding-top: 10px;
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .tools_sec
    {
        padding: 30px 0px;

        .main_heading
        {
            font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
        }

        .desc
        {
            font-size: 13px;
        }

        .pt_50
        {
            padding-top: 30px;
        }

        .card {
            margin-bottom: 15px;
            padding: 15px 10px;
            min-height: 155px;

            h5
            {
                font-weight: 500;
    font-size: 18px;
    padding-top: 10px;
            }
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .tools_sec
    {
        padding: 30px 0px;

        .main_heading
        {
            font-size: 28px;
    font-weight: 500;
    margin-bottom: 15px;
        }

     

        .pt_50
        {
            padding-top: 35px;
        }

        .card {
            margin-bottom: 15px;
            padding: 15px 5px;
            min-height: 155px;

            h5
            {
                font-weight: 500;
    font-size: 16px;
    padding-top: 10px;
            }
        }
    }
}


@media only screen and (min-width:1200px) and (max-width:1399px) {
    .tools_sec
    {
        padding: 40px 0px;

        .main_heading
        {
            font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;
        }

     

        .pt_50
        {
            padding-top: 35px;
        }

        .card {
            padding: 25px 10px;
            min-height: 185px;
            margin-bottom: 0px;

            h5
            {
                font-weight: 500;
    font-size: 16px;
    padding-top: 15px;
            }
        }
    }
}