

  .main_banner_sec
  {
 padding: 40px 0px;
    background: linear-gradient(0deg, hsl(45deg 100% 51.37% / 22%), #cdcdcd30);

    .pt_40
    {
       
        justify-content: space-between;
    }

    .content
    {
// margin-top: 4%;
        h1
        {
            font-size: 62px;
    font-weight: 600;
    margin-bottom: 25px;
    color: #212529 !important;
    height: 148px;

    span
    {
        color: #fcaf17;
    }
        }

        .desc
        {
            color: #212529;
            font-weight: 500;
            font-size: 24px;
        }

        .list
        {
          display: flex;
          align-items: center;
          gap:45px;
          margin-top: 25px;
          margin-bottom: 25px;

          li
          {
            h5
            {
              font-size: 28px;
              font-weight: 700;
              margin-bottom: 10px;
            }

            p
            {
              font-weight: 600;
              font-size: 20px;
            }
          }
        }

        .buttn_holder
        {
            margin-top: 45px;

            .enquiry_buttn {
                background-color: #fcaf18;
                padding: 18px 54px;
                border: 1px solid #fcaf17;
                color: #fff;
                font-weight: 600;
                border-radius: 40px;
                cursor: pointer;
                font-size: 20px;
    
                span {
                    background: #fff;
                    color: #fcaf17;
                    width: 40px;
                    height: 40px;
                    display: inline-block;
                    border-radius: 50%;
                    line-height: 35px;
                    font-size: 18px;
                    margin-left: 1%;
                    text-align: center;
                    border: 1px solid #fff;
                  }
    
                &:hover {
                    color: #fcaf17 !important;
                    background-color: #fff !important;
                    border: 1px solid #fcaf17;
                    span
                    {
                        background-color: transparent;
                        border-color: #fcaf17;
                    }
                  }
                  
              }
        }

       
        
    }


    .top_contact_form
    {
        padding: 25px 25px 20px 25px;
        z-index: 99;
        background: #fff 0 0 no-repeat padding-box;
        box-shadow: 0 0 2px 0 rgb(145 158 171/20%),
          0 12px 24px -4px rgb(145 158 171/12%);
        border-radius: 24px;
        width: 100%;
      margin-top: 0%;

      .form_head {
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 15px;
      }

      .form_body
      {
      
       
          
         .desc {
            font-size: 14px;
            font-weight: 600;
          }

          .ip_box
          {
            margin-bottom: 20px;


            input {
                width: 100%;
                font-size: 14px;
                padding: 13px 15px;
              }

             

              textarea {
                padding:15px;
                font-size: 15px;
                background: #fff 0 0 no-repeat padding-box !important;
                border-radius: 6px !important;
                
               

                &:focus {
                    outline: none !important;
                    box-shadow: none !important;
                    border-color: #fcaf17;

                  }
              }
              
           

          }
          .select_box
          {
            margin-bottom: 20px;


            select {
                width: 100%;
                font-size: 14px;
                height: 55px;
              }

              

          }
          
      
        
         
      }
    }


  }

  @media only screen and (min-width:320px) and (max-width:767px) {
    .main_banner_sec
    {
      padding: 20px 0px;
      .content
      {
        height: 277px;
        h1
        {
          font-size: 22px;
    margin-bottom: 15px;
    height: 48px;
        }

        .desc
        {
          font-size: 14px;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }

        .list
        {
          display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 0px;

    li
    {
      h5
      {
        font-size: 20px;
      }

      p
      {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
        }

        .buttn_holder
        {
          margin-top: 25px;
          .enquiry_buttn
          {
            padding: 10px 10px;
            font-size: 16px;
          }
        }
      }

      .top_contact_form
      {
        padding: 15px 15px 20px 15px;
    border-radius: 10px;
    margin-top: 7%;
    height: 540px;

    .form_head
    {
      font-size: 18px;
    }

    .form_body
    {
      .ip_box
      {
        margin-bottom: 15px;

        input
        {
          padding: 10px 15px;
        }
      }
    }
      }
    }
  }

  @media only screen and (min-width:768px) and (max-width:991px) {
    .main_banner_sec
    {
      padding: 30px 0px;
      .content
      {
        
        h1
        {
          font-size: 26px;
    margin-bottom: 15px;
    height: 50px;
        }

        .desc
        {
          font-size: 16px;
          line-height: 25px;
        }

        .list
        {

    gap: 10px;
    margin-top: 20px;
    margin-bottom: 0px;

    li
    {
      h5
      {
        font-size: 22px;
      }

      p
      {
        
        font-size: 16px;
        line-height: 25px;
      }
    }
        }

        .buttn_holder
        {
          .enquiry_buttn
          {
            padding: 10px 10px;
            font-size: 16px;
          }
        }
      }

      .top_contact_form
      {
        padding: 15px 15px 20px 15px;
    border-radius: 10px;

    .form_head
    {
      font-size: 18px;
    }

    .form_body
    {
      .ip_box
      {
        margin-bottom: 15px;

        input
        {
          padding: 10px 15px;
        }
      }
    }
      }
    }
  }

  @media only screen and (min-width:820px) and (max-width:991px) {
    .main_banner_sec
    {
      
      .content
      {
        margin-top: 5%;
        
        h1
        {
          font-size: 32px;
    margin-bottom: 20px;
        }

        .buttn_holder
        {
          .enquiry_buttn
          {
            padding: 14px 24px;
            font-size: 17px;
          }
        }

      }
    }
  }

  @media only screen and (min-width:992px) and (max-width:1199px) {
    .main_banner_sec
    {
      padding: 30px 0px;
      .content
      {
        margin-top: 5%;
        h1
        {
          font-size: 36px;
    margin-bottom: 20px;
    height: 60px;
        }

        .desc
        {
          font-size: 18px;
          line-height: 30px;
        }

        .list
        {

    gap: 20px;
    margin-top: 20px;
    margin-bottom: 0px;

    li
    {
      h5
      {
        font-size: 24px;
      }

      p
      {
        
        font-size: 16px;
        line-height: 25px;
      }
    }
        }

        .buttn_holder
        {
          .enquiry_buttn
          {
            padding: 15px 24px;
            font-size: 16px;
          }
        }
      }

      .top_contact_form
      {
        padding: 15px 15px 20px 15px;
    border-radius: 10px;

    .form_head
    {
      font-size: 18px;
    }

    .form_body
    {
      .ip_box
      {
        margin-bottom: 15px;

        input
        {
          padding: 10px 15px;
        }
      }
    }
      }
    }
  }

  @media only screen and (min-width:1200px) and (max-width:1440px) {
    .main_banner_sec
    {
      padding: 30px 0px;
      .content
      {
        margin-top: 5%;
        h1
        {
          font-size: 40px;
    margin-bottom: 20px;
    height: 100px;
        }

        .desc
        {
          font-size: 18px;
          line-height: 30px;
        }

        .list
        {

    gap: 30px;
    margin-top: 25px;
    margin-bottom: 0px;

    li
    {
      h5
      {
        font-size: 26px;
      }

      p
      {
        
        font-size: 17px;
        line-height: 25px;
      }
    }
        }

        .buttn_holder
        {
          .enquiry_buttn
          {
            padding: 15px 24px;
            font-size: 16px;
          }
        }
      }

      .top_contact_form
      {
        padding: 15px 15px 20px 15px;
    border-radius: 10px;

    .form_head
    {
      font-size: 18px;
    }

    .form_body
    {
      .ip_box
      {
        margin-bottom: 15px;

        input
        {
          padding: 10px 15px;
        }
      }
    }
      }
    }
  }