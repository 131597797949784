.digital_banner
{
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 550px;
    background-image: url("../../../../assets/images/ui-ux/banner/mainbanner.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

   

      .content {
        position: absolute;
        z-index: 2;
        top: 40%;
        width: 35%;
        right: 5%;

        .main_heading {
            color: white;
            font-size: 30px;
            margin-bottom: 15px;

            span {
                color: #fcaf17;
              }
          }

      

          .desc
          {
            color: #fff;
            font-size: 18px;
            margin-bottom: 25px;
          }
          .transform_buttn {
            color: white !important;
            background-color: transparent;
            border: 1px solid #fcaf17;
            padding: 14px 34px;
            transition: all 0.4s ease-in-out;
            font-weight: 600;
            overflow: hidden;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                background-color: #fcaf17;
                color: white;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
                transform-origin: 0 50%;
                transition-property: transform;
                transform: scaleX(0);
                transition: all 0.4s ease-in-out;
              }

              &:hover
              {
                &::before {
                    border: 1px solid transparent;
                    color: white;
                    transition: all 0.4s ease-in-out;
                    transform: scaleX(1);
                  } 
              }
          }
      }
} 


@media only screen and (min-width:320px) and (max-width:767px) {
  .digital_banner
    {
        min-height: 350px!important;
        .content {
            position: absolute;
            z-index: 2;
            top: 28%;
            width: 100%;
            right: 0%;

            .main_heading {
                font-size: 15px;
                margin-bottom: 5px;
                font-weight: 500;
              }
              .desc {
                margin-bottom: 15px;
                font-size: 12px;
                line-height: 20px;
              }
              .transform_buttn
              {
                padding: 8px 10px;
                font-size: 14px;
                font-weight: 500;
              }
          }
    }
    
    
    

  }



  @media only screen and (min-width:768px) and (max-width:1024px) {
  .digital_banner
    {
        min-height: 450px!important;
        .content {
           
            position: absolute;
            z-index: 2;
            top: 40%;
            width: 65%;
            right: 2%;
        
            

            .main_heading {
                font-size: 20px;
                margin-bottom: 10px;
                font-weight: 500;
              }
              .desc {
                margin-bottom: 20px;
                font-size: 15px;
                line-height: 25px;
              }
              .transform_button
              {
                padding: 8px 12px;
                font-size: 16px;
              }
          }
    }
    
    
    

  }

 

  @media only screen and (min-width:970px) and (max-width:1199px) {
  .digital_banner
    {
        .content {
           
            position: absolute;
            z-index: 2;
            top: 35%;
            width: 55%;
            right: 3%;
            
          }
    }
    
    
    

  }


  
  
  @media only screen and (min-width:1200px) and (max-width:1880px) {
  .digital_banner
    {
        .content {
           
            position: absolute;
            z-index: 2;
            top: 30%;
            width: 50%;
            right: 3%;

                .main_heading
                {
                    font-size: 26px;
                    margin-bottom: 15px;
                }
            
          }
    }
    
    
    

  }
 



 


