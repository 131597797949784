@charset "UTF-8";
@import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../../node_modules/swiper/swiper-bundle.css';
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
.modalpop.borchure .modal-body {
  padding: 0;
}

.homebannersectoin {
  background: rgb(255, 240, 208);
  background: linear-gradient(74deg, rgb(255, 240, 208) 0%, rgb(252, 253, 248) 20%, rgb(254, 246, 231) 45%);
  padding: 30px 4pc;
}
.homebannersectoin h1 {
  font-size: 43px;
  font-weight: 700;
  padding: 12px 0;
}
.homebannersectoin h2 {
  color: #f8b418;
  font-size: 36px;
}
.homebannersectoin p {
  font-size: 21px;
  width: 100%;
  color: #000;
  font-weight: 600;
  margin: 30px 0;
}
.homebannersectoin h6 {
  font-size: 22px;
  font-weight: 700;
  color: #000;
  margin: 15px 0;
}
.homebannersectoin ul {
  display: flex;
  gap: 10px;
}
.homebannersectoin .bannerheading .certificate_img_list li img {
  width: 120px;
  height: 120px;
}
.homebannersectoin .home_banner_img {
  width: 500px;
  height: 500px;
  margin: 0 auto;
}
.homebannersectoin .home_banner_img img {
  width: 100%;
  height: 100%;
}

.img_heade_menu {
  width: 100%;
  height: 490px;
}
.img_heade_menu img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.navbar_top.navbar {
  padding: 10px 15px;
  margin: 0;
  position: relative;
}
.navbar_top.navbar .dropdown-wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 2px solid #fcaf17;
  padding: 20px 0;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  font-family: "Poppins", sans-serif;
}
.navbar_top.navbar .dropdown-wrapper .left_sidebar {
  padding: 0px 46px;
}
.navbar_top.navbar .dropdown-wrapper .left_sidebar h3 {
  font-size: 23px;
  color: #fcaf17;
  margin-bottom: 30px;
}
.navbar_top.navbar .dropdown-wrapper .left_sidebar li {
  margin-bottom: 20px;
}
.navbar_top.navbar .dropdown-wrapper .left_sidebar li a {
  display: flex;
  gap: 15px;
  align-items: center;
}
.navbar_top.navbar .dropdown-wrapper .left_sidebar li .iconimg {
  display: block;
  width: 45px;
  height: 45px;
  background-color: rgba(255, 248, 234, 0.1490196078);
  border-radius: 100px;
  text-align: center;
}
.navbar_top.navbar .dropdown-wrapper .left_sidebar li span {
  color: #000;
  font-size: 17px;
  position: relative;
}
.navbar_top.navbar .dropdown-wrapper .left_sidebar li span::after {
  background: #fcaf17;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  width: 0;
}
.navbar_top.navbar .dropdown-wrapper .left_sidebar li span:hover::after {
  width: 100%;
}
.navbar_top.navbar .dropdown-wrapper .left_sidebar img {
  width: 22px;
  height: 22px;
}

.navbar_top.navbar .nav-item.mega-menu:hover .dropdown-wrapper {
  opacity: 1;
  visibility: visible;
}

.dropdown-wrapper1 {
  position: absolute;
  left: 0;
  right: 0;
  width: 75%;
  top: 80px;
  margin: 0 auto;
  background: #fff;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 2px solid #fcaf17;
  padding: 20px 0;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
.dropdown-wrapper1 .left_sidebar {
  padding: 20px 25px;
}
.dropdown-wrapper1 .left_sidebar .mega_menu_list1 {
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 15px;
  margin-top: 15px;
}
.dropdown-wrapper1 .left_sidebar .mega_menu_list1 li {
  margin-bottom: 10px;
}
.dropdown-wrapper1 .left_sidebar .mega_menu_list1 li a {
  display: inline;
}
.dropdown-wrapper1 .left_sidebar .mega_menu_list1 li .iconimg1 {
  display: block;
  width: 35px;
  height: 35px;
  background-color: rgba(255, 248, 234, 0.1490196078);
  border-radius: 100px;
  text-align: center;
}
.dropdown-wrapper1 .left_sidebar .mega_menu_list1 li .iconimg1 img {
  width: 100%;
  height: 100%;
}
.dropdown-wrapper1 .left_sidebar .mega_menu_list1 li span {
  color: #000;
  font-size: 18px;
  position: relative;
  padding-bottom: 5px;
}
.dropdown-wrapper1 .left_sidebar .mega_menu_list1 li span::after {
  background: #fcaf17;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  width: 0;
}
.dropdown-wrapper1 .left_sidebar .mega_menu_list1 li span:hover::after {
  width: 100%;
}
.dropdown-wrapper1 .left_sidebar .mega_menu_heading {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
}
.dropdown-wrapper1 .left_sidebar h3 {
  font-size: 23px;
  color: #fcaf17;
  margin-bottom: 30px;
}
.dropdown-wrapper1 .left_sidebar li {
  margin-bottom: 20px;
}
.dropdown-wrapper1 .left_sidebar li a {
  display: flex;
  gap: 15px;
  align-items: center;
}
.dropdown-wrapper1 .left_sidebar li .iconimg {
  display: block;
  width: 35px;
  height: 35px;
  background-color: rgba(255, 248, 234, 0.1490196078);
  border-radius: 100px;
  text-align: center;
}
.dropdown-wrapper1 .left_sidebar li .iconimg img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.dropdown-wrapper1 .left_sidebar li span {
  color: #000;
  font-size: 17px;
  position: relative;
}
.dropdown-wrapper1 .left_sidebar li span::after {
  background: #fcaf17;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  width: 0;
}
.dropdown-wrapper1 .left_sidebar li span:hover::after {
  width: 100%;
}
.dropdown-wrapper1 .left_sidebar img {
  width: 32px;
  height: 32px;
}

.visiblhide {
  visibility: hidden;
  z-index: -20;
  opacity: 0;
}

.visiblshow {
  visibility: visible;
  z-index: 99999999999;
  opacity: 1;
}

.navigation_nav.offcanvas-body {
  display: unset !important;
}

.topbar {
  background-color: #092e40;
  padding: 10px 0;
}
.topbar .dowload_brochure {
  background-color: #fcaf17;
  padding: 3px 10px;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  border-radius: 2px;
  animation: pulse-animation 2s infinite;
  transition: all 0.5s;
  box-shadow: 0 0 0 rgb(252, 175, 23);
}
.topbar .dowload_brochure:hover {
  color: #fff !important;
}
.topbar .container-fluid {
  padding: 0px 1pc;
}
.topbar .gap10 {
  gap: 15px;
}
.topbar-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.topbar-inner .icon {
  color: #fff;
}
.topbar-inner p {
  line-height: normal;
}
.topbar-inner p a:hover {
  color: #fcaf17 !important;
}

.social-links {
  display: flex;
  align-items: center;
  gap: 15px;
}
.social-links a:hover {
  color: #fcaf17 !important;
}

.bootom-bar {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}
.bootom-bar .navbar-nav {
  justify-content: flex-end;
  gap: 10px;
}
.bootom-bar .navbar-nav .nav-link {
  font-size: 16px;
  text-transform: uppercase;
  color: #020202;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  padding: 18px 12px;
  position: relative;
}
.bootom-bar .navbar-nav .nav-link::after {
  background: #fcaf17;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  width: 0;
}
.bootom-bar .navbar-nav .nav-link:hover::after {
  width: 100%;
}

.top-banner {
  position: relative;
}
.top-banner:before, .top-banner:after {
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
}
.top-banner:before {
  background-image: url("../images/decor/patter-2.png");
  background-position: left center;
  background-size: 20%;
  left: 0;
  top: 0px;
}
.top-banner:after {
  background-image: url("../images/decor/patter-1.png");
  background-position: right center;
  background-size: 50%;
  right: 0;
  top: 0px;
}
.top-banner .main_headding {
  color: #fff !important;
}
.top-banner .desccc {
  color: #fff !important;
  margin-bottom: 30px;
}
.top-banner .contact_buttnn {
  background-color: #fcaf17;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #fcaf17;
  padding: 12px 36px;
}
.top-banner .contact_buttnn:hover {
  background-color: transparent;
  color: #fff !important;
  border-color: #fff;
  transition: all 0.4s ease;
}
.top-banner .container {
  position: relative;
  z-index: 4;
}
.top-banner .title-section {
  font-size: 20px;
  line-height: 40px;
}
.top-banner .box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 2pc 0 4pc;
}
.top-banner .box .left {
  background: #e7e9ff;
  color: #838ae0;
}
.top-banner .box .right {
  background: #fff7df;
  color: #8c8265;
}
.top-banner .box .mid {
  background: #ffefe8;
  color: #ec7c47;
}
.top-banner .box .inner-box {
  border-radius: 14px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  align-content: center;
  flex-direction: row;
  text-align: left;
  border: 1px solid #fff;
}
.top-banner .box .inner-box .icon {
  font-size: 35px;
}
.top-banner .box .inner-box p {
  font-size: 16px;
  line-height: 24px;
}

.contact_banner {
  position: relative;
  width: 100%;
  height: 600px;
  background-image: url("../images/decor/contactbanner.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.contact_banner .contentn {
  padding-top: 10%;
}
.contact_banner .text_container {
  min-height: 60px !important;
}
.contact_banner .mb_40 {
  margin-bottom: 40px;
}
.contact_banner .main_headding {
  color: #fff !important;
  margin-bottom: 15px !important;
  font-weight: 600 !important;
  text-shadow: none !important;
}
.contact_banner .desccc {
  color: #fff !important;
  margin-bottom: 30px;
}
.contact_banner .contact_buttnn {
  background-color: #fcaf17;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #fcaf17;
  padding: 12px 36px;
}
.contact_banner .contact_buttnn:hover {
  background-color: transparent;
  color: #fff !important;
  border-color: #fff;
  transition: all 0.4s ease;
}
.contact_banner .container {
  position: relative;
  z-index: 4;
}
.contact_banner .title-section {
  font-size: 20px;
  line-height: 40px;
}
.contact_banner .box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 2pc 0 4pc;
}
.contact_banner .box .left {
  background: #e7e9ff;
  color: #838ae0;
}
.contact_banner .box .right {
  background: #fff7df;
  color: #8c8265;
}
.contact_banner .box .mid {
  background: #ffefe8;
  color: #ec7c47;
}
.contact_banner .box .inner-box {
  border-radius: 14px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  align-content: center;
  flex-direction: row;
  text-align: left;
  border: 1px solid #fff;
}
.contact_banner .box .inner-box .icon {
  font-size: 35px;
}
.contact_banner .box .inner-box p {
  font-size: 16px;
  line-height: 24px;
}

@media only screen and (max-width: 991px) {
  .contact_banner {
    height: 350px;
  }
  .contact_banner .main_headding {
    color: #fff !important;
    margin-bottom: 0px !important;
    font-weight: 600;
    font-size: 26px !important;
  }
  .contact_banner .text_container {
    min-height: 50px !important;
  }
  .contact_banner .desccc {
    color: #fff !important;
    margin-bottom: 20px;
    font-size: 15px;
  }
  .contact_banner .contact_buttnn {
    padding: 8px 16px;
    font-size: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact_banner {
    height: 400px;
  }
  .contact_banner .main_headding {
    color: #fff !important;
    margin-bottom: 0px !important;
    font-weight: 600;
    font-size: 30px !important;
  }
  .contact_banner .text_container {
    min-height: 50px !important;
  }
  .contact_banner .desccc {
    color: #fff !important;
    margin-bottom: 20px;
    font-size: 15px;
  }
  .contact_banner .contact_buttnn {
    padding: 8px 16px;
    font-size: 15px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contact_banner {
    height: 550px;
  }
  .contact_banner .main_headding {
    color: #fff !important;
    margin-bottom: 10px !important;
    font-weight: 600;
    font-size: 36px !important;
  }
  .contact_banner .text_container {
    min-height: 50px !important;
  }
}
.client-swiper .swiper-slide {
  text-align: center;
}
.client-swiper img {
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
  height: 100px;
}

.services-swiper .swiper-slide:nth-child(4n+1) .box {
  background: #fff2e2;
}
.services-swiper .swiper-slide:nth-child(4n+2) .box {
  background: #feeae3;
}
.services-swiper .swiper-slide:nth-child(4n+3) .box {
  background: #c8ebff;
}
.services-swiper .swiper-slide:nth-child(4n+4) .box {
  background: #eee0f7;
}
.services-swiper .box {
  background: #fff;
  position: relative;
  border-radius: 14px;
  padding: 40px 30px;
  box-shadow: 0 10px 15px -2px rgba(82, 0, 57, 0.0784313725);
}
.services-swiper .box h4 {
  font-weight: 700;
  margin-bottom: 1pc;
}
.services-swiper .box p {
  font-size: 18px;
  margin-bottom: 0;
}
.services-swiper .box .content {
  margin-bottom: 1pc;
}
.services-swiper .box .image {
  text-align: center;
}
.services-swiper .box .image img {
  max-height: 270px;
  -o-object-fit: contain;
     object-fit: contain;
}
.services-swiper ul.tabs {
  background: #1f2732;
  padding: 1pc;
}
.services-swiper ul.tabs li {
  padding: 15px 2pc;
  position: relative;
  cursor: pointer;
  font-size: 19px;
  font-weight: 500;
  transition: all 0.5s;
}
.services-swiper ul.tabs li:before {
  position: absolute;
  height: 100%;
  width: calc(100% + 3pc);
  top: 0;
  right: -3pc;
  background: #fcaf17;
  z-index: 1;
  transition: all 0.5s;
}
.services-swiper ul.tabs li span {
  position: relative;
  z-index: 4;
  color: #f1f1f1;
  font-weight: 400;
  font-size: 17px;
}
.services-swiper ul.tabs li.active {
  color: #fff;
  transition: all 0.5s;
}
.services-swiper ul.tabs li.active:before {
  content: "";
  transition: all 0.5s;
}
.services-swiper .tab-description {
  padding: 0 2pc;
}
.services-swiper .tab-description h6 {
  font-size: 19px;
  line-height: 30px;
}
.services-swiper .tab-description p:not(:last-child) {
  margin-bottom: 5px;
}
.services-swiper .tab-description ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 5px;
  margin-bottom: 30px;
}
.services-swiper .tab-description ul li {
  list-style-type: square;
  list-style-position: inside;
}
.services-swiper .tab-description ul li::marker {
  color: #fcaf17;
}

.tab-inner a {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  position: relative;
}
.tab-inner a::after {
  background: #fcaf17;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  width: 0;
}
.tab-inner a:hover::after {
  width: 100%;
}

.how-it-work ul.box {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.how-it-work ul.box li {
  display: flex;
  gap: 20px;
}
.how-it-work ul.box li .content {
  flex: 1;
}
.how-it-work ul.box li .content h4 {
  margin-bottom: 0;
}
.how-it-work ul.box li .icon {
  height: 45px;
  width: 45px;
  background: #5b5b5b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0 0 0px 5px rgba(53, 52, 49, 0.1098039216);
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.testimonials .box {
  background-color: #fafafa;
  height: 100%;
  padding: 70px;
  position: relative;
}
.testimonials .box .quote {
  font-size: 35px;
  opacity: 0.3;
}
.testimonials .box p.desp {
  font-size: 14px;
  color: #000;
  line-height: 1.9;
  margin: 1pc 0;
  text-align: justify;
}
.testimonials .box .user {
  display: flex;
  align-items: center;
  gap: 10px;
}
.testimonials .box .user img {
  height: 60px;
  width: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.testimonials .box .user h5 {
  margin-bottom: 0;
}
.testimonials .box .user p {
  font-weight: 500;
  font-size: 15px;
}

.technology-used {
  background-color: #fafafa;
}
.technology-used .heading_box {
  text-align: center;
  padding: 40px 0px;
}
.technology-used .heading_box .main_heading {
  font-size: 36px;
  color: #020202;
  margin-bottom: 15px;
  font-weight: 600;
}
.technology-used .heading_box .main_heading span {
  color: #fcaf17;
}
.technology-used ul .col-auto {
  width: 16.6%;
}
.technology-used ul .box {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 5px 50px rgba(13, 21, 55, 0.0588235294);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  min-height: 131px;
}
.technology-used ul .box img {
  width: auto;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-bottom: 1pc;
}
.technology-used ul .box:hover {
  box-shadow: 0 20px 20px 0 rgba(2, 19, 79, 0.1019607843);
  transform: translateY(-2px);
}

.industry-served {
  background-color: #1f2732;
  background-image: url("../images/decor/texture_dark.jpg");
  background-attachment: fixed;
  background-size: cover;
}
.industry-served ul .box {
  background-color: #fafafa;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 5px 50px rgba(13, 21, 55, 0.0588235294);
  transition: all 0.3s ease;
}
.industry-served ul .box:hover {
  transform: scale(0.98);
}
.industry-served ul .box img {
  width: auto;
  height: 110px;
  -o-object-fit: contain;
     object-fit: contain;
}
.industry-served ul .box h6 {
  margin-bottom: 0;
}

.benefits .box {
  background: #fff;
  position: relative;
  place-items: start;
  display: grid;
  border-radius: 14px;
  padding: 20px 30px 40px 30px;
  box-shadow: 0 10px 15px -2px rgba(82, 0, 57, 0.0784313725);
  overflow: hidden;
}
.benefits .box:after {
  content: "";
  position: absolute;
  left: 0;
  width: 40%;
  bottom: 0;
  height: 4px;
  background: linear-gradient(270deg, #fcaf17 35%, #ffc556 100%);
  transition: all 0.5s ease;
}
.benefits .box:hover .step {
  opacity: 1 !important;
}
.benefits .box:hover:after {
  width: 100%;
}
.benefits .box img {
  width: 70px;
  -o-object-fit: contain;
     object-fit: contain;
}
.benefits .box h4 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  margin: 1pc 0 10px;
}
.benefits .box p {
  font-size: 18px;
  line-height: 28px;
}
.benefits .box .step {
  opacity: 0;
  font-size: 74px;
  font-weight: 700;
  position: absolute;
  right: 30px;
  top: 30px;
  color: rgba(252, 175, 23, 0.231372549);
  z-index: 99;
}

.blog-box .btn {
  letter-spacing: 1px;
}
.blog-box h4 {
  margin: 14px 0 5px;
  font-size: 22px !important;
  font-weight: 600;
  line-height: 1.5;
}
.blog-box h4 a {
  color: #000000;
}
.blog-box .image {
  height: 275px;
}
.blog-box .image img {
  border-radius: 15px;
}
.blog-box ul.tags {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}
.blog-box ul.tags li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  color: #4d4d4d;
}

.blog-detail .image {
  height: 400px;
}
.blog-detail .blog-box .main_heading {
  font-size: 30px;
  padding: 10px 0px;
  font-weight: 600;
}
.blog-detail .blog-box .blogh2 {
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
}
.blog-detail .blog-box h5 {
  font-weight: 600;
  padding: 10px 0px;
  font-size: 20px;
}
.blog-detail .blog-box p {
  margin-bottom: 10px;
}
.blog-detail ul.tags li {
  color: #000;
}

.our-solutions {
  background-color: #1f2732;
  background-image: url("../images/decor/texture_dark.jpg");
  background-attachment: fixed;
  background-size: cover;
}
.our-solutions .box {
  align-items: center;
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 10px 15px -2px rgba(86, 86, 86, 0.0784313725);
  display: flex;
  justify-content: center;
  min-height: 95px;
  padding: 15px 5px;
  position: relative;
  text-align: center;
  transition: all 0.5s ease;
}
.our-solutions .box h5 {
  color: #2e2e2e;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}
.our-solutions .box:hover {
  background-color: #fcaf17;
  box-shadow: 0 8px 3px 1px rgba(42, 42, 42, 0.1490196078);
  transform: translate3d(0, -10px, 0);
}
.our-solutions .box:hover h5 {
  color: #fff;
}

.work-flow {
  background-color: #1f2732;
  background-image: url("../images/decor/texture_dark.jpg");
  background-attachment: fixed;
  background-size: cover;
  padding: 0 5pc;
}
.work-flow .box {
  text-align: center;
}
.work-flow .box:hover h4,
.work-flow .box:hover .step {
  color: #fcaf17;
}
.work-flow .box .step {
  background-color: #1f2732;
  height: 80px;
  width: 80px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 45px;
  font-weight: 700;
  box-shadow: 0 0 1px 7px rgba(128, 128, 128, 0.3019607843);
  color: #ffffff;
}
.work-flow .box h4 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  margin: 1.5pc 0 5px;
  color: #fff;
}
.work-flow .box p {
  font-size: 18px;
  line-height: 28px;
  color: #fff;
}

.our-work .outer-box {
  position: relative;
}
.our-work .outer-box::before {
  content: "";
  position: absolute;
  border-right: 2px dashed lightgray;
  height: 100%;
  top: 0;
  left: 50%;
}
.our-work .outer-box .box {
  padding-bottom: 4pc;
}
.our-work .outer-box .box:nth-child(4n+1) .left {
  background: #fff2e2;
}
.our-work .outer-box .box:nth-child(4n+2) .left {
  background: #feeae3;
}
.our-work .outer-box .box:nth-child(4n+3) .left {
  background: #c8ebff;
}
.our-work .outer-box .box:nth-child(4n+4) .left {
  background: #eee0f7;
}
.our-work .outer-box .box .left {
  text-align: center;
  padding: 20px;
  width: 80%;
  border-radius: 15px;
  margin: 0 auto;
}
.our-work .outer-box .box .left img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  height: 400px;
}
.our-work .outer-box .box.reverse .row {
  flex-direction: row-reverse;
}
.our-work .outer-box .box.reverse .right {
  text-align: right;
  padding-right: 3pc;
}
.our-work .outer-box .box.reverse .right:before {
  right: -2px;
}
.our-work .outer-box .box:not(.reverse) .right {
  padding-left: 3pc;
}
.our-work .outer-box .box:not(.reverse) .right:before {
  left: 0;
}
.our-work .outer-box .box .right {
  position: relative;
}
.our-work .outer-box .box .right:before {
  content: "";
  position: absolute;
  width: 4px;
  background-color: #fcaf17;
  height: 100%;
  top: 0%;
}
.our-work .outer-box .box .right h4 {
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 1pc;
}
.our-work .outer-box .box .right p {
  margin-bottom: 0px;
}

.our-portfolio .outer-box .box {
  padding-bottom: 2pc;
}
.our-portfolio .outer-box .box .left {
  text-align: center;
  padding: 20px;
  width: 95%;
  border-radius: 15px;
  margin: 0 auto;
}
.our-portfolio .outer-box .box .left img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.our-portfolio .outer-box .box.reverse .row {
  flex-direction: row-reverse;
}
.our-portfolio .outer-box .box.reverse .right {
  text-align: right;
}
.our-portfolio .outer-box .box .right h4 {
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 1pc;
}
.our-portfolio .outer-box .box .right p {
  margin-bottom: 20px;
  text-align: justify;
}
.our-portfolio .outer-box .box .right a {
  margin-top: 30px;
}
.our-portfolio .outer-box .box .right a .iconright {
  font-size: 23px;
  opacity: 1;
}

.our-result p {
  margin-bottom: 0;
}
.our-result .box h1,
.our-result .box h4 {
  font-weight: 700;
}
.our-result .box h1 {
  font-size: 45px;
}

.consultation .row {
  background-color: #fafafa;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: space-evenly;
}
.consultation .left {
  padding: 2pc;
}

.banner .outer-box {
  box-shadow: 0 20px 50px rgba(103, 34, 0, 0.1019607843);
  padding: 4pc;
  border-radius: 15px;
}
.banner .box {
  display: flex;
  align-items: center;
  gap: 2pc;
}
.banner .box .icon img {
  height: auto;
  width: 84px;
  -o-object-fit: contain;
     object-fit: contain;
}

.footor-outer {
  background: rgb(244, 244, 244);
  position: relative;
}

.footer {
  padding: 4pc 0 0pc;
  position: relative;
  z-index: 4;
  background-color: #020202;
  z-index: 1;
}
.footer .footer-links p {
  font-size: 13px;
  color: #fff;
}
.footer .footer-links .text {
  color: #e7e7e7;
  font-size: 13px;
  line-height: 27px;
}
.footer .footer-links .title {
  color: #eaedf3;
  margin-bottom: 20px;
  font-weight: 500;
}
.footer .footer-links-list li {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
}
.footer .footer-links-list li:hover a {
  color: #fcaf17;
  letter-spacing: 1px;
  transition: all 0.5s;
}
.footer .footer-links-list .icon {
  color: #fff;
  font-size: 25px;
  font-weight: 400;
}
.footer .footer-links-list a {
  color: #e7e7e7;
  font-size: 14px;
  transition: all 0.5s;
  text-transform: uppercase;
}
.footer .footer-links-list a:hover {
  color: #fcaf17 !important;
}
.footer ul.contact-links li {
  display: flex;
  gap: 10px;
  margin-bottom: 11px;
}
.footer ul.contact-links li h6 {
  color: #eaedf3;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}
.footer ul.contact-links li .inner {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.footer ul.contact-links li .inner a {
  color: #eaedf3;
  font-size: 14px;
  line-height: 20px;
}
.footer ul.contact-links li .inner a:hover {
  color: #fcaf17 !important;
}
.footer ul.social-links a {
  height: 36px;
  width: 35px;
  border: 2px solid #fcaf17;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #eaedf3;
}
.footer ul.social-links a:hover {
  color: #fff !important;
  transform: rotate(1turn);
  background-color: #fcaf17 !important;
}
.footer::before {
  background-image: url("../images/bgfooterimg.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.footer .copyright p a:hover {
  color: #fcaf17 !important;
}

.copyright {
  text-align: center;
  position: relative;
  z-index: 4;
}
.copyright p {
  color: #f7f7f7;
  font-size: 15px;
  line-height: 25px;
  border-top: 1px solid rgba(255, 255, 255, 0.6392156863);
  padding: 15px 0;
  margin-bottom: 0;
  margin-top: 17px;
}

.about-intro .inner img {
  height: 340px;
  width: 340px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  -o-object-position: top;
     object-position: top;
  border: 4px solid transparent;
  box-shadow: 0px 0px 15px rgba(38, 37, 36, 0.2117647059);
}
.about-intro .content {
  position: relative;
  margin-top: -5pc;
}
.about-intro .content:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: #fafafa;
  height: 40%;
  width: 100%;
  z-index: -1;
}
.about-intro .content .title-section .heading {
  background: rgba(255, 255, 255, 0.5019607843);
  padding: 1pc 1pc;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
}
.about-intro .content p {
  font-size: 17px;
}

.our-values {
  display: flex;
  align-items: center;
}
.our-values ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 13px;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.our-values ul li {
  /* box-shadow: 0 0 20px #00000010; */
  padding: 6px 1pc;
  border-radius: 7px;
  border: 1px dashed lightgray;
}
.our-values ul li:hover {
  border-color: #fcaf17;
  color: #fcaf17;
}

.about-history .outer-box .box .left {
  background-color: transparent !important;
  position: relative;
  width: 100%;
}
.about-history .outer-box .box .left:before {
  content: "";
  position: absolute;
  width: 4px;
  background-color: #fcaf17;
  height: 100%;
  top: 0%;
}
.about-history .outer-box .box .right:before {
  display: none;
}
.about-history .outer-box .box .right p {
  margin-bottom: 0;
}
.about-history .outer-box .box:not(.reverse) .left {
  text-align: right;
  padding-right: 3pc;
}
.about-history .outer-box .box:not(.reverse) .left:before {
  right: -2px;
}
.about-history .outer-box .box.reverse .left {
  text-align: left;
  padding-left: 3pc;
}
.about-history .outer-box .box.reverse .left:before {
  left: 0;
}

.services-page .col-12:nth-child(4n+1) .box {
  background: #fff2e2;
}
.services-page .col-12:nth-child(4n+2) .box {
  background: #feeae3;
}
.services-page .col-12:nth-child(4n+3) .box {
  background: #c8ebff;
}
.services-page .col-12:nth-child(4n+4) .box {
  background: #eee0f7;
}
.services-page .box {
  background: #fff;
  position: relative;
  border-radius: 14px;
  padding: 40px 30px;
  max-height: 502px;
  height: 100%;
  overflow: hidden;
}
.services-page .box:hover {
  box-shadow: 0 10px 15px -2px rgba(82, 0, 57, 0.0784313725);
}
.services-page .box h4 {
  font-weight: 700;
  margin-bottom: 1pc;
}
.services-page .box p {
  font-size: 18px;
  margin-bottom: 5px;
  line-height: 25px;
  color: #797979;
}
.services-page .box .content {
  margin-bottom: 1pc;
}
.services-page .box .image {
  text-align: center;
  max-height: 270px;
  height: 100%;
}
.services-page .box .image img {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.service-intro p {
  margin-bottom: 0;
}

.what-we-offer .box {
  text-align: left !important;
}
.what-we-offer .box h4 {
  color: #000;
}
.what-we-offer .box p {
  color: #797979;
  margin-bottom: 0;
  text-align: left;
  font-size: 15px;
  line-height: 23px;
}
.what-we-offer .box p span {
  color: #000;
  font-weight: 800;
  display: block;
}
.what-we-offer .box .icon {
  position: relative;
  width: -moz-max-content;
  width: max-content;
}
.what-we-offer .box .icon::before {
  content: "";
  position: absolute;
  height: 60px;
  width: 60px;
  background: rgba(252, 175, 23, 0.3215686275);
  z-index: 2;
  right: 0pc;
  top: -1pc;
  border-radius: 50%;
}
.what-we-offer .box .icon img {
  position: relative;
  z-index: 4;
  width: auto;
  height: 87px;
  -o-object-fit: contain;
     object-fit: contain;
}

.contact-page-sec img {
  height: 400px;
}

.contact-details {
  background-color: #1f2732;
  background-image: url("../images/decor/texture_dark.jpg");
  background-attachment: fixed;
  background-size: cover;
  color: #fff;
  padding: 3pc 2pc;
}
.contact-details .box p {
  margin-bottom: 0px;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.5);
}
.contact-details .box a {
  color: rgba(255, 255, 255, 0.5);
}
.contact-details .icon-box {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1pc !important;
}
.contact-details .icon-box h6 {
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0;
}

.our-clients .box {
  display: flex;
  align-items: center;
  -moz-column-gap: 3pc;
       column-gap: 3pc;
  row-gap: 1pc;
  flex-wrap: wrap;
}
.our-clients .box img {
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
  height: 110px;
}

.privacy-policy p {
  margin-bottom: 5px;
}
.privacy-policy a {
  color: #0d6efd;
}
.privacy-policy b {
  color: #000;
}
.privacy-policy h6 {
  margin-top: 2pc;
  margin-bottom: 5px;
}
.privacy-policy ul li {
  list-style-type: square;
  list-style-position: inside;
}
.privacy-policy ul li::marker {
  color: #fcaf17;
}

.highlight-casestudy .study-view {
  display: flex;
  align-items: center;
  gap: 2pc;
}
.highlight-casestudy .study-view img {
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
  height: 80px;
}
.highlight-casestudy .study-view p {
  font-size: 19px;
  font-weight: 500;
}

.case-study ul.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.case-study ul.tabs li {
  /* padding: 10px 1pc; */
  cursor: pointer;
  border: 1px solid lightgray;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  transition: all 0.5s;
  font-size: 18px;
  padding: 10px 20px;
  max-width: 100%;
  margin-left: 20px;
}
.case-study ul.tabs li.active {
  background-color: #fcaf17;
  border-color: #fcaf17;
  color: #fff;
  transition: all 0.5s;
}
.case-study-box {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1019607843);
  border-radius: 15px;
}
.case-study-box .image {
  position: relative;
}
.case-study-box .image img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 400px;
  border-radius: 15px 15px 0 0;
}
.case-study-box .image h5 {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 1pc;
  color: #fff;
}
.case-study-box .image:before {
  content: "";
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.case-study-box .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1pc 2pc;
  gap: 2pc;
  border-radius: 0 0 15px 15px;
  background-color: #fff;
}
.case-study-box .content img {
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
  height: 70px;
}
.case-study-box .content p {
  font-size: 19px;
  font-weight: 500;
}

.work-page-img img {
  height: 400px;
}

.work-detail-page ul.info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1pc;
  -moz-column-gap: 1pc;
       column-gap: 1pc;
}
.work-detail-page ul.info > li {
  padding: 1pc 3pc;
  border: 1px solid lightgray;
  color: #000;
}
.work-detail-page ul.info > li a {
  color: #000;
}
.work-detail-page ul.info > li a:hover {
  color: #fcaf17;
}
.work-detail-page h5.heading {
  margin-top: 2pc;
  margin-bottom: 5px;
}

.portfolio-box {
  position: relative;
  margin-bottom: 2pc;
}
.portfolio-box .portfolio-content {
  position: absolute;
  bottom: 1pc;
  left: 0;
  background-color: #fcaf17;
  color: #fff;
  padding: 1pc 2pc;
  margin: 0 2pc;
  transition: all 1s;
  z-index: 10;
}
.portfolio-box .portfolio-content h4,
.portfolio-box .portfolio-content p,
.portfolio-box .portfolio-content a {
  color: #fff;
}
.portfolio-box .portfolio-content a {
  text-decoration: none;
}
.portfolio-box .portfolio-content p {
  font-size: 15px;
  line-height: normal;
  margin: 0;
  letter-spacing: 0.5px;
}
.portfolio-box .portfolio-img {
  position: relative;
  overflow: hidden;
}
.portfolio-box .portfolio-img img {
  height: 500px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 1s;
}
.portfolio-box .portfolio-img:before {
  content: "";
  position: absolute;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3019607843) 30%, rgba(0, 0, 0, 0) 100%);
  height: 10%;
  width: 180%;
  bottom: 32pc;
  left: -31pc;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
  z-index: 4;
  transform: rotate(-50deg);
}
.portfolio-box .portfolio-img:after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3137254902);
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 1s;
  z-index: 2;
}
.portfolio-box:hover .portfolio-content {
  transform: scaleY(1);
  transition: all 1s;
}
.portfolio-box:hover .portfolio-img:before {
  opacity: 1;
  transition: all 0.5s;
  bottom: -20pc;
  left: 0;
}
.portfolio-box:hover .portfolio-img:after {
  opacity: 1;
  transition: all 1s;
}
.portfolio-box:hover .portfolio-img img {
  transform: scale(1.2);
  transition: all 1s;
}

.solution-page .box img {
  height: 385px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 15px;
}
.solution-page .box .content {
  padding: 1pc;
  text-align: left;
  width: 85%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  margin: -2pc 0 0 0pc;
  position: relative;
  z-index: 4;
  background: #fff;
}
.solution-page .box .content h5 {
  margin-bottom: 0;
}
.solution-page .box .content p {
  line-height: 25px;
  margin-bottom: 0px;
}

.solution-benefits ul.tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2pc;
  justify-content: center;
}
.solution-benefits ul.tabs li {
  padding: 10px 1pc;
  cursor: pointer;
  border: 1px solid lightgray;
  border-radius: 4px;
  min-width: 130px;
  text-align: center;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  transition: all 0.5s;
}
.solution-benefits ul.tabs li.active {
  background-color: #fcaf17;
  border-color: #fcaf17;
  color: #fff;
  transition: all 0.5s;
}

.our-team {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px;
}
.our-team .box img {
  width: 220px;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 15px;
}
.our-team .box .content {
  padding-top: 10px;
  text-align: center;
}
.our-team .box .content h6 {
  margin-bottom: 0;
}
.our-team .box .content .designation {
  color: #212529;
  font-size: 14px;
}
.our-team .box .content p {
  line-height: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-style: unset !important;
  font-size: 15px;
  opacity: 0;
}
.our-team .absolute_more {
  font-size: 27px;
  position: relative;
  bottom: -65px;
  color: #fcaf17;
}

.services_list ul {
  margin-left: 16px;
}
.services_list li {
  list-style: disc;
  font-size: 15px;
  margin-bottom: 7px;
}

.sa-technology {
  background-image: url("../images/tech_img/dots-background-2.png");
  background-repeat: no-repeat;
  background-position: top center;
  border-top: 1px solid #f1f1f1;
}

.content-grid .content-grid-item {
  position: relative;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content-grid .content-grid-item:before {
  height: 100%;
  top: 0;
  left: -1px;
  border-left: 1px solid #dadada;
}

.sa-technology p {
  font-size: 20px;
  padding-top: 10px;
}

.content-grid .content-grid-item:after {
  width: 100%;
  height: 0;
  top: auto;
  left: 0;
  bottom: -1px;
  border-bottom: 1px solid #dadada;
}

.content-grid .content-grid-item:after,
.content-grid .content-grid-item:before {
  content: "";
  position: absolute;
}

.content-grid {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.gray-bg2 {
  background-color: #f1f1f1 !important;
  border-top: 5px solid #efefef;
}

.our-solutions .col-auto {
  width: 20%;
  margin-bottom: 15px;
}

.worksflosection .box {
  border-radius: 1rem;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  display: block;
  min-height: 360px;
  position: relative;
  transition: all 0.3s ease;
  text-align: center;
  padding: 15px;
  background-color: #fff;
}
.worksflosection .box:hover {
  box-shadow: 0 5px 20px 0 rgba(210, 210, 245, 0.5);
  z-index: 3;
}
.worksflosection .box:hover .process-icon {
  background: #000;
  color: #fbaf17 !important;
}
.worksflosection .process-icon {
  align-items: center;
  border-radius: 10px;
  display: flex;
  height: 80px;
  justify-content: center;
  margin: 0 auto 25px;
  text-align: center;
  transition: all 0.3s ease;
  width: 80px;
  font-size: 2rem;
  font-weight: bold;
  color: #52525d;
}
.worksflosection h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}
.worksflosection p {
  font-size: 16px;
}
.worksflosection .dots-line.first {
  margin-left: -17px;
  margin-right: -17px;
  position: relative;
}
.worksflosection .dots-line {
  border-bottom: 2px dashed rgba(42, 42, 42, 0.2509803922);
  flex: 1 1;
  height: 1px;
  padding-top: 0;
  position: relative;
  right: auto;
  top: auto;
  width: 100px;
  z-index: 2;
}

.ourworkbg {
  background-color: #fffdfa;
}

.in_expertise_s {
  position: relative;
}
.in_expertise_s .box_indstr {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 -1px 5px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  margin-bottom: 30px;
  min-height: 120px;
  overflow: hidden;
  padding: 25px 0;
  position: relative;
  text-align: center;
  border-radius: 8px;
}
.in_expertise_s .box_indstr .icon_arro {
  position: absolute;
  right: 14px;
  top: 14px;
  transform: rotate(-50deg);
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.in_expertise_s .box_indstr .icon_arro .icon_arro i {
  color: #000;
  font-size: 25px;
}
.in_expertise_s .box_indstr::after {
  background: #fa0;
  bottom: 0;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  width: 0;
}
.in_expertise_s .box_indstr:hover::after {
  width: 100%;
}
.in_expertise_s .box_indstr p {
  color: #222;
  font-size: 16px;
  font-weight: 600;
}
.in_expertise_s img {
  height: 99px;
  width: 60px;
}
.in_expertise_s .col-auto {
  width: 20%;
  margin-bottom: 15px;
}

.tabs_customersays .container-fluid {
  padding: 0px 7pc;
}

.testimontab_body {
  background-color: #fafafa;
  min-height: 476px;
  padding: 70px;
  position: relative;
}
.testimontab_body .f_right1 {
  animation: fadeInRight 0.5s ease-in-out;
}
.testimontab_body .user_dets {
  display: flex;
  margin-top: 30px;
}
.testimontab_body .user_dets .pic {
  margin-right: 10px;
  width: 60px;
}
.testimontab_body .user_dets p {
  color: #222;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  line-height: 1.9;
}

.list_testimour {
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  padding: 36px 0 0;
}
.list_testimour .nav-link {
  cursor: pointer;
  padding: 15px 10px;
  position: relative;
  border-bottom: 1px solid #f7f7f7;
  border-radius: 0;
}
.list_testimour .nav-link img {
  width: 175px;
}
.list_testimour .nav-link.active {
  background-color: transparent;
}
.list_testimour .nav-link.active::before {
  background: #fbaf17;
  content: "";
  display: block;
  height: 70%;
  left: 0;
  position: absolute;
  top: 12px;
  width: 3px;
}

.btn-tbs {
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  text-align: center;
}
.btn-tbs a {
  align-items: center;
  color: #000;
  display: flex;
  justify-content: center;
  text-decoration: none !important;
  font-weight: 500;
  font-size: 19px;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
  }
}
.bgcolor1 {
  background: linear-gradient(157deg, rgb(255, 241, 0) 0%, rgb(231, 31, 26) 100%) 0% 0% no-repeat padding-box padding-box transparent;
}

.card_testmobial ul {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.card_testmobial h3 {
  font-size: 1.75rem;
}

.card_testmobial h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
}
.card_testmobial .content_testimonail {
  color: #fff;
  font-weight: 400;
  margin-top: 30px;
}
.card_testmobial ul {
  margin-top: 30px;
}
.card_testmobial .count-section {
  margin-top: 40px;
}
.card_testmobial hr {
  width: 2px;
  height: auto;
  border: none;
  background-color: #fff;
  opacity: 1;
  margin: 5px;
}
.card_testmobial .counts span {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.card_testmobial .counts .words {
  color: #fff;
  font-size: 14px;
}

.case_study_secton {
  background-color: #f7f7f7;
  padding: 60px 0;
}

.centeredSlides .swiper-slide:not(.swiper-slide-next) .main_testimonial {
  transform: scale(0.9);
  opacity: 0.3;
  transition: all 0.5s;
}

.centeredSlides .swiper-slide-next .main_testimonial .inner {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.portfoliyoswier .swiper-slide {
  backface-visibility: hidden;
  transform: translateZ(0);
}

.main_testimonial {
  text-align: center;
  transition: all 0.5s;
}

.browser-bx {
  box-shadow: -20px 20px 10px 0px rgba(0, 0, 0, 0.1);
  display: inline-block;
  border-radius: 10px;
  background-color: #fafcfe;
  margin-bottom: 40px;
}
.browser-bx .browser-topbar {
  background-color: #ededed;
  position: relative;
  height: 50px;
  border-radius: 10px 10px 0 0;
  text-align: left;
}
.browser-bx .browser-topbar .browser-dot {
  position: relative;
  width: 14px;
  height: 14px;
  background-color: #29b7b9;
  border-radius: 50%;
  display: inline-block;
  margin: 19px 0px 19px 35px;
}
.browser-bx .browser-topbar .browser-dot::before {
  position: absolute;
  content: "";
  width: 14px;
  height: 14px;
  background-color: #29b7b9;
  border-radius: 50%;
  left: -20px;
  top: 0;
}
.browser-bx .browser-topbar .browser-dot::after {
  position: absolute;
  content: "";
  width: 14px;
  height: 14px;
  background-color: #29b7b9;
  border-radius: 50%;
  top: 0;
  left: 20px;
}
.browser-bx .case-title {
  margin: 15px;
  font-size: 22px;
  color: #18191b;
  font-weight: bold;
  text-align: left;
}
.browser-bx .casedescription {
  text-align: justify;
  margin: 15px;
  font-size: 14px;
  line-height: 24px;
  color: #18191b;
}

.text {
  font-family: "Yanone Kaffeesatz";
  font-size: 100px;
  display: flex;
  position: absolute;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.text .wrapper {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.text .wrapper .letter {
  transition: ease-out 1s;
  transform: translateY(40%);
}
.text .wrapper .shadow {
  transform: scale(1, -1);
  color: #999;
  transition: ease-in 5s, ease-out 5s;
}
.text .wrapper:hover .letter {
  transform: translateY(-200%);
}
.text .wrapper:hover .shadow {
  opacity: 0;
  transform: translateY(200%);
}

.text-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}
.text-container h1 {
  color: #262626;
  font-size: calc(1em + 2vw);
  font-weight: 900;
  text-shadow: -0.0075em 0.0075em 0 #f4f4f4, 0.005em 0.005em 0 #b7b7b7, 0.01em 0.01em 0 #bbbbbb, 0.015em 0.015em #bebebe, 0.02em 0.02em 0 #c2c2c2, 0.025em 0.025em 0 #c5c5c5, 0.03em 0.03em 0 #c9c9c9, 0.035em 0.035em 0 #cdcdcd;
}
.text-container h2 {
  color: #262626;
  font-size: calc(1em + 1.5vw);
  font-weight: 900;
  text-shadow: -0.0075em 0.0075em 0 #f4f4f4, 0.005em 0.005em 0 #b7b7b7, 0.01em 0.01em 0 #bbbbbb, 0.015em 0.015em #bebebe, 0.02em 0.02em 0 #c2c2c2, 0.025em 0.025em 0 #c5c5c5, 0.03em 0.03em 0 #c9c9c9, 0.035em 0.035em 0 #cdcdcd;
}

.single-integration {
  height: 200px;
}

::-moz-selection {
  color: #b47c0c;
  background: #000000;
}

::selection {
  color: #b47c0c;
  background: #000000;
}

.accordion.accordion-flush .accordion-item {
  border-radius: 7px !important;
  overflow: hidden;
  outline: none !important;
}
.accordion.accordion-flush .accordion-button {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: #000;
  outline: transparent !important;
  box-shadow: unset !important;
}
.accordion.accordion-flush .accordion-button:not(.collapsed) {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.accordion.accordion-flush .accordion-body {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.accordion.accordion-flush .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}

.why-choose-us-card {
  transition: ease-in-out all 0.5s;
  padding: 70px 30px;
  background-color: white;
}

.why-choose-us-card:hover {
  box-shadow: 0px 0px 28px -3px rgba(0, 0, 0, 0.527);
  background-color: white;
  padding: 70px 30px;
  transform: scale(1.05);
  border-radius: 10px;
  z-index: 1500;
  transition: ease-in-out all 0.5s;
}

.our-key-experties {
  background-color: rgba(0, 0, 0, 0.034);
}

.get_intouc {
  position: relative;
}
.get_intouc .box_sectoin {
  border-radius: 1rem;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  display: block;
  position: relative;
  transition: all 0.3s ease;
  padding: 20px 35px;
  background-color: #fff;
}
.get_intouc .box_sectoin .sideinfobg {
  background-color: rgba(252, 175, 23, 0.4117647059);
  border-radius: 11px;
  padding: 25px 14px;
}
.get_intouc .box_sectoin .sideinfobg h2 {
  font-size: 23px;
  font-weight: 600;
}
.get_intouc .box_sectoin .sideinfobg p {
  font-size: 16px;
  color: #000;
  line-height: 24px;
  font-weight: 600;
}
.get_intouc .box_sectoin .sideinfobg .socal-linkgetcuton {
  margin-top: 20px;
}
.get_intouc .box_sectoin .sideinfobg .socal-linkgetcuton li {
  padding-bottom: 20px;
}
.get_intouc .box_sectoin .sideinfobg .socal-linkgetcuton li span {
  margin-left: 10px;
}
.get_intouc::before {
  position: absolute;
  top: 0;
  content: "";
  background-color: #fff6f7;
  width: 100%;
  left: 0;
  right: 0;
  height: 300px;
}

.honeycomb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  transform: translateY(34.375px);
}
.honeycomb-cell {
  flex: 0 1 250px;
  max-width: 200px;
  height: 100.5px;
  margin: 38.47619px 8.5px 24px;
  position: relative;
  display: flex;
  /* padding: 0.5em; */
  text-align: center;
  z-index: 1;
  text-align: center;
  z-index: 1;
  align-items: center;
  justify-content: center;
}
.honeycomb-cell__title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-hyphens: auto;
          hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1.75em;
  transition: opacity 350ms;
}
.honeycomb-cell__title > small {
  font-weight: 300;
  margin-top: 0.25em;
}
.honeycomb-cell__image {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.honeycomb-cell__image2 {
  height: 80px;
  width: 80px;
}
.honeycomb-cell::before, .honeycomb-cell::after {
  content: "";
}
.honeycomb-cell::before, .honeycomb-cell::after, .honeycomb-cell__image {
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
          clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}
.honeycomb-cell::before {
  background-color: white;
  transform: scale(1.055);
}
.honeycomb-cell::after {
  opacity: 0.5;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: opacity 350ms;
}
.honeycomb-cell:hover .honeycomb-cell__title {
  opacity: 0;
}
.honeycomb-cell:hover::after {
  opacity: 0;
}
.honeycomb-cell:nth-child(1):after {
  background: #fff2e2;
}
.honeycomb-cell:nth-child(2):after {
  background: rgb(233, 208, 208);
}
.honeycomb-cell:nth-child(3):after {
  background: #fff2e2;
}
.honeycomb-cell:nth-child(4):after {
  background: #feeae3;
}
.honeycomb-cell:nth-child(5):after {
  background: #fff2e2;
}
.honeycomb-cell:nth-child(6):after {
  background: rgb(233, 208, 208);
}
.honeycomb-cell:nth-child(7):after {
  background: #fff2e2;
}
.honeycomb-cell:nth-child(8):after {
  background: #feeae3;
}
.honeycomb-cell:nth-child(9):after {
  background: #fff2e2;
}
.honeycomb-cell:nth-child(10):after {
  background: rgb(233, 208, 208);
}
.honeycomb-cell:nth-child(11):after {
  background: #fff2e2;
}
.honeycomb-cell:nth-child(12):after {
  background: #feeae3;
}
.honeycomb-cell:nth-child(13):after {
  background: #fff2e2;
}
.honeycomb-cell:nth-child(14):after {
  background: #d5b4b4;
}
.honeycomb__placeholder {
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}

@media (max-width: 550px) {
  .honeycomb-cell {
    margin: 81.25px 25px;
  }
}
@media (min-width: 550px) and (max-width: 825px) {
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - 125px);
    margin-left: calc(50% - 125px);
  }
  .honeycomb__placeholder:nth-child(3n+5) {
    display: block;
  }
}
@media (min-width: 825px) and (max-width: 1100px) {
  .honeycomb-cell:nth-child(5n+4) {
    margin-left: calc(50% - 275px);
  }
  .honeycomb-cell:nth-child(5n+5) {
    margin-right: calc(50% - 275px);
  }
  .honeycomb__placeholder:nth-child(5n),
  .honeycomb__placeholder:nth-child(5n+3) {
    display: block;
  }
}
@media (min-width: 1100px) {
  .honeycomb-cell:nth-child(7n+5) {
    margin-left: calc(50% - 400px);
  }
  .honeycomb-cell:nth-child(7n+7),
  .honeycomb-cell:nth-child(7n+5):nth-last-child(2) {
    margin-right: calc(50% - 400px);
  }
  .honeycomb__placeholder:nth-child(7n+7),
  .honeycomb__placeholder:nth-child(7n+9),
  .honeycomb__placeholder:nth-child(7n+11) {
    display: block;
  }
}
.single-integration img {
  width: 100%;
  height: 80px;
  -o-object-fit: contain;
     object-fit: contain;
}

.bg-grayservics {
  background-color: #fafafa;
}
.bg-grayservics .services_card {
  text-align: center;
  padding: 70px 10px;
}
.bg-grayservics .services_card .services_img {
  width: 100px;
  height: 100px;
  margin: auto;
}
.bg-grayservics .services_card:hover {
  background-color: #f1f1f1;
}
.bg-grayservics .services_card h3 {
  font-weight: 400;
  margin-bottom: 0;
}
.bg-grayservics .services_card p {
  font-size: 15px;
  font-style: italic;
  margin-bottom: 0;
  padding: 14px 0;
}
.bg-grayservics .services_card a {
  font-size: 19px;
  text-decoration: underline;
  color: #000;
  font-weight: 500;
}
.bg-grayservics .bg-srvicewsgray {
  background-color: #e6e9ed;
}

.clicent.bg-clicent {
  background-color: #fffdfd;
  color: #000;
}

.our-clients .marquee-items.left .items_logo {
  width: 320px;
  height: 188px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.0588235294);
  padding: 15px;
}
.our-clients .marquee-items.left .items_logo img {
  width: 200px;
  height: 70px;
  -o-object-fit: contain;
     object-fit: contain;
}

.our-clients .marquee-items.right {
  animation: marqueeRight 100s linear infinite;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  position: relative;
  -webkit-transform: translateZ(0);
  color: #111111;
  transform: translateZ(0);
}
.our-clients .marquee-items.right .items_logo {
  width: 320px;
  height: 188px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.0588235294);
}
.our-clients .marquee-items.right .items_logo img {
  width: 200px;
  height: 70px;
  -o-object-fit: contain;
     object-fit: contain;
}

.h100vh_section {
  height: 100vh;
  padding: 60px 0;
}

.center_textdummy {
  padding-right: 150px;
}

.hegiht30 {
  height: 30px !important;
}

@keyframes marqueeLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes marqueeRight {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.homebanner img {
  width: 100%;
  height: auto;
}

.homebannertop img {
  width: 100%;
  height: auto;
}

.homebannertop .homrbanner {
  width: 100%;
  height: auto;
}

.newOurJourney_section {
  background-color: #fbfbfb;
}

.jrny-border {
  position: relative;
}
.jrny-border::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 100%;
  background-color: black;
  border: 1px solid transparent;
  border-radius: 10px;
  top: 45%;
}
.jrny-border .jrny-arrow {
  position: relative;
}
.jrny-border .jrny-arrow::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 20px solid black;
  top: 45%;
  z-index: 2;
  left: 45%;
}
.jrny-border .jrny-arrow:nth-child(2):before {
  transform: rotate(57deg);
  top: 44%;
}
.jrny-border .jrny-arrow:nth-child(4):before {
  transform: rotate(57deg);
  top: 44%;
}
.jrny-border .img-col {
  padding: 50px 0px;
  text-align: center;
}
.jrny-border .content-col {
  padding: 30px 0px;
}
.jrny-border .heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
  color: #000;
  text-align: center;
  padding: 30px 0px 5px;
}
.jrny-border .description {
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #ababab;
  text-align: center;
  min-height: 210px;
}

.mission_value {
  position: relative;
}
.mission_value .imgabsolute {
  position: absolute;
  top: -55px;
  right: 0;
  left: 0;
  margin: auto;
  width: 100px;
  opacity: 0.4;
}

.cour_value .icon-head-wrap {
  display: flex;
  gap: 24px;
}
.cour_value .icon-head-wrap img {
  width: 65px;
  height: 65px;
}
.cour_value .icon-head-wrap h4 {
  font-size: 22px;
  font-weight: 500;
}
.cour_value .icon-head-wrap p {
  font-size: 14px;
  width: 90%;
}
.cour_value .its_best {
  padding: 17px 10px;
}

.carrier_container h4 {
  font-size: 30px;
  line-height: 42px;
  font-weight: 600;
  margin-bottom: 20px;
}
.carrier_container img {
  width: 100%;
  height: 60vh;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
}

.main-blog {
  background-color: #f9f9f9;
}
.main-blog .al_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-blog .al_center .blog_bx_left {
  width: 48%;
  height: 380px;
  box-shadow: 0 1px 6px -1px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
}
.main-blog .al_center .blog_bx_left .blog__img {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  display: flex;
  margin: auto;
  border-radius: 12px;
}
.main-blog .al_center .blog_bx_right {
  width: 48%;
}
.main-blog .al_center .blog_bx_right .blog-headingtext {
  font-size: 45px;
  color: #000;
  font-weight: 500;
}
.main-blog .al_center .blog_bx_right p {
  font-size: 19px;
  line-height: 1.5;
  margin-bottom: 15px;
}
.main-blog .al_center .blog_bx_right .writer_name,
.main-blog .al_center .blog_bx_right .content_date {
  color: #919191;
  font-size: 15px;
}
.main-blog .container-fluid {
  padding: 0px 9pc;
}

.category_blog a {
  padding: 5px 15px;
  border: 1px solid #b7b6b6;
  color: #000;
  border-radius: 8px;
  text-align: center;
  margin: 7px;
  font-size: 14px;
}
.category_blog a:hover {
  background-color: #fcaf17;
  color: #000 !important;
}

.blog_listing {
  margin-bottom: 50px;
}
.blog_listing .blog_listing_img {
  overflow: hidden;
  border-radius: 10px;
}
.blog_listing .blog_listing_img img {
  width: 100%;
  height: 280px;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
  transition-property: all;
}
.blog_listing .blog_listing_img:hover img {
  filter: brightness(0.6);
  transition: 1s all;
  transform: scale(1.2);
}
.blog_listing .list_content_blog h6 {
  margin: 20px 0;
  color: #fcaf17;
}
.blog_listing .list_content_blog h4 {
  font-size: 20px;
  line-height: 30px;
  color: #4a4a4a;
  font-weight: 600;
}
.blog_listing .list_content_blog p {
  margin: 12px 0;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 15px;
}
.blog_listing .list_content_blog .btn.blog_readmore {
  color: #000;
  font-weight: 600;
  padding-left: 0;
  font-size: 16px;
}
.blog_listing .list_content_blog .btn.blog_readmore:hover {
  color: #fcaf17 !important;
}
.blog_listing .list_content_blog .btn.blog_readmore:hover svg {
  font-size: 22px;
  transition: all 0.2s;
}
.blog_listing .list_content_blog .btn.blog_readmore svg {
  transition: all 0.2s;
}

.blog2_heading h3 {
  font-size: 50px;
  font-weight: 300;
}
.blog2_heading h4 {
  font-size: 55px;
  color: #fcaf17;
  font-weight: 600;
}
.blog2_heading p {
  font-size: 20px;
}

.blog_card_img img {
  width: 95%;
  height: 307px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}

.blog_card {
  margin-bottom: 30px;
}

.icon.svgiocn svg {
  height: unset;
  width: 27px;
  -o-object-fit: unset;
     object-fit: unset;
}

.border-right {
  border-right: 1px solid #b0b3b5;
}

.info_date h6 {
  margin-bottom: 0;
}
.info_date a {
  color: #fcaf17;
  font-weight: 600;
}

.blog_page_heading {
  width: 90%;
}
.blog_page_heading h5 {
  color: #001d33;
  font-size: 14px;
  font-weight: 400;
  margin: 10px 0;
}
.blog_page_heading h3 {
  font-size: 21px;
  padding: 10px 0 5px;
  line-height: 1.4;
}
.blog_page_heading p {
  color: #5c5757;
  font-size: 19px;
  line-height: 29px;
  font-weight: 400;
}
.blog_page_heading ul {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.blog_page_heading ul li {
  font-size: 14px;
}

.category_blog.sticky-top {
  top: 2pc;
}
.category_blog.sticky-top h4 {
  padding-bottom: 10px;
  width: 75%;
  border-bottom: 1px solid #ccc;
}

.our-portfolio-section-container {
  min-height: 5000px !important;
}
.our-portfolio-section-container .our_port_section {
  height: 100vh !important;
  background-color: #ccc;
}
.our-portfolio-section-container .our_port_section .flex_row {
  display: flex;
}
.our-portfolio-section-container .our_port_section .appent_left_section {
  flex: 0.7;
  background-color: #001d33;
  height: 100vh;
  transition: ease-in-out all 0.5s;
}
.our-portfolio-section-container .our_port_section .appent_left_section .slider_portfliucontent {
  width: 80%;
}
.our-portfolio-section-container .our_port_section .appent_right_section {
  background-color: #12c21b;
  flex: 1;
  height: 100vh;
  transition: ease-in-out all 0.5s;
  position: relative;
}
.our-portfolio-section-container .our_port_section .appent_right_section .bottommockupimg {
  position: absolute;
  bottom: 0;
  right: 10px;
}
.our-portfolio-section-container .our_port_section .appent_right_section .topmockupimg {
  transition-delay: 0.05s;
}
.our-portfolio-section-container .our_port_section .appent_right_section .bottommockupimg {
  transition-delay: 0.1s;
}
.our-portfolio-section-container .our_port_section .appent_right_section img {
  width: 30%;
}
.our-portfolio-section-container .our_port_section .appent_right_section .centermockupimg {
  position: absolute;
  top: 20%;
  margin: auto;
  right: 36%;
}

.our-portfolio-section-container {
  position: relative;
}

.appent_left_section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}
.appent_left_section .slider_portfliucontent h3 {
  color: #fff;
  font-size: 56px;
}
.appent_left_section .slider_portfliucontent p {
  color: #fff;
  font-size: 24px;
  margin-bottom: 25px;
}
.appent_left_section .slider_portfliucontent a {
  color: #fff !important;
  font-size: 30px;
  position: relative;
  padding-bottom: 10px;
}
.appent_left_section .slider_portfliucontent a svg {
  color: #fff;
  font-size: 30px;
}
.appent_left_section .slider_portfliucontent a::after {
  background: #fff;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  width: 0;
}
.appent_left_section .slider_portfliucontent a:hover::after {
  width: 100%;
}

.view_webprtfoliyo {
  display: flex;
  justify-content: space-between;
}
.view_webprtfoliyo .leftwebport .bgimg {
  transform: scale(1);
  position: absolute;
  background-color: #fbe6c1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 80vh;
  width: 80%;
  left: 0;
  top: 0;
  transform: scale(1.1);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.view_webprtfoliyo .leftwebport .left_iluki {
  position: absolute;
  top: 0;
  height: 100%;
  width: 90%;
  overflow: hidden;
}
.view_webprtfoliyo .leftwebport,
.view_webprtfoliyo .rightwebport {
  flex: 1;
  position: relative;
  position: relative;
  height: 100vh;
}
.view_webprtfoliyo .leftwebport h3,
.view_webprtfoliyo .rightwebport h3 {
  font-size: 40px;
}
.view_webprtfoliyo .leftwebport h5,
.view_webprtfoliyo .rightwebport h5 {
  font-size: 21px;
}
.view_webprtfoliyo .leftwebport p,
.view_webprtfoliyo .rightwebport p {
  font-size: 19px;
  margin: 19px 0;
}
.view_webprtfoliyo .desktopicon {
  position: absolute;
  right: -11%;
  top: 12%;
}
.view_webprtfoliyo .desktopicon img {
  width: 90%;
}
.view_webprtfoliyo .rightwebport {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.view_webprtfoliyo .rightwebport .widt80 {
  width: 80%;
}

.right_view_webprtfoliyo {
  display: flex;
  justify-content: space-between;
  height: 100vh;
}
.right_view_webprtfoliyo .bgimg {
  transform: scale(1);
  background-color: #5ac4be;
  position: absolute;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 80vh;
  width: 80%;
  right: 0;
  top: 0;
  transform: scale(1.1);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.right_view_webprtfoliyo .leftwebport,
.right_view_webprtfoliyo .rightwebport {
  flex: 1;
  position: relative;
  position: relative;
  height: 100vh;
}
.right_view_webprtfoliyo .leftwebport h3,
.right_view_webprtfoliyo .rightwebport h3 {
  font-size: 40px;
}
.right_view_webprtfoliyo .leftwebport h5,
.right_view_webprtfoliyo .rightwebport h5 {
  font-size: 21px;
}
.right_view_webprtfoliyo .leftwebport p,
.right_view_webprtfoliyo .rightwebport p {
  font-size: 19px;
  margin: 19px 0;
}
.right_view_webprtfoliyo .leftwebport .btnall,
.right_view_webprtfoliyo .rightwebport .btnall {
  text-align: end;
  display: flex;
  justify-content: flex-end;
}
.right_view_webprtfoliyo .desktopicon {
  position: absolute;
  left: -9px;
  top: 20%;
}
.right_view_webprtfoliyo .desktopicon img {
  width: 95%;
}
.right_view_webprtfoliyo .rightwebport {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: end;
  padding-left: 14%;
}

.whatappiocn {
  position: fixed;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  bottom: 35px;
  left: 35px;
  z-index: 99999;
}

.whatappiocn img {
  width: 50px;
  animation: pulse-animation 2s infinite;
}

.newportfolyo li:nth-child(1) .portfolicardview::before {
  background-color: #fbe6c1;
}

.newportfolyo li:nth-child(2) .portfolicardview::before {
  background-color: #5ac4be;
}

.newportfolyo li:nth-child(3) .portfolicardview::before {
  background-color: #6ecae7;
}

.newportfolyo li:nth-child(4) .portfolicardview::before {
  background-color: #f8c259;
}

.newportfolyo li:nth-child(5) .portfolicardview::before {
  background-color: #e87741;
}

.newportfolyo li:nth-child(6) .portfolicardview::before {
  background-color: #d4b25b;
}

.newportfolyo li:nth-child(7) .portfolicardview::before {
  background-color: #4979bc;
}

.newportfolyo li:nth-child(8) .portfolicardview::before {
  background-color: #e1d5cf;
}

.newportfolyo li:nth-child(9) .portfolicardview::before {
  background-color: #735b71;
}

.newportfolyo li:nth-child(10) .portfolicardview::before {
  background-color: #df7a53;
}

.newportfolyo li:nth-child(11) .portfolicardview::before {
  background-color: #30505f;
}

.newportfolyo li:nth-child(12) .portfolicardview::before {
  background-color: #ff7857;
}

.newportfolyom li:nth-child(1) .portfolicardviewm::before {
  background-color: #a0dffd;
}

.newportfolyom li:nth-child(2) .portfolicardviewm::before {
  background-color: #e6d8ad;
}

.newportfolyom li:nth-child(3) .portfolicardviewm::before {
  background-color: #3840a8;
}

.newportfolyom li:nth-child(4) .portfolicardviewm::before {
  background-color: #126466;
}

.newportfolyom li:nth-child(5) .portfolicardviewm::before {
  background-color: #5a9aef;
}

.newportfolyom li:nth-child(6) .portfolicardviewm::before {
  background-color: #d4b25b;
}

.newportfolyom li:nth-child(7) .portfolicardviewm::before {
  background-color: #e15055;
}

.newportfolyom li:nth-child(8) .portfolicardviewm::before {
  background-color: #701c2b;
}

.newportfolyom li:nth-child(9) .portfolicardviewm::before {
  background-color: #c53b3a;
}

.newportfolyom li:nth-child(10) .portfolicardviewm::before {
  background-color: #bfadf8;
}

.newportfolyo li {
  padding: 37px 0;
  min-height: 100%;
}
.newportfolyo .card_portfoliyo {
  display: flex;
  justify-content: space-between;
}
.newportfolyo .card_portfoliyo .flex1 {
  flex: 1;
}
.newportfolyo .card_portfoliyo .portfolicardview {
  position: relative;
  min-height: 550px;
}
.newportfolyo .card_portfoliyo .portfolicardview::before {
  content: "";
  position: absolute;
  right: 0;
  width: 75%;
  height: 100%;
}
.newportfolyo .card_portfoliyo .portfolicardview img {
  width: 80%;
  z-index: 999;
  position: relative;
  margin-top: 6%;
}
.newportfolyo .card_portfoliyo .leftcardportfoli {
  padding: 0px;
  margin-top: 20%;
}
.newportfolyo .card_portfoliyo .text_left {
  margin-left: 30%;
  text-align: end;
}
.newportfolyo .card_portfoliyo h3 {
  font-size: 40px;
}
.newportfolyo .card_portfoliyo p {
  font-size: 19px;
  margin: 19px 0;
}
.newportfolyo .leftsidecontnt .portfolicardview {
  text-align: end;
}
.newportfolyo .leftsidecontnt .portfolicardview::before {
  left: 0;
}
.newportfolyo .leftsidecontnt .text_right {
  width: 70%;
}
.newportfolyo .flex_end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.newportfolyom li {
  padding: 50px 0;
  min-height: 100%;
}
.newportfolyom .card_portfoliyom {
  display: flex;
  justify-content: space-between;
}
.newportfolyom .card_portfoliyom .flex1 {
  flex: 1;
}
.newportfolyom .card_portfoliyom .portfolicardviewm {
  position: relative;
}
.newportfolyom .card_portfoliyom .portfolicardviewm::before {
  content: "";
  position: absolute;
  right: 0;
  width: 85%;
  min-height: 100%;
}
.newportfolyom .card_portfoliyom .portfolicardviewm img {
  width: 40%;
  z-index: 999;
  position: relative;
  margin-top: 6%;
}
.newportfolyom .card_portfoliyom .leftcardportfolim {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 8%;
  padding: 60px;
}
.newportfolyom .card_portfoliyom .text_left {
  margin-left: 30%;
  text-align: end;
}
.newportfolyom .card_portfoliyom h3 {
  font-size: 40px;
}
.newportfolyom .card_portfoliyom p {
  font-size: 19px;
  margin: 19px 0;
}
.newportfolyom .leftsidecontntm .portfolicardviewm {
  text-align: end;
}
.newportfolyom .leftsidecontntm .portfolicardviewm::before {
  left: 0;
}
.newportfolyom .leftsidecontntm .text_right {
  width: 70%;
}
.newportfolyom .flex_end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

/********** Edtech ************/
.first-section {
  background: url("../images/edimg1.jpg"), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-blend-mode: overlay;
  background-position: center !important;
  background-size: cover !important;
  height: 700px;
  display: flex;
  align-items: center;
}
.first-section h1 {
  font-weight: 600;
  font-size: 34px;
  color: #fff;
}
.first-section span {
  color: #fcaf17 !important;
}
.first-section p {
  font-size: 20px;
  margin: 1rem auto 2rem;
  color: #fff;
  font-weight: 400;
}
.first-section .btn {
  background-color: #fcaf17;
  padding: 10px 20px;
  border-radius: 50px;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid #fcaf17;
  font-weight: 700;
}
.first-section .btn:hover {
  border: 2px solid #fcaf17;
  color: #fcaf17 !important;
  background-color: transparent;
}

.second-section .consultation-form {
  background-color: #fff;
  border-radius: 20px;
  padding: 10%;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
}
.second-section h3 {
  padding: 0;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: black;
  font-size: 34px;
}
.second-section p {
  font-size: 16px;
  font-weight: 400;
  color: #212529;
  text-align: justify;
}
.second-section span {
  padding: 0;
  margin-bottom: 1.5rem;
  color: #fcaf17;
}
.second-section .title {
  font-size: 26px;
  color: #fcaf17;
}
.second-section form {
  padding-top: 10%;
}
.second-section .form-control {
  width: 100%;
  background: transparent;
  border: 1px solid #ced4da;
  color: #ffffff;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 400;
  box-shadow: none;
}
.second-section .consult-button {
  width: 100%;
  background-color: #fcaf17;
  border-radius: 5px;
  color: #ffffff;
  border: none !important;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px !important;
  padding: 11px 0;
}
.second-section img {
  margin-top: 15%;
}

.third-section {
  position: relative;
}
.third-section .section-img {
  -o-object-fit: cover;
     object-fit: cover;
  /* max-width: 80%; */
  animation: mover 1s infinite alternate;
  margin-top: 50px;
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-15px);
  }
}
.third-section .third-title {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.third-section span {
  color: #fcaf17;
  padding: 0;
}
.third-section .third-para {
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 400;
  color: #212529;
  text-align: justify;
}
.third-section ul {
  padding-left: 0;
  list-style: none;
}
.third-section li {
  font-size: 17px;
  font-weight: 500;
  color: #9c9c9c;
  padding-bottom: 10px;
  position: relative;
}
.third-section li::before {
  content: "";
  position: absolute;
  display: block;
  width: 20%;
  height: 2px;
  background-color: #fcaf17;
  top: 70%;
  left: 0;
  transition: 0.2s;
}
.third-section li:hover::before {
  width: 30%;
}

.fourth-section {
  background-color: #f9f9f9;
}
.fourth-section h3.fourth-title {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.fourth-section span {
  color: #fcaf17;
  padding: 0;
}
.fourth-section p {
  font-size: 17px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}
.fourth-section ul {
  border-bottom: none;
  justify-content: center;
  margin: 1.5rem 0;
}
.fourth-section a.nav-link.active {
  border: none;
  font-size: 1rem;
  background-color: #fcaf17;
  color: #ffffff !important;
  border-radius: 50px;
  font-weight: 600;
  padding: 10px 40px;
  text-align: center;
  margin: 10px;
}
.fourth-section a.nav-link {
  border: none;
  font-size: 1rem;
  border-radius: 50px;
  background-color: #e8ebf1;
  color: #212121;
  font-weight: 600;
  padding: 10px 40px;
  text-align: center;
  margin: 10px;
}
.fourth-section a.nav-link:hover {
  background-color: #fcaf17;
  color: #ffffff !important;
}
.fourth-section .features {
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 2.5rem 2rem;
  text-align: center;
  margin: 1rem 0;
  min-height: 212px;
}
.fourth-section img {
  width: 100%;
  height: 60px;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: 0.6s;
}
.fourth-section img:hover {
  transform: rotateY(180deg);
}
.fourth-section h5 {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 24px;
  color: #212529;
  font-family: "Open Sans", sans-serif;
}

.fifth-section {
  background-color: #f3f3f3;
  width: 100%;
  background-size: cover;
}
.fifth-section h3.title {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.fifth-section span {
  color: #fcaf17;
  padding: 0;
}
.fifth-section p {
  font-size: 17px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}
.fifth-section p.para1 {
  font-size: 17px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}
.fifth-section a.btn {
  background-color: #fcaf17;
  border-radius: 5px;
  box-shadow: 0 0 1px transparent;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 10px 20px;
  font-size: 17px;
}
.fifth-section a.btn:hover {
  color: #fff !important;
  background-color: #fcaf17 !important;
}
.fifth-section .work {
  display: block;
  position: relative;
  margin-bottom: 2rem;
  overflow: hidden;
  transition: transform 0.3s;
}
.fifth-section .work .images1 {
  height: 600px;
  -o-object-fit: contain;
     object-fit: contain;
  transition: transform 0.3s;
}
.fifth-section .work .images2 {
  width: 500px;
  height: 600px;
  -o-object-fit: contain;
     object-fit: contain;
  transition: transform 0.2s;
  position: absolute;
  top: 25%;
  left: 13%;
  z-index: 1;
}
.fifth-section .work .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.9), hsla(0, 0%, 60%, 0.1) 40%);
}
.fifth-section .work:hover img {
  transform: scale(1.1);
}

.sixth-section {
  background-color: #fff9ee;
}
.sixth-section .sixth-title {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 0.7rem;
  color: #212529;
}
.sixth-section span {
  color: #fcaf17;
  padding: 0;
}
.sixth-section p {
  font-size: 17px;
  font-weight: 400;
  color: #212529;
}
.sixth-section .works_section {
  text-align: right;
}
.sixth-section .works_section h5 {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 20px;
  color: #212529;
  font-family: "Open Sans", sans-serif;
}
.sixth-section .works_section .para {
  font-size: 15px;
  color: #212529;
  font-weight: 500;
}
.sixth-section span.number {
  background-color: #162f6a;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 45px;
  font-size: 1rem;
  padding: 8px 15px;
}
.sixth-section img {
  width: 100%;
  height: 600px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-top: 25%;
}
.sixth-section .right-side h5 {
  text-align: left;
}
.sixth-section .right-side p {
  font-size: 15px;
  color: #212529;
  font-weight: 500;
}
.sixth-section .right-side span.number {
  background-color: #fe9435;
}

.seventh-section {
  background-color: #212529;
}
.seventh-section .py-5 h3 {
  font-weight: 500;
  font-size: 32px;
  color: #fff;
  text-align: center;
}
.seventh-section .py-5 p {
  font-weight: 500;
  font-size: 24px;
  color: #fcaf17;
  text-align: center;
}
.seventh-section .py-5 .btn {
  background-color: #fcaf17;
  padding: 10px 20px;
  border-radius: 50px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}
.seventh-section .py-5 .btn:hover {
  color: #fcaf17 !important;
  background-color: transparent !important;
  border: 1px solid #fcaf17;
}

.eigth-section h3.eigth-title {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.eigth-section span {
  color: #fcaf17;
  padding: 0;
}
.eigth-section p {
  font-size: 17px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}
.eigth-section .borderright {
  border-right: 1px solid #fcaf17;
}
.eigth-section .bordertop {
  border-top: 1px solid #fcaf17;
}
.eigth-section .borderleft {
  border-left: 1px solid #fcaf17;
}
.eigth-section .head h3 {
  font-size: 20px;
  font-weight: 600;
  color: #212529;
}
.eigth-section .head p {
  font-size: 15px;
  font-weight: 500;
  color: #9c9c9c;
}

.tenth-section {
  background-color: #fff;
}
.tenth-section h3 {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.tenth-section p {
  font-size: 17px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}
.tenth-section span {
  color: #fcaf17;
}
.tenth-section .borderbottom {
  border-bottom: 2px solid #fcaf17;
}
.tenth-section .borderright {
  border-right: 2px solid #fcaf17;
}
.tenth-section .content h4 {
  font-size: 18px;
  font-weight: 700;
  color: #212529;
}
.tenth-section .content p.desc {
  font-size: 14px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}
.tenth-section .image1 {
  animation: upDown 2s infinite ease-in-out alternate;
}
@keyframes upDown {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-30px);
  }
}

/************ecommerce************/
.first-banner {
  background-image: url("../images/ecommerce/banner1.jpg"), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-blend-mode: overlay;
  background-position: center !important;
  background-size: cover !important;
  width: 100%;
  height: 550px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;
}
.first-banner h1.heading {
  font-weight: 600;
  font-size: 36px;
  color: #fff;
}
.first-banner p {
  font-size: 16px;
  margin: 1rem auto 2rem;
  color: #e9e9e9;
  font-weight: 500;
}
.first-banner span {
  padding: 0;
  font-weight: 600;
  font-size: 36px;
  color: #fcaf17 !important;
}
.first-banner .btn {
  background-color: #fcaf17;
  padding: 10px 20px;
  border-radius: 50px;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid #fcaf17;
  font-weight: 700;
}
.first-banner .btn:hover {
  background-color: transparent;
  border: 1px solid #fcaf17;
  color: #fcaf17 !important;
}
.first-banner .right-content .head-img-1 {
  z-index: 3;
  top: 10%;
  left: 20%;
}
.first-banner .right-content img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.first-banner .right-content .head-img-1::before {
  width: 80%;
  height: 95%;
  position: absolute;
  content: "";
  border: 3px dashed #e4a400;
  margin-left: -8%;
  margin-top: 30px;
  z-index: -1;
}
.first-banner .right-content .overlay1 {
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.first-banner .right-content .head-img-2 {
  z-index: 1;
  position: relative;
  left: -10%;
  top: -40%;
}
.first-banner .right-content .head-img-2::before {
  width: 80%;
  height: 95%;
  position: absolute;
  content: "";
  border: 3px dashed #e4a400;
  margin-left: 75px;
  margin-top: -15px;
  z-index: -1;
}
.first-banner .right-content .overlay2 {
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.second-banner .absolute_img1 {
  width: 75%;
}
.second-banner .absolute_img2 {
  position: absolute;
  bottom: -230px;
  right: 0;
  width: 75%;
}
.second-banner .content h1 {
  padding: 0;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: black;
  font-size: 36px;
}
.second-banner .content p {
  font-size: 17px;
  color: #212529;
  text-align: justify;
}
.second-banner .content span {
  padding: 0;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #fcaf17;
  font-size: 36px;
}

.third-banner .third-title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.third-banner span {
  font-size: 36px;
  color: #fcaf17;
  padding: 0;
}
.third-banner p {
  font-size: 17px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}
.third-banner ul {
  border-bottom: none;
  justify-content: center;
  margin: 1.5rem 0;
}
.third-banner a.nav-link.active {
  border: none;
  font-size: 1rem;
  background-color: #fcaf17;
  color: #ffffff !important;
  border-radius: 50px;
  font-weight: 600;
  padding: 10px 40px;
  text-align: center;
  margin: 10px;
}
.third-banner a.nav-link {
  border: none;
  font-size: 1rem;
  border-radius: 50px;
  background-color: #e8ebf1;
  color: #212121;
  font-weight: 600;
  padding: 10px 40px;
  text-align: center;
  margin: 10px;
}
.third-banner a.nav-link:hover {
  background-color: #fcaf17;
  color: #ffffff !important;
}
.third-banner .features {
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 2.5rem 2rem;
  text-align: center;
  margin: 1rem 0;
  min-height: 225px;
}
.third-banner img {
  width: 100%;
  height: 60px;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: 0.6s;
}
.third-banner img:hover {
  transform: rotateY(180deg);
}
.third-banner h5 {
  font-weight: 600;
  font-size: 17.5px;
  line-height: 24px;
  color: #212529;
  font-family: "Open Sans", sans-serif;
}

.fourth-banner {
  background-color: #f9f9f9;
}
.fourth-banner h3 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.fourth-banner span {
  font-size: 36px;
  color: #fcaf17;
  padding: 0;
}
.fourth-banner .box {
  z-index: 1 !important;
  height: 100px;
  background-color: white;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
  padding: 34px 0px 0px 0px;
}
.fourth-banner .box .sub-head {
  height: 60px;
  transition: height 1s ease-in-out;
}
.fourth-banner .box .sub-head .heading {
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s;
  margin-bottom: 0;
  color: #212529;
}
.fourth-banner .box .box-description {
  opacity: 0;
  visibility: hidden;
  z-index: -10 !important;
}
.fourth-banner .box .box-description .description {
  font-weight: 500;
  font-size: 16px;
  color: #9c9c9c;
}
.fourth-banner .box:hover {
  height: 300px;
  transition: all 1s ease-in-out;
  z-index: 1 !important;
}
.fourth-banner .box:hover .box-description {
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-in-out;
  z-index: 1 !important;
}

.fifth-banner h3 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.fifth-banner span {
  color: #fcaf17;
  font-size: 36px;
}
.fifth-banner .content {
  min-height: 300px;
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.35);
  background-color: #fff;
  transition: all 0.3s;
  border-radius: 7px;
  padding: 0px 12px 15px 12px;
}
.fifth-banner .content h3 {
  background-color: #fcaf17;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  position: relative;
  top: -21px;
  margin-bottom: 0;
  padding: 5px 22px 5px 22px;
  border-radius: 5px;
  font-size: 24px;
}
.fifth-banner .content h4 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
}
.fifth-banner .content p {
  font-size: 15px;
  color: #212529;
  font-weight: 500;
  padding: 0px 12px;
}

.sixth-banner {
  background-color: #f9f9f9;
}
.sixth-banner h3 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.sixth-banner span {
  font-size: 36px;
  color: #fcaf17;
  padding: 0;
}
.sixth-banner .inner_content {
  margin-top: 30px;
}
.sixth-banner .inner_content img {
  width: 100%;
  height: 75px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-top: 6px;
}
.sixth-banner .inner_content h3 {
  font-size: 19px;
  font-weight: 600;
  color: #212529;
  line-height: 30px;
  margin: 0;
  margin-bottom: 13px;
}
.sixth-banner .inner_content p {
  font-size: 15px;
  font-weight: 500;
  color: #9c9c9c;
}

.seventh-banner h3 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1.2rem;
  color: #212529;
}
.seventh-banner span {
  font-size: 36px;
  color: #fcaf17;
  padding: 0;
}
.seventh-banner p {
  font-size: 17px;
  color: #212529;
}
.seventh-banner .slider {
  background-color: #f9f9f9;
  padding: 50px 70px;
  margin-top: 40px;
  border-radius: 10px;
}
.seventh-banner .slider .slide_content h4 {
  font-weight: 700;
  font-size: 20px;
  color: #4a4a4a;
}
.seventh-banner .slider .slide_content p {
  font-size: 16px;
  color: #5c5c5c;
}
.seventh-banner .slider img {
  width: 100%;
  height: 80px;
  -o-object-fit: contain;
     object-fit: contain;
  float: right;
  margin-right: -110px;
}
.seventh-banner .slider .left p {
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #5c5c5c;
}
.seventh-banner .slider .left p::before {
  content: url("../images/ecommerce/check.svg");
  padding-right: 15px;
  position: relative;
  top: 5px;
}
.seventh-banner .slider .right p {
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #5c5c5c;
}
.seventh-banner .slider .right p::before {
  content: url("../images/ecommerce/check.svg");
  padding-right: 15px;
  position: relative;
  top: 5px;
}

.eigth-banner {
  background-color: #f9f9f9;
}
.eigth-banner .query_help {
  gap: 15px;
}
.eigth-banner h4 {
  font-weight: 600;
  font-size: 22px;
  color: #212529;
}
.eigth-banner p {
  font-size: 1rem;
  line-height: 1.5;
  color: #5c5c5c;
}
.eigth-banner svg {
  width: 100%;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.eigth-banner span {
  border-left: 1px solid #212529;
  padding: 0 0px 0 18px;
}
.eigth-banner span h6 {
  font-size: 12px;
  opacity: 0.8;
  color: #212529;
  margin-bottom: 5px;
}
.eigth-banner span p {
  font-weight: 600;
  opacity: 1;
  margin: 0;
  font-size: 1.2rem;
  color: #fcaf17;
}
.eigth-banner ul {
  list-style-type: disc;
}
.eigth-banner ul h5 {
  font-size: 1.25rem;
  margin: 30px 0 0;
  font-weight: 500;
  color: #212529;
}
.eigth-banner ul li {
  font-weight: 500;
  opacity: 0.8;
  line-height: 26px;
  list-style: inside;
  color: #212529;
  font-size: 1rem;
}
.eigth-banner hr {
  width: 1px;
  height: 85%;
  background-color: #5c5c5c;
  margin-top: 35px;
  border: 0;
}
.eigth-banner .Q-form {
  background-color: #fff;
  border-radius: 10px !important;
  padding: 3rem;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
}
.eigth-banner .Q-form .left .form-control {
  box-shadow: none;
  border: 1px solid #ccc;
  color: #5c5c5c;
}

.ninth-banner h3 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 1.2rem;
  color: #212529;
}
.ninth-banner span {
  font-size: 36px;
  color: #fcaf17;
  padding: 0;
}
.ninth-banner p {
  font-size: 17px;
  color: #212529;
}
.ninth-banner .accordion-item {
  border-radius: 0.75rem;
  background-color: #fff;
  border-bottom: 0;
  position: relative;
  border-left: 0.25rem solid #fcaf17;
  box-shadow: 0rem 0rem 0.5rem rgba(35, 44, 59, 0.15);
}
.ninth-banner .accordion-item .accordion-button {
  box-shadow: none;
  background-color: #fff;
  font-weight: 700;
  font-size: 1.05rem;
  color: #212529;
}
.ninth-banner .accordion-item .accordion-collapse p {
  font-size: 17px;
  color: #212529;
}

.tenth-banner h3 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1.2rem;
  color: #212529;
}
.tenth-banner span {
  font-size: 36px;
  color: #fcaf17;
  padding: 0;
}
.tenth-banner p {
  font-size: 17px;
  color: #212529;
}

.eleventh-banner {
  background-color: #212529;
}
.eleventh-banner .py-5 h3 {
  font-weight: 500;
  font-size: 32px;
  color: #fff;
  text-align: center;
}
.eleventh-banner .py-5 p {
  font-weight: 500;
  font-size: 24px;
  color: #fcaf17;
  text-align: center;
}
.eleventh-banner .py-5 .btn {
  background-color: #fcaf17;
  padding: 10px 20px;
  border-radius: 50px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}
.eleventh-banner .py-5 .btn:hover {
  color: #fcaf17 !important;
  background-color: transparent !important;
  border: 1px solid #fcaf17;
}

/************grocery app************/
.gro_first-banner {
  background: url("../images/grocery/grobanner.jpg"), linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-blend-mode: overlay;
  background-position: center !important;
  background-size: cover !important;
  padding: 10% 0;
  height: 700px;
}
.gro_first-banner h1.heading {
  font-weight: 700;
  font-size: 32px;
  color: #fff;
}
.gro_first-banner span {
  font-weight: 700;
  font-size: 32px;
  color: #fcaf17 !important;
}
.gro_first-banner p {
  font-size: 15px;
  margin: 1rem auto 2rem;
  color: #e9e9e9;
  font-weight: 500;
}
.gro_first-banner .btn {
  background-color: #fcaf17;
  padding: 10px 20px;
  border-radius: 50px;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid #fcaf17;
  font-weight: 700;
}
.gro_first-banner .btn:hover {
  border: 2px solid #fcaf17;
  color: #fcaf17 !important;
  background-color: transparent;
}
.gro_first-banner .left-content .head-img-1 {
  position: relative;
  top: -20%;
  transition: all 0.5s;
}
.gro_first-banner .left-content .head-img-2 {
  position: relative;
  top: -8%;
  transition: all 0.5s;
}
.gro_first-banner .left-content .head-img-1:hover {
  margin-top: -10%;
}
.gro_first-banner .left-content .head-img-2:hover {
  margin-top: -10%;
}
.gro_first-banner .left-content img {
  width: 100%;
  height: 500px;
  -o-object-fit: contain;
     object-fit: contain;
}

.gro_second-banner h1 {
  padding: 0;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: black;
  font-size: 36px;
}
.gro_second-banner span {
  font-weight: 600;
  font-size: 36px;
  color: #fcaf17 !important;
}
.gro_second-banner p {
  font-size: 17px;
  color: #212529;
  text-align: justify;
}
.gro_second-banner img {
  width: 100%;
  height: 450px;
  -o-object-fit: contain;
     object-fit: contain;
}

.gro_third-banner .third-title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.gro_third-banner span {
  font-size: 36px;
  color: #fcaf17;
  padding: 0;
  font-weight: 600;
}
.gro_third-banner p {
  font-size: 17px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}
.gro_third-banner ul {
  border-bottom: none;
  justify-content: center;
  margin: 1.5rem 0;
}
.gro_third-banner a.nav-link.active {
  border: none;
  font-size: 1rem;
  background-color: #fcaf17;
  color: #ffffff !important;
  border-radius: 50px;
  font-weight: 600;
  padding: 10px 40px;
  text-align: center;
  margin: 10px;
}
.gro_third-banner a.nav-link {
  border: none;
  font-size: 1rem;
  border-radius: 50px;
  background-color: #e8ebf1;
  color: #212121;
  font-weight: 600;
  padding: 10px 40px;
  text-align: center;
  margin: 10px;
}
.gro_third-banner a.nav-link:hover {
  background-color: #fcaf17;
  color: #ffffff !important;
}
.gro_third-banner .features {
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 2.5rem 2rem;
  text-align: center;
  margin: 1rem 0;
  min-height: 225px;
}
.gro_third-banner img {
  width: 100%;
  height: 60px;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: 0.6s;
}
.gro_third-banner img:hover {
  transform: rotateY(180deg);
}
.gro_third-banner h5 {
  font-weight: 600;
  font-size: 17.5px;
  line-height: 24px;
  color: #212529;
  font-family: "Open Sans", sans-serif;
}

.gro_fourth-banner h1.heading {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.gro_fourth-banner span {
  font-size: 36px;
  color: #fcaf17;
  padding: 0;
  font-weight: 600;
}
.gro_fourth-banner p {
  font-size: 17px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}
.gro_fourth-banner .head h3 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 0;
  color: #212529;
}
.gro_fourth-banner .head p {
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  color: #9c9c9c;
}
.gro_fourth-banner .borderright {
  border-right: 2px solid #efefef;
}
.gro_fourth-banner .bordertop {
  border-top: 2px solid #efefef;
}

.gro_fifth-banner h1.heading {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.gro_fifth-banner span {
  font-size: 36px;
  color: #fcaf17;
  padding: 0;
  font-weight: 600;
}
.gro_fifth-banner p {
  font-size: 17px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}
.gro_fifth-banner .modal-content1 {
  border-radius: 10px;
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
}
.gro_fifth-banner .modal-content1 .business-content .img-fluid {
  width: 50px;
  height: 50px;
}
.gro_fifth-banner .modal-content1 .business-content h4 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 20px;
  color: #000;
}
.gro_fifth-banner .modal-content1 .business-content p {
  font-size: 16px;
  font-weight: 500;
  color: #212529;
}
.gro_fifth-banner .modal-content1:hover {
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07), 0 30.1471px 24.1177px rgba(0, 0, 0, 0.0456112), 0 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0 4.5288px 3.62304px rgba(0, 0, 0, 0.02438);
}
.gro_fifth-banner .business_img-box .img-fluid {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}

.gro_sixth-banner h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.gro_sixth-banner span {
  font-size: 36px;
  color: #fcaf17;
  padding: 0;
  font-weight: 600;
}
.gro_sixth-banner p {
  font-size: 17px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}

.gro_seventh-banner h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #212529;
}
.gro_seventh-banner span {
  font-size: 36px;
  color: #fcaf17;
  padding: 0;
  font-weight: 600;
}
.gro_seventh-banner p {
  font-size: 17px;
  margin: auto;
  font-weight: 400;
  color: #212529;
}
.gro_seventh-banner .accordion-item {
  border-radius: 0.75rem;
  background-color: #fff;
  border-bottom: 0;
  position: relative;
  border-left: 0.25rem solid #fcaf17;
  box-shadow: 0rem 0rem 0.5rem rgba(35, 44, 59, 0.15);
}
.gro_seventh-banner .accordion-item .accordion-button {
  box-shadow: none;
  background-color: #fff;
  font-weight: 500;
  font-size: 16px;
  color: #212529 !important;
}
.gro_seventh-banner .accordion-item .accordion-collapse p {
  font-size: 16px;
  color: #212529;
}

.gro_eigth-banner {
  background-color: #212529;
}
.gro_eigth-banner .py-5 h3 {
  font-weight: 500;
  font-size: 32px;
  color: #fff;
  text-align: center;
}
.gro_eigth-banner .py-5 p {
  font-weight: 500;
  font-size: 24px;
  color: #fcaf17;
  text-align: center;
}
.gro_eigth-banner .py-5 .btn {
  background-color: #fcaf17;
  padding: 10px 20px;
  border-radius: 50px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}
.gro_eigth-banner .py-5 .btn:hover {
  color: #fcaf17 !important;
  background-color: transparent;
  border: 1px solid #fcaf17;
}

.menu-item {
  display: inline-block;
  background-color: #fff;
  position: relative;
}
.menu-item a {
  text-decoration: none;
  color: #000;
  display: block;
}
.menu-item:hover .drop-menu {
  display: block;
}

.drop-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.drop-menu-item {
  width: 100%;
}
.drop-menu-item a {
  color: #555;
  font-size: 14px;
  padding: 0px;
}
.drop-menu-item:hover {
  background-color: #eee;
}

.travel-app-header .navbar {
  background-color: rgba(238, 238, 238, 0.9333333333);
  color: #000;
}

.about-heading .head {
  font-size: 40px;
  text-transform: uppercase;
  color: #020202;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
  margin-bottom: 25px;
}
.about-heading .head span {
  color: rgb(252, 175, 23);
}
.about-heading .desc {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
}

.about-content .main-heading {
  font-size: 26px;
  font-weight: 600;
  color: #020202;
}
.about-content .main-heading span {
  color: rgb(252, 175, 23);
}
.about-content .desc {
  font-size: 15px;
}
.about-content .title {
  font-size: 45px;
  color: #020202;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}
.about-content .sub-heading {
  position: relative;
  color: rgb(252, 175, 23);
  font-size: 16px;
  font-weight: 600;
}
.about-content .sub-heading::before {
  content: "";
  position: absolute;
  background-color: rgb(252, 175, 23);
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
}

.about-button {
  margin-top: 20px;
}
.about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 8px 25px;
  color: #ffffff;
  font-size: 14px;
}
.about-button .read-more-button:hover {
  color: white !important;
}

.features-head h2 {
  font-size: 32px;
  font-weight: 600;
  color: #020202;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}
.features-head h2 span {
  color: rgb(252, 175, 23);
}
.features-head .desc {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  line-height: 20px;
  margin: 0 auto;
  text-align: center;
}

.features-tab-pane .nav-tabs .nav-item button {
  background-color: #e8ebf1;
  border-radius: 50px;
  color: #020202;
  padding: 10px 42px;
  margin: 0px 20px;
  font-weight: 600;
}

.features-tab-pane .nav-tabs .nav-item button:hover {
  background-color: rgb(252, 175, 23);
  color: #fff;
}

.features-tab-pane .tab-content {
  margin-top: 20px;
}

.features-tab-pane .nav-tabs {
  border-color: transparent !important;
}

.features-tab-pane .nav-tabs .nav-link.active {
  border-color: transparent;
  background-color: rgb(252, 175, 23);
  color: #fff;
}

.features-card {
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
  background-color: white;
  padding: 32px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s linear;
  min-height: 225px;
  margin-bottom: 25px;
}

.features-img {
  text-align: center;
}

.features-img img {
  width: 55px;
  height: 55px;
  margin-bottom: 15px;
  text-align: center;
}

.features-img svg {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  text-align: center;
  color: rgb(252, 175, 23);
}

.features-card h5 {
  color: #020202;
  font-weight: 400;
  padding: 10px;
  margin-top: 10px;
}

.features-card:hover img {
  transform: rotateY(360deg);
  transition: all 0.7s linear;
}

.how-it-works-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.how-it-works-img img {
  width: 75%;
}

.works-list {
  display: flex;
  align-items: center;
  min-height: 130px;
}
.works-list li {
  margin-right: 5px;
  margin-bottom: 10px;
}
.works-list li .inner-works-list h5 {
  font-size: 20px;
  color: #020202;
  font-family: "Poppins", sans-serif;
}
.works-list li .inner-works-list li .desc {
  font-size: 14px;
}
.works-list .circle-no {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-color: #020202;
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  color: white;
}

.works-list-1 {
  display: flex;
  align-items: center;
  min-height: 130px;
}
.works-list-1 li {
  margin-left: 15px;
}
.works-list-1 li .inner-works-list h5 {
  font-size: 20px;
  color: #020202;
  font-family: "Poppins", sans-serif;
}
.works-list-1 li .inner-works-list li .desc {
  font-size: 14px;
}
.works-list-1 .circle-no {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-color: rgb(252, 175, 23);
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  color: white;
}

.how-it-works-main {
  display: flex;
  align-items: center;
}

.portfolio-area {
  background-color: #f5e9ce;
  width: 100%;
  position: relative;
}

.portfolio-left-area {
  background-color: rgb(215, 164, 64);
  width: 40.6%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 85px;
}
.portfolio-left-area h4 {
  color: white;
  font-size: 40px;
  font-style: italic;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.portfolio-left-area .head-part {
  font-size: 75px;
  text-align: center;
  color: white;
  font-weight: 700;
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: inherit;
}
.portfolio-left-area .sub-heading-area {
  margin-bottom: 30px;
}
.portfolio-left-area .quote {
  position: absolute;
  bottom: 15%;
  left: 12%;
  color: white;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}
.portfolio-left-area .connect {
  position: absolute;
  top: 33%;
  left: 10%;
  right: 5%;
}
.portfolio-left-area .connect .desc {
  color: white;
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
}

.faq-area {
  background-color: #fff9ee;
}

.faq-accordion .accordion-button:not(.collapsed) {
  color: #020202 !important;
  background-color: rgb(247, 247, 247) !important;
  box-shadow: none !important;
}

.faq-accordion .accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: none !important;
}

.faq-accordion .accordion-button {
  color: #020202 !important;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 16px;
}

.faq-accordion .accordion-header {
  position: relative;
}

.faq-accordion .accordion-header svg {
  content: "";
  position: absolute;
  right: 2%;
  top: 35%;
}

.minus-icon {
  display: none;
}

.faq-accordion .accordion-item {
  border: transparent !important;
}

.faq-accordion .accordion-item .accordion-header {
  background-color: #ffffff !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 20px;
  font-size: 16px;
}

.faq-accordion .accordion-button:not(.collapsed) {
  background-color: white !important;
}

.faq-accordion .accordion-button::after {
  background-image: none !important;
}

.faq-accordion .accordion-button {
  background-image: none !important;
}

.faq-accordion .accordion-body {
  background-color: rgb(252, 175, 23);
  color: white;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
}

.faq-accordion .accordion-button {
  border-left: 5px solid rgb(252, 175, 23) !important;
  height: 60px;
}

.faq-accordion .accordion-button:not(.collapsed) .minus-icon {
  display: block;
}

.faq-accordion .accordion-button:not(.collapsed) .plus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .minus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .plus-icon {
  display: block;
}

.padding-0 {
  padding: 0px !important;
}

.banner-sec {
  position: relative;
  z-index: 1;
}
.banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
}

.travel-banner-content {
  position: absolute;
  top: 45%;
  left: 10%;
  z-index: 1;
}
.travel-banner-content h2 {
  color: white;
  font-size: 45px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0px;
}
.travel-banner-content .desc {
  font-family: 18px;
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-top: 15px;
}

.desc-button {
  margin: 20px 0px;
}

.req-demo {
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 600;
}
.req-demo:hover {
  background-color: rgb(252, 175, 23);
  border-color: transparent;
  color: white !important;
}

.travel-choose-card {
  position: relative;
  padding: 25px 15px;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  margin-bottom: 25px;
  min-height: 176px;
}
.travel-choose-card .heading-part {
  position: absolute;
  top: -10px;
  background-color: white;
  left: 30px;
}
.travel-choose-card .heading-part h4 {
  color: #020202;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  background: white;
}
.travel-choose-card .choose-content .desc {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 24px;
}

.explore-more-button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  padding: 15px 42px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
}
.explore-more-button:hover {
  background-color: #fff2d9;
  border-color: transparent;
}

.py-40 {
  padding: 40px 0px;
}

.job-banner-sec {
  position: relative;
}
.job-banner-sec .job-banner-content {
  position: absolute;
  top: 30%;
  left: 12%;
}
.job-banner-sec .job-banner-content h1 {
  color: white;
  font-size: 32px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 25px;
}
.job-banner-sec .job-banner-content h1 span {
  display: block;
  font-size: 44px;
  font-family: "Poppins", sans-serif;
}
.job-banner-sec .job-banner-content .desc {
  font-size: 18px;
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 35px;
}
.job-banner-sec .job-banner-content .get-demo {
  position: relative;
  color: white;
  padding: 12px 30px;
  border-radius: 30px;
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  font-size: 18px;
  font-weight: 500;
}
.job-banner-sec .job-banner-content .get-demo::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  animation: pulse-animation 2s infinite;
  transition: all 0.5s;
  box-shadow: 0 0 0 rgb(252, 175, 23);
}
.job-banner-sec .job-banner-content .get-demo:hover {
  color: white !important;
  background-color: transparent;
  border-color: white;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgb(252, 175, 23);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
.job-portal-services-head {
  text-align: center;
}
.job-portal-services-head h2 {
  font-size: 32px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.job-portal-services-head h2 span {
  color: rgb(252, 175, 23);
}
.job-portal-services-head .desc {
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  width: 80%;
  margin: 0 auto;
}

.job-services-card {
  position: relative;
  background: #fbfbfb;
  border: 1px solid #ededed;
  padding: 20px;
  margin-bottom: 30px;
  min-height: 278px;
  text-align: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.job-services-card:hover .uiux-black {
  display: none;
}
.job-services-card:hover .uiux-white {
  display: block;
  line-height: 50px;
  margin: 0 auto;
}
.job-services-card::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fcaf17;
  height: 2px;
  width: 0%;
}

.job-services-img {
  text-align: center;
  line-height: 90px;
  border-radius: 50%;
}
.job-services-img img {
  width: 50px;
  height: 50px;
}
.job-services-img .uiux-black {
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 0 auto;
}
.job-services-img .uiux-white {
  display: none;
  text-align: center;
}

.job-services-content h4 {
  margin-top: 15px;
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  color: #020202;
}
.job-services-content .desc {
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
}

.job-services-card:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.job-services-card:hover .job-services-img {
  width: 90px;
  height: 90px;
  background-color: #fcaf17;
  border-radius: 50%;
  color: white;
  line-height: 90px;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
}

.job-services-card:hover .job-services-img {
  width: 90px;
  height: 90px;
  background-color: #fcaf17;
  border-radius: 50%;
  color: white;
  transition: 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-services-card:hover .job-services-content h4 {
  color: #fcaf17;
}

.job-services-card:hover::after {
  width: 100%;
  transition: all 0.4s ease-in-out;
}

.on-demand-head-area .sub-head {
  font-size: 18px;
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 15px;
}

.on-demand-head-area .main-head {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
}

.pb-40 {
  padding-bottom: 40px;
}

.on-demand-my-tab {
  border-bottom: 1px solid #939393 !important;
}
.on-demand-my-tab:hover {
  border-bottom: 3px solid white !important;
}
.on-demand-my-tab .on-demand-tab-head {
  color: white;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
}

.admin-content-list-box {
  padding: 30px 0px 0px 30px;
}

.admin-content-list-box1 {
  padding: 30px 0px 0px 0px;
}

.admin-content-list li {
  color: white;
  line-height: 45px;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
}

.admin-content-list li svg {
  margin-right: 10px;
  font-size: 20px;
  color: white;
}

.job-features-tab-pane {
  position: relative;
}
.job-features-tab-pane .tab-content {
  margin-top: 20px;
}
.job-features-tab-pane .nav-tabs {
  border: none !important;
}
.job-features-tab-pane .co-features {
  position: absolute;
  left: 31.5%;
  top: 2.5%;
  z-index: 99999;
}
.job-features-tab-pane .co-features img {
  width: 24px;
  height: 24px;
}

.ad-features img {
  width: 24px;
  height: 24px;
}

.job-features-tab-pane .ad-features {
  position: absolute;
  left: 53%;
  top: 2.5%;
  z-index: 99999;
}

.job-features-tab-pane .nav-tabs .nav-item .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border-radius: 0px;
  background-color: #dcdcdc;
  color: #000;
  border: transparent;
}

.job-features-tab-pane .nav-tabs .nav-item .nav-link.active {
  background-color: #fcaf17 !important;
  color: #fff !important;
  border: transparent !important;
}

.job-features-tab-pane .nav-tabs .nav-item button {
  background-color: #dcdcdc;
  color: #000;
  margin-right: 5px;
  padding: 20px 50px;
  border: transparent;
}
.job-features-tab-pane .nav-tabs .nav-item button:hover {
  background-color: #fcaf17;
  color: #fff;
}

.job-p-features-card {
  position: relative;
  background: #fbfbfb;
  border: 1px solid #ededed;
  padding: 20px;
  margin-bottom: 30px;
  min-height: 260px;
  text-align: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.job-p-features-card .job-p-features-content h4 {
  margin-top: 15px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  color: #020202;
}
.job-p-features-card .job-p-features-content .desc {
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
}
.job-p-features-card .job-p-features-img {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;
}
.job-p-features-card .job-p-features-img img {
  width: 60px;
  height: 60px;
}
.job-p-features-card:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  background: #fff;
}
.job-p-features-card:hover .job-p-features-img {
  background: #fbfbfb;
  border: 1px solid #e4f2ff;
}
.job-p-features-card:hover .job-p-features-content h4 {
  color: #fcaf17;
}

.our-recent-works-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  margin-bottom: 25px;
}
.our-recent-works-card::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 21.88%, rgba(0, 0, 0, 0.7) 100%);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transform: translateY(114px);
  transition: all 0.3s ease;
}
.our-recent-works-card:hover img {
  transform: scale(1.05);
}
.our-recent-works-card:hover::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, #000 100%);
  transform: translateY(0);
}
.our-recent-works-card img {
  transform: scale(1);
  transition: all 0.3s ease;
  -o-object-fit: cover;
     object-fit: cover;
}
.our-recent-works-card:hover .our-recent-works-content {
  transform: translateY(0);
  transition: all 0.3s ease;
}
.our-recent-works-card .our-recent-works-content {
  position: absolute;
  bottom: 0%;
  left: 0%;
  z-index: 1;
  width: 100%;
  transform: translate(0px, 42px);
  padding: 20px;
}
.our-recent-works-card .our-recent-works-content h4 {
  color: white;
  margin-bottom: 0px;
}
.our-recent-works-card .our-recent-works-content p {
  color: white;
  margin-bottom: 20px;
}
.our-recent-works-card .our-recent-works-content a {
  color: #fcaf17;
  font-weight: 700;
}
.our-recent-works-card .our-recent-works-content a:hover {
  color: #fcaf17 !important;
}

.our-recent-works-head {
  text-align: center;
}
.our-recent-works-head h2 {
  font-size: 36px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.our-recent-works-head h2 span {
  color: rgb(252, 175, 23);
}
.our-recent-works-head .desc {
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  width: 80%;
  margin: 0 auto;
}

.recent-work-bottom {
  text-align: center;
}
.recent-work-bottom .desc {
  margin-bottom: 20px;
  font-size: 16px;
}
.recent-work-bottom .view-all-button {
  display: inline-block;
  background-color: #fcaf17;
  color: white;
  padding: 10px 30px;
  border-radius: 5px;
  font-weight: 600;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  transition: all 0.3s ease;
}
.recent-work-bottom .view-all-button:hover {
  transform: translateY(-10px);
  color: white !important;
}

.why-choose-j-p .why-choose-j-p-head {
  text-align: center;
}
.why-choose-j-p .why-choose-j-p-head h2 {
  font-size: 34px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.why-choose-j-p .why-choose-j-p-head h2 span {
  color: #fcaf17;
}
.why-choose-j-p .why-choose-j-p-head .desc {
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  width: 80%;
  margin: 0 auto;
  color: #939393;
}

.why-choose-jp-card {
  background-color: white;
  border: 1px solid #ededed;
  min-height: 210px;
  transition: 0.3s ease-out;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  padding: 25px;
  cursor: pointer;
}
.why-choose-jp-card::before {
  z-index: 2;
  background: #efd8ae;
  left: inherit;
  bottom: inherit;
  right: -18px;
  width: 37px;
  top: -18px;
  height: 37px !important;
  transform: rotate(45deg);
  content: "";
  position: absolute;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.why-choose-jp-card:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}
.why-choose-jp-card:hover::before {
  background-color: #fcaf17;
}
.why-choose-jp-card:hover .jp-head::before {
  width: 20%;
  transition: all 0.3s ease;
}
.why-choose-jp-card .jp-head {
  position: relative;
  color: #020202;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  padding-bottom: 10px;
}
.why-choose-jp-card .jp-head::before {
  position: absolute;
  content: "";
  background-color: #fcaf17;
  width: 0%;
  bottom: 0;
  left: 0;
  height: 2px;
  transition: all 0.3s ease;
}
.why-choose-jp-card .jp-head img {
  padding-right: 10px;
}
.why-choose-jp-card .desc {
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
}

.e-hiring-model .e-hiring-model-head {
  text-align: center;
  padding-bottom: 30px;
}
.e-hiring-model .e-hiring-model-head h2 {
  font-size: 36px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.e-hiring-model .e-hiring-model-head h2 span {
  color: #fcaf17;
}
.e-hiring-model .e-hiring-model-head .desc {
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  width: 80%;
  margin: 0 auto;
  color: #939393;
}

.e-hiring-model {
  padding: 40px 0px 90px 0px;
}
.e-hiring-model .e-hiring-model-head {
  text-align: center;
  text-align: center;
  padding-bottom: 30px;
}
.e-hiring-model .e-hiring-model-head h2 {
  font-size: 32px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.e-hiring-model .e-hiring-model-head h2 span {
  color: #fcaf17;
}
.e-hiring-model .e-hiring-model-head .desc {
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  width: 80%;
  margin: 0 auto;
  color: #939393;
}

.e-hiring-model-inner-list {
  display: flex;
  align-items: center;
}
.e-hiring-model-inner-list .inner-icon-content {
  margin-left: 20px;
}

.inner-icon-content p {
  font-size: 13px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}

.inner-icon-content h5 {
  font-size: 16px;
  margin-bottom: 0px;
  font-family: "Poppins", sans-serif;
  color: #fcaf17;
}

.inner-icon-img {
  text-align: center;
}
.inner-icon-img img {
  width: 50px;
  height: 50px;
}

.e-hiring-main-box {
  position: relative;
}

.e-hiring-model-list:nth-child(1) {
  content: "";
  position: absolute;
  left: 30%;
  top: 0%;
}
.e-hiring-model-list:nth-child(2) {
  content: "";
  position: absolute;
  left: 43%;
  top: 19%;
}
.e-hiring-model-list:nth-child(3) {
  content: "";
  position: absolute;
  left: 48%;
  top: 35%;
}
.e-hiring-model-list:nth-child(4) {
  content: "";
  position: absolute;
  left: 48%;
  top: 55%;
}
.e-hiring-model-list:nth-child(5) {
  content: "";
  position: absolute;
  left: 43%;
  bottom: 10%;
}
.e-hiring-model-list:nth-child(6) {
  content: "";
  position: absolute;
  left: 28%;
  bottom: -7%;
}

.discuss-specialists {
  position: relative;
}
.discuss-specialists::before {
  content: "";
  background-color: rgba(38, 38, 38, 0.95);
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  top: 0;
}

.discuss-specialists-content {
  z-index: 10;
  margin: 0 auto;
  padding: 25px 0px;
  text-align: center;
}
.discuss-specialists-content .title {
  color: white;
  font-size: 28px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.discuss-specialists-content .desc {
  font-size: 16px;
  color: white;
  text-align: center;
  margin: 30px 0px;
}
.discuss-specialists-content .lets-talk-button {
  display: inline-block;
  background-color: #fcaf17;
  color: white;
  padding: 12px 45px;
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Open Sans", sans-serif;
  border: 1px solid transparent;
}
.discuss-specialists-content .lets-talk-button:hover {
  background-color: transparent;
  color: white !important;
  border-color: white;
}

.global-recognition-head {
  text-align: center;
  margin: 0 auto;
}
.global-recognition-head h2 {
  font-size: 36px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.global-recognition-head h2 span {
  color: #fcaf17;
}
.global-recognition-head .desc {
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  width: 80%;
  margin: 0 auto;
  color: #939393;
}

.border-bot {
  border-bottom: 3px solid white !important;
}

.menu-item {
  display: inline-block;
  background-color: #fff;
  position: relative;
}

.menu-item a {
  text-decoration: none;
  color: #000;
  display: block;
}

.drop-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.drop-menu-item {
  width: 100%;
}

.drop-menu-item:hover {
  background-color: #eee;
}

.drop-menu-item a {
  color: #555;
  font-size: 14px;
  padding: 0px;
}

.menu-item:hover .drop-menu {
  display: block;
}

.travel-app-header .navbar {
  background-color: rgba(238, 238, 238, 0.9333333333);
  color: #000;
}

.about-heading .head {
  font-size: 40px;
  text-transform: uppercase;
  color: #020202;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
  margin-bottom: 25px;
}

.about-heading .desc {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
}

.about-heading .head span {
  color: rgb(252, 175, 23);
}

.about-content .title {
  font-size: 45px;
  color: #020202;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.about-content .sub-heading {
  position: relative;
  color: rgb(252, 175, 23);
  font-size: 16px;
  font-weight: 600;
}

.about-content .sub-heading::before {
  content: "";
  position: absolute;
  background-color: rgb(252, 175, 23);
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
}

.about-content .main-heading span {
  color: rgb(252, 175, 23);
}

.features-head h2 span {
  color: rgb(252, 175, 23);
}

.features-head .desc {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  line-height: 20px;
  margin: 0 auto;
  text-align: center;
}

.nav-tabs .nav-item button {
  background-color: #e8ebf1;
  border-radius: 50px;
  color: #020202;
  padding: 10px 42px;
  margin: 0px 20px;
  font-weight: 600;
}

.nav-tabs .nav-item button:hover {
  background-color: rgb(252, 175, 23);
  color: #fff;
}

.nav-tabs {
  border-color: transparent !important;
}

.nav-tabs .nav-link.active {
  border-color: transparent;
  background-color: rgb(252, 175, 23);
  color: #fff;
}

.features-card {
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
  background-color: white;
  padding: 32px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s linear;
  min-height: 225px;
  margin-bottom: 25px;
}

.features-img {
  text-align: center;
}

.features-img img {
  width: 55px;
  height: 55px;
  margin-bottom: 15px;
  text-align: center;
}

.features-img svg {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  text-align: center;
  color: rgb(252, 175, 23);
}

.features-card h5 {
  color: #020202;
  font-weight: 400;
  padding: 10px;
  margin-top: 10px;
}

.features-card:hover img {
  transform: rotateY(360deg);
  transition: all 0.7s linear;
}

.how-it-works-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.how-it-works-img img {
  width: 75%;
}

.works-list {
  display: flex;
  align-items: center;
  min-height: 130px;
}

.works-list-1 {
  display: flex;
  align-items: center;
  min-height: 130px;
}

.works-list li {
  margin-right: 5px;
  margin-bottom: 10px;
}

.works-list-1 li {
  margin-left: 15px;
}

.circle-no {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-color: #020202;
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  color: white;
}

.works-list-1 .circle-no {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-color: rgb(252, 175, 23);
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  color: white;
}

.works-list li .inner-works-list h5 {
  font-size: 20px;
  color: #020202;
  font-family: "Poppins", sans-serif;
}

.works-list-1 li .inner-works-list h5 {
  font-size: 20px;
  color: #020202;
  font-family: "Poppins", sans-serif;
}

.works-list .inner-works-list li .desc {
  font-size: 14px;
}

.works-list-1 .inner-works-list li .desc {
  font-size: 14px;
}

.how-it-works-main {
  display: flex;
  align-items: center;
}

.portfolio-area {
  background-color: #f5e9ce;
  width: 100%;
  position: relative;
}

.portfolio-left-area {
  background-color: rgb(215, 164, 64);
  width: 40.6%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 85px;
}

.portfolio-left-area h4 {
  color: white;
  font-size: 40px;
  font-style: italic;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.portfolio-left-area .head-part {
  font-size: 75px;
  text-align: center;
  color: white;
  font-weight: 700;
  background: -webkit-linear-gradient(#eee, #333);
}

.sub-heading-area {
  margin-bottom: 30px;
}

.main-heading-area {
  position: absolute;
  top: 40%;
  left: 10%;
}

.main-heading-area h1 {
  font-size: 75px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.main-heading-area .sub-head {
  font-size: 75px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
}

.quote {
  position: absolute;
  bottom: 15%;
  left: 12%;
  color: white;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}

.connect {
  position: absolute;
  top: 33%;
  left: 10%;
  right: 5%;
}

.connect .desc {
  color: white;
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
}

.faq-accordion .accordion-button:not(.collapsed) {
  color: #020202 !important;
  background-color: rgb(247, 247, 247) !important;
  box-shadow: none !important;
}

.faq-accordion .accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: none !important;
}

.faq-accordion .accordion-button {
  color: #020202 !important;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 16px;
}

.faq-accordion .accordion-header {
  position: relative;
}

.faq-accordion .accordion-header svg {
  content: "";
  position: absolute;
  right: 2%;
  top: 35%;
}

.minus-icon {
  display: none;
}

.faq-accordion .accordion-item {
  border: transparent !important;
}

.faq-accordion .accordion-item .accordion-header {
  background-color: #ffffff !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 20px;
}

.faq-accordion .accordion-button:not(.collapsed) {
  background-color: white !important;
}

.faq-accordion .accordion-button::after {
  background-image: none !important;
}

.faq-accordion .accordion-button {
  background-image: none !important;
}

.faq-accordion .accordion-body {
  background-color: rgb(252, 175, 23);
  color: white;
  margin-bottom: 15px;
  font-weight: 600;
}

.faq-accordion .accordion-button {
  border-left: 5px solid rgb(252, 175, 23) !important;
}

.faq-accordion .accordion-button:not(.collapsed) .minus-icon {
  display: block;
}

.faq-accordion .accordion-button:not(.collapsed) .plus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .minus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .plus-icon {
  display: block;
}

.padding-0 {
  padding: 0px !important;
}

.banner-sec {
  position: relative;
  z-index: 1;
}

.banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
}

.travel-banner-content {
  position: absolute;
  top: 45%;
  left: 5%;
  z-index: 1;
}

.travel-banner-content h2 {
  color: white;
  font-size: 34px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0px;
}

.req-demo {
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 600;
}

.req-demo:hover {
  background-color: rgb(252, 175, 23);
  border-color: transparent;
  color: white !important;
}

.travel-choose-card {
  position: relative;
  padding: 25px 15px;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  margin-bottom: 25px;
  min-height: 176px;
}

.travel-choose-card .heading-part {
  position: absolute;
  top: -10px;
  background-color: white;
  left: 30px;
}

.travel-choose-card .heading-part h4 {
  color: #020202;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  background: white;
}

.travel-choose-card .choose-content .desc {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 24px;
}

.explore-more-button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  padding: 15px 42px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
}

.explore-more-button:hover {
  background-color: #fff2d9;
  border-color: transparent;
}

.py-40 {
  padding: 40px 0px;
}

.bg_contact_pages {
  background-color: #f8fafe;
}
.bg_contact_pages .contact_left h4 {
  font-size: 25px;
  color: #3d3d3d;
  font-weight: 700;
}
.bg_contact_pages .contact_left p {
  margin-bottom: 40px;
  font-size: 16px;
}
.bg_contact_pages .contet-list {
  position: relative;
}
.bg_contact_pages .contet-list .list-blk {
  padding-bottom: 0px;
  margin-bottom: 15px;
}
.bg_contact_pages .contet-list .list-blk h4 {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.17px;
  margin-bottom: 10px;
  position: relative;
}
.bg_contact_pages .contet-list .list-blk h4::before {
  content: "";
  width: 18px;
  height: 18px;
  border: 3px solid rgb(255, 255, 255);
  background: #fcaf17;
  position: absolute;
  left: -34px;
  top: 2px;
  border-radius: 50%;
  z-index: 1;
}
.bg_contact_pages .contet-list .list-blk::after {
  content: "";
  position: absolute;
  width: 2px;
  background: #fcaf17;
  left: -26px;
  top: 6px;
  height: 100%;
}
.bg_contact_pages .contet-list .list-blk:last-child {
  padding-bottom: 0;
  height: unset;
}
.bg_contact_pages .contact_heading {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 70px;
}
.bg_contact_pages .contact_formquery h6 {
  padding-bottom: 15px;
}
.bg_contact_pages .bg_contact_pages {
  max-width: calc(50vw + 282px);
  flex: 1 1 999px;
  width: 100%;
  position: relative;
  padding: 80px 120px 103px 140px;
  background: rgb(243, 245, 255);
}
.bg_contact_pages .contact-form-part {
  position: relative;
  padding: 80px 120px 103px 140px;
  background: rgb(243, 245, 255);
  border-radius: 10px;
}
.bg_contact_pages .contact-form-part::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(225, 229, 254);
  top: -20px;
  right: -20px;
  z-index: -1;
}

.inputcontrol .form-control.frmcrto {
  height: 55px;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: none !important;
}
.inputcontrol .form-control {
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0.2);
}

.btn.btn-yellow {
  background-color: #fcaf17;
  padding: 9px 29px;
  color: #fff;
  border-radius: 3px;
  font-size: 19px;
  transition: all 0.5s;
}
.btn.btn-yellow:hover {
  background-color: #000;
  transition: all 0.5s;
}

.befoer_borer {
  position: relative;
}
.befoer_borer::before {
  content: "";
  position: absolute;
  top: 31px;
  left: 29px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 43px;
}

.our-clients {
  overflow: hidden;
  width: 100%;
}

.mobile_accroid .accordion {
  border-radius: 0;
}
.mobile_accroid .accordion-button.collapsed {
  padding: 12px 10px !important;
  border-radius: 0 !important;
  color: #989898;
}
.mobile_accroid .accordion-item {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0 !important;
}
.mobile_accroid .accordion-button:focus {
  box-shadow: none;
  background-color: #fff !important;
  color: #000;
}
.mobile_accroid .accordion-button {
  padding: 10px;
  background-color: #fff !important;
  color: #000;
}
.mobile_accroid .accordion-body {
  padding: 1px 0;
}
.mobile_accroid .accordion-body li {
  border-bottom: 1px solid #ccc;
}
.mobile_accroid .accordion-body li a {
  padding: 5px 10px;
  display: flex;
  gap: 10px;
  border-bottom: none;
}
.mobile_accroid .accordion-body li a span {
  font-size: 14px;
}
.mobile_accroid .accordion-body li .iconimg {
  width: 20px;
}
.mobile_accroid .accordion-body li:last-child {
  border-bottom: none;
}

.animate {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.one {
  animation-delay: 0.6s;
}

.one1 {
  animation-delay: 0.8s;
}

.one2 {
  animation-delay: 0.9s;
}

.fadeInUp {
  animation-name: fadeInUp;
}

.two {
  animation-delay: 0.5s;
}

.fadeInDown {
  animation-name: fadeInDown;
}

.four {
  animation-delay: 0.9s;
}

.fadeInRight {
  animation-name: fadeInRight;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.appdevelopemnt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 550px;
}
.appdevelopemnt .leftapp {
  flex: 1.2;
}
.appdevelopemnt .righttapp {
  flex: 1;
}
.appdevelopemnt::before {
  background-image: url("../images/topbg_img19.webp");
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  width: 35%;
  height: 100%;
  right: 0;
  z-index: -1;
  top: 0;
  background-position: center;
  background-size: cover;
}
.appdevelopemnt .contindevelopment {
  padding-left: 10pc;
}
.appdevelopemnt .contindevelopment h2 {
  font-size: 40px;
  color: #000;
  font-weight: 600;
  word-spacing: 6px;
}
.appdevelopemnt .contindevelopment h2 span {
  color: #fcaf17;
}
.appdevelopemnt .contindevelopment p {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  margin: 15px 0;
}
.appdevelopemnt .topimghand {
  position: absolute;
  bottom: 0;
}
.appdevelopemnt .topimghand img {
  width: 75%;
}

.btnall .btn-fix {
  background: transparent linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 50px;
  border: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.06);
  font-size: 22px;
  font-weight: 500;
  position: relative;
  white-space: nowrap;
  transition: 0.8s;
  height: 55px;
  padding: 5px 60px 5px 25px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.btnall .btn-fix:hover {
  background: #fff;
  padding: 5px 25px 5px 60px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.btnall .btn-fix svg {
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  transition: 0.5s;
  right: 10px;
  color: #000;
}
.btnall .btn-fix:hover svg {
  background: transparent linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%) 0% 0% no-repeat padding-box;
  right: calc(100% - 50px);
}

.fluidpadding10 .container-fluid {
  padding: 0px 10pc;
}

.developmentsecib .cardapp {
  padding: 40px 30px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.developmentsecib .cardapp h2 {
  color: #b4990d;
  opacity: 0.4;
  font-size: 45px;
}
.developmentsecib .cardapp h3 {
  font-size: 23px;
  line-height: normal;
  font-weight: 600;
  color: #000;
}
.developmentsecib .cardapp p {
  color: #000;
  line-height: 32px;
  margin-bottom: 25px;
}
.developmentsecib .cardapp img {
  width: 71%;
}
.developmentsecib .lightyeelow1 {
  background-color: rgba(239, 157, 16, 0.2588235294);
}
.developmentsecib .lightyeelow2 {
  background-color: rgba(196, 163, 90, 0.3411764706);
  min-height: 435px;
}
.developmentsecib .lightyeelow3 {
  background-color: #f4eacc;
}
.developmentsecib .lightyeelow4 {
  background-color: rgba(239, 157, 16, 0.2588235294);
}
.developmentsecib .lightyeelow5 {
  background-color: rgba(236, 193, 156, 0.6901960784);
  min-height: 97%;
}

.fntch_fnl_inn {
  background-color: #45494c;
  border-radius: 20px;
  padding: 15px 30px 15px 30px;
}
.fntch_fnl_inn .develmntser {
  margin-top: 40px;
}
.fntch_fnl_inn .develmntser h3 {
  font-size: 35px;
  font-weight: 600;
  color: white;
}
.fntch_fnl_inn .develmntser .btnall {
  margin-top: 5pc;
}
.fntch_fnl_inn .develmntser .btnall .btn-fix {
  background: #fff;
  color: #000;
}
.fntch_fnl_inn .develmntser .btnall .btn-fix svg {
  background: transparent linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%) 0% 0% no-repeat padding-box;
}
.fntch_fnl_inn .image {
  width: 100%;
  height: 300px;
  -o-object-fit: contain;
     object-fit: contain;
}

.fntch_end_flx li {
  border: 1px solid #d1d1d1;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 15px;
}
.fntch_end_flx li h3 {
  font-size: 23px;
  font-weight: 600;
}
.fntch_end_flx li p {
  font-size: 15px;
  color: #000;
}

.freaturesectionapp .imgae_dev {
  text-align: center;
}
.freaturesectionapp img {
  width: 70%;
  margin-top: 15%;
}
.freaturesectionapp li {
  padding: 18px;
  margin-bottom: 30px;
  border-radius: 20px;
  border: 1px solid #d1d1d1;
  transition: all 0.5s;
}
.freaturesectionapp li .fe_hd {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
.freaturesectionapp li .fe_hd svg {
  font-size: 35px;
  color: #fcaf17;
}
.freaturesectionapp li .fe_hd h2 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 600;
}
.freaturesectionapp li .fe_hd p {
  color: #000;
}
.freaturesectionapp li:hover {
  background: #fcaf17;
}
.freaturesectionapp li:hover svg,
.freaturesectionapp li:hover h2,
.freaturesectionapp li:hover p {
  color: #fff;
  transition: all 0.5s;
}

.premium_section .list_feature li {
  padding: 25px;
  margin-bottom: 30px;
  border-radius: 20px;
  border: 1px solid #d1d1d1;
  transition: all 0.5s;
  min-height: 200px;
}
.premium_section .list_feature li .fe_hd {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
.premium_section .list_feature li .fe_hd svg {
  font-size: 35px;
  color: #fcaf17;
}
.premium_section .list_feature li .fe_hd h2 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 600;
  margin-left: 15px;
}
.premium_section .list_feature li:hover {
  background: #fcaf17;
}
.premium_section .list_feature li:hover svg,
.premium_section .list_feature li:hover h2,
.premium_section .list_feature li:hover p {
  color: #fff;
  transition: all 0.5s;
}
.premium_section .list_feature li p {
  color: #000;
  font-size: 16px;
}

.Securitysectoin {
  background: #f5fbff url("../images/zd_curve_line.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  padding: 50px 0;
}
.Securitysectoin .premium_card {
  margin: 0 auto;
  background: #fff;
  box-shadow: 0px 3px 13px rgba(195, 213, 220, 0.16);
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  padding: 30px;
  min-height: 280px;
}

.shapeverticaldots .shapimg {
  position: absolute;
  top: 0;
  left: 10%;
  width: 8%;
}
.shapeverticaldots h3 {
  font-size: 36px !important;
  font-weight: 600 !important;
  list-style: 40px !important;
}
.shapeverticaldots .centerflex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.shapeverticaldots .develmntser {
  position: relative;
  padding-left: 20px;
}
.shapeverticaldots .develmntser::before {
  position: absolute;
  content: "";
  top: 10px;
  height: 100%;
  width: 4px;
  background-color: #fff;
  left: 0;
}
.shapeverticaldots .btnall .btn-fix {
  background: #fff;
  color: #000;
}

.discovery_section {
  background-image: url("../images/backimg2.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  padding: 30px 0 30px 0;
}
.discovery_section .content-card {
  position: relative;
  padding: 20px;
  transition: all 0.15s ease-in-out;
  margin-bottom: 20px;
  min-height: 200px;
}
.discovery_section .content-card .image {
  text-align: center;
  margin-bottom: 20px;
}
.discovery_section .content-card .image img {
  width: 80px;
  height: 80px;
  animation: mover 1s infinite alternate;
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
.discovery_section .content-card h4 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.discovery_section .content-card .content {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(94, 92, 87, 0.7098039216);
  text-align: center;
  z-index: 1;
  width: 100%;
  border-radius: 20px;
  height: 100%;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
}
.discovery_section .content-card .content p {
  color: white;
  font-size: 15px;
  opacity: 0;
  transform: translateY(0.625rem);
  transition: all 0.125s ease-in-out;
  line-height: 25px;
  font-weight: 500;
}

.content-card:hover .content {
  opacity: 1;
  visibility: visible;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.content-card:hover .content p {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-in-out 0.1s;
}

.chosse_cardsection .col-auto {
  width: 20%;
}
.chosse_cardsection .chosse_card {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding: 25px;
  margin: 0 auto;
  min-height: 150px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}
.chosse_cardsection .chosse_card img {
  width: 50px;
  height: 50px;
}
.chosse_cardsection .chosse_card h3 {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.chosse_cardsection .chosse_card p {
  font-size: 15px;
  font-weight: 500;
}

.ourPortfolio .portfolio_card {
  background-color: #e2e7ff;
  padding: 40px 35px;
  border-radius: 20px;
  margin-bottom: 50px;
}
.ourPortfolio .portfolio_cardimg_content h2 {
  font-size: 45px;
  font-weight: 700;
}
.ourPortfolio .portfolio_cardimg_content p {
  font-size: 20px;
  margin: 30px 0;
  color: #000;
  font-weight: 500;
}
.ourPortfolio .otr_lnks_port .btn-casestudy {
  border-radius: 10px;
  padding: 10px;
  border: 2px solid #000000;
  color: #000000;
  font-weight: 700;
  display: none;
}
.ourPortfolio .otr_lnks_port .btn-casestudy:hover {
  background-color: #fcaf17;
  color: #fff !important;
  border-color: #fcaf17;
}
.ourPortfolio .otr_lnks_port .btn-group img {
  margin-right: 10px;
  width: 145px;
}
.ourPortfolio .otr_lnks_port svg {
  font-size: 25px;
}

.healthcare-banner-sec {
  position: relative;
}
.healthcare-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.healthcare-banner-sec .healthcare-banner-content {
  position: absolute;
  top: 25%;
  left: 5%;
  z-index: 2;
}
.healthcare-banner-sec .healthcare-banner-content .main-heading {
  color: white;
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
  font-weight: 600;
}
.healthcare-banner-sec .healthcare-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.healthcare-banner-sec .healthcare-banner-content .transform-button {
  color: white;
  background-color: #fcaf17;
  border: 1px solid #fcaf17;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.healthcare-banner-sec .healthcare-banner-content .transform-button:hover {
  border: 1px solid white;
  background: transparent;
  color: white !important;
}

.health-about-us {
  background-color: #f9f9f9;
}

.health-about-content .main-heading {
  font-size: 31px;
  font-weight: 600;
  color: #020202;
}
.health-about-content .main-heading span {
  color: rgb(252, 175, 23);
}
.health-about-content .desc {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.health-about-content .h-about-button {
  margin-top: 20px;
}
.health-about-content .h-about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 8px 25px;
  color: #ffffff;
  font-size: 14px;
}
.health-about-content .h-about-button .read-more-button:hover {
  color: white !important;
}

.health-app-development-sec {
  background-color: #f9f9f9 !important;
}

.health-app-develop-content .sub-heading {
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
.health-app-develop-content .main-heading {
  font-size: 32px;
  font-family: "Poppins", sans-serif;
}
.health-app-develop-content .main-heading span {
  color: #fcaf17;
}
.health-app-develop-content .desc {
  font-size: 17px;
  font-family: "Open Sans", sans-serif;
}

.health-app-development-card {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 25px;
  min-height: 394px;
}

.health-app-development-icon {
  margin-bottom: 15px;
}
.health-app-development-icon img {
  width: 50px;
  height: 50px;
}

.health-app-development-content {
  padding: 10px;
}
.health-app-development-content .desc {
  margin-bottom: 10px;
}

.health-app-development-list li {
  position: relative;
  font-size: 16px;
  padding-left: 20px;
  font-family: "Open Sans", sans-serif;
  color: #51555c;
  font-weight: 600;
}

.health-app-development-list li::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #fcaf17;
  top: 5px;
  left: 0;
  transform: translate(0px, 4px);
}

.growth-banner {
  background-color: #020202;
  position: relative;
}

.growth-banner-content {
  position: absolute;
  top: 35%;
  width: 38%;
}
.growth-banner-content .main-heading {
  font-size: 32px;
  color: white;
  margin-bottom: 35px;
  font-family: "Poppins", sans-serif;
  padding: 10px 0px;
  line-height: 45px;
  font-weight: 500;
}
.growth-banner-content .main-heading span {
  color: #fcaf17;
}
.growth-banner-content .free-consult-button {
  background-color: transparent;
  color: #fcaf17;
  border: 2px solid #fcaf17;
  border-radius: 5px;
  padding: 14px 40px;
  font-weight: 600;
}
.growth-banner-content .free-consult-button:hover {
  background-color: #fcaf17;
  color: white !important;
}

.healthcare-based-solutions-img img {
  padding: 20px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.healthcare-based-solutions-list-box {
  border: 1px solid #e5e5e5;
  padding: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 500px;
}

.health-based-solutions-list li {
  line-height: 50px;
  font-size: 18px;
}
.health-based-solutions-list li img {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.choose-us-because {
  background-color: #f9f9f9;
}

.health-choose-us-card {
  border: 1px solid rgba(212, 224, 237, 0.7);
  border: 1px solid #e5e5e5;
  padding: 25px;
  margin-bottom: 25px;
  min-height: 312px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.health-choose-us-card:hover {
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.health-choose-us-card .health-choose-us-content .title {
  color: #020202;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}
.health-choose-us-card .health-choose-us-content .desc {
  font-size: 15px;
  line-height: 25px;
  font-family: "Open Sans", sans-serif;
}
.health-choose-us-card .icon {
  margin-bottom: 20px;
}

.health-choose-us-main-box .free-consult-button {
  background-color: transparent;
  color: #fcaf17;
  border: 2px solid #fcaf17;
  border-radius: 5px;
  padding: 14px 40px;
  font-weight: 600;
}
.health-choose-us-main-box .free-consult-button:hover {
  background-color: #fcaf17;
  color: white !important;
}

.hexagon-main-img {
  position: relative;
}
.hexagon-main-img .growth-banner-img-content {
  position: absolute;
  top: 27%;
  left: 15%;
  text-align: center;
  width: 60%;
}
.hexagon-main-img .growth-banner-img-content img {
  width: 50px;
  height: 50px;
}
.hexagon-main-img .growth-banner-img-content .desc {
  font-size: 14px;
  color: white;
  line-height: 25px;
  margin-top: 10px;
}

.healthcare-client-testimonials {
  background-color: #f9f9f9;
}

.erp-banner-sec {
  position: relative;
}
.erp-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.erp-banner-sec .erp-banner-content {
  position: absolute;
  top: 22%;
  left: 5%;
  z-index: 2;
}
.erp-banner-sec .erp-banner-content h1 {
  color: white;
  font-size: 34px;
  margin-bottom: 10px;
  font-weight: 600;
}
.erp-banner-sec .erp-banner-content p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
  color: white;
}
.erp-banner-sec .erp-banner-content .blue-theme-button {
  background-color: #fcaf17;
  color: white;
  padding: 10px 30px;
  font-family: "Open Sans", sans-serif;
  border: 2px solid #fcaf17;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  display: inline-block;
  border-radius: 5px;
}
.erp-banner-sec .erp-banner-content .blue-theme-button:hover {
  color: white !important;
  transform: translateY(-10px);
}
.erp-banner-sec .erp-banner-content .erp-banner-content-list {
  margin-bottom: 20px;
}
.erp-banner-sec .erp-banner-content .erp-banner-content-list .erp-banner-list li {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  color: white;
  font-weight: 600;
  position: relative;
  padding-left: 20px;
  line-height: 30px;
}
.erp-banner-sec .erp-banner-content .erp-banner-content-list .erp-banner-list li::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #fcaf17;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  transform: translate(0px, 10px);
}

.erp-development-services-head {
  text-align: center;
}
.erp-development-services-head .main-heading-type {
  color: #020202;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 20px;
}
.erp-development-services-head .main-heading-type span {
  color: rgb(252, 175, 23);
}
.erp-development-services-head .sub-heading {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.erp-development-services-head .sub-heading span {
  color: rgb(252, 175, 23);
}

.erp-development-services-card {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ededed;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  margin-bottom: 40px;
  min-height: 220px;
  position: relative;
}
.erp-development-services-card .erp-development-services-content {
  padding-top: 20px;
  text-align: center;
}
.erp-development-services-card .erp-development-services-icon {
  background-color: white;
  border: 1px solid #fcaf17;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  margin-bottom: 15px;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  margin: auto;
}
.erp-development-services-card .erp-development-services-icon img {
  width: 35px;
  height: 35px;
}
.erp-development-services-card::after {
  position: absolute;
  content: "";
  background-color: #fcaf17;
  height: 2px;
  width: 0%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
}
.erp-development-services-card:hover {
  background-color: #fbfbfb;
  transform: translateY(-10px);
}
.erp-development-services-card:hover::after {
  width: 100%;
}
.erp-development-services-card:hover h4 {
  color: #fcaf17;
}
.erp-development-services-card h4 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}
.erp-development-services-card .desc {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.why-choose-erp-card {
  position: relative;
  border-right: 1px solid #fcaf17;
  padding: 20px;
}

.d-f-a-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-bottom-m {
  border-bottom: 1px solid #fcaf17;
}

.border-right-0 {
  border-right: 0px;
}

.why-choose-erp-icon {
  margin-bottom: 15px;
}
.why-choose-erp-icon img {
  width: 45px;
  height: 45px;
}

.why-choose-erp-content h4 {
  font-size: 16px;
}
.why-choose-erp-content .desc {
  font-size: 13px;
  line-height: 20px;
}

.software-development-item {
  position: relative;
  padding: 50px 0px;
}
.software-development-item span {
  position: absolute;
  top: 35px;
  content: "";
  font-size: 80px;
  color: rgba(110, 110, 110, 0.11);
  font-weight: 800;
}
.software-development-item .software-development-content {
  margin-top: 20px;
}
.software-development-item .software-development-content .desc {
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 10px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.erp-achievements-sec .erp-achievements-head h2 {
  color: white;
  font-size: 38px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
.erp-achievements-sec .erp-achievements-card {
  text-align: center;
  margin-bottom: 15px;
}
.erp-achievements-sec .erp-achievements-card span {
  color: white;
  font-size: 50px;
  font-weight: 700;
}
.erp-achievements-sec .erp-achievements-card h4 {
  color: white;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin-top: 15px;
}
.erp-achievements-sec p {
  text-align: center;
  margin-top: 25px;
}
.erp-achievements-sec p .let-discuss-button {
  background-color: white;
  color: #020202;
  padding: 12px 32px;
  font-weight: 600;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.erp-achievements-sec p .let-discuss-button:hover {
  background-color: #fcaf17;
  color: white !important;
}

.erp-developed-modules-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  min-height: 170px;
  margin-bottom: 20px;
}
.erp-developed-modules-card:hover {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.erp-developed-modules-card .erp-developed-modules-content h4 {
  font-size: 18px;
}
.erp-developed-modules-card .erp-developed-modules-content .desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.explore-erp-services-content {
  padding-top: 50px;
}
.explore-erp-services-content .desc {
  margin-bottom: 20px;
  width: 80%;
  font-size: 15px;
}

.explore-erp-development-list li {
  margin-bottom: 15px;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
}
.explore-erp-development-list li img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.erp-developed-modules {
  background-color: #fff9ee;
}

.erp-about-us {
  background-color: #f9f9f9;
}
.erp-about-us .erp-about-img {
  margin-bottom: 25px;
}
.erp-about-us .erp-about-content .main-heading {
  font-size: 28px;
  font-weight: 600;
  color: #020202;
}
.erp-about-us .erp-about-content .main-heading span {
  color: rgb(252, 175, 23);
}
.erp-about-us .erp-about-content .desc {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.erp-about-us .erp-about-content .desc span {
  color: rgb(252, 175, 23);
}
.erp-about-us .erp-about-content .h-about-button {
  margin-top: 20px;
}
.erp-about-us .erp-about-content .h-about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 10px 30px;
  color: #ffffff;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
.erp-about-us .erp-about-content .h-about-button .read-more-button:hover {
  color: white !important;
  transform: translateY(-10px);
}

.crm-banner-sec {
  position: relative;
  z-index: 1;
}
.crm-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.crm-banner-sec .Crm-banner-content {
  position: absolute;
  z-index: 2;
  top: 40%;
  width: 40%;
  left: 7%;
}
.crm-banner-sec .Crm-banner-content .main-heading {
  color: white;
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 500;
}
.crm-banner-sec .Crm-banner-content .main-heading span {
  color: #fcaf17;
}
.crm-banner-sec .Crm-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.crm-banner-sec .Crm-banner-content .transform-button {
  color: white;
  background-color: #fcaf17;
  border: 1px solid #fcaf17;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.crm-banner-sec .Crm-banner-content .transform-button:hover {
  border: 1px solid white;
  background: transparent;
  color: white !important;
}

.about-img {
  margin-right: 20px;
}
.about-img img {
  min-height: 420px;
  margin-top: 10px;
}

.Crm-about-content {
  margin-top: 20px;
}
.Crm-about-content .main-heading {
  font-size: 26px;
  font-weight: 600;
  color: #020202;
}
.Crm-about-content .main-heading span {
  color: rgb(252, 175, 23);
}
.Crm-about-content .desc {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.Crm-about-content .h-about-button {
  margin-top: 20px;
}
.Crm-about-content .h-about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 8px 25px;
  color: #ffffff;
  font-size: 14px;
}
.Crm-about-content .h-about-button .read-more-button:hover {
  color: white !important;
}

.why-you-need-crm .Crm-app-development-content {
  text-align: center;
}
.why-you-need-crm .Crm-app-development-content .main-heading {
  font-size: 34px;
  position: relative;
}
.why-you-need-crm .Crm-app-development-content .main-heading span {
  color: rgb(252, 175, 23);
}
.why-you-need-crm .Crm-app-development-content .desc {
  color: black;
  font-size: 16px;
  width: 80%;
  margin: 0 auto;
}

.why-you-need-card {
  position: relative;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #fcaf17;
  min-height: 320px;
}
.why-you-need-card .why-you-need-icon {
  background-color: white;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  line-height: 65px;
  text-align: center;
  margin-bottom: 20px;
  border: 2px solid #fcaf17;
}
.why-you-need-card:hover .why-you-need-icon {
  border: 2px solid #e5e5e5;
}
.why-you-need-card .why-you-need-content h4 {
  font-size: 18px;
  font-weight: 600;
}
.why-you-need-card .why-you-need-content .desc {
  color: #020202;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
}

.crm-software-features .crm-software-features-head {
  text-align: center;
}
.crm-software-features .crm-software-features-head .main-heading {
  font-size: 34px;
  position: relative;
  padding-bottom: 15px;
}
.crm-software-features .crm-software-features-head .main-heading span {
  color: rgb(252, 175, 23);
}
.crm-software-features .crm-software-features-head .desc {
  color: black;
  font-size: 15px;
  width: 90%;
  margin: 0 auto;
}
.crm-software-features .crm-software-features-card {
  position: relative;
  padding: 20px;
  transition: all 0.15s ease-in-out;
  margin-bottom: 20px;
  min-height: 200px;
}
.crm-software-features .crm-software-features-card:hover .crm-software-features-content {
  opacity: 1;
  visibility: visible;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}
.crm-software-features .crm-software-features-card:hover .crm-software-features-content .desc {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-in-out 0.1s;
}
.crm-software-features .crm-software-features-card .title {
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}
.crm-software-features .crm-software-features-card .crm-software-features-icon {
  text-align: center;
  margin-bottom: 20px;
}
.crm-software-features .crm-software-features-card .crm-software-features-icon img {
  width: 80px;
  height: 80px;
}
.crm-software-features .crm-software-features-content {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5e5c57;
  text-align: center;
  z-index: 1;
  width: 100%;
  border-radius: 20px;
  height: 100%;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
}
.crm-software-features .crm-software-features-content .desc {
  color: white;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  opacity: 0;
  transform: translateY(0.625rem);
  transition: all 0.125s ease-in-out;
  line-height: 25px;
}

.crm-development-services-sec {
  background-color: #f4f4f4;
}
.crm-development-services-sec .col-xl-6:nth-child(2) {
  margin-top: 80px;
}
.crm-development-services-sec .col-xl-6:nth-child(3) {
  margin-top: -80px;
}

.crm-development-services-head .main-heading {
  color: #020202;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 20px;
}
.crm-development-services-head .main-heading span {
  color: #fcaf17;
}
.crm-development-services-head .desc {
  font-size: 15px;
  margin-bottom: 30px;
}

.crm-development-services-card {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  transition: all 400ms ease-in-out;
  padding: 15px;
  position: relative;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 25px;
  min-height: 355px;
}
.crm-development-services-card::before {
  content: "";
  position: absolute;
  transform: translateY(102%);
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fcaf17;
  transition: all 500ms ease-in-out;
  z-index: -1;
}
.crm-development-services-card:hover::before {
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
}
.crm-development-services-card .crm-development-services-icon img {
  width: 90px;
  height: 90px;
  margin: 0 auto;
  margin-bottom: 15px;
}
.crm-development-services-card:hover .crm-development-services-icon .img {
  display: none;
}
.crm-development-services-card:hover .crm-development-services-icon .imgw {
  display: block;
}
.crm-development-services-card .crm-development-services-icon .imgw {
  display: none;
  margin: 0 auto;
  margin-bottom: 15px;
}
.crm-development-services-card .crm-development-services-content {
  padding-top: 10px;
}
.crm-development-services-card .crm-development-services-content h4 {
  font-size: 19px;
  font-weight: 600;
}
.crm-development-services-card .crm-development-services-content .desc {
  font-size: 13px;
  line-height: 20px;
}
.crm-development-services-card:hover .crm-development-services-content {
  color: white;
}
.crm-development-services-card:hover .crm-development-services-content h4 {
  color: white;
}

.crm-software-solutions-sec .crm-development-services-head {
  text-align: center;
}
.crm-software-solutions-sec .crm-development-services-head .main-heading {
  font-size: 34px;
}
.crm-software-solutions-sec .crm-development-services-head .main-heading span {
  color: rgb(252, 175, 23);
}
.crm-software-solutions-sec .crm-development-services-head .desc {
  color: black;
  width: 85%;
  margin: 0 auto;
  font-size: 15px;
}

.industries-we-cater {
  background-color: #f4f4f4;
}
.industries-we-cater .industries-cater-head h2 {
  text-align: center;
  font-size: 34px;
  color: #fcaf17;
  font-weight: 600;
}
.industries-we-cater .industries-cater-card {
  text-align: center;
}
.industries-we-cater .industries-cater-card .industries-cater-icon {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  text-align: center;
  line-height: 75px;
  margin-bottom: 15px !important;
  margin: 0 auto;
}
.industries-we-cater .industries-cater-card .industries-cater-icon img {
  width: 45px;
  height: 45px;
}
.industries-we-cater .industries-cater-card .industries-cater-content h4 {
  font-size: 18px;
  font-weight: 600;
}

.why-work-with-us-content h3 {
  font-size: 30px;
}

.container-box {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.container-box .card {
  flex: 1;
  transition: all 1s ease-in-out;
  height: 55vmin;
  position: relative;
}
.container-box .card .desc {
  position: absolute;
  top: 60%;
  color: white;
  background: #fcaf17;
  margin: 0 auto;
  padding: 10px;
  font-size: 14px;
  left: 0;
  right: 0;
  width: 50%;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
}
.container-box .card .card__head {
  color: black;
  background: #fcaf17;
  padding: 0.5em;
  transform: rotate(-90deg);
  transform-origin: 0% 0%;
  transition: all 0.5s ease-in-out;
  min-width: 100%;
  text-align: center;
  position: absolute;
  bottom: 10%;
  left: 0;
  font-size: 1em;
  white-space: nowrap;
  font-weight: 700;
}
.container-box .card:hover {
  flex-grow: 10;
}
.container-box .card:hover img {
  filter: grayscale(0);
}
.container-box .card:hover .card__head {
  text-align: center;
  top: calc(100% - 2em);
  color: white;
  background: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  transform: rotate(0deg) skew(-5deg);
  height: 100%;
  font-weight: 600;
}
.container-box .card:hover .desc {
  opacity: 1;
  visibility: visible;
  transition: 0.2s;
}
.container-box .card img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: all 1s ease-in-out;
  filter: grayscale(100%);
}
.container-box .card:not(:nth-child(5)) {
  margin-right: 1em;
}

.fooerdmc {
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 20px;
  gap: 1px;
  z-index: 2;
}

.fooerdmc .btngreen {
  background-color: #35bbcb;
  padding: 5px;
  font-size: 12px;
  color: #fff !important;
  font-weight: 600;
}

.fooerdmc .btnblack {
  background-color: #000;
  padding: 5px;
  font-size: 12px;
  color: #fff !important;
  font-weight: 600;
}

.crm-software-development-card {
  border: 1px solid #fcaf17;
  padding: 25px;
  border-radius: 10px;
  transition: 0.75s;
  background-color: #fff;
}

.custom-crm-development-head .main-head {
  font-size: 34px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
}
.custom-crm-development-head .main-head span {
  color: #fcaf17;
}
.custom-crm-development-head .desc {
  width: 80%;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
}

.custom-crm-development-card-content {
  padding: 30px;
}
.custom-crm-development-card-content .main-head {
  font-size: 26px;
  color: #fcaf17;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}
.custom-crm-development-card-content .sub-heading {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
  font-weight: 600;
}
.custom-crm-development-card-content .desc {
  margin-bottom: 20px;
}
.custom-crm-development-card-content .btnall {
  margin-bottom: 30px;
}
.custom-crm-development-card-content .btnall .btn-fix {
  background: transparent linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%) 0% 0% no-repeat padding-box;
  color: #fff;
  border-radius: 50px;
  border: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.06);
  font-size: 16px;
  font-weight: 500;
  position: relative;
  white-space: nowrap;
  transition: 0.8s;
  height: 50px;
  padding: 5px 60px 5px 25px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.custom-crm-development-card-content .btnall .btn-fix:hover {
  background: #fff;
  padding: 5px 25px 5px 60px;
}
.custom-crm-development-card-content .btnall .btn-fix svg {
  background: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  transition: 0.5s;
  right: 10px;
  color: #000;
}
.custom-crm-development-card-content .btnall .btn-fix:hover svg {
  background: transparent linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%) 0% 0% no-repeat padding-box;
  right: calc(100% - 50px);
}

.school-mt-banner-sec {
  position: relative;
  z-index: 1;
}
.school-mt-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.school-mt-banner-sec .school-mt-banner-content {
  position: absolute;
  z-index: 2;
  top: 40%;
  width: 30%;
  left: 7%;
}
.school-mt-banner-sec .school-mt-banner-content .main-heading {
  color: white;
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 500;
}
.school-mt-banner-sec .school-mt-banner-content .main-heading span {
  color: #fcaf17;
}
.school-mt-banner-sec .school-mt-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.school-mt-banner-sec .school-mt-banner-content .transform-button {
  color: white;
  background-color: #fcaf17;
  border: 1px solid #fcaf17;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.school-mt-banner-sec .school-mt-banner-content .transform-button:hover {
  border: 1px solid white;
  background: transparent;
  color: white !important;
}

.Sch-mt-about-us {
  background-color: #f9f9f9;
}

.about-img {
  margin-right: 20px;
}
.about-img img {
  min-height: 420px;
  margin-top: 10px;
}

.Sch-mt-about-content {
  margin-top: 20px;
}
.Sch-mt-about-content .main-heading {
  font-size: 26px;
  font-weight: 600;
  color: #020202;
}
.Sch-mt-about-content .main-heading span {
  color: rgb(252, 175, 23);
}
.Sch-mt-about-content .desc {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.Sch-mt-about-content .h-about-button {
  margin-top: 20px;
}
.Sch-mt-about-content .h-about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 8px 25px;
  color: #ffffff;
  font-size: 14px;
}
.Sch-mt-about-content .h-about-button .read-more-button:hover {
  color: white !important;
}

.sch-software-services {
  background-color: #f9f9f9;
}

.sch-software-services-head {
  text-align: center;
}
.sch-software-services-head .main-heading {
  font-size: 32px;
  margin-bottom: 25px;
}
.sch-software-services-head .main-heading span {
  color: rgb(252, 175, 23);
}
.sch-software-services-head .desc {
  color: black;
  font-size: 16px;
  width: 80%;
  margin: 0 auto;
}

.sch-software-services-list {
  display: flex;
}

.sch-software-services-card {
  margin-bottom: 30px;
}
.sch-software-services-card .sch-software-services-icon {
  background-color: #fcaf17;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  margin-right: 20px;
  transition: all 0.5s linear;
  overflow: hidden;
  transform: rotateY(0deg);
  transition: all 0.5s linear;
}
.sch-software-services-card .sch-software-services-icon img {
  width: 65px;
  height: 65px;
}
.sch-software-services-card .sch-software-services-content {
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 95px 0px rgba(0, 0, 0, 0.09);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.sch-software-services-card .sch-software-services-content h5 {
  font-size: 17px;
  font-weight: 600;
}
.sch-software-services-card .sch-software-services-content .desc {
  font-size: 14px;
  line-height: 20px;
}
.sch-software-services-card:hover .sch-software-services-icon {
  transform: rotateY(360deg);
  transition: all 0.5s linear;
}

.sch-software-modules-head .main-heading {
  font-size: 32px;
  margin-bottom: 15px;
}
.sch-software-modules-head .main-heading span {
  color: rgb(252, 175, 23);
}
.sch-software-modules-head .desc {
  font-size: 14px;
}

.modules-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.sch-software-modules-card {
  box-shadow: rgba(13, 21, 55, 0.06) 0 5px 50px;
  -webkit-box-shadow: rgba(13, 21, 55, 0.06) 0 5px 50px;
  border-radius: 10px;
  transform: translateY(0px);
  transition: all 0.4s ease;
  min-height: 130px;
  margin-bottom: 20px;
  text-align: center;
}
.sch-software-modules-card .sch-software-modules-icon {
  text-align: center;
  padding: 10px;
}
.sch-software-modules-card .sch-software-modules-icon img {
  width: 45px;
  height: 45px;
}
.sch-software-modules-card .sch-software-modules-content {
  padding: 5px;
}
.sch-software-modules-card .sch-software-modules-content h5 {
  font-size: 14px;
  font-weight: 600;
}
.sch-software-modules-card:hover {
  transform: translateY(-5px);
  cursor: pointer;
  box-shadow: 0 20px 20px 0 rgba(2, 19, 79, 0.1);
}

.m-h-424 {
  min-height: 424px !important;
}

.Sch-mt-features-content {
  border: 1px solid #e5e5e5;
  padding: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: auto;
}
.Sch-mt-features-content h4 {
  font-size: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.Sch-mt-features-img img {
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}

.Sch-mt-features-list li {
  margin-bottom: 15px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.Sch-mt-features-list li img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.Sch-software-features .main-box {
  margin: 20px 0px;
}
.Sch-software-features .Sch-software-features-head .main-heading {
  font-size: 32px;
  margin-bottom: 15px;
}
.Sch-software-features .Sch-software-features-head .main-heading span {
  color: rgb(252, 175, 23);
}
.Sch-software-features .Sch-software-features-head .desc {
  font-size: 14px;
}

.sch-software-development-process .sch-process-card {
  color: #000;
  background: linear-gradient(188.28deg, #ffffff 22.17%, #f6f6f7 116.15%);
  box-shadow: 0px 3px 6px rgba(0, 0, 128, 0.1);
  border-radius: 5px;
  padding: 10px;
}
.sch-software-development-process .sch-software-development-process-head .main-heading {
  font-size: 32px;
  margin-bottom: 15px;
}
.sch-software-development-process .sch-software-development-process-head .main-heading span {
  color: rgb(252, 175, 23);
}
.sch-software-development-process .sch-software-development-process-head .desc {
  font-size: 14px;
}

.sch-software-process-tabs {
  position: relative;
}
.sch-software-process-tabs .tab-content {
  margin-top: 50px;
  background-color: rgb(255, 249, 238);
  padding: 2%;
  border-radius: 20px;
}
.sch-software-process-tabs .tab-content .process-tab-contents {
  padding: 10%;
}
.sch-software-process-tabs .step-triangle {
  position: absolute;
  left: 5.2%;
  top: -12%;
}
.sch-software-process-tabs .step-triangle .step-triangle-list {
  display: flex;
  align-items: center;
}
.sch-software-process-tabs .step-triangle .step-triangle-list li {
  width: 185px;
  margin-left: 5px;
}
.sch-software-process-tabs .step-triangle .step-triangle-list li .step-number {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  text-align: center;
  font-size: 16px;
  line-height: 35px;
  font-weight: 900;
  color: #f9ca6c;
  background: linear-gradient(123.73deg, #ffffff 13.95%, #f1f2f6 86.04%);
  box-shadow: 0px 6.4042px 25.6168px rgba(0, 0, 128, 0.15), 3.06761px 3.06761px 4.38047px rgba(0, 0, 0, 0.15);
  transform-origin: bottom;
  display: inline-block;
}
.sch-software-process-tabs .step-triangle .step-triangle-list li .step-number::before {
  content: "";
  position: absolute;
  background-color: #fcaf17;
  top: -8px;
  left: -7px;
  width: 145%;
  height: 145%;
  z-index: -1;
  border-radius: 50px;
}
.sch-software-process-tabs .step-triangle .step-triangle-list li .step-number::after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -19px;
  left: 52%;
  width: 0%;
  height: 0%;
  transform: translate(-50%, -7%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #fcaf17;
}
.sch-software-process-tabs .nav-tabs {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}
.sch-software-process-tabs .nav-tabs::before {
  content: "";
  position: absolute;
  box-shadow: 0px 8px 12px rgba(136, 136, 209, 0.15), inset 0px 4px 15px rgba(133, 133, 255, 0.06);
  width: 100%;
  height: 17px;
  left: 0px;
  background-color: #fbfbfd;
  z-index: -1;
}
.sch-software-process-tabs .nav-tabs .nav-item {
  background-image: url("../images/School-Management//process/dots-bg.png");
  background-position-x: center;
  background-position-y: 20px;
  background-repeat: no-repeat;
  background-size: 3px;
  position: relative;
}
.sch-software-process-tabs .nav-tabs .nav-item::before {
  content: "";
  position: absolute;
  top: 5px;
  width: 10px;
  height: 10px;
  background-color: #fcaf17;
  left: 47%;
  border-radius: 50px;
}
.sch-software-process-tabs .nav-tabs .nav-item::after {
  content: "";
  width: 60px;
  height: 60px;
}
.sch-software-process-tabs .nav-tabs .nav-link {
  color: #000;
  background: linear-gradient(188.28deg, #ffffff 22.17%, #f6f6f7 116.15%);
  box-shadow: 0px 3px 6px rgba(0, 0, 128, 0.1);
  border-radius: 20px;
  width: 150px;
  min-height: 90px;
  padding: 10px;
  font-size: 14px;
  margin: 50px 5px 0px 5px;
  line-height: 20px;
  font-weight: 600;
  transform: scale(1) translateY(0%);
  transition: all 0.5s ease;
}
.sch-software-process-tabs .nav-tabs .nav-link:hover {
  color: black !important;
}
.sch-software-process-tabs .nav-tabs .nav-link.active {
  transform: scale(1.15) translateY(50%);
  transition: all 0.5s ease;
}

.sch-software-solutions-advantages .sch-software-solutions-advantages-head .main-heading {
  font-size: 34px;
  margin-bottom: 15px;
  text-align: center;
}
.sch-software-solutions-advantages .sch-software-solutions-advantages-head .main-heading span {
  color: rgb(252, 175, 23);
}
.sch-software-solutions-advantages .sch-software-solutions-advantages-head .desc {
  font-size: 14px;
}

.sch-software-advantages-img img {
  border-radius: 5px;
  min-height: 402px;
}

.sch-software-advantages-content {
  border: 1px solid #e5e5e5;
  padding: 20px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.sch-software-advantages-content .sch-software-advantages-list li {
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 40px;
}
.sch-software-advantages-content .sch-software-advantages-list li img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.sch-software-development-process .faq-accordion {
  display: none;
}

.sch-process-r {
  display: block;
}

.sch-software-portfolio-head {
  text-align: center;
}
.sch-software-portfolio-head .main-heading {
  font-size: 32px;
  margin-bottom: 15px;
  text-align: center;
}
.sch-software-portfolio-head .main-heading span {
  color: rgb(252, 175, 23);
}

.society-mt-banner-sec {
  position: relative;
  z-index: 1;
}
.society-mt-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.society-mt-banner-sec .society-mt-banner-content {
  position: absolute;
  z-index: 2;
  top: 38%;
  width: 32%;
  left: 7%;
}
.society-mt-banner-sec .society-mt-banner-content .main-heading {
  color: white;
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 500;
}
.society-mt-banner-sec .society-mt-banner-content .main-heading span {
  color: #fcaf17;
}
.society-mt-banner-sec .society-mt-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.society-mt-banner-sec .society-mt-banner-content .transform-button {
  color: white;
  background-color: #fcaf17;
  border: 1px solid #fcaf17;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.society-mt-banner-sec .society-mt-banner-content .transform-button:hover {
  border: 1px solid white;
  background: transparent;
  color: white !important;
}

.society-mt-about-us {
  background-color: #f9f9f9;
}
.society-mt-about-us .society-about-img {
  margin-right: 20px;
}
.society-mt-about-us .society-about-img img {
  margin-top: 15px;
}
.society-mt-about-us .society-mt-about-content {
  margin-top: 10px;
}
.society-mt-about-us .society-mt-about-content .sub-heading {
  font-size: 16px;
  color: #020202;
  font-weight: 600;
  margin-top: 5px;
}
.society-mt-about-us .society-mt-about-content .main-heading {
  font-size: 26px;
  font-weight: 600;
  color: #020202;
}
.society-mt-about-us .society-mt-about-content .main-heading span {
  color: rgb(252, 175, 23);
}
.society-mt-about-us .society-mt-about-content .desc {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.society-mt-about-us .society-mt-about-content .h-about-button {
  margin-top: 10px;
}
.society-mt-about-us .society-mt-about-content .h-about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 8px 25px;
  color: #ffffff;
  font-size: 14px;
}
.society-mt-about-us .society-mt-about-content .h-about-button .read-more-button:hover {
  color: white !important;
}
.society-mt-about-us .society-mt-about-list-box .society-mt-about-list {
  padding: 10px;
}
.society-mt-about-us .society-mt-about-list-box .society-mt-about-list li {
  font-size: 14px;
  font-weight: 600;
  color: #020202;
  list-style: square;
}

.society-mt-features-head {
  text-align: center;
}
.society-mt-features-head .main-heading {
  font-size: 32px;
  margin-bottom: 15px;
}
.society-mt-features-head .main-heading span {
  color: rgb(252, 175, 23);
}
.society-mt-features-head .desc {
  font-size: 14px;
}

.real-estate-about-us .real-estate-about-content {
  margin-top: 20px;
}
.real-estate-about-us .real-estate-about-content .main-heading {
  font-size: 28px;
  font-weight: 500;
  color: #020202;
  font-family: "Poppins", sans-serif;
}
.real-estate-about-us .real-estate-about-content .main-heading span {
  color: #fcaf17;
}
.real-estate-about-us .real-estate-about-content .desc {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.real-estate-about-us .real-estate-about-content .h-about-button {
  margin-top: 20px;
}
.real-estate-about-us .real-estate-about-content .h-about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 8px 25px;
  color: #ffffff;
  font-size: 14px;
}
.real-estate-about-us .real-estate-about-content .h-about-button .read-more-button:hover {
  color: white !important;
}
.real-estate-about-us .real-estate-about-content .real-estate-about-heading {
  font-size: 24px;
}
.real-estate-about-us .real-estate-about-content .real-estate-about-list-box .real-estate-about-list {
  padding-top: 10px;
}
.real-estate-about-us .real-estate-about-content .real-estate-about-list-box .real-estate-about-list li {
  border-left: 1px dashed #bebebe;
  padding: 0px 10px 30px 20px;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
}
.real-estate-about-us .real-estate-about-content .real-estate-about-list-box .real-estate-about-list li::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #bebebe;
  border-radius: 50%;
  top: 5px;
  left: -8px;
}
.real-estate-about-us .real-estate-about-content .real-estate-about-list-box .real-estate-about-list li:hover {
  color: #fcaf17;
}
.real-estate-about-us .real-estate-about-content .real-estate-about-list-box .real-estate-about-list li:hover::before {
  background-color: #fcaf17;
}

.real-estate-about-us img {
  animation: 4s ease-in-out infinite floatY;
  transition: 4s;
  max-width: 90%;
}

@keyframes floatY {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
.real-est-development-services {
  background-color: #eff3f6;
}
.real-est-development-services .real-est-development-services-head {
  text-align: center;
}
.real-est-development-services .real-est-development-services-head .main-heading {
  font-size: 32px;
  margin-bottom: 15px;
}
.real-est-development-services .real-est-development-services-head .main-heading span {
  color: rgb(252, 175, 23);
}
.real-est-development-services .real-est-development-services-head .desc {
  font-size: 14px;
  margin: 0 auto;
  width: 80%;
}

.real-est-services-card {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 30px;
}
.real-est-services-card .real-est-services-icon {
  padding: 10px;
}
.real-est-services-card .real-est-services-icon img {
  border-radius: 20px;
}
.real-est-services-card .real-est-services-content {
  padding-top: 10px;
}
.real-est-services-card .real-est-services-content h4 {
  font-size: 20px;
}
.real-est-services-card .real-est-services-content .desc {
  font-size: 16px;
  line-height: 25px;
}

.real-est-mngmnt-system {
  position: relative;
  overflow: hidden;
  min-height: 550px;
  background-image: url("../images/Real-Estate/management/real-est-management-system.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.real-est-mngmnt-system::after {
  content: "";
  background-color: rgba(38, 38, 38, 0.8);
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
}
.real-est-mngmnt-system .real-est-mngmnt-content {
  z-index: 2;
  position: relative;
}
.real-est-mngmnt-system .real-est-mngmnt-content .heading {
  color: white;
  font-size: 34px;
  font-weight: 600;
}
.real-est-mngmnt-system .real-est-mngmnt-content .desc {
  color: white;
  font-size: 18px;
  margin: 20px 0px 30px 0px;
}
.real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 15px;
  text-align: center;
}
.real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card .desc {
  font-size: 16px;
  margin: 0;
  line-height: 25px;
}
.real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card .real-est-mngmnt-icon {
  margin-bottom: 15px;
}
.real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card .real-est-mngmnt-icon img {
  width: 80px;
  height: 80px;
  border-radius: 20px;
}
.real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card h5 {
  color: white;
  font-size: 18px;
}

.real-estate-portfolio .real-est-portfolio-card img {
  width: 80%;
  text-align: center;
}

.real-est-mob-app-solutions {
  background-color: #f7f7f7;
}
.real-est-mob-app-solutions .real-est-mob-app-head {
  text-align: center;
}
.real-est-mob-app-solutions .real-est-mob-app-head .main-heading {
  font-size: 34px;
}
.real-est-mob-app-solutions .real-est-mob-app-head .main-heading span {
  color: #fcaf17;
}
.real-est-mob-app-solutions .real-est-mob-app-card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  margin-bottom: 25px;
}
.real-est-mob-app-solutions .real-est-mob-app-card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  border-color: #bebebe;
}
.real-est-mob-app-solutions .real-est-mob-app-card:hover::before {
  width: 100%;
}
.real-est-mob-app-solutions .real-est-mob-app-card::before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  width: 0;
  height: 2px;
  background-color: #0fb531;
  transition: 0.9s;
}
.real-est-mob-app-solutions .real-est-mob-app-card.mobcard1::before {
  background-color: #0fb531;
}
.real-est-mob-app-solutions .real-est-mob-app-card.mobcard2::before {
  background-color: #4b9efb;
}
.real-est-mob-app-solutions .real-est-mob-app-card.mobcard3::before {
  background-color: #1cccfc;
}
.real-est-mob-app-solutions .real-est-mob-app-card.mobcard4::before {
  background-color: #fd7070;
}
.real-est-mob-app-solutions .real-est-mob-app-card.mobcard5::before {
  background-color: #5648f9;
}
.real-est-mob-app-solutions .real-est-mob-app-card.mobcard6::before {
  background-color: #eca84b;
}
.real-est-mob-app-solutions .real-est-mob-app-card .real-est-upper-part {
  display: flex;
  align-items: center;
}
.real-est-mob-app-solutions .real-est-mob-app-card .real-est-upper-part .icon {
  width: 60px;
  height: 60px;
  background-image: url("../images/Real-Estate/mobile-app-solutions/real-estate-app-sprite1.png");
  padding-right: 30px;
}
.real-est-mob-app-solutions .real-est-mob-app-card .real-est-upper-part .icon.icon1 {
  background-position: 0 0;
}
.real-est-mob-app-solutions .real-est-mob-app-card .real-est-upper-part .icon.icon2 {
  background-position: 0 -60px;
}
.real-est-mob-app-solutions .real-est-mob-app-card .real-est-upper-part .icon.icon3 {
  background-position: 0 -120px;
}
.real-est-mob-app-solutions .real-est-mob-app-card .real-est-upper-part .icon.icon4 {
  background-position: 0 -180px;
}
.real-est-mob-app-solutions .real-est-mob-app-card .real-est-upper-part .icon.icon5 {
  background-position: 0 -240px;
}
.real-est-mob-app-solutions .real-est-mob-app-card .real-est-upper-part .icon.icon6 {
  background-position: 0 -300px;
}
.real-est-mob-app-solutions .real-est-mob-app-card .real-est-upper-part h4 {
  font-size: 18px;
  margin-left: 10px;
}
.real-est-mob-app-solutions .real-est-mob-app-card .real-est-mob-content {
  padding: 10px;
}

.real-est-app-development-features .real-est-app-development-head {
  text-align: center;
}
.real-est-app-development-features .real-est-app-development-head .main-heading {
  font-size: 34px;
}
.real-est-app-development-features .real-est-app-development-head .main-heading span {
  color: #fcaf17;
}
.real-est-app-development-features .app-development-features-img img {
  animation: 4s ease-in-out infinite floatY;
  transition: 4s;
  max-width: 90%;
}
.real-est-app-development-features .app-development-left-side {
  margin-bottom: 30px;
}
.real-est-app-development-features .app-development-left-side .app-development-left-side-card {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.real-est-app-development-features .app-development-left-side .app-development-left-side-card .app-development-left-side-list {
  display: flex;
  align-items: center;
}
.real-est-app-development-features .app-development-left-side .app-development-left-side-card .app-development-left-side-list li {
  margin-right: 5px;
}
.real-est-app-development-features .app-development-left-side .app-development-left-side-card .app-development-left-side-list h3 {
  font-size: 18px;
}
.real-est-app-development-features .app-development-left-side .app-development-left-side-card .app-development-left-side-list .desc {
  font-size: 16px;
  line-height: 23px;
}
.real-est-app-development-features .app-development-left-side .app-development-left-side-card .app-development-left-side-list .icon {
  width: 50px;
  height: 50px;
  position: relative;
  transition: 0.3s;
}
.real-est-app-development-features .app-development-left-side .app-development-left-side-card .app-development-left-side-list .icon-b {
  position: absolute;
  opacity: 1;
  visibility: visible;
}
.real-est-app-development-features .app-development-left-side .app-development-left-side-card .app-development-left-side-list .icon-y {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.real-est-app-development-features .app-development-left-side .app-development-left-side-card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.real-est-app-development-features .app-development-left-side .app-development-left-side-card:hover .icon-b {
  opacity: 0;
  visibility: hidden;
}
.real-est-app-development-features .app-development-left-side .app-development-left-side-card:hover .icon-y {
  opacity: 1;
  visibility: visible;
}
.real-est-app-development-features .app-development-right-side {
  margin-bottom: 30px;
}
.real-est-app-development-features .app-development-right-side .app-development-right-side-card {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 95px;
}
.real-est-app-development-features .app-development-right-side .app-development-right-side-card .app-development-right-side-list {
  display: flex;
  align-items: center;
}
.real-est-app-development-features .app-development-right-side .app-development-right-side-card .app-development-right-side-list li {
  margin-left: 15px;
}
.real-est-app-development-features .app-development-right-side .app-development-right-side-card .app-development-right-side-list h3 {
  font-size: 18px;
}
.real-est-app-development-features .app-development-right-side .app-development-right-side-card .app-development-right-side-list .desc {
  font-size: 16px;
  line-height: 20px;
}
.real-est-app-development-features .app-development-right-side .app-development-right-side-card .app-development-right-side-list .icon {
  width: 50px;
  height: 50px;
  position: relative;
  transition: 0.3s;
}
.real-est-app-development-features .app-development-right-side .app-development-right-side-card .app-development-right-side-list .icon-b {
  position: absolute;
  opacity: 1;
  visibility: visible;
}
.real-est-app-development-features .app-development-right-side .app-development-right-side-card .app-development-right-side-list .icon-y {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.real-est-app-development-features .app-development-right-side .app-development-right-side-card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.real-est-app-development-features .app-development-right-side .app-development-right-side-card:hover .icon-b {
  opacity: 0;
  visibility: hidden;
}
.real-est-app-development-features .app-development-right-side .app-development-right-side-card:hover .icon-y {
  opacity: 1;
  visibility: visible;
}

.on-demand-head-area-re .sub-head {
  color: white;
  font-size: 24px;
}
.on-demand-head-area-re .main-head {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: white;
}

.real-est-banner-sec {
  position: relative;
  z-index: 1;
}
.real-est-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.real-est-banner-sec .real-est-banner-content {
  position: absolute;
  z-index: 2;
  top: 50%;
  width: 30%;
  left: 7%;
}
.real-est-banner-sec .real-est-banner-content .main-heading {
  color: white;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 500;
}
.real-est-banner-sec .real-est-banner-content .main-heading span {
  color: #fcaf17;
}
.real-est-banner-sec .real-est-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.real-est-banner-sec .real-est-banner-content .transform-button {
  color: white;
  background-color: #fcaf17;
  border: 1px solid #fcaf17;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.real-est-banner-sec .real-est-banner-content .transform-button:hover {
  border: 1px solid white;
  background: transparent;
  color: white !important;
}

.on-demand-head-area-re .sub-head {
  font-size: 24px;
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 15px;
}

.on-demand-head-area-re .main-head {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.on-demand-my-tab-re {
  border-bottom: 1px solid #939393 !important;
}
.on-demand-my-tab-re:hover {
  border-bottom: 3px solid white !important;
}
.on-demand-my-tab-re .on-demand-tab-head {
  color: white;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
}

.admin-content-list-box-re {
  padding: 30px 0px 0px 30px;
}

.admin-content-list-box1 {
  padding: 30px 0px 0px 0px;
}

.admin-content-list-re li {
  color: white;
  line-height: 45px;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
}

.admin-content-list-re li svg {
  margin-right: 10px;
  font-size: 20px;
  color: white;
}

.erp-portfolio-area {
  background-color: #f5e9ce;
}
.erp-portfolio-area .erp-portfolio-left-side {
  background-color: rgb(215, 164, 64);
  padding: 80px 40px 0px 40px;
}
.erp-portfolio-area .erp-portfolio-left-side h4 {
  color: white;
  font-size: 32px;
  font-style: italic;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.erp-portfolio-area .erp-portfolio-left-side .sub-heading-area {
  margin-bottom: 15px;
}
.erp-portfolio-area .erp-portfolio-left-side .quote-erp {
  color: white;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}
.erp-portfolio-area .erp-portfolio-left-side .connect-erp .desc {
  color: white;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.erp-portfolio-area .custom-erp-development-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

section.page_404 {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.four_zero_four_bg h1 {
  font-size: 40px;
}

.modalpop .modal-header {
  padding: 0;
  border-bottom: none;
}

.modalpop .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 8;
}

.modalpop input {
  background-color: #f4f4f4;
  height: 50px;
  border-radius: 4px;
  border-color: #f4f4f4;
}

.modalpop .form-control {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.modalpop .form-control:focus {
  box-shadow: none;
  border-color: #f4f4f4;
}

.modalpop .btnmodasubmit {
  background-color: #fcaf17;
  padding: 7px 35px;
  color: #fff !important;
  font-size: 20px;
}

.manufacturing-banner-sec {
  position: relative;
  z-index: 1;
}
.manufacturing-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.manufacturing-banner-sec .manufacturing-banner-content {
  position: absolute;
  z-index: 2;
  top: 35%;
  width: 40%;
  left: 5%;
}
.manufacturing-banner-sec .manufacturing-banner-content .main-heading {
  color: white;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 500;
}
.manufacturing-banner-sec .manufacturing-banner-content .main-heading span {
  color: #fcaf17;
}
.manufacturing-banner-sec .manufacturing-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.manufacturing-banner-sec .manufacturing-banner-content .transform-button {
  color: white;
  background-color: #fcaf17;
  border: 1px solid #fcaf17;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.manufacturing-banner-sec .manufacturing-banner-content .transform-button:hover {
  border: 1px solid white;
  background: transparent;
  color: white !important;
}

.manufacturing-about-us .manufacturing-about-content .main-heading {
  font-size: 28px;
  font-weight: 500;
  color: #020202;
  font-family: "Poppins", sans-serif;
}
.manufacturing-about-us .manufacturing-about-content .main-heading span {
  color: #fcaf17;
}
.manufacturing-about-us .manufacturing-about-content .desc {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.manufacturing-about-us .manufacturing-about-content .h-about-button {
  margin-top: 20px;
}
.manufacturing-about-us .manufacturing-about-content .h-about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 8px 25px;
  color: #ffffff;
  font-size: 14px;
}
.manufacturing-about-us .manufacturing-about-content .h-about-button .read-more-button:hover {
  color: white !important;
}
.manufacturing-about-us .manufacturing-about-img img {
  animation: 4s ease-in-out infinite floatY;
  transition: 4s;
  max-width: 90%;
}
@keyframes floatY {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.manuft-sft-solutions-sec .manuft-sft-solutions-head {
  text-align: center;
}
.manuft-sft-solutions-sec .manuft-sft-solutions-head .main-heading-type {
  color: #020202;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 20px;
}
.manuft-sft-solutions-sec .manuft-sft-solutions-head .main-heading-type span {
  color: rgb(252, 175, 23);
}
.manuft-sft-solutions-sec .manuft-sft-solutions-head .sub-heading {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.manuft-sft-solutions-sec .manuft-sft-solutions-head .sub-heading span {
  color: rgb(252, 175, 23);
}
.manuft-sft-solutions-sec .manuft-sft-solutions-img img {
  width: 90%;
  animation: 4s ease-in-out infinite floatY;
  transition: 4s;
}
.manuft-sft-solutions-sec .manuft-sft-solutions-content .main-heading {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 600;
}
.manuft-sft-solutions-sec .manuft-sft-solutions-content .sub-heading {
  font-size: 20px;
}
.manuft-sft-solutions-sec .manuft-sft-solutions-content .mft-solutions-list-box .manuft-sol-cnt-list {
  margin-bottom: 20px;
}
.manuft-sft-solutions-sec .manuft-sft-solutions-content .mft-solutions-list-box .manuft-sol-cnt-list .content h6 {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 16px;
}
.manuft-sft-solutions-sec .manuft-sft-solutions-content .mft-solutions-list-box .manuft-sol-cnt-list .content .desc {
  font-size: 14px;
}

.d-f-j-c-s-b {
  display: flex;
  justify-content: space-between;
}

.manuft-sft-services .manuft-sft-solutions-head {
  text-align: center;
}
.manuft-sft-services .manuft-sft-solutions-head .main-heading-type {
  color: #020202;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 20px;
}
.manuft-sft-services .manuft-sft-solutions-head .main-heading-type span {
  color: rgb(252, 175, 23);
}
.manuft-sft-services .manuft-sft-services-list {
  display: flex;
  align-items: center;
}
.manuft-sft-services .manuft-sft-services-list li img {
  width: 180px;
}
.manuft-sft-services .manuft-sft-services-list li .manuft-sft-services-inner-list {
  padding-left: 25px;
  padding-top: 25px;
}
.manuft-sft-services .manuft-sft-services-list li .manuft-sft-services-inner-list h5 {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
}
.manuft-sft-services .manuft-sft-services-list li .manuft-sft-services-inner-list .desc {
  font-size: 16px;
  line-height: 25px;
}

.our-works-manuft-software .manuft-sft-works-head {
  text-align: center;
}
.our-works-manuft-software .manuft-sft-works-head .main-heading-type {
  color: #020202;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 20px;
}
.our-works-manuft-software .manuft-sft-works-head .main-heading-type span {
  color: rgb(252, 175, 23);
}
.our-works-manuft-software .manuft-sft-works-content {
  padding-top: 50px;
}
.our-works-manuft-software .manuft-sft-works-content .sub-heading {
  background-color: black;
  color: white;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  padding: 8px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.our-works-manuft-software .manuft-sft-works-content .main-heading {
  font-weight: 600;
  font-size: 24px;
}
.our-works-manuft-software .manuft-sft-works-content .desc {
  margin-bottom: 25px;
}
.our-works-manuft-software .manuft-sft-works-content .contact-us-buttn {
  background-color: #fcaf17;
  color: white;
  padding: 10px 32px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
}

.retail-pos-banner-sec {
  position: relative;
}
.retail-pos-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.retail-pos-banner-sec .retail-pos-banner-content {
  position: absolute;
  z-index: 2;
  top: 50%;
  width: 31%;
  left: 7%;
}
.retail-pos-banner-sec .retail-pos-banner-content .main-heading {
  color: white;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 500;
}
.retail-pos-banner-sec .retail-pos-banner-content .main-heading span {
  color: #fcaf17;
}
.retail-pos-banner-sec .retail-pos-banner-content .sub-heading {
  color: white;
  margin-bottom: 30px;
}
.retail-pos-banner-sec .retail-pos-banner-content .transform-button {
  color: white;
  background-color: #fcaf17;
  border: 1px solid #fcaf17;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.retail-pos-banner-sec .retail-pos-banner-content .transform-button:hover {
  border: 1px solid white;
  background: transparent;
  color: white !important;
}

.retail-pos-about-us .retail-pos-about-content .main-heading {
  font-size: 26px;
  font-weight: 500;
  color: #020202;
  font-family: "Poppins", sans-serif;
}
.retail-pos-about-us .retail-pos-about-content .main-heading span {
  color: #fcaf17;
}
.retail-pos-about-us .retail-pos-about-content .desc {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.retail-pos-about-us .retail-pos-about-content .h-about-button {
  margin-top: 20px;
}
.retail-pos-about-us .retail-pos-about-content .h-about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 8px 25px;
  color: #ffffff;
  font-size: 14px;
}
.retail-pos-about-us .retail-pos-about-content .h-about-button .read-more-button:hover {
  color: white !important;
}
.retail-pos-about-us .retail-pos-about-img img {
  animation: 4s ease-in-out infinite floatY;
  transition: 4s;
  max-width: 90%;
}
@keyframes floatY {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.retail-pos-features-sec {
  background-color: #eeece0 !important;
}
.retail-pos-features-sec .retail-pos-features-head {
  text-align: center;
}
.retail-pos-features-sec .retail-pos-features-head .main-heading-type {
  color: #020202;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}
.retail-pos-features-sec .retail-pos-features-head .main-heading-type span {
  color: rgb(252, 175, 23);
}
.retail-pos-features-sec .retail-pos-features-head .sub-heading {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}
.retail-pos-features-sec .retail-pos-features-head .sub-heading span {
  color: rgb(252, 175, 23);
}
.retail-pos-features-sec .retail-pos-features-content {
  padding-top: 50px;
}
.retail-pos-features-sec .retail-pos-features-content .main-head {
  font-size: 29px;
  position: relative;
  padding-bottom: 14px;
  width: 94%;
  text-align: center;
  font-weight: 600;
  line-height: 35px;
}
.retail-pos-features-sec .retail-pos-features-content .main-head::after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: 20%;
  height: 5px;
  background-color: #fcaf17;
  bottom: 0;
}
.retail-pos-features-sec .retail-pos-features-content .retail-pos-accord-ion {
  padding-top: 40px;
}
.retail-pos-features-sec .retail-pos-features-content .retail-pos-features-card {
  background-color: #ffffff;
  box-shadow: -2px 6px 30px rgba(39, 176, 230, 0.08);
  padding: 15px;
  margin-top: 30px;
  transition: 0.5s;
  border: 1px solid #ffffff;
  border-radius: 10px;
  min-height: 144px;
}
.retail-pos-features-sec .retail-pos-features-content .retail-pos-features-card:hover {
  border-color: #fcaf17;
  transform: translateY(-5px);
  cursor: pointer;
}
.retail-pos-features-sec .retail-pos-features-content .retail-pos-features-card:hover .retail-pos-features-card-icon {
  background-color: #fcaf17;
}
.retail-pos-features-sec .retail-pos-features-content .retail-pos-features-card:hover .retail-pos-features-card-icon .icon-w {
  opacity: 1;
  visibility: visible;
}
.retail-pos-features-sec .retail-pos-features-content .retail-pos-features-card:hover .retail-pos-features-card-icon .icon-y {
  opacity: 0;
  visibility: hidden;
}
.retail-pos-features-sec .retail-pos-features-content .retail-pos-features-card .retail-pos-features-card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  line-height: 70px;
  background-color: #ffffff;
  box-shadow: -2px 4px 15px rgba(41, 171, 226, 0.12);
  border-radius: 50px;
  transition: 0.5s;
  margin: 0 auto;
  position: relative;
}
.retail-pos-features-sec .retail-pos-features-content .retail-pos-features-card .retail-pos-features-card-icon .icon-w {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.retail-pos-features-sec .retail-pos-features-content .retail-pos-features-card .retail-pos-features-card-icon .icon-y {
  position: absolute;
  opacity: 1;
  visibility: visible;
}
.retail-pos-features-sec .retail-pos-features-content .retail-pos-features-card .retail-pos-features-card-content .desc {
  font-size: 16px;
  line-height: 20px;
}
.retail-pos-features-sec .retail-pos-features-img {
  text-align: center;
  margin-top: 15%;
}
.retail-pos-features-sec .retail-pos-features-img img {
  width: 80%;
  margin-left: 0px;
}
.retail-pos-features-sec .explore-buttn-holder {
  text-align: center;
  margin-top: 40px;
}
.retail-pos-features-sec .explore-buttn-holder .explore-buttn {
  background-color: #fcaf17;
  color: white;
  padding: 12px 32px;
  border-radius: 5px;
  font-weight: 600;
  position: relative;
  transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1), transform 0.45s cubic-bezier(0.25, 1, 0.33, 1);
}
.retail-pos-features-sec .explore-buttn-holder .explore-buttn .right-arrw {
  color: white;
  font-size: 20px;
  position: absolute;
  right: 4%;
  top: 50%;
  opacity: 0;
  transform: translateX(0px);
  transition: all 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  margin-top: -9px;
}
.retail-pos-features-sec .explore-buttn-holder .explore-buttn:hover {
  color: white !important;
}
.retail-pos-features-sec .explore-buttn-holder .explore-buttn:hover .right-arrw {
  opacity: 1;
  transform: translateX(6px);
}
.retail-pos-features-sec .retail-pos-accord-ion .accordion-button:focus .accordion-header {
  border-bottom: 0px;
}
.retail-pos-features-sec .retail-pos-accord-ion .accordion-button:not(.collapsed) {
  color: #000;
  font-weight: 600;
  border-color: transparent !important;
  transition: transform 0.45s cubic-bezier(0.3, 0.4, 0.2, 1);
}
.retail-pos-features-sec .retail-pos-accord-ion .accordion-button:not(.collapsed).accordion-header {
  border-color: transparent !important;
}
.retail-pos-features-sec .retail-pos-accord-ion .accordion-header {
  border-bottom: 1px solid #e5e5e5;
}
.retail-pos-features-sec .retail-pos-accord-ion .accordion-button {
  background-color: #fff;
  color: black;
  font-weight: 600;
  font-size: 18px;
  padding: 15px;
}
.retail-pos-features-sec .retail-pos-accord-ion .accordion-button:not(.collapsed) .minus-icon {
  display: block;
}
.retail-pos-features-sec .retail-pos-accord-ion .accordion-button:not(.collapsed) .plus-icon {
  display: none;
}
.retail-pos-features-sec .retail-pos-accord-ion .accordion-button.collapsed .minus-icon {
  display: none;
}
.retail-pos-features-sec .retail-pos-accord-ion .accordion-button.collapsed .plus-icon {
  display: block;
}

.retail-pos-software-importance .retail-pos-software-imp-head {
  text-align: center;
}
.retail-pos-software-importance .retail-pos-software-imp-head .main-heading-type {
  color: #020202;
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 15px;
}
.retail-pos-software-importance .retail-pos-software-imp-head .main-heading-type span {
  color: rgb(252, 175, 23);
}
.retail-pos-software-importance .retail-pos-software-imp-head .desc {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  line-height: 25px;
}
.retail-pos-software-importance .retail-pos-imp-card {
  border-radius: 20px;
  position: relative;
  border: 1px solid transparent;
  margin-bottom: 30px;
  padding: 10px;
  text-align: center;
  min-height: 380px;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  transform: scale(0.85);
}
.retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-icon {
  margin: 0 auto;
  text-align: center;
}
.retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-icon img {
  width: 20%;
}
.retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-content {
  padding-top: 20px;
}
.retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-content h3 {
  color: #3d3e42;
  font-size: 20px;
}
.retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-content .desc {
  font-size: 16px;
  line-height: 25px;
  width: 100%;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-50 {
  padding-top: 50px;
}

.whatsapp-chatbot-banner-sec {
  position: relative;
  z-index: 1;
}
.whatsapp-chatbot-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}

.whatsapp-bot-about-us {
  background: linear-gradient(180deg, #f3ecdd, rgba(231, 245, 255, 0));
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head {
  text-align: center;
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head .main-head {
  font-size: 41px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head .main-head span {
  color: #fcaf17;
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head .h-about-button {
  margin-top: 30px;
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head .h-about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 8px 25px;
  color: #ffffff;
  font-size: 14px;
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head .h-about-button .read-more-button:hover {
  color: white !important;
}

.why-you-need-whatsappbot {
  background-color: #2f3134;
}
.why-you-need-whatsappbot .why-you-need-whatsappbot-head {
  text-align: center;
  color: white;
  border: transparent;
}

.retail-pos-accord-ion .minus-icon {
  position: absolute;
  right: 5%;
  font-size: 30px;
  transition: all 0.4s;
}

.retail-pos-accord-ion .plus-icon {
  position: absolute;
  right: 5%;
  font-size: 30px;
  transition: all 0.4s;
}

.retail-pos-accord-ion .accordion-button:hover {
  color: #fcaf17;
}

.retail-pos-accord-ion .accordion-item {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 0px;
}

.retail-pos-accord-ion .accordion-button::after {
  background-image: none;
}

.retail-pos-why-choose-us {
  background-color: #f1f2f2;
}
.retail-pos-why-choose-us .retail-pos-why-choose-us-head {
  text-align: center;
}
.retail-pos-why-choose-us .retail-pos-why-choose-us-head .main-heading-type {
  color: #020202;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}
.retail-pos-why-choose-us .retail-pos-why-choose-us-head .main-heading-type span {
  color: rgb(252, 175, 23);
}
.retail-pos-why-choose-us .why-choose-retail-pos-card {
  padding: 15px;
  border-radius: 16px;
  min-height: 274px;
  margin-bottom: 25px;
}
.retail-pos-why-choose-us .why-choose-retail-pos-card.color-1 {
  background-color: #f9eba6;
}
.retail-pos-why-choose-us .why-choose-retail-pos-card.color-2 {
  background-color: #b5eecc;
}
.retail-pos-why-choose-us .why-choose-retail-pos-card.color-3 {
  background-color: #dbe1e1;
}
.retail-pos-why-choose-us .why-choose-retail-pos-card.color-4 {
  background-color: rgba(239, 157, 16, 0.2588235294);
}
.retail-pos-why-choose-us .why-choose-retail-pos-card.color-5 {
  background-color: rgba(196, 163, 90, 0.3411764706);
}
.retail-pos-why-choose-us .why-choose-retail-pos-card.color-6 {
  background-color: rgba(236, 193, 156, 0.6901960784);
}
.retail-pos-why-choose-us .why-choose-retail-pos-card.color-7 {
  background-color: #d3d9cd;
}
.retail-pos-why-choose-us .why-choose-retail-pos-card.color-8 {
  background-color: #d2eddd;
}
.retail-pos-why-choose-us .why-choose-retail-pos-card .why-choose-retail-pos-icon {
  text-align: center;
  padding-bottom: 10px;
}
.retail-pos-why-choose-us .why-choose-retail-pos-card .why-choose-retail-pos-icon img {
  width: 25%;
}
.retail-pos-why-choose-us .why-choose-retail-pos-card .why-choose-retail-pos-content h3 {
  text-align: center;
  font-size: 20px;
  width: 100%;
  margin: 0 auto;
}
.retail-pos-why-choose-us .why-choose-retail-pos-card .why-choose-retail-pos-content .desc {
  font-size: 18px;
  line-height: 25px;
  padding: 10px 0px;
  text-align: center;
}

.possible-channels-sec {
  background-color: #e7e5e0;
}
.possible-channels-sec .possible-channels-head {
  text-align: center;
}
.possible-channels-sec .possible-channels-head .main-heading {
  font-size: 34px;
  margin-bottom: 20px;
}
.possible-channels-sec .possible-channels-head .main-heading span {
  color: #fcaf17;
}
.possible-channels-sec .possible-channels-head .desc {
  font-size: 16px;
}
.possible-channels-sec .left-side-whatsapp-content {
  position: relative;
  background-color: white;
  padding: 45px;
  border-radius: 10px;
  width: 80%;
  margin: 0 auto;
  margin-top: 30% !important;
}
.possible-channels-sec .left-side-whatsapp-content .main-heading {
  padding-top: 30px;
}
.possible-channels-sec .left-side-whatsapp-content .icon {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  top: 0px;
  position: absolute;
  left: 0;
  right: 0;
  padding: 10px 0px;
}
.possible-channels-sec .left-side-whatsapp-content .icon img {
  width: 85%;
}
.possible-channels-sec .left-side-whatsapp-content.whatsapp::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/whatsapp-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .left-side-whatsapp-content.instagram::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/instagram-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .left-side-whatsapp-content.website::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/website-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .left-side-whatsapp-content.fb::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/instagram-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .left-side-whatsapp-content.google::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/google-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .left-side-whatsapp-content.imessage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/whatsapp-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .left-side-whatsapp-content.viber::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/viber-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .left-side-whatsapp-content .desc {
  color: black;
  line-height: 25px;
  font-size: 16px;
}
.possible-channels-sec .possible-channels-tab-panes .tab-content {
  background-color: #f6f6f6 !important;
  border-radius: 10px;
  padding: 40px;
}
.possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content {
  position: relative;
  background-color: white;
  padding: 45px;
  border-radius: 10px;
  width: 80%;
  margin: 0 auto;
  margin-top: 30% !important;
}
.possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content.whatsapp::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/whatsapp-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content.instagram::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/instagram-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content.website::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/website-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content.fb::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/instagram-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content.google::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/google-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content.imessage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/whatsapp-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content.viber::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/Whatsapp-Bot/allchannels/viber-left-border.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content .desc {
  color: black;
  line-height: 25px;
  font-size: 16px;
}

.whatsapp-chatbot-banner-sec {
  position: relative;
  z-index: 1;
}
.whatsapp-chatbot-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}

.whatsapp-bot-about-us {
  background: linear-gradient(180deg, #f3ecdd, rgba(231, 245, 255, 0));
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head {
  text-align: center;
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head .main-head {
  font-size: 41px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head .main-head span {
  color: #fcaf17;
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head .h-about-button {
  margin-top: 30px;
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head .h-about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 8px 25px;
  color: #ffffff;
  font-size: 14px;
}
.whatsapp-bot-about-us .whatsapp-bot-about-us-head .h-about-button .read-more-button:hover {
  color: white !important;
}

.why-you-need-whatsappbot {
  background-color: #2f3134;
}
.why-you-need-whatsappbot .why-you-need-whatsappbot-head {
  text-align: center;
  color: white;
}
.why-you-need-whatsappbot .why-you-need-whatsappbot-head .main-heading {
  color: white;
}
.why-you-need-whatsappbot .why-you-need-whatsappbot-card {
  padding: 25px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1607843137);
  border-radius: 8px;
  background-color: white;
  text-align: center;
  margin-bottom: 25px;
}
.why-you-need-whatsappbot .why-you-need-whatsappbot-card .why-you-need-img {
  margin: 10px auto;
}
.why-you-need-whatsappbot .why-you-need-whatsappbot-card h6 {
  padding: 10px;
  font-size: 18px;
}

.whatsapp-chatbot-benefits {
  background-color: #ecf0f3;
}
.whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-head {
  text-align: center;
}
.whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-head .main-heading {
  font-size: 34px;
  font-weight: 600;
}
.whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-head .main-heading span {
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  background: linear-gradient(90.11deg, #fcaf17 0.09%, #020202 99.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-card {
  max-width: 310px;
  border-radius: 24px;
  margin-bottom: 2rem;
  background: #f5f5fa;
  box-shadow: inset -1px -1px 2px 0 #fff, inset 1px 1px 2px 0 rgba(170, 170, 204, 0.5019607843);
  padding: 25px;
  min-height: 262px;
}
.whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-card .whatsapp-chatbot-benefits-header-box {
  padding: 10px 10px 20px 10px;
}
.whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-card .whatsapp-chatbot-benefits-header-box .whatsapp-benefits-header-list {
  display: flex;
  align-items: center;
}
.whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-card .whatsapp-chatbot-benefits-header-box .whatsapp-benefits-header-list li img {
  border: 2px solid;
  border-image-source: linear-gradient(135deg, rgba(0, 0, 0, 0.05), hsla(0, 0%, 100%, 0.05));
  border-radius: 14px;
  padding: 0.5rem;
  box-shadow: 13px 10px 9.9818983078px 0 rgba(174, 174, 192, 0.3019607843), -4.9909491539px -4.9909491539px 9.9818983078px 0 #fff, inset 1.9963797331px 1.9963797331px 3.9927594662px 0 #fff, inset 0 1px 3.9927594662px 0 rgba(0, 0, 0, 0.1019607843);
}
.whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-card .whatsapp-chatbot-benefits-header-box .whatsapp-benefits-header-list li h4 {
  margin-bottom: 0px;
  margin-left: 20px;
  font-size: 20px;
}
.whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-card .whatsapp-chatbot-benefits-header-box .whatsapp-benefits-header-list li .desc {
  line-height: 25px;
}

.retail-pos-software-importance .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
  /* padding: 20px; */
  padding: 20px 10px;
}

.retail-pos-software-importance .swiper-slide.swiper-slide-active .retail-pos-imp-card {
  background-color: #fff9ed;
  overflow: hidden;
  transform: scale(1.05);
}

.mockup_webright {
  display: flex;
  justify-content: space-between;
}
.mockup_webright .content_portflito,
.mockup_webright .img_portflito {
  flex: 1;
}
.mockup_webright .img_portflito {
  text-align: end;
}
.mockup_webright .img_portflito .webportfolo {
  position: relative;
  top: 20%;
  z-index: 99;
}
.mockup_webright .box_bgportfoliyo {
  position: relative;
}
.mockup_webright .box_bgportfoliyo::before {
  content: "";
  position: absolute;
  right: 0;
  height: 80vh;
  width: 80%;
  background-color: red;
}

.brochurepadding {
  padding: 20px 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.portfolio_cardimg_content .btn-group {
  display: none;
}

.borchureimgheader {
  width: 100%;
  height: 420px;
}
.borchureimgheader img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

/************* case-study **************/
.case_section1 {
  background-color: #f8fafc;
}

.case_section1 .case_content {
  box-shadow: 0px 8px 16px 0px rgba(50, 50, 50, 0.04);
  background: #ffffff;
  transition: 0.3s all ease-in-out;
  text-align: center;
  position: relative;
  min-height: 372px;
  padding: 50px 20px;
}

.case_section1 .case_content h3 {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 500;
  color: #212529;
  -webkit-text-fill-color: transparent !important;
  -webkit-text-stroke: 1px #212529;
}

.case_section1 .case_content h4 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #212529;
}

.case_section1 .case_content p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  color: #979595;
}

.case_section1 .tech-used {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.case_section1 .tech-used span {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #979595;
}

.case_section1 .tech-used img {
  width: 150px;
  height: 75px;
  -o-object-fit: contain;
     object-fit: contain;
}

.case_section1 .tech-used1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.case_section1 .tech-used1 span {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #979595;
}

.case_section1 .tech-used1 img {
  width: 210px;
  height: 75px;
  -o-object-fit: contain;
     object-fit: contain;
}

.case_section1 .case_image {
  display: block;
  box-shadow: 0px 8px 16px 0px rgba(50, 50, 50, 0.04);
  background: #ffffff;
  transition: 0.3s all ease-in-out;
  padding: 55px 40px 50px;
  text-align: center;
  position: relative;
  min-height: 372px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0, rgba(0, 0, 0, 0.1215686275) 100%);
}

.case_section1 .case_image img {
  position: absolute;
  width: 270px;
  height: 200px;
  -o-object-fit: contain;
     object-fit: contain;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.case_section1 .case_content:hover {
  background-color: #000000;
}

.case_section1 .case_content:hover h3 {
  color: #fcaf17;
  -webkit-text-stroke: 1px #fcaf17;
}

.case_section1 .case_content:hover h4 {
  color: #fcaf17;
}

.case_section1 .case_content:hover p,
.case_section1 .case_content:hover span {
  color: #ddd9d9;
}

.patient-journey-steps .patient-journey-steps-head .main-head {
  font-size: 34px;
  font-weight: 600;
  color: #020202;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-bottom: 15px;
}
.patient-journey-steps .patient-journey-steps-head .main-head span {
  color: #fcaf17;
}
.patient-journey-steps #steps_process {
  position: relative;
}
.patient-journey-steps #steps_process .step-process-left-sidebar {
  position: relative;
}
.patient-journey-steps #steps_process .step-process-left-sidebar .step-process-card {
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.3);
  margin-top: 50px;
  padding: 30px 0px;
  height: 80vh;
}
.patient-journey-steps #steps_process .step-process-left-sidebar .step-process-card .main-heading {
  margin-bottom: 15px;
  padding: 0px 30px;
}
.patient-journey-steps #steps_process .step-process-left-sidebar .step-process-card .desc {
  padding: 0px 30px;
}
.patient-journey-steps #steps_process .step-process-left-sidebar .step-process-card .play-buttn {
  margin-top: 20px;
  color: #fcaf17;
  font-weight: 600;
  padding: 0px 30px;
}
.patient-journey-steps #steps_process .step-process-left-sidebar .step-process-card .play-buttn img {
  margin-right: 10px;
}
.patient-journey-steps #steps_process .step-process-left-sidebar .step-process-card .border-hr {
  width: 100%;
  height: 1px;
  background: #e2e2e2;
  margin: 20px 0;
}
.patient-journey-steps #steps_process .step-process-left-sidebar .step-process-card .founder-box .founder-name {
  font-size: 18px;
  padding: 0px 30px;
  margin-top: 10px;
}
.patient-journey-steps #steps_process .step-process-left-sidebar .step-process-card .founder-box .designation {
  font-size: 16px;
  padding: 0px 30px;
  color: #797979;
}
.patient-journey-steps #steps_process .step-process-left-sidebar .step-process-card .logo {
  text-align: end;
  padding-right: 30px;
}
.patient-journey-steps #steps_process .step-process-left-sidebar .step-process-card .logo img {
  width: 60%;
}
.patient-journey-steps #steps_process .step-process-left-sidebar .step-process-card .d-f-ja-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.patient-journey-steps #steps_process #steps_image {
  position: sticky;
  height: 100vh;
  top: 0;
  text-align: center;
}
.patient-journey-steps #steps_process #steps_image img {
  width: 60%;
}

.Value-added-features {
  background: #f6f6f6;
}
.Value-added-features .Value-added-features-head .main-head {
  font-size: 34px;
  font-weight: 600;
  color: #020202;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-bottom: 15px;
}
.Value-added-features .Value-added-features-head .main-head span {
  color: #fcaf17;
}
.Value-added-features .value-added-features-card {
  transition: all 0.5s;
  min-height: 525px;
  margin-bottom: 25px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 30px 80px rgba(0, 57, 110, 0.05);
}
.Value-added-features .value-added-features-card:hover {
  cursor: pointer;
  transition: 0.5s;
}
.Value-added-features .value-added-features-card:hover .value-added-features-card-icon img {
  transform: scale(1.07);
}
.Value-added-features .value-added-features-card .value-added-features-card-icon {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  transition: 0.5s;
}
.Value-added-features .value-added-features-card .value-added-features-card-icon img {
  border-radius: 10px 10px 0 0;
  min-height: 297px;
  transform: scale(1);
  transition: 0.5s;
}
.Value-added-features .value-added-features-card .value-added-features-card-content {
  padding: 15px;
}
.Value-added-features .value-added-features-card .value-added-features-card-content .main-heading {
  font-size: 22px;
  text-transform: uppercase;
}
.Value-added-features .value-added-features-card .value-added-features-card-content .sub-heading {
  font-size: 18px;
  color: #fcaf17;
}
.Value-added-features .value-added-features-card .value-added-features-card-content .desc {
  font-size: 16px;
  line-height: 25px;
}

.breadcrumb-area {
  padding: 20px 0px;
}
.breadcrumb-area .breadcrumb-casestudy-box .breadcrumb-casestudy-list {
  display: flex;
  align-items: center;
}
.breadcrumb-area .breadcrumb-casestudy-box .breadcrumb-casestudy-list li {
  margin-left: 10px;
}
.breadcrumb-area .breadcrumb-casestudy-box .breadcrumb-casestudy-list li a {
  color: #020202;
  font-size: 15px;
  font-weight: 600;
}
.breadcrumb-area .breadcrumb-casestudy-box .breadcrumb-casestudy-list li a:hover {
  color: #fcaf17 !important;
}

.case-study-banner-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/case-study-1/banner/fasto-banner-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.case-study-banner-sec .case-study-banner-content {
  position: absolute;
  z-index: 2;
  top: 40%;
  width: 40%;
  left: 15%;
}
.case-study-banner-sec .case-study-banner-content .main-heading {
  font-size: 32px;
  margin-bottom: 10px;
  font-weight: 500;
  margin-top: 15px;
}
.case-study-banner-sec .case-study-banner-content .main-heading span {
  color: #fcaf17;
}
.case-study-banner-sec .case-study-banner-content .desc {
  font-size: 14px;
  line-height: 25px;
}
.case-study-banner-sec .case-study-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.case-study-banner-sec .case-study-banner-content .transform-button {
  color: white;
  background-color: #fcaf17;
  border: 1px solid #fcaf17;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.case-study-banner-sec .case-study-banner-content .transform-button:hover {
  border: 1px solid white;
  background: transparent;
  color: white !important;
}
.case-study-banner-sec .fasto-banner-img {
  position: absolute;
  z-index: 2;
  top: 5%;
  right: 0%;
}
.case-study-banner-sec .fasto-banner-img img {
  width: 85%;
}

.about-client-sec {
  background-color: whitesmoke;
}
.about-client-sec .about-client-main-head .main-heading1 {
  font-size: 40px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
}
.about-client-sec .about-client-content-box {
  padding-left: 50px;
  padding-top: 15%;
  position: relative;
}
.about-client-sec .about-client-content-box .desc {
  font-size: 17px;
  line-height: 25px;
}
.about-client-sec .about-client-img img {
  border-radius: 10px;
}

.business-model-sec {
  background-color: #f5faff;
}
.business-model-sec .business-model-main-head .main-heading {
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.business-model-sec .business-model-main-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.business-model-sec .business-model-main-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.business-model-sec .business-model-main-head .desc {
  text-align: center;
}
.business-model-sec .business-model-card {
  padding: 25px 35px;
  border-radius: 5px;
  border-left: 2px solid #dee9fe;
  cursor: pointer;
  position: relative;
  transition: all 0.4s ease;
  overflow: hidden;
  z-index: 1;
}
.business-model-sec .business-model-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #0554f2;
  border-radius: 5px;
  transition: all 0.4s ease;
  z-index: -1;
}
.business-model-sec .business-model-card:hover {
  color: white;
}
.business-model-sec .business-model-card:hover .business-model-list-box .business-model-list li .card-head {
  color: white;
}
.business-model-sec .business-model-card:hover .business-model-list-box .business-model-list li .business-model-card-img .icon-w {
  opacity: 1;
  visibility: visible;
}
.business-model-sec .business-model-card:hover .business-model-list-box .business-model-list li .business-model-card-img .icon-y {
  opacity: 0;
  visibility: hidden;
}
.business-model-sec .business-model-card:hover::before {
  width: 100%;
}
.business-model-sec .business-model-card .business-model-list-box {
  padding-bottom: 20px;
  transition: all 0.4s ease;
}
.business-model-sec .business-model-card .business-model-list-box .business-model-list {
  display: flex;
  align-items: center;
}
.business-model-sec .business-model-card .business-model-list-box .business-model-list li .business-model-card-img {
  position: relative;
}
.business-model-sec .business-model-card .business-model-list-box .business-model-list li .business-model-card-img .icon-b {
  width: 45px;
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
}
.business-model-sec .business-model-card .business-model-list-box .business-model-list li .business-model-card-img .icon-w {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
}
.business-model-sec .business-model-card .business-model-list-box .business-model-list li .card-head {
  margin-left: 20px;
  margin-bottom: 0px;
  font-size: 22px;
}
.business-model-sec .business-model-card .desc {
  font-size: 16px;
  line-height: 25px;
}

.market-research-sec {
  background-color: #f6f7f9;
}
.market-research-sec .market-research-head .main-heading {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.market-research-sec .market-research-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.market-research-sec .market-research-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.market-research-sec .market-research-head .desc {
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
  line-height: 20px;
}
.market-research-sec .market-research-head .market-research-content-box {
  margin-top: 35px;
}
.market-research-sec .market-research-head .market-research-content-box .market-research-content {
  padding: 20px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-bottom: 7px solid #3740aa;
  min-height: 300px;
}
.market-research-sec .market-research-head .market-research-content-box .market-research-content .number-area {
  display: block;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.4;
  color: #03228f;
}
.market-research-sec .market-research-head .market-research-content-box .market-research-content h5 {
  margin-bottom: 10px;
  font-weight: 600;
}
.market-research-sec .market-research-head .market-research-content-box .market-research-content .para {
  font-size: 16px;
  line-height: 25px;
}
.market-research-sec .market-research-img {
  text-align: center;
  animation: 4s ease-in-out infinite floatY;
}
.market-research-sec .market-research-img img {
  width: 40%;
}

.technology-sec .technology-head {
  margin-top: 2%;
  background-color: #3740aa;
  padding: 50px 35px 50px 35px;
  border-radius: 10px 10px 10px 10px;
}
.technology-sec .technology-head .main-heading {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  color: white;
}
.technology-sec .technology-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.technology-sec .technology-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.technology-sec .technology-head .desc {
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
  line-height: 30px;
  color: white;
}
.technology-sec .technology-content-area {
  position: relative;
  border-top: 1px solid #e1e1e1;
  transition: all 0.3s ease 0s;
  margin-top: 10%;
  margin-left: 5%;
}
.technology-sec .technology-content-area::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  z-index: 1;
  left: 0;
  top: -7.5px;
}
.technology-sec .technology-content-area::after {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  z-index: 1;
  right: 0;
  top: -7.5px;
}
.technology-sec .technology-content-area .technology-card {
  position: relative;
}
.technology-sec .technology-content-area .technology-card::before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  background: #004fd2;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  top: -7.5px;
  z-index: 1;
}
.technology-sec .technology-content-area .technology-card::after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  top: 0;
  width: 1px;
  background: #e1e1e1;
  height: 100px;
  z-index: -11;
}
.technology-sec .technology-content-area .technology-card .technology-card-content {
  padding-top: 100px;
  text-align: center;
}
.technology-sec .technology-content-area .technology-card .technology-card-content .number-area {
  font-size: 42px;
  font-weight: 800;
  line-height: 80px;
  text-align: center;
  width: 80px;
  height: 80px;
  color: #ffffff;
  margin: 0 auto;
  border-radius: 50%;
  background-image: linear-gradient(90deg, #004fd2 0%, #a112dc 100%);
}
.technology-sec .technology-content-area .technology-card .technology-card-content .content-txt {
  padding: 20px 10px 0px 10px;
}
.technology-sec .technology-content-area .technology-card .technology-card-content .content-txt .desc {
  line-height: 25px;
  font-size: 16px;
}
.technology-sec .technology-content-area .technology-card-style1 {
  position: relative;
}
.technology-sec .technology-content-area .technology-card-style1::before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  background: #a112dc;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  top: -7.5px;
  z-index: 1;
}
.technology-sec .technology-content-area .technology-card-style1::after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  top: 0;
  width: 1px;
  background: #e1e1e1;
  height: 100px;
  z-index: -11;
}
.technology-sec .technology-content-area .technology-card-style1 .technology-card-content {
  padding-top: 80px;
  text-align: center;
}
.technology-sec .technology-content-area .technology-card-style1 .technology-card-content .number-area {
  font-size: 42px;
  font-weight: 800;
  line-height: 80px;
  text-align: center;
  width: 80px;
  height: 80px;
  color: #ffffff;
  margin: 0 auto;
  border-radius: 50%;
  background-image: linear-gradient(90deg, #004fd2 0%, #a112dc 100%);
}
.technology-sec .technology-content-area .technology-card-style1 .technology-card-content .content-txt {
  padding: 20px 15px 0px 15px;
}
.technology-sec .technology-content-area .technology-card-style1 .technology-card-content .content-txt .desc {
  line-height: 25px;
  font-size: 16px;
}
.technology-sec .market-research-img {
  text-align: center;
}
.technology-sec .market-research-img img {
  width: 40%;
}

.challenges-and-solutions-sec {
  background-color: whitesmoke;
}
.challenges-and-solutions-sec .challenges-solutions-main-head .main-heading {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.challenges-and-solutions-sec .challenges-solutions-main-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.challenges-and-solutions-sec .challenges-solutions-main-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.challenges-and-solutions-sec .challenges-and-solutions-img {
  text-align: center;
  margin-top: 5%;
}
.challenges-and-solutions-sec .challenges-and-solutions-img img {
  width: 85%;
}
.challenges-and-solutions-sec .challenges-solutions-content {
  margin-left: 5%;
}
.challenges-and-solutions-sec .challenges-solutions-content .challenges-solutions-card {
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(24, 16, 16, 0.07);
  position: relative;
  border-left: 8px solid transparent;
  transition: 0.3s;
  margin-bottom: 20px;
  padding: 20px 20px 20px 30px;
}
.challenges-and-solutions-sec .challenges-solutions-content .challenges-solutions-card .number-card {
  content: "";
  position: absolute;
  background-color: #3740aa;
  color: white;
  font-size: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 700;
  top: 25%;
  left: -5%;
}
.challenges-and-solutions-sec .challenges-solutions-content .challenges-solutions-card .desc {
  line-height: 20px;
  font-size: 14px;
}
.challenges-and-solutions-sec .challenges-solutions-content .challenges-solutions-card:hover {
  border-color: #3740aa;
  transform: translateY(-5px);
}

.results-growth-sec {
  background-color: #ecf2f6;
}
.results-growth-sec .results-growth-head .main-heading {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.results-growth-sec .results-growth-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.results-growth-sec .results-growth-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.results-growth-sec .results-growth-img {
  margin-right: 5%;
}
.results-growth-sec .results-growth-content .results-growth-content-box {
  margin-top: 15px;
  background-color: white;
  padding: 30px;
}
.results-growth-sec .results-growth-content .results-growth-content-box .results-growth-content-list li {
  position: relative;
  padding-left: 35px;
  line-height: 30px;
  margin-bottom: 12px;
}
.results-growth-sec .results-growth-content .results-growth-content-box .results-growth-content-list li .checkicn {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 22px;
  color: #414bbe;
}

.future-prospects-sec {
  background-color: rgba(231, 231, 231, 0.7607843137);
  min-height: 450px;
  width: 100%;
}
.future-prospects-sec .future-prospects-head {
  background-color: white;
  padding: 80px 30px 30px 30px;
  min-height: 450px;
}
.future-prospects-sec .future-prospects-head .main-heading {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.future-prospects-sec .future-prospects-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.future-prospects-sec .future-prospects-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.future-prospects-sec .future-prospects-head .future-prospects-content-box {
  padding-top: 20px;
}
.future-prospects-sec .future-prospects-head .future-prospects-content-box .future-prospects-content-list li {
  position: relative;
  padding-left: 35px;
  line-height: 30px;
  margin-bottom: 12px;
}
.future-prospects-sec .future-prospects-head .future-prospects-content-box .future-prospects-content-list li .checkicn {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 22px;
  color: #414bbe;
}
.future-prospects-sec .future-prospects-img {
  margin-left: 5%;
  margin-top: 10%;
}

.conclusion-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 250px;
  background-image: url("../images/casestudy/case-study-1/conclusion/conclusion.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.conclusion-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1e2c46;
  opacity: 0.8;
  z-index: 1;
}
.conclusion-sec .conclusion-sec-head {
  z-index: 2;
}
.conclusion-sec .conclusion-sec-head .main-heading {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  color: white;
}
.conclusion-sec .conclusion-sec-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.conclusion-sec .conclusion-sec-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.conclusion-sec .conclusion-content-box .desc {
  color: white;
  text-align: center;
  font-size: 18px;
  width: 55%;
  margin: 0 auto;
}

.huddle-heart-banner-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/Huddle-heart/banner/huddle-heart-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.huddle-heart-banner-sec .huddle-heart-banner-content {
  margin-top: 5%;
}
.huddle-heart-banner-sec .huddle-heart-banner-content .mt-30 {
  margin-top: 30px;
}
.huddle-heart-banner-sec .huddle-heart-banner-content .main-heading {
  font-size: 32px;
  margin-bottom: 10px;
  font-weight: 500;
  margin-top: 15px;
}
.huddle-heart-banner-sec .huddle-heart-banner-content .main-heading span {
  color: #fcaf17;
}
.huddle-heart-banner-sec .huddle-heart-banner-content .desc {
  font-size: 14px;
  line-height: 25px;
}
.huddle-heart-banner-sec .huddle-heart-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.huddle-heart-banner-sec .huddle-heart-banner-content .transform-button {
  color: white;
  background-color: #41b2e6;
  border: 1px solid #41b2e6;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.huddle-heart-banner-sec .huddle-heart-banner-content .transform-button:hover {
  border: 1px solid transparent;
  background: whitesmoke;
  color: #41b2e6 !important;
}
.huddle-heart-banner-sec .huddle-heart-banner-content .huddle-heart-intro-list-box {
  text-align: center;
}
.huddle-heart-banner-sec .huddle-heart-banner-content .huddle-heart-intro-list-box .huddle-heart-intro-list {
  text-align: left;
}
.huddle-heart-banner-sec .huddle-heart-banner-img {
  text-align: end;
}
.huddle-heart-banner-sec .huddle-heart-banner-img img {
  margin-top: 5%;
}

.future-prospects-sec {
  background-color: #f5faff;
}
.future-prospects-sec .business-model-main-head .main-heading {
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.future-prospects-sec .business-model-main-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.future-prospects-sec .business-model-main-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.future-prospects-sec .business-model-main-head .desc {
  text-align: center;
}
.future-prospects-sec .business-model-card {
  padding: 25px 35px;
  border-radius: 5px;
  border-left: 2px solid #b2e1f7;
  cursor: pointer;
  position: relative;
  transition: all 0.4s ease;
  overflow: hidden;
  z-index: 1;
  min-height: 300px;
}
.future-prospects-sec .business-model-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #41b2e6;
  border-radius: 5px;
  transition: all 0.4s ease;
  z-index: -1;
}
.future-prospects-sec .business-model-card:hover {
  color: white;
}
.future-prospects-sec .business-model-card:hover .business-model-list-box .business-model-list li .card-head {
  color: white;
}
.future-prospects-sec .business-model-card:hover .business-model-list-box .business-model-list li .business-model-card-img .icon-w {
  opacity: 1;
  visibility: visible;
}
.future-prospects-sec .business-model-card:hover .business-model-list-box .business-model-list li .business-model-card-img .icon-b {
  opacity: 0;
  visibility: hidden;
}
.future-prospects-sec .business-model-card:hover::before {
  width: 100%;
}
.future-prospects-sec .business-model-card .business-model-list-box {
  padding-bottom: 20px;
  transition: all 0.4s ease;
}
.future-prospects-sec .business-model-card .business-model-list-box .business-model-list {
  display: flex;
  align-items: center;
}
.future-prospects-sec .business-model-card .business-model-list-box .business-model-list li .business-model-card-img {
  position: relative;
}
.future-prospects-sec .business-model-card .business-model-list-box .business-model-list li .business-model-card-img .icon-b {
  width: 50px;
  opacity: 1;
  visibility: visible;
  transition: all 0.4s ease;
}
.future-prospects-sec .business-model-card .business-model-list-box .business-model-list li .business-model-card-img .icon-w {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
}
.future-prospects-sec .business-model-card .business-model-list-box .business-model-list li .card-head {
  margin-left: 20px;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
}
.future-prospects-sec .business-model-card .desc {
  font-size: 16px;
  line-height: 25px;
}

.huddle-heart-vision-sec {
  background-color: #f6f7f9;
}
.huddle-heart-vision-sec .market-research-head .main-heading {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.huddle-heart-vision-sec .market-research-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.huddle-heart-vision-sec .market-research-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.huddle-heart-vision-sec .market-research-head .desc {
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
  line-height: 20px;
}
.huddle-heart-vision-sec .market-research-head .market-research-content-box {
  margin-top: 35px;
}
.huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content {
  padding: 20px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-bottom: 7px solid #35b6ff;
  min-height: 370px;
}
.huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content .number-area {
  display: block;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.4;
  color: #e92392;
}
.huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content h5 {
  margin-bottom: 10px;
  font-weight: 600;
}
.huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content .para {
  font-size: 16px;
  line-height: 25px;
}
.huddle-heart-vision-sec .market-research-img {
  text-align: center;
  animation: 4s ease-in-out infinite floatY;
  margin-top: 5%;
}
.huddle-heart-vision-sec .market-research-img img {
  width: 50%;
}

.huddle-heart-features-sec .column {
  padding: 0px 20px;
}
.huddle-heart-features-sec .sec-title {
  margin-bottom: 50px;
}
.huddle-heart-features-sec .sec-title .main-heading {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.huddle-heart-features-sec .sec-title .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.huddle-heart-features-sec .sec-title .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.huddle-heart-features-sec .pt-110 {
  padding-top: 110px;
}
.huddle-heart-features-sec .pt-60 {
  padding-top: 60px;
}
.huddle-heart-features-sec .mb-90 {
  margin-bottom: 90px;
}
.huddle-heart-features-sec .huddle-heart-features-card {
  position: relative;
  display: block;
  padding: 20px 25px 50px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.07);
  transition: all 300ms ease;
  cursor: pointer;
  min-height: 454px;
}
.huddle-heart-features-sec .huddle-heart-features-card:hover {
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
}
.huddle-heart-features-sec .huddle-heart-features-card:hover::before {
  transform: scale(1);
  transition: all 300ms ease;
}
.huddle-heart-features-sec .huddle-heart-features-card:hover .read-more-box .more-link {
  background-color: #f74883;
  color: white;
}
.huddle-heart-features-sec .huddle-heart-features-card::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  border-bottom: 2px solid #35b6ff;
  transform: scale(0);
  transition: all 300ms ease;
}
.huddle-heart-features-sec .huddle-heart-features-card .huddle-heart-features-icon {
  margin-bottom: 25px;
}
.huddle-heart-features-sec .huddle-heart-features-card .huddle-heart-features-icon img {
  width: 25%;
}
.huddle-heart-features-sec .huddle-heart-features-card .card-heading {
  font-weight: 600;
}
.huddle-heart-features-sec .huddle-heart-features-card .desc {
  padding-top: 10px;
}
.huddle-heart-features-sec .huddle-heart-features-card .read-more-box {
  position: absolute;
  bottom: -28px;
  left: 0;
  width: 100%;
  display: block;
}
.huddle-heart-features-sec .huddle-heart-features-card .read-more-box .more-link {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 53px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 32px;
  color: #f74883;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05), 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
  transition: all 300ms ease;
}
.huddle-heart-features-sec .huddle-heart-features-card .read-more-box .more-link:hover {
  color: white !important;
}

.improved-safety-sec .huddle-heart-technology-head .main-heading {
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
  color: black;
}
.improved-safety-sec .huddle-heart-technology-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.improved-safety-sec .huddle-heart-technology-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.improved-safety-sec .huddle-heart-technology-head .desc {
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
  line-height: 30px;
  color: white;
}
.improved-safety-sec .technology-content-area {
  position: relative;
  border-top: 1px solid #e1e1e1;
  transition: all 0.3s ease 0s;
  margin-top: 3%;
}
.improved-safety-sec .technology-content-area::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  z-index: 1;
  left: 0;
  top: -7.5px;
}
.improved-safety-sec .technology-content-area::after {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  z-index: 1;
  right: 0;
  top: -7.5px;
}
.improved-safety-sec .technology-content-area .technology-card {
  position: relative;
}
.improved-safety-sec .technology-content-area .technology-card::before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  background: #35b6ff;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  top: -7.5px;
  z-index: 1;
}
.improved-safety-sec .technology-content-area .technology-card::after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  top: 0;
  width: 1px;
  background: #e1e1e1;
  height: 100px;
  z-index: -11;
}
.improved-safety-sec .technology-content-area .technology-card .huddle-heart-technology-card-content {
  padding-top: 100px;
  text-align: center;
}
.improved-safety-sec .technology-content-area .technology-card .huddle-heart-technology-card-content .number-area {
  font-size: 42px;
  font-weight: 800;
  line-height: 80px;
  text-align: center;
  width: 80px;
  height: 80px;
  color: #ffffff;
  margin: 0 auto;
  border-radius: 50%;
  background-image: linear-gradient(90deg, #35b6ff 0%, #e92392 100%);
}
.improved-safety-sec .technology-content-area .technology-card .huddle-heart-technology-card-content .content-txt {
  padding: 20px 10px 0px 10px;
}
.improved-safety-sec .technology-content-area .technology-card .huddle-heart-technology-card-content .content-txt .desc {
  line-height: 25px;
  font-size: 16px;
}
.improved-safety-sec .technology-content-area .technology-card-style1 {
  position: relative;
}
.improved-safety-sec .technology-content-area .technology-card-style1::before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  background: #e92392;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  top: -7.5px;
  z-index: 1;
}
.improved-safety-sec .technology-content-area .technology-card-style1::after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  top: 0;
  width: 1px;
  background: #e1e1e1;
  height: 100px;
  z-index: -11;
}
.improved-safety-sec .technology-content-area .technology-card-style1 .technology-card-content {
  padding-top: 100px;
  text-align: center;
}
.improved-safety-sec .technology-content-area .technology-card-style1 .technology-card-content .number-area {
  font-size: 42px;
  font-weight: 800;
  line-height: 80px;
  text-align: center;
  width: 80px;
  height: 80px;
  color: #ffffff;
  margin: 0 auto;
  border-radius: 50%;
  background-image: linear-gradient(90deg, #35b6ff 0%, #e92392 100%);
}
.improved-safety-sec .technology-content-area .technology-card-style1 .technology-card-content .content-txt {
  padding: 20px 15px 0px 15px;
}
.improved-safety-sec .technology-content-area .technology-card-style1 .technology-card-content .content-txt .desc {
  line-height: 25px;
  font-size: 16px;
}

.enhanced-communication-sec {
  background-color: #f5fbff;
}
.enhanced-communication-sec .enhanced-communication-main-head .main-heading {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
  color: black;
}
.enhanced-communication-sec .enhanced-communication-main-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.enhanced-communication-sec .enhanced-communication-main-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.enhanced-communication-sec .enhanced-img {
  width: 70%;
  min-height: 620px;
  background-color: #282828;
  border-radius: 37px;
  box-shadow: inset 0 -8px 20px rgba(0, 0, 0, 0.05), 1px 12px 44px rgba(0, 0, 0, 0.01), 2px 23px 64px rgba(0, 0, 0, 0.04);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.enhanced-communication-sec .enhanced-img img {
  width: 95%;
}
.enhanced-communication-sec .enhanced-img::before {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #f74f88;
  width: 100%;
  top: 11%;
  left: -101%;
}
.enhanced-communication-sec .enhanced-img::after {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #f74f88;
  width: 100%;
  top: 11%;
  right: -101%;
}
.enhanced-communication-sec .enhanced-communication-card {
  margin-top: 60%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  min-height: 348px;
  position: relative;
  transition: all 300ms ease;
  cursor: pointer;
}
.enhanced-communication-sec .enhanced-communication-card::before {
  content: "";
  position: absolute;
  background-color: #f74f88;
  left: 0;
  right: 0;
  width: 1px;
  top: -52%;
  height: 52%;
  margin: 0 auto;
}
.enhanced-communication-sec .enhanced-communication-card .enhanced-communication-icon {
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05), 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  margin-bottom: 15px;
}
.enhanced-communication-sec .enhanced-communication-card .enhanced-communication-icon img {
  width: 60%;
}
.enhanced-communication-sec .enhanced-communication-card .desc {
  line-height: 25px;
}

.our-works-sample {
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/Huddle-heart/works/work-banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.our-works-sample::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
}

.huddle-heart-impacts-sec {
  background-color: whitesmoke;
}
.huddle-heart-impacts-sec .huddle-heart-impacts-img {
  margin-top: 10%;
}
.huddle-heart-impacts-sec .huddle-heart-impacts-img img {
  min-height: 300px;
}
.huddle-heart-impacts-sec .huddle-heart-impacts-head .main-heading {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
  color: black;
}
.huddle-heart-impacts-sec .huddle-heart-impacts-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.huddle-heart-impacts-sec .huddle-heart-impacts-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.huddle-heart-impacts-sec .huddle-heart-impacts-card {
  border: 2px dashed #d9d9d9;
  border-radius: 10px;
  text-align: center;
  position: relative;
  padding: 15px;
  z-index: 1;
  min-height: 356px;
}
.huddle-heart-impacts-sec .huddle-heart-impacts-card .huddle-heart-impacts-icon {
  width: 75px;
  height: 75px;
  background-color: white;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05), 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 15px;
  line-height: 75px;
}
.huddle-heart-impacts-sec .huddle-heart-impacts-card .huddle-heart-impacts-icon img {
  width: 60%;
}
.huddle-heart-impacts-sec .huddle-heart-impacts-card .desc {
  font-size: 16px;
  line-height: 25px;
}

.challenges-lessons-huddle-heart {
  background-color: whitesmoke;
}
.challenges-lessons-huddle-heart .challenges-lessons-main-head .main-heading {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.challenges-lessons-huddle-heart .challenges-lessons-main-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.challenges-lessons-huddle-heart .challenges-lessons-main-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.challenges-lessons-huddle-heart .challenges-lessons-img {
  text-align: center;
}
.challenges-lessons-huddle-heart .challenges-lessons-img img {
  width: 100%;
}
.challenges-lessons-huddle-heart .challenges-lessons-content {
  margin-top: 8%;
}
.challenges-lessons-huddle-heart .challenges-lessons-content .challenges-lessons-card {
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(24, 16, 16, 0.07);
  padding: 15px 15px 15px 40px;
  min-height: 136px;
  border-radius: 5px;
  transition: 0.3s;
  margin-bottom: 25px;
  position: relative;
}
.challenges-lessons-huddle-heart .challenges-lessons-content .challenges-lessons-card .challenges-lessons-icon {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05), 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
  transition: all 300ms ease;
  line-height: 60px;
  left: -4%;
  top: 30%;
}
.challenges-lessons-huddle-heart .challenges-lessons-content .challenges-lessons-card .challenges-lessons-icon img {
  width: 60%;
}
.challenges-lessons-huddle-heart .challenges-lessons-content .challenges-lessons-card .card-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.challenges-lessons-huddle-heart .challenges-lessons-content .challenges-lessons-card .desc {
  font-size: 14px;
  line-height: 20px;
}
.challenges-lessons-huddle-heart .challenges-solutions-card {
  background: #ffffff;
  box-shadow: 1px 12px 25px rgba(15, 51, 107, 0.1);
  position: relative;
  transition: 0.3s;
  margin-bottom: 30px;
  padding: 25px;
  border-radius: 16px;
  min-height: 550px;
  cursor: pointer;
}
.challenges-lessons-huddle-heart .challenges-solutions-card .main-head {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
.challenges-lessons-huddle-heart .challenges-solutions-card .challenges-solutions-card-icon {
  text-align: center;
}
.challenges-lessons-huddle-heart .challenges-solutions-card .challenges-solutions-card-icon img {
  width: 20%;
}
.challenges-lessons-huddle-heart .challenges-solutions-card .problem-content {
  padding-top: 20px;
}
.challenges-lessons-huddle-heart .challenges-solutions-card .problem-content h6 {
  font-size: 18px;
}
.challenges-lessons-huddle-heart .challenges-solutions-card .solution-content {
  padding-top: 20px;
}
.challenges-lessons-huddle-heart .challenges-solutions-card .solution-content h6 {
  font-size: 18px;
}
.challenges-lessons-huddle-heart .challenges-solutions-card .number-card {
  content: "";
  position: absolute;
  background-color: #35b6ff;
  color: white;
  font-size: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 700;
  top: 25%;
  left: -5%;
}
.challenges-lessons-huddle-heart .challenges-solutions-card .desc {
  line-height: 25px;
  font-size: 16px;
}
.challenges-lessons-huddle-heart .challenges-solutions-card:hover {
  transform: translateY(-5px);
}
.challenges-lessons-huddle-heart .mt_76 {
  margin-top: 76px;
}

.results-growth-huddle-heart {
  background-color: #ecf2f6;
}
.results-growth-huddle-heart .results-growth-head .main-heading {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.results-growth-huddle-heart .results-growth-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.results-growth-huddle-heart .results-growth-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.results-growth-huddle-heart .results-growth-img {
  margin-right: 5%;
}
.results-growth-huddle-heart .results-growth-content .results-growth-content-box {
  margin-top: 15px;
  background-color: white;
  padding: 30px;
}
.results-growth-huddle-heart .results-growth-content .results-growth-content-box .results-growth-content-list li {
  position: relative;
  padding-left: 35px;
  line-height: 30px;
  margin-bottom: 12px;
}
.results-growth-huddle-heart .results-growth-content .results-growth-content-box .results-growth-content-list li .checkicn {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 22px;
  color: #35b6ff;
}

.future-prospects-huddle-heart {
  background-color: rgba(231, 231, 231, 0.7607843137);
  min-height: 450px;
  width: 100%;
}
.future-prospects-huddle-heart .future-prospects-head {
  background-color: white;
  padding: 80px 30px 30px 30px;
  min-height: 450px;
}
.future-prospects-huddle-heart .future-prospects-head .main-heading {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.future-prospects-huddle-heart .future-prospects-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.future-prospects-huddle-heart .future-prospects-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #fcaf17;
  bottom: 0;
  border-radius: 100px;
}
.future-prospects-huddle-heart .future-prospects-head .future-prospects-content-box {
  padding-top: 20px;
}
.future-prospects-huddle-heart .future-prospects-head .future-prospects-content-box .future-prospects-content-list li {
  position: relative;
  padding-left: 35px;
  line-height: 30px;
  margin-bottom: 12px;
}
.future-prospects-huddle-heart .future-prospects-head .future-prospects-content-box .future-prospects-content-list li .checkicn {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 22px;
  color: #35b6ff;
}
.future-prospects-huddle-heart .future-prospects-img {
  margin-left: 5%;
  margin-top: 10%;
}

.huddle-heart-conclusion-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 250px;
  background-image: url("../images/casestudy/Huddle-heart/conclusion/conclusion.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.huddle-heart-conclusion-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.7;
  z-index: 1;
}
.huddle-heart-conclusion-sec .conclusion-sec-head {
  z-index: 2;
}
.huddle-heart-conclusion-sec .conclusion-sec-head .main-heading {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  color: white;
}
.huddle-heart-conclusion-sec .conclusion-content-box .desc {
  color: white;
  text-align: center;
  font-size: 18px;
}

.how-we-design-date .how-we-design-main-heading h1 {
  font-size: 84px;
  color: #1d1e22;
  font-weight: 600;
}
.how-we-design-date .how-we-design-date-img {
  width: 342px;
  height: 712px;
  background-color: #282828;
  border-radius: 37px;
  box-shadow: inset 0 -8px 20px rgba(0, 0, 0, 0.05), 1px 12px 44px rgba(0, 0, 0, 0.01), 2px 23px 64px rgba(0, 0, 0, 0.04);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.how-we-design-date .how-we-design-date-img img {
  width: 318px;
  height: 686px;
}
.how-we-design-date .how-we-design-date-content {
  padding-top: 144px;
}
.how-we-design-date .how-we-design-date-content h3 {
  font-size: 64px;
  color: #1d1e22;
  font-weight: 600;
}
.how-we-design-date .how-we-design-date-content .desc {
  font-size: 36px;
  color: #1d1e22;
  line-height: 50px;
  font-weight: 600;
  padding-top: 30px;
}

.profile-banner-sec {
  width: 100%;
  min-height: 550px;
  background-image: linear-gradient(#c390ae, #578ea1);
}
.profile-banner-sec .screen-mob {
  width: 80%;
  min-height: 712px;
  background-color: #282828;
  border-radius: 37px;
  box-shadow: inset 0 -8px 20px rgba(0, 0, 0, 0.05), 1px 12px 44px rgba(0, 0, 0, 0.01), 2px 23px 64px rgba(0, 0, 0, 0.04);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-banner-sec .mt-80 {
  margin-top: 80px;
}

.usability-testing-sec .usability-heading h3 {
  font-size: 34px;
  color: #1d1e22;
  font-weight: 600;
}
.usability-testing-sec .usability-testing-content .desc {
  font-size: 20px;
  color: #1d1e22;
}
.usability-testing-sec .usability-testing-content .usability-list-box {
  margin-top: 25px;
}
.usability-testing-sec .usability-testing-content .usability-list-box .usability-list h5 {
  font-size: 22px;
}
.usability-testing-sec .usability-testing-content .usability-list-box .usability-list li {
  font-size: 18px;
  color: #1d1e22;
}

.huddle-heart-intro-sec .intro-head h4 {
  font-size: 28px;
  line-height: 42px;
}
.huddle-heart-intro-sec .huddle-heart-intro-content {
  background-image: linear-gradient(45deg, #35b6ff 0%, #e92392 100%);
  padding: 30px;
  border-radius: 20px;
}
.huddle-heart-intro-sec .huddle-heart-intro-content .main-heading {
  font-size: 22px;
  color: white;
  font-weight: 600;
  margin-bottom: 5px;
}
.huddle-heart-intro-sec .huddle-heart-intro-content .huddle-heart-intro-list {
  padding-left: 25px;
}
.huddle-heart-intro-sec .huddle-heart-intro-content .huddle-heart-intro-list li {
  color: #ddd8d8;
  list-style: disc;
  font-size: 14px;
}
.huddle-heart-intro-sec .huddle-heart-intro-content .desc {
  font-size: 18px;
  color: #1d1e22;
  font-weight: 500;
  margin-top: 10px;
  color: white;
}
.huddle-heart-intro-sec .huddle-heart-intro-img {
  margin-right: 5%;
}
.huddle-heart-intro-sec .huddle-heart-intro-img img {
  border-radius: 20px;
  min-height: 547px;
}
.huddle-heart-intro-sec .huddle-heart-intro-img .main-intro-img {
  position: absolute;
  z-index: 1;
}
.huddle-heart-intro-sec .huddle-heart-intro-img .main-intro-img img {
  width: 40%;
}
.huddle-heart-intro-sec .huddle-heart-intro-img .heart-shape-img {
  position: absolute;
  z-index: -1;
  width: 35%;
  top: 70px;
  left: 7%;
}
.huddle-heart-intro-sec .huddle-heart-intro-img .eclipise-shape-img {
  position: absolute;
  z-index: -1;
  top: 100px;
  left: 0%;
  width: 90%;
}

.plywood-bazar-banner-sec {
  background-color: #f5f5f5;
  min-height: 550px;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content {
  margin-top: 5%;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .sub-head {
  color: #000000;
  font-weight: 600;
  font-size: 15px;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .logo-img img {
  width: 45%;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .mt-30 {
  margin-top: 30px;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .main-heading {
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: 500;
  margin-top: 10px;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .main-heading span {
  color: #fcaf17;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .desc {
  font-size: 14px;
  line-height: 25px;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .transform-button {
  color: white;
  background-color: #b08218;
  border: 1px solid #b08218;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .transform-button:hover {
  border: 1px solid #b08218;
  background: transparent;
  color: #b08218 !important;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .plywood-bazar-intro-list-box {
  text-align: center;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .plywood-bazar-intro-list-box .plywood-bazar-intro-list {
  text-align: left;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-content .plywood-bazar-intro-list-box .plywood-bazar-intro-list li {
  font-size: 14px;
}
.plywood-bazar-banner-sec .plywood-bazar-banner-img {
  text-align: end;
  margin-top: 5%;
}

.plywood-bazar-about-us {
  background-color: #edf2f9;
  position: relative;
}
.plywood-bazar-about-us .about-content-box {
  margin-top: 8%;
}
.plywood-bazar-about-us .about-content-box .sub-heading {
  color: #b08218;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.plywood-bazar-about-us .about-content-box .main-heading {
  font-weight: 500;
  font-size: 42px;
  line-height: 56px;
  letter-spacing: 0.02em;
  color: #212529;
}
.plywood-bazar-about-us .about-content-list-box {
  padding-top: 10px;
}
.plywood-bazar-about-us .about-content-list-box .about-content-list li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 35px;
  font-size: 15px;
  line-height: 25px;
}
.plywood-bazar-about-us .about-content-list-box .about-content-list li h5 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0px;
}
.plywood-bazar-about-us .about-content-list-box .about-content-list li .circle-check {
  content: "";
  position: absolute;
  color: #b08218;
  left: 0;
  font-size: 20px;
  top: 0;
  transform: translate(0px, 10px);
}
.plywood-bazar-about-us .bg-shape-1 {
  position: absolute;
  top: 0;
  left: 0%;
}
.plywood-bazar-about-us .bg-shape-1 img {
  width: 40%;
}
.plywood-bazar-about-us .bg-shape-2 {
  position: absolute;
  bottom: 0;
  left: 0%;
}
.plywood-bazar-about-us .bg-shape-2 img {
  width: 60%;
}
.plywood-bazar-about-us .bg-shape-3 {
  position: absolute;
  bottom: 0%;
  right: 0%;
  text-align: end;
}
.plywood-bazar-about-us .bg-shape-3 img {
  width: 60%;
}

.our-client-requirement {
  background-color: #f9f9f9;
}
.our-client-requirement .about-client-area .main-head {
  font-size: 34px;
  color: #b18319;
  font-style: italic;
}
.our-client-requirement .about-client-area .desc {
  font-size: 18px;
  padding-top: 1%;
}
.our-client-requirement .about-client-area .client-hand-shake {
  padding-top: 4%;
}
.our-client-requirement .client-req-area {
  margin-left: 5%;
}
.our-client-requirement .client-req-area .main-head {
  font-size: 34px;
  color: #b18319;
}
.our-client-requirement .client-req-area .client-req-list {
  padding-top: 1%;
}
.our-client-requirement .client-req-area .client-req-list li {
  border-left: 4px solid #c79d3b;
  margin-bottom: 20px;
  padding-left: 15px;
  font-size: 18px;
}

.main-header {
  background-color: #020202;
  padding: 20px 0px;
}

.main-logo img {
  width: 45%;
}

.header-contact-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-contact-area .header-contact-list {
  margin: 0px !important;
  display: flex;
  align-items: center;
}

.a-i-c {
  align-items: center;
}

.header-contact-area .header-contact-list li {
  margin-left: 20px;
}

.header-contact-area .header-contact-list li a {
  color: white;
  font-size: 18px;
  transition: all 0.3s;
}

.header-contact-area .header-contact-list li a svg {
  font-size: 18px;
  margin-right: 5px;
}

.header-contact-area .header-contact-list li a:hover {
  color: #fcaf17;
  text-decoration: none !important;
}

.req-quote-buttn {
  animation: pulse-animation 2s infinite;
  background-color: #fcaf17;
  border-radius: 2px;
  box-shadow: 0 0 0 #fcaf17;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 24px;
  transition: all 0.5s;
  margin-left: 20px;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 #fcaf17;
  }
  100% {
    box-shadow: 0 0 0 20px transparent;
  }
}
.mission-or-vision-plywood {
  position: relative;
  background-color: #f6f6f6;
  min-height: 530px;
}
.mission-or-vision-plywood .mission-vision-wrapper {
  position: relative;
  /* what-we-offer-sec */
  /* .get-quote-buttn:hover::after
  {
    opacity: .3;
      transform-origin: 100px 100px;
      transform: scale(1) translate(-10px,-70px);
  } */
  /* industries-we-have worked with */
  /* success-delivered-sec */
  /* lets-connect-sec */
}
.mission-or-vision-plywood .mission-vision-wrapper .main-banner-sec .main-banner-content .main-heading {
  font-size: 58px;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
}
.mission-or-vision-plywood .mission-vision-wrapper .main-banner-content .main-heading span {
  color: #fcaf17;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fcaf17;
}
.mission-or-vision-plywood .mission-vision-wrapper .main-banner-content {
  margin-top: 6%;
  /* padding-left: 2%; */
}
.mission-or-vision-plywood .mission-vision-wrapper .main-banner-content h3 {
  color: #fcaf18;
}
.mission-or-vision-plywood .mission-vision-wrapper .main-banner-img {
  text-align: end;
}
.mission-or-vision-plywood .mission-vision-wrapper .main-banner-img img {
  width: 80%;
}
.mission-or-vision-plywood .mission-vision-wrapper .mt-25 {
  margin-top: 25px;
}
.mission-or-vision-plywood .mission-vision-wrapper .quick-enquiry-buttn {
  background-color: #fcaf18;
  padding: 12px 24px;
  border: 1px solid #fcaf17;
  color: white;
  font-weight: 600;
  border-radius: 5px;
}
.mission-or-vision-plywood .mission-vision-wrapper .quick-enquiry-buttn:hover {
  color: white !important;
}
.mission-or-vision-plywood .mission-vision-wrapper .main-banner-sec .main-banner-content .desc {
  color: #e5e5e5;
}
.mission-or-vision-plywood .mission-vision-wrapper .contact-form-box {
  padding: 25px 25px 15px 25px;
  z-index: 99;
  background: #fff;
  /* box-shadow: 3px 4px 22px #000; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  margin-top: 12%;
  border-radius: 5px;
  animation: updown 4s ease-in-out infinite;
}
@keyframes updown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
.mission-or-vision-plywood .mission-vision-wrapper .contact-form-box .form-head {
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
}
.mission-or-vision-plywood .mission-vision-wrapper .contact-form-box .desc {
  font-size: 14px;
  font-weight: 600;
}
.mission-or-vision-plywood .mission-vision-wrapper .contact-form-box .contact-form-body input {
  font-size: 14px;
}
.mission-or-vision-plywood .mission-vision-wrapper .contact-form-box .contact-form-body textarea {
  font-size: 14px;
}
.mission-or-vision-plywood .mission-vision-wrapper input:focus {
  box-shadow: none !important;
  border-color: #fcaf17 !important;
  outline: none !important;
}
.mission-or-vision-plywood .mission-vision-wrapper .enquiry-now-buttn {
  background-color: #fcaf17;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  display: block;
  text-align: center;
  transition: all 0.4s;
  position: relative;
}
.mission-or-vision-plywood .mission-vision-wrapper .enquiry-now-buttn::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 300px;
  height: 200px;
  background-color: #333;
  border-color: transparent;
  border-radius: 50%;
  transform: translate(-40px, -80px) scale(0.1);
  opacity: 0;
  z-index: -1;
  transition: all 0.5s;
}
.mission-or-vision-plywood .mission-vision-wrapper .enquiry-now-buttn:hover {
  background-color: #020202;
  color: white;
}
.mission-or-vision-plywood .mission-vision-wrapper .message-textarea:focus {
  box-shadow: none !important;
  border-color: #fcaf17 !important;
  outline: none !important;
}
.mission-or-vision-plywood .mission-vision-wrapper .py-40 {
  padding: 40px 0px;
}
.mission-or-vision-plywood .mission-vision-wrapper .what-we-offer-sec {
  background-color: #171717;
}
.mission-or-vision-plywood .mission-vision-wrapper .what-we-offer-heading .sub-head {
  color: #fcaf17;
}
.mission-or-vision-plywood .mission-vision-wrapper .what-we-offer-heading .main-head {
  color: white;
  font-size: 26px;
  font-weight: 700;
}
.mission-or-vision-plywood .mission-vision-wrapper .what-we-offer-heading .main-head span {
  color: #fcaf17;
}
.mission-or-vision-plywood .mission-vision-wrapper .what-we-offer-heading-content .desc {
  color: #e5e5e5;
  margin: 0px !important;
}
.mission-or-vision-plywood .mission-vision-wrapper .what-we-offer-card {
  background-color: white;
  border-radius: 5px;
  padding: 25px;
  margin-bottom: 25px;
  min-height: 372px;
}
.mission-or-vision-plywood .mission-vision-wrapper .what-we-offer-icon {
  margin-bottom: 20px;
}
.mission-or-vision-plywood .mission-vision-wrapper .what-we-offer-content h3 {
  font-size: 18px;
  font-weight: 600;
}
.mission-or-vision-plywood .mission-vision-wrapper .get-quote-buttn {
  position: relative;
  background-color: #fcaf17;
  color: white;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}
.mission-or-vision-plywood .mission-vision-wrapper .get-quote-buttn:hover {
  color: white;
}
.mission-or-vision-plywood .mission-vision-wrapper .why-choose-us-sec {
  background-color: #000;
}
.mission-or-vision-plywood .mission-vision-wrapper .why-choose-us-heading-area {
  text-align: center;
}
.mission-or-vision-plywood .mission-vision-wrapper .why-choose-us-heading-area .sub-heading {
  font-size: 16px;
  color: #fcaf17;
}
.mission-or-vision-plywood .mission-vision-wrapper .why-choose-us-heading-area .main-heading {
  font-size: 34px;
  font-weight: 700;
  color: white;
}
.mission-or-vision-plywood .mission-vision-wrapper .why-choose-us-heading-area .main-heading span {
  color: #fcaf17;
}
.mission-or-vision-plywood .mission-vision-wrapper .why-choose-us-card {
  border: solid 1px #7e7e7e;
  border-radius: 0px;
  padding: 55px 20px;
  text-align: center;
  background: rgb(38, 38, 38);
  margin-bottom: 20px;
  background: linear-gradient(180deg, rgb(38, 38, 38) 0%, rgb(67, 67, 67) 84%, rgb(69, 69, 69) 100%);
}
.mission-or-vision-plywood .mission-vision-wrapper .why-choose-us-content h5 {
  color: white;
  margin-top: 25px;
  font-size: 23px;
}
.mission-or-vision-plywood .mission-vision-wrapper .mt-35 {
  margin-top: 35px;
}
.mission-or-vision-plywood .mission-vision-wrapper .app-development-process {
  background-color: #171717;
}
.mission-or-vision-plywood .mission-vision-wrapper .app-development-main-head h3 {
  font-size: 45px;
  color: white;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 50px;
}
.mission-or-vision-plywood .mission-vision-wrapper .app-development-main-head h3 span {
  color: #fcaf17;
}
.mission-or-vision-plywood .mission-vision-wrapper .ptb-100 {
  padding: 80px 0px;
}
.mission-or-vision-plywood .mission-vision-wrapper .industries-work-with-main-head h2 {
  color: white;
  font-size: 34px;
}
.mission-or-vision-plywood .mission-vision-wrapper .success-delivered-sec {
  background-color: #000;
}
.mission-or-vision-plywood .mission-vision-wrapper .success-delivered-main-head .main-heading {
  color: white;
  font-size: 34px;
  font-weight: 700;
}
.mission-or-vision-plywood .mission-vision-wrapper .success-delivered-main-head .sub-heading {
  color: #fcaf17;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
}
.mission-or-vision-plywood .mission-vision-wrapper .success-delivered-sec .clients-logo {
  margin: 10px 0px;
}
.mission-or-vision-plywood .mission-vision-wrapper .success-delivered-content .desc {
  font-size: 18px;
  color: white;
}
.mission-or-vision-plywood .mission-vision-wrapper .letconnects-sec {
  width: 100%;
  margin: 0;
  padding: 56px 0;
  overflow: hidden;
  background-image: url("../../assets/images/LandingPage/lets-connect-sec/connect.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.mission-or-vision-plywood .mission-vision-wrapper .content-part .cnct-heading {
  width: 100%;
  display: block;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 15px;
  color: #fff;
  font-weight: 500;
}
.mission-or-vision-plywood .mission-vision-wrapper .content-part .cnct-heading span {
  font-weight: 300;
}
.mission-or-vision-plywood .mission-vision-wrapper .content-part p {
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: #fff;
}
.mission-or-vision-plywood .mission-vision-wrapper .letconnects-sec input {
  margin: 10px 0px;
}
.mission-or-vision-plywood .mission-vision-wrapper .letconnects-sec input[type=submit] {
  background-color: #ba464a;
  border-color: transparent;
  font-size: 16px;
  border-radius: 5px;
  color: white;
  padding: 7px 20px;
  text-transform: uppercase;
}
.mission-or-vision-plywood .mission-vision-wrapper::after {
  position: absolute;
  content: "";
  background-color: #c5a766;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -48px;
}
.mission-or-vision-plywood .left-side-box {
  position: relative;
  font-size: 16px;
  padding: 25px;
  border-radius: 10px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-top: 10%;
}
.mission-or-vision-plywood .left-side-box::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: -17px;
  top: 40px;
  box-sizing: border-box;
  border: 10px solid black;
  border-color: transparent transparent #fdfdfd #fdfdfd;
  transform-origin: 0 0;
  transform: rotate(-132deg);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
}
.mission-or-vision-plywood .right-side-box {
  position: relative;
  font-size: 16px;
  padding: 25px;
  border-radius: 10px;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-top: 63%;
}
.mission-or-vision-plywood .right-side-box::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: unset;
  left: 0px;
  top: 20px;
  transform: rotate(42deg);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border: 10px solid black;
  border-color: transparent transparent #fdfdfd #fdfdfd;
  transform-origin: 0 0;
}
.mission-or-vision-plywood .middle-box {
  display: flex;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: 0 auto;
  border: 6px solid #707070;
  background-color: #c5a766;
  color: white;
  position: relative;
  margin-bottom: 60px;
}
.mission-or-vision-plywood .middle-box1 {
  display: flex;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: 0 auto;
  border: 6px solid #707070;
  background-color: #c5a766;
  color: white;
  position: relative;
}
.mission-or-vision-plywood .middle-box1::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border: 1px dashed #707070;
  width: 2px;
  height: 62px;
  top: -69px;
  margin: 0 auto;
}
.mission-or-vision-plywood .middle-box1::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border: 1px dashed #707070;
  width: 2px;
  height: 62px;
  bottom: -69px;
  margin: 0 auto;
}
.mission-or-vision-plywood .mission-vision-left-shape {
  position: absolute;
  left: 0;
  bottom: 30px;
}
.mission-or-vision-plywood .mission-vision-right-shape {
  position: absolute;
  right: 0;
  top: 0;
}

.solution-given-sec {
  background-color: #f3f4f8 !important;
}
.solution-given-sec .solution-given-content .sub-heading {
  color: #b08218;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 600;
}
.solution-given-sec .solution-given-content .main-heading {
  color: #b08218;
  font-size: 34px;
}
.solution-given-sec .solution-given-content .desc {
  margin-bottom: 25px;
}
.solution-given-sec .solution-given-content .solution-given-list-box .solution-given-list {
  padding-left: 15px;
  margin-top: 15px;
}
.solution-given-sec .solution-given-content .solution-given-list-box .solution-given-list li {
  padding-left: 10px;
  list-style: decimal;
}
.solution-given-sec .solution-given-content .solution-given-list-box .solution-given-list li h5 {
  font-size: 18px;
}
.solution-given-sec .solution-given-content .solution-given-list-box .solution-given-list li::marker {
  color: #b08218;
  font-size: 20px;
  font-weight: 600;
}
.solution-given-sec .solution-given-img {
  text-align: end;
  margin-top: 35%;
}
.solution-given-sec .solution-given-img img {
  animation: rotatefull 5s infinite ease-in;
}
@keyframes rotatefull {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.plywoodbazar-features-sec {
  background-color: #edf2f9;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.plywoodbazar-features-sec ::-webkit-scrollbar {
  width: 4px !important;
}
.plywoodbazar-features-sec ::-webkit-scrollbar-track {
  background: transparent !important;
}
.plywoodbazar-features-sec ::-webkit-scrollbar-thumb {
  background: #b08218 !important;
}
.plywoodbazar-features-sec ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.plywoodbazar-features-sec .plywoodbazar-features-content .plywoodbazar-features-head .main-heading {
  font-size: 38px;
  color: #b08218;
  margin-bottom: 20px;
}
.plywoodbazar-features-sec .plywoodbazar-features-content .plywoodbazar-features-head .sub-heading {
  color: #b08218;
}
.plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box {
  height: 525px;
  overflow-y: auto;
}
.plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list {
  height: 480px;
  overflow-y: auto;
  padding-left: 25px;
}
.plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li {
  list-style-type: decimal;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #b08218;
}
.plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li::marker {
  color: #b08218;
  font-size: 21px;
}
.plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li .heading {
  font-size: 20px;
  color: #b08218;
  font-weight: 500;
}
.plywoodbazar-features-sec .plywoodbazar-features-img {
  margin-top: 10%;
  animation: 4s ease-in-out infinite floatY;
  transition: 4s;
}
.plywoodbazar-features-sec .j-c-sb {
  justify-content: space-between;
}

.plywoodbazar-result-sec {
  background-color: #e7e7e7;
}
.plywoodbazar-result-sec .plywoodbazar-result-main-head {
  text-align: center;
}
.plywoodbazar-result-sec .plywoodbazar-result-main-head .main-heading {
  font-size: 38px;
  color: #b08218;
  margin-bottom: 20px;
}
.plywoodbazar-result-sec .plywoodbazar-result-main-head .desc {
  color: black;
  font-weight: 600;
  font-size: 17px;
  width: 80%;
  margin: 0 auto;
}
.plywoodbazar-result-sec .plywoodbazar-result-card {
  transform: scale(0.85);
  transition: all 0.5s;
}
.plywoodbazar-result-sec .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
  /* padding: 20px; */
  padding: 20px 10px;
}
.plywoodbazar-result-sec .swiper-slide.swiper-slide-active .plywoodbazar-result-card {
  overflow: hidden;
  transform: scale(1.05);
  transition: all 0.5s;
}

.our-work-screens {
  background-color: #edf2f9;
  min-height: 500px;
  background-image: url("../images/casestudy/plywoodbazar/our-work-screens/work-screens.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.our-work-screens::after {
  content: "";
  position: absolute;
  background-color: #2c2c2c;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 2;
  top: 0;
}
.our-work-screens .our-work-screens-main-head {
  position: absolute;
  width: 23%;
}
.our-work-screens .our-work-screens-main-head .main-heading {
  color: rgb(136, 104, 30);
  font-size: 48px;
  font-weight: 600;
}
.our-work-screens .our-work-screens-main-head .main-heading span {
  color: #1f173a;
}

.RoCare-banner-sec {
  width: 100%;
  min-height: 550px;
  background: linear-gradient(90deg, rgb(245, 245, 245) 20%, rgb(139, 215, 215) 100%);
}
.RoCare-banner-sec .RoCare-banner-content {
  margin-top: 8%;
}
.RoCare-banner-sec .RoCare-banner-content .sub-head {
  color: #000000;
  font-weight: 600;
  font-size: 17px;
}
.RoCare-banner-sec .RoCare-banner-content .logo-img img {
  width: 80%;
}
.RoCare-banner-sec .RoCare-banner-content .mt-30 {
  margin-top: 30px;
}
.RoCare-banner-sec .RoCare-banner-content .main-heading {
  font-size: 27px;
  margin-bottom: 15px;
  font-weight: 500;
  margin-top: 15px;
}
.RoCare-banner-sec .RoCare-banner-content .main-heading span {
  color: #fcaf17;
}
.RoCare-banner-sec .RoCare-banner-content .desc {
  font-size: 16px;
  line-height: 25px;
}
.RoCare-banner-sec .RoCare-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.RoCare-banner-sec .RoCare-banner-content .transform-button {
  color: white;
  background-color: #116566;
  border: 1px solid #116566;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.RoCare-banner-sec .RoCare-banner-content .transform-button:hover {
  border: 1px solid #116566;
  background: transparent;
  color: #116566 !important;
}
.RoCare-banner-sec .RoCare-banner-content .RoCare-intro-list-box {
  text-align: center;
}
.RoCare-banner-sec .RoCare-banner-content .RoCare-intro-list-box .RoCare-intro-list {
  text-align: left;
}
.RoCare-banner-sec .RoCare-banner-content .RoCare-intro-list-box .RoCare-intro-list li {
  font-size: 14px;
}
.RoCare-banner-sec .RoCare-banner-img {
  text-align: end;
  margin-top: 5%;
}

.Rocare-aboutus {
  background-color: #fffaf7;
}
.Rocare-aboutus .Rocare-aboutus-main-head {
  text-align: center;
}
.Rocare-aboutus .Rocare-aboutus-main-head .main-heading {
  text-transform: capitalize;
  font-size: 45px;
  position: relative;
}
.Rocare-aboutus .Rocare-aboutus-main-head .main-heading::before {
  content: "Service";
  -webkit-text-stroke: 2px #1f1f1f;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  font-size: 80px;
  bottom: 0;
  -webkit-text-fill-color: transparent;
  opacity: 0.1;
  z-index: 0;
  width: 100%;
}

.client-requirements-sec {
  background-color: #116566;
}
.client-requirements-sec .client-requirements-main-head {
  background-color: white;
  padding: 110px 50px 105px 50px;
}
.client-requirements-sec .client-requirements-main-head .main-heading {
  text-transform: capitalize;
  font-size: 36px;
  position: relative;
  font-weight: 600;
}
.client-requirements-sec .client-requirements-main-head .main-heading::before {
  content: "Client";
  -webkit-text-stroke: 2px #1f1f1f;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  font-size: 70px;
  bottom: 10px;
  -webkit-text-fill-color: transparent;
  opacity: 0.1;
  z-index: 0;
  width: 100%;
}
.client-requirements-sec .client-requirements-main-head .desc {
  font-size: 18px;
}
.client-requirements-sec .client-requirements-img {
  text-align: center;
  padding: 20px 0px;
}

.Rocare-features-sec {
  position: relative;
  width: 100%;
  background-image: url("../images/casestudy/RoCareIndia/features/features-bg.png");
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;
  background-repeat: repeat;
}
.Rocare-features-sec .position-relative-z-index-1 {
  position: relative;
  z-index: 1;
}
.Rocare-features-sec .overlay-rocare-features {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.Rocare-features-sec .Rocare-features-main-head {
  text-align: center;
}
.Rocare-features-sec .Rocare-features-main-head .main-heading {
  text-transform: uppercase;
  font-size: 34px;
  font-weight: 600;
}
.Rocare-features-sec .Rocare-features-main-head .desc {
  color: black;
}
.Rocare-features-sec .content-heading .features-heading {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 25px;
}
.Rocare-features-sec .features-content .white-box {
  margin-bottom: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
  background: #fff;
  min-height: 210px;
}
.Rocare-features-sec .features-content .white-box h5 {
  margin-bottom: 15px;
}
.Rocare-features-sec .features-content .white-box h5 img {
  width: 32px;
  margin-right: 5px;
}
.Rocare-features-sec .features-content .white-box .features-list {
  padding-left: 10px;
}
.Rocare-features-sec .features-content .white-box .features-list li {
  font-size: 17px;
  position: relative;
  margin-top: 6px;
  padding-left: 20px;
  color: black;
}
.Rocare-features-sec .features-content .white-box .features-list li::before {
  content: "-";
  position: absolute;
  color: #116566;
  left: 0;
  top: 0;
  font-size: 24px;
  padding-right: 10px;
}

.rocare-customer-screens {
  background-color: #116566;
}
.rocare-customer-screens .swiper-horizontal > .swiper-pagination-bullets,
.rocare-customer-screens .swiper-pagination-bullets.swiper-pagination-horizontal,
.rocare-customer-screens .swiper-pagination-custom,
.rocare-customer-screens .swiper-pagination-fraction {
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rocare-customer-screens .swiper-pagination-bullet-active {
  opacity: 1 !important;
  background: white !important;
  width: 40px !important;
  height: 5px !important;
  margin: 7px 5px !important;
  border-radius: 10px !important;
}
.rocare-customer-screens .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.rocare-customer-screens .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 20px;
  height: 5px !important;
  margin: 7px 5px !important;
  background: white !important;
  border-radius: 10px !important;
}

.rocare-challenges-sec .rocare-challenges-content {
  margin-top: 10%;
}
.rocare-challenges-sec .rocare-challenges-content .main-heading {
  text-transform: capitalize;
  font-size: 42px;
  position: relative;
  font-weight: 600;
  margin-bottom: 25px;
}
.rocare-challenges-sec .rocare-challenges-content .main-heading::before {
  content: "Client";
  -webkit-text-stroke: 2px #1f1f1f;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  font-size: 75px;
  bottom: 10px;
  -webkit-text-fill-color: transparent;
  opacity: 0.1;
  z-index: 0;
  width: 100%;
}
.rocare-challenges-sec .rocare-challenges-content .sub-heading {
  font-size: 18px;
}
.rocare-challenges-sec .rocare-challenges-content .desc {
  margin-bottom: 25px;
}

.NikkyPore-banner-sec {
  width: 100%;
  min-height: 550px;
  background: rgb(245, 245, 245);
  background: linear-gradient(90deg, rgb(245, 245, 245) 0%, rgba(1, 120, 150, 0.6901960784) 100%);
}
.NikkyPore-banner-sec .NikkyPore-banner-content {
  margin-top: 6%;
}
.NikkyPore-banner-sec .NikkyPore-banner-content .sub-head {
  color: #000000;
  font-weight: 600;
  font-size: 17px;
}
.NikkyPore-banner-sec .NikkyPore-banner-content .mt-30 {
  margin-top: 30px;
}
.NikkyPore-banner-sec .NikkyPore-banner-content .main-heading {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 500;
  margin-top: 15px;
}
.NikkyPore-banner-sec .NikkyPore-banner-content .main-heading span {
  color: #fcaf17;
}
.NikkyPore-banner-sec .NikkyPore-banner-content .desc {
  font-size: 16px;
  line-height: 25px;
}
.NikkyPore-banner-sec .NikkyPore-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.NikkyPore-banner-sec .NikkyPore-banner-content .transform-button {
  color: white;
  background-color: #017896;
  border: 1px solid #017896;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.NikkyPore-banner-sec .NikkyPore-banner-content .transform-button:hover {
  border: 1px solid #017896;
  background: transparent;
  color: #017896 !important;
}
.NikkyPore-banner-sec .NikkyPore-banner-content .NikkyPore-intro-list-box {
  text-align: center;
}
.NikkyPore-banner-sec .NikkyPore-banner-content .NikkyPore-intro-list-box .NikkyPore-intro-list {
  text-align: left;
}
.NikkyPore-banner-sec .NikkyPore-banner-content .NikkyPore-intro-list-box .NikkyPore-intro-list li {
  font-size: 14px;
}
.NikkyPore-banner-sec .NikkyPore-banner-img {
  text-align: end;
  margin-top: 7%;
  animation: 4s ease-in-out infinite floatY;
}

.Nikkypore-project-summary {
  background-color: #f7f7f7;
}
.Nikkypore-project-summary .pb-50 {
  padding-bottom: 50px;
}
.Nikkypore-project-summary .Nikkypore-main-head .main-heading {
  font-size: 34px;
  color: #222a41;
  font-weight: 600;
}
.Nikkypore-project-summary .Nikkypore-main-head span {
  color: #017896;
}
.Nikkypore-project-summary .Nikkypore-project-summary-img {
  text-align: center;
}

.Nikkypore-goals-objectives {
  background-color: #e9e9e9;
}
.Nikkypore-goals-objectives .Nikkypore-goals-objectives-main-head {
  text-align: center;
}
.Nikkypore-goals-objectives .Nikkypore-goals-objectives-main-head .main-heading {
  font-size: 34px;
  color: #222a41;
  font-weight: 600;
}
.Nikkypore-goals-objectives .Nikkypore-goals-objectives-main-head span {
  color: #222a41;
}
.Nikkypore-goals-objectives .Nikkypore-goals-objectives-main-head .desc {
  line-height: 25px;
  color: #222a41;
}
.Nikkypore-goals-objectives .goals-obj-content-box {
  margin-top: 20%;
}
.Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card {
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 168px;
  margin-bottom: 25px;
}
.Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card h5 {
  font-size: 18px;
}
.Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card .desc {
  font-size: 15px;
  line-height: 20px;
}
.Nikkypore-goals-objectives .goals-obj-img-box {
  text-align: center;
}

.Nikkypore-features-sec {
  position: relative;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/NikkyPore/features-sec/featuresbg.png");
  background-size: cover;
  background-attachment: fixed;
}
.Nikkypore-features-sec .overlay-Nikkypore-features {
  content: "";
  position: absolute;
  background: rgba(233, 233, 233, 0.55);
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.Nikkypore-features-sec .position-relative-z-index-1 {
  position: relative;
  z-index: 1;
}
.Nikkypore-features-sec .features-sec-main-head {
  text-align: center;
}
.Nikkypore-features-sec .features-sec-main-head .main-heading {
  font-size: 34px;
  font-weight: 600;
}
.Nikkypore-features-sec .customer-app-features-content {
  margin-top: 32%;
}
.Nikkypore-features-sec .customer-app-features-content .white-box {
  border: 1px solid #dedede;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  min-height: 100px;
  display: flex;
  align-items: center;
}
.Nikkypore-features-sec .customer-app-features-content .white-box .featured-list {
  display: flex;
  align-items: center;
}
.Nikkypore-features-sec .customer-app-features-content .white-box .featured-list li .inner-featured-list {
  margin-left: 15px;
}
.Nikkypore-features-sec .customer-app-features-content .white-box .featured-list li .inner-featured-list h4 {
  font-size: 17px;
  margin-bottom: 5px;
}
.Nikkypore-features-sec .customer-app-features-content .white-box .featured-list li .inner-featured-list .desc {
  line-height: 20px;
  font-size: 15px;
}
.Nikkypore-features-sec .customer-app-features-content .white-box .featured-list li span {
  display: inline-block;
  background: linear-gradient(190deg, rgb(244, 137, 4) 0%, rgb(90, 154, 239) 100%);
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 0;
  color: white;
}

.challenges-and-solutions-sec .challenges-and-solutions-main-head {
  text-align: center;
}
.challenges-and-solutions-sec .challenges-and-solutions-main-head .main-heading {
  font-size: 34px;
  font-weight: 600;
}
.challenges-and-solutions-sec .challenges-and-solutions-main-head span {
  color: #222a41;
}
.challenges-and-solutions-sec .challenges-and-solutions-main-head .desc {
  line-height: 25px;
}
.challenges-and-solutions-sec .challenges-sec h4 {
  font-size: 23px;
  font-weight: 600;
}
.challenges-and-solutions-sec .challenges-sec .challenges-content-list {
  margin-top: 5%;
}
.challenges-and-solutions-sec .challenges-sec .challenges-content-list li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
}
.challenges-and-solutions-sec .challenges-sec .challenges-content-list li .desc {
  font-size: 15px;
  line-height: 25px;
}
.challenges-and-solutions-sec .challenges-sec .challenges-content-list li svg {
  position: absolute;
  left: 0;
  color: #017896;
  top: 2px;
}

.visual-designs-sec .visual-designs-main-head {
  text-align: center;
}
.visual-designs-sec .visual-designs-main-head .main-heading {
  font-size: 34px;
  font-weight: 600;
}
.visual-designs-sec .visual-designs-main-head .desc {
  line-height: 25px;
  margin-bottom: 40px;
}
.visual-designs-sec .visual-designs-wrapper {
  background-color: #4192a7;
  padding: 120px 0px 30px;
  position: relative;
}
.visual-designs-sec .visual-designs-wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100px;
  background: url("../images/casestudy/NikkyPore/visual-designs/portfolio-design.png");
  background-size: cover;
  background-repeat: repeat-x;
}
.visual-designs-sec .visual-designs-wrapper .visual-designs-slide img {
  width: 50%;
}

.deyor-main-banner-sec {
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/Deyor/main-banner/deyor-main-banner-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.deyor-main-banner-sec .deyor-main-banner-content {
  margin-top: 15%;
}
.deyor-main-banner-sec .deyor-main-banner-content .deyor-main-banner-logo {
  margin-bottom: 15px;
}
.deyor-main-banner-sec .deyor-main-banner-content h1 {
  font-size: 28px;
  font-weight: 600;
}
.deyor-main-banner-sec .deyor-main-banner-content .desc {
  color: #222a41;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
}
.deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list {
  display: flex;
  flex-wrap: wrap;
}
.deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li {
  flex-basis: 50%;
  margin-top: 20px;
  font-size: 18px;
}
.deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li h5 {
  font-size: 18px;
  margin-bottom: 0;
  line-height: 30px;
}
.deyor-main-banner-sec .deyor-main-banner-content .mt-30 {
  margin-top: 30px;
}
.deyor-main-banner-sec .deyor-main-banner-content .visit-site-buttn {
  background-image: linear-gradient(to right, #fc4f54 0%, #ff8a12 50%, #fa534f 100%);
  padding: 10px 32px;
  color: white;
  position: relative;
  overflow: hidden;
  background-size: 200% auto;
  transition: all 0.5s;
  border-radius: 5px;
  border: none;
}
.deyor-main-banner-sec .deyor-main-banner-content .visit-site-buttn:hover {
  background-position: center right;
  color: white !important;
}

.deyor-objective-sec {
  background-color: #f5f5f5;
}
.deyor-objective-sec .j-c-sb {
  justify-content: space-between;
}
.deyor-objective-sec .deyor-objective-main-content-box {
  text-align: center;
}
.deyor-objective-sec .deyor-objective-main-content-box .main-heading {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 20px;
}
.deyor-objective-sec .deyor-objective-main-content-box .main-heading::after {
  content: "";
  background-color: #c4343c;
  position: absolute;
  width: 72px;
  height: 4px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.deyor-objective-sec .deyor-objective-main-content-box .main-heading span {
  color: #c4343c;
}
.deyor-objective-sec .deyor-objective-main-content-box .desc {
  font-size: 18px;
}
.deyor-objective-sec .deyor-objective-img {
  margin-top: 10%;
}
.deyor-objective-sec .deyor-objective-content-box {
  margin-top: 25%;
}
.deyor-objective-sec .deyor-objective-content-box .deyor-objective-card {
  padding: 15px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-bottom: 7px solid #c4343c;
  text-align: left;
  min-height: 210px;
  margin-bottom: 20px;
}
.deyor-objective-sec .deyor-objective-content-box .deyor-objective-card .number-area {
  display: block;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.4;
  color: #c4343c;
}
.deyor-objective-sec .deyor-objective-content-box .deyor-objective-card .desc1 {
  font-size: 16px;
}
.deyor-objective-sec .deyor-about-us-wrapper {
  cursor: pointer;
}
.deyor-objective-sec .deyor-about-us-wrapper:hover .deyor-about-us-image-box .deyor-about-us-main-img img {
  transform: scale(1.1);
  transition: all 0.5s ease-in;
  filter: grayscale(100%);
}
.deyor-objective-sec .deyor-about-us-wrapper:hover .deyor-about-us-content .main-heading {
  transform: 0.9s ease-in-out;
  transform: translateX(20px);
}
.deyor-objective-sec .deyor-about-us-wrapper .deyor-about-us-image-box {
  position: relative;
  cursor: pointer;
}
.deyor-objective-sec .deyor-about-us-wrapper .deyor-about-us-image-box .deyor-about-us-main-img {
  position: absolute;
  overflow: hidden;
}
.deyor-objective-sec .deyor-about-us-wrapper .deyor-about-us-image-box .deyor-about-us-main-img img {
  transform: scale(1);
  transition: all 0.5s ease-in;
}
.deyor-objective-sec .deyor-about-us-wrapper .deyor-about-us-image-box .aboutus-logo {
  position: absolute;
  width: 17%;
  right: 0;
  top: 0;
}
.deyor-objective-sec .deyor-about-us-wrapper .deyor-about-us-content {
  margin-top: 25%;
  cursor: pointer;
}
.deyor-objective-sec .deyor-about-us-wrapper .deyor-about-us-content .main-heading {
  font-size: 40px;
  font-weight: 700;
  color: #000;
  margin-bottom: 25px;
  display: inline-block;
  transform: 130ms ease-in-out, -webkit-transform 130ms ease-in-out;
  transform: perspective(1px) translateZ(0);
}
.deyor-objective-sec .deyor-about-us-wrapper .deyor-about-us-content .desc {
  font-size: 22px;
  color: #212124;
  font-weight: 600;
  margin-bottom: 20px;
}
.deyor-objective-sec .deyor-about-us-wrapper .deyor-about-us-content .deyor-about-us-content-list li {
  position: relative;
  padding-left: 35px;
  font-size: 18px;
  line-height: 35px;
  color: black;
  font-weight: 600;
}
.deyor-objective-sec .deyor-about-us-wrapper .deyor-about-us-content .deyor-about-us-content-list li .list-bar {
  content: "";
  position: absolute;
  background-color: #e43051;
  width: 16px;
  height: 2px;
  left: 0;
  top: 20px;
}

.deyor-methodology-sec {
  width: 100%;
  min-height: 550px;
  background: url("../images/casestudy/Deyor/methodology/methodology-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.deyor-methodology-sec .j-c-se {
  justify-content: space-evenly;
}
.deyor-methodology-sec .deyor-methodology-img {
  margin-top: 20%;
}
.deyor-methodology-sec .deyor-methodology-img .mt-10 {
  margin-top: 25%;
  width: 90%;
  text-align: center;
  margin-left: 5%;
}
.deyor-methodology-sec .deyor-methodology-main-head {
  text-align: center;
}
.deyor-methodology-sec .deyor-methodology-main-head .main-heading {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 0px;
  position: relative;
  padding-bottom: 20px;
}
.deyor-methodology-sec .deyor-methodology-main-head .main-heading::after {
  content: "";
  background-color: #c4343c;
  position: absolute;
  width: 72px;
  height: 4px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.deyor-methodology-sec .deyor-methodology-main-head .main-heading span {
  color: #c4343c;
}
.deyor-methodology-sec .deyor-methodology-content-box .main-heading {
  color: #c4343c;
  font-weight: 600;
}
.deyor-methodology-sec .deyor-methodology-content-box .sub-heading {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
}
.deyor-methodology-sec .deyor-methodology-content-box .deyor-features-content-box .deyor-features-content-list .content-list-li {
  margin-bottom: 10px;
}
.deyor-methodology-sec .deyor-methodology-content-box .deyor-features-content-box .deyor-features-content-list .content-list-li .deyor-features-inner-list {
  display: flex;
}
.deyor-methodology-sec .deyor-methodology-content-box .deyor-features-content-box .deyor-features-content-list .content-list-li .deyor-features-inner-list li svg {
  color: #c4343c;
  font-size: 24px;
  margin-right: 10px;
  margin-top: 5px;
}
.deyor-methodology-sec .deyor-methodology-content-box .deyor-features-content-box .deyor-features-content-list .content-list-li .deyor-features-inner-list li h6 {
  margin-bottom: 0px;
}
.deyor-methodology-sec .deyor-methodology-content-box .deyor-features-content-box .deyor-features-content-list .content-list-li .deyor-features-inner-list li p {
  font-size: 15px;
  line-height: 25px;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box {
  margin-top: 15%;
  background-color: white;
  padding: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 20px;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box .deyor-features-content-list .content-list-li {
  margin-bottom: 10px;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box .deyor-features-content-list .content-list-li .deyor-features-inner-list {
  display: flex;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box .deyor-features-content-list .content-list-li .deyor-features-inner-list li svg {
  color: #c4343c;
  font-size: 24px;
  margin-right: 10px;
  margin-top: 5px;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box .deyor-features-content-list .content-list-li .deyor-features-inner-list li h6 {
  margin-bottom: 0px;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box .deyor-features-content-list .content-list-li .deyor-features-inner-list li p {
  font-size: 14px;
  line-height: 25px;
  display: inline-block;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box {
  margin-top: 5%;
  background-color: white;
  padding: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  min-height: 214px;
  margin-bottom: 20px;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box .deyor-features-content-list .content-list-li {
  margin-bottom: 10px;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box .deyor-features-content-list .content-list-li .deyor-features-inner-list {
  display: flex;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box .deyor-features-content-list .content-list-li .deyor-features-inner-list li svg {
  color: #c4343c;
  font-size: 24px;
  margin-right: 10px;
  margin-top: 5px;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box .deyor-features-content-list .content-list-li .deyor-features-inner-list li h6 {
  margin-bottom: 0px;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box .deyor-features-content-list .content-list-li .deyor-features-inner-list li p {
  font-size: 14px;
  line-height: 25px;
  display: inline-block;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 {
  margin-top: 29%;
  background-color: white;
  padding: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 .deyor-features-content-list .content-list-li {
  margin-bottom: 10px;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 .deyor-features-content-list .content-list-li .deyor-features-inner-list {
  display: flex;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 .deyor-features-content-list .content-list-li .deyor-features-inner-list li svg {
  color: #c4343c;
  font-size: 24px;
  margin-right: 10px;
  margin-top: 5px;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 .deyor-features-content-list .content-list-li .deyor-features-inner-list li h6 {
  margin-bottom: 0px;
}
.deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 .deyor-features-content-list .content-list-li .deyor-features-inner-list li p {
  font-size: 14px;
  line-height: 25px;
  display: inline-block;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box-1 {
  margin-top: 5%;
  background-color: white;
  padding: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box-1 h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box-1 .deyor-features-content-list .content-list-li {
  margin-bottom: 10px;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box-1 .deyor-features-content-list .content-list-li .deyor-features-inner-list {
  display: flex;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box-1 .deyor-features-content-list .content-list-li .deyor-features-inner-list li svg {
  color: #c4343c;
  font-size: 24px;
  margin-right: 10px;
  margin-top: 5px;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box-1 .deyor-features-content-list .content-list-li .deyor-features-inner-list li h6 {
  margin-bottom: 0px;
}
.deyor-methodology-sec .deyor-methodology-content-box .outcomes-box-1 .deyor-features-content-list .content-list-li .deyor-features-inner-list li p {
  font-size: 14px;
  line-height: 25px;
  display: inline-block;
}

.deyor-challenges-sec .d-f-fd-cr-deyor {
  display: flex;
  flex-direction: row;
}
.deyor-challenges-sec .j-c-sa {
  display: flex;
  justify-content: space-around;
}
.deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box {
  animation: 4s ease-in-out infinite floatY;
  transition: 4s;
  background-color: #f6f9fb;
  padding: 75px 60px 90px;
  position: relative;
  min-height: 550px;
  margin-top: 5%;
}
.deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .challenges-content-list {
  margin-top: 10%;
}
.deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .challenges-content-list li .challenges-inner-list {
  display: flex;
  margin-bottom: 20px;
}
.deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .challenges-content-list li .challenges-inner-list li svg {
  color: #c4343c;
  font-size: 24px;
  margin-right: 15px;
}
.deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .challenges-content-list li .challenges-inner-list li h5 {
  font-size: 18px;
  margin-bottom: 0px;
}
.deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .challenges-content-list li .challenges-inner-list li .desc {
  font-size: 15px;
  line-height: 25px;
}
.deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .small-bar {
  background-color: #c4343c;
  width: 47px;
  height: 7px;
  display: block;
  margin-bottom: 15px;
}
.deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .main-heading {
  font-size: 30px;
}

.deyor-solutions-sec {
  width: 100%;
  min-height: 550px;
  background-color: #dcdcdc;
  position: relative;
}
.deyor-solutions-sec .d-f-fd-cr-deyor {
  display: flex;
  flex-direction: row;
}
.deyor-solutions-sec .j-c-sa {
  display: flex;
  justify-content: space-around;
}
.deyor-solutions-sec .deyor-solutions-main-head .small-bar {
  background-color: #c4343c;
  width: 47px;
  height: 7px;
  display: block;
  margin-bottom: 15px;
}
.deyor-solutions-sec .deyor-solutions-main-head .main-heading {
  font-size: 34px;
}
.deyor-solutions-sec .deyor-solutions-content-box {
  animation: 4s ease-in-out infinite floatY;
  transition: 4s;
  background-color: #f6f9fb;
  padding: 55px 50px 50px;
  position: relative;
  min-height: 500px;
  margin-top: 16%;
}
.deyor-solutions-sec .deyor-solutions-content-box .small-bar {
  background-color: #c4343c;
  width: 47px;
  height: 7px;
  display: block;
  margin-bottom: 15px;
}
.deyor-solutions-sec .deyor-solutions-content-box .main-heading {
  font-size: 34px;
}

.deyor-work-screens-sec {
  background-color: #f3f3f3;
}
.deyor-work-screens-sec .deyor-work-screens-main-head {
  text-align: center;
}
.deyor-work-screens-sec .deyor-work-screens-main-head .main-heading {
  font-size: 34px;
  font-weight: 600;
}

.samagri-banner-sec {
  background-color: #fff8ed;
}
.samagri-banner-sec .samagri-banner-shape {
  position: relative;
}
.samagri-banner-sec .samagri-banner-shape img {
  position: absolute;
  width: 36%;
  z-index: 1;
  left: -39px;
}
.samagri-banner-sec .samagri-banner-img {
  position: relative;
  z-index: 2;
  margin-top: 10%;
}
.samagri-banner-sec .samagri-banner-content {
  margin-top: 30%;
}
.samagri-banner-sec .samagri-banner-content .j-c-sb {
  justify-content: space-between;
}
.samagri-banner-sec .samagri-banner-content .main-heading {
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
}
.samagri-banner-sec .samagri-banner-content .main-heading span {
  color: #c48041;
}
.samagri-banner-sec .samagri-banner-content .desc {
  font-size: 20px;
  color: #413d2c;
  font-weight: 600;
}
.samagri-banner-sec .samagri-banner-content .desc1 {
  font-size: 18px;
  color: #413d2c;
  font-weight: 400;
}
.samagri-banner-sec .samagri-banner-content .samagri-contactus-buttnn {
  background-color: #c48041;
  color: white;
  padding: 12px 32px;
  border: 1px solid transparent;
  transition: all 0.5s;
}
.samagri-banner-sec .samagri-banner-content .samagri-contactus-buttnn:hover {
  background-color: transparent;
  color: #c48041 !important;
  border-color: #c48041;
}
.samagri-banner-sec .samagri-banner-content .mt-25 {
  margin-top: 25px;
}

.samagri-aboutus-sec {
  background-color: #f1e9e7;
}
.samagri-aboutus-sec .pb-50 {
  padding-bottom: 50px;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box {
  background-color: white;
  padding: 50px;
  justify-content: space-between;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li {
  display: flex;
  align-items: center;
  line-height: 50px;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li .sub-heading {
  font-size: 20px;
  margin-right: 15px;
  font-weight: 600;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li .main-heading {
  font-size: 22px;
  color: #000;
  font-weight: 600;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li span svg {
  color: #c48041;
  font-size: 24px;
  margin-right: 20px;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li {
  line-height: 50px;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .sub-heading {
  font-size: 22px;
  margin-right: 15px;
  font-weight: 600;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .sub-heading:hover {
  color: #c48041 !important;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .main-heading {
  font-size: 22px;
  color: #000;
  font-weight: 600;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .main-heading:hover {
  color: #c48041 !important;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li span svg {
  color: #c48041;
  font-size: 24px;
  margin-right: 20px;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .j-c-sb {
  justify-content: space-between;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box {
  margin-top: 20%;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box .main-heading {
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 25px;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box .main-heading span {
  color: #c48041;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box .desc {
  font-size: 20px;
  margin-bottom: 25px;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-img-box .samagri-logo-img {
  text-align: center;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-img-box .samagri-logo-img img {
  width: 40%;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-img-box .samagri-small-img-box {
  position: relative;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-img-box .samagri-small-img-box .sm-1 {
  position: absolute;
  left: 6%;
  top: -75px;
  z-index: 2;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-img-box .samagri-small-img-box .sm-2 {
  position: absolute;
  left: 37%;
  z-index: 2;
  top: 80px;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-small-img-box1 {
  position: relative;
  margin-top: 55%;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-small-img-box1 .sm-3 {
  position: absolute;
  left: 50%;
  z-index: 2;
}
.samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-small-img-box1 .sm-4 {
  position: absolute;
  left: 62%;
  z-index: 1;
}

.samagri-project-highlights {
  background-color: #b99471;
}
.samagri-project-highlights .samagri-project-highlights-img {
  position: relative;
}
.samagri-project-highlights .samagri-project-highlights-img .circle .circle-content {
  position: absolute;
  top: 45%;
  left: 80%;
}
.samagri-project-highlights .samagri-project-highlights-img .circle .circle-content .main-heading {
  font-size: 48px;
  font-weight: 600;
}
.samagri-project-highlights .samagri-project-highlights-img .circle .circle-content .main-heading span {
  display: block;
  font-size: 48px;
  color: #c48041;
}
.samagri-project-highlights .samagri-project-highlights-img .circle img {
  position: absolute;
  left: 60%;
  top: 185px;
  width: 80%;
}
.samagri-project-highlights .samagri-project-highlights-content {
  position: relative;
}
.samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-icon {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  background-color: white;
  filter: drop-shadow(-24px 29px 25px rgba(12, 12, 13, 0.32));
  text-align: center;
  line-height: 115px;
}
.samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-area {
  padding-left: 20px;
}
.samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-area .desc {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}
.samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(1) {
  position: absolute;
}
.samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(2) {
  position: absolute;
  top: 25%;
  left: 30%;
}
.samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(3) {
  position: absolute;
  top: 54%;
  left: 31%;
}
.samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(4) {
  position: absolute;
  top: 85%;
  left: 2%;
}

.samagri-work-screens {
  position: relative;
  width: 100%;
  min-height: 1050px;
  background: url("../images/casestudy/Samagri/our-screens/work-screens.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.samagri-work-screens .samagri-work-screen-content {
  margin-top: 10%;
}
.samagri-work-screens .samagri-work-screen-content .main-heading {
  font-size: 48px;
  font-weight: 600;
}
.samagri-work-screens .samagri-work-screen-content .main-heading span {
  color: #c48041;
  display: block;
}
.samagri-work-screens .samagri-work-screen-content .desc {
  line-height: 25px;
}

.samagri-features-sec {
  background-color: #f1e9e7;
}
.samagri-features-sec .j-c-sb {
  justify-content: space-between;
}
.samagri-features-sec .samagri-features-main-head {
  margin-top: 15%;
}
.samagri-features-sec .samagri-features-main-head .main-heading {
  color: black;
  font-size: 46px;
  font-weight: 600;
}
.samagri-features-sec .samagri-features-main-head .main-heading span {
  color: #c48041;
}
.samagri-features-sec .samagri-features-main-head .desc {
  font-size: 20px;
}
.samagri-features-sec .samagri-features-img {
  margin-top: 25%;
}
.samagri-features-sec .samagri-features-card {
  padding: 20px 10px;
  border: 1px solid #e1e1e1;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 25px;
  transition: box-shadow 0.4s ease-in-out;
  background: #fff;
  max-width: 100%;
  min-height: 165px;
}
.samagri-features-sec .samagri-features-card:hover {
  box-shadow: 0 69px 57px 0 rgba(56, 56, 84, 0.09);
  border: 1px solid #fff;
  cursor: pointer;
}
.samagri-features-sec .samagri-features-card .samagri-features-icon {
  margin-bottom: 15px;
}
.samagri-features-sec .samagri-features-card .samagri-features-icon img {
  width: 30%;
}
.samagri-features-sec .samagri-features-card .card-heading {
  font-size: 15px;
  color: black;
  font-weight: 600;
  display: inline-block;
}

.samagri-challenges-sec {
  background-color: #b99471;
}
.samagri-challenges-sec .j-c-sb {
  justify-content: space-between;
}
.samagri-challenges-sec .samagri-challenges-main-head {
  text-align: center;
}
.samagri-challenges-sec .samagri-challenges-main-head .main-heading {
  font-size: 40px;
  font-weight: 600;
  color: white;
}
.samagri-challenges-sec .samagri-challenges-content {
  margin-top: 10%;
}
.samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card {
  background-color: white;
  padding: 10px;
  margin-bottom: 20px;
}
.samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card .samagri-challenges-card-heading {
  background-color: #af6b2d;
}
.samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card .samagri-challenges-card-heading .main-heading {
  text-align: center;
  font-size: 16px;
  color: white;
  padding: 10px;
}
.samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card .desc {
  padding: 5px;
  line-height: 25px;
}

.samagri-contact-banner {
  width: 100%;
  min-height: 250px;
  background-image: url("../images/casestudy/Samagri/contact-banner/kickstart-banner.webp");
  background-size: cover;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  position: relative;
  padding: 100px 0px;
}
.samagri-contact-banner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 18, 6, 0.51);
  top: 0;
  left: 0;
}
.samagri-contact-banner .position-relative-z-index-1 {
  position: relative;
  z-index: 1;
}
.samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content {
  text-align: center;
}
.samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .main-heading {
  color: white;
  font-size: 36px;
  margin-bottom: 10px;
}
.samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .desc {
  color: white;
}
.samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .mt-30 {
  margin-top: 30px;
}
.samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .visit-site-buttn {
  background-image: linear-gradient(to right, #fc4f54 0%, #ff8a12 50%, #fa534f 100%);
  padding: 12px 32px;
  color: white;
  position: relative;
  overflow: hidden;
  background-size: 200% auto;
  transition: all 0.5s;
  border-radius: 5px;
  font-weight: 600;
}
.samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .visit-site-buttn:hover {
  background-position: center right;
  color: white !important;
}

.digital-mkt-banner-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/DigitalMarketing/main-banner/main-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.digital-mkt-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.digital-mkt-banner-sec .digital-mkt-banner-content {
  position: absolute;
  z-index: 2;
  top: 30%;
  width: 40%;
  left: 7%;
}
.digital-mkt-banner-sec .digital-mkt-banner-content .main-heading {
  color: white;
  font-size: 28px;
  margin-bottom: 15px;
}
.digital-mkt-banner-sec .digital-mkt-banner-content .main-heading span {
  color: #fcaf17;
}
.digital-mkt-banner-sec .digital-mkt-banner-content .desc {
  color: white;
  margin-bottom: 30px;
  font-size: 16px;
}
.digital-mkt-banner-sec .digital-mkt-banner-content .transform-button {
  color: white !important;
  background-color: transparent;
  border: 1px solid #fcaf17;
  padding: 12px 40px;
  transition: all 0.4s ease-in-out;
  font-weight: 600;
  overflow: hidden;
  position: relative;
}
.digital-mkt-banner-sec .digital-mkt-banner-content .transform-button::before {
  content: "";
  position: absolute;
  background-color: #fcaf17;
  color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform-origin: 0 50%;
  transition-property: transform;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out;
}
.digital-mkt-banner-sec .digital-mkt-banner-content .transform-button:hover::before {
  border: 1px solid transparent;
  color: white;
  transition: all 0.4s ease-in-out;
  transform: scaleX(1);
}

.digital-mkt-about-us .digital-mkt-about-content {
  margin-top: 15%;
}
.digital-mkt-about-us .digital-mkt-about-content .main-heading {
  font-size: 30px;
  font-weight: 600;
  color: #020202;
}
.digital-mkt-about-us .digital-mkt-about-content .main-heading span {
  color: rgb(252, 175, 23);
}
.digital-mkt-about-us .digital-mkt-about-content .desc {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.digital-mkt-about-us .digital-mkt-about-content .h-about-button {
  margin-top: 20px;
}
.digital-mkt-about-us .digital-mkt-about-content .h-about-button .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 8px 25px;
  color: #ffffff;
  font-size: 14px;
  transition: all 0.5s;
}
.digital-mkt-about-us .digital-mkt-about-content .h-about-button .read-more-button:hover {
  color: white !important;
  transform: translateY(-15px);
  transition: all 0.5s;
}

.digital-mkt-features-sec {
  background-color: #f8f9fe;
}
.digital-mkt-features-sec .digital-mkt-features-main-head {
  text-align: center;
}
.digital-mkt-features-sec .digital-mkt-features-main-head .main-heading {
  font-size: 32px;
  margin-bottom: 15px;
  font-weight: 600;
}
.digital-mkt-features-sec .digital-mkt-features-main-head .main-heading span {
  color: #fcaf17;
}
.digital-mkt-features-sec .digital-mkt-features-card {
  background-color: #fff;
  border: #dee4fe solid 1px;
  border-radius: 22px;
  padding: 28px;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 25px;
}
.digital-mkt-features-sec .digital-mkt-features-card:hover {
  border-color: #fcaf17;
  box-shadow: 0 20px 25px -3px rgba(252, 175, 23, 0.22), 0 8px 10px -2px rgba(252, 175, 23, 0.22);
  background-color: #fff;
}
.digital-mkt-features-sec .digital-mkt-features-card .main-heading {
  font-size: 22px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.digital-mkt-features-sec .digital-mkt-features-card .main-heading::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 82px;
  height: 3px;
  background-color: #fcaf17;
  left: 0;
}

.digital-mkt-why-choose-us-sec {
  background-color: #f2f2f2;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-main-head {
  text-align: center;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-main-head .main-heading {
  font-size: 32px;
  margin-bottom: 15px;
  font-weight: 600;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-main-head .main-heading span {
  color: #fcaf17;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-main-head .desc {
  font-size: 16px;
  width: 80%;
  margin: 0 auto;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-card {
  background-color: white;
  padding: 20px 15px;
  transition: 0.2s ease-in-out;
  margin-bottom: 25px;
  min-height: 290px;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-card:hover {
  transform: translateY(-10px);
  cursor: pointer;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-card img {
  width: 50px;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-card .digital-mkt-why-choose-content h5 {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 18px;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-card .digital-mkt-why-choose-content .desc {
  font-size: 16px;
  line-height: 25px;
}

.digital-mkt-services-sec .pt-15 {
  padding-top: 15px;
}
.digital-mkt-services-sec .j-c-sb {
  justify-content: space-between;
}
.digital-mkt-services-sec .digital-mkt-services-img {
  position: relative;
}
.digital-mkt-services-sec .digital-mkt-services-img .video-icon {
  position: absolute;
  z-index: 2;
  top: 68%;
  left: 56%;
  cursor: pointer;
}
.digital-mkt-services-sec .digital-mkt-services-img .video-icon img {
  width: 75%;
}
.digital-mkt-services-sec .digital-mkt-services-content {
  margin-top: 5%;
}
.digital-mkt-services-sec .digital-mkt-services-content .main-heading {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}
.digital-mkt-services-sec .digital-mkt-services-content .digital-mkt-services-content-list li {
  border-top: 1px dotted #fcaf17;
  padding: 7px;
  font-weight: 600;
  font-size: 16px;
}
.digital-mkt-services-sec .digital-mkt-services-content .digital-mkt-services-content-list li:hover {
  color: #fcaf17;
  cursor: pointer;
}
.digital-mkt-services-sec .digital-mkt-services-content .digital-mkt-services-content-list li svg {
  color: #fcaf17;
  font-size: 20px;
}

.digital-mkt-services-benefits-sec .digital-mkt-services-benefits-main-head {
  text-align: left;
}
.digital-mkt-services-benefits-sec .digital-mkt-services-benefits-main-head .main-heading {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}
.digital-mkt-services-benefits-sec .digital-mkt-services-benefits-main-head .main-heading span {
  color: #fcaf17;
}
.digital-mkt-services-benefits-sec .digital-mkt-services-benefits-main-content .desc {
  margin-bottom: 15px;
  font-size: 16px;
}
.digital-mkt-services-benefits-sec .desc1 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}
.digital-mkt-services-benefits-sec .desc1 span {
  color: #fcaf17;
}
.digital-mkt-services-benefits-sec .digital-mkt-benefits-list-box .digital-mkt-benefits-list {
  margin-top: 3%;
}
.digital-mkt-services-benefits-sec .digital-mkt-benefits-list-box .digital-mkt-benefits-list li {
  line-height: 30px;
  font-size: 17px;
  margin-bottom: 25px;
}
.digital-mkt-services-benefits-sec .digital-mkt-benefits-list-box .digital-mkt-benefits-list li svg {
  color: #fcaf17;
  font-size: 26px;
  margin-right: 10px;
}

.digital-mkt-tools-sec .digital-mkt-tools-main-head {
  text-align: center;
}
.digital-mkt-tools-sec .digital-mkt-tools-main-head .main-heading {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 15px;
}
.digital-mkt-tools-sec .digital-mkt-tools-main-head .main-heading span {
  color: #fcaf17;
}
.digital-mkt-tools-sec .tools-img {
  text-align: center;
}
.digital-mkt-tools-sec .tools-img img {
  width: 100% !important;
  height: 70px !important;
  -o-object-fit: contain;
     object-fit: contain;
  margin-bottom: 15px;
}

.digital-mkt-casestudy-sec {
  background-color: #f9f9f9;
}
.digital-mkt-casestudy-sec .digital-mkt-casestudy-content .main-heading {
  font-size: 32px;
  font-weight: 600px;
  margin-bottom: 25px;
}
.digital-mkt-casestudy-sec .digital-mkt-casestudy-content .sub-heading {
  font-size: 24px;
  font-weight: 600;
}
.digital-mkt-casestudy-sec .digital-mkt-casestudy-content .desc {
  font-size: 18px;
}
.digital-mkt-casestudy-sec .digital-mkt-casestudy-content .digital-mkt-casestudy-content-list {
  margin-top: 2%;
}
.digital-mkt-casestudy-sec .digital-mkt-casestudy-content .digital-mkt-casestudy-content-list li {
  line-height: 35px;
  font-size: 17px;
}
.digital-mkt-casestudy-sec .digital-mkt-casestudy-content .digital-mkt-casestudy-content-list li svg {
  color: #fcaf17;
  font-size: 20px;
  margin-right: 10px;
}
.digital-mkt-casestudy-sec .j-c-sb {
  justify-content: space-between;
}

.digital-marketing-services-sec {
  background-color: #f6ebd6;
}
.digital-marketing-services-sec .digital-marketing-services-main-head {
  text-align: center;
}
.digital-marketing-services-sec .digital-marketing-services-main-head .main-heading {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 15px;
}
.digital-marketing-services-sec .digital-marketing-services-main-head .main-heading span {
  color: #fcaf17;
}
.digital-marketing-services-sec .digital-marketing-services-main-head .desc {
  font-size: 16px;
  width: 90%;
  margin: 0 auto;
}
.digital-marketing-services-sec .digital-marketing-services-card {
  background-color: white;
  border: 1px solid #e5e5e5;
  padding: 20px 20px 0px 20px;
  transform: scale(1);
  border-radius: 20px;
  transition: all 0.5s;
  margin-bottom: 25px;
  min-height: 291px;
}
.digital-marketing-services-sec .digital-marketing-services-card:hover .service-btnn {
  background-color: #fcaf17;
  color: white;
  transition: all 0.3s;
}
.digital-marketing-services-sec .digital-marketing-services-card .d-mkt-card-icon {
  position: relative;
}
.digital-marketing-services-sec .digital-marketing-services-card .d-mkt-card-icon .circle-icon-holder {
  display: block;
  width: 100px;
  height: 100px;
  background-color: #ffedc8;
  text-align: center;
  border-radius: 100%;
  padding: 24px;
  position: relative;
  transition: all 0.4s;
  animation: rotate 6s linear infinite;
  z-index: 1;
}
.digital-marketing-services-sec .digital-marketing-services-card .d-mkt-card-icon .circle-icon-holder::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 2px;
  left: 15px;
  right: 0;
  bottom: 0;
  background-color: #fcaf17;
  border-radius: 100%;
  animation: rotate 6s linear infinite;
  transition: all 0.4s;
  z-index: 0;
}
.digital-marketing-services-sec .digital-marketing-services-card .d-mkt-card-icon img {
  width: 65px;
  z-index: 1;
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: center;
  margin: 0 auto;
}
@keyframes rotate {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.digital-marketing-services-sec .digital-marketing-services-card .digital-marketing-services-heading .heading {
  font-size: 21px;
}
.digital-marketing-services-sec .digital-marketing-services-card .digital-marketing-content {
  margin-top: 15px;
}
.digital-marketing-services-sec .digital-marketing-services-card .digital-marketing-content .desc {
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 15px;
  min-height: 72px;
}
.digital-marketing-services-sec .digital-marketing-services-card .digital-marketing-content .service-btnn {
  border-top: 1px solid #ddd;
  padding: 10px;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
.digital-marketing-services-sec .digital-marketing-services-card:hover {
  cursor: pointer;
}
.digital-marketing-services-sec .digital-marketing-services-card:hover .circle-icon-holder::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  background-color: #fcaf17;
  border-radius: 100%;
  z-index: 0;
  transition: all 0.3s;
}

.boost-your-business-sec {
  background-color: #f9f9f9;
}
.boost-your-business-sec .boost-your-business-content {
  margin-top: 10%;
}
.boost-your-business-sec .boost-your-business-content .main-heading {
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 15px;
}
.boost-your-business-sec .boost-your-business-content .main-heading span {
  color: #fcaf17;
}
.boost-your-business-sec .boost-your-business-content .desc {
  margin-bottom: 15px;
}
.boost-your-business-sec .boost-your-business-content .percentage span {
  font-size: 42px;
  color: #fcaf17;
  font-weight: 600;
}
.boost-your-business-sec .boost-your-business-content .percentage-content .desc1 {
  font-size: 15px;
  line-height: 20px;
  margin-left: 15px;
}

.social-media-management-sec {
  background-color: #efefef;
}
.social-media-management-sec .social-media-management-content {
  margin-top: 20%;
}
.social-media-management-sec .social-media-management-content .main-heading {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.social-media-management-sec .social-media-management-content .main-heading span {
  color: #fcaf17;
}
.social-media-management-sec .social-media-management-content .desc {
  font-size: 18px;
  line-height: 30px;
}
.social-media-management-sec .social-media-management-content .social-media-icons-list {
  display: flex;
  margin-top: 2%;
}
.social-media-management-sec .social-media-management-content .social-media-icons-list li {
  margin-right: 20px;
}
.social-media-management-sec .social-media-management-content .social-media-icons-list li:hover img {
  transition: all 0.5s;
  animation: bounce 1.5s infinite;
}
.social-media-management-sec .social-media-management-content .social-media-icons-list li img {
  width: 50px;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-17px);
  }
  100% {
    transform: translateY(0px);
  }
}

.turning-point-banner-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/Turningpoint/main-banner/turningpoint-main-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.turning-point-banner-sec .turning-point-banner-content {
  margin-top: 9%;
}
.turning-point-banner-sec .turning-point-banner-content .mt-30 {
  margin-top: 30px;
}
.turning-point-banner-sec .turning-point-banner-content .main-heading {
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: 500;
  margin-top: 20px;
}
.turning-point-banner-sec .turning-point-banner-content .main-heading span {
  color: #fcaf17;
}
.turning-point-banner-sec .turning-point-banner-content .desc {
  font-size: 14px;
  line-height: 25px;
}
.turning-point-banner-sec .turning-point-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}
.turning-point-banner-sec .turning-point-banner-content .transform-button {
  color: white;
  background-color: #0a0a0a;
  border: 1px solid #0a0a0a;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.turning-point-banner-sec .turning-point-banner-content .transform-button:hover {
  border: 1px solid transparent;
  background: #e4252b;
  color: #fff !important;
}
.turning-point-banner-sec .turning-point-banner-content .turning-point-intro-list-box {
  text-align: center;
}
.turning-point-banner-sec .turning-point-banner-content .turning-point-intro-list-box .turning-point-intro-list {
  text-align: left;
}
.turning-point-banner-sec .turning-point-banner-img {
  text-align: end;
}
.turning-point-banner-sec .turning-point-banner-img img {
  margin-top: 5%;
}

.turning-point-about-client-sec {
  background-color: whitesmoke;
}
.turning-point-about-client-sec .turning-point-about-client-main-head .main-heading1 {
  font-size: 40px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
}
.turning-point-about-client-sec .turning-point-about-client-content-box {
  padding-left: 50px;
  padding-top: 15%;
  position: relative;
}
.turning-point-about-client-sec .turning-point-about-client-content-box .desc {
  font-size: 17px;
  line-height: 25px;
}
.turning-point-about-client-sec .turning-point-about-client-img img {
  border-radius: 10px;
}

.turning-point-project-highlights-sec .turning-point-project-highlights-head .main-heading {
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
  color: black;
}
.turning-point-project-highlights-sec .turning-point-project-highlights-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #e95155;
  bottom: 0;
  border-radius: 100px;
}
.turning-point-project-highlights-sec .turning-point-project-highlights-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #e95155;
  bottom: 0;
  border-radius: 100px;
}
.turning-point-project-highlights-sec .turning-point-project-highlights-head .desc {
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
  line-height: 30px;
  color: white;
}
.turning-point-project-highlights-sec .project-highlights-content-area {
  position: relative;
  border-top: 1px solid #e1e1e1;
  transition: all 0.3s ease 0s;
  margin-top: 3%;
}
.turning-point-project-highlights-sec .project-highlights-content-area::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  z-index: 1;
  left: 0;
  top: -7.5px;
}
.turning-point-project-highlights-sec .project-highlights-content-area::after {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  z-index: 1;
  right: 0;
  top: -7.5px;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card {
  position: relative;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card::before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  background: #0a0a0a;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  top: -7.5px;
  z-index: 1;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card::after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  top: 0;
  width: 1px;
  background: #e1e1e1;
  height: 100px;
  z-index: -11;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card .turning-point-project-highlights-card-content {
  padding-top: 100px;
  text-align: center;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card .turning-point-project-highlights-card-content .number-area {
  font-size: 42px;
  font-weight: 800;
  line-height: 80px;
  text-align: center;
  width: 80px;
  height: 80px;
  color: #ffffff;
  margin: 0 auto;
  border-radius: 50%;
  background-image: linear-gradient(90deg, #0a0a0a 0%, #e4252b 100%);
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card .turning-point-project-highlights-card-content .content-txt {
  padding: 20px 10px 0px 10px;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card .turning-point-project-highlights-card-content .content-txt .desc {
  line-height: 25px;
  font-size: 16px;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card-style1 {
  position: relative;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card-style1::before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  background: #e4252b;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  top: -7.5px;
  z-index: 1;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card-style1::after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
  top: 0;
  width: 1px;
  background: #e1e1e1;
  height: 100px;
  z-index: -11;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card-style1 .project-highlights-card-content {
  padding-top: 100px;
  text-align: center;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card-style1 .project-highlights-card-content .number-area {
  font-size: 42px;
  font-weight: 800;
  line-height: 80px;
  text-align: center;
  width: 80px;
  height: 80px;
  color: #ffffff;
  margin: 0 auto;
  border-radius: 50%;
  background-image: linear-gradient(90deg, #0a0a0a 0%, #e4252b 100%);
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card-style1 .project-highlights-card-content .content-txt {
  padding: 20px 15px 0px 15px;
}
.turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card-style1 .project-highlights-card-content .content-txt .desc {
  line-height: 25px;
  font-size: 16px;
}

.turning-point-features-sec {
  position: relative;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/Turningpoint/features/features-bg.png");
  background-size: cover;
  background-attachment: fixed;
}
.turning-point-features-sec .overlay-turning-point-features {
  content: "";
  position: absolute;
  background: rgba(233, 233, 233, 0.55);
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.turning-point-features-sec .position-relative-z-index-1 {
  position: relative;
  z-index: 1;
}
.turning-point-features-sec .features-sec-main-head {
  text-align: center;
}
.turning-point-features-sec .features-sec-main-head .main-heading {
  font-size: 34px;
  font-weight: 600;
}
.turning-point-features-sec .customer-app-features-content {
  margin-top: 20%;
}
.turning-point-features-sec .customer-app-features-content .white-box {
  border: 1px solid #dedede;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  min-height: 100px;
  display: flex;
  align-items: center;
}
.turning-point-features-sec .customer-app-features-content .white-box .featured-list {
  display: flex;
  align-items: center;
}
.turning-point-features-sec .customer-app-features-content .white-box .featured-list li .inner-featured-list {
  margin-left: 15px;
}
.turning-point-features-sec .customer-app-features-content .white-box .featured-list li .inner-featured-list h4 {
  font-size: 17px;
  margin-bottom: 5px;
}
.turning-point-features-sec .customer-app-features-content .white-box .featured-list li .inner-featured-list .desc {
  line-height: 20px;
  font-size: 15px;
}
.turning-point-features-sec .customer-app-features-content .white-box .featured-list li span {
  display: inline-block;
  background: linear-gradient(190deg, rgb(10, 10, 10) 0%, rgb(233, 81, 85) 100%);
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 0;
  color: white;
}

.turning-point-challenges-and-solutions-sec .challenges-and-solutions-main-head {
  text-align: center;
}
.turning-point-challenges-and-solutions-sec .challenges-and-solutions-main-head .main-heading {
  font-size: 34px;
  font-weight: 600;
}
.turning-point-challenges-and-solutions-sec .challenges-and-solutions-main-head span {
  color: #222a41;
}
.turning-point-challenges-and-solutions-sec .challenges-and-solutions-main-head .desc {
  line-height: 25px;
}
.turning-point-challenges-and-solutions-sec .challenges-sec {
  margin-top: 15%;
}
.turning-point-challenges-and-solutions-sec .challenges-sec h4 {
  font-size: 23px;
  font-weight: 600;
}
.turning-point-challenges-and-solutions-sec .challenges-sec .challenges-content-list .challenges-heading {
  color: #e4252b;
  font-weight: 600;
}
.turning-point-challenges-and-solutions-sec .challenges-sec .challenges-content-list .solutions-heading {
  color: #2ed915;
  font-weight: 600;
}
.turning-point-challenges-and-solutions-sec .challenges-sec .challenges-content-list li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
}
.turning-point-challenges-and-solutions-sec .challenges-sec .challenges-content-list li h6 {
  font-size: 18px;
  font-weight: 600;
}
.turning-point-challenges-and-solutions-sec .challenges-sec .challenges-content-list li .desc {
  font-size: 14px;
  line-height: 25px;
}
.turning-point-challenges-and-solutions-sec .challenges-sec .challenges-content-list li svg {
  position: absolute;
  left: 0;
  color: #e4252b;
  top: 2px;
}

.turning-point-conclusion-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 250px;
  background-image: url("../images/casestudy/case-study-1/conclusion/conclusion.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.turning-point-conclusion-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1a0b0c;
  opacity: 0.8;
  z-index: 1;
}
.turning-point-conclusion-sec .turning-point-conclusion-sec-head {
  z-index: 2;
}
.turning-point-conclusion-sec .turning-point-conclusion-sec-head .main-heading {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  color: white;
  margin-bottom: 20px;
}
.turning-point-conclusion-sec .turning-point-conclusion-sec-head .main-heading::before {
  content: "";
  position: absolute;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 3px;
  background-color: #e4252b;
  bottom: 0;
  border-radius: 100px;
}
.turning-point-conclusion-sec .turning-point-conclusion-sec-head .main-heading::after {
  content: "";
  position: absolute;
  left: 60px;
  right: 0;
  margin: 0 auto;
  width: 20px;
  height: 3px;
  background-color: #e4252b;
  bottom: 0;
  border-radius: 100px;
}
.turning-point-conclusion-sec .turning-point-conclusion-content-box .desc {
  color: white;
  text-align: center;
  font-size: 16px;
}

.turning-point-visual-designs-sec .turning-point-visual-designs-main-head {
  text-align: center;
}
.turning-point-visual-designs-sec .turning-point-visual-designs-main-head .main-heading {
  font-size: 34px;
  font-weight: 600;
}
.turning-point-visual-designs-sec .turning-point-visual-designs-main-head .desc {
  line-height: 25px;
  margin-bottom: 40px;
}
.turning-point-visual-designs-sec .turning-point-visual-designs-wrapper {
  background-color: #f0686c;
  padding: 120px 0px 30px;
  position: relative;
}
.turning-point-visual-designs-sec .turning-point-visual-designs-wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100px;
  background: url("../images/casestudy/NikkyPore/visual-designs/portfolio-design.png");
  background-size: cover;
  background-repeat: repeat-x;
}
.turning-point-visual-designs-sec .turning-point-visual-designs-wrapper .turning-point-visual-designs-slide {
  text-align: center;
}
.turning-point-visual-designs-sec .turning-point-visual-designs-wrapper .turning-point-visual-designs-slide img {
  width: 50%;
}

.mid_heading {
  font-weight: 600;
  font-size: 17px;
}

.marketplace-banner-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/MarketplaceManagement/marketplacebanner.webp");
  background-size: cover;
  background-position: center;
}
.marketplace-banner-sec .marketplace-banner-content {
  position: absolute;
  z-index: 2;
  top: 45%;
  width: 30%;
  left: 5%;
}
.marketplace-banner-sec .marketplace-banner-content .main-heading {
  font-size: 30px;
  margin-bottom: 10px;
  color: #000;
}
.marketplace-banner-sec .marketplace-banner-content .main-heading span {
  color: #fcaf17;
}
.marketplace-banner-sec .marketplace-banner-content .sub-heading {
  color: #000;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
  font-size: 16px;
}
.marketplace-banner-sec .marketplace-banner-content .transform-button {
  color: white;
  background-color: #fcaf17;
  border: 1px solid #fcaf17;
  padding: 12px 40px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}
.marketplace-banner-sec .marketplace-banner-content .transform-button:hover {
  border: 1px solid #000;
  background: transparent;
  color: #000 !important;
}

.marektplace_services_sec {
  background-color: #ebebeb !important;
}

@media only screen and (max-width: 992px) {
  .homebannersectoin {
    padding: 25px 0pc !important;
  }
  .homebannersectoin .d-flex {
    display: unset !important;
  }
  .homebannersectoin h1 {
    font-size: 24px;
  }
  .homebannersectoin h2 {
    font-size: 26px;
  }
  .homebannersectoin p {
    font-size: 17px;
    width: 100%;
    margin: 20px 0;
  }
  .homebannersectoin h6 {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0;
  }
  .homebannersectoin .home_banner_img {
    width: 100%;
    height: 400px;
  }
  .homebannersectoin .bannerheading .certificate_img_list li img {
    width: 100px;
    height: 100px;
  }
  .work-flow {
    padding: 0 0pc;
  }
  .bg_contact_pages .contact_left h4 {
    margin-top: 30px;
  }
  .tab-description.portfoliotabs {
    padding: 10px 8px;
  }
  .portfolio-box .portfolio-content {
    bottom: -1px;
    padding: 1pc 5px;
    margin: 0;
  }
  .portfolio-box .portfolio-img img {
    height: 345px;
  }
  .portfolio-box .portfolio-content p {
    font-weight: 500;
    font-size: 13px;
  }
  .ourPortfolio .portfolio_card {
    padding: 40px 9px;
  }
  .ourPortfolio .portfolio_cardimg_content h2 {
    font-size: 24px;
    margin-top: 20px;
    font-weight: 700;
  }
  .portfolio_cardimg img {
    width: 100%;
    height: 320px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .ourPortfolio .portfolio_cardimg_content p {
    font-size: 18px;
    margin: 10px 0;
    color: #000;
    font-weight: 500;
  }
  .otr_lnks_port.gap10 {
    text-align: center;
  }
  .ourPortfolio .otr_lnks_port .btn-casestudy {
    margin-bottom: 20px;
  }
  .chosse_cardsection .col-auto {
    width: 100%;
  }
  .fooerdmc {
    display: none;
  }
  .homrbanner {
    display: none !important;
  }
  .phone_view .title-section {
    padding-bottom: 0 !important;
  }
  .title-section {
    margin-bottom: 0;
  }
  .bg_contact_pages .contact-form-part::after,
  .befoer_borer::before {
    display: none;
  }
  .mb-80 {
    margin-bottom: 0 !important;
  }
  .ptb-80 {
    padding-top: 25px !important;
    padding-bottom: 20px !important;
  }
  .pt-80 {
    padding-top: 40px !important;
  }
  .bootom-bar .navbar {
    padding: 10px;
  }
  .banner .outer-box {
    padding: 1pc;
  }
  .banner .box {
    margin-bottom: 20px;
    display: unset;
  }
  .mobile_nav a {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  .content.flex-1 {
    margin-bottom: 40px;
  }
  .blog2_heading h3 {
    font-size: 23px;
    font-weight: 300;
  }
  .blog2_heading h4 {
    font-size: 25px;
  }
  .blog2_heading p {
    font-size: 16px;
  }
  .worksflosection .dots-line {
    display: none;
  }
  .blog-detail .image {
    height: 190px;
  }
  .blog_listing .blog_listing_img img {
    height: 200px;
  }
  .row.mobile_scrollbar {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 15px;
  }
  .technology-used ul .col-auto {
    width: 33%;
  }
  .technology-used .mobile_technologyspace {
    --bs-gutter-x: 0.3rem;
  }
  .technology-used ul .box {
    margin-bottom: 30px;
    min-height: 119px;
  }
  .technology-used ul .box h6 {
    font-size: 12px;
  }
  .phone_view {
    background-color: #fafafa;
    padding: 60px 0px;
  }
  .phone_view .col {
    flex: 1 1;
    min-width: 350px;
  }
  .row.mobile_scroll_view {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .phone_view ::-webkit-scrollbar {
    width: 10px !important;
    height: 4px !important;
  }
  .phone_view ::-webkit-scrollbar-track {
    background-color: #e2e1dd;
    border-radius: 0px;
  }
  .phone_view ::-webkit-scrollbar-thumb {
    background: #fcaf17;
    border-radius: 4px;
  }
  .in_expertise_s .col-auto {
    width: 50%;
  }
  .box_indstr p {
    font-size: 13px;
  }
  .our-portfolio .outer-box .box.reverse .right {
    text-align: left;
  }
  .in_expertise_s img {
    height: 35px;
    width: 35px;
  }
  .in_expertise_s .box_indstr {
    margin-bottom: 5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0), 0 -1px 5px 0 rgba(0, 0, 0, 0.12);
    min-height: 130px;
  }
  .in_expertise_s .box_indstr p {
    font-size: 12px;
  }
  .tab-description {
    background: #fff;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    padding: 25px 20px 35px 20px;
    min-height: 575px;
    margin-bottom: 15px;
    margin-top: 9px;
    position: relative;
  }
  .tab-description h3 {
    font-size: 16px !important;
    position: relative;
  }
  .scroll_right {
    display: block;
  }
  .ptb-40 {
    padding: 25px 0px !important;
  }
  .tab-description h3::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 40px;
    background-color: #fcaf17;
  }
  .tab-description p {
    font-size: 12px;
    line-height: 1.71;
    /* min-height: 277px; */
    text-align: justify;
    margin-bottom: 27px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 6;
    -webkit-line-clamp: 6;
    overflow: hidden;
  }
  .tab-description h6 {
    font-size: 14px;
    margin: 12px 0;
  }
  .tab-description li {
    position: relative;
    margin-bottom: 5px;
    font-size: 11px;
    padding-left: 14px;
    line-height: 18px;
    list-style-type: none !important;
  }
  .tab-description li::after {
    position: absolute;
    content: "";
    left: 0;
    width: 6px;
    height: 6px;
    background-color: #fcaf17;
    top: 11px;
  }
  .consultation .row {
    border-top-right-radius: 10px;
  }
  .consultation .left {
    padding: 10px;
  }
  .blog-box {
    margin-bottom: 30px;
  }
  .blog-box h4 {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
  }
  .pb-80 {
    padding-bottom: 20px !important;
  }
  .mb-80 {
    margin-bottom: 20px;
  }
  .footer-links {
    margin-top: 30px;
  }
  .footer .footer-links .title {
    margin-bottom: 10px;
  }
  .mobile_viewslider {
    background-color: #fafafa;
  }
  .logo_header {
    background: #fff;
    box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    text-align: center;
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
  }
  .logo_header img {
    width: 150px;
  }
  .mobile_viewslider .swiper-button-next:after,
  .mobile_viewslider .swiper-button-prev:after {
    position: absolute;
    font-size: 22px !important;
    color: #343434;
    background-color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 13px !important;
    border: 1px solid rgba(204, 204, 204, 0.1803921569);
    border-radius: 57px;
  }
  .testmonail_boxshowmobile {
    padding: 23px 20px;
  }
  .iocnquite {
    font-size: 45px;
    opacity: 0.3;
  }
  .testmonail_boxshowmobile p {
    font-size: 14px;
    color: #000;
    margin-top: 15px;
    text-align: justify;
  }
  .testmonail_boxshowmobile h4 {
    font-size: 20px;
    margin-top: 25px;
    font-style: italic;
  }
  .our-clients {
    overflow: hidden;
    width: 100%;
  }
  .jrny-border .img-col {
    padding: 25px 0;
  }
  .jrny-border .jrny-arrow::before {
    left: 47%;
  }
  .row.row_reverse {
    flex-direction: column-reverse;
  }
  .content_mission h3 {
    margin: 20px 0;
  }
  .topbar {
    padding: 10px 1px;
  }
  .jrny-border .heading {
    font-size: 18px;
  }
  .main-blog .container-fluid {
    padding: 0px 2pc;
  }
  .blog-detail .blog-box h3.heading {
    font-size: 25px;
    line-height: 30px;
  }
  .row.about-card {
    margin-bottom: 30px;
  }
  .center_mobile {
    text-align: center;
  }
  .slider_content {
    background-color: #f6f6f6;
    padding: 29px 13px;
    min-height: 360px;
  }
  .slider_content h2 {
    position: relative;
  }
  .slider_content p {
    margin: 10px 0;
  }
  .slider_content a {
    color: #fcaf17;
    font-size: 19px;
    font-weight: 500;
  }
  .consultation .left {
    margin-bottom: 20px;
  }
  .bg_contact_pages .contact-form-part {
    padding: 15px 10px;
  }
  .bg_contact_pages .contact-form-part h3 {
    font-size: 15px;
    line-height: 23px;
  }
  .bg_contact_pages .contact-form-part img {
    width: 4pc;
  }
  .title-section.mb-5 {
    margin-bottom: 0 !important;
  }
  .cour_value .icon-head-wrap img {
    width: 35px;
    height: 37px;
  }
  .cour_value .icon-head-wrap h4 {
    font-size: 18px;
  }
  .cour_value .icon-head-wrap p {
    width: 100%;
  }
  .our-key-experties .mt-5 {
    margin-top: 0px !important;
  }
  .mission_value .imgabsolute {
    display: none;
  }
  .blog_container.top-banner {
    margin-bottom: 20px !important;
  }
  .appdevelopemnt .topimghand img {
    display: none;
  }
  .appdevelopemnt .contindevelopment {
    padding-left: 15px;
  }
  .fluidpadding10 .container-fluid {
    padding: 0px 15px;
  }
  .appdevelopemnt .leftapp {
    flex: unset;
  }
  .appdevelopemnt .contindevelopment h2 {
    font-size: 30px;
  }
  .developmentsecib .cardapp {
    padding: 15px;
  }
  .developmentsecib .cardapp h3 {
    font-size: 17px;
    font-weight: 700;
  }
  .fntch_fnl_inn {
    padding: 20px 16px;
  }
  .fntch_fnl_inn .develmntser {
    margin-top: 20px;
  }
  .fntch_fnl_inn .develmntser h3 {
    font-size: 21px !important;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 41px;
  }
  .fntch_fnl_inn img {
    margin-top: 25px;
  }
  .development_servicer img {
    margin-bottom: 30px;
  }
  .freaturesectionapp img {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .blog-detail .blog-box .main_heading {
    font-size: 17px !important;
    padding: 10px 0px;
    font-weight: 600;
  }
  .main-logo {
    width: 100px !important;
    height: 35px !important;
  }
  .bootom-bar .navbar {
    padding: 5px !important;
  }
  .homebannersectoin {
    padding: 10px 0pc !important;
  }
  .homebannersectoin .bannerheading p {
    width: 100%;
    font-size: 11px !important;
    margin: 5px 0;
    line-height: 16px !important;
  }
  .homebannersectoin .home_banner_img {
    height: 225px !important;
  }
  .homebannersectoin .animate_buttn {
    margin: 0px !important;
  }
  .homebannersectoin .animate_buttn button {
    padding: 6px 20px !important;
    font-size: 13px !important;
    margin-top: 0px !important;
  }
  .homebannersectoin h6 {
    font-size: 14px !important;
    margin: 6px 0 !important;
  }
  .services_list li {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .worksflosection .box {
    padding: 15px;
    min-height: 230px;
  }
  .worksflosection .process-icon {
    width: 55px;
    height: 50px;
    margin: 0 auto 15px;
  }
  .worksflosection h4 {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .worksflosection p {
    font-size: 13px;
  }
  .case-study ul.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0%;
  }
  .case-study ul.tabs li {
    margin-bottom: 15px;
  }
  .newportfolyo li {
    padding: 0px 0px !important;
  }
  .blog-detail .blog-box .blogh2 {
    font-size: 16px !important;
  }
  .blog-detail .blog-box p {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 20px;
  }
  .blog-detail .blog-box h5 {
    font-weight: 600;
    padding: 5px 0px;
    font-size: 15px;
  }
  .blog-detail ul.tags li {
    color: #000;
    font-size: 12px;
  }
  .blog-box .image {
    height: 185px !important;
  }
  .blog .blog-box p {
    font-size: 13px;
    line-height: 20px;
  }
  .blog-box h4 {
    margin: 5px 0 !important;
    font-size: 15px !important;
    font-weight: 600;
  }
  .blog-box ul.tags li {
    font-size: 12px !important;
  }
  .bottom-btn {
    margin-bottom: 10px;
  }
  .bottom-btn a {
    font-size: 14px !important;
    padding: 8px 16px !important;
  }
  .title-section h1 {
    font-size: 17px !important;
    line-height: 25px !important;
  }
  .title-section p {
    text-align: center !important;
    font-size: 13px !important;
    line-height: 20px !important;
    margin-bottom: 10px !important;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1199px) {
  .homebannersectoin {
    padding: 25px 1pc !important;
  }
  .homebannersectoin .d-flex {
    display: unset !important;
  }
  .homebannersectoin h1 {
    font-size: 24px;
    padding: 0px;
  }
  .homebannersectoin h2 {
    font-size: 22px;
  }
  .homebannersectoin p {
    font-size: 15px;
    width: 100%;
    margin: 15px 0;
  }
  .homebannersectoin h6 {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0;
  }
  .homebannersectoin .home_banner_img {
    width: 100%;
    height: 400px;
  }
  .homebannersectoin .bannerheading .certificate_img_list li img {
    width: 100px;
    height: 100px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 349px) {
  .topbar .dowload_brochure {
    font-size: 11px !important;
    padding: 3px !important;
  }
  .main-logo {
    height: 40px !important;
  }
  .pl-10 {
    padding-left: 8px;
  }
  .topbar-inner {
    justify-content: space-around !important;
  }
  .topbar-inner p a {
    font-size: 11px !important;
  }
  .topbar .container-fluid {
    padding: 0px 5px !important;
  }
}
@media only screen and (min-width: 350px) and (max-width: 575px) {
  .topbar .dowload_brochure {
    font-size: 12px !important;
    padding: 5px 8px !important;
  }
  .main-logo {
    height: 40px !important;
  }
  .pl-10 {
    padding-left: 10px;
  }
  .topbar-inner {
    justify-content: space-around !important;
  }
  .topbar-inner p a {
    font-size: 12px !important;
  }
  .topbar .container-fluid {
    padding: 0px 8px !important;
  }
}
@media only screen and (max-width: 768px) {
  .homebannersectoin {
    padding: 20px 0pc;
  }
  .homebannersectoin .home_banner_img {
    width: 225px;
    height: 260px;
  }
  .homebannersectoin .bannerheading .certificate_img_list li img {
    width: 75px;
    height: 75px;
  }
  .topbar .dowload_brochure {
    font-size: 13px;
  }
  .bannerheading h1 {
    font-size: 16px;
    padding: 0;
  }
  .bannerheading h2 {
    font-size: 14px;
  }
  .bannerheading p {
    width: 100%;
    font-size: 13px;
    margin: 10px 0;
  }
  .main-blog .container-fluid {
    padding: 0px 14px;
  }
  .topbar .container-fluid {
    font-size: 13px;
    padding: 0px 15px;
  }
  .title-section .heading {
    font-size: 18px;
    line-height: 30px;
  }
  .services-swiper {
    padding-top: 30px;
  }
  .title-section p {
    text-align: center;
  }
  .services-swiper ul.tabs li:before {
    right: -1pc;
    width: calc(100% + 2pc);
  }
  .title-section {
    padding-bottom: 5px !important;
  }
  .our-team .col-auto {
    width: 50%;
  }
  .our-team .box img {
    width: 162px;
  }
  .carrier_container img {
    height: 38vh;
  }
  .carrier_container h4 {
    font-size: 20px;
    line-height: 30px;
  }
  .our-clients .marquee-items.left .items_logo {
    height: 120px;
  }
  .our-clients .marquee-items.right .items_logo {
    height: 120px;
  }
  .btn.btn-learmore {
    position: absolute;
    bottom: 10px;
    left: 20px;
    color: #fcaf17;
    font-weight: 500;
  }
  .footer {
    padding: 1pc 0 0pc;
  }
}
@media only screen and (max-width: 991px) {
  .mobile_viewslider {
    display: none !important;
  }
  .blog-detail .blog-box .blogh2 {
    font-size: 16px !important;
  }
  .blog-detail .blog-box p {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 20px;
  }
  .blog-detail .blog-box h5 {
    font-weight: 600;
    padding: 5px 0px;
    font-size: 15px;
  }
  .blog-detail ul.tags li {
    color: #000;
    font-size: 12px;
  }
  .blog-box .image {
    height: 240px !important;
  }
  .title-section.mb-5.title-section.mb-5.text-center {
    margin-bottom: 2rem !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .homebannersectoin .home_banner_img {
    width: 390px;
    height: 390px;
  }
  .bootom-bar .navbar-nav {
    justify-content: flex-end;
    gap: 0px;
  }
  .bootom-bar .navbar-nav .nav-link {
    font-size: 15px;
    padding: 18px 7px;
  }
  .blog .blog-box p {
    font-size: 13px;
    line-height: 20px;
  }
  .blog-box h4 {
    margin: 5px 0 !important;
    font-size: 18px !important;
    font-weight: 600;
  }
  .blog-box ul.tags li {
    font-size: 12px !important;
  }
}
/**************Ecommerce*****************/
/**************Ecommerce*****************/
@media (min-width: 768px) and (max-width: 991px) {
  .head-img-2::before {
    margin-left: 28% !important;
  }
  .second-banner .screen_two {
    margin-bottom: 270px;
  }
  .second-banner .absolute_img2 {
    position: absolute;
    bottom: -204px;
  }
  .third-banner .features {
    min-height: 212px;
  }
  .fourth-banner .box:hover {
    height: 330px;
  }
  .eigth-banner ul {
    margin-bottom: 40px;
  }
}
@media (min-width: 310px) and (max-width: 767px) {
  .first-banner.mb-80 {
    margin-bottom: 0 !important;
    height: 70vh;
  }
  .first-banner.mb-80 .head-img-1 {
    display: none;
  }
  .first-banner.mb-80 .head-img-2 {
    display: none;
  }
  .first-banner.mb-80 h1.heading {
    font-size: 32px;
    line-height: 35px;
    padding-top: 30px;
    text-align: center;
  }
  .first-banner.mb-80 span {
    font-size: 32px;
  }
  .first-banner.mb-80 p {
    font-size: 14px;
    color: #fff;
    text-align: center;
  }
  .first-banner.mb-80 .btn {
    font-size: 14px;
  }
  .title-section .heading {
    line-height: 30px;
  }
  .ecomprebtncenter {
    text-align: center;
  }
  .second-banner.mb-5 {
    margin-bottom: 0 !important;
  }
  .second-banner.mb-5 .screen_two {
    margin-bottom: 130px;
  }
  .second-banner.mb-5 .absolute_img2 {
    position: absolute;
    bottom: -109px;
  }
  .second-banner.mb-5 .content h1 {
    font-size: 28px;
    text-align: center;
  }
  .second-banner.mb-5 .content span {
    font-size: 28px;
    text-align: center;
  }
  .second-banner.mb-5 .content p {
    font-size: 14px;
  }
  .third-banner.mb-5 {
    margin-bottom: 0 !important;
  }
  .third-banner.mb-5 .third-title {
    font-size: 28px;
  }
  .third-banner.mb-5 span {
    font-size: 28px;
  }
  .third-banner.mb-5 p {
    font-size: 14px;
  }
  .third-banner.mb-5 ul {
    flex-direction: row;
  }
  .third-banner.mb-5 ul li.nav-item {
    width: 33%;
  }
  .third-banner.mb-5 ul li.nav-item a.nav-link {
    padding: 0;
    font-size: 13px;
  }
  .third-banner.mb-5 ul li.nav-item a.nav-link.active {
    padding: 0;
    font-size: 13px;
  }
  .third-banner.mb-5 .features {
    min-height: 154px;
    padding: 10px;
  }
  .third-banner.mb-5 .features img {
    height: 50px;
  }
  .third-banner.mb-5 .features h5 {
    font-size: 15px;
  }
  .fourth-banner.mb-5 {
    margin-bottom: 0 !important;
  }
  .fourth-banner.mb-5 h3 {
    font-size: 28px;
  }
  .fourth-banner.mb-5 span {
    font-size: 28px;
  }
  .fourth-banner.mb-5 .box:hover {
    height: 270px;
  }
  .fourth-banner.mb-5 .sub-head .heading {
    font-size: 16px;
  }
  .fourth-banner.mb-5 .box-description .description {
    font-size: 14px;
  }
  .fifth-banner.mb-5 {
    margin-bottom: 0 !important;
  }
  .fifth-banner.mb-5 h3 {
    font-size: 28px;
  }
  .fifth-banner.mb-5 span {
    font-size: 28px;
  }
  .fifth-banner.mb-5 .content {
    min-height: 270px;
  }
  .fifth-banner.mb-5 .content h3 {
    font-size: 21px;
  }
  .fifth-banner.mb-5 .content h4 {
    font-size: 16px;
    margin-top: 6px;
  }
  .fifth-banner.mb-5 .content p {
    font-size: 14px;
  }
  .sixth-banner.mb-5 {
    margin-bottom: 0 !important;
  }
  .sixth-banner.mb-5 h3 {
    font-size: 28px;
  }
  .sixth-banner.mb-5 span {
    font-size: 28px;
  }
  .sixth-banner.mb-5 .inner_content h3 {
    font-size: 17px;
  }
  .sixth-banner.mb-5 .inner_content p {
    font-size: 14px;
  }
  .sixth-banner.mb-5 .inner_content img {
    height: 55px;
  }
  .tenth-banner.mb-5 {
    margin-bottom: 0 !important;
  }
  .tenth-banner.mb-5 h3 {
    font-size: 28px;
  }
  .tenth-banner.mb-5 span {
    font-size: 28px;
  }
  .tenth-banner.mb-5 p {
    font-size: 14px;
  }
  .tenth-banner.mb-5 .gx-5 {
    --bs-gutter-x: unset;
  }
  .ninth-banner.mb-5 {
    margin-bottom: 0 !important;
  }
  .ninth-banner.mb-5 h3 {
    font-size: 28px;
  }
  .ninth-banner.mb-5 span {
    font-size: 28px;
  }
  .ninth-banner.mb-5 p {
    font-size: 14px;
  }
  .ninth-banner.mb-5 .accordion-item .accordion-button {
    font-size: 13.5px;
    font-weight: 500;
  }
  .ninth-banner.mb-5 .accordion-item .accordion-collapse p {
    font-size: 14px;
  }
  .eleventh-banner .py-5 h3 {
    font-size: 24px;
  }
  .eleventh-banner .py-5 p {
    font-size: 17px;
  }
  .eleventh-banner .py-5 .btn {
    font-size: 14px;
  }
  .ptb-40 {
    padding: 20px 0px !important;
  }
  .eigth-banner .main_content.pt-5.pb-5 {
    padding: 0px 0px;
  }
  .eigth-banner h4 {
    font-size: 20px;
    text-align: center;
  }
  .eigth-banner p {
    font-size: 14.5px;
    text-align: center;
  }
  .eigth-banner .query_help {
    justify-content: center;
  }
  .eigth-banner svg {
    height: 25px;
  }
  .eigth-banner span h6 {
    font-size: 11px;
  }
  .eigth-banner span p {
    font-size: 17px;
  }
  .eigth-banner ul {
    text-align: center;
    margin-bottom: 30px;
  }
  .eigth-banner ul h5 {
    font-size: 17.5px;
  }
  .eigth-banner ul li {
    font-size: 15px;
  }
  .eigth-banner .Q-form {
    padding: 30px 20px;
  }
  .eigth-banner .Q-form .form-control {
    padding: 11px;
    font-size: 14px;
  }
  .eigth-banner .Q-form .btn {
    font-size: 15px;
    padding: 5px;
  }
}
@media (min-width: 310px) and (max-width: 375px) {
  .third-banner.mb-5 ul {
    flex-direction: row;
  }
  .third-banner.mb-5 ul li.nav-item {
    width: 33%;
  }
  .third-banner.mb-5 ul li.nav-item a.nav-link {
    padding: 0;
    font-size: 11px;
  }
  .third-banner.mb-5 ul li.nav-item a.nav-link.active {
    padding: 0;
    font-size: 10px;
  }
  .pt-80 {
    padding-top: 15px !important;
  }
  .bannerheading h1 {
    font-size: 13px;
  }
  .bannerheading h2 {
    font-size: 15px;
  }
  .bannerheading p {
    width: 100%;
    font-size: 13px;
    margin: 5px 0;
    line-height: 20px;
  }
  .certificate_img_list li img {
    width: 66px;
    height: 66px;
  }
  .homebannersectoin h6 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 10px 0;
  }
}
/**************Grocery*****************/
@media (min-width: 768px) and (max-width: 992px) {
  .gro_first-banner.mb-80 {
    margin-bottom: 0 !important;
  }
  .gro_first-banner.mb-80 .right-content {
    text-align: center;
  }
  .gro_second-banner h1 {
    text-align: center;
  }
  .gro_second-banner span {
    text-align: center;
  }
  .gro_third-banner.mb-5 {
    margin-bottom: 0 !important;
  }
  .gro_third-banner.mb-5 .features {
    min-height: 212px;
  }
  .gro_fifth-banner .modal-content1 {
    min-height: 547px;
  }
  .gro_fifth-banner .modal-content1 .business_img-box {
    margin-top: 20px;
  }
}
@media (min-width: 310px) and (max-width: 768px) {
  .gro_first-banner.mb-80 {
    margin-bottom: 0 !important;
    height: 70vh;
  }
  .gro_first-banner.mb-80 .py-5 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .gro_first-banner.mb-80 .right-content {
    text-align: center;
  }
  .gro_first-banner.mb-80 h1.heading {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }
  .gro_first-banner.mb-80 span {
    font-size: 28px;
  }
  .gro_first-banner.mb-80 p {
    font-size: 14px;
    color: #fff;
    text-align: center;
  }
  .gro_first-banner.mb-80 .btn {
    font-size: 14px;
    padding: 10px;
  }
  .gro_second-banner h1 {
    font-size: 28px;
    text-align: center;
  }
  .gro_second-banner span {
    font-size: 28px;
  }
  .gro_second-banner p {
    font-size: 14px;
  }
  .gro_third-banner.mb-5 {
    margin-bottom: 0 !important;
  }
  .gro_third-banner.mb-5 .third-title {
    font-size: 28px;
  }
  .gro_third-banner.mb-5 span {
    font-size: 28px;
  }
  .gro_third-banner.mb-5 p {
    font-size: 14px;
  }
  .gro_third-banner.mb-5 ul {
    flex-direction: row;
  }
  .gro_third-banner.mb-5 ul li.nav-item {
    width: 33%;
  }
  .gro_third-banner.mb-5 ul li.nav-item a.nav-link {
    padding: 0;
    font-size: 13px;
  }
  .gro_third-banner.mb-5 ul li.nav-item a.nav-link.active {
    padding: 0;
    font-size: 13px;
  }
  .gro_third-banner.mb-5 .features {
    min-height: 165px;
    padding: 10px;
  }
  .gro_third-banner.mb-5 .features img {
    height: 50px;
  }
  .gro_third-banner.mb-5 .features h5 {
    font-size: 15px;
  }
  .gro_fourth-banner h1.heading {
    font-size: 28px;
    line-height: 36px;
  }
  .gro_fourth-banner span {
    font-size: 28px;
  }
  .gro_fourth-banner p {
    font-size: 14px;
  }
  .gro_fourth-banner .borderright {
    border-right: none;
  }
  .gro_fourth-banner .bordertop {
    border-top: none;
  }
  .gro_fourth-banner .mt-5 {
    margin-top: 5px !important;
    padding: 0;
  }
  .gro_fourth-banner .pt-5 {
    padding-top: 10px !important;
  }
  .gro_fourth-banner .pb-5 {
    padding-bottom: 30px !important;
  }
  .gro_fourth-banner .img-fluid {
    width: 50px;
    height: 50px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .gro_fourth-banner .head h3 {
    font-size: 16px;
  }
  .gro_fourth-banner .head p {
    font-size: 14px;
  }
  .gro_fifth-banner h1.heading {
    font-size: 28px;
    line-height: 36px;
  }
  .gro_fifth-banner span {
    font-size: 28px;
  }
  .gro_fifth-banner p {
    font-size: 14px;
  }
  .gro_fifth-banner .business_img-box {
    margin-top: 20px;
  }
  .gro_fifth-banner .modal-content1 {
    margin-top: 30px;
  }
  .gro_fifth-banner .modal-content1 .business-content h4 {
    font-size: 19px;
  }
  .gro_fifth-banner .modal-content1 .business-content p {
    font-size: 14px;
  }
  .gro_fifth-banner .pt-5 {
    padding-top: 0 !important;
  }
  .gro_sixth-banner h1 {
    font-size: 28px;
  }
  .gro_sixth-banner h1 span {
    font-size: 28px;
  }
  .gro_sixth-banner p {
    font-size: 14px;
  }
  .gro_eigth-banner .py-5 h3 {
    font-size: 28px;
  }
  .gro_eigth-banner .py-5 p {
    font-size: 18px;
  }
  .gro_eigth-banner .py-5 .btn {
    font-size: 15px;
  }
}
@media (min-width: 310px) and (max-width: 375px) {
  .sliders2 {
    padding: 0px 10px 0 10px;
  }
  .gro_second-banner img {
    height: 275px;
  }
  .gro_third-banner.mb-5 {
    padding-top: 0px !important;
  }
  .gro_third-banner.mb-5 ul {
    flex-direction: row;
  }
  .gro_third-banner.mb-5 ul li.nav-item {
    width: 33%;
  }
  .gro_third-banner.mb-5 ul li.nav-item a.nav-link {
    padding: 0;
    font-size: 11px;
  }
  .gro_third-banner.mb-5 ul li.nav-item a.nav-link.active {
    padding: 0;
    font-size: 11px;
  }
}
/*************** Edtech ****************/
@media (min-width: 310px) and (max-width: 991px) {
  .sixth-section img {
    margin-top: 0;
  }
  .sixth-section .mobile-reverse {
    display: flex;
    flex-direction: row-reverse;
  }
  .sixth-section .works_section h5 {
    text-align: left;
  }
  .sixth-section .works_section .para {
    text-align: left;
  }
  .eigth-banner .border-right {
    border-right: none;
  }
  .eigth-banner h4 {
    text-align: center;
  }
  .eigth-banner p {
    text-align: center;
  }
  .eigth-banner .contact_onfo {
    text-align: center;
  }
  .eigth-banner .icon.svgiocn svg {
    margin-bottom: 10px;
  }
  .eigth-banner ul {
    text-align: center;
  }
}
@media (min-width: 310px) and (max-width: 768px) {
  .fourth-section ul li {
    width: 25%;
  }
  .fourth-section ul li a.nav-link.active {
    padding: 10px;
  }
  .fourth-section ul li a.nav-link {
    padding: 10px;
  }
}
@media (min-width: 310px) and (max-width: 767px) {
  .first-section.mb-80 {
    height: 70vh;
  }
  .first-section.mb-80 h1 {
    font-size: 32px;
    line-height: 35px;
    padding-top: 30px;
    text-align: center;
  }
  .first-section.mb-80 h1 span {
    font-size: 32px;
    line-height: 35px;
    padding-top: 30px;
    text-align: center;
  }
  .first-section.mb-80 p {
    font-size: 14px;
  }
  .first-section.mb-80 .btn {
    font-size: 14px;
  }
  .third-section .third-title {
    font-size: 28px;
    text-align: center;
  }
  .third-section .third-title span {
    font-size: 28px;
    text-align: center;
  }
  .third-section .third-para {
    font-size: 14px;
  }
  .third-section li {
    font-size: 14px;
  }
  .fourth-section h3.fourth-title {
    font-size: 28px;
  }
  .fourth-section p {
    font-size: 14px;
  }
  .fourth-section ul li {
    width: 25%;
  }
  .fourth-section ul li a.nav-link.active {
    padding: 0px;
    font-size: 14px;
  }
  .fourth-section ul li a.nav-link {
    padding: 0px;
    font-size: 14px;
  }
  .fourth-section .features {
    min-height: 155px;
    padding: 10px;
  }
  .fourth-section .features img {
    height: 50px;
  }
  .fourth-section .features h5 {
    font-size: 15px;
  }
  .seventh-section .py-5 h3 {
    font-size: 28px;
  }
  .seventh-section .py-5 p {
    font-size: 18px;
  }
  .seventh-section .py-5 .btn {
    font-size: 15px;
  }
  .eigth-section h3.eigth-title {
    font-size: 28px;
  }
  .eigth-section h3.eigth-title span {
    font-size: 28px;
  }
  .eigth-section p {
    font-size: 14px;
  }
  .eigth-section .head h3 {
    font-size: 15px;
  }
  .eigth-section .head p {
    font-size: 13px;
  }
  .sixth-section .sixth-title {
    font-size: 28px;
  }
  .sixth-section .sixth-title span {
    font-size: 28px;
  }
  .sixth-section p {
    font-size: 14px;
  }
  .sixth-section .works_section h5 {
    font-size: 17px;
  }
  .sixth-section .works_section .para {
    font-size: 14px;
  }
  .sixth-section .right-side h5 {
    font-size: 17px;
  }
  .sixth-section .right-side .para {
    font-size: 14px;
  }
  .sixth-section span.number {
    font-size: 13px;
    padding: 6px 11px;
  }
  .sixth-section img {
    height: 500px;
  }
  .sixth-section .right-side h5 {
    font-size: 17px;
  }
  .sixth-section .right-side p {
    font-size: 14px;
  }
  .tenth-section h3 {
    font-size: 28px;
  }
  .tenth-section span {
    font-size: 28px;
  }
  .tenth-section p {
    font-size: 14px;
  }
  .fifth-section h3.title {
    font-size: 28px;
  }
  .fifth-section h3.title span {
    font-size: 28px;
  }
  .fifth-section p {
    font-size: 14px;
  }
  .fifth-section .work .images1 {
    height: 350px;
  }
  .fifth-section .work .images2 {
    height: 400px;
  }
  .fifth-section a.btn {
    padding: 6px 16px;
    font-size: 14px;
    margin-top: 0px;
  }
}
@media (min-width: 310px) and (max-width: 575px) {
  .third-section li {
    font-size: 11px;
  }
  .homebannersectoin .home_banner_img img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .homebannersectoin .bannerheading .certificate_img_list li img {
    width: 60px;
    height: 60px;
  }
  .fourth-section ul li {
    width: 35%;
  }
  .fourth-section ul li a.nav-link.active {
    padding: 0px;
    font-size: 13px;
  }
  .fourth-section ul li a.nav-link {
    padding: 0px;
    font-size: 13px;
  }
  .sixth-section .right-side .list {
    text-align: right;
  }
  .eigth-section .bordertop {
    border-top: none;
  }
  .eigth-section .borderbottom {
    border-bottom: 1px solid #fcaf17;
  }
  .eigth-section .bordertop1 {
    border-top: 1px solid #fcaf17;
  }
  .eigth-section .borderright {
    border-right: none;
  }
  .eigth-section .borderleft {
    border-left: none;
  }
  .tenth-section .borderbottom {
    border-bottom: none;
  }
  .tenth-section .borderright {
    border-right: none;
  }
}
@media (min-width: 360px) and (max-width: 540px) {
  .fifth-section p {
    margin-bottom: 20px !important;
  }
  .fifth-section .work .images1 {
    height: 230px;
  }
  .fifth-section .work .images2 {
    height: 185px;
  }
  .seventh-section .py-5 h3 {
    font-size: 21px;
    line-height: 31px;
  }
  .seventh-section .py-5 p {
    font-size: 17px;
  }
}
@media (min-width: 310px) and (max-width: 360px) {
  .third-section li {
    font-size: 8px;
    padding: 0;
  }
  .third-section li::before {
    position: unset;
    display: none;
  }
  .fourth-section ul li a.nav-link.active {
    font-size: 12px;
  }
  .fourth-section ul li a.nav-link {
    font-size: 12px;
  }
  .fifth-section .work .overlay {
    height: 90%;
  }
  .eigth-banner ul {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 575px) {
  .our-clients .marquee-items.left .items_logo {
    width: 100%;
    height: 85px !important;
  }
  .our-clients .marquee-items.left .items_logo img {
    width: 100% !important;
    height: 70px !important;
  }
  .portfolio-left-area h4 {
    color: white;
    font-size: 26px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-top: 50px;
  }
  .works-list-1 li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }
  .works-list li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }
  .travel-about-us .about-img {
    padding-top: 0px;
  }
  .py-40 {
    padding: 20px 0px;
  }
  .banner-sec {
    height: 350px !important;
  }
  .py-5.row {
    padding: 1rem 0rem !important;
  }
  .travel-banner-content {
    position: absolute;
    top: 25%;
    left: 0%;
    z-index: 1;
    width: 100%;
  }
  .req-demo {
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;
  }
  .travel-banner-content h2 {
    color: white;
    font-size: 18px;
    font-weight: 600;
    font-family: var(--roboto-fonte);
    margin-bottom: 0px;
  }
  .travel-banner-content .desc {
    font-size: 14px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-top: 15px;
    line-height: 20px;
  }
  .about-content {
    margin-bottom: 30px;
  }
  .about-content .main-heading {
    font-size: 19px;
    font-weight: 600;
    color: #020202;
    margin: 10px 0px;
  }
  .about-content .desc {
    font-size: 14px;
  }
  .features-head h2 {
    font-size: 20px;
    font-weight: 600;
    color: #020202;
    font-family: "Roboto", sans-serif;
    text-align: center;
    margin-bottom: 10px;
  }
  .travel-choose-card .heading-part h4 {
    font-size: 13px;
  }
  .travel-choose-card {
    padding: 10px;
  }
  .nav-tabs .nav-item button {
    background-color: #e8ebf1;
    border-radius: 50px;
    color: #020202;
    padding: 5px 10px 5px 7px !important;
    margin: 0px !important;
    margin-right: 5px !important;
    font-size: 11px !important;
    font-weight: 600;
  }
  .portfolio-left-area {
    background-color: rgb(215, 164, 64);
    width: 100% !important;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
  }
  .connect {
    position: absolute;
    top: 25%;
    left: 5%;
  }
  .connect .desc {
    color: white;
    font-size: 18px !important;
    font-family: var(--roboto-fonte);
  }
  .quote {
    position: absolute;
    top: 80%;
    left: 5%;
    color: white;
    font-size: 20px;
    font-family: var(--roboto-fonte);
  }
  .portfolio-area {
    background-color: #f5e9ce;
    width: 100%;
    position: relative;
    min-height: 550px !important;
  }
  .sch-software-process-tabs {
    display: none;
  }
  .sch-software-development-process .faq-accordion {
    display: block;
  }
  .sch-software-development-process .faq-accordion .accordion .accordion-button {
    background-image: none !important;
    font-size: 15px;
  }
  .faq-accordion .accordion .accordion-button {
    background-image: none !important;
    font-size: 11px;
  }
  .faq-accordion .accordion .accordion-header svg {
    content: "";
    position: absolute;
    right: 4%;
    top: 35%;
  }
  .faq-accordion .accordion .accordion-body {
    background-color: rgb(252, 175, 23);
    color: white;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
  .sch-software-development-process .faq-accordion .accordion .accordion-body {
    background-color: rgb(252, 175, 23);
    color: white;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .features-card {
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s linear;
    min-height: 120px;
    margin-bottom: 25px;
  }
  .features-img img {
    width: 30px;
    height: 30px;
    margin-bottom: 0px;
    text-align: center;
  }
  .features-card h5 {
    padding: 5px;
    font-size: 12px;
    margin-top: 5px;
  }
  .features-head .desc {
    font-size: 12px;
  }
  .job-banner-sec .job-banner-content {
    position: absolute;
    top: 45%;
    left: 0%;
  }
  .job-banner-sec .job-banner-content h1 span {
    display: block;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
  }
  .e-hiring-model-list {
    margin: 10px 0px;
  }
  .e-hiring-model-list:nth-child(1) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(2) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(3) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(4) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(5) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(6) {
    position: relative;
    left: 0%;
  }
  .inner-icon-content h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .inner-icon-content p {
    font-size: 12px;
    line-height: 15px;
  }
  .discuss-specialists-content .desc {
    font-size: 13px;
    line-height: 20px;
  }
  .d-f-fd-cr {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .custom-crm-development-card-content .desc {
    margin-bottom: 15px;
    font-size: 14px;
  }
  .custom-crm-development-card-content .main-head {
    font-size: 26px;
    color: #fcaf17;
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
  }
  .custom-crm-development-head .desc {
    width: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }
  .discuss-specialists-content .lets-talk-button {
    font-size: 12px;
    padding: 5px 16px;
  }
  .discuss-specialists-content .title {
    font-size: 18px;
  }
  .discuss-specialists-content .desc {
    font-size: 14px;
    color: white;
    text-align: center;
    margin: 10px 0px;
  }
  .job-banner-sec .job-banner-content h1 {
    color: white;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }
  .job-banner-sec .job-banner-content .desc {
    font-size: 14px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 30px;
  }
  .job-portal-services-head h2 {
    font-size: 21px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }
  .job-portal-services-head .desc {
    font-size: 11px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    width: 100%;
    line-height: 16px;
  }
  .on-demand-head-area .sub-head {
    font-size: 12px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 15px;
  }
  .pb-40 {
    padding-bottom: 20px;
  }
  .on-demand-head-area .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }
  .on-demand-my-tab .on-demand-tab-head {
    color: white;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 0px;
  }
  .admin-content-list li {
    color: white;
    line-height: 30px;
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
  }
  .admin-content-list-box {
    padding: 15px 0px 0px 0px;
  }
  .admin-content-list li svg {
    margin-right: 5px;
    font-size: 14px;
  }
  .on-demand-my-tab .on-demand-tab-head {
    font-size: 10px;
    margin-bottom: 5px;
  }
  .job-features-tab-pane .ad-features img {
    width: 14px;
    height: 14px;
  }
  .job-features-tab-pane .ad-features {
    position: absolute;
    left: 50.2%;
    top: 5px;
    z-index: 99999;
  }
  .job-features-tab-pane .co-features img {
    width: 14px;
    height: 14px;
  }
  .job-features-tab-pane .co-features {
    position: absolute;
    left: 11%;
    top: 4px;
    z-index: 99999;
  }
  .job-p-features-card .job-p-features-img img {
    width: 25px;
    height: 25px;
  }
  .job-p-features-card .job-p-features-img {
    width: 40px;
    height: 40px;
  }
  .job-p-features-card .job-p-features-content h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 13px;
  }
  .job-p-features-card .job-p-features-content .desc {
    font-size: 10px;
    line-height: 15px;
  }
  .job-p-features-card {
    padding: 5px;
    min-height: 135px;
    margin-bottom: 20px;
  }
  .our-recent-works-head h2 {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .our-recent-works-head .desc {
    font-size: 13px;
    width: 100%;
    line-height: 20px;
  }
  .why-choose-j-p .why-choose-j-p-head h2 {
    font-size: 19px;
    margin-bottom: 10px;
  }
  .why-choose-j-p .why-choose-j-p-head .desc {
    font-size: 12px;
    width: 100%;
    line-height: 15px;
  }
  .e-hiring-model .e-hiring-model-head h2 {
    font-size: 19px;
    margin-bottom: 10px;
  }
  .e-hiring-model .e-hiring-model-head .desc {
    font-size: 12px;
    width: 100%;
    line-height: 16px;
  }
  .e-hiring-model .e-hiring-model-head {
    text-align: center;
    padding: 0px;
  }
  .healthcare-banner-sec {
    min-height: 450px !important;
  }
  .healthcare-banner-sec .healthcare-banner-content {
    position: absolute;
    top: 10%;
    left: 0%;
    z-index: 2;
  }
  .health-app-development-content h4 {
    font-size: 17px;
  }
  .health-about-content .main-heading {
    font-size: 20px;
    font-weight: 600;
    color: #020202;
    margin-top: 10px;
  }
  .health-about-content .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
  }
  .healthcare-banner-sec .healthcare-banner-content .main-heading {
    font-size: 18px;
  }
  .health-app-develop-content .main-heading {
    font-size: 22px;
    font-family: "Poppins", sans-serif;
  }
  .health-app-develop-content .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
    font-weight: 500;
  }
  .health-app-development-content .desc {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 25px;
  }
  .health-app-development-list li {
    position: relative;
    font-size: 14px;
    padding-left: 20px;
    font-family: "Open Sans", sans-serif;
    color: #51555c;
    font-weight: 600;
    line-height: 25px;
  }
  .health-app-develop-content h4 {
    font-size: 19px;
  }
  .growth-banner-content {
    position: relative;
    top: 0%;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .growth-banner-content .main-heading {
    font-size: 18px;
    color: white;
    margin-bottom: 25px;
    font-family: "Poppins", sans-serif;
    padding: 10px 0px;
  }
  .hexagon-main-img {
    position: relative;
    text-align: center;
    margin-bottom: 25px;
  }
  .hexagon-main-img .growth-banner-img-content {
    position: absolute;
    top: 27%;
    left: 20%;
    text-align: center;
    width: 60%;
  }
  .health-choose-us-card:nth-child(2) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-right: 0px;
    border-bottom: 0;
  }
  .mt-5 {
    margin-top: 0px !important;
  }
  .health-choose-us-main-box .free-consult-button {
    background-color: transparent;
    color: #fcaf17;
    border: 2px solid #fcaf17;
    border-radius: 5px;
    padding: 8px 10px;
    font-weight: 500;
    font-size: 14px;
  }
  .health-choose-us-main-box {
    margin-bottom: 25px;
  }
  .discuss-specialists {
    min-height: 200px !important;
  }
  .erp-banner-sec {
    position: relative;
    min-height: 410px !important;
  }
  .erp-banner-sec .erp-banner-content {
    position: absolute;
    top: 3%;
    left: 0%;
  }
  .erp-banner-sec .erp-banner-content p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 20px;
  }
  .erp-banner-sec .erp-banner-content .erp-banner-content-list .erp-banner-list li {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    font-weight: 600;
    position: relative;
    padding-left: 20px;
    line-height: 25px;
  }
  .erp-banner-sec .erp-banner-content .blue-theme-button {
    background-color: #fcaf17;
    color: white;
    padding: 8px 12px;
    font-family: "Open Sans", sans-serif;
    border: 2px solid #fcaf17;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    display: inline-block;
    border-radius: 5px;
    font-size: 14px;
  }
  .erp-about-us .erp-about-content .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
  }
  .explore-erp-services-content .desc {
    margin-bottom: 20px;
    width: 100%;
    font-size: 14px;
  }
  .pb-4 {
    padding-bottom: 0.5rem !important;
  }
  .d-f-fc-r {
    display: flex;
    flex-direction: column-reverse;
  }
  .erp-development-services-card {
    margin-bottom: 35px;
    min-height: 180px;
  }
  .explore-erp-services-img {
    margin-bottom: 25px;
  }
  .erp-developed-modules-card {
    padding: 10px;
  }
  .erp-developed-modules-card .erp-developed-modules-content h4 {
    font-size: 14px;
  }
  .erp-developed-modules-card .erp-developed-modules-content .desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  .border-right-0 {
    border-right: 1px solid #fcaf17;
  }
  .border-bottom-1 {
    border-bottom: 1px solid #fcaf17;
  }
  .explore-erp-services-content {
    padding-top: 0px;
  }
  .erp-banner-sec .erp-banner-content h1 {
    color: #fff;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 25px;
  }
  .erp-development-services-head .main-heading-type {
    color: #020202;
    font-size: 20px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
    line-height: 25px;
  }
  .erp-development-services-head .sub-heading {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-align: left;
  }
  .erp-about-us .erp-about-img img {
    width: 100%;
  }
  .erp-about-us .erp-about-content .main-heading {
    font-size: 20px;
    font-weight: 600;
    color: #020202;
  }
  .erp-achievements-sec p .let-discuss-button {
    background-color: white;
    color: #020202;
    padding: 8px 20px;
    font-weight: 600;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  .erp-achievements-sec .erp-achievements-head h2 {
    color: white;
    font-size: 18px;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  .software-development-item .software-development-content .desc {
    font-size: 16px;
    line-height: 25px;
  }
  .software-development-content h4 {
    font-size: 18px;
  }
  .software-development-item span {
    position: absolute;
    top: 30px;
  }
  .erp-achievements-sec p {
    text-align: center;
    margin-top: 25px;
    padding-bottom: 25px;
  }
  .erp-achievements-sec .erp-achievements-card span {
    color: white;
    font-size: 50px;
    font-weight: 700;
  }
  .erp-achievements-sec .erp-achievements-card h4 {
    color: white;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    margin-top: 15px;
  }
  .crm-banner-sec {
    min-height: 300px !important;
  }
  .crm-banner-sec .Crm-banner-content {
    position: absolute;
    z-index: 2;
    top: 5%;
    width: 100%;
    left: 0%;
  }
  .crm-banner-sec .Crm-banner-content .main-heading {
    color: white;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .crm-banner-sec .Crm-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 17px;
  }
  .crm-banner-sec .Crm-banner-content .transform-button {
    padding: 6px 12px;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    font-size: 14px;
  }
  .about-img img {
    min-height: 0px;
  }
  .about-img {
    margin-right: 0px;
  }
  .Crm-about-content .main-heading {
    font-size: 17px;
    font-weight: 600;
    color: #020202;
  }
  .why-you-need-crm .Crm-app-development-content .main-heading {
    font-size: 22px;
    position: relative;
  }
  .why-you-need-crm .Crm-app-development-content .desc {
    color: black;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .why-you-need-card {
    padding: 10px;
  }
  .why-you-need-card .why-you-need-content h4 {
    font-size: 16px;
    font-weight: 600;
  }
  .why-you-need-card .why-you-need-content .desc {
    font-size: 12px;
  }
  .crm-software-features .crm-software-features-head .main-heading {
    font-size: 22px;
    position: relative;
  }
  .crm-software-features .crm-software-features-head .desc {
    color: black;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
  .crm-software-features .crm-software-features-card .crm-software-features-icon {
    text-align: center;
    margin-bottom: 15px;
  }
  .crm-software-features .crm-software-features-content .desc {
    color: white;
    font-size: 10px;
    font-family: "Open Sans", sans-serif;
    opacity: 0;
    transform: translateY(0.625rem);
    transition: all 0.125s ease-in-out;
    line-height: 15px;
  }
  .crm-software-features .crm-software-features-card .crm-software-features-icon img {
    width: 60px;
    height: 60px;
  }
  .crm-software-features .crm-software-features-card:hover .crm-software-features-content {
    opacity: 1;
    visibility: visible;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    min-height: 220px;
    max-height: 220px;
    overflow: scroll;
  }
  .crm-software-features .crm-software-features-card .title {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
  }
  .crm-software-features .crm-software-features-card {
    position: relative;
    padding: 10px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 35px;
    min-height: 100px;
    max-height: 100px;
  }
  .crm-software-features .crm-software-features-card:hover {
    position: relative;
    padding: 10px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 35px;
    min-height: 200px;
    max-height: 200px;
  }
  .crm-software-solutions-sec .crm-development-services-head .main-heading {
    font-size: 22px;
  }
  .crm-software-solutions-sec .crm-development-services-head .desc {
    color: black;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
  .container-box .card .card__head {
    color: black;
    background: #fcaf17;
    padding: 0.5em;
    transform: rotate(-90deg);
    transform-origin: 0% 0%;
    transition: all 0.5s ease-in-out;
    /* min-width: 100%; */
    text-align: center;
    position: absolute;
    bottom: -50%;
    /* visibility: hidden; */
    height: 100px;
    /* opacity: 0; */
    left: 0px;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 700;
    width: 210px;
  }
  .why-work-with-us-content h3 {
    font-size: 20px;
  }
  .why-work-with-us-content .desc {
    font-size: 14px;
  }
  .why-work-with-us-img {
    margin-bottom: 20px;
  }
  .d-f-fc-r {
    display: flex;
    flex-direction: column-reverse;
  }
  .custom-crm-development-head .main-head {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: left;
  }
  .custom-crm-development-head {
    text-align: left;
  }
  .crm-development-services-head .main-heading {
    color: #020202;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .btnall .btn-fix {
    background: transparent linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%) 0% 0% no-repeat padding-box;
    color: #fff;
    border-radius: 50px;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.06);
    font-size: 14px;
    font-weight: 500;
    position: relative;
    white-space: nowrap;
    transition: 0.8s;
    height: 45px;
    padding: 5px 60px 5px 25px;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
  }
  .crm-development-services-sec .col-xl-6:nth-child(2) {
    margin-top: 0px;
  }
  .crm-development-services-sec .col-xl-6:nth-child(3) {
    margin-top: 0px;
  }
  .crm-development-services-card {
    padding: 10px;
    min-height: 300px;
  }
  .crm-development-services-card .crm-development-services-icon img {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  }
  .crm-development-services-head .desc {
    font-size: 13px;
    margin-bottom: 20px;
    line-height: 20px;
  }
  .erp-development-services-head {
    text-align: center;
  }
  .Sch-mt-about-content .desc {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }
  .sch-software-portfolio-head {
    text-align: left;
  }
  .school-mt-banner-sec .school-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 47%;
    width: 100%;
    left: 0%;
  }
  .school-mt-banner-sec .school-mt-banner-content .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .school-mt-banner-sec .school-mt-banner-content .sub-heading {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
  }
  .school-mt-banner-sec .school-mt-banner-content .transform-button {
    font-size: 14px;
    padding: 10px;
  }
  .Sch-mt-about-content .main-heading {
    font-size: 19px;
  }
  .sch-software-services-list {
    display: inline;
  }
  .sch-software-services-head .main-heading {
    font-size: 19px;
    margin-bottom: 0px;
  }
  .sch-software-services-card .sch-software-services-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin: 0 auto;
    margin-bottom: -25px;
  }
  .sch-software-services-card .sch-software-services-icon img {
    width: 35px;
    height: 35px;
  }
  .sch-software-services-card .sch-software-services-content {
    padding: 10px;
  }
  .sch-software-services-card .sch-software-services-content h5 {
    font-size: 14px;
    font-weight: 500;
    padding-top: 20px;
  }
  .sch-software-services-card .sch-software-services-content .desc {
    font-size: 12px;
    line-height: 16px;
  }
  .sch-software-modules-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .sch-software-modules-head .desc {
    font-size: 12px;
    line-height: 16px;
  }
  .sch-software-modules-card {
    min-height: 100px;
  }
  .sch-software-modules-card .sch-software-modules-content h5 {
    font-size: 13px;
    font-weight: 500;
  }
  .Sch-software-features .Sch-software-features-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .Sch-software-features .Sch-software-features-head .desc {
    font-size: 12px;
    line-height: 17px;
  }
  .Sch-mt-features-img img {
    border-radius: 5px;
    margin-bottom: 25px;
  }
  .Sch-mt-features-content {
    border: 1px solid #e5e5e5;
    padding: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: auto;
    margin-bottom: 25px;
  }
  .Sch-software-features .main-box {
    margin: 0px;
  }
  .sch-software-development-process .sch-software-development-process-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .sch-process-r {
    display: none;
  }
  .sch-software-solutions-advantages .sch-software-solutions-advantages-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
  .sch-software-advantages-img img {
    border-radius: 5px;
    min-height: auto;
  }
  .sch-software-advantages-content {
    border-radius: 0px;
  }
  .sch-software-advantages-content .sch-software-advantages-list li {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    line-height: 30px;
  }
  .sch-software-portfolio-head .main-heading {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
  }
  .society-mt-banner-sec {
    min-height: 350px !important;
  }
  .society-mt-banner-sec .society-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 20%;
    width: 100%;
    left: 0%;
  }
  .society-mt-banner-sec .society-mt-banner-content .main-heading {
    color: white;
    font-size: 17px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .society-mt-banner-sec .society-mt-banner-content .sub-heading {
    font-size: 13px;
    margin-bottom: 10px;
    line-height: 20px;
    text-align: left;
  }
  .society-mt-banner-sec .society-mt-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 8px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }
  .society-mt-about-us .society-mt-about-content .main-heading {
    font-size: 18px;
    color: #020202;
  }
  .society-mt-about-list-box {
    padding-left: 20px;
  }
  .society-mt-about-us .society-mt-about-content .h-about-button {
    margin-top: 10px;
    text-align: center;
  }
  .society-mt-features-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .society-mt-features-head .desc {
    font-size: 12px;
    line-height: 18px;
  }
  .erp-development-services-card h4 {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }
  .real-est-banner-sec .real-est-banner-content .sub-heading {
    font-size: 13px;
  }
  .real-estate-about-us .real-estate-about-content .main-heading {
    font-size: 18px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }
  .real-est-services-card .real-est-services-content h4 {
    font-size: 17px;
  }
  .real-estate-about-us .real-estate-about-content .real-estate-about-list-box .real-estate-about-list li {
    border-left: 1px dashed #bebebe;
    padding: 0px 10px 15px 20px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
  }
  .on-demand-my-tab-re .on-demand-tab-head {
    color: white;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
  }
  .admin-content-list-re li {
    color: white;
    line-height: 45px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }
  .admin-content-list-box-re {
    padding: 20px 0px 0px 15px;
  }
  .real-est-services-card .real-est-services-content .desc {
    font-size: 14px;
    line-height: 22px;
  }
  .real-est-development-services .real-est-development-services-head {
    text-align: left;
  }
  .real-est-app-development-features .app-development-left-side .app-development-left-side-card {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .real-est-development-services .real-est-development-services-head .main-heading {
    font-size: 22px;
    margin-bottom: 0px;
  }
  .real-est-development-services .real-est-development-services-head .desc {
    font-size: 14px;
    width: 100%;
  }
  .real-est-app-development-features .real-est-app-development-head {
    text-align: left;
  }
  .real-est-app-development-features .real-est-app-development-head .main-heading {
    font-size: 23px;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .heading {
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .desc {
    color: white;
    font-size: 14px;
    margin: 15px 0px 20px 0px;
    line-height: 25px;
  }
  .on-demand-head-area-re .sub-head {
    color: white;
    font-size: 18px;
  }
  .on-demand-head-area-re .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 300 !important;
    line-height: 22px;
    color: white;
  }
  .real-est-banner-sec {
    min-height: 350px !important;
  }
  .real-est-banner-sec .real-est-banner-content {
    position: absolute;
    z-index: 2;
    top: 25%;
    width: 100%;
    left: 0%;
  }
  .real-est-banner-sec .real-est-banner-content .main-heading {
    color: white;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .real-est-banner-sec .real-est-banner-content .transform-button {
    padding: 8px 16px;
    font-size: 14px;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card h5 {
    color: white;
    font-size: 14px;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    text-align: center;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card .desc {
    font-size: 12px;
    margin: 0;
    line-height: 17px;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card .real-est-mngmnt-icon {
    margin-bottom: 10px;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card .real-est-mngmnt-icon img {
    width: 60px;
    height: 60px;
  }
  .real-est-app-development-features .app-development-left-side .app-development-left-side-card .app-development-left-side-list .desc {
    font-size: 14px;
    line-height: 17px;
  }
  .real-est-app-development-features .app-development-left-side .app-development-left-side-card .app-development-left-side-list h3 {
    font-size: 16px;
  }
  .real-est-app-development-features .app-development-right-side .app-development-right-side-card .app-development-right-side-list .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .real-est-app-development-features .app-development-right-side .app-development-right-side-card .app-development-right-side-list h3 {
    font-size: 16px;
  }
  .real-est-app-development-features .app-development-right-side .app-development-right-side-card {
    padding: 10px 10px;
    margin-bottom: 0px;
  }
  .erp-portfolio-area .erp-portfolio-left-side {
    background-color: rgb(215, 164, 64);
    padding: 20px 15px;
  }
  .erp-portfolio-area .erp-portfolio-left-side h4 {
    color: white;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
  .erp-portfolio-area .erp-portfolio-left-side .sub-heading-area {
    margin-bottom: 15px;
  }
  .erp-portfolio-area .erp-portfolio-left-side .connect-erp .desc {
    color: white;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
  }
  .quote-erp .explore-more-button {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    padding: 12px 32px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
  }
  .manufacturing-banner-sec .manufacturing-banner-content {
    position: absolute;
    z-index: 2;
    top: 35%;
    width: 100%;
    left: 0%;
  }
  .healthcare-banner-sec .healthcare-banner-content .sub-heading {
    font-size: 13px;
  }
  .manufacturing-banner-sec .manufacturing-banner-content .main-heading {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .manufacturing-banner-sec .manufacturing-banner-content .sub-heading {
    margin-bottom: 20px;
    line-height: 20px;
    font-size: 14px;
  }
  .manufacturing-about-us .manufacturing-about-content .main-heading {
    font-size: 22px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
    padding-top: 15px;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-head {
    text-align: left;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-content .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-content .sub-heading {
    font-size: 16px;
  }
  .d-f-fd-cr1 {
    display: flex;
    flex-direction: column-reverse;
  }
  .manuft-sft-services .manuft-sft-solutions-head {
    text-align: left;
  }
  .manuft-sft-services .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .manuft-sft-services .manuft-sft-services-list li .manuft-sft-services-inner-list h5 {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
  }
  .manuft-sft-services .manuft-sft-services-list li .manuft-sft-services-inner-list .desc {
    font-size: 15px;
    line-height: 20px;
  }
  .our-works-manuft-software .manuft-sft-works-head {
    text-align: left;
  }
  .our-works-manuft-software .manuft-sft-works-head .main-heading-type {
    color: #020202;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .our-works-manuft-software .manuft-sft-works-content .main-heading {
    font-weight: 600;
    font-size: 18px;
  }
  .our-works-manuft-software .manuft-sft-works-content .desc {
    margin-bottom: 20px;
    line-height: 20px;
    font-size: 14px;
  }
  .manuft-sft-works-content {
    margin-bottom: 20px;
  }
  .our-works-manuft-software .manuft-sft-works-content {
    padding-top: 0px;
  }
  .our-works-manuft-software .manuft-sft-works-content .contact-us-buttn {
    background-color: #fcaf17;
    color: white;
    padding: 10px 32px;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
  }
  .our-works-manuft-software .manuft-sft-works-content .sub-heading {
    background-color: black;
    color: white;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    padding: 5px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .discuss-specialists-content {
    z-index: 10;
    margin: 0 auto;
    padding: 10px 0px;
    text-align: center;
  }
  .retail-pos-banner-sec .retail-pos-banner-content {
    position: absolute;
    z-index: 2;
    top: 56%;
    width: 95%;
    left: 0%;
  }
  .retail-pos-banner-sec .retail-pos-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    line-height: 25px;
  }
  .retail-pos-about-img {
    text-align: center;
  }
  .retail-pos-banner-sec .retail-pos-banner-content .main-heading {
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .retail-pos-banner-sec .retail-pos-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }
  .retail-pos-about-us .retail-pos-about-content .main-heading {
    font-size: 20px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }
  .retail-pos-software-importance .retail-pos-software-imp-head {
    text-align: left;
  }
  .retail-pos-software-importance .retail-pos-software-imp-head .main-heading-type {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .retail-pos-software-importance .retail-pos-software-imp-head .desc {
    width: 100%;
    text-align: left;
    font-size: 16px;
    line-height: 25px;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-content .desc {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-content h3 {
    color: #3d3e42;
    font-size: 18px;
    margin-bottom: 5px;
    padding-top: 0px;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-icon img {
    width: 20%;
  }
  .retail-pos-features-sec .retail-pos-features-head .main-heading-type {
    color: #020202;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .retail-pos-software-importance .retail-pos-imp-card {
    min-height: 330px;
  }
  .retail-pos-features-sec .retail-pos-features-head {
    text-align: left;
  }
  .retail-pos-features-sec .retail-pos-features-content {
    padding-top: 10px;
  }
  .retail-pos-features-sec .retail-pos-features-content .main-head {
    font-size: 21px;
    position: relative;
    padding-bottom: 15px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    line-height: 25px;
  }
  .retail-pos-features-sec .retail-pos-features-content .retail-pos-accord-ion {
    padding-top: 25px;
    margin-bottom: 25px;
  }
  .retail-pos-accord-ion .accordion-button {
    background-color: #fff;
    color: black;
    font-weight: 600;
    font-size: 16px;
    padding: 15px;
  }
  .retail-pos-accord-ion .plus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }
  .retail-pos-accord-ion .accordion-body {
    box-shadow: 0 90px 70px 0 rgba(0, 0, 0, 0.04), 0 40px 35px 0 rgba(0, 0, 0, 0.03), 0 25px 15px 0 rgba(0, 0, 0, 0.03), 0 11px 7px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.03) !important;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: #fcaf17;
    border: transparent;
  }
  .retail-pos-accord-ion .minus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }
  .retail-pos-features-img {
    text-align: center;
  }
  .pb-60 {
    padding-bottom: 40px;
  }
  .retail-pos-features-sec .explore-buttn-holder {
    text-align: center;
    margin-top: 30px;
  }
  .retail-pos-why-choose-us .retail-pos-why-choose-us-head {
    text-align: left;
  }
  .retail-pos-why-choose-us .retail-pos-why-choose-us-head .main-heading-type {
    color: #020202;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .retail-pos-why-choose-us .why-choose-retail-pos-card .why-choose-retail-pos-content .desc {
    font-size: 18px;
    line-height: 25px;
    padding: 15px 0px;
    text-align: center;
  }
  .whatsapp-bot-about-us .whatsapp-bot-about-us-head .main-head {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
  }
  .pt-50 {
    padding-top: 20px;
  }
  .whatsapp-bot-about-big-img {
    margin-bottom: 20px;
  }
  .whatsapp-bot-about-small-img {
    text-align: center;
  }
  .whatsapp-bot-about-small-img img {
    width: 55%;
  }
  .why-you-need-whatsappbot .why-you-need-whatsappbot-head .main-heading {
    color: white;
    font-size: 20px;
  }
  .why-you-need-whatsappbot-head .desc {
    font-size: 16px;
    line-height: 25px;
  }
  .why-you-need-whatsappbot .why-you-need-whatsappbot-card {
    padding: 10px;
    margin-bottom: 20px;
    min-height: 160px;
  }
  .why-you-need-whatsappbot .why-you-need-whatsappbot-card h6 {
    padding: 5px;
    font-size: 14px;
  }
  .why-you-need-img img {
    width: 45%;
  }
  .whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-head .main-heading {
    font-size: 23px;
    font-weight: 600;
    /* line-height: 10px; */
  }
  .whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-card {
    max-width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    min-height: 210px;
  }
  .whatsapp-chatbot-benefits-card .desc {
    line-height: 20px;
  }
  .possible-channels-sec .possible-channels-head .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .possible-channels-sec .possible-channels-head .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .possible-channels-sec .possible-channels-tab-panes .tab-content {
    background-color: #f6f6f6 !important;
    border-radius: 10px;
    padding: 0px;
  }
  .possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content {
    position: relative;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    margin-top: 10% !important;
    margin-bottom: 25px;
  }
  .possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content .desc {
    color: black;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
  }
  .possible-channels-sec .possible-channels-tab-panes .nav-tabs .nav-item button {
    background-color: #e8ebf1;
    border-radius: 25px;
    color: #020202;
    padding: 8px 20px !important;
    font-weight: 600;
    font-size: 13px !important;
    margin: 0px 10px 10px 0px !important;
  }
  .Value-added-features .Value-added-features-head .main-head {
    font-size: 24px;
    margin-bottom: 0px;
  }
  .possible-channels-sec .left-side-whatsapp-content {
    margin-top: 10% !important;
    margin-bottom: 5%;
  }
  .possible-channels-sec .left-side-whatsapp-content .main-heading {
    padding-top: 20px;
  }
  .possible-channels-sec .left-side-whatsapp-content .icon img {
    width: 70%;
  }
  .possible-channels-sec .left-side-whatsapp-content .desc {
    line-height: 20px;
    font-size: 14px;
  }
  .newportfolyo .card_portfoliyo .portfolicardview {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
  }
  .newportfolyo .card_portfoliyo p {
    font-size: 16px;
    margin: 10px 0;
    line-height: 25px;
  }
  .newportfolyo .card_portfoliyo h3 {
    font-size: 24px;
  }
  .newportfolyo .leftsidecontnt .text_right {
    width: 100%;
    text-align: center;
  }
  .newportfolyo .card_portfoliyo .leftcardportfoli {
    padding: 0px;
    margin-top: 5%;
  }
  .btnall.text-end {
    display: flex;
    justify-content: center;
  }
  .newportfolyo .card_portfoliyo .text_left {
    text-align: center;
    margin-left: 0%;
  }
  .newportfolyo li {
    padding: 37px 0;
    min-height: 100%;
  }
  .newportfolyo .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .newportfolyo .card_portfoliyo .portfolicardview img {
    width: 80%;
    z-index: 999;
    position: relative;
    margin-top: 21%;
  }
  .newportfolyo .card_portfoliyo .portfolicardview::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
    text-align: center;
  }
  .newportfolyom .card_portfoliyom p {
    font-size: 16px;
    margin: 10px 0;
    line-height: 25px;
  }
  .newportfolyom .card_portfoliyom h3 {
    font-size: 24px;
  }
  .newportfolyom .leftsidecontntm .text_right {
    width: 100%;
    text-align: center;
  }
  .newportfolyom .card_portfoliyom .leftcardportfolim {
    padding: 0px;
    margin-top: 5%;
    height: unset;
  }
  .btnall.text-end {
    display: flex;
    justify-content: center;
  }
  .newportfolyom .card_portfoliyom .text_left {
    text-align: center;
    margin-left: 0%;
  }
  .newportfolyom li {
    padding: 37px 0;
    min-height: 100%;
  }
  .newportfolyom .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm img {
    width: 50%;
    z-index: 999;
    position: relative;
    margin-top: 12%;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }
  .newportfolyom .leftsidecontntm .portfolicardviewm {
    text-align: center;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }
  .f-d-cr {
    flex-direction: column-reverse;
  }
  .case-study ul.tabs li {
    cursor: pointer;
    border: 1px solid lightgray;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    transition: all 0.5s;
    font-size: 10px;
    padding: 5px 10px;
    margin-left: 10px;
  }
  .text-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
  }
  .title-section .desc {
    line-height: 25px;
    font-size: 16px;
  }
  .case-study-banner-sec .case-study-banner-content {
    position: absolute;
    z-index: 2;
    top: 10%;
    width: 100%;
    left: 0%;
    text-align: center;
  }
  .case-study-banner-sec .fasto-banner-img {
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 0%;
    text-align: center;
  }
  .case-study-banner-sec .fasto-banner-img img {
    width: 90%;
  }
  .case-study-banner-sec .case-study-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .case-study-banner-sec .case-study-banner-content .breadcrumb-casestudy-box {
    margin-bottom: 5px;
  }
  .case-study-banner-sec .case-study-banner-content .desc {
    font-size: 16px;
    line-height: 20px;
  }
  .about-client-sec .about-client-content-box .desc {
    font-size: 17px;
    line-height: 25px;
  }
  .about-client-sec .about-client-main-head .main-heading {
    font-size: 22px;
  }
  .about-client-sec .about-client-content-box {
    padding: 15px;
    position: relative;
  }
  .about-client-sec .about-client-content-box .right-quote {
    position: absolute;
    left: 5%;
    top: 0%;
    width: 10%;
  }
  .about-client-sec .about-client-main-head .main-heading1 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-bottom: 5px;
  }
  .about-client-sec .about-client-content-box .left-quote {
    position: absolute;
    right: 5%;
    bottom: 2%;
    width: 10%;
  }
  .business-model-sec .business-model-main-head .main-heading {
    font-size: 22px;
  }
  .business-model-sec .business-model-main-head .desc {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
  .business-model-sec .business-model-card {
    padding: 15px;
    border-radius: 5px;
    min-height: 200px;
    cursor: pointer;
    margin-bottom: 25px;
  }
  .market-research-sec .market-research-head .main-heading {
    font-size: 22px;
  }
  .market-research-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
  }
  .market-research-sec .market-research-head .market-research-content-box .market-research-content {
    padding: 15px;
    min-height: 250px;
    margin-bottom: 25px;
  }
  .market-research-sec .market-research-img {
    margin-top: 30px;
  }
  .technology-sec .technology-content-area .technology-card .technology-card-content {
    border-top: 1px solid #e5e5e5;
    padding-top: 80px;
  }
  .technology-card-style1 .technology-card-content {
    padding-top: 80px;
    text-align: center;
    margin: 25px 0px;
    border-top: 1px solid #e5e5e5;
  }
  .market-research-sec .market-research-head .market-research-content .market-research-content-list li {
    position: relative;
    padding-left: 35px;
    line-height: 25px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .challenges-and-solutions-sec .challenges-solutions-content .challenges-solutions-card .number-card {
    content: "";
    position: absolute;
    left: -9%;
  }
  .market-research-sec .market-research-head .market-research-content h5 {
    font-size: 16px;
  }
  .market-research-sec .market-research-head .market-research-content {
    padding-top: 20px;
    margin-bottom: 35px;
  }
  .technology-sec .technology-head .main-heading {
    font-size: 24px;
  }
  .technology-sec .technology-head .desc {
    font-size: 14px;
    margin-top: 15px;
  }
  .technology-sec .technology-head .market-research-content h5 {
    font-size: 16px;
  }
  .technology-sec .technology-head .market-research-content {
    padding-top: 20px;
  }
  .technology-sec .technology-head .market-research-content .market-research-content-list li {
    position: relative;
    padding-left: 35px;
    line-height: 20px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .challenges-and-solutions-sec .challenges-solutions-main-head .main-heading {
    font-size: 24px;
    padding-bottom: 15px;
  }
  .challenges-and-solutions-sec .challenges-solutions-main-head {
    margin-top: 0%;
    margin-bottom: 5%;
  }
  .challenges-and-solutions-sec .challenges-and-solutions-img img {
    margin-left: 0%;
  }
  .results-growth-sec .results-growth-head .main-heading {
    font-size: 24px;
  }
  .results-growth-sec .results-growth-content .results-growth-content-box {
    margin-top: 20px;
    background-color: white;
    padding: 15px;
  }
  .results-growth-sec .results-growth-content .results-growth-content-box .results-growth-content-list li {
    line-height: 20px;
    font-size: 14px;
  }
  .future-prospects-sec .future-prospects-head .main-heading {
    font-size: 24px;
  }
  .future-prospects-sec .future-prospects-head {
    background-color: white;
    min-height: 300px;
    padding: 25px;
  }
  .future-prospects-sec .future-prospects-head .future-prospects-content-box {
    padding-top: 15px;
  }
  .future-prospects-sec .future-prospects-head .future-prospects-content-box .future-prospects-content-list li {
    line-height: 25px;
    font-size: 14px;
  }
  .future-prospects-sec .future-prospects-img {
    margin-left: 0%;
    margin-top: 2%;
  }
  .conclusion-sec .conclusion-sec-head .main-heading {
    font-size: 24px;
  }
  .conclusion-sec .conclusion-content-box {
    padding: 15px;
    margin-top: 10px;
  }
  .conclusion-sec .conclusion-content-box .desc {
    line-height: 25px;
    font-size: 16px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content {
    margin-top: 5%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .mt-30 {
    margin-top: 15px;
  }
  .huddle-heart-banner-sec .case-study-banner-content img {
    width: 50%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .transform-button {
    color: white;
    background-color: #41b2e6;
    border: 1px solid #41b2e6;
    padding: 10px 24px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 14px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-img {
    position: absolute;
    z-index: 2;
    top: 45%;
    right: 0%;
    text-align: center;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }
  .huddle-heart-vision-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
  }
  .huddle-heart-vision-sec .market-research-head .main-heading {
    font-size: 20px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content img {
    width: 40%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-img img {
    width: 100%;
    margin-top: 25%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .huddle-heart-intro-list li {
    font-size: 14px;
    line-height: 20px;
  }
  .huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content {
    padding: 10px;
    min-height: 300px;
    margin-bottom: 25px;
    border-bottom: 3px solid #35b6ff;
  }
  .huddle-heart-vision-sec .market-research-img {
    margin-top: 8%;
  }
  .huddle-heart-features-sec .pt-110 {
    padding-top: 0px;
  }
  .huddle-heart-features-sec .sec-title .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }
  .huddle-heart-features-sec .column {
    padding: 0px 10px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card {
    min-height: 400px;
    margin-bottom: 40px;
    padding: 15px 15px 10px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card .card-heading {
    font-weight: 600;
    font-size: 18px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card .desc {
    padding-top: 10px;
    font-size: 14px;
    line-height: 25px;
  }
  .enhanced-communication-sec .enhanced-communication-main-head .main-heading {
    font-size: 20px;
    padding-bottom: 15px;
  }
  .enhanced-communication-sec .enhanced-communication-card::before {
    display: none;
  }
  .enhanced-communication-sec .enhanced-communication-card {
    margin-top: 0%;
    margin-bottom: 35px;
    padding: 20px;
    min-height: 348px;
    position: relative;
  }
  .enhanced-communication-sec .enhanced-img::after {
    display: none;
  }
  .enhanced-communication-sec .enhanced-img::before {
    display: none;
  }
  .enhanced-communication-sec .enhanced-img {
    width: 70%;
    min-height: 450px;
    position: relative;
    margin-bottom: 10%;
  }
  .enhanced-communication-card h5 {
    font-size: 18px;
  }
  .improved-safety-sec .huddle-heart-technology-head .main-heading {
    font-size: 20px;
    padding-top: 10px;
  }
  .huddle-heart-impacts-sec .huddle-heart-impacts-head .main-heading {
    font-size: 20px;
    padding-bottom: 10px;
  }
  .huddle-heart-impacts-sec .huddle-heart-impacts-card {
    padding: 10px;
    min-height: 356px;
    margin-bottom: 30px;
  }
  .huddle-heart-imapcts-img {
    margin-bottom: 5%;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-main-head .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-img {
    margin-bottom: 15%;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-content .challenges-lessons-card .challenges-lessons-icon {
    position: absolute;
    left: 0;
    top: -12%;
    right: 0;
    margin: 0 auto;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-content .challenges-lessons-card {
    margin-bottom: 45px;
    padding: 40px 15px 15px 15px;
  }
  .profile-banner-sec .screen-mob {
    width: 70%;
    min-height: 450px;
  }
  .screen-mob img {
    width: 96%;
  }
  .profile-banner-sec .mt-80 {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .future-prospects-sec .business-model-main-head .main-heading {
    font-size: 21px;
    padding-bottom: 10px;
  }
  .future-prospects-sec .business-model-card .business-model-list-box .business-model-list li .card-head {
    font-size: 18px;
  }
  .future-prospects-sec .business-model-card {
    min-height: 285px;
    margin-bottom: 25px;
    padding: 10px 20px;
  }
  .plywood-bazar-banner-sec .plywood-bazar-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }
  .plywood-bazar-banner-sec .plywood-bazar-banner-content {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .plywood-bazar-banner-sec .plywood-bazar-banner-img {
    text-align: end;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .plywood-bazar-about-us .about-content-box .main-heading {
    font-weight: 500;
    font-size: 28px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #212529;
  }
  .plywood-bazar-about-us .about-content-list-box .about-content-list li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    line-height: 20px;
  }
  .plywood-bazar-about-us .about-content-box .sub-heading {
    font-size: 16px;
  }
  .mission-or-vision-plywood .mission-vision-right-shape {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }
  .mission-or-vision-plywood .left-side-box {
    margin-bottom: 25px;
  }
  .mission-or-vision-plywood .mission-vision-left-shape {
    position: absolute;
    left: 0;
    bottom: 30px;
    display: none;
  }
  .mission-or-vision-plywood .mission-vision-wrapper::after {
    position: absolute;
    bottom: 24%;
  }
  .mission-or-vision-plywood .right-side-box {
    margin-top: 50%;
  }
  .our-client-requirement .about-client-area .main-head {
    font-size: 26px;
  }
  .our-client-requirement .about-client-area .desc {
    font-size: 16px;
    padding-top: 0%;
    line-height: 25px;
  }
  .our-client-requirement .client-req-area .main-head {
    font-size: 26px;
    font-weight: 500;
  }
  .our-client-requirement .client-req-area .client-req-list li {
    border-left: 2px solid #f7c553;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 20px;
  }
  .solution-given-sec .solution-given-content .main-heading {
    color: #b08218;
    font-size: 22px;
    font-weight: 500;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li .heading {
    font-size: 20px;
    font-weight: 500;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li::marker {
    font-size: 20px;
  }
  .solution-given-sec .solution-given-content .sub-heading {
    color: #b08218;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 20px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .plywoodbazar-features-head .sub-heading {
    font-size: 14px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .plywoodbazar-features-head .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list {
    height: 480px;
    overflow-y: auto;
    padding-left: 30px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li {
    list-style-type: decimal;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  }
  .plywoodbazar-result-sec .plywoodbazar-result-main-head .desc {
    color: black;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    line-height: 20px;
  }
  .plywoodbazar-result-sec .plywoodbazar-result-main-head .main-heading {
    font-size: 26px;
    color: #b08218;
    margin-bottom: 10px;
  }
  .our-work-screens .our-work-screens-main-head {
    display: none;
  }
  .RoCare-banner-sec .RoCare-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .RoCare-banner-sec .RoCare-banner-content .transform-button {
    padding: 8px 24px;
    font-size: 14px;
  }
  .RoCare-banner-sec .RoCare-banner-content .sub-head {
    color: #000000;
    font-size: 15px;
  }
  .RoCare-banner-sec .RoCare-banner-content .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .RoCare-banner-sec .RoCare-banner-content .mt-30 {
    margin-top: 20px;
  }
  .Rocare-aboutus .Rocare-aboutus-main-head {
    text-align: center;
    margin-top: 10%;
  }
  .Rocare-aboutus .Rocare-aboutus-main-head .main-heading {
    font-size: 26px;
  }
  .Rocare-aboutus .Rocare-aboutus-main-head .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .Rocare-aboutus .Rocare-aboutus-main-head .main-heading::before {
    content: "Service";
    -webkit-text-stroke: 2px #1f1f1f;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    font-size: 70px;
    bottom: 0px;
    -webkit-text-fill-color: transparent;
    opacity: 0.1;
    z-index: 0;
    width: 100%;
  }
  .client-requirements-sec .client-requirements-main-head {
    background-color: white;
    padding: 70px 10px 20px 10px;
  }
  .client-requirements-sec .client-requirements-main-head .main-heading {
    font-size: 24px;
  }
  .client-requirements-sec .client-requirements-main-head .desc {
    font-size: 14px;
    line-height: 25px;
  }
  .client-requirements-sec .client-requirements-main-head .main-heading::before {
    position: absolute;
    font-size: 70px;
    bottom: 0px;
  }
  .Rocare-features-sec .content-heading .features-heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .Rocare-features-sec .features-content .white-box {
    padding: 15px;
  }
  .Rocare-features-sec .features-content .white-box h5 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .Rocare-features-sec .features-content .white-box h5 img {
    width: 28px;
    margin-right: 5px;
  }
  .Rocare-features-sec .features-content .white-box .features-list li {
    font-size: 15px;
    line-height: 25px;
  }
  .Rocare-customer-slide {
    text-align: center;
  }
  .Rocare-customer-slide img {
    width: 80%;
  }
  .Rocare-features-sec .Rocare-features-main-head .main-heading {
    font-size: 26px;
  }
  .rocare-challenges-sec .rocare-challenges-content .main-heading {
    text-transform: capitalize;
    font-size: 26px;
    position: relative;
    font-weight: 600;
    margin-bottom: 25px;
  }
  .rocare-challenges-sec .rocare-challenges-content .sub-heading {
    font-size: 17px;
  }
  .padding-zero {
    padding: 0px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .mt-30 {
    margin-top: 15px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .sub-head {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .desc {
    font-size: 14px;
    line-height: 25px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content {
    margin-top: 4%;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-img {
    text-align: end;
    margin-top: 10%;
    animation: 4s ease-in-out infinite floatY;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .transform-button {
    padding: 10px 24px;
    font-size: 15px;
  }
  .Nikkypore-project-summary .Nikkypore-main-head .main-heading {
    font-size: 24px;
  }
  .Nikkypore-goals-objectives .Nikkypore-goals-objectives-main-head .main-heading {
    font-size: 24px;
  }
  .Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card {
    min-height: 100%;
  }
  .Nikkypore-goals-objectives .goals-obj-content-box {
    margin-top: 5%;
  }
  .Nikkypore-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
  }
  .mt-3 {
    margin-top: 0px !important;
  }
  .Nikkypore-features-sec .customer-app-features-content {
    margin-top: 0%;
  }
  .challenges-and-solutions-sec .challenges-sec .challenges-content-list li .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .challenges-and-solutions-sec .challenges-solutions-img {
    margin-bottom: 5%;
  }
  .challenges-and-solutions-sec .challenges-sec h4 {
    font-size: 20px;
  }
  .challenges-and-solutions-sec .challenges-and-solutions-main-head .main-heading {
    font-size: 22px;
  }
  .visual-designs-sec .visual-designs-main-head .main-heading {
    font-size: 24px;
  }
  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide {
    margin: 0 auto;
    text-align: center;
  }
  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide img {
    width: 80%;
  }
  .visual-designs-sec .visual-designs-wrapper {
    padding: 0px;
  }
  .visual-designs-sec .visual-designs-wrapper::before {
    display: none;
  }
  .visual-designs-sec .visual-designs-main-head .desc {
    margin-bottom: 0px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content {
    padding-left: 0%;
    margin-top: 10%;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .desc {
    color: #222a41;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-main-banner-logo {
    margin-bottom: 15px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li {
    flex-basis: 50%;
    margin-top: 10px;
    font-size: 16px;
  }
  .deyor-main-banner-sec {
    min-height: 470px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li h5 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 25px;
  }
  .deyor-about-us .deyor-about-us-main-head .main-heading {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .deyor-about-us {
    padding: 20px 0px 20px 0px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box {
    position: relative;
    cursor: pointer;
    margin-bottom: 80%;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .main-heading {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .desc {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .deyor-about-us-content-list li {
    font-size: 16px;
    line-height: 30px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .deyor-about-us-content-list li .list-bar {
    position: absolute;
    left: 0;
    top: 15px;
  }
  .deyor-features-sec .deyor-features-main-head .main-heading {
    font-size: 26px;
    font-weight: 600;
  }
  .deyor-features-sec .first-child-bg-img {
    display: none;
  }
  .deyor-features-sec .deyor-features-content {
    margin-top: 0%;
    padding-left: 10px;
    margin-left: 0px;
    background: #fff;
    z-index: 99;
    width: 100%;
    position: relative;
  }
  .deyor-features-sec .deyor-features-content .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .deyor-features-sec .deyor-features-content .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 7px;
    display: block;
    margin-bottom: 10px;
  }
  .deyor-features-sec .feature-user2 .second-child-bg-img {
    display: none;
  }
  .deyor-features-sec .feature-user3 {
    position: relative;
    padding-top: 25px;
  }
  .deyor-features-sec .deyor-features-content1 {
    margin-top: 10%;
  }
  .deyor-features-sec .deyor-features-content1 .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .deyor-features-sec .feature-user3 .third-child-bg-img {
    display: none;
  }
  .deyor-features-sec .feature-user2 {
    padding-top: 20px;
    position: relative;
  }
  .deyor-objective-sec .deyor-objective-img {
    margin-top: 2%;
  }
  .deyor-objective-sec .deyor-objective-content-box {
    margin-top: 10%;
  }
  .deyor-objective-sec .deyor-objective-main-content-box .main-heading {
    font-size: 24px;
    padding-bottom: 10px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .sub-heading {
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 600;
  }
  .deyor-methodology-sec .deyor-methodology-main-head .main-heading {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
    position: relative;
    padding-bottom: 10px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 {
    margin-top: 10%;
  }
  .deyor-methodology-sec .deyor-methodology-img .mt-10 {
    margin-top: 10%;
    width: 100%;
    text-align: center;
    margin-left: 0%;
    margin-bottom: 10%;
  }
  .deyor-methodology-sec .deyor-methodology-img {
    margin-top: 10%;
  }
  .d-f-fd-cr {
    display: flex;
    flex-direction: column-reverse;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box {
    padding: 25px 15px 20px;
    margin-top: 10%;
    margin-right: 0%;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .main-heading {
    font-size: 26px;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }
  .deyor-solutions-sec .deyor-solutions-main-head .main-heading {
    font-size: 26px;
  }
  .deyor-solutions-sec .deyor-solutions-content-box {
    padding: 25px 15px 20px;
    margin-top: 10%;
    margin-left: 0%;
  }
  .deyor-solutions-sec .deyor-solutions-content-box .main-heading {
    font-size: 26px;
  }
  .deyor-solutions-sec .deyor-solutions-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }
  .deyor-work-screens-sec .deyor-work-screens-main-head .main-heading {
    font-size: 20px;
  }
  .deyor-work-screens-sec .deyor-work-screens-main-head {
    text-align: left;
  }
  .samagri-banner-sec .samagri-banner-shape img {
    position: absolute;
    width: 36%;
    z-index: 1;
    left: 35px;
  }
  .samagri-banner-sec .samagri-banner-content .main-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
  }
  .samagri-banner-sec .samagri-banner-content .desc {
    font-size: 18px;
    color: #413d2c;
    font-weight: 600;
    line-height: 30px;
  }
  .samagri-banner-sec .samagri-banner-content .desc1 {
    font-size: 16px;
    color: #413d2c;
    font-weight: 400;
    line-height: 25px;
  }
  .samagri-banner-sec .samagri-banner-content {
    margin-top: 10%;
    padding-bottom: 10%;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box {
    background-color: white;
    padding: 10px;
    justify-content: space-between;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li span svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li {
    display: flex;
    align-items: start;
    line-height: 35px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li span svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li {
    line-height: 35px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box {
    margin-top: 0%;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box .main-heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box .desc {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-img-box .samagri-logo-img img {
    width: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle img {
    position: absolute;
    left: 40%;
    top: 160px;
    width: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle .circle-content {
    position: absolute;
    top: 47%;
    left: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle .circle-content .main-heading span {
    display: block;
    font-size: 26px;
    color: #c48041;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle .circle-content .main-heading {
    font-size: 24px;
    font-weight: 600;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(1) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(2) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(3) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(4) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(-24px 29px 25px rgba(12, 12, 13, 0.32));
    text-align: center;
    line-height: 75px;
    margin-bottom: 20px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-icon img {
    width: 45%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-area {
    padding-left: 0px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-area .desc {
    font-size: 16px;
    line-height: 25px;
    color: #fff;
  }
  .samagri-work-screens {
    position: relative;
    width: 100%;
    min-height: 350px;
  }
  .samagri-work-screens .samagri-work-screen-content .main-heading {
    font-size: 22px;
    font-weight: 600;
  }
  .samagri-work-screens .samagri-work-screen-content {
    margin-top: 0%;
  }
  .samagri-work-screens .samagri-work-screen-content .desc {
    line-height: 20px;
    font-size: 13px;
    width: 70%;
  }
  .samagri-features-sec .samagri-features-main-head {
    margin-top: 5%;
  }
  .samagri-features-sec .samagri-features-main-head .main-heading {
    color: black;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .samagri-features-sec .samagri-features-card {
    padding: 15px 10px;
    margin-bottom: 20px;
    min-height: 140px;
  }
  .samagri-features-sec .samagri-features-card .card-heading {
    font-size: 14px;
    margin-bottom: 0px !important;
  }
  .samagri-features-sec .samagri-features-img {
    margin-top: 10%;
  }
  .samagri-challenges-sec .samagri-challenges-main-head .main-heading {
    font-size: 22px;
    color: white;
  }
  .samagri-challenges-sec .samagri-challenges-content {
    margin-top: 0%;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card .samagri-challenges-card-heading .main-heading {
    text-align: center;
    font-size: 14px;
    color: white;
    padding: 10px;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card {
    padding: 5px;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card .desc {
    padding: 5px;
    line-height: 20px;
    font-size: 14px;
  }
  .samagri-challenges-sec .samagri-challenges-img {
    margin-bottom: 5%;
  }
  .samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .main-heading {
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .desc {
    color: white;
    font-size: 14px;
    line-height: 20px;
  }
  .samagri-contact-banner {
    padding: 70px 0px;
  }
  .turning-point-banner-sec .turning-point-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 15px;
  }
  .turning-point-banner-sec .turning-point-banner-content {
    margin-top: 5%;
  }
  .turning-point-banner-sec .turning-point-banner-img img {
    margin-top: 5%;
    padding: 5% 0%;
  }
  .turning-point-banner-sec .turning-point-banner-content .transform-button {
    padding: 10px 24px;
    font-weight: 500;
  }
  .turning-point-project-highlights-sec .turning-point-project-highlights-head .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }
  .turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card-style1 {
    position: relative;
    margin-top: 10%;
    border-top: 1px solid #eee;
  }
  .turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card {
    position: relative;
    margin-top: 5%;
  }
  .turning-point-features-sec .customer-app-features-content {
    margin-top: 0%;
  }
  .turning-point-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .turning-point-challenges-and-solutions-sec .challenges-and-solutions-main-head .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .turning-point-challenges-and-solutions-sec .challenges-sec {
    margin-top: 5%;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-main-head .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper::before {
    display: none;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper .turning-point-visual-designs-slide img {
    width: 65%;
  }
  .turning-point-conclusion-sec .turning-point-conclusion-sec-head .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .turning-point-conclusion-sec .turning-point-conclusion-content-box .desc {
    color: white;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper {
    background-color: #f0686c;
    padding: 20px 0px 20px;
    position: relative;
  }
  .topbar-inner p {
    line-height: normal;
    font-size: 12px;
  }
  .digital-mkt-banner-sec {
    min-height: 360px !important;
  }
  .digital-mkt-banner-sec .digital-mkt-banner-content {
    position: absolute;
    z-index: 2;
    top: 5%;
    width: 100%;
    left: 0%;
  }
  .digital-mkt-banner-sec .digital-mkt-banner-content .main-heading {
    color: white;
    font-size: 17px;
    margin-bottom: 10px;
  }
  .digital-mkt-banner-sec .digital-mkt-banner-content .desc {
    color: white;
    font-size: 13px;
    margin-bottom: 10px;
  }
  .digital-mkt-banner-sec .digital-mkt-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 25px;
  }
  .digital-mkt-banner-sec .digital-mkt-banner-content .transform-button {
    color: white !important;
    padding: 8px 10px;
    font-weight: 400;
    font-size: 14px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 992px) {
  .portfolio-left-area h4 {
    color: white;
    font-size: 34px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-top: 50px;
  }
  .portfolio-left-area .sub-heading-area {
    margin-bottom: 30px;
    margin-left: 50px;
  }
  .works-list-1 li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }
  .works-list li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }
  .travel-about-us .about-img {
    padding-top: 0px;
  }
  .py-40 {
    padding: 20px 0px;
  }
  .travel-banner-content {
    position: absolute;
    top: 55%;
    left: 0%;
    width: 60%;
    z-index: 1;
  }
  .travel-banner-content .desc {
    font-family: 18px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-top: 10px;
    font-size: 15px;
    line-height: 25px;
  }
  .travel-choose-card .heading-part h4 {
    color: #020202;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    background: white;
  }
  .travel-banner-content h2 {
    color: white;
    font-size: 26px;
    font-weight: 600;
    font-family: var(--roboto-fonte);
    margin-bottom: 0px;
  }
  .about-content .main-heading {
    font-size: 20px;
    font-weight: 600;
    color: #020202;
    margin-top: 10px;
  }
  .about-content .desc {
    font-size: 14px;
  }
  .features-head h2 {
    font-size: 24px;
    font-weight: 700;
    color: #020202;
    font-family: "Roboto", sans-serif;
    text-align: center;
    margin-bottom: 15px;
  }
  .nav-tabs .nav-item button {
    background-color: #e8ebf1;
    border-radius: 50px;
    color: #020202;
    padding: 8px 20px 8px 20px !important;
    margin: 0px !important;
    margin-right: 20px !important;
    font-size: 13px !important;
    font-weight: 600;
  }
  .works-list li {
    margin-right: 34px;
    margin-bottom: 10px;
  }
  .portfolio-left-area {
    background-color: rgb(215, 164, 64);
    width: 100% !important;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
  }
  .connect {
    position: absolute;
    top: 25%;
    left: 5%;
  }
  .connect .desc {
    color: white;
    font-size: 20px;
    font-family: var(--roboto-fonte);
  }
  .quote {
    position: absolute;
    top: 80%;
    left: 5%;
    color: white;
    font-size: 20px;
    font-family: var(--roboto-fonte);
  }
  .portfolio-area {
    background-color: #f5e9ce;
    width: 100%;
    position: relative;
    min-height: 550px !important;
  }
  .sch-software-process-tabs {
    display: none;
  }
  .sch-software-development-process .faq-accordion {
    display: block;
  }
  .sch-software-development-process .faq-accordion .accordion .accordion-button {
    background-image: none !important;
    font-size: 15px;
  }
  .faq-accordion .accordion .accordion-button {
    background-image: none !important;
    font-size: 12px;
  }
  .faq-accordion .accordion .accordion-header svg {
    content: "";
    position: absolute;
    right: 4%;
    top: 35%;
  }
  .faq-accordion .accordion .accordion-body {
    background-color: rgb(252, 175, 23);
    color: white;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
  .features-card {
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s linear;
    min-height: 115px;
    margin-bottom: 25px;
    width: 85%;
  }
  .features-img img {
    width: 30px;
    height: 30px;
    margin-bottom: 0px;
    text-align: center;
  }
  .features-card h5 {
    padding: 5px;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 500;
  }
  .features-head .desc {
    font-size: 12px;
  }
  .job-banner-sec .job-banner-content {
    position: absolute;
    top: 33%;
    left: 0%;
  }
  .e-hiring-model-list {
    margin: 10px 0px;
  }
  .e-hiring-model-list:nth-child(1) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(2) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(3) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(4) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(5) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(6) {
    position: relative;
    left: 0%;
  }
  .inner-icon-content h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .inner-icon-content p {
    font-size: 12px;
    line-height: 15px;
  }
  .discuss-specialists-content .desc {
    font-size: 13px;
  }
  .discuss-specialists-content .lets-talk-button {
    font-size: 14px;
    padding: 8px 32px;
  }
  .discuss-specialists-content .title {
    font-size: 22px;
  }
  .job-banner-sec .job-banner-content h1 {
    color: white;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }
  .job-banner-sec .job-banner-content .desc {
    font-size: 16px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 40px;
    width: 70%;
  }
  .job-portal-services-head h2 {
    font-size: 21px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }
  .job-portal-services-head .desc {
    font-size: 12px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    line-height: 16px;
  }
  .on-demand-head-area .sub-head {
    font-size: 12px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 15px;
  }
  .pb-40 {
    padding-bottom: 20px;
  }
  .on-demand-head-area .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }
  .on-demand-my-tab .on-demand-tab-head {
    color: white;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 0px;
  }
  .admin-content-list li {
    color: white;
    line-height: 30px;
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
  }
  .admin-content-list-box {
    padding: 15px 0px 0px 0px;
  }
  .admin-content-list li svg {
    margin-right: 5px;
    font-size: 14px;
  }
  .on-demand-my-tab .on-demand-tab-head {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .job-features-tab-pane .ad-features img {
    width: 14px;
    height: 14px;
  }
  .job-features-tab-pane .ad-features {
    position: absolute;
    left: 51.2%;
    top: 5px;
    z-index: 99999;
  }
  .job-features-tab-pane .co-features img {
    width: 14px;
    height: 14px;
  }
  .job-features-tab-pane .co-features {
    position: absolute;
    left: 30%;
    top: 7px;
    z-index: 99999;
  }
  .job-p-features-card .job-p-features-content h4 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .job-p-features-card .job-p-features-content .desc {
    line-height: 20px;
  }
  .job-p-features-card {
    padding: 5px;
    min-height: 200px;
    margin-bottom: 20px;
  }
  .our-recent-works-head h2 {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .our-recent-works-head .desc {
    font-size: 13px;
    width: 100%;
    line-height: 20px;
  }
  .why-choose-j-p .why-choose-j-p-head h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .why-choose-j-p .why-choose-j-p-head .desc {
    font-size: 12px;
    line-height: 15px;
  }
  .e-hiring-model .e-hiring-model-head h2 {
    font-size: 19px;
    margin-bottom: 10px;
  }
  .e-hiring-model .e-hiring-model-head .desc {
    font-size: 12px;
    width: 100%;
    line-height: 16px;
  }
  .e-hiring-model .e-hiring-model-head {
    text-align: center;
    padding: 0px;
  }
  .job-services-card {
    min-height: 310px;
    margin-bottom: 25px;
  }
  .our-recent-works-card .our-recent-works-content p {
    color: white;
    margin-bottom: 20px;
    font-size: 13px;
  }
  .our-recent-works-card .our-recent-works-content h4 {
    color: white;
    margin-bottom: 0px;
    font-size: 18px;
  }
  .recent-work-bottom .desc {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .why-choose-jp-card .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
  }
  .health-app-development-list li {
    position: relative;
    font-size: 14px;
    padding-left: 20px;
    font-family: "Open Sans", sans-serif;
    color: #51555c;
    font-weight: 600;
  }
  .health-choose-us-card .health-choose-us-list .title {
    color: #020202;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }
  .healthcare-banner-sec .healthcare-banner-content {
    position: absolute;
    top: 50%;
    left: 2%;
    width: 52%;
    z-index: 2;
  }
  .healthcare-banner-sec .healthcare-banner-content .main-heading {
    font-size: 32px;
  }
  .health-app-develop-content .main-heading {
    font-size: 31px;
    font-family: "Poppins", sans-serif;
  }
  .health-app-develop-content .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
    font-weight: 500;
  }
  .growth-banner-content {
    position: relative;
    top: 0%;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .growth-banner-content .main-heading {
    font-size: 26px;
    color: white;
    margin-bottom: 30px;
    font-family: "Poppins", sans-serif;
    padding: 10px 0px;
  }
  .hexagon-main-img {
    position: relative;
    text-align: center;
    margin-bottom: 0px;
  }
  .hexagon-main-img .growth-banner-img-content {
    position: absolute;
    top: 27%;
    left: 20%;
    text-align: center;
    width: 60%;
  }
  .health-choose-us-card:nth-child(2) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-right: 0px;
    border-bottom: 0;
  }
  .health-choose-us-main-box .free-consult-button {
    background-color: transparent;
    color: #fcaf17;
    border: 2px solid #fcaf17;
    border-radius: 5px;
    padding: 12px 20px;
    font-weight: 600;
  }
  .health-choose-us-main-box {
    margin-bottom: 25px;
  }
  .discuss-specialists {
    min-height: 200px !important;
  }
  .health-choose-us-card:nth-child(4) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 5px;
    border-right: 1px solid #e5e5e5;
    border-top: 0;
  }
  .health-choose-us-card:nth-child(1) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 5px;
    border-right: 0;
    border-bottom: 0;
  }
  .health-choose-us-card:nth-child(2) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-right: 1px solid #e5e5e5;
  }
  .health-choose-us-card:nth-child(5) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-top: 0;
    border-right: 0;
  }
  .health-choose-us-card:nth-child(3) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-top-right-radius: 5px;
    border-right: 0;
  }
  .hexagon-main-img .growth-banner-img-content .desc {
    font-size: 11px;
    color: white;
    line-height: 20px;
    margin-top: 15px;
  }
  .erp-banner-sec .erp-banner-content {
    position: absolute;
    top: 16%;
    left: 5% !important;
    width: 80%;
  }
  .why-choose-erp-content .desc {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
  }
  .why-choose-erp-content h4 {
    font-size: 15px;
  }
  .why-choose-erp-card {
    position: relative;
    border-right: 1px solid #fcaf17;
    padding: 15px;
  }
  .erp-banner-sec .erp-banner-content h1 {
    color: white;
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
    font-weight: 800;
    line-height: 35px;
  }
  .erp-development-services-head .main-heading-type {
    color: #020202;
    font-size: 21px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
    line-height: 25px;
  }
  .erp-development-services-head .sub-heading {
    font-size: 12px;
    line-height: 20px;
    width: 90%;
  }
  .erp-achievements-sec .erp-achievements-head h2 {
    color: white;
    font-size: 32px;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  .software-development-item .software-development-content .desc {
    font-size: 16px;
    line-height: 25px;
  }
  .software-development-content h4 {
    font-size: 18px;
  }
  .software-development-item span {
    position: absolute;
    top: 30px;
  }
  .erp-achievements-sec p {
    text-align: center;
    margin-top: 25px;
    padding-bottom: 25px;
  }
  .erp-achievements-sec .erp-achievements-card span {
    color: white;
    font-size: 50px;
    font-weight: 700;
  }
  .erp-achievements-sec .erp-achievements-card h4 {
    color: white;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    margin-top: 15px;
  }
  .erp-banner-sec .erp-banner-content h1 {
    color: white;
    font-size: 30px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
    font-weight: 700;
  }
  .erp-banner-sec .erp-banner-content p {
    color: #020202;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    margin-bottom: 30px;
  }
  .erp-banner-sec {
    background-position: left !important;
  }
  .erp-banner-sec .erp-banner-content h1 {
    color: #000;
    font-size: 28px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .erp-achievements-sec {
    min-height: 0px !important;
  }
  .explore-erp-services-content {
    padding-top: 0px;
  }
  .d-f-fc-r {
    display: flex;
    flex-direction: row;
  }
  .explore-erp-services-img {
    margin-bottom: 30px;
  }
  .erp-developed-modules-card {
    padding: 10px;
  }
  .erp-developed-modules-card .erp-developed-modules-content h4 {
    font-size: 14px;
  }
  .erp-developed-modules-card .erp-developed-modules-content .desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  .explore-erp-development-list li {
    margin-bottom: 5px;
    font-size: 11px;
  }
  .explore-erp-services-content .main-heading {
    font-size: 16px;
  }
  .explore-erp-services-content .desc {
    margin-bottom: 15px;
    width: 100%;
    font-size: 12px;
  }
  .explore-erp-development-list li img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
  .custom-crm-development-head .desc {
    width: 90%;
    text-align: center;
    margin: 0 auto;
    font-size: 13px;
    line-height: 20px;
  }
  .custom-crm-development-head .desc {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    line-height: 25px;
  }
  .crm-software-solutions-sec .crm-development-services-head .main-heading {
    font-size: 23px;
  }
  .crm-software-solutions-sec .crm-development-services-head .desc {
    color: black;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
  }
  .crm-software-features .crm-software-features-head .main-heading {
    font-size: 26px;
    position: relative;
    padding-bottom: 15px;
  }
  .crm-software-features .crm-software-features-head .desc {
    color: black;
    font-size: 13px;
    width: 100%;
    line-height: 20px;
  }
  .crm-software-features .crm-software-features-card .title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
  .why-you-need-crm .Crm-app-development-content .main-heading {
    font-size: 23px;
    position: relative;
  }
  .why-you-need-crm .Crm-app-development-content .desc {
    color: black;
    font-size: 13px;
    width: 100%;
  }
  .crm-banner-sec .Crm-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 65%;
    left: 5%;
  }
  .crm-banner-sec .Crm-banner-content .main-heading {
    color: white;
    font-size: 23px;
    margin-bottom: 15px;
    font-weight: 500;
  }
  .school-mt-banner-sec .school-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 62%;
    left: 2%;
  }
  .school-mt-banner-sec .school-mt-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .school-mt-banner-sec .school-mt-banner-content .sub-heading {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
  }
  .school-mt-banner-sec .school-mt-banner-content .transform-button {
    font-size: 14px;
    padding: 10px;
  }
  .Sch-mt-about-content .main-heading {
    font-size: 19px;
  }
  .sch-software-services-head .main-heading {
    font-size: 19px;
    margin-bottom: 0px;
  }
  .sch-software-services-card .sch-software-services-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .sch-software-services-card .sch-software-services-icon img {
    width: 35px;
    height: 35px;
  }
  .sch-software-services-card .sch-software-services-content {
    padding: 10px;
  }
  .sch-software-services-card .sch-software-services-content h5 {
    font-size: 14px;
    font-weight: 500;
  }
  .sch-software-services-card .sch-software-services-content .desc {
    font-size: 12px;
    line-height: 16px;
  }
  .sch-software-modules-head .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .sch-software-modules-head .desc {
    font-size: 12px;
    line-height: 16px;
  }
  .sch-software-modules-card {
    min-height: 120px;
  }
  .sch-software-modules-card .sch-software-modules-content h5 {
    font-size: 14px;
    font-weight: 500;
  }
  .Sch-software-features .Sch-software-features-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .Sch-software-features .Sch-software-features-head .desc {
    font-size: 12px;
    line-height: 17px;
  }
  .Sch-mt-features-img img {
    border-radius: 5px;
    margin-bottom: 25px;
  }
  .Sch-mt-features-content {
    border: 1px solid #e5e5e5;
    padding: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: auto;
    margin-bottom: 25px;
  }
  .Sch-software-features .main-box {
    margin: 0px;
  }
  .sch-software-development-process .sch-software-development-process-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .sch-process-r {
    display: none;
  }
  .sch-software-solutions-advantages .sch-software-solutions-advantages-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
  .sch-software-advantages-img img {
    border-radius: 5px;
    min-height: auto;
    margin-bottom: 25px;
  }
  .sch-software-advantages-content {
    border-radius: 0px;
  }
  .sch-software-advantages-content .sch-software-advantages-list li {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    line-height: 30px;
  }
  .d-f-fd-cr {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .sch-software-portfolio-head .main-heading {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
  }
  .sch-software-portfolio-head {
    text-align: left;
  }
  .school-mt-banner-sec .school-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 50%;
    width: 100%;
    left: 0%;
  }
  .school-mt-banner-sec .school-mt-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .school-mt-banner-sec .school-mt-banner-content .sub-heading {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
  }
  .school-mt-banner-sec .school-mt-banner-content .transform-button {
    font-size: 14px;
    padding: 10px;
  }
  .Sch-mt-about-content .main-heading {
    font-size: 19px;
  }
  .sch-software-services-head .main-heading {
    font-size: 19px;
    margin-bottom: 0px;
  }
  .sch-software-services-card .sch-software-services-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .sch-software-services-card .sch-software-services-icon img {
    width: 35px;
    height: 35px;
  }
  .sch-software-services-card .sch-software-services-content {
    padding: 10px;
  }
  .sch-software-services-card .sch-software-services-content h5 {
    font-size: 14px;
    font-weight: 500;
  }
  .sch-software-services-card .sch-software-services-content .desc {
    font-size: 12px;
    line-height: 16px;
  }
  .sch-software-modules-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .sch-software-modules-head .desc {
    font-size: 12px;
    line-height: 16px;
  }
  .sch-software-modules-card {
    min-height: 100px;
  }
  .sch-software-modules-card .sch-software-modules-content h5 {
    font-size: 13px;
    font-weight: 500;
  }
  .Sch-software-features .Sch-software-features-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .Sch-software-features .Sch-software-features-head .desc {
    font-size: 12px;
    line-height: 17px;
  }
  .Sch-mt-features-img img {
    border-radius: 5px;
    margin-bottom: 25px;
  }
  .Sch-mt-features-content {
    border: 1px solid #e5e5e5;
    padding: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: auto;
    margin-bottom: 25px;
  }
  .Sch-software-features .main-box {
    margin: 0px;
  }
  .sch-software-development-process .sch-software-development-process-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .sch-process-r {
    display: none;
  }
  .sch-software-solutions-advantages .sch-software-solutions-advantages-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
  .sch-software-advantages-img img {
    border-radius: 5px;
    min-height: auto;
  }
  .sch-software-advantages-content {
    border-radius: 0px;
  }
  .sch-software-advantages-content .sch-software-advantages-list li {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    line-height: 30px;
  }
  .sch-software-portfolio-head .main-heading {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
  }
  .society-mt-banner-sec .society-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 45%;
    width: 55%;
    left: 0%;
  }
  .society-mt-banner-sec .society-mt-banner-content .main-heading {
    color: white;
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .society-mt-banner-sec .society-mt-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  .society-mt-banner-sec .society-mt-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 8px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }
  .society-mt-about-us .society-mt-about-content .main-heading {
    font-size: 18px;
    color: #020202;
  }
  .society-mt-about-list-box {
    padding-left: 20px;
  }
  .society-mt-about-us .society-mt-about-content .h-about-button {
    margin-top: 10px;
  }
  .society-mt-features-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .society-mt-features-head .desc {
    font-size: 12px;
    line-height: 18px;
  }
  .erp-development-services-card h4 {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }
  .real-est-banner-sec .real-est-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .real-est-banner-sec .real-est-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 50%;
    left: 4%;
  }
  .real-estate-about-us .real-estate-about-content .main-heading {
    font-size: 23px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }
  .real-estate-about-us .real-estate-about-content .real-estate-about-heading {
    font-size: 20px;
  }
  .real-estate-about-us .real-estate-about-content .real-estate-about-list-box .real-estate-about-list li {
    border-left: 1px dashed #bebebe;
    padding: 0px 10px 15px 20px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
  }
  .real-est-development-services .real-est-development-services-head {
    text-align: left;
  }
  .real-est-development-services .real-est-development-services-head .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .real-est-development-services .real-est-development-services-head .desc {
    font-size: 14px;
    margin: 0 auto;
    width: 100%;
  }
  .real-est-services-card {
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 25px;
    min-height: 282px;
  }
  .real-est-services-card .real-est-services-content h4 {
    font-size: 17px;
  }
  .real-est-services-card .real-est-services-content .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .real-est-services-card .real-est-services-icon {
    padding: 0px;
    padding-bottom: 5px;
  }
  .on-demand-head-area-re .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 25px;
  }
  .on-demand-head-area-re .sub-head {
    font-size: 20px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 10px;
  }
  .real-est-app-development-features .app-development-right-side .app-development-right-side-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    min-height: 95px;
  }
  .real-est-app-development-features .app-development-left-side .app-development-left-side-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .real-est-app-development-features .real-est-app-development-head {
    text-align: left;
  }
  .real-est-app-development-features .real-est-app-development-head .main-heading {
    font-size: 26px;
  }
  .real-est-app-development-features .app-development-left-side .app-development-left-side-card .app-development-left-side-list li {
    margin-right: 85px;
  }
  .app-development-features-img {
    text-align: center;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .heading {
    color: white;
    font-size: 26px;
    font-weight: 600;
    line-height: 35px;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .desc {
    color: white;
    font-size: 16px;
    margin: 10px 0px 20px 0px;
  }
  .real-est-app-development-features .app-development-right-side {
    margin-bottom: 0px;
  }
  .erp-portfolio-area .erp-portfolio-left-side {
    background-color: rgb(215, 164, 64);
    padding: 20px 15px;
  }
  .erp-portfolio-area .erp-portfolio-left-side h4 {
    color: white;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
  .erp-portfolio-area .erp-portfolio-left-side .sub-heading-area {
    margin-bottom: 15px;
  }
  .erp-portfolio-area .erp-portfolio-left-side .connect-erp .desc {
    color: white;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
  }
  .quote-erp .explore-more-button {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    padding: 12px 32px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
  }
  .manufacturing-banner-sec .manufacturing-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 58%;
    left: 0%;
  }
  .manufacturing-banner-sec .manufacturing-banner-content .main-heading {
    font-size: 21px;
    margin-bottom: 5px;
  }
  .manufacturing-banner-sec .manufacturing-banner-content .sub-heading {
    margin-bottom: 20px;
    line-height: 25px;
  }
  .manufacturing-about-us .manufacturing-about-content .main-heading {
    font-size: 22px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
    padding-top: 15px;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-content .mft-solutions-list-box .manuft-sol-cnt-list .content h6 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 14px;
  }
  .manuft-sft-services-inner-list h5 {
    font-size: 18px;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-content .sub-heading {
    font-size: 16px;
  }
  .mft-sol-inner-list li image {
    width: 80px;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-content .manuft-solutions-left-cnt .manuft-sol-cnt-list li .mft-sol-inner-list h6 {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .manuft-sft-solutions-content .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .manuft-sft-services-inner-list .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .manuft-sft-services .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 25px;
  }
  .manuft-sft-services .manuft-sft-services-list li .manuft-sft-services-inner-list h5 {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
  }
  .manuft-sft-services .manuft-sft-services-list li .manuft-sft-services-inner-list .desc {
    font-size: 15px;
    line-height: 20px;
  }
  .our-works-manuft-software .manuft-sft-works-head {
    text-align: left;
  }
  .our-works-manuft-software .manuft-sft-works-head .main-heading-type {
    color: #020202;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .our-works-manuft-software .manuft-sft-works-content .main-heading {
    font-weight: 600;
    font-size: 20px;
  }
  .our-works-manuft-software .manuft-sft-works-content .desc {
    margin-bottom: 20px;
    line-height: 25px;
  }
  .manuft-sft-works-content {
    margin-bottom: 20px;
  }
  .our-works-manuft-software .manuft-sft-works-content .sub-heading {
    background-color: black;
    color: white;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    padding: 5px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .discuss-specialists-content {
    z-index: 10;
    margin: 0 auto;
    padding: 10px 0px;
    text-align: center;
  }
  .retail-pos-banner-sec .retail-pos-banner-content {
    position: absolute;
    z-index: 2;
    top: 60%;
    width: 55%;
    left: 5%;
  }
  .retail-pos-banner-sec .retail-pos-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    line-height: 25px;
  }
  .retail-pos-about-img {
    text-align: center;
  }
  .retail-pos-banner-sec .retail-pos-banner-content .main-heading {
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .retail-pos-banner-sec .retail-pos-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }
  .retail-pos-about-us .retail-pos-about-content .main-heading {
    font-size: 24px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }
  .retail-pos-software-importance .retail-pos-software-imp-head {
    text-align: left;
  }
  .retail-pos-software-importance .retail-pos-software-imp-head .main-heading-type {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .retail-pos-software-importance .retail-pos-software-imp-head .desc {
    width: 100%;
    text-align: left;
    font-size: 16px;
    line-height: 25px;
  }
  .retail-pos-software-importance .retail-pos-imp-card {
    border-radius: 20px;
    min-height: 200px;
    position: relative;
    border: 1px solid transparent;
    margin-top: 0px;
    text-align: center;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-content .desc {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-content h3 {
    color: #3d3e42;
    font-size: 16px;
    margin-bottom: 5px;
    padding-top: 0px;
    margin-top: 35px;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-content {
    padding-top: 20px;
    text-align: left;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-icon {
    content: "";
    position: absolute;
    top: 1%;
    left: 0%;
    right: 0%;
    margin: 0 auto;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-icon img {
    width: 25%;
  }
  .retail-pos-features-sec .retail-pos-features-head .main-heading-type {
    color: #020202;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .retail-pos-features-sec .retail-pos-features-head {
    text-align: center;
  }
  .retail-pos-features-sec .retail-pos-features-content {
    padding-top: 10px;
  }
  .retail-pos-features-sec .retail-pos-features-content .main-head {
    font-size: 21px;
    position: relative;
    padding-bottom: 15px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    line-height: 25px;
  }
  .retail-pos-features-sec .retail-pos-features-content .retail-pos-accord-ion {
    padding-top: 25px;
    margin-bottom: 25px;
  }
  .retail-pos-accord-ion .accordion-button {
    background-color: #fff;
    color: black;
    font-weight: 600;
    font-size: 16px;
    padding: 15px;
  }
  .retail-pos-accord-ion .plus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }
  .retail-pos-accord-ion .accordion-body {
    box-shadow: 0 90px 70px 0 rgba(0, 0, 0, 0.04), 0 40px 35px 0 rgba(0, 0, 0, 0.03), 0 25px 15px 0 rgba(0, 0, 0, 0.03), 0 11px 7px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.03) !important;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: #fcaf17;
    border: transparent;
  }
  .retail-pos-accord-ion .minus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }
  .retail-pos-features-img {
    text-align: center;
    padding-top: 30px;
  }
  .pb-60 {
    padding-bottom: 40px;
  }
  .retail-pos-features-sec .explore-buttn-holder .explore-buttn {
    padding: 10px 20px;
  }
  .retail-pos-features-sec .explore-buttn-holder {
    text-align: center;
    margin-top: 30px;
  }
  .retail-pos-why-choose-us .retail-pos-why-choose-us-head {
    text-align: center;
  }
  .retail-pos-why-choose-us .retail-pos-why-choose-us-head .main-heading-type {
    color: #020202;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .retail-pos-why-choose-us .why-choose-retail-pos-card {
    padding: 15px;
    border-radius: 16px;
    margin-bottom: 25px;
    min-height: 237px;
  }
  .retail-pos-why-choose-us .why-choose-retail-pos-card .why-choose-retail-pos-content h3 {
    text-align: center;
    font-size: 20px;
    width: 60%;
    margin: 0 auto;
  }
  .retail-pos-why-choose-us .why-choose-retail-pos-card .why-choose-retail-pos-icon img {
    width: 20%;
  }
  .retail-pos-why-choose-us .why-choose-retail-pos-card .why-choose-retail-pos-content .desc {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
  .whatsapp-bot-about-us .whatsapp-bot-about-us-head .main-head {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
  }
  .whatsapp-bot-about-big-img {
    margin-bottom: 20px;
  }
  .whatsapp-bot-about-small-img {
    text-align: center;
  }
  .whatsapp-bot-about-small-img img {
    width: 55%;
  }
  .why-you-need-whatsappbot .why-you-need-whatsappbot-head .main-heading {
    color: white;
    font-size: 24px;
  }
  .why-you-need-whatsappbot-head .desc {
    font-size: 16px;
    line-height: 25px;
  }
  .why-you-need-whatsappbot .why-you-need-whatsappbot-card {
    padding: 10px;
    margin-bottom: 20px;
    min-height: 160px;
  }
  .why-you-need-whatsappbot .why-you-need-whatsappbot-card h6 {
    padding: 5px;
    font-size: 16px;
  }
  .why-you-need-img img {
    width: 25%;
  }
  .whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-head .main-heading {
    font-size: 23px;
    font-weight: 600;
    /* line-height: 10px; */
  }
  .whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-card {
    max-width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    min-height: 210px;
  }
  .whatsapp-chatbot-benefits-card .desc {
    line-height: 20px;
  }
  .possible-channels-sec .possible-channels-head .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .possible-channels-sec .possible-channels-head .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .possible-channels-sec .possible-channels-tab-panes .tab-content {
    background-color: #f6f6f6 !important;
    border-radius: 10px;
    padding: 20px;
  }
  .possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content {
    position: relative;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    width: 92%;
    margin: 0 auto;
    margin-top: 50% !important;
    margin-bottom: 25px;
  }
  .btnall .btn-fix {
    display: block;
    margin: 0 auto;
    height: 50px;
  }
  .fntch_fnl_inn .develmntser .btnall {
    margin-top: 0pc;
  }
  .fntch_fnl_inn .develmntser {
    margin-top: 20px;
    text-align: center;
  }
  .possible-channels-sec .possible-channels-tab-panes .tab-content .left-side-whatsapp-content .desc {
    color: black;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
  }
  .possible-channels-sec .possible-channels-tab-panes .nav-tabs .nav-item button {
    background-color: #e8ebf1;
    border-radius: 25px;
    color: #020202;
    padding: 5px 15px !important;
    font-weight: 600;
    font-size: 13px !important;
    margin: 0px 10px 10px 0px !important;
  }
  .newportfolyo .card_portfoliyo .portfolicardviewm img {
    width: 50%;
    z-index: 999;
    position: relative;
    margin-top: 8%;
  }
  .Value-added-features .value-added-features-card .value-added-features-card-content .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .Value-added-features .value-added-features-card .value-added-features-card-icon img {
    border-radius: 10px 10px 0 0;
    min-height: 250px;
    transform: scale(1);
    transition: 0.5s;
  }
  .Value-added-features .value-added-features-card .value-added-features-card-content .main-heading {
    font-size: 20px;
  }
  .Value-added-features .value-added-features-card .value-added-features-card-content .sub-heading {
    font-size: 16px;
    color: #fcaf17;
  }
  .Value-added-features .value-added-features-card {
    min-height: 450px;
  }
  .Value-added-features .Value-added-features-head .main-head {
    font-size: 26px;
    margin-bottom: 0px;
  }
  .pt-50 {
    padding-top: 20px;
  }
  .possible-channels-sec .left-side-whatsapp-content .main-heading {
    padding-top: 35px;
    font-size: 18px;
    margin-bottom: 5px;
  }
  .possible-channels-sec .left-side-whatsapp-content {
    position: relative;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
    margin-top: 30% !important;
  }
  .possible-channels-sec .left-side-whatsapp-content .icon img {
    width: 70%;
  }
  .possible-channels-sec .left-side-whatsapp-content .desc {
    line-height: 20px;
    font-size: 14px;
  }
  .possible-channels-big-img img {
    width: 90%;
  }
  .newportfolyo .card_portfoliyo .portfolicardview {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
  }
  .newportfolyo .card_portfoliyo p {
    font-size: 18px;
    margin: 10px 0;
    line-height: 30px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .newportfolyo .card_portfoliyo h3 {
    font-size: 24px;
  }
  .newportfolyo .leftsidecontnt .text_right {
    width: 100%;
    text-align: center;
  }
  .newportfolyo .card_portfoliyo .leftcardportfoli {
    padding: 0px;
    margin-top: 5%;
  }
  .btnall.text-end {
    display: flex;
    justify-content: center;
  }
  .newportfolyo .card_portfoliyo .text_left {
    text-align: center;
    margin-left: 0%;
  }
  .newportfolyo li {
    padding: 30px 0;
    min-height: 100%;
  }
  .newportfolyo .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .newportfolyo .card_portfoliyo .portfolicardview img {
    width: 80%;
    z-index: 999;
    position: relative;
    margin-top: 8%;
  }
  .newportfolyo .card_portfoliyo .portfolicardview::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
    text-align: center;
  }
  .newportfolyom .card_portfoliyom p {
    font-size: 20px;
    line-height: 30px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .newportfolyom .card_portfoliyom h3 {
    font-size: 28px;
  }
  .newportfolyom .leftsidecontntm .text_right {
    width: 100%;
    text-align: center;
  }
  .newportfolyom .card_portfoliyom .leftcardportfolim {
    padding: 0px;
    margin-top: 0%;
    height: unset;
  }
  .btnall.text-end {
    display: flex;
    justify-content: center;
  }
  .newportfolyom .card_portfoliyom .text_left {
    text-align: center;
    margin-left: 0%;
  }
  .newportfolyom li {
    padding: 37px 0;
    min-height: 100%;
  }
  .newportfolyom .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm img {
    width: 45%;
    z-index: 999;
    position: relative;
    margin-top: 2%;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }
  .newportfolyom .leftsidecontntm .portfolicardviewm {
    text-align: center;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }
  .f-d-cr {
    flex-direction: column-reverse;
  }
  .case-study ul.tabs li {
    /* padding: 10px 1pc; */
    cursor: pointer;
    border: 1px solid lightgray;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    transition: all 0.5s;
    font-size: 16px;
    padding: 10px 20px;
    max-width: 100%;
    margin-left: 15px;
  }
  .case-study-banner-sec .case-study-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 60%;
    left: 5%;
  }
  .case-study-banner-sec .case-study-banner-content .main-heading {
    font-size: 26px;
    font-weight: 500;
  }
  .case-study-banner-sec .case-study-banner-content .breadcrumb-casestudy-box {
    margin-bottom: 5px;
  }
  .case-study-banner-sec .case-study-banner-content .desc {
    font-size: 18px;
    line-height: 25px;
  }
  .about-client-sec .about-client-content-box .desc {
    font-size: 17px;
    line-height: 25px;
  }
  .about-client-sec .about-client-main-head .main-heading {
    font-size: 22px;
  }
  .about-client-sec .about-client-content-box {
    padding: 15px;
    position: relative;
  }
  .about-client-sec .about-client-content-box .right-quote {
    position: absolute;
    left: 5%;
    top: 0%;
    width: 10%;
  }
  .about-client-sec .about-client-main-head .main-heading1 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-bottom: 5px;
  }
  .about-client-sec .about-client-content-box .left-quote {
    position: absolute;
    right: 5%;
    bottom: 2%;
    width: 10%;
  }
  .business-model-sec .business-model-main-head .main-heading {
    font-size: 22px;
  }
  .business-model-sec .business-model-main-head .desc {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
  .business-model-sec .business-model-card .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .business-model-sec .business-model-card {
    padding: 15px;
    border-radius: 5px;
    min-height: 200px;
    cursor: pointer;
    margin-bottom: 25px;
  }
  .business-model-sec .business-model-card .business-model-list-box .business-model-list li .card-head {
    margin-left: 10px;
    margin-bottom: 0px;
    font-size: 16px;
  }
  .market-research-sec .market-research-head .market-research-content-box .market-research-content .para {
    font-size: 14px;
    line-height: 20px;
  }
  .market-research-sec .market-research-head .main-heading {
    font-size: 22px;
  }
  .market-research-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
  }
  .market-research-sec .market-research-head .market-research-content-box .market-research-content {
    padding: 15px;
    min-height: 250px;
    margin-bottom: 25px;
  }
  .market-research-sec .market-research-img {
    margin-top: 30px;
  }
  .technology-sec .technology-content-area .technology-card .technology-card-content {
    border-top: 1px solid #e5e5e5;
    padding-top: 80px;
  }
  .technology-card-style1 .technology-card-content {
    padding-top: 80px;
    text-align: center;
    margin: 25px 0px;
    border-top: 1px solid #e5e5e5;
  }
  .market-research-sec .market-research-head .market-research-content .market-research-content-list li {
    position: relative;
    padding-left: 35px;
    line-height: 25px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .challenges-and-solutions-sec .challenges-solutions-content .challenges-solutions-card .number-card {
    content: "";
    position: absolute;
    left: -5%;
  }
  .market-research-sec .market-research-head .market-research-content h5 {
    font-size: 16px;
  }
  .market-research-sec .market-research-head .market-research-content {
    padding-top: 20px;
    margin-bottom: 35px;
  }
  .technology-sec .technology-head .main-heading {
    font-size: 24px;
  }
  .technology-sec .technology-head .desc {
    font-size: 16px;
    margin-top: 20px;
    line-height: 25px;
  }
  .technology-sec .technology-head .market-research-content h5 {
    font-size: 16px;
  }
  .technology-sec .technology-head .market-research-content {
    padding-top: 20px;
  }
  .technology-sec .technology-head .market-research-content .market-research-content-list li {
    position: relative;
    padding-left: 35px;
    line-height: 20px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .challenges-and-solutions-sec .challenges-solutions-main-head .main-heading {
    font-size: 24px;
    padding-bottom: 15px;
  }
  .challenges-and-solutions-sec .challenges-solutions-main-head {
    margin-top: 2%;
    margin-bottom: 0%;
  }
  .challenges-and-solutions-sec .challenges-and-solutions-img img {
    margin-left: 0%;
  }
  .results-growth-sec .results-growth-head .main-heading {
    font-size: 24px;
  }
  .results-growth-sec .results-growth-content .results-growth-content-box {
    margin-top: 20px;
    background-color: white;
    padding: 15px;
  }
  .results-growth-sec .results-growth-content .results-growth-content-box .results-growth-content-list li {
    line-height: 25px;
    font-size: 16px;
  }
  .future-prospects-sec .future-prospects-head .main-heading {
    font-size: 24px;
  }
  .future-prospects-sec .future-prospects-head {
    background-color: white;
    min-height: 300px;
    padding: 25px;
  }
  .future-prospects-sec .future-prospects-head .future-prospects-content-box {
    padding-top: 15px;
  }
  .future-prospects-sec .future-prospects-head .future-prospects-content-box .future-prospects-content-list li {
    line-height: 25px;
    font-size: 14px;
  }
  .future-prospects-sec .future-prospects-head .future-prospects-content-box .future-prospects-content-list li .checkicn {
    content: "";
    position: absolute;
    left: 7px;
    top: 4px;
    font-size: 18px;
    color: #414bbe;
    transform: translate(0px, 0px);
  }
  .future-prospects-sec .future-prospects-img {
    margin-left: 0%;
    margin-top: 2%;
  }
  .conclusion-sec .conclusion-sec-head .main-heading {
    font-size: 24px;
  }
  .conclusion-sec .conclusion-content-box {
    padding: 15px;
    margin-top: 10px;
  }
  .conclusion-sec .conclusion-content-box .desc {
    line-height: 25px;
    font-size: 16px;
    margin-top: 25px;
  }
  .results-growth-sec .results-growth-content .results-growth-content-box .results-growth-content-list li .checkicn {
    content: "";
    position: absolute;
    left: 7px;
    top: 4px;
    font-size: 18px;
    color: #414bbe;
    transform: translate(0px, 0px);
  }
  .technology-sec .technology-head {
    background-color: #3740aa;
    padding: 30px 15px 30px 15px;
    border-radius: 10px 10px 10px 10px;
    min-height: 300px;
    width: 400px;
    margin: 0 auto;
  }
  .huddle-heart-banner-sec {
    min-height: 350px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .mt-30 {
    margin-top: 15px;
  }
  .huddle-heart-banner-sec .case-study-banner-content img {
    width: 50%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .transform-button {
    color: white;
    background-color: #41b2e6;
    border: 1px solid #41b2e6;
    padding: 10px 24px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 14px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }
  .huddle-heart-vision-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
  }
  .huddle-heart-vision-sec .market-research-head .main-heading {
    font-size: 20px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content img {
    width: 45%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-img img {
    margin-top: 10%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .huddle-heart-intro-list li {
    font-size: 14px;
    line-height: 20px;
  }
  .huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content {
    padding: 10px;
    min-height: 300px;
    margin-bottom: 25px;
    border-bottom: 3px solid #35b6ff;
  }
  .huddle-heart-vision-sec .market-research-img {
    margin-top: 8%;
  }
  .huddle-heart-features-sec .pt-110 {
    padding-top: 0px;
  }
  .huddle-heart-features-sec .sec-title .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }
  .huddle-heart-features-sec .column {
    padding: 0px 10px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card {
    min-height: 400px;
    margin-bottom: 40px;
    padding: 15px 15px 10px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card .card-heading {
    font-weight: 600;
    font-size: 18px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card .desc {
    padding-top: 10px;
    font-size: 14px;
    line-height: 25px;
  }
  .enhanced-communication-sec .enhanced-communication-main-head .main-heading {
    font-size: 20px;
    padding-bottom: 15px;
  }
  .enhanced-communication-sec .enhanced-communication-card::before {
    display: none;
  }
  .enhanced-communication-sec .enhanced-communication-card {
    margin-top: 0%;
    margin-bottom: 35px;
    padding: 20px;
    min-height: 348px;
    position: relative;
  }
  .enhanced-communication-sec .enhanced-img::after {
    display: none;
  }
  .enhanced-communication-sec .enhanced-img::before {
    display: none;
  }
  .enhanced-communication-sec .enhanced-img {
    width: 70%;
    min-height: 540px;
    position: relative;
    margin-bottom: 10%;
  }
  .enhanced-communication-card h5 {
    font-size: 18px;
  }
  .improved-safety-sec .huddle-heart-technology-head .main-heading {
    font-size: 20px;
    padding-top: 10px;
  }
  .huddle-heart-impacts-sec .huddle-heart-impacts-head .main-heading {
    font-size: 20px;
    padding-bottom: 10px;
  }
  .huddle-heart-impacts-sec .huddle-heart-impacts-card {
    padding: 10px;
    min-height: 356px;
    margin-bottom: 30px;
  }
  .huddle-heart-imapcts-img {
    margin-bottom: 5%;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-main-head .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-img {
    margin-bottom: 5%;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-content .challenges-lessons-card .challenges-lessons-icon {
    position: absolute;
    left: 0;
    top: -20%;
    right: 0;
    margin: 0 auto;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-content .challenges-lessons-card {
    margin-bottom: 45px;
    padding: 30px 15px 15px 15px;
  }
  .profile-banner-sec .screen-mob {
    width: 90%;
    min-height: 712px;
  }
  .profile-banner-sec .mt-80 {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .future-prospects-sec .business-model-main-head .main-heading {
    font-size: 21px;
    padding-bottom: 10px;
  }
  .future-prospects-sec .business-model-card .business-model-list-box .business-model-list li .card-head {
    font-size: 18px;
  }
  .future-prospects-sec .business-model-card {
    min-height: 285px;
    margin-bottom: 25px;
  }
  .eigth-banner ul {
    list-style-type: disc;
    padding-left: 0px;
  }
  .plywood-bazar-banner-sec {
    min-height: 450px;
  }
  .plywood-bazar-banner-sec .plywood-bazar-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }
  .plywood-bazar-banner-sec .plywood-bazar-banner-content {
    margin-top: 5%;
  }
  .plywood-bazar-about-us .bg-shape-3 {
    position: absolute;
    bottom: 0%;
    right: 0%;
    text-align: end;
    display: none;
  }
  .plywood-bazar-about-us .bg-shape-1 img {
    width: 40%;
    display: none;
  }
  .plywood-bazar-about-us .bg-shape-2 img {
    width: 19%;
    display: none;
  }
  .plywood-bazar-banner-sec .plywood-bazar-banner-img {
    text-align: end;
    margin-top: 20%;
  }
  .plywood-bazar-about-us .about-content-box .main-heading {
    font-weight: 500;
    font-size: 28px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #212529;
  }
  .plywood-bazar-about-us .about-content-list-box .about-content-list li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    line-height: 20px;
  }
  .plywood-bazar-about-us .about-content-box .sub-heading {
    font-size: 16px;
  }
  .mission-or-vision-plywood .mission-vision-right-shape {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }
  .mission-or-vision-plywood .left-side-box {
    margin-bottom: 25px;
    padding: 10px;
  }
  .mission-or-vision-plywood .left-side-box .desc {
    font-size: 14px;
    line-height: 25px;
  }
  .mission-or-vision-plywood .mission-vision-left-shape {
    position: absolute;
    left: 0;
    bottom: 30px;
    display: none;
  }
  .mission-or-vision-plywood .mission-vision-wrapper::after {
    position: absolute;
    bottom: -27%;
  }
  .mission-or-vision-plywood .right-side-box {
    margin-top: 100%;
    padding: 10px;
  }
  .mission-or-vision-plywood .right-side-box .desc {
    font-size: 14px;
    line-height: 25px;
  }
  .our-client-requirement .about-client-area .main-head {
    font-size: 26px;
  }
  .our-client-requirement .about-client-area .desc {
    font-size: 16px;
    padding-top: 0%;
    line-height: 25px;
  }
  .our-client-requirement .client-req-area .main-head {
    font-size: 26px;
    font-weight: 500;
  }
  .our-client-requirement .client-req-area .client-req-list li {
    border-left: 3px solid #f7c553;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 20px;
  }
  .solution-given-sec .solution-given-content .main-heading {
    color: #b08218;
    font-size: 22px;
    font-weight: 500;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li .heading {
    font-size: 20px;
    font-weight: 500;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li::marker {
    font-size: 20px;
  }
  .solution-given-sec .solution-given-content .sub-heading {
    color: #b08218;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 20px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .plywoodbazar-features-head .sub-heading {
    font-size: 14px;
  }
  .our-work-screens .our-work-screens-main-head {
    position: absolute;
    width: 34%;
    margin-top: 14%;
  }
  .our-work-screens .our-work-screens-main-head .main-heading {
    font-size: 26px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .plywoodbazar-features-head .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list {
    height: 480px;
    overflow-y: auto;
    padding-left: 30px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li {
    list-style-type: decimal;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  }
  .plywoodbazar-result-sec .plywoodbazar-result-main-head .desc {
    color: black;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    line-height: 20px;
  }
  .plywoodbazar-result-sec .plywoodbazar-result-main-head .main-heading {
    font-size: 26px;
    color: #b08218;
    margin-bottom: 10px;
  }
  .RoCare-banner-sec {
    min-height: 500px;
  }
  .RoCare-banner-sec .RoCare-banner-img {
    margin-top: 20%;
  }
  .RoCare-banner-sec .RoCare-banner-content .main-heading {
    font-size: 23px;
    margin-bottom: 10px;
  }
  .RoCare-banner-sec .RoCare-banner-content {
    margin-top: 6%;
  }
  .Rocare-aboutus .Rocare-aboutus-main-head {
    margin-top: 5%;
  }
  .client-requirements-sec .client-requirements-main-head {
    background-color: white;
    padding: 50px 15px 22px 15px;
  }
  .j-c-sb {
    justify-content: space-between;
  }
  .client-requirements-img img {
    width: 75%;
  }
  .client-requirements-sec .client-requirements-main-head .main-heading {
    font-size: 22px;
  }
  .client-requirements-sec .client-requirements-main-head .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .client-requirements-sec .client-requirements-main-head .main-heading::before {
    font-size: 60px;
    bottom: 0px;
  }
  .Rocare-features-sec .content-heading .features-heading {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .rocare-challenges-sec .rocare-challenges-content {
    margin-top: 12%;
  }
  .NikkyPore-banner-sec {
    min-height: 470px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .main-heading {
    font-size: 21px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .mt-30 {
    margin-top: 15px;
  }
  .Nikkypore-project-summary .pb-50 {
    padding-bottom: 10px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .sub-head {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .desc {
    font-size: 14px;
    line-height: 25px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content {
    margin-top: 4%;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-img {
    text-align: end;
    margin-top: 20%;
    animation: 4s ease-in-out infinite floatY;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .transform-button {
    padding: 10px 24px;
    font-size: 15px;
  }
  .Nikkypore-project-summary .Nikkypore-main-head .main-heading {
    font-size: 24px;
  }
  .Nikkypore-goals-objectives .Nikkypore-goals-objectives-main-head .main-heading {
    font-size: 24px;
  }
  .Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card {
    min-height: 100%;
  }
  .Nikkypore-goals-objectives .goals-obj-content-box {
    margin-top: 0%;
  }
  .Nikkypore-goals-objectives .goals-obj-img-box {
    margin: 2% 0%;
  }
  .Nikkypore-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
  }
  .mt-3 {
    margin-top: 0px !important;
  }
  .Nikkypore-features-sec .customer-app-features-content {
    margin-top: 0%;
  }
  .Nikkypore-features-sec .customer-app-features-img {
    text-align: center;
  }
  .challenges-and-solutions-sec .challenges-sec .challenges-content-list li .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .challenges-and-solutions-sec .challenges-solutions-img {
    margin-bottom: 5%;
    text-align: center;
  }
  .challenges-and-solutions-sec .challenges-solutions-content {
    margin-bottom: 4%;
  }
  .challenges-and-solutions-sec .challenges-sec h4 {
    font-size: 20px;
  }
  .challenges-and-solutions-sec .challenges-and-solutions-main-head .main-heading {
    font-size: 22px;
  }
  .visual-designs-sec .visual-designs-main-head .main-heading {
    font-size: 24px;
  }
  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide {
    margin: 0 auto;
    text-align: center;
  }
  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide img {
    width: 80%;
  }
  .visual-designs-sec .visual-designs-wrapper {
    padding: 0px;
  }
  .visual-designs-sec .visual-designs-wrapper::before {
    display: none;
  }
  .visual-designs-sec .visual-designs-main-head .desc {
    margin-bottom: 0px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content {
    padding-left: 0%;
    margin-top: 5%;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .desc {
    color: #222a41;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-main-banner-logo {
    margin-bottom: 15px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li {
    flex-basis: 50%;
    margin-top: 10px;
    font-size: 16px;
  }
  .deyor-main-banner-sec {
    min-height: 350px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li h5 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 25px;
  }
  .deyor-about-us .deyor-about-us-main-head .main-heading {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .deyor-about-us {
    padding: 20px 0px 20px 0px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box .aboutus-logo {
    position: absolute;
    width: 24.3%;
    right: 0;
    top: 0;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box {
    position: relative;
    cursor: pointer;
    margin-bottom: 80%;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content {
    margin-top: 2%;
    cursor: pointer;
  }
  .deyor-objective-sec .deyor-objective-content-box {
    margin-top: 10%;
  }
  .deyor-objective-sec .deyor-objective-content-box .deyor-objective-card .number-area {
    display: block;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
    color: #c4343c;
  }
  .deyor-objective-sec .deyor-objective-content-box .deyor-objective-card {
    padding: 10px;
    min-height: 170px;
    margin-bottom: 20px;
  }
  .deyor-objective-sec .deyor-objective-content-box .deyor-objective-card .desc1 {
    font-size: 14px;
    line-height: 20px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .main-heading {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .desc {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .deyor-about-us-content-list li {
    font-size: 18px;
    line-height: 30px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .deyor-about-us-content-list li .list-bar {
    position: absolute;
    left: 0;
    top: 15px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box {
    margin-top: 5%;
    background-color: white;
    padding: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
  }
  .deyor-methodology-sec .deyor-methodology-img {
    margin-top: 5%;
    text-align: center;
  }
  .deyor-methodology-sec .deyor-methodology-img .mt-10 {
    margin-top: 5%;
    width: 70%;
    text-align: center;
    margin-left: 0%;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 {
    margin-top: 5%;
  }
  .d-f-fd-cr {
    display: flex;
    flex-direction: column-reverse;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box {
    margin-top: 5%;
    margin-right: 0%;
    min-height: 430px;
    margin-bottom: 5%;
    padding: 45px 50px 40px;
  }
  .deyor-challenges-sec .deyor-challenges-img {
    text-align: center;
  }
  .deyor-challenges-sec .deyor-challenges-img img {
    width: 80%;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .desc {
    font-size: 18px;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .main-heading {
    font-size: 26px;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }
  .deyor-solutions-sec .deyor-solutions-main-head .main-heading {
    font-size: 26px;
  }
  .deyor-solutions-sec .deyor-solutions-img {
    text-align: center;
  }
  .deyor-solutions-sec .deyor-solutions-img img {
    width: 80%;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .challenges-content-list {
    margin-top: 5%;
  }
  .deyor-solutions-sec .deyor-solutions-content-box {
    margin-top: 5%;
    margin-left: 0%;
    min-height: 400px;
    margin-bottom: 0%;
    padding: 50px 50px 50px;
  }
  .deyor-solutions-sec .deyor-solutions-content-box .desc {
    font-size: 18px;
  }
  .deyor-solutions-sec .deyor-solutions-content-box .main-heading {
    font-size: 26px;
  }
  .deyor-solutions-sec .deyor-solutions-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }
  .deyor-work-screens-sec .deyor-work-screens-main-head .main-heading {
    font-size: 24px;
  }
  .samagri-banner-sec .samagri-banner-shape img {
    position: absolute;
    width: 36%;
    z-index: 1;
    left: 35px;
  }
  .samagri-banner-sec .samagri-banner-content .main-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
  }
  .samagri-banner-sec .samagri-banner-content .desc {
    font-size: 18px;
    color: #413d2c;
    font-weight: 600;
    line-height: 30px;
  }
  .samagri-banner-sec .samagri-banner-content .desc1 {
    font-size: 16px;
    color: #413d2c;
    font-weight: 400;
    line-height: 25px;
  }
  .samagri-banner-sec .samagri-banner-content {
    margin-top: 10%;
    padding-bottom: 10%;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box {
    background-color: white;
    padding: 10px;
    justify-content: space-between;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li span svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li {
    display: flex;
    align-items: start;
    line-height: 35px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li span svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li {
    line-height: 35px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box {
    margin-top: 0%;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box .main-heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box .desc {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-img-box .samagri-logo-img img {
    width: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle img {
    position: absolute;
    left: 40%;
    top: 160px;
    width: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle .circle-content {
    position: absolute;
    top: 43%;
    left: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(1) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(2) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(3) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(4) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(-24px 29px 25px rgba(12, 12, 13, 0.32));
    text-align: center;
    line-height: 75px;
    margin-bottom: 20px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-icon img {
    width: 45%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-area {
    padding-left: 0px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-area .desc {
    font-size: 16px;
    line-height: 25px;
    color: #fff;
  }
  .samagri-work-screens {
    position: relative;
    width: 100%;
    min-height: 350px;
  }
  .samagri-work-screens .samagri-work-screen-content .main-heading {
    font-size: 22px;
    font-weight: 600;
  }
  .samagri-work-screens .samagri-work-screen-content {
    margin-top: 0%;
  }
  .samagri-work-screens .samagri-work-screen-content .desc {
    line-height: 20px;
    font-size: 13px;
    width: 70%;
  }
  .samagri-features-sec .samagri-features-main-head {
    margin-top: 5%;
  }
  .samagri-features-sec .samagri-features-main-head .main-heading {
    color: black;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .samagri-features-sec .samagri-features-card {
    padding: 15px 10px;
    margin-bottom: 20px;
    min-height: 140px;
  }
  .samagri-features-sec .samagri-features-card .card-heading {
    font-size: 14px;
    margin-bottom: 0px !important;
  }
  .samagri-features-sec .samagri-features-img {
    margin-top: 10%;
  }
  .samagri-challenges-sec .samagri-challenges-main-head .main-heading {
    font-size: 22px;
    color: white;
  }
  .samagri-challenges-sec .samagri-challenges-content {
    margin-top: 0%;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card .samagri-challenges-card-heading .main-heading {
    text-align: center;
    font-size: 14px;
    color: white;
    padding: 10px;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card {
    padding: 5px;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card .desc {
    padding: 5px;
    line-height: 20px;
    font-size: 14px;
  }
  .samagri-challenges-sec .samagri-challenges-img {
    margin-bottom: 5%;
  }
  .samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .main-heading {
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .desc {
    color: white;
    font-size: 14px;
    line-height: 20px;
  }
  .samagri-contact-banner {
    padding: 50px 0px;
  }
  .turning-point-banner-sec {
    min-height: 450px;
  }
  .turning-point-banner-sec .turning-point-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 15px;
  }
  .turning-point-banner-sec .turning-point-banner-content {
    margin-top: 5%;
  }
  .turning-point-banner-sec .turning-point-banner-img img {
    margin-top: 25%;
  }
  .turning-point-banner-sec .turning-point-banner-content .transform-button {
    padding: 10px 24px;
    font-weight: 500;
  }
  .turning-point-about-client-sec .turning-point-about-client-content-box {
    padding-left: 20px;
    padding-top: 0%;
    position: relative;
  }
  .turning-point-about-client-sec .turning-point-about-client-main-head .main-heading1 {
    font-size: 26px;
    font-weight: 600;
    position: relative;
    margin-bottom: 10px;
  }
  .turning-point-about-client-sec .turning-point-about-client-img {
    margin-top: 2%;
  }
  .turning-point-about-client-sec .turning-point-about-client-content-box .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .turning-point-project-highlights-sec .turning-point-project-highlights-head .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }
  .mt-20 {
    margin-top: 20px;
    border-top: 1px solid #eee;
  }
  .turning-point-features-sec .customer-app-features-content {
    margin-top: 0%;
  }
  .turning-point-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .turning-point-challenges-and-solutions-sec .challenges-and-solutions-main-head .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .turning-point-challenges-and-solutions-sec .challenges-sec {
    margin-top: 5%;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-main-head .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper::before {
    display: none;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper .turning-point-visual-designs-slide img {
    width: 65%;
  }
  .turning-point-conclusion-sec .turning-point-conclusion-sec-head .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .turning-point-conclusion-sec .turning-point-conclusion-content-box .desc {
    font-size: 16px;
    line-height: 25px;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper {
    background-color: #f0686c;
    padding: 20px 0px 20px;
    position: relative;
  }
  .turning-point-features-sec .position-relative-z-index-1 .customer-app-features-img {
    text-align: center;
  }
  .turning-point-challenges-and-solutions-sec .challenges-solutions-img {
    text-align: center;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .sch-software-portfolio-head .main-heading {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
  }
  .portfolio-left-area h4 {
    color: white;
    font-size: 38px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-top: 70px;
  }
  .portfolio-left-area .sub-heading-area {
    margin-bottom: 30px;
    margin-left: 80px;
  }
  .works-list li {
    margin-right: 113px;
    margin-bottom: 10px;
  }
  .desc-button {
    margin-top: 25px;
  }
  .works-list-1 li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }
  .works-list li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }
  .travel-about-us .about-img {
    padding-top: 0px;
  }
  .py-40 {
    padding: 20px 0px;
  }
  .travel-banner-content {
    position: absolute;
    top: 54%;
    left: 3%;
    z-index: 1;
    width: 60%;
  }
  .travel-banner-content h2 {
    color: white;
    font-size: 32px;
    font-weight: 600;
    font-family: var(--roboto-fonte);
    margin-bottom: 0px;
  }
  .about-content .main-heading {
    font-size: 26px;
    font-weight: 700;
    color: #020202;
    margin: 10px 0px;
  }
  .about-content .desc {
    font-size: 14px;
  }
  .features-head h2 {
    font-size: 24px;
    font-weight: 700;
    color: #020202;
    font-family: "Roboto", sans-serif;
    text-align: center;
    margin-bottom: 15px;
  }
  .nav-tabs .nav-item button {
    background-color: #e8ebf1;
    border-radius: 50px;
    color: #020202;
    padding: 12px 32px !important;
    margin: 0px !important;
    margin-right: 5px !important;
    font-size: 12px !important;
    font-weight: 600;
  }
  .portfolio-left-area {
    background-color: rgb(215, 164, 64);
    width: 100% !important;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
  }
  .connect {
    position: absolute;
    top: 25%;
    left: 5%;
  }
  .connect .desc {
    color: white;
    font-size: 20px;
    font-family: var(--roboto-fonte);
  }
  .quote {
    position: absolute;
    top: 80%;
    left: 5%;
    color: white;
    font-size: 20px;
    font-family: var(--roboto-fonte);
  }
  .portfolio-area {
    background-color: #f5e9ce;
    width: 100%;
    position: relative;
    min-height: 550px !important;
  }
  .sch-software-process-tabs {
    display: none;
  }
  .sch-software-development-process .faq-accordion {
    display: block;
  }
  .sch-software-development-process .faq-accordion .accordion .accordion-button {
    background-image: none !important;
    font-size: 15px;
  }
  .faq-accordion .accordion .accordion-button {
    background-image: none !important;
    font-size: 14px;
  }
  .faq-accordion .accordion .accordion-header svg {
    content: "";
    position: absolute;
    right: 4%;
    top: 35%;
  }
  .faq-accordion .accordion .accordion-body {
    background-color: rgb(252, 175, 23);
    color: white;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
  .features-card {
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s linear;
    min-height: 100px;
    margin-bottom: 25px;
  }
  .features-img img {
    width: 30px;
    height: 30px;
    margin-bottom: 0px;
    text-align: center;
  }
  .features-card h5 {
    padding: 5px;
    font-size: 12px;
    margin-top: 5px;
  }
  .features-head .desc {
    font-size: 12px;
  }
  .job-banner-sec .job-banner-content {
    position: absolute;
    top: 33%;
    left: 0%;
  }
  .e-hiring-model-list {
    margin: 10px 0px;
  }
  .e-hiring-model-list:nth-child(1) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(2) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(3) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(4) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(5) {
    position: relative;
    left: 0%;
  }
  .e-hiring-model-list:nth-child(6) {
    position: relative;
    left: 0%;
  }
  .inner-icon-content h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .inner-icon-content p {
    font-size: 12px;
    line-height: 15px;
  }
  .discuss-specialists-content .desc {
    font-size: 13px;
  }
  .discuss-specialists-content .lets-talk-button {
    font-size: 14px;
    padding: 8px 32px;
  }
  .discuss-specialists-content .title {
    font-size: 22px;
  }
  .job-banner-sec .job-banner-content h1 {
    color: white;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }
  .job-banner-sec .job-banner-content .desc {
    font-size: 16px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 40px;
    width: 70%;
  }
  .job-portal-services-head h2 {
    font-size: 26px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }
  .job-portal-services-head .desc {
    font-size: 12px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    line-height: 16px;
  }
  .on-demand-head-area .sub-head {
    font-size: 12px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 15px;
  }
  .pb-40 {
    padding-bottom: 20px;
  }
  .on-demand-head-area .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }
  .on-demand-my-tab .on-demand-tab-head {
    color: white;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 0px;
  }
  .admin-content-list li {
    color: white;
    line-height: 30px;
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
  }
  .admin-content-list-box {
    padding: 15px 0px 0px 0px;
  }
  .admin-content-list li svg {
    margin-right: 5px;
    font-size: 14px;
  }
  .on-demand-my-tab .on-demand-tab-head {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .job-features-tab-pane .ad-features img {
    width: 16px;
    height: 16px;
  }
  .job-features-tab-pane .ad-features {
    position: absolute;
    left: 51%;
    top: 10px;
    z-index: 1;
  }
  .job-features-tab-pane .co-features img {
    width: 16px;
    height: 16px;
  }
  .job-features-tab-pane .co-features {
    position: absolute;
    left: 31.5%;
    top: 10px;
    z-index: 1;
  }
  .job-p-features-card .job-p-features-content h4 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .job-p-features-card .job-p-features-content .desc {
    line-height: 20px;
  }
  .job-p-features-card {
    padding: 5px;
    min-height: 200px;
    margin-bottom: 20px;
  }
  .our-recent-works-head h2 {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .our-recent-works-head .desc {
    font-size: 13px;
    width: 100%;
    line-height: 20px;
  }
  .why-choose-j-p .why-choose-j-p-head h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .why-choose-j-p .why-choose-j-p-head .desc {
    font-size: 12px;
    line-height: 15px;
  }
  .e-hiring-model .e-hiring-model-head h2 {
    font-size: 19px;
    margin-bottom: 10px;
  }
  .e-hiring-model .e-hiring-model-head .desc {
    font-size: 12px;
    width: 100%;
    line-height: 16px;
  }
  .e-hiring-model .e-hiring-model-head {
    text-align: center;
    padding: 0px;
  }
  .job-services-card {
    min-height: 310px;
    margin-bottom: 25px;
  }
  .our-recent-works-card .our-recent-works-content p {
    color: white;
    margin-bottom: 20px;
    font-size: 13px;
  }
  .our-recent-works-card .our-recent-works-content h4 {
    color: white;
    margin-bottom: 0px;
    font-size: 18px;
  }
  .recent-work-bottom .desc {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .why-choose-jp-card .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
  }
  .e-hiring-model-img {
    text-align: center;
  }
  .e-hiring-model .e-hiring-model-head h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .healthcare-banner-sec .healthcare-banner-content {
    position: absolute;
    top: 50%;
    left: 2%;
    width: 52%;
    z-index: 2;
  }
  .healthcare-banner-sec .healthcare-banner-content .main-heading {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .healthcare-based-solutions-list-box {
    border: 1px solid #e5e5e5;
    padding: 25px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: 0px;
  }
  .healthcare-based-solutions-img img {
    padding: 20px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: 402px;
  }
  .health-app-develop-content .main-heading {
    font-size: 31px;
    font-family: "Poppins", sans-serif;
  }
  .health-app-develop-content .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
    font-weight: 500;
  }
  .growth-banner-content {
    position: relative;
    top: 35%;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .growth-banner-content .main-heading {
    font-size: 24px;
    color: white;
    font-family: "Poppins", sans-serif;
    padding: 10px 0px;
  }
  .hexagon-main-img {
    position: relative;
    text-align: center;
    margin-bottom: 0px;
  }
  .hexagon-main-img .growth-banner-img-content {
    position: absolute;
    top: 27%;
    left: 20%;
    text-align: center;
    width: 60%;
  }
  .health-choose-us-card:nth-child(2) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-right: 0px;
    border-bottom: 0;
  }
  .health-choose-us-main-box .free-consult-button {
    background-color: transparent;
    color: #fcaf17;
    border: 2px solid #fcaf17;
    border-radius: 5px;
    padding: 12px 20px;
    font-weight: 600;
  }
  .health-choose-us-main-box {
    margin-bottom: 25px;
  }
  .discuss-specialists {
    min-height: 200px !important;
  }
  .health-choose-us-card:nth-child(4) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 5px;
    border-right: 1px solid #e5e5e5;
    border-top: 0;
  }
  .health-choose-us-card:nth-child(1) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 5px;
    border-right: 0;
    border-bottom: 0;
  }
  .health-choose-us-card:nth-child(2) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-right: 1px solid #e5e5e5;
  }
  .health-choose-us-card:nth-child(5) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-top: 0;
    border-right: 0;
  }
  .health-choose-us-card:nth-child(3) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-top-right-radius: 5px;
    border-right: 0;
  }
  .hexagon-main-img .growth-banner-img-content .desc {
    font-size: 11px;
    color: white;
    line-height: 15px;
    margin-top: 10px;
  }
  .explore-erp-services-content {
    padding-top: 0px;
  }
  .growth-banner-content .free-consult-button {
    background-color: transparent;
    color: #fcaf17;
    border: 2px solid #fcaf17;
    border-radius: 5px;
    padding: 12px 20px;
    font-weight: 600;
  }
  .explore-erp-services-img {
    margin-bottom: 30px;
  }
  .erp-developed-modules-card {
    padding: 10px;
  }
  .erp-developed-modules-card .erp-developed-modules-content h4 {
    font-size: 14px;
  }
  .erp-developed-modules-card .erp-developed-modules-content .desc {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }
  .erp-banner-sec .erp-banner-content {
    position: absolute;
    top: 22%;
    left: 0%;
  }
  .explore-erp-development-list li {
    margin-bottom: 10px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }
  .erp-about-us .erp-about-img {
    margin-bottom: 25px;
    text-align: center;
  }
  .crm-banner-sec .Crm-banner-content {
    position: absolute;
    z-index: 2;
    top: 52%;
    width: 60%;
    left: 5%;
  }
  .crm-software-features .crm-software-features-card {
    position: relative;
    padding: 5px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 20px;
    min-height: 180px;
  }
  .why-you-need-crm .Crm-app-development-content .main-heading {
    font-size: 30px;
    position: relative;
  }
  .why-you-need-crm .Crm-app-development-content .desc {
    color: black;
    font-size: 14px;
    width: 80%;
    margin: 0 auto;
  }
  .crm-software-features .crm-software-features-head .main-heading {
    font-size: 30px;
    position: relative;
    padding-bottom: 5px;
  }
  .crm-software-features .crm-software-features-head .desc {
    color: black;
    font-size: 13px;
    width: 80%;
    margin: 0 auto;
    line-height: 20px;
  }
  .crm-software-solutions-sec .crm-development-services-head .main-heading {
    font-size: 30px;
  }
  .crm-software-solutions-sec .crm-development-services-head .desc {
    color: black;
    width: 85%;
    margin: 0 auto;
    font-size: 13px;
    line-height: 20px;
  }
  .erp-development-services-head .main-heading-type {
    color: #020202;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .erp-development-services-head .sub-heading {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
  }
  .custom-crm-development-head .main-head {
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: center;
  }
  .custom-crm-development-head .desc {
    width: 80%;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
  }
  .school-mt-banner-sec .school-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 50%;
    width: 100%;
    left: 0%;
  }
  .school-mt-banner-sec .school-mt-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .school-mt-banner-sec .school-mt-banner-content .sub-heading {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
  }
  .school-mt-banner-sec .school-mt-banner-content .transform-button {
    font-size: 14px;
    padding: 10px;
  }
  .Sch-mt-about-content .main-heading {
    font-size: 19px;
  }
  .sch-software-services-head .main-heading {
    font-size: 19px;
    margin-bottom: 0px;
  }
  .sch-software-services-card .sch-software-services-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .sch-software-services-card .sch-software-services-icon img {
    width: 35px;
    height: 35px;
  }
  .sch-software-services-card .sch-software-services-content {
    padding: 10px;
  }
  .sch-software-services-card .sch-software-services-content h5 {
    font-size: 14px;
    font-weight: 500;
  }
  .sch-software-services-card .sch-software-services-content .desc {
    font-size: 12px;
    line-height: 16px;
  }
  .sch-software-modules-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .sch-software-modules-head .desc {
    font-size: 12px;
    line-height: 16px;
  }
  .sch-software-modules-card .sch-software-modules-content h5 {
    font-size: 13px;
    font-weight: 500;
  }
  .Sch-software-features .Sch-software-features-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .Sch-software-features .Sch-software-features-head .desc {
    font-size: 12px;
    line-height: 17px;
  }
  .Sch-mt-features-img img {
    border-radius: 5px;
    margin-bottom: 25px;
  }
  .Sch-mt-features-content {
    border: 1px solid #e5e5e5;
    padding: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: auto;
    margin-bottom: 25px;
  }
  .Sch-software-features .main-box {
    margin: 0px;
  }
  .sch-software-development-process .sch-software-development-process-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .sch-process-r {
    display: none;
  }
  .sch-software-solutions-advantages .sch-software-solutions-advantages-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
  .sch-software-advantages-img img {
    border-radius: 5px;
    min-height: auto;
  }
  .sch-software-advantages-content {
    border-radius: 0px;
  }
  .sch-software-advantages-content .sch-software-advantages-list li {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    line-height: 30px;
  }
  .sch-software-portfolio-head .main-heading {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
  }
  .society-mt-banner-sec .society-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 60%;
    width: 55%;
    left: 0%;
  }
  .erp-development-services-card {
    padding: 10px;
    min-height: 160px;
  }
  .erp-development-services-card .desc {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }
  .society-mt-banner-sec .society-mt-banner-content .main-heading {
    color: white;
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .society-mt-banner-sec .society-mt-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  .society-mt-banner-sec .society-mt-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 8px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }
  .society-mt-about-us .society-mt-about-content .main-heading {
    font-size: 18px;
    color: #020202;
  }
  .society-mt-about-list-box {
    padding-left: 20px;
  }
  .society-mt-about-us .society-mt-about-content .h-about-button {
    margin-top: 10px;
  }
  .society-mt-features-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .society-mt-features-head .desc {
    font-size: 12px;
    line-height: 18px;
  }
  .erp-development-services-card h4 {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }
  .real-est-banner-sec .real-est-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .real-est-banner-sec .real-est-banner-content {
    position: absolute;
    z-index: 2;
    top: 45%;
    width: 50%;
    left: 4%;
  }
  .real-estate-about-img {
    text-align: center;
  }
  .real-estate-about-us .real-estate-about-content .main-heading {
    font-size: 23px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }
  .real-estate-about-us .real-estate-about-content .real-estate-about-heading {
    font-size: 20px;
  }
  .real-estate-about-us .real-estate-about-content .real-estate-about-list-box .real-estate-about-list li {
    border-left: 1px dashed #bebebe;
    padding: 0px 10px 15px 20px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
  }
  .real-est-development-services .real-est-development-services-head {
    text-align: left;
  }
  .real-est-development-services .real-est-development-services-head .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .real-est-development-services .real-est-development-services-head .desc {
    font-size: 14px;
    margin: 0 auto;
    width: 100%;
  }
  .real-est-services-card {
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 25px;
    min-height: 282px;
  }
  .real-est-services-card .real-est-services-content h4 {
    font-size: 17px;
  }
  .real-est-services-card .real-est-services-content .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .real-est-services-card .real-est-services-icon {
    padding: 0px;
    padding-bottom: 5px;
  }
  .on-demand-head-area-re .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 25px;
  }
  .on-demand-head-area-re .sub-head {
    font-size: 20px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 10px;
  }
  .real-est-app-development-features .app-development-right-side .app-development-right-side-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    min-height: 95px;
  }
  .real-est-app-development-features .app-development-left-side .app-development-left-side-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .real-est-app-development-features .real-est-app-development-head {
    text-align: left;
  }
  .real-est-app-development-features .real-est-app-development-head .main-heading {
    font-size: 26px;
  }
  .real-est-app-development-features .app-development-left-side .app-development-left-side-card .app-development-left-side-list li {
    margin-right: 155px;
  }
  .app-development-features-img {
    text-align: center;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .heading {
    color: white;
    font-size: 26px;
    font-weight: 600;
    line-height: 35px;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .desc {
    color: white;
    font-size: 16px;
    margin: 10px 0px 20px 0px;
  }
  .real-est-app-development-features .app-development-right-side {
    margin-bottom: 0px;
  }
  .erp-portfolio-area .erp-portfolio-left-side {
    background-color: rgb(215, 164, 64);
    padding: 60px;
  }
  .erp-portfolio-area .erp-portfolio-left-side h4 {
    color: white;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
  .erp-portfolio-area .erp-portfolio-left-side .sub-heading-area {
    margin-bottom: 15px;
  }
  .erp-portfolio-area .erp-portfolio-left-side .connect-erp .desc {
    color: white;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    width: 80%;
  }
  .discuss-specialists-content .desc {
    font-size: 16px;
    color: white;
    text-align: center;
    margin: 20px 0px;
  }
  .quote-erp .explore-more-button {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    padding: 12px 32px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
  }
  .manufacturing-banner-sec .manufacturing-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 50%;
    left: 0%;
  }
  .whatsapp-bot-about-us .whatsapp-bot-about-us-head .main-head {
    font-size: 32px;
  }
  .manufacturing-banner-sec .manufacturing-banner-content .main-heading {
    font-size: 21px;
    margin-bottom: 5px;
  }
  .manufacturing-banner-sec .manufacturing-banner-content .sub-heading {
    margin-bottom: 20px;
    line-height: 25px;
  }
  .manufacturing-about-us .manufacturing-about-content .main-heading {
    font-size: 22px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
    padding-top: 15px;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 25px;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-content .mft-solutions-list-box .manuft-sol-cnt-list .content h6 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 14px;
  }
  .manuft-sft-services-inner-list h5 {
    font-size: 18px;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-content .sub-heading {
    font-size: 16px;
  }
  .mft-sol-inner-list li image {
    width: 80px;
  }
  .manuft-sft-solutions-sec .manuft-sft-solutions-content .manuft-solutions-left-cnt .manuft-sol-cnt-list li .mft-sol-inner-list h6 {
    font-size: 13px;
    margin-bottom: 0px;
  }
  .manuft-sft-solutions-content .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .manuft-sft-services-inner-list .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .manuft-sft-services .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 25px;
  }
  .manuft-sft-services .manuft-sft-services-list li .manuft-sft-services-inner-list h5 {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
  }
  .manuft-sft-services .manuft-sft-services-list li .manuft-sft-services-inner-list .desc {
    font-size: 15px;
    line-height: 20px;
  }
  .our-works-manuft-software .manuft-sft-works-head {
    text-align: left;
  }
  .our-works-manuft-software .manuft-sft-works-head .main-heading-type {
    color: #020202;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .our-works-manuft-software .manuft-sft-works-content .main-heading {
    font-weight: 600;
    font-size: 20px;
  }
  .our-works-manuft-software .manuft-sft-works-content .desc {
    margin-bottom: 20px;
    line-height: 25px;
  }
  .manuft-sft-works-content {
    margin-bottom: 20px;
  }
  .our-works-manuft-software .manuft-sft-works-content .sub-heading {
    background-color: black;
    color: white;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    padding: 5px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .discuss-specialists-content {
    z-index: 10;
    margin: 0 auto;
    padding: 10px 0px;
    text-align: center;
  }
  .retail-pos-banner-sec .retail-pos-banner-content {
    position: absolute;
    z-index: 2;
    top: 56%;
    width: 50%;
    left: 5%;
  }
  .retail-pos-banner-sec .retail-pos-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    line-height: 25px;
  }
  .retail-pos-about-img {
    text-align: center;
  }
  .retail-pos-banner-sec .retail-pos-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .retail-pos-banner-sec .retail-pos-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }
  .retail-pos-about-us .retail-pos-about-content .main-heading {
    font-size: 26px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
    padding-top: 20px;
  }
  .retail-pos-software-importance .retail-pos-software-imp-head {
    text-align: left;
  }
  .retail-pos-software-importance .retail-pos-software-imp-head .main-heading-type {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .retail-pos-software-importance .retail-pos-software-imp-head .desc {
    width: 100%;
    text-align: left;
    font-size: 16px;
    line-height: 25px;
  }
  .retail-pos-software-importance .retail-pos-imp-card {
    padding: 35px 20px 25px 40px;
    border-radius: 20px;
    min-height: 200px;
    position: relative;
    border: 1px solid transparent;
    margin-right: 0px;
    margin-bottom: 45px;
    margin-top: 25px;
    text-align: center;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-content .desc {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-content h3 {
    color: #3d3e42;
    font-size: 18px;
    margin-bottom: 5px;
    padding-top: 10px;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-icon {
    content: "";
    position: absolute;
    top: -25%;
    left: 0%;
    right: 0%;
    margin: 0 auto;
  }
  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-icon img {
    width: 18%;
  }
  .retail-pos-features-sec .retail-pos-features-head .main-heading-type {
    color: #020202;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .retail-pos-features-sec .retail-pos-features-head {
    text-align: center;
  }
  .retail-pos-features-sec .retail-pos-features-content {
    padding-top: 50px;
  }
  .retail-pos-features-sec .retail-pos-features-content .main-head {
    font-size: 21px;
    position: relative;
    padding-bottom: 15px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    line-height: 25px;
  }
  .retail-pos-features-sec .retail-pos-features-content .retail-pos-accord-ion {
    padding-top: 25px;
    margin-bottom: 25px;
  }
  .retail-pos-accord-ion .accordion-button {
    background-color: #fff;
    color: black;
    font-weight: 600;
    font-size: 16px;
    padding: 15px;
  }
  .retail-pos-accord-ion .plus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }
  .retail-pos-accord-ion .accordion-body {
    box-shadow: 0 90px 70px 0 rgba(0, 0, 0, 0.04), 0 40px 35px 0 rgba(0, 0, 0, 0.03), 0 25px 15px 0 rgba(0, 0, 0, 0.03), 0 11px 7px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.03) !important;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: #fcaf17;
    border: transparent;
  }
  .retail-pos-accord-ion .minus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }
  .retail-pos-features-img {
    text-align: center;
    padding-top: 0px;
  }
  .pb-60 {
    padding-bottom: 40px;
  }
  .retail-pos-features-sec .explore-buttn-holder .explore-buttn {
    padding: 10px 20px;
  }
  .retail-pos-features-sec .explore-buttn-holder {
    text-align: center;
    margin-top: 40px;
  }
  .retail-pos-why-choose-us .retail-pos-why-choose-us-head {
    text-align: center;
  }
  .retail-pos-why-choose-us .retail-pos-why-choose-us-head .main-heading-type {
    color: #020202;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .retail-pos-why-choose-us .why-choose-retail-pos-card {
    padding: 20px;
    border-radius: 16px;
    margin-bottom: 25px;
  }
  .retail-pos-why-choose-us .why-choose-retail-pos-card .why-choose-retail-pos-content h3 {
    text-align: center;
    font-size: 24px;
    width: 50%;
    margin: 0 auto;
  }
  .retail-pos-why-choose-us .why-choose-retail-pos-card .why-choose-retail-pos-content .desc {
    font-size: 18px;
    line-height: 25px;
    padding: 15px 0px;
    text-align: center;
  }
  .newportfolyo .card_portfoliyo .portfolicardview {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
    text-align: center;
  }
  .newportfolyo .card_portfoliyo p {
    font-size: 20px;
    margin: 10px 0;
    line-height: 30px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .newportfolyo .card_portfoliyo h3 {
    font-size: 32px;
  }
  .newportfolyo .leftsidecontnt .text_right {
    width: 100%;
    text-align: center;
  }
  .newportfolyo .card_portfoliyo .leftcardportfoli {
    padding: 0px;
    margin-top: 5%;
  }
  .btnall.text-end {
    display: flex;
    justify-content: center;
  }
  .newportfolyo .card_portfoliyo .text_left {
    text-align: center;
    margin-left: 0%;
  }
  .newportfolyo li {
    padding: 30px 0;
    min-height: 100%;
  }
  .newportfolyo .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .newportfolyo .card_portfoliyo .portfolicardview img {
    width: 50%;
    z-index: 999;
    position: relative;
    margin-top: 2%;
  }
  .newportfolyo .card_portfoliyo .portfolicardview::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
    text-align: center;
  }
  .newportfolyom .card_portfoliyom p {
    font-size: 20px;
    line-height: 30px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .newportfolyom .card_portfoliyom h3 {
    font-size: 28px;
  }
  .newportfolyom .leftsidecontntm .text_right {
    width: 100%;
    text-align: center;
  }
  .newportfolyom .card_portfoliyom .leftcardportfolim {
    padding: 0px;
    margin-top: 0%;
    height: unset;
  }
  .btnall.text-end {
    display: flex;
    justify-content: center;
  }
  .newportfolyom .card_portfoliyom .text_left {
    text-align: center;
    margin-left: 0%;
  }
  .newportfolyom li {
    padding: 37px 0;
    min-height: 100%;
  }
  .newportfolyom .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm img {
    width: 45%;
    z-index: 999;
    position: relative;
    margin-top: 2%;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }
  .newportfolyom .leftsidecontntm .portfolicardviewm {
    text-align: center;
  }
  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }
  .f-d-cr {
    flex-direction: column-reverse;
  }
  .case-study-banner-sec .case-study-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 80%;
    left: 5%;
  }
  .case-study-banner-sec .case-study-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .case-study-banner-sec .case-study-banner-content .breadcrumb-casestudy-box {
    margin-bottom: 5px;
  }
  .case-study-banner-sec .case-study-banner-content .desc {
    font-size: 16px;
    line-height: 20px;
  }
  .about-client-sec .about-client-content-box .desc {
    font-size: 17px;
    line-height: 25px;
  }
  .about-client-sec .about-client-main-head .main-heading {
    font-size: 22px;
  }
  .about-client-sec .about-client-content-box {
    padding: 15px;
    position: relative;
  }
  .about-client-sec .about-client-content-box .right-quote {
    position: absolute;
    left: 5%;
    top: 0%;
    width: 10%;
  }
  .about-client-sec .about-client-main-head .main-heading1 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-bottom: 5px;
  }
  .about-client-sec .about-client-content-box .left-quote {
    position: absolute;
    right: 5%;
    bottom: 2%;
    width: 10%;
  }
  .business-model-sec .business-model-main-head .main-heading {
    font-size: 22px;
  }
  .business-model-sec .business-model-main-head .desc {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
  .business-model-sec .business-model-card {
    padding: 15px;
    border-radius: 5px;
    min-height: 200px;
    cursor: pointer;
    margin-bottom: 25px;
  }
  .market-research-sec .market-research-head .main-heading {
    font-size: 22px;
  }
  .market-research-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
  }
  .market-research-sec .market-research-head .market-research-content-box .market-research-content {
    padding: 15px;
    min-height: 250px;
    margin-bottom: 25px;
  }
  .market-research-sec .market-research-img {
    margin-top: 30px;
  }
  .technology-sec .technology-content-area .technology-card .technology-card-content {
    border-top: 1px solid #e5e5e5;
    padding-top: 80px;
  }
  .technology-card-style1 .technology-card-content {
    margin: 0px 0px;
  }
  .improved-safety-sec .technology-content-area .technology-card .huddle-heart-technology-card-content .content-txt .desc {
    line-height: 20px;
    font-size: 14px;
  }
  .market-research-sec .market-research-head .market-research-content .market-research-content-list li {
    position: relative;
    padding-left: 35px;
    line-height: 25px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .improved-safety-sec .technology-content-area .technology-card-style1 .technology-card-content .content-txt {
    padding: 0px;
  }
  .technology-card-content .content-txt h5 {
    padding: 5px 0px;
    font-size: 16px;
  }
  .huddle-heart-technology-card-content .content-txt h5 {
    padding: 5px 0px;
    font-size: 16px;
  }
  .huddle-heart-impacts-sec .huddle-heart-impacts-card .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .challenges-and-solutions-sec .challenges-solutions-content .challenges-solutions-card .number-card {
    content: "";
    position: absolute;
    left: -9%;
  }
  .market-research-sec .market-research-head .market-research-content h5 {
    font-size: 16px;
  }
  .market-research-sec .market-research-head .market-research-content {
    padding-top: 20px;
    margin-bottom: 35px;
  }
  .technology-sec .technology-head .main-heading {
    font-size: 24px;
  }
  .technology-sec .technology-head .desc {
    font-size: 14px;
    margin-top: 15px;
  }
  .technology-sec .technology-head .market-research-content h5 {
    font-size: 16px;
  }
  .technology-sec .technology-head .market-research-content {
    padding-top: 20px;
  }
  .technology-sec .technology-head .market-research-content .market-research-content-list li {
    position: relative;
    padding-left: 35px;
    line-height: 20px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .challenges-and-solutions-sec .challenges-solutions-main-head .main-heading {
    font-size: 24px;
    padding-bottom: 15px;
  }
  .challenges-and-solutions-sec .challenges-solutions-main-head {
    margin-top: 0%;
    margin-bottom: 5%;
  }
  .challenges-and-solutions-sec .challenges-and-solutions-img img {
    margin-left: 0%;
  }
  .results-growth-sec .results-growth-head .main-heading {
    font-size: 24px;
  }
  .results-growth-sec .results-growth-content .results-growth-content-box {
    margin-top: 20px;
    background-color: white;
    padding: 15px;
  }
  .results-growth-sec .results-growth-content .results-growth-content-box .results-growth-content-list li {
    line-height: 20px;
    font-size: 14px;
  }
  .future-prospects-sec .future-prospects-head .main-heading {
    font-size: 24px;
  }
  .future-prospects-sec .future-prospects-head {
    background-color: white;
    min-height: 300px;
    padding: 25px;
  }
  .future-prospects-sec .future-prospects-head .future-prospects-content-box {
    padding-top: 15px;
  }
  .future-prospects-sec .future-prospects-head .future-prospects-content-box .future-prospects-content-list li {
    line-height: 25px;
    font-size: 14px;
  }
  .future-prospects-sec .future-prospects-img {
    margin-left: 0%;
    margin-top: 2%;
  }
  .conclusion-sec .conclusion-sec-head .main-heading {
    font-size: 24px;
  }
  .conclusion-sec .conclusion-content-box {
    padding: 15px;
    margin-top: 10px;
  }
  .conclusion-sec .conclusion-content-box .desc {
    line-height: 25px;
    font-size: 16px;
  }
  .huddle-heart-banner-sec {
    min-height: 350px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content {
    margin-top: 5%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .mt-30 {
    margin-top: 15px;
  }
  .huddle-heart-banner-sec .case-study-banner-content img {
    width: 50%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .transform-button {
    color: white;
    background-color: #41b2e6;
    border: 1px solid #41b2e6;
    padding: 10px 24px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 14px;
  }
  .huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content h5 {
    font-size: 16px;
  }
  .huddle-heart-vision-sec .market-research-img img {
    width: 60%;
  }
  .huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content .para {
    font-size: 14px;
    line-height: 20px;
  }
  .huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content .number-area {
    font-size: 32px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }
  .huddle-heart-vision-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
  }
  .huddle-heart-vision-sec .market-research-head .main-heading {
    font-size: 20px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content img {
    width: 40%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .huddle-heart-intro-list li {
    font-size: 14px;
    line-height: 20px;
  }
  .huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content {
    padding: 10px;
    min-height: 300px;
    margin-bottom: 25px;
    border-bottom: 3px solid #35b6ff;
  }
  .huddle-heart-vision-sec .market-research-img {
    margin-top: 10%;
  }
  .huddle-heart-features-sec .pt-110 {
    padding-top: 0px;
  }
  .huddle-heart-features-sec .sec-title .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }
  .huddle-heart-features-sec .column {
    padding: 0px 10px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card {
    min-height: 320px;
    margin-bottom: 40px;
    padding: 15px 15px 40px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card .card-heading {
    font-weight: 600;
    font-size: 18px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card .desc {
    padding-top: 10px;
    font-size: 14px;
    line-height: 25px;
  }
  .enhanced-communication-sec .enhanced-communication-main-head .main-heading {
    font-size: 26px;
    padding-bottom: 15px;
  }
  .enhanced-communication-sec .enhanced-communication-card::before {
    display: none;
  }
  .enhanced-communication-sec .enhanced-communication-card {
    margin-top: 25%;
    padding: 15px;
    min-height: 325px;
    position: relative;
  }
  .enhanced-communication-sec .enhanced-communication-card .desc {
    line-height: 20px;
    font-size: 14px;
  }
  .enhanced-communication-sec .enhanced-img::after {
    display: none;
  }
  .enhanced-communication-sec .enhanced-img::before {
    display: none;
  }
  .enhanced-communication-sec .enhanced-img {
    width: 70%;
    min-height: 450px;
    position: relative;
    margin-bottom: 10%;
  }
  .enhanced-communication-card h5 {
    font-size: 18px;
  }
  .improved-safety-sec .huddle-heart-technology-head .main-heading {
    font-size: 26px;
    padding-top: 10px;
  }
  .huddle-heart-impacts-sec .huddle-heart-impacts-head .main-heading {
    font-size: 26px;
    padding-bottom: 10px;
  }
  .huddle-heart-impacts-sec .huddle-heart-impacts-card {
    padding: 10px;
    min-height: 356px;
    margin-bottom: 30px;
  }
  .huddle-heart-imapcts-img {
    margin-bottom: 0%;
    margin-top: 25%;
  }
  .huddle-heart-imapcts-img img {
    min-height: 260px;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-main-head .main-heading {
    font-size: 30px;
    padding-bottom: 10px;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-img {
    margin-top: 20%;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-content .challenges-lessons-card .challenges-lessons-icon {
    position: absolute;
    left: 0;
    top: -20%;
    right: 0;
    margin: 0 auto;
  }
  .challenges-lessons-huddle-heart .challenges-lessons-content .challenges-lessons-card {
    margin-bottom: 45px;
    padding: 30px 15px 15px 15px;
  }
  .profile-banner-sec .screen-mob {
    width: 100%;
    min-height: 650px;
  }
  .profile-banner-sec .screen-mob img {
    width: 96%;
  }
  .future-prospects-sec .business-model-main-head .main-heading {
    font-size: 26px;
    padding-bottom: 10px;
  }
  .future-prospects-sec .business-model-card .business-model-list-box .business-model-list li .card-head {
    font-size: 18px;
  }
  .future-prospects-sec .business-model-card {
    min-height: 300px;
    margin-bottom: 25px;
    padding: 10px 15px;
  }
  .plywood-bazar-banner-sec {
    min-height: 500px;
  }
  .plywood-bazar-banner-sec .plywood-bazar-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }
  .j-c-sb {
    justify-content: space-between;
  }
  .plywood-bazar-banner-sec .plywood-bazar-banner-content {
    margin-top: 5%;
  }
  .plywood-bazar-about-us .bg-shape-3 {
    position: absolute;
    bottom: 0%;
    right: 0%;
    text-align: end;
    display: none;
  }
  .plywood-bazar-about-us .bg-shape-1 img {
    width: 40%;
    display: none;
  }
  .plywood-bazar-about-us .bg-shape-2 img {
    width: 19%;
    display: none;
  }
  .plywood-bazar-banner-sec .plywood-bazar-banner-img {
    text-align: end;
    margin-top: 12%;
  }
  .plywood-bazar-about-us .about-content-box .main-heading {
    font-weight: 500;
    font-size: 28px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #212529;
  }
  .about-us-img img {
    width: 80%;
  }
  .plywood-bazar-about-us .about-content-box {
    margin-top: 5%;
  }
  .plywood-bazar-about-us .about-content-list-box .about-content-list li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    line-height: 25px;
  }
  .plywood-bazar-about-us .about-content-box .sub-heading {
    font-size: 16px;
  }
  .mission-or-vision-plywood .mission-vision-right-shape {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }
  .mission-or-vision-plywood .left-side-box {
    margin-bottom: 25px;
    padding: 15px;
  }
  .mission-or-vision-plywood .left-side-box .desc {
    font-size: 16px;
    line-height: 25px;
  }
  .mission-or-vision-plywood .mission-vision-left-shape {
    position: absolute;
    left: 0;
    bottom: 30px;
    display: none;
  }
  .mission-or-vision-plywood .mission-vision-wrapper::after {
    position: absolute;
    bottom: -27%;
  }
  .mission-or-vision-plywood .right-side-box {
    margin-top: 78%;
    padding: 15px;
  }
  .mission-or-vision-plywood .right-side-box .desc {
    font-size: 16px;
    line-height: 25px;
  }
  .our-client-requirement .about-client-area .main-head {
    font-size: 26px;
  }
  .our-client-requirement .about-client-area .desc {
    font-size: 16px;
    padding-top: 0%;
    line-height: 25px;
  }
  .our-client-requirement .client-req-area .main-head {
    font-size: 26px;
    font-weight: 500;
  }
  .our-client-requirement .client-req-area .client-req-list li {
    border-left: 3px solid #f7c553;
    margin-bottom: 15px;
    padding-left: 10px;
    font-size: 16px;
    line-height: 25px;
  }
  .solution-given-sec .solution-given-content .main-heading {
    color: #b08218;
    font-size: 22px;
    font-weight: 500;
  }
  .solution-given-sec .solution-given-img {
    text-align: center;
    margin-top: 30%;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li .heading {
    font-size: 20px;
    font-weight: 500;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li::marker {
    font-size: 20px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-img {
    margin-top: 30%;
  }
  .solution-given-sec .solution-given-content .sub-heading {
    color: #b08218;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 20px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .plywoodbazar-features-head .sub-heading {
    font-size: 14px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .plywoodbazar-features-head .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list {
    height: 480px;
    overflow-y: auto;
    padding-left: 30px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li {
    list-style-type: decimal;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  }
  .plywoodbazar-result-sec .plywoodbazar-result-main-head .desc {
    color: black;
    font-weight: 600;
    font-size: 14px;
    width: 80%;
    line-height: 20px;
    margin: 0 auto;
  }
  .plywoodbazar-result-sec .plywoodbazar-result-main-head .main-heading {
    font-size: 36px;
    color: #b08218;
    margin-bottom: 10px;
  }
  .our-work-screens .our-work-screens-main-head {
    position: absolute;
    width: 31%;
  }
  .our-work-screens .our-work-screens-main-head .main-heading {
    font-size: 34px;
  }
  .RoCare-banner-sec {
    min-height: 500px;
  }
  .RoCare-banner-sec .RoCare-banner-img {
    margin-top: 7%;
  }
  .RoCare-banner-sec .RoCare-banner-content .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .RoCare-banner-sec .RoCare-banner-content {
    margin-top: 4%;
  }
  .Rocare-aboutus .Rocare-aboutus-main-head {
    margin-top: 5%;
  }
  .client-requirements-sec .client-requirements-main-head {
    background-color: white;
    padding: 77px 15px 40px 15px;
  }
  .j-c-sb {
    justify-content: space-between;
  }
  .Rocare-features-sec .content-heading .features-heading {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .rocare-challenges-sec .rocare-challenges-content {
    margin-top: 12%;
  }
  .NikkyPore-banner-sec {
    min-height: 470px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .main-heading {
    font-size: 21px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .mt-30 {
    margin-top: 15px;
  }
  .Nikkypore-project-summary .pb-50 {
    padding-bottom: 10px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .sub-head {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .desc {
    font-size: 14px;
    line-height: 25px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content {
    margin-top: 4%;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-img {
    text-align: end;
    margin-top: 7%;
    animation: 4s ease-in-out infinite floatY;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content .transform-button {
    padding: 10px 24px;
    font-size: 15px;
  }
  .Nikkypore-project-summary .Nikkypore-main-head .main-heading {
    font-size: 24px;
  }
  .Nikkypore-goals-objectives .Nikkypore-goals-objectives-main-head .main-heading {
    font-size: 24px;
  }
  .Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card {
    padding: 10px;
    min-height: 168px;
    margin-bottom: 20px;
  }
  .Nikkypore-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
  }
  .Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card .desc {
    color: #000;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .mt-3 {
    margin-top: 0px !important;
  }
  .Nikkypore-features-sec .customer-app-features-content {
    margin-top: 0%;
  }
  .Nikkypore-features-sec .customer-app-features-content .white-box {
    padding: 10px;
    margin-bottom: 20px;
  }
  .Nikkypore-features-sec .customer-app-features-img {
    text-align: center;
  }
  .challenges-and-solutions-sec .challenges-sec .challenges-content-list li .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .challenges-and-solutions-sec .challenges-solutions-img {
    margin-top: 15%;
    text-align: center;
  }
  .challenges-and-solutions-sec .challenges-sec .challenges-content-list {
    margin-top: 0%;
  }
  .challenges-and-solutions-sec .challenges-solutions-content {
    margin-bottom: 4%;
  }
  .challenges-and-solutions-sec .challenges-sec h4 {
    font-size: 20px;
  }
  .challenges-and-solutions-sec .challenges-and-solutions-main-head .main-heading {
    font-size: 22px;
  }
  .visual-designs-sec .visual-designs-main-head .main-heading {
    font-size: 24px;
  }
  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide {
    margin: 0 auto;
    text-align: center;
  }
  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide img {
    width: 80%;
  }
  .visual-designs-sec .visual-designs-wrapper {
    padding: 0px;
  }
  .visual-designs-sec .visual-designs-wrapper::before {
    display: none;
  }
  .visual-designs-sec .visual-designs-main-head .desc {
    margin-bottom: 0px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content {
    padding-left: 0%;
    margin-top: 6%;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .desc {
    color: #222a41;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-main-banner-logo {
    margin-bottom: 15px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li {
    flex-basis: 50%;
    margin-top: 10px;
    font-size: 16px;
  }
  .deyor-main-banner-sec {
    min-height: 380px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li h5 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 25px;
  }
  .deyor-about-us .deyor-about-us-main-head .main-heading {
    font-size: 30px;
  }
  .deyor-about-us {
    padding: 20px 0px 20px 0px;
    min-height: 570px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box .aboutus-logo {
    position: absolute;
    width: 20%;
    right: -19%;
    top: 0;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box {
    position: relative;
    cursor: pointer;
    margin-bottom: 0%;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content {
    margin-top: 20%;
    cursor: pointer;
  }
  .deyor-features-sec .feature-user2 .deyor-features-img {
    text-align: center;
    margin-top: 5%;
  }
  .deyor-features-sec .feature-user3 .deyor-features-img {
    text-align: center;
    margin-top: 5%;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .main-heading {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .desc {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .deyor-features-sec .deyor-features-main-head .main-heading {
    font-size: 32px;
    font-weight: 600;
  }
  .deyor-features-sec .first-child-bg-img {
    display: none;
  }
  .deyor-features-sec .deyor-features-content {
    margin-top: 0%;
    padding-left: 1%;
    margin-left: 0px;
    background: #fff;
    z-index: 99;
    width: 100%;
    position: relative;
  }
  .deyor-features-sec .deyor-features-content .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .deyor-features-sec .deyor-features-content .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 7px;
    display: block;
    margin-bottom: 10px;
  }
  .deyor-features-sec .feature-user2 .second-child-bg-img {
    display: none;
  }
  .deyor-features-sec .feature-user3 {
    position: relative;
    padding-top: 25px;
  }
  .deyor-features-sec .deyor-features-content1 {
    margin-top: 10%;
  }
  .deyor-features-sec .deyor-features-content1 .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .deyor-features-sec .feature-user3 .third-child-bg-img {
    display: none;
  }
  .deyor-features-sec .feature-user2 {
    padding-top: 20px;
    position: relative;
  }
  .d-f-fd-cr {
    display: flex;
    flex-direction: column-reverse;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box {
    margin-top: 5%;
    margin-right: 0%;
    min-height: 400px;
    margin-bottom: 5%;
    padding: 65px 40px 50px;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .desc {
    font-size: 18px;
  }
  .deyor-challenges-sec .deyor-challenges-img {
    text-align: center;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .main-heading {
    font-size: 26px;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }
  .deyor-solutions-sec .deyor-solutions-main-head .main-heading {
    font-size: 26px;
  }
  .deyor-solutions-sec .deyor-solutions-img {
    text-align: center;
  }
  .deyor-solutions-sec .deyor-solutions-content-box {
    margin-top: 5%;
    margin-left: 0%;
    min-height: 400px;
    margin-bottom: 0%;
    padding: 65px 40px 50px;
  }
  .deyor-solutions-sec .deyor-solutions-content-box .desc {
    font-size: 18px;
  }
  .deyor-solutions-sec .deyor-solutions-content-box .main-heading {
    font-size: 26px;
  }
  .deyor-solutions-sec .deyor-solutions-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }
  .deyor-work-screens-sec .deyor-work-screens-main-head .main-heading {
    font-size: 30px;
  }
  .samagri-banner-sec .samagri-banner-shape img {
    position: absolute;
    width: 36%;
    z-index: 1;
    left: 35px;
  }
  .samagri-banner-sec .samagri-banner-content .main-heading {
    font-size: 26px;
    line-height: 35px;
  }
  .samagri-banner-sec .samagri-banner-content .desc {
    font-size: 18px;
    color: #413d2c;
    font-weight: 600;
    line-height: 30px;
  }
  .samagri-banner-sec .samagri-banner-content .desc1 {
    font-size: 16px;
    color: #413d2c;
    font-weight: 400;
    line-height: 25px;
  }
  .samagri-banner-sec .samagri-banner-content {
    margin-top: 10%;
    padding-bottom: 10%;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box {
    background-color: white;
    padding: 10px;
    justify-content: space-between;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li span svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li {
    display: flex;
    align-items: start;
    line-height: 35px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li span svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li {
    line-height: 35px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box {
    margin-top: 25%;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box .main-heading {
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 15px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box .desc {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-img-box .samagri-logo-img img {
    width: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle img {
    position: absolute;
    left: 40%;
    top: 160px;
    width: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle .circle-content {
    position: absolute;
    top: 46%;
    left: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle .circle-content .main-heading {
    font-size: 24px;
    font-weight: 600;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle .circle-content .main-heading span {
    display: block;
    font-size: 28px;
    color: #c48041;
  }
  .samagri-project-highlights {
    background-color: #b99471;
    min-height: 800px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(1) {
    position: absolute;
    left: -10%;
    top: -5%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(2) {
    position: absolute;
    top: 30%;
    left: 5%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(3) {
    position: absolute;
    top: 62%;
    left: 5%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(4) {
    position: absolute;
    top: 96%;
    left: -8%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(-24px 29px 25px rgba(12, 12, 13, 0.32));
    text-align: center;
    line-height: 75px;
    margin-bottom: 10px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-icon img {
    width: 45%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-area {
    padding-left: 0px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-area .desc {
    font-size: 14px;
    line-height: 25px;
    color: #fff;
  }
  .samagri-work-screens {
    position: relative;
    width: 100%;
    min-height: 350px;
  }
  .samagri-work-screens .samagri-work-screen-content .main-heading {
    font-size: 22px;
    font-weight: 600;
  }
  .samagri-work-screens .samagri-work-screen-content {
    margin-top: 0%;
  }
  .samagri-work-screens .samagri-work-screen-content .desc {
    line-height: 20px;
    font-size: 13px;
    width: 70%;
  }
  .samagri-features-sec .samagri-features-main-head {
    margin-top: 5%;
  }
  .samagri-features-sec .samagri-features-main-head .main-heading {
    color: black;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .samagri-features-sec .samagri-features-card {
    padding: 15px 10px;
    margin-bottom: 15px;
    min-height: 135px;
  }
  .samagri-features-sec .samagri-features-card .card-heading {
    font-size: 14px;
    margin-bottom: 0px !important;
  }
  .samagri-features-sec .samagri-features-img {
    margin-top: 15%;
  }
  .samagri-challenges-sec .samagri-challenges-main-head .main-heading {
    font-size: 22px;
    color: white;
  }
  .samagri-challenges-sec .samagri-challenges-content {
    margin-top: 0%;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card .samagri-challenges-card-heading .main-heading {
    text-align: center;
    font-size: 14px;
    color: white;
    padding: 10px;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card {
    padding: 5px;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card .desc {
    padding: 5px;
    line-height: 20px;
    font-size: 14px;
  }
  .samagri-challenges-sec .samagri-challenges-img {
    margin-bottom: 0%;
    margin-top: 15%;
  }
  .samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .main-heading {
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .desc {
    color: white;
    font-size: 14px;
    line-height: 20px;
  }
  .samagri-contact-banner {
    padding: 50px 0px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content {
    padding-left: 0%;
    margin-top: 5%;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .desc {
    color: #222a41;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-main-banner-logo {
    margin-bottom: 15px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li {
    flex-basis: 50%;
    margin-top: 10px;
    font-size: 16px;
  }
  .deyor-main-banner-sec {
    min-height: 350px;
  }
  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li h5 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 25px;
  }
  .deyor-about-us .deyor-about-us-main-head .main-heading {
    font-size: 26px;
    margin-bottom: 15px;
  }
  .deyor-about-us {
    padding: 20px 0px 20px 0px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box .aboutus-logo {
    position: absolute;
    width: 24.3%;
    right: 0;
    top: 0;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box {
    position: relative;
    cursor: pointer;
    margin-bottom: 80%;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content {
    margin-top: 2%;
    cursor: pointer;
  }
  .deyor-objective-sec .deyor-objective-content-box {
    margin-top: 12%;
  }
  .deyor-objective-sec .deyor-objective-content-box .deyor-objective-card .number-area {
    display: block;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
    color: #c4343c;
  }
  .deyor-objective-sec .deyor-objective-content-box .deyor-objective-card {
    padding: 10px;
    min-height: 170px;
    margin-bottom: 20px;
  }
  .deyor-objective-sec .deyor-objective-content-box .deyor-objective-card .desc1 {
    font-size: 14px;
    line-height: 20px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .main-heading {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .desc {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .deyor-about-us-content-list li {
    font-size: 18px;
    line-height: 30px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .deyor-about-us-content-list li .list-bar {
    position: absolute;
    left: 0;
    top: 15px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box {
    margin-top: 5%;
    background-color: white;
    padding: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
  }
  .deyor-methodology-sec .deyor-methodology-img {
    margin-top: 50%;
    text-align: center;
  }
  .deyor-methodology-sec .deyor-methodology-img .mt-10 {
    margin-top: 70%;
    width: 100%;
    text-align: center;
    margin-left: 0%;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box .deyor-features-content-list .content-list-li .deyor-features-inner-list li p {
    font-size: 14px;
    line-height: 20px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 .deyor-features-content-list .content-list-li .deyor-features-inner-list li p {
    font-size: 14px;
    line-height: 20px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .outcomes-box h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .outcomes-box-1 h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .outcomes-box .deyor-features-content-list .content-list-li .deyor-features-inner-list li p {
    font-size: 14px;
    line-height: 20px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .outcomes-box-1 .deyor-features-content-list .content-list-li .deyor-features-inner-list li p {
    font-size: 14px;
    line-height: 20px;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 {
    margin-top: 7%;
  }
  .deyor-methodology-sec .deyor-methodology-content-box .sub-heading {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
  }
  .deyor-challenges-sec .d-f-fd-cr-deyor {
    display: flex;
    flex-direction: row;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box {
    margin-top: 5%;
    margin-right: 0%;
    min-height: 430px;
    margin-bottom: 5%;
    padding: 45px 50px 40px;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .challenges-content-list li .challenges-inner-list li .desc {
    font-size: 14px;
    line-height: 20px;
  }
  .deyor-challenges-sec .deyor-challenges-img {
    text-align: center;
    margin-top: 10%;
  }
  .deyor-challenges-sec .deyor-challenges-img img {
    width: 100%;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .desc {
    font-size: 18px;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .main-heading {
    font-size: 26px;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }
  .deyor-solutions-sec .d-f-fd-cr-deyor {
    display: flex;
    flex-direction: row;
  }
  .deyor-solutions-sec .deyor-solutions-main-head .main-heading {
    font-size: 26px;
  }
  .deyor-solutions-sec .deyor-solutions-img {
    text-align: center;
  }
  .deyor-solutions-sec .deyor-solutions-img img {
    width: 80%;
  }
  .deyor-challenges-sec .deyor-challenges-wrap-box .deyor-challenges-content-box .challenges-content-list {
    margin-top: 5%;
  }
  .deyor-solutions-sec .deyor-solutions-content-box {
    margin-top: 5%;
    margin-left: 0%;
    min-height: 400px;
    margin-bottom: 0%;
    padding: 50px 50px 50px;
  }
  .deyor-solutions-sec .deyor-solutions-content-box .desc {
    font-size: 18px;
  }
  .deyor-solutions-sec .deyor-solutions-content-box .main-heading {
    font-size: 26px;
  }
  .deyor-solutions-sec .deyor-solutions-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }
  .deyor-work-screens-sec .deyor-work-screens-main-head .main-heading {
    font-size: 24px;
  }
  .turning-point-banner-sec {
    min-height: 450px;
  }
  .turning-point-banner-sec .turning-point-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 15px;
  }
  .turning-point-banner-sec .turning-point-banner-content {
    margin-top: 5%;
  }
  .turning-point-banner-sec .turning-point-banner-img img {
    margin-top: 10%;
  }
  .turning-point-banner-sec .turning-point-banner-content .transform-button {
    padding: 10px 24px;
    font-weight: 500;
  }
  .turning-point-about-client-sec .turning-point-about-client-content-box {
    padding-left: 20px;
    padding-top: 8%;
    position: relative;
  }
  .turning-point-about-client-sec .turning-point-about-client-main-head .main-heading1 {
    font-size: 26px;
    font-weight: 600;
    position: relative;
    margin-bottom: 10px;
  }
  .turning-point-about-client-sec .turning-point-about-client-img {
    margin-top: 2%;
  }
  .turning-point-project-highlights-sec .turning-point-project-highlights-head .main-heading {
    font-size: 26px;
    padding-bottom: 10px;
  }
  .turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card .turning-point-project-highlights-card-content .content-txt h5 {
    font-size: 17px;
  }
  .turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card-style1 .turning-point-project-highlights-card-content .content-txt h5 {
    font-size: 17px;
  }
  .turning-point-features-sec .customer-app-features-content {
    margin-top: 0%;
  }
  .turning-point-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .turning-point-features-sec .customer-app-features-content .white-box {
    min-height: 177px;
  }
  .turning-point-features-sec .position-relative-z-index-1 .customer-app-features-img {
    text-align: center;
    margin-top: 25%;
  }
  .turning-point-challenges-and-solutions-sec .challenges-solutions-img {
    text-align: center;
    margin-top: 25%;
  }
  .turning-point-challenges-and-solutions-sec .challenges-and-solutions-main-head .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .turning-point-challenges-and-solutions-sec .challenges-sec {
    margin-top: 5%;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-main-head .main-heading {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper::before {
    display: none;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper .turning-point-visual-designs-slide img {
    width: 65%;
  }
  .turning-point-conclusion-sec .turning-point-conclusion-sec-head .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .turning-point-conclusion-sec .turning-point-conclusion-content-box .desc {
    font-size: 15px;
    line-height: 25px;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper {
    background-color: #f0686c;
    padding: 20px 0px 20px;
    position: relative;
  }
  .main-blog .container-fluid {
    padding: 0px 2pc;
  }
  .homebannersectoin {
    padding: 25px 2pc;
  }
  .pt-80 {
    padding-top: 40px !important;
  }
  .homebannersectoin h3 {
    font-size: 24px;
    font-weight: 600;
    padding: 0px 0;
  }
  .homebannersectoin h4 {
    color: #f8b418;
    font-size: 26px;
  }
  .homebannersectoin p {
    font-size: 15px;
    width: 100%;
    color: #000;
    font-weight: 600;
    margin: 15px 0;
  }
  .newportfolyo .card_portfoliyo .portfolicardview::before {
    height: 70vh;
  }
  .newportfolyo .card_portfoliyo .text_left {
    margin-left: 1%;
    text-align: end;
  }
  .newportfolyo .leftsidecontnt .text_right {
    width: 100%;
  }
  .newportfolyo .card_portfoliyo .portfolicardview img {
    width: 100%;
    z-index: 999;
    position: relative;
    margin-top: 19%;
  }
  h1.heading {
    font-size: 28px !important;
    line-height: 30px !important;
  }
  .pb-80 {
    padding-bottom: 20px !important;
  }
  .mb-80 {
    margin-bottom: 30px !important;
  }
  .services_list li {
    list-style: disc;
    font-size: 13px;
    margin-bottom: 7px;
    line-height: 20px;
  }
  .worksflosection .box {
    padding: 15px 10px;
    min-height: 285px;
  }
  .worksflosection .process-icon {
    height: 70px;
    margin: 0 auto 15px;
  }
  .worksflosection h4 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .worksflosection p {
    font-size: 14px;
    line-height: 20px;
  }
  .in_expertise_s .box_indstr p {
    color: #222;
    font-size: 13px;
    font-weight: 600;
  }
  .in_expertise_s .box_indstr {
    margin-bottom: 15px;
    padding: 15px 0;
  }
  .our-portfolio-section-container .our_port_section .appent_left_section .slider_portfliucontent {
    width: 90%;
    padding-top: 0%;
  }
  .our-portfolio-section-container .our_port_section .appent_left_section {
    height: 60vh;
  }
  .our-portfolio-section-container .our_port_section .appent_right_section {
    height: 60vh;
  }
  .our-portfolio-section-container .our_port_section {
    height: 60vh !important;
    background-color: #ccc;
  }
  .ptb-80 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .appent_left_section .slider_portfliucontent h3 {
    color: #fff;
    font-size: 40px;
  }
  .appent_left_section .slider_portfliucontent p {
    color: #fff;
    font-size: 20px;
    margin-bottom: 25px;
    line-height: 35px;
  }
  .tabs_customersays .container-fluid {
    padding: 0px 2pc !important;
  }
  .mb-5 {
    margin-bottom: 2rem !important;
  }
  .testimontab_body {
    background-color: #fafafa;
    min-height: 476px;
    padding: 40px;
    position: relative;
  }
  .our-portfolio-section-container .our_port_section .appent_right_section .centermockupimg {
    position: absolute;
    top: 10%;
    margin: auto;
    right: 36%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1536px) {
  .navbar_top.navbar {
    padding: 5px 12px !important;
  }
  .bootom-bar .navbar-nav .nav-link {
    padding: 15px 12px !important;
  }
  .homebannersectoin {
    padding: 20px 1pc !important;
  }
  .homebannersectoin h1 {
    font-size: 28px;
    padding: 0px;
  }
  .homebannersectoin h2 {
    font-size: 28px;
  }
  .homebannersectoin p {
    font-size: 15px;
    width: 100%;
    margin: 15px 0;
  }
  .homebannersectoin .home_banner_img {
    width: 430px;
    height: 430px;
  }
  .submit_buttn {
    padding: 10px 32px !important;
    font-size: 16px !important;
    border-radius: 5px !important;
  }
  .services-swiper ul.tabs li {
    padding: 10px 1pc !important;
  }
  .services-swiper .tab-description ul li {
    font-size: 15px !important;
  }
  .ptb-80 {
    padding: 30px 0px !important;
  }
  .mb-80 {
    margin-bottom: 30px !important;
  }
  .pt-80 {
    padding-top: 30px !important;
  }
  h1.heading {
    font-size: 30px !important;
    line-height: 40px !important;
  }
  .our-clients .marquee-items.left .items_logo {
    width: 100%;
    height: 150px;
    padding: 10px;
  }
  .consultation .row {
    padding: 1.6rem !important;
  }
  .consultation .left {
    padding: 0pc !important;
  }
  .df_jcsb {
    display: inline !important;
  }
  .form.row {
    padding: 0px !important;
  }
  .right.h-100 {
    height: 80% !important;
  }
  h3.heading {
    font-size: 34px !important;
    line-height: 40px !important;
  }
  .worksflosection .box {
    min-height: 260px !important;
  }
  .worksflosection .process-icon {
    height: 60px;
    margin: 0 auto 18px;
    width: 60px;
    font-size: 1.6rem;
  }
  .worksflosection h4 {
    font-size: 16px !important;
    margin-bottom: 10px !important;
  }
  .worksflosection p {
    font-size: 13px !important;
  }
  .blog .blog-box p {
    font-size: 13px;
    line-height: 20px;
  }
  .blog-box ul.tags li {
    font-size: 12px !important;
  }
  .blog-box h4 {
    margin: 5px 0 !important;
    font-size: 18px !important;
    font-weight: 600;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1367px) {
  .crm-banner-sec .Crm-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 40%;
    left: 7%;
  }
  .why-you-need-card {
    min-height: 370px;
  }
  .manufacturing-banner-sec .manufacturing-banner-content .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .crm-banner-sec .Crm-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 500;
  }
  .manufacturing-banner-sec .manufacturing-banner-content {
    position: absolute;
    z-index: 2;
    top: 60%;
    width: 35%;
    left: 7%;
  }
  .possible-channels-sec .possible-channels-tab-panes .nav-tabs .nav-item button {
    background-color: #e8ebf1;
    border-radius: 50px;
    color: #020202;
    padding: 8px 20px !important;
    margin: 0px 20px;
    font-weight: 600;
  }
  .fntch_fnl_inn .develmntser h3 {
    font-size: 30px;
    font-weight: 600;
    color: white;
  }
  .why-you-need-whatsappbot .why-you-need-whatsappbot-card {
    padding: 25px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1607843137);
    border-radius: 8px;
    background-color: white;
    text-align: center;
    margin-bottom: 25px;
    min-height: 222px;
  }
  .whatsapp-bot-about-us .whatsapp-bot-about-us-head {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .crm-development-services-sec .col-xl-6:nth-child(2) {
    margin-top: 50px;
  }
  .crm-development-services-sec .col-xl-6:nth-child(3) {
    margin-top: -35px;
  }
  .crm-development-services-card .crm-development-services-icon img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    margin-bottom: 0px;
  }
  .crm-development-services-card {
    min-height: 376px;
  }
  .society-mt-banner-sec .society-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 35%;
    width: 35%;
    left: 7%;
  }
  .society-mt-banner-sec .society-mt-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .school-mt-banner-sec .school-mt-banner-content .main-heading {
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .school-mt-banner-sec .school-mt-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    font-size: 17px;
  }
  .school-mt-banner-sec .school-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 35%;
    left: 7%;
  }
  .sch-software-process-tabs .nav-tabs .nav-link {
    color: #000;
    background: linear-gradient(188.28deg, #ffffff 22.17%, #f6f6f7 116.15%);
    box-shadow: 0px 3px 6px rgba(0, 0, 128, 0.1);
    border-radius: 20px;
    width: 120px;
    min-height: 90px;
    padding: 10px;
    font-size: 14px;
    margin: 50px 5px 0px 5px;
    line-height: 20px;
    font-weight: 600;
    transform: scale(1) translateY(0%);
    transition: all 0.5s ease;
  }
  .sch-software-process-tabs .step-triangle .step-triangle-list li {
    width: 158.5px;
    margin-left: 5px;
  }
  .portfolio-left-area .quote {
    position: absolute;
    bottom: 4%;
    left: 12%;
    color: white;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
  }
  .portfolio-left-area {
    width: 42.6%;
  }
  .explore-more-button {
    font-size: 20px;
  }
  .travel-choose-card .heading-part h4 {
    font-size: 16px;
  }
  .travel-banner-content {
    position: absolute;
    top: 50%;
    left: 5%;
    z-index: 1;
    width: 35%;
  }
  .travel-banner-content h2 {
    font-size: 26px;
  }
  .travel-banner-content .desc {
    margin-top: 10px;
    font-size: 18px;
  }
  .job-features-tab-pane .co-features {
    position: absolute;
    left: 29%;
    top: 2.5%;
    z-index: 99999;
  }
  .developmentsecib .cardapp {
    padding: 40px 30px;
    margin-bottom: 20px;
    border-radius: 20px;
    min-height: 597px;
  }
  .freaturesectionapp img {
    margin-top: 60%;
    width: 100%;
  }
  .freaturesectionapp li {
    padding: 18px;
    margin-bottom: 30px;
    border-radius: 20px;
    border: 1px solid #d1d1d1;
    transition: all 0.5s;
    min-height: 200px;
  }
  .list_feature li p {
    font-size: 14px;
  }
  .premium_section .list_feature li .fe_hd h2 {
    font-size: 17px;
    margin-bottom: 0;
    font-weight: 600;
    margin-left: 0px;
  }
  .premium_section .list_feature li p {
    color: #000;
    font-size: 15px;
    line-height: 20px;
  }
  .premium_section .list_feature li {
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 20px;
    border: 1px solid #d1d1d1;
    transition: all 0.5s;
    min-height: 215px;
  }
  .premium_section .list_feature li .fe_hd {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
  }
  .retail-pos-banner-sec .retail-pos-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .retail-pos-banner-sec .retail-pos-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 35%;
    left: 7%;
  }
  .real-est-banner-sec .real-est-banner-content .main-heading {
    color: white;
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .real-est-banner-sec .real-est-banner-content {
    position: absolute;
    z-index: 2;
    top: 45%;
    width: 30%;
    left: 7%;
  }
  .app-development-features-img {
    margin-top: 40%;
    text-align: center;
  }
  .py-40 {
    padding: 20px 0px;
  }
  .real-est-mngmnt-system .real-est-mngmnt-content .desc {
    color: white;
    font-size: 18px;
    margin: 10px 0px 20px 0px;
  }
  .explore-erp-development-list li {
    margin-bottom: 15px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }
  .erp-portfolio-area .erp-portfolio-left-side {
    background-color: rgb(215, 164, 64);
    padding: 60px 40px 20px 40px;
  }
  .case-study-banner-sec .case-study-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 80%;
    left: 5%;
  }
  .case-study-banner-sec .case-study-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .case-study-banner-sec .case-study-banner-content .breadcrumb-casestudy-box {
    margin-bottom: 5px;
  }
  .case-study-banner-sec .case-study-banner-content .desc {
    font-size: 16px;
    line-height: 20px;
  }
  .about-client-sec .about-client-content-box .desc {
    font-size: 17px;
    line-height: 25px;
  }
  .about-client-sec .about-client-main-head .main-heading {
    font-size: 22px;
  }
  .about-client-sec .about-client-content-box {
    padding: 15px;
    position: relative;
    margin-top: 10%;
  }
  .about-client-sec .about-client-content-box .right-quote {
    position: absolute;
    left: 5%;
    top: 0%;
    width: 10%;
  }
  .about-client-sec .about-client-main-head .main-heading1 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-bottom: 5px;
  }
  .about-client-sec .about-client-content-box .left-quote {
    position: absolute;
    right: 5%;
    bottom: 2%;
    width: 10%;
  }
  .business-model-sec .business-model-main-head .main-heading {
    font-size: 22px;
  }
  .business-model-sec .business-model-main-head .desc {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
  .business-model-sec .business-model-card {
    padding: 15px;
    border-radius: 5px;
    min-height: 200px;
    cursor: pointer;
    margin-bottom: 25px;
  }
  .market-research-sec .market-research-head .main-heading {
    font-size: 22px;
  }
  .market-research-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
  }
  .market-research-sec .market-research-head .market-research-content-box .market-research-content {
    padding: 15px;
    min-height: 320px;
    margin-bottom: 25px;
  }
  .market-research-sec .market-research-img {
    margin-top: 30px;
  }
  .technology-sec .technology-content-area .technology-card .technology-card-content {
    border-top: 1px solid #e5e5e5;
    padding-top: 80px;
  }
  .market-research-sec .market-research-head .market-research-content .market-research-content-list li {
    position: relative;
    padding-left: 35px;
    line-height: 25px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .challenges-and-solutions-sec .challenges-solutions-content .challenges-solutions-card .number-card {
    content: "";
    position: absolute;
    left: -9%;
  }
  .market-research-sec .market-research-head .market-research-content h5 {
    font-size: 20px;
  }
  .market-research-sec .market-research-head .market-research-content {
    padding-top: 20px;
    margin-bottom: 35px;
  }
  .technology-sec .technology-head .main-heading {
    font-size: 24px;
  }
  .technology-sec .technology-head .desc {
    font-size: 14px;
    margin-top: 15px;
  }
  .technology-sec .technology-head .market-research-content h5 {
    font-size: 16px;
  }
  .technology-sec .technology-head .market-research-content {
    padding-top: 20px;
  }
  .technology-sec .technology-head .market-research-content .market-research-content-list li {
    position: relative;
    padding-left: 35px;
    line-height: 20px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .challenges-and-solutions-sec .challenges-solutions-main-head .main-heading {
    font-size: 24px;
    padding-bottom: 15px;
  }
  .challenges-and-solutions-sec .challenges-solutions-main-head {
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .challenges-and-solutions-sec .challenges-and-solutions-img img {
    margin-left: 0%;
  }
  .results-growth-sec .results-growth-head .main-heading {
    font-size: 24px;
  }
  .results-growth-sec .results-growth-content .results-growth-content-box {
    margin-top: 20px;
    background-color: white;
    padding: 15px;
  }
  .results-growth-sec .results-growth-content .results-growth-content-box .results-growth-content-list li {
    line-height: 20px;
    font-size: 14px;
  }
  .future-prospects-sec .future-prospects-head .main-heading {
    font-size: 24px;
  }
  .future-prospects-sec .future-prospects-head {
    background-color: white;
    min-height: 300px;
    padding: 25px;
  }
  .future-prospects-sec .future-prospects-head .future-prospects-content-box {
    padding-top: 15px;
  }
  .future-prospects-sec .future-prospects-head .future-prospects-content-box .future-prospects-content-list li {
    line-height: 25px;
    font-size: 14px;
  }
  .future-prospects-sec .future-prospects-img {
    margin-left: 0%;
    margin-top: 2%;
  }
  .conclusion-sec .conclusion-sec-head .main-heading {
    font-size: 24px;
  }
  .conclusion-sec .conclusion-content-box {
    padding: 15px;
    margin-top: 10px;
  }
  .conclusion-sec .conclusion-content-box .desc {
    line-height: 25px;
    font-size: 16px;
  }
  .future-prospects-sec {
    background-color: rgba(231, 231, 231, 0.7607843137);
    min-height: auto;
    width: 100%;
  }
  .profile-banner-sec .screen-mob {
    width: 80%;
    min-height: 620px;
  }
  .screen-mob img {
    width: 96%;
  }
  .huddle-heart-imapcts-img {
    margin-top: 15%;
  }
  .huddle-heart-imapcts-img img {
    min-height: 270px;
  }
  .improved-safety-sec .technology-content-area .technology-card .huddle-heart-technology-card-content .content-txt .desc {
    line-height: 20px;
    font-size: 14px;
  }
  .technology-card-content .content-txt h5 {
    padding: 5px 0px;
    font-size: 16px;
  }
  .huddle-heart-technology-card-content .content-txt h5 {
    padding: 5px 0px;
    font-size: 16px;
  }
  .enhanced-communication-sec .enhanced-communication-main-head .main-heading {
    font-size: 26px;
    padding-bottom: 15px;
  }
  .enhanced-communication-sec .enhanced-communication-card::before {
    display: none;
  }
  .enhanced-communication-sec .enhanced-communication-card {
    margin-top: 30%;
    padding: 15px;
    min-height: 325px;
    position: relative;
  }
  .enhanced-communication-sec .enhanced-communication-card .desc {
    line-height: 20px;
    font-size: 14px;
  }
  .enhanced-communication-sec .enhanced-img::after {
    display: none;
  }
  .enhanced-communication-sec .enhanced-img::before {
    display: none;
  }
  .enhanced-communication-sec .enhanced-img {
    width: 70%;
    min-height: 530px;
    position: relative;
    margin-bottom: 10%;
  }
  .huddle-heart-features-sec .sec-title .main-heading {
    font-size: 24px;
    padding-bottom: 10px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card {
    min-height: 320px;
    margin-bottom: 40px;
    padding: 15px 15px 40px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card .card-heading {
    font-weight: 600;
    font-size: 18px;
  }
  .huddle-heart-features-sec .huddle-heart-features-card .desc {
    padding-top: 10px;
    font-size: 14px;
    line-height: 25px;
  }
  .huddle-heart-vision-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
  }
  .huddle-heart-vision-sec .market-research-head .main-heading {
    font-size: 20px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content img {
    width: 40%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .huddle-heart-intro-list li {
    font-size: 14px;
    line-height: 20px;
  }
  .huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content {
    padding: 10px;
    min-height: 300px;
    margin-bottom: 25px;
    border-bottom: 3px solid #35b6ff;
  }
  .huddle-heart-vision-sec .market-research-img {
    margin-top: 10%;
  }
  .huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content h5 {
    font-size: 16px;
  }
  .huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content .para {
    font-size: 14px;
    line-height: 20px;
  }
  .huddle-heart-vision-sec .market-research-head .market-research-content-box .market-research-content .number-area {
    font-size: 32px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content {
    margin-top: 10%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .mt-30 {
    margin-top: 15px;
  }
  .huddle-heart-banner-sec .case-study-banner-content img {
    width: 50%;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .transform-button {
    color: white;
    background-color: #41b2e6;
    border: 1px solid #41b2e6;
    padding: 10px 24px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 14px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }
  .huddle-heart-banner-sec .huddle-heart-banner-img img {
    margin-top: 5%;
    width: 70%;
  }
  .plywood-bazar-banner-sec .plywood-bazar-banner-img {
    text-align: end;
    margin-top: 8%;
  }
  .plywood-bazar-about-us .bg-shape-1 {
    position: absolute;
    top: 0;
    left: 0%;
    display: none;
  }
  .plywood-bazar-about-us .bg-shape-2 {
    position: absolute;
    bottom: 0;
    left: 0%;
    display: none;
  }
  .plywood-bazar-about-us .bg-shape-3 {
    position: absolute;
    bottom: 0%;
    right: 0%;
    text-align: end;
    display: none;
  }
  .mission-or-vision-plywood .mission-vision-left-shape {
    position: absolute;
    left: 0;
    bottom: 30px;
    display: none;
  }
  .mission-or-vision-plywood .mission-vision-right-shape {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }
  .mission-or-vision-plywood .mission-vision-wrapper::after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -98px;
  }
  .plywoodbazar-features-sec .plywoodbazar-features-content .key-features-list-box .key-features-list li {
    list-style-type: decimal;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  }
  .RoCare-banner-sec .RoCare-banner-content .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .client-requirements-sec .client-requirements-main-head {
    padding: 100px 50px 50px 50px;
  }
  .Rocare-aboutus .Rocare-aboutus-main-head {
    text-align: center;
    margin-top: 4%;
  }
  .NikkyPore-banner-sec {
    min-height: 570px;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-content {
    margin-top: 5%;
  }
  .NikkyPore-banner-sec .NikkyPore-banner-img {
    margin-top: 9%;
  }
  .Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card {
    padding: 10px;
    min-height: 125px;
    margin-bottom: 20px;
  }
  .Nikkypore-goals-objectives .goals-obj-content-box {
    margin-top: 25%;
  }
  .mt-3 {
    margin-top: 0px !important;
  }
  .challenges-and-solutions-sec .challenges-and-solutions-main-head .main-heading {
    font-size: 26px;
  }
  .challenges-and-solutions-sec .challenges-sec h4 {
    font-size: 20px;
  }
  .challenges-and-solutions-sec .challenges-solutions-img {
    margin-top: 10%;
  }
  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide img {
    width: 80%;
  }
  .visual-designs-sec .visual-designs-wrapper {
    background-color: #4192a7;
    padding: 0px;
    position: relative;
  }
  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide {
    text-align: center;
    margin: 0 auto;
  }
  .visual-designs-sec .visual-designs-wrapper::before {
    display: none;
  }
  .deyor-about-us {
    background-color: #f5f5f5;
    padding: 35px 0px 160px 0px;
  }
  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box .aboutus-logo {
    position: absolute;
    width: 18%;
    right: -14%;
    top: 0;
  }
  .deyor-features-sec .feature-user3 .third-child-bg-img {
    position: absolute;
    left: 5%;
    top: -23%;
  }
  .samagri-banner-sec .samagri-banner-shape img {
    position: absolute;
    width: 36%;
    z-index: 1;
    left: 35px;
  }
  .samagri-banner-sec .samagri-banner-content .main-heading {
    font-size: 26px;
    line-height: 35px;
  }
  .samagri-banner-sec .samagri-banner-content .desc {
    font-size: 18px;
    color: #413d2c;
    font-weight: 600;
    line-height: 30px;
  }
  .samagri-banner-sec .samagri-banner-content .desc1 {
    font-size: 16px;
    color: #413d2c;
    font-weight: 400;
    line-height: 25px;
  }
  .samagri-banner-sec .samagri-banner-content {
    margin-top: 10%;
    padding-bottom: 10%;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box {
    background-color: white;
    padding: 10px;
    justify-content: space-between;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li span svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li {
    display: flex;
    align-items: start;
    line-height: 35px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-client-content-list li .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li span svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box .aboutus-our-content .aboutus-our-content-list li {
    line-height: 35px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box {
    margin-top: 25%;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box .main-heading {
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 15px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box .desc {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-img-box .samagri-logo-img img {
    width: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle img {
    position: absolute;
    left: 40%;
    top: 200px;
    width: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle .circle-content {
    position: absolute;
    top: 46%;
    left: 60%;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle .circle-content .main-heading {
    font-size: 28px;
    font-weight: 600;
  }
  .samagri-project-highlights .samagri-project-highlights-img .circle .circle-content .main-heading span {
    display: block;
    font-size: 32px;
    color: #c48041;
  }
  .samagri-project-highlights {
    background-color: #b99471;
    min-height: 800px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(1) {
    position: absolute;
    left: -10%;
    top: -5%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(2) {
    position: absolute;
    top: 30%;
    left: 5%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(3) {
    position: absolute;
    top: 62%;
    left: 5%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list:nth-child(4) {
    position: absolute;
    top: 96%;
    left: -8%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(-24px 29px 25px rgba(12, 12, 13, 0.32));
    text-align: center;
    line-height: 75px;
    margin-bottom: 10px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-icon img {
    width: 45%;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-area {
    padding-left: 0px;
  }
  .samagri-project-highlights .samagri-project-highlights-content .project-highlights-content-list .project-highlights-content-area .desc {
    font-size: 14px;
    line-height: 25px;
    color: #fff;
  }
  .samagri-work-screens {
    position: relative;
    width: 100%;
    min-height: 350px;
  }
  .samagri-work-screens .samagri-work-screen-content .main-heading {
    font-size: 22px;
    font-weight: 600;
  }
  .samagri-work-screens .samagri-work-screen-content {
    margin-top: 0%;
  }
  .samagri-work-screens .samagri-work-screen-content .desc {
    line-height: 20px;
    font-size: 13px;
    width: 70%;
  }
  .samagri-features-sec .samagri-features-main-head {
    margin-top: 5%;
  }
  .samagri-features-sec .samagri-features-main-head .main-heading {
    color: black;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .samagri-features-sec .samagri-features-card {
    padding: 15px 10px;
    margin-bottom: 15px;
    min-height: 135px;
  }
  .samagri-features-sec .samagri-features-card .card-heading {
    font-size: 14px;
    margin-bottom: 0px !important;
  }
  .samagri-features-sec .samagri-features-img {
    margin-top: 15%;
  }
  .samagri-challenges-sec .samagri-challenges-main-head .main-heading {
    font-size: 22px;
    color: white;
  }
  .samagri-challenges-sec .samagri-challenges-content {
    margin-top: 0%;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card .samagri-challenges-card-heading .main-heading {
    text-align: center;
    font-size: 14px;
    color: white;
    padding: 10px;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card {
    padding: 5px;
  }
  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card .desc {
    padding: 5px;
    line-height: 20px;
    font-size: 14px;
  }
  .samagri-challenges-sec .samagri-challenges-img {
    margin-bottom: 0%;
    margin-top: 15%;
  }
  .samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .main-heading {
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .samagri-contact-banner .position-relative-z-index-1 .samagri-contact-banner-content .desc {
    color: white;
    font-size: 14px;
    line-height: 20px;
  }
  .samagri-contact-banner {
    padding: 50px 0px;
  }
  .turning-point-banner-sec .turning-point-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 15px;
  }
  .turning-point-banner-sec .turning-point-banner-content {
    margin-top: 10%;
  }
  .turning-point-banner-sec .turning-point-banner-img img {
    margin-top: 5%;
  }
  .turning-point-banner-sec .turning-point-banner-content .transform-button {
    padding: 10px 24px;
    font-weight: 500;
  }
  .turning-point-about-client-sec .turning-point-about-client-content-box {
    padding-left: 20px;
    padding-top: 8%;
    position: relative;
  }
  .turning-point-about-client-sec .turning-point-about-client-main-head .main-heading1 {
    font-size: 26px;
    font-weight: 600;
    position: relative;
    margin-bottom: 10px;
  }
  .turning-point-about-client-sec .turning-point-about-client-img {
    margin-top: 2%;
  }
  .turning-point-project-highlights-sec .turning-point-project-highlights-head .main-heading {
    font-size: 26px;
    padding-bottom: 10px;
  }
  .turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card .turning-point-project-highlights-card-content .content-txt h5 {
    font-size: 17px;
  }
  .turning-point-project-highlights-sec .project-highlights-content-area .project-highlights-card-style1 .turning-point-project-highlights-card-content .content-txt h5 {
    font-size: 17px;
  }
  .turning-point-features-sec .customer-app-features-content {
    margin-top: 0%;
  }
  .turning-point-features-sec .features-sec-main-head .main-heading {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .turning-point-features-sec .customer-app-features-content .white-box {
    min-height: 177px;
  }
  .turning-point-features-sec .position-relative-z-index-1 .customer-app-features-img {
    text-align: center;
    margin-top: 25%;
  }
  .turning-point-challenges-and-solutions-sec .challenges-solutions-img {
    text-align: center;
    margin-top: 5%;
  }
  .turning-point-challenges-and-solutions-sec .challenges-and-solutions-main-head .main-heading {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .turning-point-challenges-and-solutions-sec .challenges-sec {
    margin-top: 5%;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-main-head .main-heading {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper::before {
    display: none;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper .turning-point-visual-designs-slide img {
    width: 65%;
  }
  .turning-point-conclusion-sec .turning-point-conclusion-sec-head .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper {
    background-color: #f0686c;
    padding: 20px 0px 20px;
    position: relative;
  }
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #797979;
  line-height: 1.7em;
  font-weight: 400;
  background: #fff;
  overflow-x: hidden;
  position: relative;
}

.a-i-c {
  display: flex;
  align-items: center;
}

.mb-80 {
  margin-bottom: 60px;
}

.mt-80 {
  margin-top: 60px;
}

.ptb-80 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ptb-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pt-80 {
  padding-top: 60px;
}

.pb-80 {
  padding-bottom: 60px;
}

a:hover {
  color: #000 !important;
}

.btn:focus {
  box-shadow: none;
}
.btn-custom {
  font-weight: 500;
  --bs-btn-padding-x: 1pc;
  --bs-btn-padding-y: 10px;
}
.btn-hover {
  position: relative;
  overflow: hidden;
}
.btn-hover:before {
  position: absolute;
  background: rgba(255, 255, 255, 0.5019607843);
  height: 100%;
  width: 100%;
  top: 0;
  left: 100%;
  content: "";
  border-radius: 3px;
  transition: all 0.5s;
}
.btn-hover:hover:before {
  transition: all 0.5s;
  left: 0;
  transform: translateX(-100%);
}
.btn-blue {
  background-color: #092e40;
  color: #fff;
  transition: all 0.5s;
}
.btn-blue:hover {
  background-color: #000 !important;
  color: #fff !important;
  transition: all 0.5s;
}
.btn-black {
  background-color: #363636;
  color: #fff;
}
.btn-black:hover {
  background-color: #363636 !important;
  color: #fff !important;
}

.roboto {
  font-family: "Open Sans", sans-serif !important;
}

.poppin {
  font-family: "Poppins", sans-serif !important;
}

.g-10 {
  gap: 10px;
}

.blue {
  color: #092e40 !important;
}

.blue-bg {
  background-color: #092e40 !important;
}

.yellow {
  color: #fcaf17 !important;
}

.yellow-bg {
  background-color: #fcaf17 !important;
}

.gray-bg {
  background-color: #fdfdfd !important;
}

.fs-15 {
  font-size: 15px;
}

.list-circle > li {
  list-style-type: circle;
  list-style-position: inside;
}
.list-circle > li::marker {
  color: #fcaf17;
}

.flex-1 {
  flex: 1;
}

h1.heading {
  font-size: 40px;
  line-height: 47px;
}

h2.heading {
  font-size: 34px;
  line-height: 45px;
}

h3.heading {
  font-size: 34px;
  line-height: 40px;
}

h4.heading {
  font-size: 22px;
  line-height: 32px;
}

h5.heading {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

h6.heading {
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
}

.heading {
  font-weight: 600;
}

.title-section .heading {
  text-transform: capitalize;
  font-weight: 600;
}
.title-section .sub {
  width: -moz-max-content;
  width: max-content;
  position: relative;
  letter-spacing: 3px;
}

p {
  line-height: 1.8em;
  margin-bottom: 0px;
}
p:last-child {
  margin-bottom: 0;
}

.img-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.img-contain {
  -o-object-fit: contain;
     object-fit: contain;
}

.img-position-top {
  -o-object-position: top;
     object-position: top;
}

.img-fill {
  -o-object-fit: fill;
     object-fit: fill;
}

a {
  text-decoration: none;
  color: inherit;
  transition: all 0.5s;
}
a:hover {
  transition: all 0.5s;
  color: #fcaf17;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

.pb-60 {
  padding-bottom: 60px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-family: "Poppins", sans-serif;
}

.btn-sm {
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.main-logo {
  height: 50px;
  width: 137px;
  -o-object-fit: contain;
     object-fit: contain;
  transition: all 0.5s;
}

.translateX {
  transform: translateX(0) !important;
}

.pointer {
  cursor: pointer;
}

.overlay {
  position: relative;
}
.overlay:before {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  z-index: 66;
  pointer-events: none;
}

#scrollUp {
  position: fixed;
  right: 1pc;
  bottom: 1pc;
  opacity: 0;
}
#scrollUp .scroll-inner {
  height: 45px;
  width: 45px;
  background: #f16331;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #fff;
  font-size: 22px;
  cursor: pointer;
}

#call-us {
  position: fixed;
  right: 2pc;
  bottom: 1pc;
  filter: drop-shadow(0px 0px 6px gray);
  z-index: 9999;
  opacity: 0;
}
#call-us:before {
  content: "";
  position: absolute;
  border: 2px solid #fff;
  bottom: 0;
  right: 0;
  transform: translate(-2px, -2px);
  height: 15px;
  width: 15px;
  background: #128c7e;
  z-index: 0;
}
#call-us .call-us-inner {
  height: 55px;
  width: 55px;
  background: #128c7e;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  font-size: 30px;
  transform: scaleX(-1);
}
#call-us .call-us-inner i {
  animation: phone 1.5s linear 0s infinite alternate;
}

.line {
  width: 70px;
  background: #fff;
  height: 1px;
}

input:focus-visible {
  outline: 0;
}

.form.filled-input .form-control,
.form.filled-input .select2-selection--single {
  background-color: rgba(243, 244, 248, 0.6705882353) !important;
}
.form [class*=col-] {
  margin-bottom: 10px;
}
.form [class*=col-]:last-child {
  margin-bottom: 0;
}
.form .form-check-label {
  flex: 1;
  color: #000;
  display: flex;
  align-items: center;
  gap: 11px;
  cursor: pointer;
}
.form .form-check:not(.form-check-inline) {
  display: flex;
  gap: 10px;
  align-items: center;
}
.form .form-check.form-check-inline .form-check-input {
  margin-right: 10px;
}
.form .form-check input[type=checkbox] {
  height: 16px;
  width: 16px;
  border: 1px solid #d0d0d0;
  border-radius: 0;
  position: relative;
  background-color: #fff;
  cursor: pointer;
}
.form .form-check input[type=checkbox].form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.2509803922);
  box-shadow: none;
}
.form .form-check input[type=radio] {
  cursor: pointer;
}
.form .form-check input[type=radio]:checked {
  background-color: #ee82c5;
  border-color: #ee82c5;
}
.form .form-check input[type=radio]:focus {
  border-color: #ee82c5;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(226, 86, 173, 0.2509803922);
}
.form .select2-container--default {
  width: 100% !important;
}
.form .select2-container--default .select2-selection--single {
  height: 40px !important;
  border-radius: 0;
  width: 100%;
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  border: 1px solid #ced4da !important;
}
.form .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px !important;
}
.form .form-control {
  border-color: transparent;
  padding: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.062745098);
}
.form .form-control:focus {
  box-shadow: none;
  border-color: #fcaf17;
  transition: all 0.5s;
}
.form .form-control:focus::-moz-placeholder {
  opacity: 0;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.form .form-control:focus::placeholder {
  opacity: 0;
  transition: all 0.5s;
}
.form .form-control::-moz-placeholder {
  opacity: 1;
  color: #ccc;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  text-transform: capitalize;
}
.form .form-control::placeholder {
  opacity: 1;
  color: #ccc;
  transition: all 0.5s;
  text-transform: capitalize;
}
.form label {
  margin: 0;
  color: #838383;
  line-height: 20px;
  width: 100%;
  font-size: 15px;
}

.address-map {
  margin: 0 10px;
  padding: 8px 60px 8px 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  background-color: white;
  position: absolute;
  top: -5px;
  left: -5px;
  text-align: left;
  z-index: 1;
}
.address-map p,
.address-map a {
  font-size: 15px;
}

.paddingprotfolittopbotm {
  padding: 60px 0;
}

@media only screen and (max-width: 767px) {
  .ptb-40 {
    padding: 20px 0px !important;
  }
}/*# sourceMappingURL=main.css.map */