body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-toggler
{
  border: none!important;
}

.btn-close:focus
{
  box-shadow: none!important;
}

.call_buttn_box {
  position: fixed !important;
  bottom: 95px !important;
  left: 14px !important;
  right: unset !important;
  z-index: 99999999 !important;
  animation: floaty 4s ease-in-out infinite;
  cursor: pointer;
}

.call_buttn_box img {
  width: 50px;
  height: 50px;
}

@keyframes floaty {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.whatsapp-buttn-box-left img {
  width: 50px;
  height: 50px;
}

.whatsapp-buttn-box-left {
  right: unset !important;
  left: 14px;
  bottom: 40px !important;
  position: fixed;
  z-index: 999;

  
}
.brochuremodal {
  position: relative;
}

.brochuremodal .modal-header {
  padding: 0px !important;
  border-bottom: none;
}
.brochuremodal .modal-header .btn-close {
  padding: 0px !important;
  margin: 0px !important;
  position: absolute;
  right: 15px;
  top: 20px;
}

.brochuremodal .modal-body {
  padding: 0px !important;
}
.brochuremodal .modal-body .closebuttn {
  padding: 0px;
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: transparent;
  border: none;
}
.brochuremodal .modal-body .closebuttn img {
  width: 40px;
  height: 40px;
}
.brochuremodal .modal-body .submit_buttn {
  background-color: #fcaf17;
  color: white;
  padding: 10px 42px;
  border-color: transparent;
}

.brochuremodal .modal-body input {
  font-size: 14px;
  padding: 12px;
}
.brochuremodal .modal-body .main-heading {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}

.yellow_b {
  font-weight: bold;
  color: #fcaf17;
  text-transform: capitalize;
}

.h_600 {
  height: 500px;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.spec_para {
  font-size: 20px;
  padding: 10px 0px;
  font-weight: 600;
}

.digital-mkt-banner-sec {
  position: relative;
  z-index: 1;
}
.digital-mkt-banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.digital-mkt-banner-sec .digital-mkt-banner-content {
  position: absolute;
  z-index: 2;
  top: 35%;
  width: 40%;
  left: 5%;
}
.digital-mkt-banner-sec .digital-mkt-banner-content .main-heading {
  color: white;
  font-size: 28px;
  margin-bottom: 15px;
}
.digital-mkt-banner-sec .digital-mkt-banner-content .main-heading span {
  color: #fcaf17;
}
.digital-mkt-banner-sec .digital-mkt-banner-content .sub-heading {
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
  font-size: 15px;
}
.digital-mkt-banner-sec .digital-mkt-banner-content .transform-button {
  color: white !important;
  background-color: transparent;
  border: 1px solid #fcaf17;
  padding: 12px 40px;
  transition: all 0.4s ease-in-out;
  font-weight: 600;
  overflow: hidden;
  position: relative;
}
.digital-mkt-banner-sec .digital-mkt-banner-content .transform-button::before {
  content: "";
  position: absolute;
  background-color: #fcaf17;
  color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform-origin: 0 50%;
  transition-property: transform;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out;
}
.digital-mkt-banner-sec
  .digital-mkt-banner-content
  .transform-button:hover::before {
  border: 1px solid transparent;
  color: white;
  transition: all 0.4s ease-in-out;
  transform: scaleX(1);
}

.digital-mkt-about-us .digital-mkt-about-content {
  margin-top: 15%;
}
.digital-mkt-about-us .digital-mkt-about-content .main-heading {
  font-size: 30px;
  font-weight: 600;
  color: #020202;
}
.digital-mkt-about-us .digital-mkt-about-content .main-heading span {
  color: rgb(252, 175, 23);
}
.digital-mkt-about-us .digital-mkt-about-content .desc {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.digital-mkt-about-us .digital-mkt-about-content .h-about-button {
  margin-top: 20px;
}
.digital-mkt-about-us
  .digital-mkt-about-content
  .h-about-button
  .read-more-button {
  background-color: rgb(252, 175, 23);
  border: 1px solid rgb(252, 175, 23);
  border-radius: 5px;
  padding: 12px 25px;
  color: #ffffff;
  font-size: 14px;
  transition: all 0.5s;
}
.digital-mkt-about-us
  .digital-mkt-about-content
  .h-about-button
  .read-more-button:hover {
  color: white !important;
  transform: translateY(-15px);
  transition: all 0.5s;
}

.digital-mkt-features-sec {
  background-color: #f8f9fe;
}
.digital-mkt-features-sec .digital-mkt-features-main-head {
  text-align: center;
}
.digital-mkt-features-sec .digital-mkt-features-main-head .main-heading {
  font-size: 32px;
  margin-bottom: 15px;
  font-weight: 600;
}
.digital-mkt-features-sec .digital-mkt-features-main-head .main-heading span {
  color: #fcaf17;
}
.digital-mkt-features-sec .digital-mkt-features-card {
  background-color: #fff;
  border: #dee4fe solid 1px;
  border-radius: 22px;
  padding: 28px;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  margin-bottom: 25px;
}
.digital-mkt-features-sec .digital-mkt-features-card:hover {
  border-color: #fcaf17;
  box-shadow: 0 20px 25px -3px rgba(252, 175, 23, 0.22),
    0 8px 10px -2px rgba(252, 175, 23, 0.22);
  background-color: #fff;
}
.digital-mkt-features-sec .digital-mkt-features-card .main-heading {
  font-size: 22px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.digital-mkt-features-sec .digital-mkt-features-card .main-heading::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 82px;
  height: 3px;
  background-color: #fcaf17;
  left: 0;
}

.digital-mkt-why-choose-us-sec {
  background-color: #f2f2f2;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-main-head {
  text-align: center;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-main-head .main-heading {
  font-size: 32px;
  margin-bottom: 15px;
  font-weight: 600;
}
.digital-mkt-why-choose-us-sec
  .digital-mkt-why-choose-main-head
  .main-heading
  span {
  color: #fcaf17;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-main-head .desc {
  font-size: 16px;
  width: 80%;
  margin: 0 auto;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-card {
  background-color: white;
  padding: 20px 15px;
  transition: 0.2s ease-in-out;
  margin-bottom: 25px;
  min-height: 290px;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-card:hover {
  transform: translateY(-10px);
  cursor: pointer;
}
.digital-mkt-why-choose-us-sec .digital-mkt-why-choose-card img {
  width: 50px;
}
.digital-mkt-why-choose-us-sec
  .digital-mkt-why-choose-card
  .digital-mkt-why-choose-content
  h5 {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 18px;
}
.digital-mkt-why-choose-us-sec
  .digital-mkt-why-choose-card
  .digital-mkt-why-choose-content
  .desc {
  font-size: 16px;
  line-height: 25px;
}

.digital-mkt-services-sec .pt-15 {
  padding-top: 15px;
}
.digital-mkt-services-sec .j-c-sb {
  justify-content: space-between;
}
.digital-mkt-services-sec .digital-mkt-services-img {
  position: relative;
}
.digital-mkt-services-sec .digital-mkt-services-img .video-icon {
  position: absolute;
  z-index: 2;
  top: 68%;
  left: 56%;
  cursor: pointer;
}
.digital-mkt-services-sec .digital-mkt-services-img .video-icon img {
  width: 75%;
}
.digital-mkt-services-sec .digital-mkt-services-content {
  margin-top: 5%;
}
.digital-mkt-services-sec .digital-mkt-services-content .main-heading {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}
.digital-mkt-services-sec
  .digital-mkt-services-content
  .digital-mkt-services-content-list
  li {
  border-top: 1px dotted #fcaf17;
  padding: 7px;
  font-weight: 600;
  font-size: 16px;
}
.digital-mkt-services-sec
  .digital-mkt-services-content
  .digital-mkt-services-content-list
  li:hover {
  color: #fcaf17;
  cursor: pointer;
}
.digital-mkt-services-sec
  .digital-mkt-services-content
  .digital-mkt-services-content-list
  li
  svg {
  color: #fcaf17;
  font-size: 20px;
}

.digital-mkt-services-benefits-sec .digital-mkt-services-benefits-main-head {
  text-align: left;
}
.digital-mkt-services-benefits-sec
  .digital-mkt-services-benefits-main-head
  .main-heading {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}
.digital-mkt-services-benefits-sec
  .digital-mkt-services-benefits-main-head
  .main-heading
  span {
  color: #fcaf17;
}
.digital-mkt-services-benefits-sec
  .digital-mkt-services-benefits-main-content
  .desc {
  margin-bottom: 15px;
  font-size: 16px;
}
.digital-mkt-services-benefits-sec .desc1 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}
.digital-mkt-services-benefits-sec .desc1 span {
  color: #fcaf17;
}
.digital-mkt-services-benefits-sec
  .digital-mkt-benefits-list-box
  .digital-mkt-benefits-list {
  margin-top: 3%;
}
.digital-mkt-services-benefits-sec
  .digital-mkt-benefits-list-box
  .digital-mkt-benefits-list
  li {
  line-height: 30px;
  font-size: 17px;
  margin-bottom: 25px;
}
.digital-mkt-services-benefits-sec
  .digital-mkt-benefits-list-box
  .digital-mkt-benefits-list
  li
  svg {
  color: #fcaf17;
  font-size: 26px;
  margin-right: 10px;
}

.digital-mkt-tools-sec .digital-mkt-tools-main-head {
  text-align: center;
}
.digital-mkt-tools-sec .digital-mkt-tools-main-head .main-heading {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 15px;
}
.digital-mkt-tools-sec .digital-mkt-tools-main-head .main-heading span {
  color: #fcaf17;
}
.digital-mkt-tools-sec .tools-img {
  text-align: center;
}
.digital-mkt-tools-sec .tools-img img {
  width: 100% !important;
  height: 70px !important;
  -o-object-fit: contain;
  object-fit: contain;
  margin-bottom: 15px;
}

.digital-mkt-casestudy-sec {
  background-color: #f9f9f9;
}
.digital-mkt-casestudy-sec .digital-mkt-casestudy-content .main-heading {
  font-size: 32px;
  font-weight: 600px;
  margin-bottom: 25px;
}
.digital-mkt-casestudy-sec .digital-mkt-casestudy-content .sub-heading {
  font-size: 24px;
  font-weight: 600;
}
.digital-mkt-casestudy-sec .digital-mkt-casestudy-content .desc {
  font-size: 18px;
}
.digital-mkt-casestudy-sec
  .digital-mkt-casestudy-content
  .digital-mkt-casestudy-content-list {
  margin-top: 2%;
}
.digital-mkt-casestudy-sec
  .digital-mkt-casestudy-content
  .digital-mkt-casestudy-content-list
  li {
  line-height: 35px;
  font-size: 17px;
}
.digital-mkt-casestudy-sec
  .digital-mkt-casestudy-content
  .digital-mkt-casestudy-content-list
  li
  svg {
  color: #fcaf17;
  font-size: 20px;
  margin-right: 10px;
}
.digital-mkt-casestudy-sec .j-c-sb {
  justify-content: space-between;
}

.digital-marketing-services-sec {
  background-color: #f6ebd6;
}
.digital-marketing-services-sec .digital-marketing-services-main-head {
  text-align: center;
}
.digital-marketing-services-sec
  .digital-marketing-services-main-head
  .main-heading {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 15px;
}
.digital-marketing-services-sec
  .digital-marketing-services-main-head
  .main-heading
  span {
  color: #fcaf17;
}
.digital-marketing-services-sec .digital-marketing-services-main-head .desc {
  font-size: 16px;
  width: 90%;
  margin: 0 auto;
}
.digital-marketing-services-sec .digital-marketing-services-card {
  background-color: white;
  border: 1px solid #e5e5e5;
  padding: 20px 20px 0px 20px;
  transform: scale(1);
  border-radius: 20px;
  transition: all 0.5s;
  margin-bottom: 25px;
  min-height: 291px;
}
.digital-marketing-services-sec
  .digital-marketing-services-card:hover
  .service-btnn {
  background-color: #fcaf17;
  color: white;
  transition: all 0.3s;
}
.digital-marketing-services-sec
  .digital-marketing-services-card
  .d-mkt-card-icon {
  position: relative;
}
.digital-marketing-services-sec
  .digital-marketing-services-card
  .d-mkt-card-icon
  .circle-icon-holder {
  display: block;
  width: 100px;
  height: 100px;
  background-color: #ffedc8;
  text-align: center;
  border-radius: 100%;
  padding: 24px;
  position: relative;
  transition: all 0.4s;
  animation: rotate 6s linear infinite;
  z-index: 1;
}
.digital-marketing-services-sec
  .digital-marketing-services-card
  .d-mkt-card-icon
  .circle-icon-holder::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 2px;
  left: 15px;
  right: 0;
  bottom: 0;
  background-color: #fcaf17;
  border-radius: 100%;
  animation: rotate 6s linear infinite;
  transition: all 0.4s;
  z-index: 0;
}
.digital-marketing-services-sec
  .digital-marketing-services-card
  .d-mkt-card-icon
  img {
  width: 65px;
  z-index: 1;
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: center;
  margin: 0 auto;
}
@keyframes rotate {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.digital-marketing-services-sec
  .digital-marketing-services-card
  .digital-marketing-services-heading
  .heading {
  font-size: 21px;
}
.digital-marketing-services-sec
  .digital-marketing-services-card
  .digital-marketing-content {
  margin-top: 15px;
}
.digital-marketing-services-sec
  .digital-marketing-services-card
  .digital-marketing-content
  .desc {
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 15px;
  min-height: 72px;
}
.digital-marketing-services-sec
  .digital-marketing-services-card
  .digital-marketing-content
  .service-btnn {
  border-top: 1px solid #ddd;
  padding: 10px;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
.digital-marketing-services-sec .digital-marketing-services-card:hover {
  cursor: pointer;
}
.digital-marketing-services-sec
  .digital-marketing-services-card:hover
  .circle-icon-holder::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  background-color: #fcaf17;
  border-radius: 100%;
  z-index: 0;
  transition: all 0.3s;
}

.boost-your-business-sec {
  background-color: #f9f9f9;
}
.boost-your-business-sec .boost-your-business-content {
  margin-top: 10%;
}
.boost-your-business-sec .boost-your-business-content .main-heading {
  font-size: 29px;
  font-weight: 600;
  margin-bottom: 15px;
}
.boost-your-business-sec .boost-your-business-content .main-heading span {
  color: #fcaf17;
}
.boost-your-business-sec .boost-your-business-content .desc {
  margin-bottom: 15px;
}
.boost-your-business-sec .boost-your-business-content .percentage span {
  font-size: 42px;
  color: #fcaf17;
  font-weight: 600;
}
.boost-your-business-sec
  .boost-your-business-content
  .percentage-content
  .desc1 {
  font-size: 15px;
  line-height: 20px;
  margin-left: 15px;
}

.social-media-management-sec {
  background-color: #efefef;
}
.social-media-management-sec .social-media-management-content {
  margin-top: 20%;
}
.social-media-management-sec .social-media-management-content .main-heading {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.social-media-management-sec
  .social-media-management-content
  .main-heading
  span {
  color: #fcaf17;
}
.social-media-management-sec .social-media-management-content .desc {
  font-size: 18px;
  line-height: 30px;
}
.social-media-management-sec
  .social-media-management-content
  .social-media-icons-list {
  display: flex;
  margin-top: 2%;
}
.social-media-management-sec
  .social-media-management-content
  .social-media-icons-list
  li {
  margin-right: 20px;
}
.social-media-management-sec
  .social-media-management-content
  .social-media-icons-list
  li:hover
  img {
  transition: all 0.5s;
  animation: bounce 1.5s infinite;
}
.social-media-management-sec
  .social-media-management-content
  .social-media-icons-list
  li
  img {
  width: 50px;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-17px);
  }
  100% {
    transform: translateY(0px);
  }
}

.mapcontact .map_iframe {
  min-height: 500px;
}


.select_box  select {
  padding: 10px;
  width: 100%;
  color: #798189;
  cursor: pointer;
  min-height: 45px;
  border-radius: 6px !important;
  border: 1px solid #ced4da !important;
}

.select_box select:focus
{
  border-color: #fcaf17 !important;
      box-shadow: none !important;
      outline: none !important;
}
 
.df_jcsb
{
  display: flex;
  justify-content: space-between;
}


.industries_swiper .swiper-pagination-bullet-active {
  width: 30px!important;
  border-radius: 5px!important;
  height: 5px!important;
  background-color: #fcaf17!important;

}

.industries_swiper .swiper-pagination-bullet
{
  background-color: #fff!important;
}

.industries_swiper .swiper-wrapper
{
  padding-bottom: 25px;
}

.swiper-wrapper
{
  transition-timing-function: linear!important;
}


@media only screen and (max-width:768px) {
  .df_jcsb
  {
    display: inline;
  }

  .checkbox_area h5
  {
    font-size: 16px;
    margin-bottom: 10px!important;
  }

  .left .title-section p
  {
    text-align: left!important;
  }
}


/* .consultation .row {
  padding: 40px 0px;
} */

.pl-10 {
  padding-left: 10px;
}

@media only screen and (max-width: 767px) {
  .mb-50 {
    margin-bottom: 50px;
  }

  .consultation .row {
    padding: 20px 0px;
  }

  .title-section .heading {
    font-size: 18px !important;
  }

  .blog-box .image {
    height: 200px;
}
}
@media only screen and (min-width: 768px) and (max-width:991px) {
  .mb-50 {
    margin-bottom: 50px;
  }

  .consultation .row {
    padding: 24px 0px;
  }

  .title-section .heading {
    font-size: 24px !important;
    margin-bottom: 10px!important;
  }

  .blog-box .image {
    height: 200px;
}
}

@media only screen and (min-width:1400px) and (max-width:1440px) {
  .main-blog .container-fluid {
    padding: 0px 3pc;
}
}