.we_offer
{
    background-color: #f5f5f5;
    padding: 60px 0px;

    .py_20
    {
        padding: 20px 0px;
        align-items: center;
        justify-content:space-evenly;
    }

    .heading
    {

        h2
        {
            font-size: 45px;
            color: #212529;
            font-weight: 700;
        }
    }

    .pt_40
    {
        padding-top: 40px;
        justify-content: space-evenly;
    }

    .offer_card
    {
position: relative;
z-index: 2;
cursor: pointer;


        .image
        {
            position: relative;
            width: 100%;
            height: 300px;
            overflow: hidden;
            transition: all 0.4s ease;
            border-radius: 20px;

            &::after
            {
                content: "";
                position: absolute;
                left: 0;
                bottom:0;
                width: 100%;
                height: 100%;
                border-radius: 10px 10px 0;
                z-index: 1;
              background-color: rgb(33 37 41 / 30%);
            }
            
            img
            {
                border-radius: 20px;
                transform: scaleY(1);
                 transition: all 0.4s ease;
                 width: 100%;
                 height: 100%;
                
            }

          
        }

        .content
        {
            position: absolute;
            bottom: 10%;
            left: 0;
            right: 0;
            padding: 0px 20px;
z-index: 1;
            h4
            {
                color: #fcaf17;
                font-size: 20px;
                font-weight: 700;
            }

            p
            {
                color: #ededed;
                font-size: 14px;
            }

        }

        &:hover
        {
           
            .image
            {
                img
            {
                transform: scaleY(1.1);
                 transition: all 0.4s ease;
            }
            }
        }
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .we_offer
    {
        padding: 20px 0px 0px 0px;

       

        .heading
        {
            
            h2
            {
                font-size: 20px;
            }
        }

        .content
        {

            p
            {
                font-size: 14px;
            }
        }

        .py_20
        {
            padding: 0px 0px;
        }

        .pt_40
        {
            padding-top: 20px;
        }

        .offer_card
        {
            .image
            {
               
                height: 250px;
                margin-bottom: 20px;
                border-radius: 10px;
                img
                {
                    border-radius: 10px;
                }
            }

            .content
            {
                h4
                {
                    font-size: 16px;
                }
                p
                {
                    font-size: 13px;
                    line-height: 20px;
                }
            }
        }
    }
}


@media only screen and (min-width:768px) and (max-width:991px) {
    .we_offer
    {
        padding: 30px 0px 0px 0px;

       

        .heading
        {
            
            h2
            {
                font-size: 26px;
            }
        }

        .content
        {

            p
            {
                font-size: 14px;
            }
        }

        .py_20
        {
            padding: 0px 0px;
        }

        .pt_40
        {
            padding-top: 20px;
        }

        .offer_card
        {
            .image
            {
               
                height: 250px;
                margin-bottom: 20px;
                border-radius: 10px;
                img
                {
                    border-radius: 10px;
                }
            }

            .content
            {
                h4
                {
                    font-size: 16px;
                }
                p
                {
                    font-size: 13px;
                    line-height: 20px;
                }
            }
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .we_offer
    {
        padding: 30px 0px 30px 0px;

       

        .heading
        {
            
            h2
            {
                font-size: 28px;
            }
        }

        .content
        {

            p
            {
                font-size: 14px;
            }
        }

        .py_20
        {
            padding: 0px 0px;
        }

        .pt_40
        {
            padding-top: 30px;
        }

        .offer_card
        {
            .image
            {
               
                
                margin-bottom: 20px;
                border-radius: 10px;
                img
                {
                    border-radius: 10px;
                }
            }

            .content
            {
                h4
                {
                    font-size: 20px;
                }
                p
                {
                    font-size: 15px;
                    line-height: 20px;
                }
            }
        }
    }
}
@media only screen and (min-width:1200px) and (max-width:1440px) {
    .we_offer
    {
        padding: 40px 0px;

       

        .heading
        {
            
            h2
            {
                font-size: 32px;
                text-align: center;
            }
        }


        .py_20
        {
            padding: 0px 0px;
        }

        .pt_40
        {
            padding-top: 40px;
        }

        .offer_card
        {
           
            .content
            {
                h4
                {
                    font-size: 20px;
                }
                p
                {
                    font-size: 15px;
                    line-height: 20px;
                }
            }
        }
    }
}