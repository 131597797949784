
.digital_aboutus
{
    padding: 40px 0px;


    .py_20
    {
        padding: 20px 0px;
    }

    .image
    {
        
        width: 100%;
        height: 500px;

        img
        {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .content {
       

        .main_heading {
            font-size: 30px;
            font-weight: 600;
            color: #020202;

            span {
                color: rgb(252, 175, 23);
              }
          }

          .desc {
            font-size: 16px;
            
          }

          .buttn_holder {
            margin-top: 20px;

            .rm_buttn {
                background-color: rgb(252, 175, 23);
                border: 1px solid rgb(252, 175, 23);
                border-radius: 5px;
                padding: 12px 25px;
                color: #ffffff;
                font-size: 14px;
                transition: all 0.5s;

                &:hover {
                    color: white !important;
                    transform: translateY(-15px);
                    transition: all 0.5s;
                  }
              }
          }
      }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .digital_aboutus
    {
        padding: 20px  0px;

        .py_20
        {
            padding: 0px;
        }

        .image
        {
            height: 275px;
        }

        .content
        {
            margin-top: 0%;

            .main_heading
            {
                font-size: 18px;
            }

            .desc
            {
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .digital_aboutus
    {
        padding: 30px  0px;

        .py_20
        {
            padding: 0px;
        }

       
        .content
        {
            margin-top: 2%;

            .main_heading
            {
                font-size: 26px;
            }

            .desc
            {
                font-size: 14px;
                line-height: 25px;
            }
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .digital_aboutus
    {
        padding: 30px  0px;

        // .py_20
        // {
        //     padding: 0px;
        // }

        .image
        {
            height: 450px;
        }

        .content
        {
            margin-top: 0%;

            .main_heading
            {
                font-size: 24px;
            }

            .desc
            {
                font-size: 14px;
            }
        }
    }
}

@media only screen and (min-width:1200px) and (max-width:1399px) {
    .digital_aboutus
    {
        padding: 40px  0px;

        .py_20
        {
            padding: 0px;
        }

        .image
        {
            height: 550px;
        }

        .content
        {
            margin-top: 8%;

            .main_heading
            {
                font-size: 24px;
            }

            .desc
            {
                font-size: 14px;
            }
        }
    }
}
  