.contact_banner
{
    padding: 50px 0px 50px 0px;

    .contact_wrapper
    {
        position: relative;
height: 275px;
background-color: #181818;
padding: 50px;
align-items: center;
gap: 20px;
justify-content: center;
border-radius: 50px;


        &::after
        {
            position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background-image: url('../../../../assets/images/MLandAI/bg-shape2.webp');
    background-repeat: repeat-x;
    background-size: cover;
    background-position: bottom center;
    opacity: 0.1;
        }

        .content
        {
            position: relative;
            z-index: 1;
            h5
            {
                color: #fff;
                font-weight: 600;
                font-size: 22px;
                margin-bottom: 15px;
            }

            h3
            {
                color: #fff;
                font-weight: 700;
                font-size: 32px;
            }

            
        }

        .buttn_holder
        {
            margin-top: 25px;
            position: relative;
            z-index: 1;
            

            .opp_buttn
            {
    background: linear-gradient( 270deg, #f2f2fc, #fff, rgba(34, 34, 34, 0), rgba(34, 34, 34, 0) );
    background-size: 300% 300%;
    background-color: #ffad0b;
    font-family: "Circular Std";
    transition: 0.3s all ease-in-out;
    display: inline-block;
    color: #fff!important;
    font-size: 20px;
    padding: 14px 24px;
    font-weight: 600;

    &:hover
    {
        background-position: 99% 50%;
    transition: 0.3s all ease-in-out;
    color: #212529!important;
    }

            }
        }
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .contact_banner
    {
        padding: 20px 0px 20px 0px;

        .contact_wrapper
        {
    height: 100%;
    padding: 25px 15px;
    gap: 0px;
    border-radius: 10px;

    .content
    {
         h5 {
            
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 15px;
        }

        h3
        {
            font-size: 21px;
            font-weight: 600;
        }
    }

    .buttn_holder
    {
        .opp_buttn
        {
            font-size: 16px;
            padding: 10px 20px;
        }
    }
        }
    }
}


@media only screen and (min-width:768px) and (max-width:991px) {
    .contact_banner
    {
        padding: 20px  0px;

        .contact_wrapper
        {
    height: 100%;
    padding: 25px 15px;
    gap: 0px;
    border-radius: 20px;

    .content
    {
         h5 {
            
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 15px;
        }

        h3
        {
            font-size: 24px;
            font-weight: 600;
        }
    }

    .buttn_holder
    {
        .opp_buttn
        {
            font-size: 18px;
            padding: 10px 20px;
        }
    }
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .contact_banner
    {
        padding: 30px  0px;

        .contact_wrapper
        {
    height: 250px;
    padding: 35px 25px;
    border-radius: 30px;

    .content
    {
         h5 {
            
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 15px;
        }

        h3
        {
            font-size: 26px;
            font-weight: 600;
            line-height: 35px;
        }
    }

    .buttn_holder
    {
        margin-top: 0px;
        .opp_buttn
        {
            font-size: 18px;
            padding: 10px 20px;
        }
    }
        }
    }
}