.popup_contact_form_box {
    padding: 25px 30px 15px 30px;
    z-index: 99;
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 0 2px 0 rgb(145 158 171/20%),
      0 12px 24px -4px rgb(145 158 171/12%);
    border-radius: 24px;
    width: 100%;
    /* margin-top: 15%; */
  }
  
  .popup_contact_form_box-inpt-box .inpt input {
    padding: 20px 79px 20px 27px;
    font-size: 18px;
    background: #f8f8f8 0 0 no-repeat padding-box !important;
    border-radius: 6px !important;
    border: 2px solid #f8f8f8 !important;
    outline: none !important;
  }
  .popup_contact_form_box-inpt-box .message-textarea1 {
    padding: 25px 79px 28px 27px;
    font-size: 18px;
    background: #f8f8f8 0 0 no-repeat padding-box !important;
    border-radius: 6px !important;
    border: 2px solid #f8f8f8 !important;
    outline: none !important;
  }
  
  .popup_contact_form_box-inpt-box .message-textarea1:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .submit_buttn
  {
    background-color: #fcaf17;
    border: transparent;
    padding: 12px 42px;
    color: #fff!important;
    width: 100%;
    font-size: 18px;
    border-radius: 3px;
    
  }
  .submit_buttn:hover
  {
    background-color: #1a1a1a;
    transition: all 0.3s ease;
    color: #fff!important;
  }

  .animate_buttn
  {
    animation: leftmover 3s infinite ease-in-out;
  }

  .animate_buttn button
  {
    width: unset;
    text-transform: uppercase;
  }

  @media only screen and (max-width:767px) {
    .animate_buttn button
    {
      padding: 10px 20px;
      font-size: 14px;
      margin-top: 15px;
    }
  }

  @keyframes leftmover {
    0% {
      transform: translateX(0px);
  }
  50% {
      transform: translateX(-20px);
  }
  
  100% {
      transform: translateX(0px);
  }
  }
  
  .back-buttn {
    cursor: pointer;
  }
  
  .back-buttn svg {
    color: black;
    font-size: 26px;
  }
  
  .popup_contact_form_box-body {
    text-align: center;
    /* padding: 20px 40px; */
  }
  
  .popup_contact_form_box-body .heading {
    font-size: 28px;
    font-weight: 600;
  }
  
  .popup_contact_form_box-body .desc {
    font-size: 18px;
    font-weight: 500;
    /* width: 89%;
    margin: 0 auto; */
  }
  
  .popup_contact_form_box-heading {
    margin-bottom: 30px;
  }
  .modal-header
  {
    border-bottom: none!important;
    padding: 0px!important;
  }
  
  .modal-header .btn-close {
    position: absolute;
    right: 15px;
    top: 20px;
    z-index: 99999;
    background-color: #cfcdcd;
    border-radius: 0px;
  }
  
  .modal-body
  {
    padding: 0px;
  }
  
  
  .popup_contact_form_box {
    padding: 25px 25px 20px 25px;
    z-index: 99;
    background: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 100%;
    /* margin-top: 10%; */
    border-radius: 5px;
  }
  
  .popup_contact_form_box .form-head {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .popup_contact_form_box .contact-form-body{
    margin-top: 30px;
  }
  .popup_contact_form_box .desc {
    font-size: 14px;
    font-weight: 600;
  }
  
  .popup_contact_form_box .contact-form-body input {
    font-size: 14px;
    height: 45px;
  }
  .popup_contact_form_box .contact-form-body textarea {
    font-size: 14px;
  }
  .popup_contact_form_box .form-head {
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
  }


.checkbox_area
{
  margin-top: 15px;

  
}

.checkbox_area ul li
{
 margin-bottom: 10px;
display: flex;
align-items: center;
gap: 10px;
font-size: 14px;
}

.popup_rocket
{
  width: 80px;
  height: 80px;
  position: absolute;
  top: 8px;
  right: 10%;
}


.rocket_img
{
  width: 100%;
  height: 250px;
}

.rocket_img img
{
  width: 100%;
  height: 100%;
  object-fit: cover;
}






  @media only screen and (max-width:767px){
    .popup_contact_form_box .desc {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 15px;
      margin-top: 10px;
    }

    .popup_contact_form_box .contact-form-body {
      margin-top: 0px;
  }

    .rocket_img {
 
      display: none;
  }

    .popup_rocket {
      width: 50px;
      height: 45px;
      position: absolute;
      top: 5px;
      right: 50px;
  }
    .submit_buttn {
      padding: 8px 42px;
      font-size: 16px;
  }
  
    .margin-top-5 {
      margin-top: 15%;
    }
  
    .popup_contact_form_box {
      /* margin-top: 12%; */
      padding: 15px;
    }
  
    .popup_contact_form_box .form-head {
      font-size: 18px !important;
      font-weight: 500 !important;
      margin-bottom: 10px;
    }
  
  }

  @media only screen and (max-width:991px) {
    .footer .row.justify-content-center
    {
      justify-content: flex-start!important;
    }
    .footer .footer-links {
      margin-top: 0px;
      margin-bottom: 20px;
  }
    .footer .footer-links .title {
      font-size: 18px;
    
  }
    .footer .footer-links .main-logo {
      margin-bottom: 10px;
    
  }

  .popup_contact_form_box
  {
    padding: 25px 15px 20px 15px;
  }

  .rocket_img {
    width: 100%;
    height: 250px;

    margin-top: 15%;
}




  .footer {
    padding: 2pc 0 0pc!important;
  }

  .footer ul.social-links a {
    height: 30px;
    width: 30px;
}
  .footer ul.social-links {
    display: flex;
    align-items: center;
    gap: 6px;
}

}