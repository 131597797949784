@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
:root {
  --PhoneInputCountryFlag-aspectRatio: 1.333;
}
body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden !important;
}
* {
  list-style: none;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

ul {
  margin: 0;
  padding-left: 0px;
}

.header-contact-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-contact-area .header-contact-list {
  margin: 0px !important;
  display: flex;
  align-items: center;
}
.a-i-c {
  align-items: center;
}

.header-contact-area .header-contact-list li {
  margin-left: 20px;
}

.header-contact-area .header-contact-list li a {
  color: white;
  font-size: 18px;
  transition: all 0.3s;
}

.header-contact-area .header-contact-list li a svg {
  font-size: 18px;
  margin-right: 5px;
}

.header-contact-area .header-contact-list li a:hover {
  color: #fcaf17;
  text-decoration: none !important;
}

.req-quote-buttn {
  animation: pulse-animation 2s infinite;
  background-color: #fcaf17;
  border-radius: 2px;
  box-shadow: 0 0 0 #fcaf17;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 24px;
  transition: all 0.5s;
  margin-left: 20px;
  cursor: pointer;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 #fcaf17;
  }
  100% {
    box-shadow: 0 0 0 20px transparent;
  }
}

.main-banner-sec {
  width: 100%;
  /* background: linear-gradient(74deg,#fff0d0,#fef6e7 45%); */
  background-color: #000;
  width: 100%;

  background-image: url("../../assets/images/EbslonLandingPage/img/footerbg/footer-bg.jpg");
  background-position: center;
  background-repeat: repeat;
  min-height: 400px;
  position: relative;
}

.main-banner-sec .main-banner-content .main-heading {
  font-size: 58px;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
}

.main-banner-content .main-heading span {
  color: #fcaf17;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fcaf17;
}

.main-banner-content {
  margin-top: 12%;
}
.main-banner-content h3 {
  color: #fcaf18;
}

.main-banner-img {
  text-align: end;
}

.main-banner-img img {
  width: 80%;
}
.mt-25 {
  margin-top: 25px;
}

.quick-enquiry-buttn {
  background-color: #fcaf18;
  padding: 12px 24px;
  border: 1px solid #fcaf17;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
.quick-enquiry-buttn:hover {
  color: #fcaf17 !important;
  background-color: transparent !important;
  border: 1px solid #fcaf17;
}

input:focus {
  box-shadow: none !important;
  border-color: #fcaf17 !important;
  outline: none !important;
}

.enquiry-now-buttn {
  background-color: #fcaf17;
  color: white;
  padding: 12px 32px;
  border-radius: 5px;
  font-size: 16px;
  display: block;
  text-align: center;
  transition: all 0.4s;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}

.enquiry-now-buttn::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 300px;
  height: 200px;
  background-color: #333;
  border-color: transparent;
  border-radius: 50%;
  transform: translate(-40px, -80px) scale(0.1);
  opacity: 0;
  z-index: -1;
  transition: all 0.5s;
}

.enquiry-now-buttn:hover {
  background-color: #020202;
  color: white;
}

.message-textarea:focus {
  box-shadow: none !important;
  border-color: #fcaf17 !important;
  outline: none !important;
}

.py-40 {
  padding: 40px 0px;
}

.get-quote-buttn {
  position: relative;
  /* background-color: #fcaf17; */
  background-color: #876015;
  color: white;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.get-quote-buttn:hover {
  color: white;
}

.mt-35 {
  margin-top: 35px;
}

.app-development-process {
  background-color: #000;
}

.app-development-main-head h3 {
  font-size: 45px;
  color: white;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 50px;
}

.app-development-main-head h3 span {
  color: #fcaf17;
}
.ptb-100 {
  padding: 80px 0px;
}

/* porftolio-sec */
.portfolio-sec {
  background-color: #171717;
}

.portfolio-sec .portfolio-img {
  text-align: center;
  padding: 25px 0px;
}

.portfolio-sec-main-head .main-heading {
  color: white;
  font-size: 34px;
  text-align: center;
  text-transform: capitalize;
}

.portfolio-sec-main-head .main-heading span {
  color: #fcaf17;
}


.fluid-padding .container-fluid {
  padding: 0px 8pc;
}

.w-80 {
  width: 80%;
  margin: 0 auto;
}

.accordion-item {
  background-color: transparent !important;
  border-color: transparent !important;
}

.faq-sec-main-head .main-heading {
  color: white;
  font-size: 36px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
}

.faq-sec-main-head .main-heading span {
  color: #fcaf17;
}

.m-auto {
  margin: 0 auto;
}

.faq-accordion .accordion-button:not(.collapsed) {
  color: #020202 !important;
  background-color: rgb(247 247 247) !important;
  box-shadow: none !important;
}

.faq-accordion .accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none !important;
}

.faq-accordion .accordion-button {
  color: #fff !important;
  box-shadow: none !important;
  background-color: #262626;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  width: 100%;
  border-radius: 10px;
  padding: 25px 25px 25px 30px;
}

.faq-accordion .accordion-header svg {
  content: "";
  position: absolute;
  right: 2%;
  top: 35%;
}

.minus-icon {
  display: none;
}

.faq-accordion .accordion-item .accordion-header {
  box-shadow: none !important;
  margin-bottom: 20px;
  font-size: 16px;
  background-color: #171717 !important;
  position: relative;
}

.faq-accordion .accordion-button:not(.collapsed) {
  background-color: #171717 !important;
  color: white !important;
}

.faq-accordion .accordion-button::after {
  background-image: none !important;
}

.faq-sec1 .faq-accordion .accordion-body {
  background-color: #858383;
  color: #e5e5e5;
  margin-bottom: 15px;
  font-size: 16px;
  padding: 20px;
  border-radius: 0px;
}

.faq-accordion .accordion-button:not(.collapsed) .minus-icon {
  display: block;
}

.faq-accordion .accordion-button:not(.collapsed) .plus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .minus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .plus-icon {
  display: block;
}

.padding-0 {
  padding: 0px !important;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.portfolio-content {
  background-color: #262626;
  border-radius: 10px;
  padding: 30px 20px;
  transition: all 0.3s;
  cursor: pointer;
  transform: scale(1);
  position: relative;
}

.portfolio-content .heading {
  color: white;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
}

.portfolio-content .heading span {
  color: #fcaf17;
  font-size: 22px;
  text-transform: capitalize;
  letter-spacing: 2px;
  font-weight: 500;
}

.portfolio-content .desc {
  font-size: 16px;
  color: #e5e5e5;
  font-weight: 400;
  line-height: 25px;
}

.a-i-c {
  align-items: center;
}

.portfolio-content::after {
  content: "";
  position: absolute;
  width: 0%;
  background-color: #fcaf17;
  height: 4px;
  left: 0;
  bottom: 0;
  transition: all 0.5s;
}

.portfolio-content:hover::after {
  width: 100%;
}

.position-relative {
  position: relative;
}

.position-absolute-left {
  position: absolute;
  top: 0;
  left: 0;
}

.position-absolute-right {
  position: absolute;
  right: 0;
  bottom: 0;
}

.imgre .img-fluid {
  width: 100%;
  height: auto;
}

.py-20 {
  padding: 20px 0px;
}

/* download-brochure-modal-popup */
.brochurepadding {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 15px;
}

.text-center {
  text-align: center !important;
}

.modalpop .form-control,
.modalpop input {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.modalpop input {
  border-radius: 4px;
  height: 50px;
}

.modalpop.borchure .modal-body {
  padding: 0;
}

.modalpop .modal-header {
  border-bottom: none;
  padding: 0;
}

.modalpop .btnmodasubmit {
  background-color: #fcaf17;
  color: #fff !important;
  font-size: 20px;
  padding: 7px 35px;
}

.modalpop .closebtnme {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 8;
}

.modalpop.borchure img {
  height: 430px;
  object-fit: cover;
  width: 100%;
}

.modalpop {
  position: relative;
}

.modalpop .modal-content-heading {
  font-size: 24px;
}

.footer-logo-area img {
  width: 35%;
}

.footer-company-info h5 {
  color: white;
  margin-top: 5%;
}

/* enquiry-now-buttn */
.enquiry-now-side-buttn {
  position: fixed;
  top: 90%;
  right: 0;
  transform: rotate(0deg);
  z-index: 9999999;
  background-color: #fcaf17 !important;
  color: white;
  border-color: transparent !important;
  padding: 8px 24px !important;
  cursor: pointer;
}

.enquiry-now-side-buttn:focus {
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: #fcaf17 !important;
}

.popup-modal .modal-content {
  background-color: #171717;
  min-height: 300px;
  width: 600px;
  max-width: 100%;
}

.popup-modal .modal-header {
  border-bottom: 0px;
  padding: 25px;
}
.popup-modal .modal-footer {
  border: none;
}

.popup-modal .modal-header .btn-close {
  background-color: #f5efef;
  opacity: 1;
  border-radius: 2px;
  box-shadow: none;
}

.popup-modal .modal-body {
  margin-top: 5%;
  padding: 10px 55px 10px 55px;
  text-align: center;
}

.popup-modal .modal-body .content {
  color: white;
  font-size: 30px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 30px;
  font-weight: 500;
}

.call-us-buttn {
  background-color: #fcaf17;
  color: white;
  padding: 10px 24px;
  border-radius: 5px;
  border-color: transparent;
  box-shadow: none;
  cursor: pointer;
}

.popup-modal .modal-backdrop.show {
  opacity: 0.7;
}

.bottom-bar {
  background-color: #000;
  padding: 20px 0px;
}
.privacy-list-box {
  margin-bottom: 0px;
}

.privacy-list-box li {
  color: white;
  font-size: 14px;
}

.privacy-list-box li a {
  color: white;
  font-size: 14px;
  margin: 0px 5px;
}

.copyright-area p {
  margin-bottom: 0px;
  color: #e5e5e5;
  font-size: 14px;
}
.copyright-area p span {
  color: #fcaf17;
}

.made-area p {
  color: #e5e5e5;
  margin-bottom: 0px;
  font-size: 14px;
}

.made-area p svg {
  color: #b53559;
}

.d-f-j-c-e {
  display: flex;
  justify-content: flex-end;
}
.footer-logo-img img {
  width: 35%;
}

.other-services {
  position: relative;
  margin-top: 3%;
}
.other-services1 {
  position: relative;
  margin-top: 5%;
}

.other-services1 .main-heading {
  font-size: 26px;
  margin-bottom: 0px;
  color: #212529;
  font-weight: 600;
}
.copyright-area {
  padding: 10px 0px;
  background: #282727;
  color: white;
}

.copyright-area .copyright-text {
  text-align: center;
  font-weight: 400;
}
.copyright-area .copyright-text a {
  color: #fcaf17;
  font-weight: 400;
}

.other-services1 .main-other-services-list {
  padding-left: 0px;
  margin-top: 3%;
}

.other-services1 .main-other-services-list li {
  margin-bottom: 10px;
}

.other-services1 .other-services-list {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.other-services1 .other-services-list li {
  line-height: 40px;
  list-style: none;
  margin-right: 20px;
}

.other-services1 .other-services-list li .desc {
  color: #212529;
  font-size: 14px;
  line-height: 20px;
}

.other-services1 .other-services-list li h6 {
  color: #212529;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}

.other-services1 .other-services-list li a img {
  margin-right: 10px;
}

.Q-form1 .left h4 {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 600;
}

.contact_onfo {
  margin-top: 8%;
}

.mb-30 {
  margin-bottom: 30px;
}

/* special-buttn */

.buttn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #fcaf17;
  border-radius: 5px;
  transition: ease-out 0.4s;
  position: relative;
  padding: 12px 32px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  box-shadow: none;
  border: transparent;
  display: inline-block;
  cursor: pointer;
}

.buttn.styleform {
  width: 35%;
  letter-spacing: 1px;
}

.buttn.styleform:hover {
  width: 28%;
}

:not(.buttn-check) + .buttn:active {
  color: #fcaf17 !important;
  background-color: #fcaf17 !important;
  border-color: none !important;
  border-radius: 5px;
}

.buttn:hover {
  box-shadow: inset -5.5em 0 0 0 #101010, inset 5.5em 0 0 0 #101010;
  border-radius: 5px;
  color: #fcaf17 !important;
}

.buttn:focus {
  box-shadow: inset -5.5em 0 0 0 #101010, inset 5.5em 0 0 0 #101010;
  border-radius: 5px;
  color: #fcaf17 !important;
}

.work-samples-sec {
  position: relative;
  width: 100%;
  min-height: 450px;
  /* background-image: url('../assets/img/work-sample-banner/work-screens.png'); */
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.req-for-demo-buttn {
  background-color: #fcaf17;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  transition: all 0.5s;
  border: 1px solid transparent;
  font-weight: 600;
  cursor: pointer;
}

.req-for-demo-buttn:hover {
  background-color: #e5e5e5;
  color: black !important;
  border-color: #e5e5e5;
  font-weight: 700;
}

.mt-25 {
  margin-top: 25px;
}
.our-clients-logo-sec {
  width: 100%;
  /* background-image: url("../assets/img/footerbg/footer-bg.jpg"); */
  background-position: center;
  background-repeat: repeat;
  min-height: 250px;
}

.our-clients-logo-sec .clients-logo {
  background-color: #cccccc;
  border-radius: 5px;
  text-align: center;
}

.our-clients-logo-sec .clients-logo img {
  width: 90%;
}
.video-player {
  position: absolute;
  width: 90%;
  min-height: 406px;
  left: 5%;
  top: 3%;
}

.features-sec-img {
  position: relative;
  margin-top: 24%;
  width: 100%;
  min-height: 507px;
  /* background-image: url("../assets/images/ecommerce/laptop-screen.png"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-form-bg {
  width: 100%;
  min-height: 100vh;
  background-image: url("../../assets/images/EbslonLandingPage/img/footerbg/footer-bg.jpg");
  background-position: center;
  background-repeat: repeat;
}

.contact-form-box1 {
  padding: 25px 30px 15px 30px;
  z-index: 99;
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 0 2px 0 rgb(145 158 171/20%),
    0 12px 24px -4px rgb(145 158 171/12%);
  border-radius: 24px;
  width: 100%;
margin-top: 15%;
}
.contact-form-box1 {
  padding: 25px 30px 15px 30px;
  z-index: 99;
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 0 2px 0 rgb(145 158 171/20%),
    0 12px 24px -4px rgb(145 158 171/12%);
  border-radius: 24px;
  width: 100%;
  /* margin-top: 15%; */
}

.contact-form-box1-inpt-box .inpt input {
  padding: 20px 79px 20px 27px;
  font-size: 18px;
  background: #f8f8f8 0 0 no-repeat padding-box !important;
  border-radius: 6px !important;
  border: 2px solid #f8f8f8 !important;
  outline: none !important;
}
.contact-form-box1-inpt-box .message-textarea1 {
  padding: 25px 79px 28px 27px;
  font-size: 18px;
  background: #f8f8f8 0 0 no-repeat padding-box !important;
  border-radius: 6px !important;
  border: 2px solid #f8f8f8 !important;
  outline: none !important;
}

.contact-form-box1-inpt-box .message-textarea1:focus {
  outline: none !important;
  box-shadow: none !important;
}

.back-buttn {
  cursor: pointer;
}

.back-buttn svg {
  color: black;
  font-size: 26px;
}

.contact-form-box1-body {
  text-align: center;
  /* padding: 20px 40px; */
}

.contact-form-box1-body .heading {
  font-size: 28px;
  font-weight: 600;
}

.contact-form-box1-body .desc {
  font-size: 18px;
  font-weight: 500;
  /* width: 89%;
  margin: 0 auto; */
}

.contact-form-box1-heading {
  margin-bottom: 30px;
}
/* .modal-header
{
  border-bottom: none!important;
  padding: 0px!important;
}

.modal-header .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 99999;
} */

.modal-body
{
  padding: 0px;
}


.contact-form-box1 {
  padding: 25px 25px 20px 25px;
  z-index: 99;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  /* margin-top: 10%; */
  border-radius: 5px;
}

.contact-form-box1 .form-head {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.contact-form-box1 .desc {
  font-size: 14px;
  font-weight: 600;
}

.contact-form-box1 .contact-form-body input {
  font-size: 14px;
  height: 45px;
}
.contact-form-box1 .contact-form-body textarea {
  font-size: 14px;
}
.contact-form-box1 .form-head {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
}

.whatsapp-icn {
  margin-bottom: 15px;
  margin-right: 15px;
}

.whatsapp-icn svg {
  color: #57cb6b;
  font-size: 34px;
}

.PhoneInputCountryIcon--border {
  background-color: transparent !important;
  box-shadow: none !important;
}

.PhoneInputCountry {
  margin-right: 0px !important;
  padding-right: 10px !important;
  margin-bottom: 8px !important;
}

.PhoneInputInput {
  border-color: #e5e5e5;
  box-shadow: none !important;
  outline: 0 !important;
  height: 60px;
  background-color: #f8f8f8;
  box-shadow: none !important;
  border-style: none !important;
  padding: 10px;
  font-size: 16px;
}

/* .PhoneInput
{
  margin-bottom: 15px;
} */

.phone-input-box {
  background-color: #f8f8f8;
  padding: 0px 10px;
  margin-bottom: 15px;
}

.t-c {
  text-align: center;
}

.mt-35 {
  margin-top: 35px !important;
}

/* whatsapp-buttn-box */

.whatsapp-buttn-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99999999;
  animation: floaty 4s ease-in-out infinite;
  cursor: pointer;
}

@keyframes floaty {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.d-f-j-c-sb {
  justify-content: space-between;
}

.pt-25 {
  padding-top: 25px;
}

.form .form-control {
  padding: 8px;
}

.form .form-control {
  padding: 8px;
}

.whatsapp-buttn-box {
  position: fixed;
  right: 14px;
  bottom: 14px;
  z-index: 99999999;
  animation: floaty 4s ease-in-out infinite;
  cursor: pointer;
}

.whatsapp-buttn-box-left {
  right: unset !important;
  left: 14px;
  bottom: 20px !important;
}

/* EbslonLandingPageUpdatecss */

.main-header1 {
  width: 100%;
  padding: 15px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #f5f5f5;
  z-index: 999999;
  position: absolute;
  top: 0;
}

.main-header1 .main-logo1 img {
  height: 45px;
  width: auto;
}

.main-banner-sec1 {
  width: 100%;
  background-color: #f5f5f5;
  min-height: 400px;
  position: relative;
  z-index: 1;
}

.main-banner-sec1 .main-banner-content1 {
  text-align: left;
}

.main-banner-sec1 .main-banner-content1 .main-heading {
  font-size: 62px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #212529 !important;
}

.main-banner-sec1 .main-banner-content1 .main-heading a {
  font-size: 62px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #212529 !important;
}

.main-banner-sec1 .main-banner-content1 .main-heading span {
  color: #fcaf17;
}

.main-banner-sec1 .main-banner-content1 {
  margin-top: 8%;
}
.main-banner-sec1 .main-banner-content1 h3 {
  color: #fcaf18;
}

.main-banner-sec1 .main-banner-content1 .desc {
  font-size: 24px;
}

/* main-banner-sec-app */

.main-banner-sec-app {
  width: 100%;
  background-color: #f5f5f5;
  min-height: 550px;
  position: relative;
  z-index: 1;
}
/* .main-banner-sec-app strong {
  color: #020202db;
} */

.main-banner-sec-app .main-banner-content1 {
  text-align: left;
}

.main-banner-sec-app .main-banner-content1 .main-heading {
  font-size: 58px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #212529 !important;
}

.main-banner-sec-app .main-banner-content1 .main-heading a {
  font-size: 58px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #212529 !important;
}

.main-banner-sec-app .main-banner-content1 .main-heading span {
  color: #fcaf17;
}

.main-banner-sec-app .main-banner-content1 {
  margin-top: 6%;
}
.main-banner-sec-app .main-banner-content1 h3 {
  color: #fcaf18;
}

.main-banner-sec-app .main-banner-content1 .desc {
  font-size: 18px;
}

.req-quote-buttn1 {
  animation: pulse-animation 2s infinite;
  background-color: #fcaf17;
  border-radius: 20px;
  box-shadow: 0 0 0 #fcaf17;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 24px;
  transition: all 0.5s;
  margin-left: 20px;
  cursor: pointer;
}

.mt-5-percent {
  margin-top: 5%;
}

.quick-enquiry-buttn1 {
  background-color: #fcaf18;
  padding: 18px 54px;
  border: 1px solid #fcaf17;
  color: white;
  font-weight: 600;
  border-radius: 40px;
  cursor: pointer;
  font-size: 20px;
}
.quick-enquiry-buttn1:hover {
  color: #fcaf17 !important;
  background-color: transparent !important;
  border: 1px solid #fcaf17;
}

.quick-enquiry-buttn1 span {
  background: white;
  color: #fcaf17;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  line-height: 35px;
  font-size: 18px;
  margin-left: 1%;
  text-align: center;
}


.our-offerings-sec {
  padding: 20px 0px;
}

.margin-top-5 {
  margin-top: 10%;
}
.req-quote-buttn1:hover {
  color: white !important;
}

.our-offerings-sec .padding-zeroo {
  padding: 0px;
}

.our-offerings-card {
  border-radius: 5px;
  padding: 15px;
  transition: all 0.4s;
  transform: scale(1);
  background-color: #fff;
  min-height: 301px;
  position: relative;
  transition: all 0.5s;
  cursor: pointer;
}

.our-offerings-card::after {
  content: "";
  position: absolute;
  left: 0;
  width: 0%;
  height: 4px;
  background-color: #fcaf17;
  bottom: 0;
}

.our-offerings-card:hover::after {
  width: 100%;
  transition: all 0.5s;
}

.brder-r {
  border-right: 1px solid #c9cede;
}
.brder-t {
  border-top: 1px solid #c9cede;
}

.our-offerings-card .our-offerings-card-icon {
  width: 75px;
  height: 75px;
  border-radius: 10%;
  text-align: center;
  line-height: 75px;
  margin-bottom: 20px;
}

.our-offerings-card .our-offerings-card-icon img {
  width: 64px;
  height: 64px;
}

.our-offerings-card h5 {
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
}
.our-offerings-card .desc {
  font-size: 17px;
  line-height: 25px;
}
.our-offerings-sec .our-offerings-main-head {
  text-align: center;
}

.our-offerings-sec .our-offerings-main-head .main-heading {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 15px;
}

.our-offerings-sec .our-offerings-main-head .main-heading span {
  color: #fcaf17;
}

.our-offerings-sec .our-offerings-card .our-offerings-card-icon1 img {
  width: 55%;
}
.our-offerings-sec .our-offerings-card .our-offerings-card-icon2 img {
  width: 55%;
}
.our-offerings-sec .our-offerings-card .our-offerings-card-icon3 img {
  width: 55%;
}
.our-offerings-sec .our-offerings-card .our-offerings-card-icon4 img {
  width: 55%;
}
.our-offerings-sec .our-offerings-card .our-offerings-card-icon5 img {
  width: 55%;
}

.our-offerings-sec .our-offerings-main-head {
  margin-bottom: 3%;
}

.our-offerings-sec .our-offerings-main-head .desc {
  width: 80%;
  margin: 0 auto;
  font-size: 15px;
  line-height: 25px;
}

.contact-us-banner-sec {
  width: 100%;
  min-height: 250px;
  /* background-color: #c9dcf8; */
  background-color: #d5dde9;
}

.contact-us-banner-content .main-heading {
  font-weight: 600;
  margin-bottom: 20px;
}

.contact-us-banner-content .desc {
  font-size: 18px;
}

.j-c-e {
  justify-content: flex-end;
  margin-top: 75px;
}

.contact-us-banner-sec .contact-us-banner-button .lets-discuss-button {
  background: #fcaf17;
  padding: 14px 40;
  padding: 14px 42px;
  color: white;
  border-radius: 5px;
  font-size: 18px;
}

.contact-us-banner-sec .contact-us-banner-button .lets-discuss-button:hover {
  color: white !important;
  background-color: #020202;
}

.contact-us-main-content .main-heading {
  font-size: 32px;
  font-weight: 600;
  color: white;
}

.why-choose-us-sec1 .j-c-sa {
  justify-content: space-around;
}

.why-choose-us-sec1 .why-choose-us-main-head {
  margin-top: 10%;
}
.why-choose-us-sec1 .why-choose-us-main-head .sub-heading {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 10px;
}
.why-choose-us-sec1 .why-choose-us-main-head .desc {
  margin-bottom: 25px;
}

.why-choose-us-sec1 .why-choose-us-main-head .main-heading {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 25px;
}

.why-choose-us-sec1 .why-choose-us-main-head .main-heading span {
  color: #fcaf17;
}

.why-choose-us-sec1 .why-choose-us-card1 {
  border: 3px solid #e5e5e5;
  border-radius: 14px;
  padding: 25px;
  background-color: transparent;
  text-align: center;
  transition: all 0.4s;
  transform: translateY(0px);
  cursor: pointer;
}

.why-choose-us-content-card {
  padding: 15px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e9dcc4;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  min-height: 180px;
}

.why-choose-us-content-card:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.4s;
  border-color: #fcaf17;
}

.why-choose-us-sec1 .why-choose-us-card1:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  transform: translateY(-10px);
  border: transparent;
}

.why-choose-us-sec1 .why-choose-us-content-card h5 {
  font-size: 18px;
}

.why-choose-us-sec1
  .why-choose-us-card1
  .why-choose-us-content
  .why-choose-us-content-list
  li
  svg {
  font-size: 24px;
  margin-right: 15px;
}

.why-choose-us-content-card .why-choose-us-card-img img {
  width: 67px;
  height: 67px;
}

.why-choose-us-content-card .why-choose-us-card-img {
  text-align: center;
  margin-bottom: 15px;
}

.j-c-sb {
  justify-content: space-between;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-17px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.contact-form-sec {
  width: 100%;
  background-image: url("../../assets/images/EbslonLandingPage/img/contact-form-banner1/contact-form.webp");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 95vh;
}
.contact-form-bg-white {
  width: 100%;
  background-image: url("../../assets/images/EbslonLandingPage/img/contact-form-banner1/contact-form.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 95vh;
}

.contact-form-box2 {
  padding: 25px 30px 15px 30px;
  z-index: 99;
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 0 2px 0 rgb(145 158 171/20%),
    0 12px 24px -4px rgb(145 158 171/12%);
  border-radius: 24px;
  width: 100%;
  margin-top: 0%;
}

.contact-form-box2-inpt-box .inpt input {
  padding: 15px 79px 15px 27px;
  font-size: 18px;
  background: #f8f8f8 0 0 no-repeat padding-box !important;
  border-radius: 6px !important;
  border: 2px solid #f8f8f8 !important;
  outline: none !important;
}
.contact-form-box2-inpt-box .message-textarea1 {
  padding: 15px 79px 15px 27px;
  font-size: 18px;
  background: #f8f8f8 0 0 no-repeat padding-box !important;
  border-radius: 6px !important;
  border: 2px solid #f8f8f8 !important;
  outline: none !important;
}

.contact-form-box2-inpt-box .message-textarea1:focus {
  outline: none !important;
  box-shadow: none !important;
}

.our-clients-sec {
  width: 100%;
}

.our-clients-sec .our-clients-sec-main-head {
  text-align: center;
}

.our-clients-sec .our-clients-sec-main-head .main-heading {
  color: #212529;
  font-size: 34px;
  font-weight: 500;
}
.our-clients-sec .our-clients-sec-main-head .desc {
  color: #212529;
  font-size: 18px;
}

.our-clients-sec .our-clients-sec-wrapper {
  /* padding: 30px; */
  position: relative;
}

.our-client-sec .py-20 {
  padding: 20px 0px;
}

.our-clients-sec .our-clients-sec-wrapper .py-40 {
  padding: 0px;
}

.our-clients-sec .our-clients-sec-wrapper .left-leaf {
  content: "";
  position: absolute;
}
.our-clients-sec .our-clients-sec-wrapper .right-leaf {
  content: "";
  position: absolute;
  right: 0;
}

.our-clients-sec-wrapper .our-clients-logo-img {
  text-align: center;
  /* border: 1px solid #e5e5e5; */
  padding: 10px;
  /* margin-bottom: 10px; */
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  cursor: pointer;
}

.our-clients-sec-wrapper .our-clients-logo-img img {
  height: 70px;
  object-fit: contain;
  /* margin: 10px 0px; */
  width: 150px;
}

/* porftolio-sec */
.portfolio-sec1 {
  background-color: #dfdfdf;
}

.portfolio-sec1 .portfolio-img {
  text-align: center;
  padding: 25px 0px;
}

.portfolio-sec1 .portfolio-img img {
  height: 300px;
}

.contact-us-banner-sec .contact-us-banner-img img {
  height: 230px;
  width: 226px;
}

.portfolio-sec1 .portfolio-sec-main-head .main-heading {
  font-size: 34px;
  text-align: center;
  text-transform: capitalize;
  color: #212529;
  font-weight: 500;
}

.portfolio-sec1 .portfolio-sec-main-head .main-heading span {
  color: #fcaf17;
}

.eleventh-banner1 {
  background-color: #7e7c7c;
  padding: 40px 0px 40px 0px;
}

/* eleventh-banner */

.eleventh-banner1 .eleventh-banner-content h3 {
  font-weight: 500;
  font-size: 32px;
  color: #fff;
  text-align: center;
}

.eleventh-banner1 .eleventh-banner-content {
  position: relative;
  padding: 20px 0px;
}

.eleventh-banner1 .eleventh-banner-content p {
  font-weight: 500;
  font-size: 24px;
  color: #fcaf17;
  text-align: center;
}

.eleventh-banner1 .eleventh-banner-content .btn {
  background-color: #fcaf17;
  padding: 10px 20px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

.eleventh-banner1 .eleventh-banner-content .btn:hover {
  color: #fcaf17 !important;
  background-color: transparent !important;
  border: 1px solid #fcaf17;
}

/* eigth-banner1 */

.eigth-banner1 {
  width: 100%;
  min-height: 450px;
  background-image: url("../../assets/images/EbslonLandingPage/img/footerbg/contact-bg.webp");
  background-position: center;
  position: relative;
  z-index: 1;
}

.eigth-banner1::after
{
  content: '';
  position: absolute;
  background-color: #000;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.white-overlay {
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.eigth-banner1 .main_content {
  z-index: 3;
  position: relative;
}

.eigth-banner1 .query_help {
  gap: 15px;
}

.eigth-banner1 h4 {
  font-weight: 500;
  font-size: 32px;
  color: #fcaf17;
  line-height: 30px;
  margin-bottom: 15px;
}
.eigth-banner1 .footer-content {
  margin-top: 25%;
}

.eigth-banner1 .desc {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}

.footer-form .form-select {
  min-height: 45px;
  color: #798189;
  cursor: pointer;
}

.form-select:focus {
  box-shadow: none !important;
  border-color: #fcaf17 !important;
}

.form-select option {
  min-height: 50px;
  font-size: 16px;
  padding: 10px;
}

.css-qbdosj-Input {
  min-height: 40px !important;
}

.eigth-banner1 .info_date h6 {
  color: white;
}

.eigth-banner1 .info_date a {
  color: #fcaf17;
}

.eigth-banner1 span h6 {
  font-size: 12px;
  opacity: 0.8;
  color: #212529;
  margin-bottom: 5px;
}

.eigth-banner1 span p {
  font-weight: 600;
  opacity: 1;
  margin: 0;
  font-size: 1.2rem;
  color: #fcaf17;
}

.eigth-banner1 ul h5 {
  font-size: 1.25rem;
  margin: 30px 0 0;
  font-weight: 500;
  color: #fff;
}

.eigth-banner1 .Q-form1 {
  background-color: #fff;
  padding: 30px 20px 25px 20px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
}

.eigth-banner1 .left .form-control {
  box-shadow: none;
  border: 1px solid #ccc;
  color: #5c5c5c;
}

.form .form-control {
  padding: 10px;
}

.form [class*="col-"] {
  margin-bottom: 25px;
}

.eigth-banner1 .submit-buttn {
  background-color: #fcaf17;
  color: white;
  width: 150px;
  height: 45px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
  text-align: center;
  line-height: 45px;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
}

.eigth-banner1 .submit-buttn:hover {
  background-color: #000;
}

.Lets-talk-buttn {
  background-color: #fcaf17;
  color: #fff;
  padding: 16px 52px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 600;
}

.t-center {
  text-align: center;
  margin-top: 30px;
}

.our-client-reviews {
  background-color: #f5f5f5;
  padding: 20px 0px;
}

.py-20 {
  padding: 20px 0px;
}

.our-client-reviews .our-client-reviews-main-head {
  text-align: center;
}

.our-client-reviews .our-client-reviews-main-head .main-heading {
  font-size: 34px;
  font-weight: 500;
}

.our-client-reviews .our-client-reviews-main-head .main-heading span {
  color: #fcaf17;
}

.our-client-reviews-card {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 5px;

  box-shadow: 0 0 20px rgba(51, 51, 51, 0.1);
  min-height: 331px;
}

.our-client-reviews-content .desc {
  font-size: 18px;
  padding: 10px;
}

.our-client-reviews-list {
  display: flex;
  align-items: center;
}

.client-reviews-inner-list li {
  margin-bottom: 10px;
}

.client-reviews-inner-list li .person-name {
  font-size: 20px;
  font-weight: 600;
}

.client-reviews-inner-list li svg {
  font-size: 24px;
  color: #fcaf17;
}

.technologies-sec{
  background-color: #fbf7ec;
}

.technologies-sec .technologies-sec-main-head {
  text-align: left;
  margin-top: 25%;
}

.technologies-sec .j-c-sb {
  justify-content: space-between;
}

.technologies-sec .technologies-sec-main-head .main-heading {
  font-size: 31px;
  font-weight: 500;
  margin-bottom: 15px;
}

.technologies-sec .technologies-sec-main-head .main-heading span {
  color: #fcaf17;
}

.technologies-sec .technologies-sec-main-head .sub-heading {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 10px;
  font-weight: 600;
}

.brder-r-tech {
  border-right: 1px solid #ddd;
}

.technologies-sec .padding-zeroo {
  padding: 0;
}

.brder-b-tech {
  border-bottom: 1px solid #ddd;
}

.technologies-card {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 140px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
}

.technologies-card img {
  height: 50px;
  object-fit: contain;
  width: 100px;
}

.py-40 {
  padding: 40px 0px;
}


.faq-sec1 {
  width: 100%;
}
.accordion-item {
  background-color: transparent !important;
  border-color: transparent !important;
}

.faq-sec1 .faq-sec-main-head1 .main-heading {
  font-size: 34px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 500;
}

.faq-sec-main-head1 .main-heading span {
  color: #fcaf17;
}

.m-auto {
  margin: 0 auto;
}

.faq-accordion .accordion-button:not(.collapsed) {
  color: #020202 !important;
  background-color: #f9f9f9 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.faq-accordion .accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none !important;
}

.faq-accordion .accordion-button {
  color: #000 !important;
  background-color: #f9f9f9 !important;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  width: 100%;
  border-radius: 0px;
  padding: 25px 25px 25px 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.faq-accordion .accordion-header svg {
  content: "";
  position: absolute;
  right: 2%;
  top: 35%;
}

.minus-icon {
  display: none;
}

.faq-accordion .accordion-item .accordion-header {
  background-color: #f9f9f9 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  margin-bottom: 20px;
  font-size: 16px;
  position: relative;
}

.faq-accordion .accordion-button:not(.collapsed) {
  background-color: #f9f9f9;
  color: #000 !important;
}

.faq-accordion .accordion-button::after {
  background-image: none !important;
}

/* .faq-accordion .accordion-body {
  color: #e5e5e5;
  margin-bottom: 15px;
  font-size: 16px;
  padding: 20px;
  border-radius: 5px;
} */

.faq-accordion .accordion-button:not(.collapsed) .minus-icon {
  display: block;
}

.faq-accordion .accordion-button:not(.collapsed) .plus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .minus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .plus-icon {
  display: block;
}

/* thankyoupage-css */

.thankyoupage-sec {
  /* margin-top: 10%; */
  min-height: 95vh;
  display: grid;
  place-items: center;
}

.thankyoupage-content {
  text-align: center;
}

.thankyoupage-content img {
  margin-bottom: 25px;
  width: 15%;
}

.thankyoupage-content .main-heading {
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.thankyoupage-content .desc {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 25px;
}

.thankyoupage-content .get-home-buttn {
  animation: pulse-animation 2s infinite;
  background-color: #fcaf17;
  border: 1px solid #fcaf17;
  padding: 12px 42px;
  border-radius: 5px;
  color: white;
  font-size: 20px;
}

.thankyoupage-content .t-center-mt-50 {
  margin-top: 50px;
  text-align: center;
}



/* responsive-css-for-EbslonLandingPageUpdate */

@media only screen and (min-width: 200px) and (max-width: 567px) {
  .fluid-padding .container-fluid {
    padding: 0px 0.5pc;
  }

  .popup-modal {
    display: none;
  }

  .main-header1 .main-logo1 img {
    height: 35px;
  }

  .req-quote-buttn1 {
    margin-left: 0px;
  }

  .header-contact-area {
    display: flex;
    justify-content: flex-end;
  }

  .main-header1 .main-logo img {
    width: 80%;
  }

  .mt-5-percent {
    margin-top: 0%;
    padding-bottom: 5%;
  }

  .contact-form-box1 .desc {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .margin-top-5 {
    margin-top: 15%;
  }

  .contact-form-box1 {
    margin-top: 12%;
    padding: 10px;
  }

  .contact-form-box1 .form-head {
    font-size: 18px !important;
    font-weight: 500 !important;
    margin-bottom: 10px;
  }

  .main-banner-sec1 .main-banner-content1 .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .main-banner-sec1 .main-banner-content1 .main-heading a {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  .main-banner-sec-app .main-banner-content1 .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .main-banner-sec-app .main-banner-content1 .main-heading a {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .our-clients-sec-wrapper .our-clients-logo-img img {
    margin: 0px;
  }
  .contact-us-banner-sec {
    text-align: center;
  }

  .contact-us-banner-sec .contact-us-banner-content {
    margin-top: 5%;
  }
  .contact-us-banner-content .main-heading {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 17px;
  }

  .contact-us-banner-content .desc {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .contact-us-banner-sec .contact-us-banner-button {
    margin-bottom: 15px;
  }

  .contact-us-banner-sec .contact-us-banner-button .lets-discuss-button {
    background: #fcaf17;
    padding: 14px 40;
    padding: 10px 12px;
    color: white;
    border-radius: 5px;
    font-size: 14px;
  }

  .main-banner-sec1 .main-banner-content1 .desc {
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 25px;
  }
  .main-banner-sec-app .main-banner-content1 .desc {
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 25px;
  }
  .quick-enquiry-buttn1 {
    padding: 8px 16px;
    border: 1px solid #fcaf17;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
  }

  .req-for-demo-buttn {
    background-color: #fcaf17;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    transition: all 0.5s;
    border: 1px solid transparent;
    font-weight: 500;
    cursor: pointer;
  }

  .quick-enquiry-buttn1 span {
    display: none;
  }

  .py-40 {
    padding: 15px 0px;
  }

  .our-clients-sec-wrapper .our-clients-logo-img {
    margin-bottom: 5px;
    padding: 5px;
  }

  .our-clients-sec .our-clients-sec-main-head .main-heading {
    color: #212529;
    font-size: 28px;
    font-weight: 500;
  }

  .our-clients-sec .our-clients-sec-main-head .desc {
    color: #212529;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .our-clients-sec .our-clients-sec-wrapper {
    padding: 0px;
    position: relative;
  }

  .our-offerings-sec .our-offerings-main-head .main-heading {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .our-offerings-card-content {
    text-align: center;
    margin-top: 18%;
  }

  .our-offerings-sec .our-offerings-main-head {
    text-align: left;
  }

  .our-offerings-sec .our-offerings-main-head .desc {
    width: 100%;
    margin: 0 auto;
    font-size: 15px;
    line-height: 20px;
  }

  .our-offerings-card {
    padding: 15px 10px;
  }

  .our-offerings-card .our-offerings-card-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    text-align: center;
    line-height: 75px;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .our-offerings-card .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .our-offerings-card h5 {
    font-size: 18px;
  }

  .technologies-sec .technologies-sec-main-head {
    margin-top: 5%;
  }

  .technologies-sec .technologies-sec-main-head .sub-heading {
    font-size: 16px;
    width: 100%;
    line-height: 25px;
  }

  .technologies-sec .technologies-sec-main-head .main-heading {
    margin-bottom: 10px;
    font-size: 20px;
  }

  .our-offerings-card {
    min-height: 210px;
    margin-bottom: 20px;
  }

  .our-offerings-card:hover::after {
    width: 0%;
  }
  .brder-t {
    border-top: none;
  }
  .brder-r {
    border-right: none;
  }

  .why-choose-us-sec1 .why-choose-us-main-head .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .why-choose-us-sec1 .why-choose-us-main-head .sub-heading {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }

  .why-choose-us-sec1 .why-choose-us-main-head .desc {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
  }

  .why-choose-us-content-card {
    min-height: 180px;
  }

  .portfolio-sec1 .portfolio-sec-main-head .main-heading {
    font-size: 21px;
  }

  .portfolio-content {
    padding: 25px 10px 30px 10px;
    margin: 0 auto;
    width: 90%;
    min-height: 240px;
  }

  .portfolio-content .heading {
    line-height: 20px !important;
  }

  .portfolio-sec1 .portfolio-img {
    padding: 0px 0px 20px 0px;
  }

  .portfolio-sec1 .portfolio-img img {
    height: 225px;
    object-fit: contain;
    width: 100%;
  }

  .portfolio-content .heading span {
    color: #fcaf17;
    font-size: 20px;
    text-transform: capitalize;
    letter-spacing: 2px;
    font-weight: 500;
  }

  .Lets-talk-buttn {
    padding: 12px 24px;
    font-size: 15px;
    font-weight: 500;
  }

  .portfolio-content .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .our-client-reviews .our-client-reviews-main-head .main-heading {
    font-size: 21px;
  }

  .main-banner-sec1 .main-banner-content1 {
    margin-top: 25%;
  }
  /* .main-banner-sec-app .main-banner-content1 {
  margin-top: 25%;
} */

  .main-banner-sec-app {
    min-height: 480px;
  }

  .our-client-reviews-card {
    padding: 15px 10px;
    margin: 0px 15px;
    min-height: 305px;
  }
  .client-reviews-inner-list li .person-name {
    font-size: 18px;
  }
  .faq-sec1 .faq-sec-main-head1 .main-heading {
    font-size: 18px;
    font-weight: 500;
  }

  .faq-sec1 .faq-accordion .accordion-button {
    padding: 10px 15px 10px 15px;
    font-size: 12px;
  }

  .faq-sec1 .faq-accordion .accordion-body {
    font-size: 14px;
    padding: 10px;
  }

  .our-client-reviews-content .desc {
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
    margin-bottom: 10px;
  }

  .eigth-banner1 .Q-form1 {
    padding: 15px;
    width: 100%;
  }

  .buttn {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 32px;
    letter-spacing: 1px;
    display: block;
    text-align: center;
  }

  .buttn:hover {
    box-shadow: none;
    color: white !important;
  }

  .other-services1 {
    margin-top: 5%;
  }

  .other-services1 .main-heading {
    font-size: 24px;
    margin-bottom: 15px;
    color: #212529;
    font-weight: 500;
  }

  .other-services1 .other-services-list li {
    margin-right: 15px;
  }

  .other-services1 .other-services-list li h6 {
    color: #212529;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .other-services1 .other-services-list li .desc {
    margin-bottom: 5px;
  }

  .copyright-area .copyright-text {
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }

  .Q-form1 .left h5 {
    margin-bottom: 15px;
  }

  .form [class*="col-"] {
    margin-bottom: 10px;
  }

  .eigth-banner1 h4 {
    font-weight: 500;
    font-size: 24px;
    color: #fcaf17;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .why-choose-us-sec1 .why-choose-us-main-head {
    margin-bottom: 10%;
  }

  .enquiry-now-buttn {
    padding: 10px 32px;
    font-size: 14px;
  }

  /* thankyoupage-responsive-200 */

  .thankyoupage-sec {
    /* margin-top: 50%; */
  }

  .thankyoupage-content img {
    margin-bottom: 25px;
    width: 50%;
  }

  .thankyoupage-content .main-heading {
    font-size: 23px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .thankyoupage-content .desc {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 20px;
  }

  .thankyoupage-content .get-home-buttn {
    padding: 10px 42px;
    font-size: 16px;
    color: white !important;
  }

  .thankyoupage-content .t-center-mt-50 {
    margin-top: 30px;
    text-align: center;
  }
}

@media only screen and (min-width: 568px) and (max-width: 992px) {
  .main-banner-sec-app {
    min-height: 450px;
  }
  .fluid-padding .container-fluid {
    padding: 0px 0.5pc;
  }

  .popup-modal {
    display: none;
  }

  .header-contact-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .main-banner-sec1 .main-banner-content1 .main-heading {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .main-banner-sec1 .main-banner-content1 .main-heading a {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .main-banner-sec-app .app-development-img {
    margin-top: 15%;
  }

  .main-banner-sec-app .main-banner-content1 {
    margin-top: 5%;
  }

  .main-banner-sec-app .main-banner-content1 .main-heading {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .main-banner-sec-app .main-banner-content1 .main-heading a {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .our-clients-sec-wrapper .our-clients-logo-img img {
    margin: 0px;
  }
  .contact-us-banner-sec {
    display: none;
  }
  .main-banner-sec1 .main-banner-content1 .desc {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .main-banner-sec-app .main-banner-content1 .desc {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .quick-enquiry-buttn1 {
    padding: 12px 16px;
    border: 1px solid #fcaf17;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
  }

  .req-for-demo-buttn {
    background-color: #fcaf17;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    transition: all 0.5s;
    border: 1px solid transparent;
    font-weight: 500;
    cursor: pointer;
  }

  .quick-enquiry-buttn1 span {
    display: none;
  }

  .contact-form-box1 {
    margin-top: 20%;
  }

  .py-40 {
    padding: 20px 0px;
  }

  .our-clients-sec .our-clients-sec-main-head .main-heading {
    color: #212529;
    font-size: 28px;
    font-weight: 500;
  }

  .our-clients-sec .our-clients-sec-main-head .desc {
    color: #212529;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .our-clients-sec .our-clients-sec-wrapper {
    padding: 0px;
    position: relative;
  }

  .our-offerings-sec .our-offerings-main-head .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .our-offerings-card-content {
    margin-top: 15%;
  }

  .our-offerings-card {
    border-radius: 18px;
    padding: 15px 10px;
  }

  .our-offerings-card .our-offerings-card-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    text-align: center;
    line-height: 75px;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .our-offerings-card h5 {
    font-size: 20px;
  }

  .our-offerings-sec .our-offerings-main-head .desc {
    width: 100%;
    margin: 0 auto;
  }

  .our-offerings-card {
    min-height: 256px;
  }

  .our-offerings-card:hover::after {
    width: 0%;
  }
  .brder-t {
    border-top: none;
  }
  .brder-r {
    border-right: none;
  }
  .technologies-sec .technologies-sec-main-head {
    margin-top: 0%;
    text-align: center;
  }

  .technologies-sec .technologies-sec-main-head .main-heading {
    margin-bottom: 5px;
    font-size: 23px;
  }
  .technologies-sec .desc {
    margin: 0 auto;
    width: 73%;
    font-size: 14px;
    line-height: 20px;
  }

  .technologies-sec .technologies-sec-main-head .sub-heading {
    width: 100%;
    margin-bottom: 5px;
  }

  .why-choose-us-sec1 .why-choose-us-main-head {
    margin-bottom: 5%;
  }

  .why-choose-us-sec1 .why-choose-us-main-head .desc {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    width: 72%;
    margin: 0 auto;
    margin-bottom: 25px;
  }
  .why-choose-us-sec1 .why-choose-us-main-head .sub-heading {
    width: 88%;
    font-size: 14px;
    margin: 0 auto;
  }

  .why-choose-us-sec1 .why-choose-us-main-head {
    margin-top: 2%;
    text-align: center;
  }

  .why-choose-us-sec1 .why-choose-us-main-head .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .why-choose-us-sec1
    .why-choose-us-main-content
    .why-choose-us-content-card
    h5 {
    font-size: 16px;
  }

  .why-choose-us-content-card {
    min-height: 155px;
    padding: 10px;
  }

  .portfolio-sec1 .portfolio-sec-main-head .main-heading {
    font-size: 21px;
  }

  .portfolio-content {
    padding: 15px 10px;
    width: 100%;
  }

  .portfolio-sec1 .portfolio-img img {
    width: 100%;
    height: 300px;
  }

  .technologies-sec {
    z-index: 9999;
    position: relative;
  }

  .portfolio-content .heading span {
    color: #fcaf17;
    font-size: 20px;
    text-transform: capitalize;
    letter-spacing: 2px;
    font-weight: 500;
  }

  .Lets-talk-buttn {
    padding: 12px 24px;
    font-size: 15px;
    font-weight: 500;
  }

  .portfolio-content .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .our-client-reviews .our-client-reviews-main-head .main-heading {
    font-size: 26px;
  }

  .main-banner-sec1 .main-banner-content1 {
    margin-top: 25%;
  }

  /* .main-banner-sec-app .main-banner-content1 {
margin-top: 25%;
} */

  .contact-form-box1 {
    padding: 20px 20px 20px 20px;
  }

  .j-c-sa {
    justify-content: space-around;
  }

  .our-client-reviews-card {
    padding: 15px 10px;
    margin: 0px 15px;
    min-height: 305px;
  }
  .client-reviews-inner-list li .person-name {
    font-size: 16px;
  }
  .faq-sec1 .faq-sec-main-head1 .main-heading {
    font-size: 22px;
    font-weight: 500;
  }

  .faq-sec1 .faq-accordion .accordion-button {
    padding: 15px;
    font-size: 15px;
  }

  .faq-sec1 .faq-accordion .accordion-body {
    font-size: 14px;
    padding: 10px;
  }

  .our-client-reviews-content .desc {
    font-size: 16px;
    padding: 5px;
    margin-bottom: 10px;
  }

  .eigth-banner1 .Q-form1 {
    padding: 15px;
    width: 100%;
  }

  .buttn {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 32px;
    letter-spacing: 1px;
    display: block;
    text-align: center;
  }

  .buttn:hover {
    box-shadow: none;
    color: white !important;
  }

  .other-services1 {
    margin-top: 5%;
  }

  .other-services1 .main-heading {
    font-size: 24px;
    margin-bottom: 15px;
    color: #212529;
    font-weight: 500;
  }

  .other-services1 .other-services-list li {
    margin-right: 15px;
  }

  .other-services1 .other-services-list li h6 {
    color: #212529;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .other-services1 .other-services-list li .desc {
    margin-bottom: 5px;
  }

  .copyright-area .copyright-text {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
  }

  .Q-form1 .left h5 {
    margin-bottom: 15px;
  }

  .form [class*="col-"] {
    margin-bottom: 10px;
  }

  .eigth-banner1 h4 {
    font-weight: 500;
    font-size: 24px;
    color: #fcaf17;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .enquiry-now-buttn {
    padding: 10px 32px;
    font-size: 18px;
  }

  .contact-form-box1 .desc {
    font-size: 15px;
    font-weight: 500;
  }

  /* thankyoupage-responsive-568px */


  .thankyoupage-content img {
    margin-bottom: 25px;
    width: 40%;
  }

  .thankyoupage-content .get-home-buttn {
    color: white !important;
  }

  .thankyoupage-content .t-center-mt-50 {
    margin-top: 30px;
    text-align: center;
  }

  .main-header1 .main-logo1 img {
    height: 45px;
  }

  .contact-form-sec {
    min-height: 96vh;
  }

  .contact-form-box1 .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .contact-form-box1 .form-head {
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
  }



}

@media only screen and (min-width: 993px) and (max-width: 1365px) {
  .main-banner-sec-app {
    min-height: 450px;
  }
  .fluid-padding .container-fluid {
    padding: 0px 0.5pc;
  }

  .popup-modal {
    display: none;
  }

  .header-contact-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .main-banner-sec1 .main-banner-content1 .main-heading {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .main-banner-sec1 .main-banner-content1 .main-heading a {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .main-banner-sec-app .main-banner-content1 {
    margin-top: 5%;
  }

  .main-banner-sec-app .main-banner-content1 .main-heading {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .main-banner-sec-app .main-banner-content1 .main-heading a {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .our-clients-sec-wrapper .our-clients-logo-img img {
    margin: 0px;
  }
  .contact-us-banner-sec {
    display: none;
  }
  .main-banner-sec1 .main-banner-content1 .desc {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .main-banner-sec-app .main-banner-content1 .desc {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .quick-enquiry-buttn1 {
    padding: 12px 16px;
    border: 1px solid #fcaf17;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
  }

  .req-for-demo-buttn {
    background-color: #fcaf17;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    transition: all 0.5s;
    border: 1px solid transparent;
    font-weight: 500;
    cursor: pointer;
  }

  .quick-enquiry-buttn1 span {
    display: none;
  }

  .contact-form-box1 {
    margin-top: 20%;
  }

  .py-40 {
    padding: 20px 0px;
  }

  .our-clients-sec .our-clients-sec-main-head .main-heading {
    color: #212529;
    font-size: 28px;
    font-weight: 500;
  }

  .our-clients-sec .our-clients-sec-main-head .desc {
    color: #212529;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .our-clients-sec .our-clients-sec-wrapper {
    padding: 0px;
    position: relative;
  }

  .our-clients-sec .our-clients-sec-wrapper .py-40 {
    padding: 0px;
  }

  .our-offerings-sec .our-offerings-main-head .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .our-offerings-sec .our-offerings-main-head {
    text-align: center;
  }
  .our-offerings-sec .our-offerings-main-head .desc {
    width: 83%;
    margin: 0 auto;
  }

  .our-offerings-card {
    min-height: 256px;
    padding: 10px;
  }

  .our-offerings-card .our-offerings-card-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    text-align: center;
    line-height: 75px;
    position: absolute;
    top: -35px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .our-offerings-card h5 {
    font-size: 18px;
  }

  .our-offerings-card .desc {
    font-size: 15px;
    line-height: 20px;
  }

  .brder-t {
    border-top: none;
  }
  .brder-r {
    border-right: none;
  }

  .our-offerings-card-content {
    text-align: center;
    margin-top: 18%;
  }

  .our-offerings-card:hover::after {
    width: 0%;
  }

  .technologies-sec .technologies-sec-main-head .main-heading {
    margin-bottom: 5px;
    font-size: 23px;
  }
  .technologies-sec .desc {
    margin: 0 auto;
    width: 73%;
    font-size: 14px;
    line-height: 20px;
  }

  .technologies-sec .technologies-sec-main-head .sub-heading {
    width: 100%;
    margin-bottom: 5px;
  }

  .technologies-sec .technologies-sec-main-head {
    margin-top: 2%;
    text-align: center;
  }

  .why-choose-us-sec1 .why-choose-us-main-head .desc {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    width: 72%;
    margin: 0 auto;
    margin-bottom: 25px;
  }
  .why-choose-us-sec1 .why-choose-us-main-head .sub-heading {
    width: 80%;
    font-size: 14px;
    margin: 0 auto;
  }

  .why-choose-us-sec1 .why-choose-us-main-head {
    margin-top: 2%;
    text-align: center;
  }

  .why-choose-us-sec1 .why-choose-us-main-head .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .why-choose-us-content-card {
    min-height: 155px;
    padding: 10px;
  }

  .portfolio-sec1 .portfolio-sec-main-head .main-heading {
    font-size: 21px;
  }

  .portfolio-content {
    padding: 15px 10px;
    width: 100%;
  }

  .portfolio-sec1 .portfolio-img img {
    width: 80%;
    height: 300px;
  }

  .technologies-sec {
    z-index: 9999;
    position: relative;
  }

  .portfolio-content .heading span {
    color: #fcaf17;
    font-size: 20px;
    text-transform: capitalize;
    letter-spacing: 2px;
    font-weight: 500;
  }

  .Lets-talk-buttn {
    padding: 12px 24px;
    font-size: 15px;
    font-weight: 500;
  }

  .portfolio-content .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .our-client-reviews .our-client-reviews-main-head .main-heading {
    font-size: 26px;
  }

  .main-banner-sec1 .main-banner-content1 {
    margin-top: 35%;
  }

  .contact-form-box1 {
    padding: 15px;
  }

  .j-c-sa {
    justify-content: space-around;
  }

  .our-client-reviews-card {
    padding: 15px 10px;
    margin: 0px 15px;
    min-height: 362px;
  }
  .client-reviews-inner-list li .person-name {
    font-size: 18px;
  }
  .faq-sec1 .faq-sec-main-head1 .main-heading {
    font-size: 22px;
    font-weight: 500;
  }

  .faq-sec1 .faq-accordion .accordion-button {
    padding: 15px;
    font-size: 15px;
  }

  .faq-sec1 .faq-accordion .accordion-body {
    font-size: 14px;
    padding: 10px;
  }

  .our-client-reviews-content .desc {
    font-size: 16px;
    padding: 5px;
    margin-bottom: 10px;
  }

  .eigth-banner1 .Q-form1 {
    padding: 15px;
    width: 100%;
  }

  .buttn {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 32px;
    letter-spacing: 1px;
    display: block;
    text-align: center;
  }

  .buttn:hover {
    box-shadow: none;
    color: white !important;
  }

  .other-services1 {
    margin-top: 5%;
  }

  .other-services1 .main-heading {
    font-size: 24px;
    margin-bottom: 15px;
    color: #212529;
    font-weight: 500;
  }

  .other-services1 .other-services-list li {
    margin-right: 15px;
  }

  .other-services1 .other-services-list li h6 {
    color: #212529;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .other-services1 .other-services-list li .desc {
    margin-bottom: 5px;
  }

  .copyright-area .copyright-text {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
  }

  .Q-form1 .left h5 {
    margin-bottom: 15px;
  }

  .form [class*="col-"] {
    margin-bottom: 10px;
  }

  .eigth-banner1 h4 {
    font-weight: 500;
    font-size: 24px;
    color: #fcaf17;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .why-choose-us-sec1 .why-choose-us-main-head {
    margin-bottom: 5%;
  }

  .why-choose-us-sec1
    .why-choose-us-main-content
    .why-choose-us-content-card
    h5 {
    font-size: 16px;
  }

  .enquiry-now-buttn {
    padding: 10px 32px;
    font-size: 16px;
  }

  /* thankyoupage-responsive-993px */



  .thankyoupage-content img {
    margin-bottom: 25px;
    width: 35%;
  }

  .thankyoupage-content .get-home-buttn {
    color: white !important;
  }

  .thankyoupage-content .t-center-mt-50 {
    margin-top: 40px;
    text-align: center;
  }

  .main-header1 .main-logo1 img {
    height: 45px;
  }

  .contact-form-box1 .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }



}

/* 1366px to 1367px */

@media only screen and (min-width: 1366px) and (max-width: 1367px) {
  .main-banner-sec-app {
    min-height: 450px;
  }
  .fluid-padding .container-fluid {
    padding: 0px 0.5pc;
  }

  .popup-modal {
    display: none;
  }

  .header-contact-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .main-banner-sec1 .main-banner-content1 .main-heading {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .main-banner-sec1 .main-banner-content1 .main-heading a {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .main-banner-sec-app .main-banner-content1 {
    margin-top: 15%;
  }

  .main-banner-sec-app .main-banner-content1 .main-heading {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .main-banner-sec-app .main-banner-content1 .main-heading a {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .our-clients-sec-wrapper .our-clients-logo-img img {
    margin: 0px;
  }
  .contact-us-banner-sec {
    display: none;
  }
  .main-banner-sec1 .main-banner-content1 .desc {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .main-banner-sec-app .main-banner-content1 .desc {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .quick-enquiry-buttn1 {
    padding: 12px 16px;
    border: 1px solid #fcaf17;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
  }

  .req-for-demo-buttn {
    background-color: #fcaf17;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    transition: all 0.5s;
    border: 1px solid transparent;
    font-weight: 500;
    cursor: pointer;
  }

  .quick-enquiry-buttn1 span {
    display: none;
  }

  .contact-form-box1 {
    margin-top: 20%;
  }

  .py-40 {
    padding: 20px 0px;
  }

  .our-clients-sec .our-clients-sec-main-head .main-heading {
    color: #212529;
    font-size: 28px;
    font-weight: 500;
  }

  .our-clients-sec .our-clients-sec-main-head .desc {
    color: #212529;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .our-clients-sec .our-clients-sec-wrapper {
    padding: 0px;
    position: relative;
  }

  .our-clients-sec .our-clients-sec-wrapper .py-40 {
    padding: 0px;
  }

  .our-offerings-sec .our-offerings-main-head .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .our-offerings-sec .our-offerings-main-head {
    text-align: center;
  }
  .our-offerings-sec .our-offerings-main-head .desc {
    width: 83%;
    margin: 0 auto;
  }

  .our-offerings-card {
    min-height: 256px;
    padding: 10px;
  }

  .our-offerings-card .our-offerings-card-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    text-align: center;
    line-height: 75px;
    position: absolute;
    top: -35px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .our-offerings-card h5 {
    font-size: 18px;
  }

  .our-offerings-card .desc {
    font-size: 15px;
    line-height: 20px;
  }

  .brder-t {
    border-top: none;
  }
  .brder-r {
    border-right: none;
  }

  .our-offerings-card-content {
    text-align: center;
    margin-top: 18%;
  }

  .our-offerings-card:hover::after {
    width: 0%;
  }

  .technologies-sec .technologies-sec-main-head .main-heading {
    margin-bottom: 5px;
    font-size: 23px;
  }
  .technologies-sec .desc {
    margin: 0 auto;
    width: 73%;
    font-size: 14px;
    line-height: 20px;
  }

  .technologies-sec .technologies-sec-main-head .sub-heading {
    width: 100%;
    margin-bottom: 5px;
  }

  .technologies-sec .technologies-sec-main-head {
    margin-top: 2%;
    text-align: center;
  }

  .why-choose-us-sec1 .why-choose-us-main-head .desc {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    width: 72%;
    margin: 0 auto;
    margin-bottom: 25px;
  }
  .why-choose-us-sec1 .why-choose-us-main-head .sub-heading {
    width: 80%;
    font-size: 14px;
    margin: 0 auto;
  }

  .why-choose-us-sec1 .why-choose-us-main-head {
    margin-top: 2%;
    text-align: center;
  }

  .why-choose-us-sec1 .why-choose-us-main-head .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .why-choose-us-content-card {
    min-height: 155px;
    padding: 10px;
  }

  .portfolio-sec1 .portfolio-sec-main-head .main-heading {
    font-size: 21px;
  }

  .portfolio-content {
    padding: 15px 10px;
    width: 100%;
  }

  .portfolio-sec1 .portfolio-img img {
    /* width: 80%; */
    height: 300px;
  }

  .technologies-sec {
    z-index: 9999;
    position: relative;
  }

  .portfolio-content .heading span {
    color: #fcaf17;
    font-size: 20px;
    text-transform: capitalize;
    letter-spacing: 2px;
    font-weight: 500;
  }

  .Lets-talk-buttn {
    padding: 12px 24px;
    font-size: 15px;
    font-weight: 500;
  }

  .portfolio-content .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .our-client-reviews .our-client-reviews-main-head .main-heading {
    font-size: 26px;
  }

  .main-banner-sec1 .main-banner-content1 {
    margin-top: 35%;
  }

  .contact-form-box1 {
    padding: 15px;
  }

  .j-c-sa {
    justify-content: space-around;
  }

  .our-client-reviews-card {
    padding: 15px 10px;
    margin: 0px 15px;
    min-height: 362px;
  }
  .client-reviews-inner-list li .person-name {
    font-size: 18px;
  }
  .faq-sec1 .faq-sec-main-head1 .main-heading {
    font-size: 22px;
    font-weight: 500;
  }

  .faq-sec1 .faq-accordion .accordion-button {
    padding: 15px;
    font-size: 15px;
  }

  .faq-sec1 .faq-accordion .accordion-body {
    font-size: 14px;
    padding: 10px;
  }

  .our-client-reviews-content .desc {
    font-size: 16px;
    padding: 5px;
    margin-bottom: 10px;
  }

  .eigth-banner1 .Q-form1 {
    padding: 15px;
    width: 100%;
    margin-top: 10%;
  }

  .buttn {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 32px;
    letter-spacing: 1px;
    display: block;
    text-align: center;
  }

  .buttn:hover {
    box-shadow: none;
    color: white !important;
  }

  .other-services1 {
    margin-top: 5%;
  }

  .other-services1 .main-heading {
    font-size: 24px;
    margin-bottom: 15px;
    color: #212529;
    font-weight: 500;
  }

  .other-services1 .other-services-list li {
    margin-right: 15px;
  }

  .other-services1 .other-services-list li h6 {
    color: #212529;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .other-services1 .other-services-list li .desc {
    margin-bottom: 5px;
  }

  .copyright-area .copyright-text {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
  }

  .Q-form1 .left h5 {
    margin-bottom: 15px;
  }

  .form [class*="col-"] {
    margin-bottom: 10px;
  }

  .eigth-banner1 h4 {
    font-weight: 500;
    font-size: 24px;
    color: #fcaf17;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .why-choose-us-sec1 .why-choose-us-main-head {
    margin-bottom: 5%;
  }

  .why-choose-us-sec1
    .why-choose-us-main-content
    .why-choose-us-content-card
    h5 {
    font-size: 16px;
  }

  .enquiry-now-buttn {
    padding: 10px 32px;
    font-size: 16px;
  }

  /* thankyoupage-responsive-993px */

  .thankyoupage-content img {
    margin-bottom: 25px;
    width: 35%;
  }

  .thankyoupage-content .get-home-buttn {
    color: white !important;
  }

  .thankyoupage-content .t-center-mt-50 {
    margin-top: 40px;
    text-align: center;
  }

  .main-header1 .main-logo1 img {
    height: 45px;
  }

  .contact-form-box1 .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
