.our_approach
{
    padding: 60px 0px;
    background-color:#0C0C0C;

    .main_heading
    {
        font-size: 32px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 20px;
    }

    .desc
    {
        font-weight: 500;
        color: #fff;
        font-size: 18px;
    }

    .pt_40
    {
        padding-top: 40px;
        justify-content: space-between;
    }

  .sidebar
  {
    .list
    {
        li
        {
            button
            {
                padding: 15px 25px;
                color: #fff;
                box-shadow: none;
                border-right: 1px solid transparent;
                background: transparent;
                border: none;
               

                // &:hover
                // {
                //     background: linear-gradient(90deg,rgba(30,30,30,1) 0%,rgba(51,51,51,1) 100%);
                //     border-right: 2px solid #fcaf17!important;
                // }

            }

            .tabactive
            {
                background: linear-gradient(90deg,rgba(30,30,30,1) 0%,rgba(51,51,51,1) 100%);
                border-right: 2px solid #fcaf17!important;
            }
        }
    }
  }

  .content
  {
    background-color: #262625;
    padding: 5% 45px 25px 45px;
    min-height: 275px;

    h5
    {
        color: #fff;
        margin-bottom: 20px;
    }

    p
    {
        color: #fff;
    }
  }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .our_approach
    {
        padding: 20px 0px;

        .main_heading
        {
            font-size: 20px;
            margin-bottom: 10px;
        }

        .desc
        {
            font-size: 14px;
        }

        .pt_40
        {
            padding-top: 20px;
        }

        .sidebar 
        {
            margin-bottom: 10px;
            .list 
            {
                li 
                {
                    button {
                        padding: 8px 20px;
                        font-size: 14px;
                    }
                }
            }
        }

        .content {
            background-color: #262625;
            padding: 30px 15px 20px 15px;
            min-height: 175px;

            h5
            {
                margin-bottom: 10px;
                font-size: 16px;
            }

            p
            {
                font-size: 13px;
            }
        }
    }
}


@media only screen and (min-width:768px) and (max-width:991px) {
    .our_approach
    {
        padding: 30px 0px;

        .main_heading
        {
            font-size: 24px;
            margin-bottom: 15px;
        }

        .desc
        {
            font-size: 14px;
        }

        .pt_40
        {
            padding-top: 20px;
        }

        .sidebar 
        {
            margin-bottom: 10px;
            .list 
            {
                li 
                {
                    button {
                        padding: 8px 20px;
                        font-size: 14px;
                    }
                }
            }
        }

        .content {
            background-color: #262625;
            padding: 30px 15px 20px 15px;
            min-height: 175px;

            h5
            {
                margin-bottom: 10px;
                font-size: 18px;
            }

            p
            {
                font-size: 14px;
            }
        }
    }
}