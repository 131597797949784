.why_choose_sec
{
    background-color: #f2f2f2;
    padding: 40px 0px;

    .heading_box {
        text-align: center;
    
        .main_heading {
          font-size: 32px;
          margin-bottom: 15px;
          font-weight: 600;
    
          span {
            color: #fcaf17;
          }
        }
    
        .desc {
          font-size: 16px;
          width: 80%;
          margin: 0 auto;
        }
      }

      .pt_40
      {
        padding-top: 40px;
      }

      .card {
        background-color: #fff;
        padding: 20px 15px;
        transition: 0.2s ease-in-out;
        margin-bottom: 25px;
        min-height: 270px;
    
        &:hover {
          transform: translateY(-10px);
          cursor: pointer;
        }
    
        .image {
            
          width: 50px;
          height: 50px;

          img
          {
            width: 100%;
            height: 100%;
          }
        }
    
        .content {
          h5 {
            margin-top: 15px;
            margin-bottom: 10px;
            font-size: 18px;
          }
    
          .desc {
            font-size: 16px;
            line-height: 25px;
          }
        }
      }
}


@media only screen and (min-width:320px) and (max-width:767px) {
  .why_choose_sec
  {
    padding: 20px 0px;

    .heading_box
    {
        .main_heading
        {
            font-size: 18px;
            margin-bottom: 10px;
        }

        .desc
        {
            font-size: 14px;
width: 100%;
margin: 0 auto;
line-height: 20px;
        }
    }

    .pt_40
    {
      padding-top: 20px;
    }
  }
}


@media only screen and (min-width:768px) and (max-width:991px) {
  .why_choose_sec
  {
    padding: 30px 0px;

    .heading_box
    {
        .main_heading
        {
            font-size: 24px;
            margin-bottom: 10px;
        }

        .desc
        {
            font-size: 14px;
width: 100%;
margin: 0 auto;
line-height: 20px;
        }
    }

    .pt_40
    {
      padding-top: 30px;
    }

    .card
    {
      min-height: 255px;
    }
  }
}


@media only screen and (min-width:992px) and (max-width:1440px) {
  .why_choose_sec
  {
    padding: 30px 0px;

    .heading_box
    {
        .main_heading
        {
            font-size: 28px;
            margin-bottom: 10px;
        }

        .desc
        {
            font-size: 14px;
width: 100%;
margin: 0 auto;
line-height: 20px;
        }
    }

    .pt_40
    {
      padding-top: 30px;
    }

    .card
    {
      min-height: 270px;
      margin-bottom: 20px;

      .content
      {
        h5
        {
          font-size: 17px;
        }

        .desc
        {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}