.our_approach
{
    padding: 40px 0px 60px 0px;


    .main_heading
    {
        font-size: 36px;
        font-weight: 600;
        color: #212529;
        margin-bottom: 20px;
        text-align: center;
    }

    .desc
    {
        font-weight: 400;
        color: #000;
        font-size: 16px;
        text-align: center;
    }

    .pt_50
    {
        padding-top: 50px;
        justify-content: space-evenly;
    }

  .sidebar
  {
    .list
    {

     
        li
        {
           
           
            button
            {
                padding: 15px 15px 25px 15px;
                color: #383838;
                box-shadow: none;
                border-left: 1px solid #C3C3C3!important;
                background: transparent;
                border: none;
                font-weight: 500;
                font-size: 18px;
                text-align: left;

               

                &:hover
                {
                   color: #fcaf17;
                }

            }

            .tabactive
            {
              
                border-left: 4px solid #fcaf17!important;
                color: #fcaf17!important;
            }
        }
    }
  }

  .content
  {
    padding: 3% 25px 25px 25px;
   

    .image
    {
       
        width: 100%;
        height: 300px;
        margin-bottom: 25px;

        img
        {
            width: 100%;
            height: 100%;
        }


    }

    h5
    {
        color: #212529;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 22px;
    }

    p
    {
        margin-top: 4%;
        color: #212529;
        font-weight: 400;
    }
  }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .our_approach
    {
        padding: 20px 0px 20px 0px;

        .main_heading
        {
            font-size: 20px;
            margin-bottom: 10px;
        }

        .desc
        {
            font-size: 14px;
        }

        .pt_50
        {
            padding-top: 20px;
        }

        .sidebar 
        {
            .list
            {
                li 
                {
                    button {
                        padding: 10px 10px 10px 10px;
                        font-weight: 500;
                        font-size: 15px;
                    }
                }
            }
        }

        .content
        {
            padding: 20px 10px;

            h5
            {
                font-size: 16px;
            }

            p
            {
                font-size: 13px;
            }

            .image
            {
                height: 200px;
                margin-bottom: 15px;
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .our_approach
    {
        padding: 30px 0px 30px 0px;

        .main_heading
        {
            font-size: 24px;
            margin-bottom: 15px;
        }

        .desc
        {
            font-size: 14px;
        }

        .pt_50
        {
            padding-top: 30px;
        }

        .sidebar 
        {
            .list
            {
                li 
                {
                    button {
                        padding: 10px 10px 10px 10px;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }
        }

        .content
        {
            padding: 20px 10px;

            h5
            {
                font-size: 16px;
                margin-bottom: 10px;
            }

            p
            {
                margin-top: 0%;
                font-size: 13px;
            }

            .image
            {
                height: 185px;
                margin-bottom: 15px;
            }
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .our_approach
    {
        padding: 30px 0px 30px 0px;

        .main_heading
        {
            font-size: 26px;
            margin-bottom: 15px;
        }

        .desc
        {
            font-size: 14px;
        }

        .pt_50
        {
            padding-top: 30px;
        }

        .sidebar 
        {
            .list
            {
                li 
                {
                    button
                     {
                        padding: 10px 10px 10px 10px;
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
            }
        }

        .content
        {
            padding: 20px 10px;

            h5
            {
                font-size: 17px;
                margin-bottom: 10px;
            }

            p
            {
                margin-top: 0%;
                font-size: 14px;
            }

            .image
            {
                height: 200px;
                margin-bottom: 15px;
            }
        }
    }
}


@media only screen and (min-width:1200px) and (max-width:1399px) {
    .our_approach
    {
       

        .main_heading
        {
            font-size: 30px;
            margin-bottom: 15px;
        }

        .desc
        {
            font-size: 14px;
        }

        .pt_50
        {
            padding-top: 30px;
        }

        .sidebar 
        {
            .list
            {
                li 
                {
                    button
                     {
                        padding: 10px 10px 10px 10px;
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
            }
        }

        .content
        {
            padding: 20px 10px;

            h5
            {
                font-size: 18px;
                margin-bottom: 10px;
            }

            p
            {
                margin-top: 3%;
                font-size: 15px;
            }

            .image
            {
                height: 250px;
                margin-bottom: 15px;
            }
        }
    }
}