.software_benefits
{
    padding: 40px 0px;

    .heading_box {
        text-align: center;
    
        .main_heading {
          font-size: 32px;
          margin-bottom: 15px;
          font-weight: 600;
    
          span {
            color: #fcaf17;
          }
        }
    
        .desc {
          font-size: 16px;
          width: 80%;
          margin: 0 auto;
        }
      }

      .pt_40
      {
        padding-top: 40px;
      }

      .b_r
      {
        border-right: 1px solid #dedede;
      }
      .b_d
      {
        border-bottom: 1px solid #dedede;
      }

      .card
      {
        padding: 32px 15px;

        h5
        {
            
            margin-bottom: 25px;
        }
       
      }


}

@media only screen and (min-width:320px) and (max-width:767px) {
    .software_benefits
    {
        padding: 20px 0px;

        .heading_box
        {
            .main_heading
            {
                font-size: 18px;
            }
            .desc
            {
                font-size: 14px;
                width: 100%;
                margin: 0 auto;
            }
        }

        .pt_40
        {
            padding-top: 20px;
        }

        .b_r
        {
            border-right: 0px;
            border-bottom: 1px solid #dedede;
        }

        .card
        {
            padding: 20px 15px;

            h5
            {
                margin-bottom: 15px;
    font-size: 16px;
            }

            p
            {
                margin-bottom: 0px;
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .software_benefits
    {
        padding: 25px 0px;

        .heading_box
        {
            .main_heading
            {
                font-size: 24px;
            }
            .desc
            {
                font-size: 14px;
                width: 100%;
                margin: 0 auto;
            }
        }

        .pt_40
        {
            padding-top: 25px;
        }

        .b_d
        {
           
            border-right: 1px solid #dedede;
        }

        .b_r
        {
            border-bottom: 1px solid #dedede;
            
        }
        .b_rr
        {
            border-bottom: 1px solid #dedede;
           
        }

        .card
        {
            padding: 20px 15px;

            h5
            {
                margin-bottom: 15px;
    font-size: 18px;
            }

            p
            {
                margin-bottom: 0px;
            }
        }
    }
}

@media only screen and (min-width:991px) and (max-width:1199px) {
    .software_benefits
    {
        padding: 30px 0px;

        .heading_box
        {
            .main_heading
            {
                font-size: 28px;
            }
            .desc
            {
                font-size: 15px;
                width: 90%;
                margin: 0 auto;
            }
        }

        .pt_40
        {
            padding-top: 30px;
        }

        

        .card
        {
            padding: 25px 15px;

            h5
            {
                margin-bottom: 20px;
    font-size: 18px;
            }

            p
            {
                margin-bottom: 0px;
            }
        }
    }
}