@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
$roboto: 'Open Sans', sans-serif;
$poppin:'Poppins', sans-serif;
$black: #000;
$white: #fff;
$white-50: #ffffff80;
$gray: #888888;
$blue: #092e40;
$yellow: #fcaf17;
$theme-yellow:rgb(252 175 23);


