.modalpop.borchure .modal-body {
  padding: 0;
}

.homebannersectoin {
  background: rgb(255, 240, 208);
  background: linear-gradient(
    74deg,
    rgba(255, 240, 208, 1) 0%,
    rgba(252, 253, 248, 1) 20%,
    rgba(254, 246, 231, 1) 45%
  );
  padding: 30px 4pc;

  h1 {
    font-size: 43px;
    font-weight: 700;
    padding: 12px 0;
  }

  h2 {
    color: #f8b418;
    font-size: 36px;
  }

  p {
    font-size: 21px;

    width: 100%;

    color: #000;

    font-weight: 600;

    margin: 30px 0;
  }

  h6 {
    font-size: 22px;
    font-weight: 700;

    color: #000;

    margin: 15px 0;
  }

  ul {
    display: flex;
    gap: 10px;
  }

  .bannerheading {
    .certificate_img_list {
      li {
        img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }

  .home_banner_img {
    width: 500px;
    height: 500px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
}


.img_heade_menu
{
  width: 100%;
  height: 490px;

  img
  {
    width:100%;
    height:100%;
    object-fit: contain;
  }
}



.navbar_top.navbar {
  padding: 10px 15px;
  margin: 0;
  position: relative;

  .dropdown-wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 2px solid #fcaf17;
    padding: 20px 0;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    font-family: "Poppins", sans-serif;

    .left_sidebar {
      padding: 0px 46px;

      h3 {
        font-size: 23px;
        color: #fcaf17;
        margin-bottom: 30px;
      }

      li {
        margin-bottom: 20px;

        a {
          display: flex;
          gap: 15px;
          align-items: center;
        }

        .iconimg {
          display: block;
          width: 45px;
          height: 45px;
          background-color: #fff8ea26;
          border-radius: 100px;
          text-align: center;
          // line-height: 45px;
        }

        span {
          color: #000;
          font-size: 17px;
          position: relative;

          &::after {
            background: #fcaf17;
            bottom: 0;
            content: "";
            height: 2px;
            left: 0;
            position: absolute;
            transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            width: 0;
          }

          &:hover::after {
            width: 100%;
          }
        }
      }

      img {
        width: 22px;
        height: 22px;
        // margin-right: 15px;
      }
    }
  }
}

.navbar_top.navbar .nav-item.mega-menu:hover .dropdown-wrapper {
  opacity: 1;
  visibility: visible;
}

.dropdown-wrapper1 {
  // position: absolute;
  // top: 50px;
  // left: 0;
  // right: 0;
  // width: 100%;
  // background: #fff;
  // z-index: 9999999;
  position: absolute;
  left: 0;
  right:0;
  width: 75%;
  top:80px;
  margin:0 auto;
  // opacity: 0;
  // visibility: hidden;
  background: #fff;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 2px solid #fcaf17;
  padding: 20px 0;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;

  .left_sidebar {
    padding: 20px 25px;

    .mega_menu_list1
    {
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 15px;
      margin-top: 15px;


      li {
        margin-bottom: 10px;
  
        a {
         display:inline;
        }
  
       
        .iconimg1 {
          display: block;
          width: 35px;
          height: 35px;
          background-color: #fff8ea26;
          border-radius: 100px;
          text-align: center;
  
          img
          {
            width:100%;
            height:100%;
          }
        }
  
        span {
          color: #000;
          font-size: 18px;
          position: relative;
          padding-bottom: 5px;
  
          &::after {
            background: #fcaf17;
            bottom: 0;
            content: "";
            height: 2px;
            left: 0;
            position: absolute;
            transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            width: 0;
          }
  
          &:hover::after {
            width: 100%;
          }
        }
      }
    }


    .mega_menu_heading
    {
      font-size: 16px;
      text-transform: uppercase;
      font-weight:600;
      margin-bottom:15px;
    }
   

    h3 {
      font-size: 23px;
      color: #fcaf17;
      margin-bottom: 30px;
    }

    li {
      margin-bottom: 20px;

      a {
        display: flex;
        gap: 15px;
        align-items: center;
      }

      .iconimg {
        display: block;
        width: 35px;
        height: 35px;
        background-color: #fff8ea26;
        border-radius: 100px;
        text-align: center;
        // line-height: 45px;

        img
        {
          width:100%;
          height:100%;
          object-fit: contain;
        }
      }
      

      span {
        color: #000;
        font-size: 17px;
        position: relative;

        &::after {
          background: #fcaf17;
          bottom: 0;
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          width: 0;
        }

        &:hover::after {
          width: 100%;
        }
      }
    }

    img {
      width: 32px;
      height: 32px;
      // margin-right: 15px;
    }
  }
}

.visiblhide {
  visibility: hidden;
  z-index: -20;
  opacity: 0;
}

.visiblshow {
  visibility: visible;
  z-index: 99999999999;
  opacity: 1;
}

.navigation_nav.offcanvas-body {
  display: unset !important;
}

.topbar {
  background-color: $blue;
  padding: 10px 0;

  .dowload_brochure {
    background-color: #fcaf17;
    padding: 3px 10px;
    color: #fff !important;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    animation: pulse-animation 2s infinite;
    transition: all 0.5s;
    box-shadow: 0 0 0 rgb(252, 175, 23);
  }

  .dowload_brochure:hover {
    color: #fff !important;
  }

  .container-fluid {
    padding: 0px 1pc;
  }

  .gap10 {
    gap: 15px;
  }

  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    .icon {
      color: #fff;
    }

    p {
      line-height: normal;

      a:hover {
        color: #fcaf17 !important;
      }
    }
  }
}

.social-links {
  display: flex;
  align-items: center;
  gap: 15px;

  a:hover {
    color: #fcaf17 !important;
  }
}

.bootom-bar {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);


  .navbar {
   

    &-nav {
      justify-content: flex-end;
      gap: 10px;

      .nav-link {
        font-size: 16px;
        text-transform: uppercase;
        color: #020202;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        padding: 18px 12px;
        position: relative;

        &::after {
          background: #fcaf17;
          bottom: 0;
          content: "";
          height: 2px;
          left: 0;
          position: absolute;
          transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          width: 0;
        }

        &:hover::after {
          width: 100%;
        }
      }
    }
  }
}

.top-banner {
  position: relative;
  // width:100%;
  // height:600px;
  // background-image:url('../images/decor/contactbanner.webp');
  // background-size: cover;
  // background-repeat: no-repeat;

  
  &:before,
  &:after {
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
  }

  &:before {
    background-image: url("../images/decor/patter-2.png");
    background-position: left center;
    background-size: 20%;
    left: 0;
    top: 0px;
  }

  &:after {
    background-image: url("../images/decor/patter-1.png");
    background-position: right center;
    background-size: 50%;
    right: 0;
    top: 0px;
  }

  .main_headding
  {
    color: #fff!important;
  }

  .desccc
  {
    color: #fff!important;
    margin-bottom: 30px;
  }

  .contact_buttnn
  {
    background-color: #fcaf17;
    border-radius: 5px;
    color:#fff;
    border:1px solid #fcaf17;
    padding: 12px 36px;
  }

  .contact_buttnn:hover
  {
    background-color: transparent;
    color: #fff!important;
    border-color: #fff;
    transition:  all 0.4s ease;
  }

  .container {
    position: relative;
    z-index: 4;
  }

  .title-section {
    font-size: 20px;
    line-height: 40px;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 2pc 0 4pc;

    .left {
      background: #e7e9ff;
      color: #838ae0;
    }

    .right {
      background: #fff7df;
      color: #8c8265;
    }

    .mid {
      background: #ffefe8;
      color: #ec7c47;
    }

    .inner-box {
      border-radius: 14px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      align-content: center;
      flex-direction: row;
      text-align: left;
      border: 1px solid #fff;

      .icon {
        font-size: 35px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.contact_banner {
  position: relative;
  width:100%;
  height:600px;
  background-image:url('../images/decor/contactbanner.webp');
  background-size: cover;
  background-repeat: no-repeat;

  
  // &:before,
  // &:after {
  //   background-repeat: no-repeat;
  //   content: "";
  //   position: absolute;
  //   z-index: -1;
  //   height: 100%;
  //   width: 100%;
  // }

  // &:before {
  //   background-image: url("../images/decor/patter-2.png");
  //   background-position: left center;
  //   background-size: 20%;
  //   left: 0;
  //   top: 0px;
  // }

  // &:after {
  //   background-image: url("../images/decor/patter-1.png");
  //   background-position: right center;
  //   background-size: 50%;
  //   right: 0;
  //   top: 0px;
  // }
  .contentn
  {
    padding-top: 10%;
  }

  .text_container
  {
    min-height: 60px!important;
  }

  .mb_40
{
  margin-bottom: 40px;
}

  .main_headding
  {
    color: #fff!important;
    margin-bottom: 15px!important;
    font-weight: 600!important;
    text-shadow: none!important;
  }

  .desccc
  {
    color: #fff!important;
    margin-bottom: 30px;
  }

  .contact_buttnn
  {
    background-color: #fcaf17;
    border-radius: 5px;
    color:#fff;
    border:1px solid #fcaf17;
    padding: 12px 36px;
  }

  .contact_buttnn:hover
  {
    background-color: transparent;
    color: #fff!important;
    border-color: #fff;
    transition:  all 0.4s ease;
  }

  .container {
    position: relative;
    z-index: 4;
  }

  .title-section {
    font-size: 20px;
    line-height: 40px;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 2pc 0 4pc;

    .left {
      background: #e7e9ff;
      color: #838ae0;
    }

    .right {
      background: #fff7df;
      color: #8c8265;
    }

    .mid {
      background: #ffefe8;
      color: #ec7c47;
    }

    .inner-box {
      border-radius: 14px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      align-content: center;
      flex-direction: row;
      text-align: left;
      border: 1px solid #fff;

      .icon {
        font-size: 35px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

@media only screen and (max-width:991px)
{
  .contact_banner
  {
    height:350px;

    .main_headding
    {
      color: #fff!important;
      margin-bottom: 0px!important;
      font-weight:600;
      font-size: 26px!important;
    }

    .text_container
    {
      min-height: 50px!important;
    }
  
    .desccc
    {
      color: #fff!important;
      margin-bottom: 20px;
      font-size:15px;
    }
  
    .contact_buttnn
    {
      padding: 8px 16px;
      font-size:15px;
    }
  }
}

@media only screen and (min-width:992px) and (max-width:1199px)
{
  .contact_banner
  {
    height:400px;

    .main_headding
    {
      color: #fff!important;
      margin-bottom: 0px!important;
      font-weight:600;
      font-size: 30px!important;
    }

    .text_container
    {
      min-height: 50px!important;
    }
  
    .desccc
    {
      color: #fff!important;
      margin-bottom: 20px;
      font-size:15px;
    }
  
    .contact_buttnn
    {
      padding: 8px 16px;
      font-size:15px;
    }
  }
}
@media only screen and (min-width:1200px) and (max-width:1440px)
{
  .contact_banner
  {
    height:550px;

    .main_headding
    {
      color: #fff!important;
      margin-bottom: 10px!important;
      font-weight:600;
      font-size: 36px!important;
    }

    .text_container
    {
      min-height: 50px!important;
    }
  
  }
}

.client-swiper {
  .swiper-slide {
    text-align: center;
  }

  img {
    width: auto;
    object-fit: contain;
    height: 100px;
  }
}

.services-swiper {
  
  .swiper-slide {
    &:nth-child(4n + 1) {
      .box {
        background: #fff2e2;
      }
    }

    &:nth-child(4n + 2) {
      .box {
        background: #feeae3;
      }
    }

    &:nth-child(4n + 3) {
      .box {
        background: #c8ebff;
      }
    }

    &:nth-child(4n + 4) {
      .box {
        background: #eee0f7;
      }
    }
  }

  .box {
    background: #fff;
    position: relative;
    border-radius: 14px;
    padding: 40px 30px;
    box-shadow: 0 10px 15px -2px #52003914;

    h4 {
      font-weight: 700;
      margin-bottom: 1pc;
    }

    p {
      font-size: 18px;
      margin-bottom: 0;
    }

    .content {
      margin-bottom: 1pc;
    }

    .image {
      text-align: center;

      img {
        max-height: 270px;
        object-fit: contain;
      }
    }
  }

  ul.tabs {
    background: #1f2732;
    padding: 1pc;

    li {
      padding: 15px 2pc;
      position: relative;
      cursor: pointer;
      font-size: 19px;
      font-weight: 500;
      transition: all 0.5s;

      &:before {
        position: absolute;
        height: 100%;
        width: calc(100% + 3pc);
        top: 0;
        right: -3pc;
        background: #fcaf17;
        z-index: 1;
        transition: all 0.5s;
      }

      span {
        position: relative;
        z-index: 4;
        color: #f1f1f1;
        font-weight: 400;
        font-size: 17px;
      }

      &.active {
        color: #fff;
        transition: all 0.5s;

        &:before {
          content: "";
          transition: all 0.5s;
        }
      }
    }
  }

  .tab-description {
    padding: 0 2pc;

    h6 {
      font-size: 19px;
      line-height: 30px;
    }

    p:not(:last-child) {
      margin-bottom: 5px;
    }

    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      // height: 200px;
      row-gap: 5px;
      margin-bottom: 30px;

      li {
        list-style-type: square;
        list-style-position: inside;

        &::marker {
          color: $yellow;
        }
      }
    }
  }
}

.tab-inner a {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  position: relative;

  &::after {
    background: #fcaf17;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    width: 0;
  }

  &:hover::after {
    width: 100%;
  }
}

.how-it-work {
  ul.box {
    display: flex;
    flex-direction: column;
    gap: 25px;

    li {
      display: flex;
      gap: 20px;

      .content {
        flex: 1;

        h4 {
          margin-bottom: 0;
        }
      }

      .icon {
        height: 45px;
        width: 45px;
        background: #5b5b5b;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        box-shadow: 0 0 0px 5px #3534311c;
        font-size: 25px;
        font-weight: 600;
        color: #fff;
      }
    }
  }
}

.testimonials {
  .box {
    background-color: #fafafa;
    height: 100%;
    padding: 70px;
    position: relative;

    // box-shadow: 0 4px 6px -1px #00000012, 0 2px 4px -1px #0000000f;
    .quote {
      font-size: 35px;
      opacity: 0.3;
    }

    p.desp {
      font-size: 14px;
      // font-style: italic;
      color: #000;
      line-height: 1.9;
      margin: 1pc 0;
      text-align: justify;
    }

    .user {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        height: 60px;
        width: 60px;
        object-fit: cover;
        border-radius: 50%;
      }

      h5 {
        margin-bottom: 0;
      }

      p {
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}

.technology-used {
  background-color: #fafafa;

  .heading_box
  {
    text-align: center;
    padding: 40px 0px;

    .main_heading
    {
      font-size: 36px;
      color: #020202;
      margin-bottom:15px;
      font-weight: 600;


      span
      {
        color:#fcaf17;
      }
    }
  }

  ul {
    .col-auto {
      width: 16.6%;
    }

    .box {
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 5px 50px #0d15370f;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: auto;
      padding: 20px;
      text-align: center;
      transition: all 0.3s ease;
      min-height: 131px;

      img {
        width: auto;
        height: 50px;
        object-fit: contain;
        margin-bottom: 1pc;
      }

      &:hover {
        box-shadow: 0 20px 20px 0 #02134f1a;
        transform: translateY(-2px);
      }
    }
  }
}

.industry-served {
  background-color: #1f2732;
  background-image: url("../images/decor/texture_dark.jpg");
  background-attachment: fixed;
  background-size: cover;

  ul {
    .box {
      background-color: #fafafa;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      text-align: center;
      transition: all 0.3s ease;
      box-shadow: 0 5px 50px #0d15370f;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(0.98);
      }

      img {
        width: auto;
        height: 110px;
        object-fit: contain;
        // margin-bottom: 1pc;
      }

      h6 {
        margin-bottom: 0;
      }
    }
  }
}

.benefits {
  .box {
    background: #fff;
    position: relative;
    place-items: start;
    display: grid;
    border-radius: 14px;
    padding: 20px 30px 40px 30px;
    box-shadow: 0 10px 15px -2px #52003914;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 40%;
      bottom: 0;
      height: 4px;
      background: linear-gradient(270deg, #fcaf17 35%, #ffc556 100%);
      transition: all 0.5s ease;
    }

    &:hover {
      .step {
        opacity: 1 !important;
      }

      &:after {
        width: 100%;
      }
    }

    img {
      width: 70px;
      object-fit: contain;
    }

    h4 {
      font-size: 22px;
      line-height: 32px;
      font-weight: 700;
      margin: 1pc 0 10px;
    }

    p {
      font-size: 18px;
      line-height: 28px;
    }

    .step {
      opacity: 0;
      font-size: 74px;
      font-weight: 700;
      position: absolute;
      right: 30px;
      top: 30px;
      color: #fcaf173b;
      z-index: 99;
    }
  }
}

.blog-box {
  .btn {
    letter-spacing: 1px;
  }

  h4 {
    margin: 14px 0 5px;
    font-size: 22px!important;
    font-weight: 600;
    line-height: 1.5;

    a {
      color: #000000;
    }
  }

  .image {
    height: 275px;

    img {
      border-radius: 15px;
    }
  }

  ul.tags {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;

    li {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 500;
      color: #4d4d4d;
    }
  }
}

.blog-detail {
  .image {
    height: 400px;
  }

  .blog-box {
    .main_heading {
      font-size: 30px;
      padding: 10px 0px;
      font-weight: 600;
    }

    .blogh2 {
      font-size: 26px;
      font-weight: 600;
      text-transform: capitalize;
    }
    h5 {
      font-weight: 600;
      padding: 10px 0px;
      font-size: 20px;
    }
    p {
      margin-bottom: 10px;
    }
  }
  ul.tags li {
    color: #000;
  }
}

.our-solutions {
  background-color: #1f2732;
  background-image: url("../images/decor/texture_dark.jpg");
  background-attachment: fixed;
  background-size: cover;

  .box {
    align-items: center;
    background: #fff;
    border-radius: 14px;
    box-shadow: 0 10px 15px -2px rgba(86, 86, 86, 0.0784313725);
    display: flex;
    justify-content: center;
    min-height: 95px;
    padding: 15px 5px;
    position: relative;
    text-align: center;
    transition: all 0.5s ease;

    h5 {
      color: #2e2e2e;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0;
    }

    &:hover {
      background-color: #fcaf17;
      box-shadow: 0 8px 3px 1px #2a2a2a26;
      transform: translate3d(0, -10px, 0);

      h5 {
        color: #fff;
      }
    }
  }
}

.work-flow {
  background-color: #1f2732;
  background-image: url("../images/decor/texture_dark.jpg");
  background-attachment: fixed;
  background-size: cover;
  padding: 0 5pc;

  .box {
    text-align: center;

    &:hover {
      h4,
      .step {
        color: $yellow;
      }
    }

    .step {
      background-color: #1f2732;
      height: 80px;
      width: 80px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      font-size: 45px;
      font-weight: 700;
      box-shadow: 0 0 1px 7px #8080804d;
      color: #ffffff;
    }

    h4 {
      font-size: 22px;
      line-height: 32px;
      font-weight: 700;
      margin: 1.5pc 0 5px;
      color: #fff;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      color: #fff;
    }
  }
}

.our-work {
  .outer-box {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      border-right: 2px dashed lightgray;
      height: 100%;
      top: 0;
      left: 50%;
    }

    .box {
      padding-bottom: 4pc;

      &:nth-child(4n + 1) {
        .left {
          background: #fff2e2;
        }
      }

      &:nth-child(4n + 2) {
        .left {
          background: #feeae3;
        }
      }

      &:nth-child(4n + 3) {
        .left {
          background: #c8ebff;
        }
      }

      &:nth-child(4n + 4) {
        .left {
          background: #eee0f7;
        }
      }

      .left {
        text-align: center;
        padding: 20px;
        width: 80%;
        border-radius: 15px;
        margin: 0 auto;

        img {
          width: 100%;
          object-fit: contain;
          height: 400px;
        }
      }

      &.reverse {
        .row {
          flex-direction: row-reverse;
        }

        .right {
          text-align: right;
          padding-right: 3pc;

          &:before {
            right: -2px;
          }
        }
      }

      &:not(.reverse) {
        .right {
          padding-left: 3pc;

          &:before {
            left: 0;
          }
        }
      }

      .right {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 4px;
          background-color: $yellow;
          height: 100%;
          top: 00%;
        }

        h4 {
          line-height: 32px;
          font-weight: 700;
          margin-bottom: 1pc;
        }

        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.our-portfolio {
  .outer-box {
    .box {
      padding-bottom: 2pc;

      // &:nth-child(4n + 1) {
      //   .left {
      //     background: #fff2e2;
      //   }
      // }

      // &:nth-child(4n + 2) {
      //   .left {
      //     background: #feeae3;
      //   }
      // }

      // &:nth-child(4n + 3) {
      //   .left {
      //     background: #c8ebff;
      //   }
      // }

      // &:nth-child(4n + 4) {
      //   .left {
      //     background: #eee0f7;
      //   }
      // }

      .left {
        text-align: center;
        padding: 20px;
        width: 95%;
        border-radius: 15px;
        margin: 0 auto;

        img {
          width: 100%;
          object-fit: contain;
          // height: 400px;
        }
      }

      &.reverse {
        .row {
          flex-direction: row-reverse;
        }

        .right {
          text-align: right;
        }
      }

      .right {
        h4 {
          line-height: 32px;
          font-weight: 700;
          margin-bottom: 1pc;
        }

        p {
          margin-bottom: 20px;
          text-align: justify;
        }

        a {
          margin-top: 30px;

          .iconright {
            font-size: 23px;
            opacity: 1;
          }
        }
      }
    }
  }
}

.our-result {
  p {
    margin-bottom: 0;
  }

  .box {
    h1,
    h4 {
      font-weight: 700;
    }

    h1 {
      font-size: 45px;
    }
  }
}

.consultation {
  .row {
    background-color: #fafafa;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    justify-content: space-evenly;
  }

  .left {
    padding: 2pc;
  }
}

.banner {
  .outer-box {
    box-shadow: 0 20px 50px #6722001a;
    padding: 4pc;
    border-radius: 15px;
  }

  .box {
    display: flex;
    align-items: center;
    gap: 2pc;

    .icon img {
      height: auto;
      width: 84px;
      object-fit: contain;
    }
  }
}

.footor-outer {
  background: rgb(244 244 244);
  position: relative;
  // &:before {
  //   background-image: url("../images/decor/footer.png");
  //   background-position: 50%;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   content: "";
  //   position: absolute;
  //   height: 100%;
  //   width: 100%;
  //   left: 0;
  //   top: 0;
  //   z-index: 0;
  // }
}

.footer {
  padding: 4pc 0 0pc;
  position: relative;
  z-index: 4;
  background-color: #020202;
  z-index: 1;

  .footer-links {
    p {
      font-size: 13px;
      color: #fff;
    }

    .text {
      color: #e7e7e7;
      font-size: 13px;
      line-height: 27px;
      // text-align: justify;
    }

    .title {
      color: #eaedf3;
      margin-bottom: 20px;
      font-weight: 500;
    }

    &-list {
      li {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 4px;

        &:hover a {
          color: #fcaf17;
          letter-spacing: 1px;
          transition: all 0.5s;
        }
      }

      .icon {
        color: #fff;
        font-size: 25px;
        font-weight: 400;
      }

      a {
        color: #e7e7e7;
        font-size: 14px;
        transition: all 0.5s;
        text-transform: uppercase;

        &:hover {
          color: $yellow !important;
        }
      }
    }
  }

  ul.contact-links li {
    display: flex;
    gap: 10px;
    margin-bottom: 11px;

    h6 {
      color: #eaedf3;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 7px;
    }

    .inner {
      display: flex;
      flex-direction: column;
      gap: 6px;

      a {
        color: #eaedf3;
        font-size: 14px;
        line-height: 20px;

        &:hover {
          color: #fcaf17 !important;
        }
      }
    }
  }

  ul.social-links a {
    height: 36px;
    width: 35px;
    border: 2px solid $yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #eaedf3;

    &:hover {
      color: #fff !important;
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
      background-color: $yellow !important;
    }
  }

  &::before {
    background-image: url("../images/bgfooterimg.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}

.footer .copyright p a:hover {
  color: $yellow !important;
}

.copyright {
  text-align: center;
  position: relative;
  z-index: 4;

  p {
    color: #f7f7f7;
    font-size: 15px;
    line-height: 25px;
    border-top: 1px solid #ffffffa3;
    padding: 15px 0;
    margin-bottom: 0;
    margin-top: 17px;
  }
}

.about-intro {
  .inner {
    img {
      height: 340px;
      width: 340px;
      object-fit: cover;
      border-radius: 50%;
      object-position: top;
      border: 4px solid transparent;
      box-shadow: 0px 0px 15px #26252436;
    }
  }

  .content {
    position: relative;
    margin-top: -5pc;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      background: #fafafa;
      height: 40%;
      width: 100%;
      z-index: -1;
    }

    .title-section .heading {
      background: #ffffff80;
      padding: 1pc 1pc;
      width: max-content;
      margin: 0 auto;
    }

    p {
      font-size: 17px;
    }
  }
}

.our-values {
  display: flex;
  align-items: center;

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 13px;
    column-gap: 20px;

    li {
      /* box-shadow: 0 0 20px #00000010; */
      padding: 6px 1pc;
      border-radius: 7px;
      border: 1px dashed lightgray;

      &:hover {
        border-color: $yellow;
        color: $yellow;
      }
    }
  }
}

.about-history {
  .outer-box {
    .box {
      .left {
        background-color: transparent !important;
        position: relative;
        width: 100%;

        &:before {
          content: "";
          position: absolute;
          width: 4px;
          background-color: $yellow;
          height: 100%;
          top: 00%;
        }
      }

      .right {
        &:before {
          display: none;
        }

        p {
          margin-bottom: 0;
        }
      }

      &:not(.reverse) {
        .left {
          text-align: right;
          padding-right: 3pc;

          &:before {
            right: -2px;
          }
        }
      }

      &.reverse {
        .left {
          text-align: left;
          padding-left: 3pc;

          &:before {
            left: 0;
          }
        }
      }
    }
  }
}

.services-page {
  .col-12 {
    &:nth-child(4n + 1) {
      .box {
        background: #fff2e2;
      }
    }

    &:nth-child(4n + 2) {
      .box {
        background: #feeae3;
      }
    }

    &:nth-child(4n + 3) {
      .box {
        background: #c8ebff;
      }
    }

    &:nth-child(4n + 4) {
      .box {
        background: #eee0f7;
      }
    }
  }

  .box {
    background: #fff;
    position: relative;
    border-radius: 14px;
    padding: 40px 30px;
    max-height: 502px;
    height: 100%;
    overflow: hidden;

    &:hover {
      box-shadow: 0 10px 15px -2px #52003914;
    }

    h4 {
      font-weight: 700;
      margin-bottom: 1pc;
    }

    p {
      font-size: 18px;
      margin-bottom: 5px;
      line-height: 25px;
      color: #797979;
    }

    .content {
      margin-bottom: 1pc;
    }

    .image {
      text-align: center;
      max-height: 270px;
      height: 100%;

      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.service-intro {
  p {
    margin-bottom: 0;
  }
}

.what-we-offer {
  .box {
    text-align: left !important;
    h4 {
      color: #000;
    }

    p {
      color: #797979;
      margin-bottom: 0;
      text-align: left;
      font-size: 15px;
      line-height: 23px;

      span {
        color: #000;
        font-weight: 800;
        display: block;
      }
    }

    .icon {
      position: relative;
      width: max-content;
      // margin: 0 auto;

      &::before {
        content: "";
        position: absolute;
        height: 60px;
        width: 60px;
        background: #fcaf1752;
        z-index: 2;
        right: 0pc;
        top: -1pc;
        border-radius: 50%;
      }

      img {
        position: relative;
        z-index: 4;
        width: auto;
        height: 87px;
        object-fit: contain;
      }
    }
  }
}

.contact-page-sec {
  img {
    height: 400px;
  }
}

.contact-details {
  background-color: #1f2732;
  background-image: url("../images/decor/texture_dark.jpg");
  background-attachment: fixed;
  background-size: cover;
  color: $white;
  padding: 3pc 2pc;

  .box {
    p {
      margin-bottom: 0px;
      font-size: 15px;
      color: rgba(255, 255, 255, 0.5);
    }

    a {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .icon-box {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 1pc !important;

    h6 {
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #fff;
      margin-bottom: 0;
    }
  }
}

.our-clients {
  .box {
    display: flex;
    align-items: center;
    column-gap: 3pc;
    row-gap: 1pc;
    flex-wrap: wrap;

    img {
      width: auto;
      object-fit: contain;
      height: 110px;
    }
  }
}

.privacy-policy {
  p {
    margin-bottom: 5px;

    // b {
    //   color: $yellow;
    // }
  }

  a {
    color: #0d6efd;
  }

  b {
    color: #000;
  }

  h6 {
    margin-top: 2pc;
    margin-bottom: 5px;
  }

  ul {
    li {
      list-style-type: square;
      list-style-position: inside;

      &::marker {
        color: $yellow;
      }
    }
  }
}

.highlight-casestudy {
  .study-view {
    display: flex;
    align-items: center;
    gap: 2pc;

    img {
      width: auto;
      object-fit: contain;
      height: 80px;
    }

    p {
      font-size: 19px;
      font-weight: 500;
    }
  }
}

.case-study {
  ul.tabs {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    // gap: 2pc;
    justify-content: center;

    li {
      // padding: 10px 1pc;
      // cursor: pointer;
      // border: 1px solid lightgray;
      // border-radius: 4px;
      // min-width: 130px;
      // text-align: center;
      // font-weight: 500;
      // color: #000;
      // background-color: #fff;
      // transition: all 0.5s;
      /* padding: 10px 1pc; */
      cursor: pointer;
      border: 1px solid lightgray;
      border-radius: 4px;
      text-align: center;
      font-weight: 500;
      color: #000;
      background-color: #fff;
      transition: all 0.5s;
      font-size: 18px;
      padding: 10px 20px;
      max-width: 100%;
      margin-left: 20px;

      &.active {
        background-color: $yellow;
        border-color: $yellow;
        color: #fff;
        transition: all 0.5s;
      }
    }
  }

  &-box {
    box-shadow: 0 0 15px #0000001a;
    border-radius: 15px;

    .image {
      position: relative;

      img {
        width: 100%;
        object-fit: cover;
        height: 400px;
        border-radius: 15px 15px 0 0;
      }

      h5 {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 1pc;
        color: #fff;
      }

      &:before {
        content: "";
        background-image: linear-gradient(
          -180deg,
          #00000000 60%,
          #000000cc 100%
        );
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1pc 2pc;
      gap: 2pc;
      border-radius: 0 0 15px 15px;
      background-color: #fff;

      img {
        width: auto;
        object-fit: contain;
        height: 70px;
      }

      p {
        font-size: 19px;
        font-weight: 500;
      }
    }
  }
}

.work-page-img img {
  height: 400px;
}

.work-detail-page {
  ul.info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 1pc;
    column-gap: 1pc;

    & > li {
      padding: 1pc 3pc;
      border: 1px solid lightgray;
      color: #000;

      a {
        color: #000;

        &:hover {
          color: $yellow;
        }
      }
    }
  }

  h5.heading {
    margin-top: 2pc;
    margin-bottom: 5px;
  }
}

.portfolio-box {
  position: relative;
  margin-bottom: 2pc;

  .portfolio-content {
    position: absolute;
    bottom: 1pc;
    left: 0;
    background-color: #fcaf17;
    color: #fff;
    padding: 1pc 2pc;
    margin: 0 2pc;
    // transform: scaleY(0);
    transition: all 1s;
    z-index: 10;

    h4,
    p,
    a {
      color: #fff;
    }

    a {
      text-decoration: none;
    }

    p {
      font-size: 15px;
      line-height: normal;
      margin: 0;
      letter-spacing: 0.5px;
    }
  }

  .portfolio-img {
    position: relative;
    overflow: hidden;

    img {
      height: 500px;
      width: 100%;
      object-fit: cover;
      transition: all 1s;
    }

    &:before {
      content: "";
      position: absolute;
      background: linear-gradient(0deg, #ffffff4d 30%, #00000000 100%);
      height: 10%;
      width: 180%;
      bottom: 32pc;
      left: -31pc;
      opacity: 0;
      pointer-events: none;
      transition: all 0.5s;
      z-index: 4;
      transform: rotate(-50deg);
    }

    &:after {
      content: "";
      position: absolute;
      background-color: #00000050;
      height: 100%;
      width: 100%;
      bottom: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
      transition: all 1s;
      z-index: 2;
    }
  }

  &:hover {
    .portfolio-content {
      transform: scaleY(1);
      transition: all 1s;
    }

    .portfolio-img {
      &:before {
        opacity: 1;
        transition: all 0.5s;
        bottom: -20pc;
        left: 0;
      }

      &:after {
        opacity: 1;
        transition: all 1s;
      }

      img {
        transform: scale(1.2);
        transition: all 1s;
      }
    }
  }
}

.solution-page {
  .box {
    img {
      height: 385px;
      width: 100%;
      object-fit: cover;
      border-radius: 15px;
    }

    .content {
      padding: 1pc;
      text-align: left;
      width: 85%;
      box-shadow: 0 0 20px #00000020;
      margin: -2pc 0 0 0pc;
      position: relative;
      z-index: 4;
      background: #fff;

      h5 {
        margin-bottom: 0;
      }

      p {
        line-height: 25px;
        margin-bottom: 0px;
      }
    }
  }
}

.solution-benefits {
  ul.tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2pc;
    justify-content: center;

    li {
      padding: 10px 1pc;
      cursor: pointer;
      border: 1px solid lightgray;
      border-radius: 4px;
      min-width: 130px;
      text-align: center;
      font-weight: 500;
      color: #000;
      background-color: #fff;
      transition: all 0.5s;

      &.active {
        background-color: $yellow;
        border-color: $yellow;
        color: #fff;
        transition: all 0.5s;
      }
    }
  }
}

.our-team {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px;
  // justify-content: center;

  .box {
    img {
      width: 220px;
      height: auto;
      object-fit: cover;
      border-radius: 15px;
    }

    .content {
      padding-top: 10px;
      text-align: center;

      h6 {
        margin-bottom: 0;
      }

      .designation
      {
        color: #212529;
    font-size: 14px;
      }

      p {
        line-height: normal;
        margin-bottom: 0;
        font-weight: 700;
        font-style: unset !important;
        font-size: 15px;
        opacity: 0;
      }
    }
  }

  .absolute_more {
    font-size: 27px;
    position: relative;
    bottom: -65px;
    color: #fcaf17;
  }
}

.services_list {
  ul {
    margin-left: 16px;
  }

  li {
    list-style: disc;
    font-size: 15px;
    margin-bottom: 7px;
  }
}

.sa-technology {
  background-image: url("../images/tech_img/dots-background-2.png");
  background-repeat: no-repeat;
  background-position: top center;
  border-top: 1px solid #f1f1f1;
}

.content-grid .content-grid-item {
  position: relative;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content-grid .content-grid-item:before {
  height: 100%;
  top: 0;
  left: -1px;
  border-left: 1px solid #dadada;
}

.sa-technology p {
  font-size: 20px;
  padding-top: 10px;
}

.content-grid .content-grid-item:after {
  width: 100%;
  height: 0;
  top: auto;
  left: 0;
  bottom: -1px;
  border-bottom: 1px solid #dadada;
}

.content-grid .content-grid-item:after,
.content-grid .content-grid-item:before {
  content: "";
  position: absolute;
}

.content-grid {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.gray-bg2 {
  background-color: #f1f1f1 !important;
  border-top: 5px solid #efefef;
}

.our-solutions {
  .col-auto {
    width: 20%;
    margin-bottom: 15px;
  }
}

.worksflosection {
  .box {
    border-radius: 1rem;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
    display: block;
    min-height: 360px;
    position: relative;
    transition: all 0.3s ease;
    text-align: center;
    padding: 15px;
    background-color: #fff;

    &:hover {
      box-shadow: 0 5px 20px 0 rgba(210, 210, 245, 0.5);
      z-index: 3;

      .process-icon {
        background: #000;
        color: #fbaf17 !important;
      }
    }
  }

  .process-icon {
    align-items: center;
    border-radius: 10px;
    display: flex;
    height: 80px;
    justify-content: center;
    margin: 0 auto 25px;
    text-align: center;
    transition: all 0.3s ease;
    width: 80px;
    font-size: 2rem;
    font-weight: bold;
    color: #52525d;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
  }

  .dots-line.first {
    margin-left: -17px;
    margin-right: -17px;
    position: relative;
  }

  .dots-line {
    border-bottom: 2px dashed #2a2a2a40;
    flex: 1 1;
    height: 1px;
    padding-top: 0;
    position: relative;
    right: auto;
    top: auto;
    width: 100px;
    z-index: 2;
  }
}

.ourworkbg {
  background-color: #fffdfa;
}

.in_expertise_s {
  position: relative;

  .box_indstr {
    align-items: center;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 -1px 5px 0 rgb(0 0 0/5%);
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    margin-bottom: 30px;
    min-height: 120px;
    overflow: hidden;
    padding: 25px 0;
    position: relative;
    text-align: center;
    border-radius: 8px;

    .icon_arro {
      position: absolute;
      right: 14px;
      top: 14px;
      -webkit-transform: rotate(-50deg);
      transform: rotate(-50deg);
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;

      .icon_arro i {
        color: #000;
        font-size: 25px;
      }
    }

    &::after {
      background: #fa0;
      bottom: 0;
      content: "";
      height: 5px;
      left: 0;
      position: absolute;
      transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      width: 0;
    }

    &:hover::after {
      width: 100%;
    }

    p {
      color: #222;
      font-size: 16px;
      font-weight: 600;
    }
  }

  img {
    height: 99px;
    width: 60px;
  }

  .col-auto {
    width: 20%;
    margin-bottom: 15px;
  }

  // &:before {
  //   background-repeat: no-repeat;
  //   content: "";
  //   position: absolute;
  //   z-index: -1;
  //   height: 100%;
  //   width: 100%;
  //   background-image: url("../images/decor/patter-2.png");
  //   background-position: left center;
  //   background-size: 20%;
  //   left: 0;
  //   top: 0px;
  // }
}

.tabs_customersays .container-fluid {
  padding: 0px 7pc;
}

.testimontab_body {
  background-color: #fafafa;
  min-height: 476px;
  padding: 70px;
  position: relative;

  .f_right1 {
    -webkit-animation: fadeInRight 0.5s ease-in-out;
    animation: fadeInRight 0.5s ease-in-out;
  }

  .user_dets {
    display: flex;
    margin-top: 30px;

    .pic {
      margin-right: 10px;
      width: 60px;
    }

    p {
      color: #222;
      font-size: 18px;
      font-weight: 600;
      font-style: italic;
      line-height: 1.9;
    }
  }
}

.list_testimour {
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  padding: 36px 0 0;

  .nav-link {
    cursor: pointer;
    // margin-bottom: 10px;
    padding: 15px 10px;
    position: relative;
    border-bottom: 1px solid #f7f7f7;
    border-radius: 0;

    img {
      width: 175px;
    }

    &.active {
      background-color: transparent;

      &::before {
        background: #fbaf17;
        content: "";
        display: block;
        height: 70%;
        left: 0;
        position: absolute;
        top: 12px;
        width: 3px;
      }
    }
  }
}

.btn-tbs {
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  text-align: center;

  a {
    align-items: center;
    color: #000;
    display: flex;
    justify-content: center;
    text-decoration: none !important;
    font-weight: 500;
    font-size: 19px;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }

  to {
    opacity: 1;
  }
}

.bgcolor1 {
  background: linear-gradient(
      157deg,
      rgb(255, 241, 0) 0%,
      rgb(231, 31, 26) 100%
    )
    0% 0% no-repeat padding-box padding-box transparent;
}

.card_testmobial ul {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.card_testmobial h3 {
  font-size: 1.75rem;
}

.card_testmobial {
  h3 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
  }

  .content_testimonail {
    color: #fff;
    font-weight: 400;
    margin-top: 30px;
  }

  ul {
    margin-top: 30px;
  }

  .count-section {
    margin-top: 40px;
  }

  hr {
    width: 2px;
    height: auto;
    border: none;
    background-color: #fff;
    opacity: 1;
    margin: 5px;
  }

  .counts {
    span {
      font-size: 30px;
      font-weight: 600;
      color: #fff;
    }

    .words {
      color: #fff;
      font-size: 14px;
    }
  }
}

.case_study_secton {
  background-color: #f7f7f7;
  padding: 60px 0;
}

.centeredSlides .swiper-slide:not(.swiper-slide-next) .main_testimonial {
  transform: scale(0.9);
  // filter: blur(1px);
  opacity: 0.3;
  transition: all 0.5s;
}

.centeredSlides .swiper-slide-next .main_testimonial .inner {
  // background-image: url("../Images/backgroundimg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.portfoliyoswier .swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.main_testimonial {
  text-align: center;
  transition: all 0.5s;
}

// ---- added by ---

.browser-bx {
  -webkit-box-shadow: -20px 20px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -20px 20px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -20px 20px 10px 0px rgba(0, 0, 0, 0.1);
  display: inline-block;
  border-radius: 10px;
  background-color: #fafcfe;
  margin-bottom: 40px;

  .browser-topbar {
    background-color: #ededed;
    position: relative;
    height: 50px;
    border-radius: 10px 10px 0 0;
    text-align: left;

    .browser-dot {
      position: relative;
      width: 14px;
      height: 14px;
      background-color: #29b7b9;
      border-radius: 50%;
      display: inline-block;
      margin: 19px 0px 19px 35px;

      &::before {
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        background-color: #29b7b9;
        border-radius: 50%;
        left: -20px;
        top: 0;
      }

      &::after {
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        background-color: #29b7b9;
        border-radius: 50%;
        top: 0;
        left: 20px;
      }
    }
  }

  .case-title {
    margin: 15px;
    font-size: 22px;
    color: #18191b;
    font-weight: bold;
    text-align: left;
  }

  .casedescription {
    text-align: justify;
    margin: 15px;
    font-size: 14px;
    line-height: 24px;
    color: #18191b;
  }
}

.text {
  font-family: "Yanone Kaffeesatz";
  font-size: 100px;
  display: flex;
  position: absolute;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;

  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;

    .letter {
      transition: ease-out 1s;
      transform: translateY(40%);
    }

    .shadow {
      transform: scale(1, -1);
      color: #999;
      transition: ease-in 5s, ease-out 5s;
    }

    &:hover {
      .letter {
        transform: translateY(-200%);
      }

      .shadow {
        opacity: 0;
        transform: translateY(200%);
      }
    }
  }
}

.text-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  // End Codepen spesific styling

  h1 {
    color: mix(#fff, #000000, 15%);
    font-size: calc(1em + 2vw);
    font-weight: 900;
    text-shadow: -0.0075em 0.0075em 0 mix(#fff, #4b4b4b, 94%),
      0.005em 0.005em 0 mix(#fff, #4b4b4b, 60%),
      0.01em 0.01em 0 mix(#fff, #4b4b4b, 62%),
      0.015em 0.015em mix(#fff, #4b4b4b, 64%),
      0.02em 0.02em 0 mix(#fff, #4b4b4b, 66%),
      0.025em 0.025em 0 mix(#fff, #4b4b4b, 68%),
      0.03em 0.03em 0 mix(#fff, #4b4b4b, 70%),
      0.035em 0.035em 0 mix(#fff, #4b4b4b, 72%);
  }

  h2 {
    color: mix(#fff, #000000, 15%);
    font-size: calc(1em + 1.5vw);
    font-weight: 900;
    text-shadow: -0.0075em 0.0075em 0 mix(#fff, #4b4b4b, 94%),
      0.005em 0.005em 0 mix(#fff, #4b4b4b, 60%),
      0.01em 0.01em 0 mix(#fff, #4b4b4b, 62%),
      0.015em 0.015em mix(#fff, #4b4b4b, 64%),
      0.02em 0.02em 0 mix(#fff, #4b4b4b, 66%),
      0.025em 0.025em 0 mix(#fff, #4b4b4b, 68%),
      0.03em 0.03em 0 mix(#fff, #4b4b4b, 70%),
      0.035em 0.035em 0 mix(#fff, #4b4b4b, 72%);
  }
}

.single-integration {
  height: 200px;
}

::selection {
  color: #b47c0c;
  background: #000000;
}

.accordion.accordion-flush {
  .accordion-item {
    border-radius: 7px !important;
    overflow: hidden;
    outline: none !important;
  }

  .accordion-button {
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: #000;
    outline: transparent !important;
    box-shadow: unset !important;
  }

  .accordion-button:not(.collapsed) {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  .accordion-body {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
  }
}

.why-choose-us-card {
  transition: ease-in-out all 0.5s;
  // -webkit-box-shadow: 0px 0px 28px -3px rgba(0, 0, 0, 0.527);
  // -moz-box-shadow: 0px 0px 28px -3px rgba(0, 0, 0, 0.527);
  // box-shadow: 0px 0px 28px -3px rgba(0, 0, 0, 0.527);
  padding: 70px 30px;
  background-color: white;
  // border-radius: 10px;
}

.why-choose-us-card:hover {
  -webkit-box-shadow: 0px 0px 28px -3px rgba(0, 0, 0, 0.527);
  -moz-box-shadow: 0px 0px 28px -3px rgba(0, 0, 0, 0.527);
  box-shadow: 0px 0px 28px -3px rgba(0, 0, 0, 0.527);
  background-color: white;
  padding: 70px 30px;
  transform: scale(1.05);
  border-radius: 10px;
  z-index: 1500;
  transition: ease-in-out all 0.5s;
}

// ::-moz-selection {
//   /* Code for Firefox */
//   color: #b47c0c;
//   background: #000000;
// }

// ::selection {
//   color: #b47c0c;
//   background: #000000;
// }
.our-key-experties {
  background-color: rgba(0, 0, 0, 0.034);
  // padding: 70px 0px;
}

.get_intouc {
  position: relative;

  .box_sectoin {
    border-radius: 1rem;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
    display: block;
    position: relative;
    transition: all 0.3s ease;
    padding: 20px 35px;
    background-color: #fff;

    .sideinfobg {
      background-color: #fcaf1769;
      border-radius: 11px;
      padding: 25px 14px;

      h2 {
        font-size: 23px;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        color: #000;
        line-height: 24px;
        font-weight: 600;
      }

      .socal-linkgetcuton {
        margin-top: 20px;

        li {
          padding-bottom: 20px;

          span {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &::before {
    position: absolute;
    top: 0;
    content: "";
    background-color: #fff6f7;
    width: 100%;
    left: 0;
    right: 0;
    height: 300px;
  }
}

$hexagon-width: 250px;
$hexagon-height: $hexagon-width * 0.55;
$hexagon-margin: 25px;
$hexagon-title-color: #fff;
$hexagon-border-color: #fff;
$hexagon-border-color--hover: #72f88e;
$hexagon-image-color: #3ea0eb;
$honeycomb-max-width: 1200px;
$viewport-thereshold: $hexagon-width + $hexagon-margin;
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

.honeycomb {
  display: flex;

  flex-wrap: wrap;

  list-style: none;

  justify-content: center;

  align-items: center;

  max-width: $honeycomb-max-width;

  margin: 0 auto;

  padding: 0;

  transform: translateY(#{$hexagon-height/4});

  &-cell {
    flex: 0 1 250px;
    max-width: 200px;
    height: 100.5px;
    margin: 38.47619px 8.5px 24px;
    position: relative;
    display: flex;
    /* padding: 0.5em; */
    text-align: center;
    z-index: 1;
    text-align: center;
    z-index: 1;
    align-items: center;
    justify-content: center;

    &__title {
      height: 100%;

      display: flex;

      flex-direction: column;

      justify-content: center;

      hyphens: auto;

      word-break: break-word;

      text-transform: uppercase;

      color: #fff;

      font-weight: 700;

      font-size: 1.75em;

      transition: opacity 350ms;

      > small {
        font-weight: 300;

        margin-top: 0.25em;
      }
    }

    &__image {
      object-fit: cover;

      object-position: center;
    }

    &__image2 {
      height: 80px;
      width: 80px;
    }

    &::before,
    &::after {
      content: "";
    }

    &::before,
    &::after,
    &__image {
      top: -50%;

      left: 0;

      width: 100%;

      height: 200%;

      display: block;

      position: absolute;

      clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);

      z-index: -1;
    }

    &::before {
      background-color: white;
      transform: scale(1.055);
    }

    &::after {
      opacity: 0.5;

      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      transition: opacity 350ms;
    }

    &:hover {
      .honeycomb-cell__title {
        opacity: 0;
      }

      &::after {
        opacity: 0;
      }
    }

    &:nth-child(1):after {
      background: #fff2e2;
    }

    &:nth-child(2):after {
      background: rgb(233, 208, 208);
    }

    &:nth-child(3):after {
      background: #fff2e2;
    }

    &:nth-child(4):after {
      background: #feeae3;
    }

    &:nth-child(5):after {
      background: #fff2e2;
    }

    &:nth-child(6):after {
      background: rgb(233, 208, 208);
    }

    &:nth-child(7):after {
      background: #fff2e2;
    }

    &:nth-child(8):after {
      background: #feeae3;
    }

    &:nth-child(9):after {
      background: #fff2e2;
    }

    &:nth-child(10):after {
      background: rgb(233, 208, 208);
    }

    &:nth-child(11):after {
      background: #fff2e2;
    }

    &:nth-child(12):after {
      background: #feeae3;
    }

    &:nth-child(13):after {
      background: #fff2e2;
    }

    &:nth-child(14):after {
      background: #d5b4b4;
    }
  }

  &__placeholder {
    display: none;

    opacity: 0;

    width: $hexagon-width;

    margin: 0 $hexagon-margin/2;
  }
}

@media (max-width:#{2*$viewport-thereshold}) {
  .honeycomb-cell {
    margin: #{$hexagon-margin/2 + $hexagon-height/2} $hexagon-margin;
  }
}

@media (min-width: #{2*$viewport-thereshold}) and (max-width:#{3*$viewport-thereshold}) {
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - #{$hexagon-width/2});

    margin-left: calc(50% - #{$hexagon-width/2});
  }

  .honeycomb__placeholder:nth-child(3n + 5) {
    display: block;
  }
}

@media (min-width: #{3*$viewport-thereshold}) and (max-width:#{4*$viewport-thereshold}) {
  .honeycomb-cell:nth-child(5n + 4) {
    margin-left: calc(50% - #{$hexagon-width + $hexagon-margin});
  }

  .honeycomb-cell:nth-child(5n + 5) {
    margin-right: calc(50% - #{$hexagon-width + $hexagon-margin});
  }

  .honeycomb__placeholder:nth-child(5n),
  .honeycomb__placeholder:nth-child(5n + 3) {
    display: block;
  }
}

@media (min-width: #{4*$viewport-thereshold}) {
  .honeycomb-cell:nth-child(7n + 5) {
    margin-left: calc(50% - #{$hexagon-width * 1.5 + $hexagon-margin});
  }

  .honeycomb-cell:nth-child(7n + 7),
  .honeycomb-cell:nth-child(7n + 5):nth-last-child(2) {
    margin-right: calc(50% - #{$hexagon-width * 1.5 + $hexagon-margin});
  }

  .honeycomb__placeholder:nth-child(7n + 7),
  .honeycomb__placeholder:nth-child(7n + 9),
  .honeycomb__placeholder:nth-child(7n + 11) {
    display: block;
  }
}

.single-integration img {
  width: 100%;
  height: 80px;
  object-fit: contain;
}

.bg-grayservics {
  background-color: #fafafa;

  .services_card {
    .services_img {
      width: 100px;
      height: 100px;
      margin: auto;
    }

    text-align: center;
    padding: 70px 10px;

    &:hover {
      background-color: #f1f1f1;
    }

    h3 {
      font-weight: 400;
      margin-bottom: 0;
    }

    p {
      font-size: 15px;
      font-style: italic;
      margin-bottom: 0;
      padding: 14px 0;
    }

    a {
      font-size: 19px;
      text-decoration: underline;
      color: #000;
      font-weight: 500;
    }
  }

  .bg-srvicewsgray {
    background-color: #e6e9ed;
  }
}

.clicent.bg-clicent {
  background-color: #fffdfd;
  color: #000;
}

.our-clients .marquee-items.left {
  // -webkit-animation: marqueeLeft 90s linear infinite;
  // animation: marqueeLeft 90s linear infinite;
  // display: -ms-flexbox;
  // display: flex;
  // -ms-flex-wrap: nowrap;
  // flex-wrap: nowrap;
  // white-space: nowrap;
  // position: relative;
  // -webkit-transform: translateZ(0);
  // color: #111111;
  // transform: translateZ(0);

  .items_logo {
    width: 320px;
    height: 188px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0000000f;
    padding: 15px;

    img {
      width: 200px;
      height: 70px;
      object-fit: contain;
    }
  }
}

.our-clients .marquee-items.right {
  -webkit-animation: marqueeRight 100s linear infinite;
  animation: marqueeRight 100s linear infinite;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  white-space: nowrap;
  position: relative;
  -webkit-transform: translateZ(0);
  color: #111111;
  transform: translateZ(0);

  .items_logo {
    width: 320px;
    height: 188px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    // background-color: red;
    border: 1px solid #0000000f;

    img {
      width: 200px;
      height: 70px;
      object-fit: contain;
    }
  }
}

.h100vh_section {
  height: 100vh;
  padding: 60px 0;
}

.center_textdummy {
  padding-right: 150px;
}

.hegiht30 {
  height: 30px !important;
}

@keyframes marqueeLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes marqueeRight {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.homebanner {
  img {
    width: 100%;
    height: auto;
  }
}

.homebannertop {
  img {
    width: 100%;
    height: auto;
  }
}

.homebannertop {
  .homrbanner {
    width: 100%;
    height: auto;
  }
}

// .homebannertop{
//   .homrbanner {
//     width: 100%;
//     height: auto;
//     height: 700px;
//     object-fit: cover;
//   }
// }

// about pages css
.newOurJourney_section {
  background-color: #fbfbfb;
  // padding: 60px 0;
}

.jrny-border {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 100%;
    background-color: black;
    border: 1px solid transparent;
    border-radius: 10px;
    top: 45%;
  }

  .jrny-arrow {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 20px solid black;
      top: 45%;
      z-index: 2;
      left: 45%;
    }

    &:nth-child(2):before {
      -webkit-transform: rotate(57deg);
      transform: rotate(57deg);
      top: 44%;
    }

    &:nth-child(4):before {
      -webkit-transform: rotate(57deg);
      transform: rotate(57deg);
      top: 44%;
    }
  }

  .img-col {
    padding: 50px 0px;
    text-align: center;
  }

  .content-col {
    padding: 30px 0px;
  }

  .heading {
    font-weight: 600;
    font-size: 22px;
    line-height: 31px;
    color: #000;
    text-align: center;
    padding: 30px 0px 5px;
  }

  .description {
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: #ababab;
    text-align: center;
    min-height: 210px;
  }
}

.mission_value {
  position: relative;

  .imgabsolute {
    position: absolute;
    top: -55px;
    right: 0;
    left: 0;
    margin: auto;
    width: 100px;
    opacity: 0.4;
  }
}

.cour_value {
  .icon-head-wrap {
    display: flex;
    gap: 24px;

    img {
      width: 65px;
      height: 65px;
    }

    h4 {
      font-size: 22px;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      width: 90%;
    }
  }

  .its_best {
    padding: 17px 10px;
    // background: #fff;
    // border: 0.5px solid rgb(0 0 0 / 9%);
    // box-shadow: 0 0 50px rgba(0,0,0,.06);
    // border-radius: 16px;
    // min-height: 200px;
  }
}

.carrier_container {
  h4 {
    font-size: 30px;
    line-height: 42px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    border-radius: 12px;
  }
}

.main-blog {
  background-color: #f9f9f9;

  .al_center {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .blog_bx_left {
      width: 48%;
      height: 380px;
      box-shadow: 0 1px 6px -1px rgb(0 0 0 / 11%);
      border-radius: 8px;

      .blog__img {
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        display: flex;
        margin: auto;
        border-radius: 12px;
      }
    }

    .blog_bx_right {
      width: 48%;

      .blog-headingtext {
        font-size: 45px;
        color: #000;
        font-weight: 500;
      }

      p {
        font-size: 19px;
        line-height: 1.5;
        margin-bottom: 15px;
      }

      .writer_name,
      .content_date {
        color: #919191;
        font-size: 15px;
      }
    }
  }

  .container-fluid {
    padding: 0px 9pc;
  }
}

.category_blog a {
  padding: 5px 15px;
  border: 1px solid #b7b6b6;
  color: #000;
  border-radius: 8px;
  text-align: center;
  margin: 7px;
  font-size: 14px;

  &:hover {
    background-color: #fcaf17;
    color: #000 !important;
  }
}

.blog_listing {
  margin-bottom: 50px;

  .blog_listing_img {
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
      height: 280px;
      border-radius: 10px;
      object-fit: cover;
      -webkit-transition-delay: 0.1s;
      transition-delay: 0.1s;
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out;
      -webkit-transition-duration: 0.7s;
      transition-duration: 0.7s;
      -webkit-transition-property: all;
      transition-property: all;
    }

    &:hover img {
      -webkit-filter: brightness(0.6);
      filter: brightness(0.6);
      -webkit-transition: 1s all;
      transition: 1s all;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  .list_content_blog {
    h6 {
      margin: 20px 0;
      color: #fcaf17;
    }

    h4 {
      font-size: 20px;
      line-height: 30px;
      color: #4a4a4a;
      font-weight: 600;
    }

    p {
      margin: 12px 0;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      font-size: 15px;
    }

    .btn.blog_readmore {
      color: #000;
      font-weight: 600;
      padding-left: 0;
      font-size: 16px;

      &:hover {
        color: #fcaf17 !important;

        svg {
          font-size: 22px;
          transition: all 0.2s;
        }
      }

      svg {
        transition: all 0.2s;
      }
    }
  }
}

.blog2_heading {
  h3 {
    font-size: 50px;
    font-weight: 300;
  }

  h4 {
    font-size: 55px;
    color: #fcaf17;
    font-weight: 600;
  }

  p {
    font-size: 20px;
  }
}

.blog_card_img {
  img {
    width: 95%;
    height: 307px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.blog_card {
  margin-bottom: 30px;
}

.icon.svgiocn svg {
  height: unset;
  width: 27px;
  object-fit: unset;
}

.border-right {
  border-right: 1px solid #b0b3b5;
}

.info_date {
  h6 {
    margin-bottom: 0;
  }

  a {
    color: $yellow;
    font-weight: 600;
  }
}

.blog_page_heading {
  width: 90%;

  h5 {
    color: #001d33;
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0;
  }

  h3 {
    font-size: 21px;
    padding: 10px 0 5px;
    line-height: 1.4;
  }

  p {
    color: #5c5757;
    font-size: 19px;
    line-height: 29px;
    font-weight: 400;
  }

  ul {
    display: flex;
    gap: 10px;
    margin-top: 10px;

    li {
      font-size: 14px;
    }
  }
}

.category_blog.sticky-top {
  top: 2pc;

  h4 {
    padding-bottom: 10px;
    width: 75%;
    border-bottom: 1px solid #ccc;
  }
}

.our-portfolio-section-container {
  min-height: 5000px !important;

  .our_port_section {
    // height: 200px;
    height: 100vh !important;
    background-color: #ccc;

    .flex_row {
      display: flex;
    }

    .appent_left_section {
      flex: 0.7;
      background-color: #001d33;
      height: 100vh;
      transition: ease-in-out all 0.5s;

      .slider_portfliucontent {
        width: 80%;
      }
    }

    .appent_right_section {
      background-color: #12c21b;
      flex: 1;
      height: 100vh;
      transition: ease-in-out all 0.5s;
      position: relative;

      .bottommockupimg {
        position: absolute;
        bottom: 0;
        right: 10px;
        // transform: rotate(180deg);
      }

      .topmockupimg {
        transition-delay: 0.05s;
      }

      .bottommockupimg {
        transition-delay: 0.1s;
      }

      img {
        width: 30%;
      }

      .centermockupimg {
        position: absolute;
        top: 20%;
        margin: auto;
        right: 36%;
      }
    }
  }
}

.our-portfolio-section-container {
  position: relative;
}

.appent_left_section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;

  .slider_portfliucontent {
    h3 {
      color: #fff;
      font-size: 56px;
    }

    p {
      color: #fff;
      font-size: 24px;
      margin-bottom: 25px;
    }

    a {
      color: #fff !important;
      font-size: 30px;
      position: relative;
      padding-bottom: 10px;

      svg {
        color: #fff;
        font-size: 30px;
      }

      &::after {
        background: #fff;
        bottom: 0;
        content: "";
        height: 2px;
        left: 0;

        position: absolute;
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        width: 0;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }
}

.view_webprtfoliyo {
  display: flex;
  justify-content: space-between;

  .leftwebport {
    .bgimg {
      // background-image: url('../images/portfolio/North-Eastern.jpg');
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      position: absolute;

      background-color: #fbe6c1;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      height: 80vh;
      width: 80%;
      left: 0;
      top: 0;
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      -webkit-transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transition: -webkit-transform 750ms
        cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: -webkit-transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1),
        -webkit-transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .left_iluki {
      position: absolute;
      top: 0;
      height: 100%;
      width: 90%;
      overflow: hidden;
      // background-color: rgba(0,0,0,.1);
    }
  }

  .leftwebport,
  .rightwebport {
    flex: 1;
    position: relative;
    position: relative;
    height: 100vh;

    h3 {
      font-size: 40px;
    }

    h5 {
      font-size: 21px;
    }

    p {
      font-size: 19px;
      margin: 19px 0;
    }
  }

  .desktopicon {
    position: absolute;
    right: -11%;
    top: 12%;

    img {
      width: 90%;
    }
  }

  .rightwebport {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .widt80 {
      width: 80%;
    }
  }
}

.right_view_webprtfoliyo {
  display: flex;
  justify-content: space-between;
  height: 100vh;

  .bgimg {
    // background-image: url('../images/benefits/AI-in-banking.jpg');
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    background-color: #5ac4be;
    position: absolute;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 80vh;
    width: 80%;
    right: 0;
    top: 0;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -webkit-transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition: -webkit-transform 750ms
      cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: -webkit-transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1),
      -webkit-transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .leftwebport,
  .rightwebport {
    flex: 1;
    position: relative;
    position: relative;
    height: 100vh;

    h3 {
      font-size: 40px;
    }

    h5 {
      font-size: 21px;
    }

    p {
      font-size: 19px;
      margin: 19px 0;
    }

    .btnall {
      text-align: end;
      display: flex;
      justify-content: flex-end;
    }
  }

  .desktopicon {
    position: absolute;
    left: -9px;
    top: 20%;

    img {
      width: 95%;
    }
  }

  .rightwebport {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: end;
    padding-left: 14%;
  }
}

.whatappiocn {
  position: fixed;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  bottom: 35px;
  left: 35px;
  z-index: 99999;
}

.whatappiocn img {
  width: 50px;
  animation: pulse-animation 2s infinite;
}

.newportfolyo li:nth-child(1) .portfolicardview::before {
  background-color: #fbe6c1;
}

.newportfolyo li:nth-child(2) .portfolicardview::before {
  background-color: #5ac4be;
}

.newportfolyo li:nth-child(3) .portfolicardview::before {
  background-color: #6ecae7;
}

.newportfolyo li:nth-child(4) .portfolicardview::before {
  background-color: #f8c259;
}

.newportfolyo li:nth-child(5) .portfolicardview::before {
  background-color: #e87741;
}

.newportfolyo li:nth-child(6) .portfolicardview::before {
  background-color: #d4b25b;
}

.newportfolyo li:nth-child(7) .portfolicardview::before {
  background-color: #4979bc;
}

.newportfolyo li:nth-child(8) .portfolicardview::before {
  background-color: #e1d5cf;
}

.newportfolyo li:nth-child(9) .portfolicardview::before {
  background-color: #735b71;
}

.newportfolyo li:nth-child(10) .portfolicardview::before {
  background-color: #df7a53;
}

.newportfolyo li:nth-child(11) .portfolicardview::before {
  background-color: #30505f;
}

.newportfolyo li:nth-child(12) .portfolicardview::before {
  background-color: #ff7857;
}

// portfoliocardviewm

.newportfolyom li:nth-child(1) .portfolicardviewm::before {
  background-color: #a0dffd;
}

.newportfolyom li:nth-child(2) .portfolicardviewm::before {
  background-color: #e6d8ad;
}

.newportfolyom li:nth-child(3) .portfolicardviewm::before {
  background-color: #3840a8;
}

.newportfolyom li:nth-child(4) .portfolicardviewm::before {
  background-color: #126466;
}

.newportfolyom li:nth-child(5) .portfolicardviewm::before {
  background-color: #5a9aef;
}

.newportfolyom li:nth-child(6) .portfolicardviewm::before {
  background-color: #d4b25b;
}

.newportfolyom li:nth-child(7) .portfolicardviewm::before {
  background-color: #e15055;
}

.newportfolyom li:nth-child(8) .portfolicardviewm::before {
  background-color: #701c2b;
}

.newportfolyom li:nth-child(9) .portfolicardviewm::before {
  background-color: #c53b3a;
}

.newportfolyom li:nth-child(10) .portfolicardviewm::before {
  background-color: #bfadf8;
}

.newportfolyo {
  li {
    padding: 37px 0;
    min-height: 100%;
  }

  .card_portfoliyo {
    display: flex;
    justify-content: space-between;

    .flex1 {
      flex: 1;
    }

    .portfolicardview {
      position: relative;

      min-height: 550px;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        width: 75%;
        height: 100%;
      }

      img {
        width: 80%;
        z-index: 999;
        position: relative;
        margin-top: 6%;
      }
    }

    .leftcardportfoli {
      // height: 80vh;
      // display: flex;
      // align-items: center;
      // flex-direction: row;
      // padding: 60px;
      padding: 0px;
      margin-top: 20%;
    }

    .text_left {
      margin-left: 30%;
      text-align: end;
    }

    h3 {
      font-size: 40px;
    }

    p {
      font-size: 19px;
      margin: 19px 0;
    }
  }

  .leftsidecontnt {
    .portfolicardview {
      text-align: end;

      &::before {
        left: 0;
      }
    }

    .text_right {
      width: 70%;
    }
  }

  .flex_end {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

// newportfoliomobileview css

.newportfolyom {
  li {
    padding: 50px 0;
    min-height: 100%;
  }

  .card_portfoliyom {
    display: flex;
    justify-content: space-between;

    .flex1 {
      flex: 1;
    }

    .portfolicardviewm {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        width: 85%;
        min-height: 100%;
      }

      img {
        width: 40%;
        z-index: 999;
        position: relative;
        margin-top: 6%;
      }
    }

    .leftcardportfolim {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-top: 8%;
      padding: 60px;
    }

    .text_left {
      margin-left: 30%;
      text-align: end;
    }

    h3 {
      font-size: 40px;
    }

    p {
      font-size: 19px;
      margin: 19px 0;
    }
  }

  .leftsidecontntm {
    .portfolicardviewm {
      text-align: end;

      &::before {
        left: 0;
      }
    }

    .text_right {
      width: 70%;
    }
  }

  .flex_end {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

// 10-05-23 bhawana css

/********** Edtech ************/

.first-section {
  background: url("../images/edimg1.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-blend-mode: overlay;
  background-position: center !important;
  background-size: cover !important;
  // padding: 10% 0;
  height: 700px;
  display: flex;
  align-items: center;

  h1 {
    font-weight: 600;
    font-size: 34px;
    color: #fff;
  }

  span {
    color: #fcaf17 !important;
  }

  p {
    font-size: 20px;
    margin: 1rem auto 2rem;
    color: #fff;
    font-weight: 400;
  }

  .btn {
    background-color: #fcaf17;
    padding: 10px 20px;
    border-radius: 50px;
    color: #ffffff;
    font-size: 16px;
    border: 1px solid #fcaf17;
    font-weight: 700;
  }

  .btn:hover {
    border: 2px solid #fcaf17;
    color: #fcaf17 !important;
    background-color: transparent;
  }
}

.second-section {
  .consultation-form {
    background-color: #fff;
    border-radius: 20px;
    padding: 10%;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
  }

  h3 {
    padding: 0;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: black;
    font-size: 34px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: #212529;
    text-align: justify;
  }

  span {
    padding: 0;
    margin-bottom: 1.5rem;
    color: #fcaf17;
  }

  .title {
    font-size: 26px;
    color: #fcaf17;
  }

  form {
    padding-top: 10%;
  }

  .form-control {
    width: 100%;
    background: transparent;
    border: 1px solid #ced4da;
    color: #ffffff;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 400;
    box-shadow: none;
  }

  .consult-button {
    width: 100%;
    background-color: #fcaf17;
    border-radius: 5px;
    color: #ffffff;
    border: none !important;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px !important;
    padding: 11px 0;
  }

  img {
    margin-top: 15%;
  }
}

.third-section {
  position: relative;

  .section-img {
    object-fit: cover;
    /* max-width: 80%; */
    animation: mover 1s infinite alternate;
    margin-top: 50px;
  }

  @keyframes mover {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-15px);
    }
  }

  .third-title {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #212529;
  }

  span {
    color: #fcaf17;
    padding: 0;
  }

  .third-para {
    margin-bottom: 1rem;
    font-size: 16px;
    font-weight: 400;
    color: #212529;
    text-align: justify;
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  li {
    font-size: 17px;
    font-weight: 500;
    color: #9c9c9c;
    padding-bottom: 10px;
    position: relative;
  }

  li::before {
    content: "";
    position: absolute;
    display: block;
    width: 20%;
    height: 2px;
    background-color: #fcaf17;
    top: 70%;
    left: 0;
    transition: 0.2s;
  }

  li:hover::before {
    width: 30%;
  }

  // &::before{
  //     content: url("../images/square.png");
  //     top: 10%;
  //     position: absolute;
  //     left: -20%;
  //     transform: scale(.7);
  //     z-index: 99;
  // }
}

.fourth-section {
  background-color: #f9f9f9;

  h3.fourth-title {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #212529;
  }

  span {
    color: #fcaf17;
    padding: 0;
  }

  p {
    font-size: 17px;
    margin: auto;
    font-weight: 400;
    color: #212529;
  }

  ul {
    border-bottom: none;
    justify-content: center;
    margin: 1.5rem 0;
  }

  a.nav-link.active {
    border: none;
    font-size: 1rem;
    background-color: #fcaf17;
    color: #ffffff !important;
    border-radius: 50px;
    font-weight: 600;
    padding: 10px 40px;
    text-align: center;
    margin: 10px;
  }

  a.nav-link {
    border: none;
    font-size: 1rem;
    border-radius: 50px;
    background-color: #e8ebf1;
    color: #212121;
    font-weight: 600;
    padding: 10px 40px;
    text-align: center;
    margin: 10px;
  }

  a.nav-link:hover {
    background-color: #fcaf17;
    color: #ffffff !important;
  }

  .features {
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    padding: 2.5rem 2rem;
    text-align: center;
    margin: 1rem 0;
    min-height: 212px;
  }

  img {
    width: 100%;
    height: 60px;
    object-fit: contain;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: 0.6s;
  }

  img:hover {
    transform: rotateY(180deg);
  }

  h5 {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 24px;
    color: #212529;
    font-family: $roboto;
  }
}

.fifth-section {
  // background-image: url("../images/background.jpg");
  background-color: #f3f3f3;
  width: 100%;
  background-size: cover;

  h3.title {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #212529;
  }

  span {
    color: #fcaf17;
    padding: 0;
  }

  p {
    font-size: 17px;
    margin: auto;
    font-weight: 400;
    color: #212529;
  }

  p.para1 {
    font-size: 17px;
    margin: auto;
    font-weight: 400;
    color: #212529;
  }

  a.btn {
    background-color: #fcaf17;
    border-radius: 5px;
    box-shadow: 0 0 1px transparent;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    padding: 10px 20px;
    font-size: 17px;
  }

  a.btn:hover {
    color: #fff !important;
    background-color: #fcaf17 !important;
  }

  .work {
    display: block;
    position: relative;
    margin-bottom: 2rem;
    overflow: hidden;
    transition: transform 0.3s;

    .images1 {
      // width: 500px;
      height: 600px;
      object-fit: contain;
      transition: transform 0.3s;
    }

    .images2 {
      width: 500px;
      height: 600px;
      object-fit: contain;
      transition: transform 0.2s;
      position: absolute;
      top: 25%;
      left: 13%;
      z-index: 1;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9),
        hsla(0, 0%, 60%, 0.1) 40%
      );
    }

    &:hover img {
      transform: scale(1.1);
    }
  }
}

.sixth-section {
  background-color: #fff9ee;

  .sixth-title {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 0.7rem;
    color: #212529;
  }

  span {
    color: #fcaf17;
    padding: 0;
  }

  p {
    font-size: 17px;
    font-weight: 400;
    color: #212529;
  }

  .works_section {
    text-align: right;

    h5 {
      font-weight: 600;
      margin-bottom: 5px;
      font-size: 20px;
      color: #212529;
      font-family: $roboto;
      // text-align: right;
    }

    .para {
      font-size: 15px;
      color: #212529;
      // text-align: right;
      font-weight: 500;
    }
  }

  span.number {
    background-color: #162f6a;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 45px;
    // margin-left: 1.5rem !important;
    font-size: 1rem;
    padding: 8px 15px;
  }

  img {
    width: 100%;
    height: 600px;
    object-fit: contain;
    margin-top: 25%;
  }

  .right-side {
    h5 {
      text-align: left;
    }

    p {
      font-size: 15px;
      color: #212529;
      font-weight: 500;
    }

    span.number {
      background-color: #fe9435;
    }
  }
}

.seventh-section {
  background-color: #212529;

  .py-5 {
    h3 {
      font-weight: 500;

      font-size: 32px;

      color: #fff;

      text-align: center;
    }

    p {
      font-weight: 500;

      font-size: 24px;

      color: #fcaf17;

      text-align: center;
    }

    .btn {
      background-color: #fcaf17;

      padding: 10px 20px;

      border-radius: 50px;

      color: #ffffff;

      font-size: 16px;

      font-weight: 700;
    }

    .btn:hover {
      color: #fcaf17 !important;

      background-color: transparent !important;

      border: 1px solid #fcaf17;
    }
  }
}

.eigth-section {
  h3.eigth-title {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #212529;
  }

  span {
    color: #fcaf17;
    padding: 0;
  }

  p {
    font-size: 17px;
    margin: auto;
    font-weight: 400;
    color: #212529;
  }

  .borderright {
    border-right: 1px solid #fcaf17;
  }

  .bordertop {
    border-top: 1px solid #fcaf17;
  }

  .borderleft {
    border-left: 1px solid #fcaf17;
  }

  .head {
    h3 {
      font-size: 20px;
      font-weight: 600;
      color: #212529;
    }

    p {
      font-size: 15px;
      font-weight: 500;
      color: #9c9c9c;
    }
  }
}

.tenth-section {
  background-color: #fff;

  h3 {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #212529;
  }

  p {
    font-size: 17px;
    margin: auto;
    font-weight: 400;
    color: #212529;
  }

  span {
    color: #fcaf17;
  }

  .borderbottom {
    border-bottom: 2px solid #fcaf17;
  }

  .borderright {
    border-right: 2px solid #fcaf17;
  }

  .content {
    h4 {
      font-size: 18px;
      font-weight: 700;
      color: #212529;
    }

    p.desc {
      font-size: 14px;
      margin: auto;
      font-weight: 400;
      color: #212529;
    }
  }

  .image1 {
    animation: upDown 2s infinite ease-in-out alternate;
  }

  @keyframes upDown {
    0% {
      transform: translateY(0px);
    }

    100% {
      transform: translateY(-30px);
    }
  }
}

/************ecommerce************/
.first-banner {
  background-image: url("../images/ecommerce/banner1.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

  background-blend-mode: overlay;

  background-position: center !important;

  background-size: cover !important;

  // padding: 10% 0;

  width: 100%;

  height: 550px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 25px;

  h1.heading {
    font-weight: 600;

    font-size: 36px;

    color: #fff;
  }

  p {
    font-size: 16px;

    margin: 1rem auto 2rem;

    color: #e9e9e9;

    font-weight: 500;
  }

  span {
    padding: 0;

    font-weight: 600;

    font-size: 36px;

    color: #fcaf17 !important;
  }

  .btn {
    background-color: #fcaf17;

    padding: 10px 20px;

    border-radius: 50px;

    color: #ffffff;

    font-size: 16px;

    border: 1px solid #fcaf17;

    font-weight: 700;
  }

  .btn:hover {
    background-color: transparent;

    border: 1px solid #fcaf17;

    color: #fcaf17 !important;
  }

  .right-content {
    .head-img-1 {
      z-index: 3;

      top: 10%;

      left: 20%;
    }

    img {
      width: 100%;

      height: 100%;

      object-fit: contain;
    }

    .head-img-1::before {
      width: 80%;

      height: 95%;

      position: absolute;

      content: "";

      border: 3px dashed #e4a400;

      margin-left: -8%;

      margin-top: 30px;

      z-index: -1;
    }

    .overlay1 {
      width: 100%;

      min-height: 100%;

      background-color: rgba(0, 0, 0, 0.1);

      position: absolute;

      top: 0;

      left: 0;

      z-index: 999;
    }

    .head-img-2 {
      z-index: 1;

      position: relative;

      left: -10%;

      top: -40%;
    }

    .head-img-2::before {
      width: 80%;

      height: 95%;

      position: absolute;

      content: "";

      border: 3px dashed #e4a400;

      margin-left: 75px;

      margin-top: -15px;

      z-index: -1;
    }

    .overlay2 {
      width: 100%;

      min-height: 100%;

      background-color: rgba(0, 0, 0, 0.3);

      position: absolute;

      top: 0;

      left: 0;

      z-index: 999;
    }
  }
}

.second-banner {
  .absolute_img1 {
    width: 75%;
  }

  .absolute_img2 {
    position: absolute;

    bottom: -230px;

    right: 0;

    width: 75%;
  }

  .content {
    h1 {
      padding: 0;

      font-weight: 600;

      margin-bottom: 1.5rem;

      color: black;

      font-size: 36px;
    }

    p {
      font-size: 17px;

      color: #212529;

      text-align: justify;
    }

    span {
      padding: 0;

      font-weight: 600;

      margin-bottom: 1.5rem;

      color: #fcaf17;

      font-size: 36px;
    }
  }
}

.third-banner {
  .third-title {
    font-size: 36px;

    font-weight: 600;

    margin-bottom: 1.5rem;

    color: #212529;
  }

  span {
    font-size: 36px;

    color: #fcaf17;

    padding: 0;
  }

  p {
    font-size: 17px;

    margin: auto;

    font-weight: 400;

    color: #212529;
  }

  ul {
    border-bottom: none;

    justify-content: center;

    margin: 1.5rem 0;
  }

  a.nav-link.active {
    border: none;

    font-size: 1rem;

    background-color: #fcaf17;

    color: #ffffff !important;

    border-radius: 50px;

    font-weight: 600;

    padding: 10px 40px;

    text-align: center;

    margin: 10px;
  }

  a.nav-link {
    border: none;

    font-size: 1rem;

    border-radius: 50px;

    background-color: #e8ebf1;

    color: #212121;

    font-weight: 600;

    padding: 10px 40px;

    text-align: center;

    margin: 10px;
  }

  a.nav-link:hover {
    background-color: #fcaf17;

    color: #ffffff !important;
  }

  .features {
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);

    border-radius: 15px;

    padding: 2.5rem 2rem;

    text-align: center;

    margin: 1rem 0;

    min-height: 225px;
  }

  img {
    width: 100%;

    height: 60px;

    object-fit: contain;

    cursor: pointer;

    margin-bottom: 1rem;

    transition: 0.6s;
  }

  img:hover {
    transform: rotateY(180deg);
  }

  h5 {
    font-weight: 600;

    font-size: 17.5px;

    line-height: 24px;

    color: #212529;

    font-family: $roboto;
  }
}

.fourth-banner {
  background-color: #f9f9f9;

  h3 {
    font-size: 36px;

    font-weight: 600;

    margin-bottom: 1.5rem;

    color: #212529;
  }

  span {
    font-size: 36px;

    color: #fcaf17;

    padding: 0;
  }

  .box {
    z-index: 1 !important;

    height: 100px;

    background-color: white;

    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);

    padding: 34px 0px 0px 0px;

    .sub-head {
      // margin-top: 20px;

      height: 60px;

      transition: height 1s ease-in-out;

      .heading {
        font-size: 18px;

        font-weight: 600;

        transition: 0.3s;

        margin-bottom: 0;

        color: #212529;
      }
    }

    .box-description {
      opacity: 0;

      visibility: hidden;

      z-index: -10 !important;

      // margin-top: 10px;

      .description {
        font-weight: 500;

        font-size: 16px;

        color: #9c9c9c;

        // text-align: justify;

        // padding-bottom: 20px;
      }
    }
  }

  .box:hover {
    height: 300px;

    transition: all 1s ease-in-out;

    // transition-delay: 1s !important;

    z-index: 1 !important;
  }

  .box:hover .box-description {
    opacity: 1;

    // margin: 10px;

    visibility: visible;

    // transition-delay: 1s !important;

    // height: max-content;

    transition: all 1s ease-in-out;

    z-index: 1 !important;
  }

  // .box:hover .box-description .description{

  // height: max-content;

  // }
}

.fifth-banner {
  h3 {
    font-size: 36px;

    font-weight: 600;

    margin-bottom: 1.5rem;

    color: #212529;
  }

  span {
    color: #fcaf17;

    font-size: 36px;
  }

  .content {
    min-height: 300px;

    box-shadow: inset 0 0 7px rgb(0 0 0 / 35%);

    background-color: #fff;

    transition: all 0.3s;

    border-radius: 7px;

    padding: 0px 12px 15px 12px;

    h3 {
      background-color: #fcaf17;

      color: #fff;

      font-weight: 600;

      display: inline-block;

      position: relative;

      top: -21px;

      // line-height: 70px;

      margin-bottom: 0;

      // border-radius: 50%;

      padding: 5px 22px 5px 22px;

      border-radius: 5px;

      font-size: 24px;
    }

    h4 {
      font-size: 18px;

      font-weight: 700;

      margin-top: 15px;
    }

    p {
      font-size: 15px;

      color: #212529;

      font-weight: 500;

      padding: 0px 12px;
    }
  }
}

.sixth-banner {
  background-color: #f9f9f9;

  h3 {
    font-size: 36px;

    font-weight: 600;

    margin-bottom: 1.5rem;

    color: #212529;
  }

  span {
    font-size: 36px;

    color: #fcaf17;

    padding: 0;
  }

  .inner_content {
    margin-top: 30px;

    img {
      width: 100%;

      height: 75px;

      object-fit: contain;

      margin-top: 6px;
    }

    h3 {
      font-size: 19px;

      font-weight: 600;

      color: #212529;

      line-height: 30px;

      margin: 0;

      margin-bottom: 13px;
    }

    p {
      font-size: 15px;

      font-weight: 500;

      color: #9c9c9c;
    }
  }
}

.seventh-banner {
  h3 {
    font-size: 36px;

    font-weight: 600;

    margin-bottom: 1.2rem;

    color: #212529;
  }

  span {
    font-size: 36px;

    color: #fcaf17;

    padding: 0;
  }

  p {
    font-size: 17px;

    color: #212529;
  }

  .slider {
    background-color: #f9f9f9;

    padding: 50px 70px;

    margin-top: 40px;

    border-radius: 10px;

    .slide_content {
      h4 {
        font-weight: 700;

        font-size: 20px;

        color: #4a4a4a;
      }

      p {
        font-size: 16px;

        color: #5c5c5c;
      }
    }

    img {
      width: 100%;

      height: 80px;

      object-fit: contain;

      float: right;

      margin-right: -110px;
    }

    .left {
      p {
        font-weight: 600;

        font-size: 16px;

        line-height: 40px;

        color: #5c5c5c;
      }

      p::before {
        content: url("../images/ecommerce/check.svg");

        padding-right: 15px;

        position: relative;

        top: 5px;
      }
    }

    .right {
      p {
        font-weight: 600;

        font-size: 16px;

        line-height: 40px;

        color: #5c5c5c;
      }

      p::before {
        content: url("../images/ecommerce/check.svg");

        padding-right: 15px;

        position: relative;

        top: 5px;
      }
    }
  }
}

.eigth-banner {
  background-color: #f9f9f9;

  // margin-top:8%;

  .query_help {
    gap: 15px;
  }

  h4 {
    font-weight: 600;

    font-size: 22px;

    color: #212529;
  }

  p {
    font-size: 1rem;

    line-height: 1.5;

    color: #5c5c5c;
  }

  svg {
    width: 100%;

    height: 30px;

    object-fit: contain;
  }

  span {
    border-left: 1px solid #212529;

    padding: 0 0px 0 18px;

    h6 {
      font-size: 12px;

      opacity: 0.8;

      color: #212529;

      margin-bottom: 5px;
    }

    p {
      font-weight: 600;

      opacity: 1;

      margin: 0;

      font-size: 1.2rem;

      color: #fcaf17;
    }
  }

  ul {
    list-style-type: disc;

    h5 {
      font-size: 1.25rem;

      margin: 30px 0 0;

      font-weight: 500;

      color: #212529;
    }

    li {
      font-weight: 500;

      opacity: 0.8;

      line-height: 26px;

      list-style: inside;

      color: #212529;

      font-size: 1rem;
    }
  }

  hr {
    width: 1px;

    height: 85%;

    background-color: #5c5c5c;

    // margin: auto;

    margin-top: 35px;

    border: 0;

    // border-top: 1px solid rgba(0,0,0,.1);
  }

  .Q-form {
    background-color: #fff;

    border-radius: 10px !important;

    padding: 3rem;

    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);

    .left {
      .form-control {
        box-shadow: none;

        border: 1px solid #ccc;

        color: #5c5c5c;
      }
    }
  }
}

.ninth-banner {
  h3 {
    font-size: 36px;

    font-weight: 700;

    margin-bottom: 1.2rem;

    color: #212529;
  }

  span {
    font-size: 36px;

    color: #fcaf17;

    padding: 0;
  }

  p {
    font-size: 17px;

    color: #212529;
  }

  .accordion-item {
    // border: none;

    border-radius: 0.75rem;

    background-color: #fff;

    border-bottom: 0;

    position: relative;

    border-left: 0.25rem solid #fcaf17;

    box-shadow: 0rem 0rem 0.5rem rgba(35, 44, 59, 0.15);

    .accordion-button {
      box-shadow: none;

      background-color: #fff;

      font-weight: 700;

      font-size: 1.05rem;

      color: #212529;
    }

    .accordion-collapse {
      p {
        font-size: 17px;

        color: #212529;
      }
    }
  }
}

.tenth-banner {
  h3 {
    font-size: 36px;

    font-weight: 600;

    margin-bottom: 1.2rem;

    color: #212529;
  }

  span {
    font-size: 36px;

    color: #fcaf17;

    padding: 0;
  }

  p {
    font-size: 17px;

    color: #212529;
  }
}

.eleventh-banner {
  background-color: #212529;

  .py-5 {
    h3 {
      font-weight: 500;

      font-size: 32px;

      color: #fff;

      text-align: center;
    }

    p {
      font-weight: 500;

      font-size: 24px;

      color: #fcaf17;

      text-align: center;
    }

    .btn {
      background-color: #fcaf17;

      padding: 10px 20px;

      border-radius: 50px;

      color: #ffffff;

      font-size: 16px;

      font-weight: 700;
    }

    .btn:hover {
      color: #fcaf17 !important;

      background-color: transparent !important;

      border: 1px solid #fcaf17;
    }
  }
}

/************grocery app************/

.gro_first-banner {
  background: url("../images/grocery/grobanner.jpg"),
    linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));

  background-blend-mode: overlay;

  background-position: center !important;

  background-size: cover !important;

  padding: 10% 0;

  height: 700px;

  // display: flex;

  // align-items: center;

  h1.heading {
    font-weight: 700;

    font-size: 32px;

    color: #fff;
  }

  span {
    font-weight: 700;

    font-size: 32px;

    color: #fcaf17 !important;
  }

  p {
    font-size: 15px;

    margin: 1rem auto 2rem;

    color: #e9e9e9;

    font-weight: 500;
  }

  .btn {
    background-color: #fcaf17;

    padding: 10px 20px;

    border-radius: 50px;

    color: #ffffff;

    font-size: 16px;

    border: 1px solid #fcaf17;

    font-weight: 700;
  }

  .btn:hover {
    border: 2px solid #fcaf17;

    color: #fcaf17 !important;

    background-color: transparent;
  }

  .left-content {
    .head-img-1 {
      position: relative;

      top: -20%;

      transition: all 0.5s;
    }

    .head-img-2 {
      position: relative;

      top: -8%;

      transition: all 0.5s;
    }

    .head-img-1:hover {
      margin-top: -10%;
    }

    .head-img-2:hover {
      margin-top: -10%;
    }

    img {
      width: 100%;

      height: 500px;

      object-fit: contain;
    }
  }
}

.gro_second-banner {
  h1 {
    padding: 0;

    font-weight: 600;

    margin-bottom: 1.5rem;

    color: black;

    font-size: 36px;
  }

  span {
    font-weight: 600;

    font-size: 36px;

    color: #fcaf17 !important;
  }

  p {
    font-size: 17px;

    color: #212529;

    text-align: justify;
  }

  img {
    width: 100%;

    height: 450px;

    object-fit: contain;
  }
}

.gro_third-banner {
  .third-title {
    font-size: 36px;

    font-weight: 600;

    margin-bottom: 1.5rem;

    color: #212529;
  }

  span {
    font-size: 36px;

    color: #fcaf17;

    padding: 0;

    font-weight: 600;
  }

  p {
    font-size: 17px;

    margin: auto;

    font-weight: 400;

    color: #212529;
  }

  ul {
    border-bottom: none;

    justify-content: center;

    margin: 1.5rem 0;
  }

  a.nav-link.active {
    border: none;

    font-size: 1rem;

    background-color: #fcaf17;

    color: #ffffff !important;

    border-radius: 50px;

    font-weight: 600;

    padding: 10px 40px;

    text-align: center;

    margin: 10px;
  }

  a.nav-link {
    border: none;

    font-size: 1rem;

    border-radius: 50px;

    background-color: #e8ebf1;

    color: #212121;

    font-weight: 600;

    padding: 10px 40px;

    text-align: center;

    margin: 10px;
  }

  a.nav-link:hover {
    background-color: #fcaf17;

    color: #ffffff !important;
  }

  .features {
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);

    border-radius: 15px;

    padding: 2.5rem 2rem;

    text-align: center;

    margin: 1rem 0;

    min-height: 225px;
  }

  img {
    width: 100%;

    height: 60px;

    object-fit: contain;

    cursor: pointer;

    margin-bottom: 1rem;

    transition: 0.6s;
  }

  img:hover {
    transform: rotateY(180deg);
  }

  h5 {
    font-weight: 600;

    font-size: 17.5px;

    line-height: 24px;

    color: #212529;

    font-family: $roboto;
  }
}

.gro_fourth-banner {
  h1.heading {
    font-size: 36px;

    font-weight: 600;

    margin-bottom: 1.5rem;

    color: #212529;
  }

  span {
    font-size: 36px;

    color: #fcaf17;

    padding: 0;

    font-weight: 600;
  }

  p {
    font-size: 17px;

    margin: auto;

    font-weight: 400;

    color: #212529;
  }

  .head {
    h3 {
      font-size: 18px;

      font-weight: 600;

      margin-top: 30px;

      margin-bottom: 0;

      color: #212529;
    }

    p {
      font-weight: 500;

      font-size: 16px;

      margin-top: 20px;

      color: #9c9c9c;
    }
  }

  .borderright {
    border-right: 2px solid #efefef;
  }

  .bordertop {
    border-top: 2px solid #efefef;
  }
}

.gro_fifth-banner {
  h1.heading {
    font-size: 36px;

    font-weight: 600;

    margin-bottom: 1.5rem;

    color: #212529;
  }

  span {
    font-size: 36px;

    color: #fcaf17;

    padding: 0;

    font-weight: 600;
  }

  p {
    font-size: 17px;

    margin: auto;

    font-weight: 400;

    color: #212529;
  }

  .modal-content1 {
    border-radius: 10px;

    padding: 1.5rem;

    border: 1px solid #e0e0e0;

    .business-content {
      .img-fluid {
        width: 50px;

        height: 50px;

        // object-fit: contain;
      }

      h4 {
        font-size: 22px;

        font-weight: 600;

        margin-top: 20px;

        color: #000;
      }

      p {
        font-size: 16px;

        font-weight: 500;

        color: #212529;
      }
    }
  }

  .modal-content1:hover {
    box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07),
      0 30.1471px 24.1177px rgba(0, 0, 0, 0.0456112),
      0 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
      0 4.5288px 3.62304px rgba(0, 0, 0, 0.02438);
  }

  .business_img-box {
    .img-fluid {
      width: 100%;

      height: 250px;

      object-fit: cover;

      border-radius: 10px;
    }
  }
}

.gro_sixth-banner {
  h1 {
    font-size: 36px;

    font-weight: 600;

    margin-bottom: 1.5rem;

    color: #212529;
  }

  span {
    font-size: 36px;

    color: #fcaf17;

    padding: 0;

    font-weight: 600;
  }

  p {
    font-size: 17px;

    margin: auto;

    font-weight: 400;

    color: #212529;
  }
}

.gro_seventh-banner {
  h1 {
    font-size: 36px;

    font-weight: 600;

    margin-bottom: 1.5rem;

    color: #212529;
  }

  span {
    font-size: 36px;

    color: #fcaf17;

    padding: 0;

    font-weight: 600;
  }

  p {
    font-size: 17px;

    margin: auto;

    font-weight: 400;

    color: #212529;
  }

  .accordion-item {
    // border: none;

    border-radius: 0.75rem;

    background-color: #fff;

    border-bottom: 0;

    position: relative;

    border-left: 0.25rem solid #fcaf17;

    box-shadow: 0rem 0rem 0.5rem rgba(35, 44, 59, 0.15);

    .accordion-button {
      box-shadow: none;

      background-color: #fff;

      font-weight: 500;

      font-size: 16px;

      color: #212529 !important;
    }

    .accordion-collapse {
      p {
        font-size: 16px;

        color: #212529;
      }
    }
  }
}

.gro_eigth-banner {
  background-color: #212529;

  .py-5 {
    h3 {
      font-weight: 500;

      font-size: 32px;

      color: #fff;

      text-align: center;
    }

    p {
      font-weight: 500;

      font-size: 24px;

      color: #fcaf17;

      text-align: center;
    }

    .btn {
      background-color: #fcaf17;

      padding: 10px 20px;

      border-radius: 50px;

      color: #ffffff;

      font-size: 16px;

      font-weight: 700;
    }

    .btn:hover {
      color: #fcaf17 !important;

      background-color: transparent;

      border: 1px solid #fcaf17;
    }
  }
}

// mukul css 01-05-2023
// dropdown button solutions  mukul 28-04-2023

.menu-item {
  display: inline-block;
  background-color: #fff;
  position: relative;

  a {
    text-decoration: none;
    color: #000;
    display: block;
  }

  &:hover .drop-menu {
    display: block;
  }
}

.drop-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.drop-menu-item {
  width: 100%;

  a {
    color: #555;
    font-size: 14px;
    padding: 0px;
  }

  &:hover {
    background-color: #eee;
  }
}

// travel-app-header

// .travel-about-us
// {
//   background-color: #f9f9f9;
// }

.travel-app-header .navbar {
  background-color: #eeee;
  color: #000;
}

// travel-about

.about-heading {
  .head {
    font-size: 40px;
    text-transform: uppercase;
    color: #020202;
    font-weight: 800;
    font-family: "Roboto", sans-serif;
    margin-bottom: 25px;

    span {
      color: rgb(252 175 23);
    }
  }

  .desc {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    line-height: 20px;
  }
}

.about-content {
  .main-heading {
    font-size: 26px;
    font-weight: 600;
    color: #020202;
    // margin-top: 35px;

    span {
      color: rgb(252 175 23);
    }
  }

  .desc {
    font-size: 15px;
  }

  .title {
    font-size: 45px;
    color: #020202;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }

  .sub-heading {
    position: relative;
    color: rgb(252 175 23);
    font-size: 16px;
    font-weight: 600;

    &::before {
      content: "";
      position: absolute;
      background-color: rgb(252 175 23);
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 2px;
    }
  }
}

// .travel-about-us .about-img
// {
//   padding-top: 50px;
// }

.about-button {
  margin-top: 20px;

  .read-more-button {
    background-color: rgb(252, 175, 23);
    border: 1px solid rgb(252, 175, 23);
    border-radius: 5px;
    padding: 8px 25px;
    color: #ffffff;
    font-size: 14px;

    &:hover {
      color: white !important;
    }
  }
}

// features-area

.features-head {
  h2 {
    font-size: 32px;
    font-weight: 600;
    color: #020202;
    font-family: "Poppins", sans-serif;
    text-align: center;
    margin-bottom: 20px;

    span {
      color: rgb(252 175 23);
    }
  }

  .desc {
    font-size: 16px;
    font-family: $roboto;
    line-height: 20px;
    margin: 0 auto;
    text-align: center;
  }
}

.features-tab-pane .nav-tabs .nav-item button {
  background-color: #e8ebf1;
  border-radius: 50px;
  color: #020202;
  padding: 10px 42px;
  margin: 0px 20px;
  font-weight: 600;
}

.features-tab-pane .nav-tabs .nav-item button:hover {
  background-color: rgb(252 175 23);
  color: #fff;
}

.features-tab-pane .tab-content {
  margin-top: 20px;
}

.features-tab-pane .nav-tabs {
  border-color: transparent !important;
}

.features-tab-pane .nav-tabs .nav-link.active {
  border-color: transparent;
  background-color: rgb(252 175 23);
  color: #fff;
}

.features-card {
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
  background-color: white;
  padding: 32px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s linear;
  min-height: 225px;
  margin-bottom: 25px;
}

.features-img {
  text-align: center;
}

.features-img img {
  width: 55px;
  height: 55px;
  margin-bottom: 15px;
  text-align: center;
}

.features-img svg {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  text-align: center;
  color: rgb(252 175 23);
}

.features-card h5 {
  color: #020202;
  font-weight: 400;
  padding: 10px;
  margin-top: 10px;
}

.features-card:hover img {
  transform: rotateY(360deg);
  transition: all 0.7s linear;
}

// how-it-works

.how-it-works-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;

  img {
    width: 75%;
  }
}

.works-list {
  display: flex;
  align-items: center;
  min-height: 130px;

  li {
    margin-right: 5px;
    margin-bottom: 10px;

    .inner-works-list {
      h5 {
        font-size: 20px;
        color: #020202;
        font-family: $poppin;
      }

      li {
        .desc {
          font-size: 14px;
        }
      }
    }
  }

  .circle-no {
    display: inline-block;
    width: 45px;
    height: 45px;
    background-color: #020202;
    border-radius: 50%;
    text-align: center;
    line-height: 45px;
    color: white;
  }
}

.works-list-1 {
  display: flex;
  align-items: center;
  min-height: 130px;

  li {
    margin-left: 15px;

    .inner-works-list {
      h5 {
        font-size: 20px;
        color: #020202;
        font-family: $poppin;
      }

      li {
        .desc {
          font-size: 14px;
        }
      }
    }
  }

  .circle-no {
    display: inline-block;
    width: 45px;
    height: 45px;
    background-color: rgb(252 175 23);
    border-radius: 50%;
    text-align: center;
    line-height: 45px;
    color: white;
  }
}

.how-it-works-main {
  display: flex;
  align-items: center;
}

// portfolio-area

.portfolio-area {
  background-color: #f5e9ce;
  width: 100%;
  position: relative;
}

.portfolio-left-area {
  background-color: rgb(215 164 64);
  width: 40.6%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 85px;

  h4 {
    color: white;
    font-size: 40px;
    font-style: italic;
    font-weight: 500;
    font-family: $poppin;
  }

  .head-part {
    font-size: 75px;
    text-align: center;
    color: white;
    font-weight: 700;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: inherit;
  }

  .sub-heading-area {
    margin-bottom: 30px;
  }

  .quote {
    position: absolute;
    bottom: 15%;
    left: 12%;
    color: white;
    font-size: 20px;
    font-family: $roboto;
  }

  .connect {
    position: absolute;
    top: 33%;
    left: 10%;
    right: 5%;

    .desc {
      color: white;
      font-size: 24px;
      font-family: $roboto;
    }
  }
}

// faq-area

.faq-area {
  background-color: #fff9ee;
}

.faq-accordion .accordion-button:not(.collapsed) {
  color: #020202 !important;
  background-color: rgb(247 247 247) !important;
  box-shadow: none !important;
}

.faq-accordion .accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: none !important;
}

.faq-accordion .accordion-button {
  color: #020202 !important;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 16px;
}

.faq-accordion .accordion-header {
  position: relative;
}

.faq-accordion .accordion-header svg {
  content: "";
  position: absolute;
  right: 2%;
  top: 35%;
}

.minus-icon {
  display: none;
}

.faq-accordion .accordion-item {
  border: transparent !important;
}

.faq-accordion .accordion-item .accordion-header {
  background-color: #ffffff !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 20px;
  font-size: 16px;
}

.faq-accordion .accordion-button:not(.collapsed) {
  background-color: white !important;
}

.faq-accordion .accordion-button::after {
  background-image: none !important;
}

.faq-accordion .accordion-button {
  background-image: none !important;
}

.faq-accordion .accordion-body {
  background-color: $theme-yellow;
  color: white;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
}

.faq-accordion .accordion-button {
  border-left: 5px solid $theme-yellow !important;
  height: 60px;
}

.faq-accordion .accordion-button:not(.collapsed) .minus-icon {
  display: block;
}

.faq-accordion .accordion-button:not(.collapsed) .plus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .minus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .plus-icon {
  display: block;
}

.padding-0 {
  padding: 0px !important;
}

// banner-sec

.banner-sec {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
  }
}

.travel-banner-content {
  position: absolute;
  top: 45%;
  left: 10%;
  z-index: 1;

  h2 {
    color: white;
    font-size: 45px;
    font-weight: 600;
    font-family: $roboto;
    margin-bottom: 0px;
  }

  .desc {
    font-family: 18px;
    color: white;
    font-family: $roboto;
    margin-top: 15px;
  }
}

.desc-button {
  margin: 20px 0px;
}

.req-demo {
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background-color: rgb(252 175 23);
    border-color: transparent;
    color: white !important;
  }
}

.travel-choose-card {
  position: relative;
  padding: 25px 15px;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  margin-bottom: 25px;
  min-height: 176px;

  .heading-part {
    position: absolute;
    top: -10px;
    background-color: white;
    left: 30px;

    h4 {
      color: #020202;
      font-size: 18px;
      font-family: $poppin;
      background: white;
    }
  }

  .choose-content {
    .desc {
      font-size: 14px;
      font-family: $roboto;
      line-height: 24px;
    }
  }
}

.explore-more-button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  padding: 15px 42px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;

  &:hover {
    background-color: #fff2d9;
    border-color: transparent;
  }
}

.py-40 {
  padding: 40px 0px;
}

.job-banner-sec {
  position: relative;

  .job-banner-content {
    position: absolute;
    top: 30%;
    left: 12%;

    h1 {
      color: white;
      font-size: 32px;
      font-family: $poppin;
      margin-bottom: 25px;

      span {
        display: block;
        font-size: 44px;
        font-family: $poppin;
      }
    }

    .desc {
      font-size: 18px;
      color: white;
      font-family: $roboto;
      margin-bottom: 35px;
    }

    .get-demo {
      position: relative;
      color: white;
      padding: 12px 30px;
      border-radius: 30px;
      background-color: rgb(252, 175, 23);
      border: 1px solid rgb(252, 175, 23);
      font-size: 18px;
      font-weight: 500;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        animation: pulse-animation 2s infinite;
        transition: all 0.5s;
        box-shadow: 0 0 0 rgb(252, 175, 23);
      }

      &:hover {
        color: white !important;
        background-color: transparent;
        border-color: white;
      }
    }
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgb(252, 175, 23);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.job-portal-services-head {
  text-align: center;

  h2 {
    font-size: 32px;
    font-weight: 600;
    font-family: $poppin;
    margin-bottom: 20px;

    span {
      color: rgb(252 175 23);
    }
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    width: 80%;
    margin: 0 auto;
  }
}

.job-services-card {
  position: relative;
  background: #fbfbfb;
  border: 1px solid #ededed;
  padding: 20px;
  margin-bottom: 30px;
  min-height: 278px;
  text-align: center;
  width: 100%;
  transition: all 0.3s ease-in-out;

  &:hover {
    .uiux-black {
      display: none;
    }

    .uiux-white {
      display: block;
      line-height: 50px;
      margin: 0 auto;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fcaf17;
    height: 2px;
    width: 0%;
  }
}

.job-services-img {
  text-align: center;
  line-height: 90px;
  border-radius: 50%;

  img {
    width: 50px;
    height: 50px;
  }

  .uiux-black {
    width: 50px;
    height: 50px;
    text-align: center;
    margin: 0 auto;
  }

  .uiux-white {
    display: none;
    text-align: center;
  }
}

.job-services-content {
  h4 {
    margin-top: 15px;
    font-size: 17px;
    font-family: $poppin;
    cursor: pointer;
    color: #020202;
  }

  .desc {
    font-size: 13px;
    font-family: $roboto;
  }
}

.job-services-card:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.job-services-card:hover .job-services-img {
  width: 90px;
  height: 90px;
  background-color: #fcaf17;
  border-radius: 50%;
  color: white;
  line-height: 90px;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
}

.job-services-card:hover .job-services-img {
  width: 90px;
  height: 90px;
  background-color: #fcaf17;
  border-radius: 50%;
  color: white;
  transition: 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-services-card:hover .job-services-content h4 {
  color: #fcaf17;
}

.job-services-card:hover::after {
  width: 100%;
  transition: all 0.4s ease-in-out;
}

// on-demand-tab-pane-area

.on-demand-head-area .sub-head {
  font-size: 18px;
  color: white;
  font-family: $roboto;
  margin-bottom: 15px;
}

.on-demand-head-area .main-head {
  color: white;
  font-family: $poppin;
  font-size: 24px;
}

.pb-40 {
  padding-bottom: 40px;
}

.on-demand-my-tab {
  border-bottom: 1px solid #939393 !important;
  // transition: all 0.3s ease;

  &:hover {
    border-bottom: 3px solid white !important;
  }

  .on-demand-tab-head {
    color: white;
    font-family: $poppin;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
  }
}

.admin-content-list-box {
  padding: 30px 0px 0px 30px;
}

.admin-content-list-box1 {
  padding: 30px 0px 0px 0px;
}

.admin-content-list li {
  color: white;
  line-height: 45px;
  font-size: 18px;
  font-family: $roboto;
}

.admin-content-list li svg {
  margin-right: 10px;
  font-size: 20px;
  color: white;
}

// job-features-tab-pane

.job-features-tab-pane {
  position: relative;

  .tab-content {
    margin-top: 20px;
  }

  .nav-tabs {
    border: none !important;
  }

  .co-features {
    position: absolute;
    left: 31.5%;
    top: 2.5%;
    z-index: 99999;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.ad-features img {
  width: 24px;
  height: 24px;
}

.job-features-tab-pane .ad-features {
  position: absolute;
  left: 53%;
  top: 2.5%;
  z-index: 99999;
}

.job-features-tab-pane .nav-tabs .nav-item .nav-link {
  font-family: $poppin;
  font-weight: 600;
  border-radius: 0px;
  background-color: #dcdcdc;
  color: #000;
  border: transparent;
}

.job-features-tab-pane .nav-tabs .nav-item .nav-link.active {
  background-color: #fcaf17 !important;
  color: #fff !important;
  border: transparent !important;
}

// .job-features-tab-pane .nav-tabs .nav-item .nav-link.active

.job-features-tab-pane .nav-tabs .nav-item button {
  background-color: #dcdcdc;
  color: #000;
  margin-right: 5px;
  padding: 20px 50px;
  border: transparent;

  &:hover {
    background-color: #fcaf17;
    color: #fff;
  }
}

// job-p-features-card

.job-p-features-card {
  position: relative;
  background: #fbfbfb;
  border: 1px solid #ededed;
  padding: 20px;
  margin-bottom: 30px;
  min-height: 260px;
  text-align: center;
  width: 100%;
  transition: all 0.3s ease-in-out;

  .job-p-features-content {
    h4 {
      margin-top: 15px;
      font-size: 18px;
      font-family: $poppin;
      cursor: pointer;
      color: #020202;
    }

    .desc {
      font-size: 13px;
      font-family: $roboto;
    }
  }

  .job-p-features-img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 50%;

    img {
      width: 60px;
      height: 60px;
    }
  }

  &:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    background: #fff;

    .job-p-features-img {
      background: #fbfbfb;
      border: 1px solid #e4f2ff;
    }

    .job-p-features-content h4 {
      color: #fcaf17;
    }
  }
}

// our-recent-works

.our-recent-works-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  margin-bottom: 25px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 21.88%,
      rgba(0, 0, 0, 0.7) 100%
    );

    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    transform: translateY(114px);
    transition: all 0.3s ease;
  }

  &:hover img {
    transform: scale(1.05);
  }

  &:hover::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, #000 100%);
    transform: translateY(0);
  }

  img {
    transform: scale(1);
    transition: all 0.3s ease;
    object-fit: cover;
  }

  &:hover .our-recent-works-content {
    transform: translateY(0);
    transition: all 0.3s ease;
  }

  .our-recent-works-content {
    position: absolute;
    bottom: 0%;
    left: 0%;
    z-index: 1;
    width: 100%;
    transform: translate(0px, 42px);
    padding: 20px;

    h4 {
      color: white;
      margin-bottom: 0px;
    }

    p {
      color: white;
      margin-bottom: 20px;
    }

    a {
      color: #fcaf17;
      font-weight: 700;

      &:hover {
        color: #fcaf17 !important;
      }
    }
  }
}

.our-recent-works-head {
  text-align: center;

  h2 {
    font-size: 36px;
    font-weight: 600;
    font-family: $poppin;
    margin-bottom: 20px;

    span {
      color: rgb(252 175 23);
    }
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    width: 80%;
    margin: 0 auto;
  }
}

.recent-work-bottom {
  text-align: center;

  .desc {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .view-all-button {
    display: inline-block;
    background-color: #fcaf17;
    color: white;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: 600;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-10px);
      color: #ffff !important;
    }
  }
}

.why-choose-j-p {
  // background-color: #f9f9f9;

  .why-choose-j-p-head {
    text-align: center;

    h2 {
      font-size: 34px;
      font-weight: 600;
      font-family: $poppin;
      margin-bottom: 20px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 14px;
      font-weight: 400;
      font-family: $roboto;
      width: 80%;
      margin: 0 auto;
      color: #939393;
    }
  }
}

// why-choose-jp-card

.why-choose-jp-card {
  background-color: white;
  border: 1px solid #ededed;
  min-height: 210px;
  transition: 0.3s ease-out;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  padding: 25px;
  cursor: pointer;

  &::before {
    z-index: 2;
    background: #efd8ae;
    left: inherit;
    bottom: inherit;
    right: -18px;
    width: 37px;
    top: -18px;
    height: 37px !important;
    transform: rotate(45deg);
    content: "";
    position: absolute;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
  }

  &:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);

    &::before {
      background-color: #fcaf17;
    }

    .jp-head::before {
      width: 20%;
      transition: all 0.3s ease;
    }
  }

  .jp-head {
    position: relative;
    color: #020202;
    font-size: 18px;
    font-family: $poppin;
    padding-bottom: 10px;

    &::before {
      position: absolute;
      content: "";
      background-color: #fcaf17;
      width: 0%;
      bottom: 0;
      left: 0;
      height: 2px;
      transition: all 0.3s ease;
    }

    img {
      padding-right: 10px;
    }
  }

  .desc {
    font-size: 13px;
    font-family: $roboto;
  }
}

// eng-hiring-model

.e-hiring-model {
  .e-hiring-model-head {
    text-align: center;
    padding-bottom: 30px;

    h2 {
      font-size: 36px;
      font-weight: 600;
      font-family: $poppin;
      margin-bottom: 20px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 14px;
      font-weight: 400;
      font-family: $roboto;
      width: 80%;
      margin: 0 auto;
      color: #939393;
    }
  }
}

.e-hiring-model {
  padding: 40px 0px 90px 0px;

  .e-hiring-model-head {
    text-align: center;

    text-align: center;
    padding-bottom: 30px;

    h2 {
      font-size: 32px;

      font-weight: 600;

      font-family: $poppin;

      margin-bottom: 20px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 14px;

      font-weight: 400;

      font-family: $roboto;

      width: 80%;

      margin: 0 auto;

      color: #939393;
    }
  }
}

.e-hiring-model-inner-list {
  display: flex;
  align-items: center;

  .inner-icon-content {
    margin-left: 20px;
  }
}

.inner-icon-content p {
  font-size: 13px;

  line-height: 20px;

  font-family: $roboto;
}

.inner-icon-content h5 {
  font-size: 16px;

  margin-bottom: 0px;

  font-family: $poppin;

  color: #fcaf17;
}

.inner-icon-img {
  text-align: center;

  img {
    width: 50px;
    height: 50px;
  }
}

.e-hiring-main-box {
  position: relative;
}

.e-hiring-model-list {
  &:nth-child(1) {
    content: "";
    position: absolute;
    left: 30%;
    top: 0%;
  }

  &:nth-child(2) {
    content: "";
    position: absolute;
    left: 43%;
    top: 19%;
  }

  &:nth-child(3) {
    content: "";
    position: absolute;
    left: 48%;
    top: 35%;
  }

  &:nth-child(4) {
    content: "";
    position: absolute;
    left: 48%;
    top: 55%;
  }

  &:nth-child(5) {
    content: "";
    position: absolute;
    left: 43%;
    bottom: 10%;
  }

  &:nth-child(6) {
    content: "";
    position: absolute;
    left: 28%;
    bottom: -7%;
  }
}

// discuss-specialists

.discuss-specialists {
  position: relative;

  &::before {
    content: "";
    background-color: rgba(38, 38, 38, 0.95);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    top: 0;
  }
}

.discuss-specialists-content {
  z-index: 10;
  margin: 0 auto;
  padding: 25px 0px;
  text-align: center;

  .title {
    color: white;
    font-size: 28px;
    text-align: center;
    font-family: $poppin;
  }

  .desc {
    font-size: 16px;
    color: white;
    text-align: center;
    margin: 30px 0px;
  }

  .lets-talk-button {
    display: inline-block;
    background-color: #fcaf17;
    color: white;
    padding: 12px 45px;
    border-radius: 5px;
    font-weight: 500;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: $roboto;
    border: 1px solid transparent;

    &:hover {
      background-color: transparent;
      color: white !important;
      border-color: white;
    }
  }
}

// global-recognition-area

.global-recognition-head {
  text-align: center;
  margin: 0 auto;

  h2 {
    font-size: 36px;
    font-weight: 600;
    font-family: $poppin;
    margin-bottom: 20px;

    span {
      color: #fcaf17;
    }
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    font-family: $roboto;
    width: 80%;
    margin: 0 auto;
    color: #939393;
  }
}

.border-bot {
  border-bottom: 3px solid white !important;
}

.menu-item {
  display: inline-block;
  background-color: #fff;
  position: relative;
}

.menu-item a {
  text-decoration: none;
  color: #000;
  display: block;
}

.drop-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.drop-menu-item {
  width: 100%;
}

.drop-menu-item:hover {
  background-color: #eee;
}

.drop-menu-item a {
  color: #555;
  font-size: 14px;
  padding: 0px;
}

.menu-item:hover .drop-menu {
  display: block;
}

.travel-app-header .navbar {
  background-color: #eeee;
  color: #000;
}

// travel-about

.about-heading .head {
  font-size: 40px;
  text-transform: uppercase;
  color: #020202;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
  margin-bottom: 25px;
}

.about-heading .desc {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
}

.about-heading .head span {
  color: rgb(252 175 23);
}

.about-content .title {
  font-size: 45px;
  color: #020202;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.about-content .sub-heading {
  position: relative;
  color: rgb(252 175 23);
  font-size: 16px;
  font-weight: 600;
}

.about-content .sub-heading::before {
  content: "";
  position: absolute;
  background-color: rgb(252 175 23);
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
}

.about-content .main-heading span {
  color: rgb(252 175 23);
}

.features-head h2 span {
  color: rgb(252 175 23);
}

.features-head .desc {
  font-size: 16px;
  font-family: $roboto;
  line-height: 20px;
  margin: 0 auto;
  text-align: center;
  // font-weight: 300!important;
}

.nav-tabs .nav-item button {
  background-color: #e8ebf1;
  border-radius: 50px;
  color: #020202;
  padding: 10px 42px;
  margin: 0px 20px;
  font-weight: 600;
}

.nav-tabs .nav-item button:hover {
  background-color: rgb(252 175 23);
  color: #fff;
}

.nav-tabs {
  border-color: transparent !important;
}

.nav-tabs .nav-link.active {
  border-color: transparent;
  background-color: rgb(252 175 23);
  color: #fff;
}

.features-card {
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
  background-color: white;
  padding: 32px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s linear;
  min-height: 225px;
  margin-bottom: 25px;
}

.features-img {
  text-align: center;
}

.features-img img {
  width: 55px;
  height: 55px;
  margin-bottom: 15px;
  text-align: center;
}

.features-img svg {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  text-align: center;
  color: rgb(252 175 23);
}

.features-card h5 {
  color: #020202;
  font-weight: 400;
  padding: 10px;
  margin-top: 10px;
}

.features-card:hover img {
  transform: rotateY(360deg);
  transition: all 0.7s linear;
}

.how-it-works-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.how-it-works-img img {
  width: 75%;
}

.works-list {
  display: flex;
  align-items: center;
  min-height: 130px;
}

.works-list-1 {
  display: flex;
  align-items: center;
  min-height: 130px;
}

.works-list li {
  margin-right: 5px;
  margin-bottom: 10px;
}

.works-list-1 li {
  margin-left: 15px;
}

.circle-no {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-color: #020202;
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  color: white;
}

.works-list-1 .circle-no {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-color: rgb(252 175 23);
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  color: white;
}

.works-list li .inner-works-list h5 {
  font-size: 20px;
  color: #020202;
  font-family: $poppin;
}

.works-list-1 li .inner-works-list h5 {
  font-size: 20px;
  color: #020202;
  font-family: $poppin;
}

.works-list .inner-works-list li .desc {
  font-size: 14px;
}

.works-list-1 .inner-works-list li .desc {
  font-size: 14px;
}

.how-it-works-main {
  display: flex;
  align-items: center;
}

// portfolio-area

.portfolio-area {
  background-color: #f5e9ce;
  width: 100%;
  position: relative;
}

.portfolio-left-area {
  background-color: rgb(215 164 64);
  width: 40.6%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 85px;
}

.portfolio-left-area h4 {
  color: white;
  font-size: 40px;
  font-style: italic;
  font-weight: 500;
  font-family: $poppin;
}

.portfolio-left-area .head-part {
  font-size: 75px;
  text-align: center;
  color: white;
  font-weight: 700;
  background: -webkit-linear-gradient(#eee, #333);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}

.sub-heading-area {
  margin-bottom: 30px;
}

.main-heading-area {
  position: absolute;
  top: 40%;
  left: 10%;
}

.main-heading-area h1 {
  font-size: 75px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.main-heading-area .sub-head {
  font-size: 75px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
}

.quote {
  position: absolute;
  bottom: 15%;
  left: 12%;
  color: white;
  font-size: 20px;
  font-family: $roboto;
}

.connect {
  position: absolute;
  top: 33%;
  left: 10%;
  right: 5%;
}

.connect .desc {
  color: white;
  font-size: 24px;
  font-family: $roboto;
}

.faq-accordion .accordion-button:not(.collapsed) {
  color: #020202 !important;
  background-color: rgb(247 247 247) !important;
  box-shadow: none !important;
}

.faq-accordion .accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: none !important;
}

.faq-accordion .accordion-button {
  color: #020202 !important;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 16px;
}

.faq-accordion .accordion-header {
  position: relative;
}

.faq-accordion .accordion-header svg {
  content: "";
  position: absolute;
  right: 2%;
  top: 35%;
}

.minus-icon {
  display: none;
}

.faq-accordion .accordion-item {
  border: transparent !important;
}

.faq-accordion .accordion-item .accordion-header {
  background-color: #ffffff !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 20px;
}

.faq-accordion .accordion-button:not(.collapsed) {
  background-color: white !important;
}

.faq-accordion .accordion-button::after {
  background-image: none !important;
}

.faq-accordion .accordion-button {
  background-image: none !important;
}

.faq-accordion .accordion-body {
  background-color: $theme-yellow;
  color: white;
  margin-bottom: 15px;
  font-weight: 600;
}

.faq-accordion .accordion-button {
  border-left: 5px solid $theme-yellow !important;
}

.faq-accordion .accordion-button:not(.collapsed) .minus-icon {
  display: block;
}

.faq-accordion .accordion-button:not(.collapsed) .plus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .minus-icon {
  display: none;
}

.faq-accordion .accordion-button.collapsed .plus-icon {
  display: block;
}

.padding-0 {
  padding: 0px !important;
}

.banner-sec {
  position: relative;
  z-index: 1;
}

.banner-sec::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
}

.travel-banner-content {
  position: absolute;
  top: 45%;
  left: 5%;
  z-index: 1;
}

.travel-banner-content h2 {
  color: white;
  font-size: 34px;
  font-weight: 600;
  font-family: $roboto;
  margin-bottom: 0px;
}

.req-demo {
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 600;
}

.req-demo:hover {
  background-color: rgb(252 175 23);
  border-color: transparent;
  color: white !important;
}

.travel-choose-card {
  position: relative;
  padding: 25px 15px;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  margin-bottom: 25px;
  min-height: 176px;
}

.travel-choose-card .heading-part {
  position: absolute;
  top: -10px;
  background-color: white;
  left: 30px;
}

.travel-choose-card .heading-part h4 {
  color: #020202;
  font-size: 18px;
  font-family: $poppin;
  background: white;
}

.travel-choose-card .choose-content .desc {
  font-size: 14px;
  font-family: $roboto;
  line-height: 24px;
}

.explore-more-button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  padding: 15px 42px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
}

.explore-more-button:hover {
  background-color: #fff2d9;
  border-color: transparent;
}

.py-40 {
  padding: 40px 0px;
}

.bg_contact_pages {
  background-color: #f8fafe;

  .contact_left {
    h4 {
      font-size: 25px;
      color: #3d3d3d;
      font-weight: 700;
    }

    p {
      margin-bottom: 40px;
      font-size: 16px;
    }
  }

  .contet-list {
    position: relative;

    .list-blk {
      padding-bottom: 0px;
      margin-bottom: 15px;

      h4 {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.17px;
        margin-bottom: 10px;
        position: relative;

        &::before {
          content: "";
          width: 18px;
          height: 18px;
          border: 3px solid rgb(255, 255, 255);
          background: #fcaf17;
          position: absolute;
          left: -34px;
          top: 2px;
          border-radius: 50%;
          z-index: 1;
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 2px;
        background: #fcaf17;
        left: -26px;
        top: 6px;
        height: 100%;
      }

      &:last-child {
        padding-bottom: 0;
        height: unset;
      }
    }
  }

  .contact_heading {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 70px;
  }

  .contact_formquery h6 {
    padding-bottom: 15px;
  }

  .bg_contact_pages {
    max-width: calc(50vw + 282px);
    flex: 1 1 999px;
    width: 100%;
    position: relative;
    padding: 80px 120px 103px 140px;
    background: rgb(243, 245, 255);
  }

  .contact-form-part {
    position: relative;
    padding: 80px 120px 103px 140px;
    background: rgb(243, 245, 255);
    border-radius: 10px;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(225, 229, 254);
      top: -20px;
      right: -20px;
      z-index: -1;
    }
  }
}

.inputcontrol {
  .form-control.frmcrto {
    height: 55px;
    border-color: rgba(0, 0, 0, 0.2);
    box-shadow: none !important;
  }

  .form-control {
    box-shadow: none !important;
    border-color: rgba(0, 0, 0, 0.2);
  }
}

.btn.btn-yellow {
  background-color: #fcaf17;
  padding: 9px 29px;
  color: #fff;
  border-radius: 3px;
  font-size: 19px;
  transition: all 0.5s;

  &:hover {
    background-color: #000;
    transition: all 0.5s;
  }
}

.befoer_borer {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 31px;
    left: 29px;
    background-color: #fff;
    // border: 2px solid #d69689;
    width: 100%;
    height: 100%;
    border-radius: 43px;
  }
}

.our-clients {
  overflow: hidden;
  width: 100%;
}

.mobile_accroid {
  .accordion {
    border-radius: 0;
  }

  .accordion-button.collapsed {
    padding: 12px 10px !important;
    border-radius: 0 !important;
    color: #989898;
  }

  .accordion-item {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0 !important;
  }

  .accordion-button:focus {
    box-shadow: none;
    background-color: #fff !important;
    color: #000;
  }

  .accordion-button {
    padding: 10px;
    background-color: #fff !important;
    color: #000;
  }

  .accordion-body {
    padding: 1px 0;

    li {
      border-bottom: 1px solid #ccc;

      a {
        padding: 5px 10px;
        display: flex;
        gap: 10px;
        border-bottom: none;

        span {
          font-size: 14px;
        }
      }

      .iconimg {
        width: 20px;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.6s;
}

.one1 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.8s;
}

.one2 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.9s;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.two {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 0.5s;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.four {
  -webkit-animation-delay: 9s;
  -moz-animation-delay: 9s;
  animation-delay: 0.9s;
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.appdevelopemnt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 550px;

  .leftapp {
    flex: 1.2;
  }

  .righttapp {
    flex: 1;
  }

  &::before {
    background-image: url("../images/topbg_img19.webp");
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    width: 35%;
    height: 100%;
    right: 0;
    z-index: -1;
    top: 0;
    background-position: center;
    background-size: cover;
  }

  .contindevelopment {
    padding-left: 10pc;

    h2 {
      font-size: 40px;
      color: #000;
      font-weight: 600;
      word-spacing: 6px;

      span {
        color: $yellow;
      }
    }

    p {
      font-size: 20px;
      color: #000;
      font-weight: 500;
      margin: 15px 0;
    }
  }

  .topimghand {
    position: absolute;
    bottom: 0;

    img {
      width: 75%;
    }
  }
}

.btnall {
  .btn-fix {
    background: transparent linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%) 0%
      0% no-repeat padding-box;
    color: #fff;
    border-radius: 50px;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.06);
    font-size: 22px;
    font-weight: 500;
    position: relative;
    white-space: nowrap;
    transition: 0.8s;
    height: 55px;
    padding: 5px 60px 5px 25px;
    width: fit-content;
    cursor: pointer;

    &:hover {
      background: #fff;
      padding: 5px 25px 5px 60px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    svg {
      background: #fff;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      padding: 10px;
      position: absolute;
      transition: 0.5s;
      right: 10px;
      color: #000;
    }

    &:hover svg {
      background: transparent linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%)
        0% 0% no-repeat padding-box;
      right: calc(100% - 50px);
    }
  }
}

.fluidpadding10 .container-fluid {
  padding: 0px 10pc;
}

.developmentsecib {
  .cardapp {
    padding: 40px 30px;
    margin-bottom: 20px;
    border-radius: 20px;

    h2 {
      color: #b4990d;
      opacity: 0.4;
      font-size: 45px;
    }

    h3 {
      font-size: 23px;
      line-height: normal;
      font-weight: 600;
      color: #000;
    }

    p {
      color: #000;
      line-height: 32px;
      margin-bottom: 25px;
    }

    img {
      width: 71%;
    }
  }

  .lightyeelow1 {
    background-color: #ef9d1042;
  }

  .lightyeelow2 {
    background-color: #c4a35a57;
    min-height: 435px;
  }

  .lightyeelow3 {
    background-color: #f4eacc;
  }

  .lightyeelow4 {
    background-color: #ef9d1042;
  }

  .lightyeelow5 {
    background-color: #ecc19cb0;
    min-height: 97%;
  }
}

.fntch_fnl_inn {
  // background-color: #f5cb78 linear-gradient(269deg, #fff1d6 0%, #f6c86e 40%, #ffc95f 100%) 0% 0% no-repeat padding-box;

  background-color: #45494c;
  // background-image: linear-gradient(315deg, #e4ac4ff0 0%, #ffdd004a 74%);
  border-radius: 20px;
  padding: 15px 30px 15px 30px;

  .develmntser {
    margin-top: 40px;

    h3 {
      font-size: 35px;
      font-weight: 600;

      color: white;
    }

    .btnall {
      margin-top: 5pc;

      .btn-fix {
        background: #fff;
        color: #000;

        svg {
          background: transparent
            linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%) 0% 0% no-repeat
            padding-box;
        }
      }
    }
  }

  .image {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
}

.fntch_end_flx {
  li {
    border: 1px solid #d1d1d1;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 15px;

    h3 {
      font-size: 23px;
      font-weight: 600;
    }

    p {
      font-size: 15px;
      color: #000;
    }
  }
}

.freaturesectionapp {
  .imgae_dev {
    text-align: center;
  }

  img {
    width: 70%;
    margin-top: 15%;
  }

  li {
    padding: 18px;
    margin-bottom: 30px;
    border-radius: 20px;
    border: 1px solid #d1d1d1;
    transition: all 0.5s;

    .fe_hd {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;

      svg {
        font-size: 35px;
        color: #fcaf17;
      }

      h2 {
        font-size: 20px;
        margin-bottom: 0;
        font-weight: 600;
      }

      p {
        color: #000;
      }
    }

    &:hover {
      background: #fcaf17;

      svg,
      h2,
      p {
        color: #fff;
        transition: all 0.5s;
      }
    }
  }
}

.premium_section {
  .list_feature {
    li {
      padding: 25px;
      margin-bottom: 30px;
      border-radius: 20px;
      border: 1px solid #d1d1d1;
      transition: all 0.5s;
      min-height: 200px;

      .fe_hd {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 15px;

        svg {
          font-size: 35px;
          color: #fcaf17;
        }

        h2 {
          font-size: 20px;
          margin-bottom: 0;
          font-weight: 600;
          margin-left: 15px;
        }
      }

      &:hover {
        background: #fcaf17;

        svg,
        h2,
        p {
          color: #fff;
          transition: all 0.5s;
        }
      }

      p {
        color: #000;
        font-size: 16px;
      }
    }
  }
}

.Securitysectoin {
  background: #f5fbff url("../images/zd_curve_line.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  padding: 50px 0;

  .premium_card {
    margin: 0 auto;
    background: #fff;
    box-shadow: 0px 3px 13px rgba(195, 213, 220, 0.16);
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    padding: 30px;
    min-height: 280px;
  }
}

.shapeverticaldots {
  .shapimg {
    position: absolute;
    top: 0;
    left: 10%;
    width: 8%;
  }

  h3 {
    font-size: 36px !important;
    font-weight: 600 !important;
    list-style: 40px !important;
  }

  .centerflex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .develmntser {
    position: relative;
    padding-left: 20px;

    &::before {
      position: absolute;
      content: "";
      top: 10px;
      height: 100%;
      width: 4px;
      background-color: #fff;
      left: 0;
    }
  }

  .btnall .btn-fix {
    background: #fff;
    color: #000;
  }
}

.discovery_section {
  // background-color: #fcfcfc;
  background-image: url("../images/backimg2.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  padding: 30px 0 30px 0;
  //   .list_main_discover{
  //     li{
  //       border: 2px solid #122840;
  //       border-radius: 10px;
  //       background: #fff;
  //       padding: 25px 20px;
  //       text-align: center;
  //       margin-bottom: 20px;
  //       font-size: 25px;
  //       color: #000;
  //       &:hover{
  //         background-color: #fcaf17;
  //         color: #fff;
  //         border-color: #fcaf17;
  //         cursor: pointer;
  //       }
  //     }
  //     li.active{
  //       background: #fcaf17;
  //       color: #fff;
  //       border-color: #fcaf17;
  //     }
  //   }
  //   .list_discovred {
  //     background-color: black;
  //     padding: 52px 25px;
  //     border-radius: 10px;
  //     min-height: 518px;

  //     h3{
  //       color: #fff;
  //       font-size: 25px;
  //       margin: 20px 0;
  //     }
  //     p{
  //       color: #fff;
  //     }
  // }
  .content-card {
    position: relative;
    padding: 20px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 20px;
    min-height: 200px;

    .image {
      text-align: center;
      margin-bottom: 20px;

      img {
        width: 80px;
        height: 80px;
        animation: mover 1s infinite alternate;
      }
    }

    @keyframes mover {
      0% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(-10px);
      }
    }

    h4 {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }

    .content {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(94, 92, 87, 0.7098039216);
      text-align: center;
      z-index: 1;
      width: 100%;
      border-radius: 20px;
      height: 100%;
      padding: 10px;
      opacity: 0;
      visibility: hidden;

      p {
        color: white;
        font-size: 15px;
        opacity: 0;
        transform: translateY(0.625rem);
        transition: all 0.125s ease-in-out;
        line-height: 25px;
        font-weight: 500;
      }
    }
  }
}

.content-card:hover .content {
  opacity: 1;
  visibility: visible;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.content-card:hover .content p {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-in-out 0.1s;
}

.chosse_cardsection {
  .col-auto {
    width: 20%;
  }

  .chosse_card {
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 25px;
    // min-width: 150px;
    margin: 0 auto;
    min-height: 150px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);

    img {
      width: 50px;
      height: 50px;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      color: #000;
    }

    p {
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.ourPortfolio {
  .portfolio_card {
    background-color: #e2e7ff;
    padding: 40px 35px;
    border-radius: 20px;
    margin-bottom: 50px;
  }

  .portfolio_cardimg_content {
    h2 {
      font-size: 45px;
      font-weight: 700;
    }

    p {
      font-size: 20px;
      margin: 30px 0;
      color: #000;
      font-weight: 500;
    }
  }

  .otr_lnks_port {
    .btn-casestudy {
      border-radius: 10px;
      padding: 10px;
      border: 2px solid #000000;
      color: #000000;
      font-weight: 700;
      display: none;

      &:hover {
        background-color: #fcaf17;
        color: #fff !important;
        border-color: #fcaf17;
      }
    }

    .btn-group {
      img {
        margin-right: 10px;
        width: 145px;
      }
    }
  }

  .otr_lnks_port svg {
    font-size: 25px;
  }
}

// healthcare-page-css

.healthcare-banner-sec {
  position: relative;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }

  .healthcare-banner-content {
    position: absolute;
    top: 25%;
    left: 5%;
    z-index: 2;

    .main-heading {
      color: white;
      font-size: 30px;
      font-family: "Poppins", sans-serif;
      margin-bottom: 20px;
      font-weight: 600;
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;

      background-color: #fcaf17;
      border: 1px solid #fcaf17;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid white;
        background: transparent;
        color: white !important;
      }
    }
  }
}

.health-about-us {
  background-color: #f9f9f9;
}

.health-about-content {
  .main-heading {
    font-size: 31px;
    font-weight: 600;
    color: #020202;

    span {
      color: rgb(252 175 23);
    }
  }

  .desc {
    font-size: 16px;
    font-family: $roboto;
  }

  .h-about-button {
    margin-top: 20px;

    .read-more-button {
      background-color: rgb(252, 175, 23);
      border: 1px solid rgb(252, 175, 23);
      border-radius: 5px;
      padding: 8px 25px;
      color: #ffffff;
      font-size: 14px;

      &:hover {
        color: white !important;
      }
    }
  }
}

.health-app-development-sec {
  background-color: #f9f9f9 !important;
}

.health-app-develop-content {
  .sub-heading {
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
  }

  .main-heading {
    font-size: 32px;
    font-family: $poppin;

    span {
      color: #fcaf17;
    }
  }

  .desc {
    font-size: 17px;
    font-family: $roboto;
  }
}

.health-app-development-card {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 25px;
  min-height: 394px;
}

.health-app-development-icon {
  margin-bottom: 15px;

  img {
    width: 50px;
    height: 50px;
  }
}

.health-app-development-content {
  padding: 10px;

  .desc {
    margin-bottom: 10px;
  }
}

.health-app-development-list li {
  position: relative;
  font-size: 16px;
  padding-left: 20px;
  font-family: $roboto;
  color: #51555c;
  font-weight: 600;
}

.health-app-development-list li::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #fcaf17;
  top: 5px;
  left: 0;
  transform: translate(0px, 4px);
}

.growth-banner {
  background-color: #020202;
  position: relative;
}

.growth-banner-content {
  position: absolute;
  top: 35%;
  width: 38%;

  .main-heading {
    font-size: 32px;
    color: white;
    margin-bottom: 35px;
    font-family: "Poppins", sans-serif;
    padding: 10px 0px;
    line-height: 45px;
    font-weight: 500;

    span {
      color: #fcaf17;
    }
  }

  .free-consult-button {
    background-color: transparent;
    color: #fcaf17;
    border: 2px solid #fcaf17;
    border-radius: 5px;
    padding: 14px 40px;
    font-weight: 600;

    &:hover {
      background-color: #fcaf17;
      color: white !important;
    }
  }
}

.healthcare-based-solutions-img img {
  padding: 20px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.healthcare-based-solutions-list-box {
  border: 1px solid #e5e5e5;
  padding: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 500px;
}

.health-based-solutions-list {
  li {
    line-height: 50px;
    font-size: 18px;

    img {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }
  }
}

.choose-us-because {
  background-color: #f9f9f9;
}

.health-choose-us-card {
  border: 1px solid rgba(212, 224, 237, 0.7);
  border: 1px solid #e5e5e5;
  padding: 25px;
  margin-bottom: 25px;
  min-height: 312px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: white;
    // border-color: #fcaf17;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .health-choose-us-content {
    .title {
      color: #020202;
      font-family: $poppin;
      font-size: 18px;
    }

    .desc {
      font-size: 15px;
      line-height: 25px;
      font-family: $roboto;
    }
  }

  .icon {
    margin-bottom: 20px;
  }
}

.health-choose-us-main-box {
  .free-consult-button {
    background-color: transparent;
    color: #fcaf17;
    border: 2px solid #fcaf17;
    border-radius: 5px;
    padding: 14px 40px;
    font-weight: 600;

    &:hover {
      background-color: #fcaf17;
      color: white !important;
    }
  }
}

.hexagon-main-img {
  position: relative;

  .growth-banner-img-content {
    position: absolute;
    top: 27%;
    left: 15%;
    text-align: center;
    width: 60%;

    img {
      width: 50px;
      height: 50px;
    }

    .desc {
      font-size: 14px;
      color: white;
      line-height: 25px;
      margin-top: 10px;
    }
  }
}

.healthcare-client-testimonials {
  background-color: #f9f9f9;
}

// erp css

.erp-banner-sec {
  position: relative;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }

  .erp-banner-content {
    position: absolute;
    top: 22%;
    left: 5%;
    z-index: 2;

    h1 {
      color: white;
      font-size: 34px;
      margin-bottom: 10px;
      font-weight: 600;
    }

    p {
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      margin-bottom: 15px;
      color: white;
    }

    .blue-theme-button {
      background-color: #fcaf17;
      color: white;
      padding: 10px 30px;
      font-family: $roboto;
      border: 2px solid #fcaf17;
      transition: all 0.3s ease-in-out;
      font-weight: 600;
      display: inline-block;
      border-radius: 5px;

      &:hover {
        color: white !important;
        transform: translateY(-10px);
      }
    }

    .erp-banner-content-list {
      margin-bottom: 20px;

      .erp-banner-list {
        li {
          font-size: 14px;
          font-family: $roboto;
          color: white;
          font-weight: 600;
          position: relative;
          padding-left: 20px;
          line-height: 30px;

          &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #fcaf17;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            transform: translate(0px, 10px);
          }
        }
      }
    }
  }
}

.erp-development-services-head {
  text-align: center;

  .main-heading-type {
    color: #020202;
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 20px;

    span {
      color: rgb(252, 175, 23);
    }
  }

  .sub-heading {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-family: $roboto;

    span {
      color: rgb(252, 175, 23);
    }
  }
}

.erp-development-services-card {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #ededed;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  margin-bottom: 40px;
  min-height: 220px;
  position: relative;

  .erp-development-services-content {
    padding-top: 20px;
    text-align: center;
  }

  .erp-development-services-icon {
    background-color: white;
    border: 1px solid #fcaf17;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    margin-bottom: 15px;
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    margin: auto;

    img {
      width: 35px;
      height: 35px;
    }
  }

  &::after {
    position: absolute;
    content: "";
    background-color: #fcaf17;
    height: 2px;
    width: 0%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    background-color: #fbfbfb;
    transform: translateY(-10px);

    &::after {
      width: 100%;
    }

    h4 {
      color: #fcaf17;
    }
  }

  h4 {
    font-size: 18px;
    font-family: $poppin;
  }

  .desc {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}

.why-choose-erp-card {
  position: relative;
  border-right: 1px solid #fcaf17;
  padding: 20px;
}

.d-f-a-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-bottom-m {
  border-bottom: 1px solid #fcaf17;
}

.border-right-0 {
  border-right: 0px;
}

.why-choose-erp-icon {
  margin-bottom: 15px;

  img {
    width: 45px;
    height: 45px;
  }
}

.why-choose-erp-content {
  h4 {
    font-size: 16px;
  }

  .desc {
    font-size: 13px;
    line-height: 20px;
  }
}

.software-development-item {
  position: relative;
  padding: 50px 0px;

  span {
    position: absolute;
    top: 35px;
    content: "";
    font-size: 80px;
    color: rgb(110 110 110 / 11%);
    font-weight: 800;
  }

  .software-development-content {
    margin-top: 20px;

    .desc {
      font-size: 20px;
      font-family: $roboto;
    }
  }
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 10px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.erp-achievements-sec {
  .erp-achievements-head {
    h2 {
      color: white;
      font-size: 38px;
      text-align: center;
      font-family: $poppin;
    }
  }

  .erp-achievements-card {
    text-align: center;
    margin-bottom: 15px;

    span {
      color: white;
      font-size: 50px;
      font-weight: 700;
    }

    h4 {
      color: white;
      font-size: 16px;
      font-family: $poppin;
      margin-top: 15px;
    }
  }

  p {
    text-align: center;
    margin-top: 25px;

    .let-discuss-button {
      background-color: white;
      color: #020202;
      padding: 12px 32px;
      font-weight: 600;
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #fcaf17;
        color: white !important;
      }
    }
  }
}

.erp-developed-modules-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  min-height: 170px;
  margin-bottom: 20px;

  &:hover {
    box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .erp-developed-modules-content {
    h4 {
      font-size: 18px;
    }

    .desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.explore-erp-services-content {
  padding-top: 50px;

  .desc {
    margin-bottom: 20px;
    width: 80%;
    font-size: 15px;
  }
}

.explore-erp-development-list {
  li {
    margin-bottom: 15px;
    font-size: 13px;
    font-family: $roboto;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}

.erp-developed-modules {
  background-color: #fff9ee;
}

.erp-about-us {
  background-color: #f9f9f9;

  .erp-about-img {
    margin-bottom: 25px;
  }

  .erp-about-content {
    .main-heading {
      font-size: 28px;
      font-weight: 600;
      color: #020202;

      span {
        color: rgb(252 175 23);
      }
    }

    .desc {
      font-size: 16px;
      font-family: $roboto;

      span {
        color: rgb(252 175 23);
      }
    }

    .h-about-button {
      margin-top: 20px;

      .read-more-button {
        background-color: rgb(252, 175, 23);
        border: 1px solid rgb(252, 175, 23);
        border-radius: 5px;
        padding: 10px 30px;
        color: #ffffff;
        display: inline-block;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: white !important;
          transform: translateY(-10px);
        }
      }
    }
  }
}

// crm css by mukul

.crm-banner-sec {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }

  .Crm-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 40%;
    left: 7%;

    .main-heading {
      color: white;
      font-size: 30px;
      margin-bottom: 20px;
      font-weight: 500;

      span {
        color: #fcaf17;
      }
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;

      background-color: #fcaf17;
      border: 1px solid #fcaf17;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid white;
        background: transparent;
        color: white !important;
      }
    }
  }
}

.Crm-about-us {
  // background-color: #f9f9f9;
}

.about-img {
  margin-right: 20px;

  img {
    min-height: 420px;
    margin-top: 10px;
  }
}

.Crm-about-content {
  margin-top: 20px;

  .main-heading {
    font-size: 26px;
    font-weight: 600;
    color: #020202;

    span {
      color: rgb(252 175 23);
    }
  }

  .desc {
    font-size: 14px;
    font-family: $roboto;
  }

  .h-about-button {
    margin-top: 20px;

    .read-more-button {
      background-color: rgb(252, 175, 23);
      border: 1px solid rgb(252, 175, 23);
      border-radius: 5px;
      padding: 8px 25px;
      color: #ffffff;
      font-size: 14px;

      &:hover {
        color: white !important;
      }
    }
  }
}

.why-you-need-crm {
  // background-color: #f4f4f4;

  .Crm-app-development-content {
    text-align: center;

    .main-heading {
      font-size: 34px;
      position: relative;

      span {
        color: rgb(252 175 23);
      }
    }

    .desc {
      color: black;
      font-size: 16px;
      width: 80%;
      margin: 0 auto;
    }
  }
}

.why-you-need-card {
  position: relative;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  // border: 2px solid transparent;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #fcaf17;
  min-height: 320px;
  // &:hover {

  // }

  .why-you-need-icon {
    background-color: white;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    line-height: 65px;
    text-align: center;
    margin-bottom: 20px;
    border: 2px solid #fcaf17;
  }

  &:hover .why-you-need-icon {
    border: 2px solid #e5e5e5;
  }

  .why-you-need-content {
    h4 {
      font-size: 18px;
      font-weight: 600;
    }

    .desc {
      color: #020202;
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
    }
  }
}

.crm-software-features {
  .crm-software-features-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      position: relative;
      padding-bottom: 15px;

      span {
        color: rgb(252 175 23);
      }
    }

    .desc {
      color: black;
      font-size: 15px;
      width: 90%;
      margin: 0 auto;
    }
  }

  .crm-software-features-card {
    position: relative;
    padding: 20px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 20px;
    min-height: 200px;

    &:hover .crm-software-features-content {
      opacity: 1;
      visibility: visible;
      transition: all 0.15s ease-in-out;
      cursor: pointer;
    }

    &:hover .crm-software-features-content .desc {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.3s ease-in-out 0.1s;
    }

    .title {
      text-align: center;
      font-size: 17px;
      font-weight: 600;
    }

    .crm-software-features-icon {
      text-align: center;
      margin-bottom: 20px;

      img {
        width: 80px;
        height: 80px;
      }
    }
  }

  .crm-software-features-content {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5e5c57;
    text-align: center;
    z-index: 1;
    width: 100%;
    border-radius: 20px;
    height: 100%;
    padding: 10px;
    opacity: 0;
    visibility: hidden;

    .desc {
      color: white;
      font-size: 14px;
      font-family: $roboto;
      opacity: 0;
      transform: translateY(0.625rem);
      transition: all 0.125s ease-in-out;
      line-height: 25px;
    }
  }
}

.crm-development-services-sec {
  background-color: #f4f4f4;

  .col-xl-6:nth-child(2) {
    margin-top: 80px;
  }

  .col-xl-6:nth-child(3) {
    margin-top: -80px;
  }
}

.crm-development-services-head {
  .main-heading {
    color: #020202;
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 20px;

    span {
      color: #fcaf17;
    }
  }

  .desc {
    font-size: 15px;
    margin-bottom: 30px;
  }
}

.crm-development-services-card {
  background: #fff;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
  padding: 15px;
  position: relative;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 25px;
  min-height: 355px;

  &::before {
    content: "";
    position: absolute;
    -webkit-transform: translateY(102%);
    transform: translateY(102%);
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fcaf17;
    -webkit-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    z-index: -1;
  }

  &:hover::before {
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
  }

  .crm-development-services-icon img {
    width: 90px;
    height: 90px;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  &:hover .crm-development-services-icon .img {
    display: none;
  }

  &:hover .crm-development-services-icon .imgw {
    display: block;
  }

  .crm-development-services-icon .imgw {
    display: none;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .crm-development-services-content {
    padding-top: 10px;

    h4 {
      font-size: 19px;
      font-weight: 600;
    }

    .desc {
      font-size: 13px;
      line-height: 20px;
    }
  }

  &:hover .crm-development-services-content {
    color: white;
  }

  &:hover .crm-development-services-content h4 {
    color: white;
  }
}

.crm-software-solutions-sec {
  .crm-development-services-head {
    text-align: center;

    .main-heading {
      font-size: 34px;

      span {
        color: rgb(252 175 23);
      }
    }

    .desc {
      color: black;
      width: 85%;
      margin: 0 auto;
      font-size: 15px;
    }
  }
}

.industries-we-cater {
  background-color: #f4f4f4;

  .industries-cater-head {
    h2 {
      text-align: center;
      font-size: 34px;
      color: #fcaf17;
      font-weight: 600;
    }
  }

  .industries-cater-card {
    text-align: center;

    .industries-cater-icon {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      text-align: center;
      line-height: 75px;
      margin-bottom: 15px !important;
      margin: 0 auto;

      img {
        width: 45px;
        height: 45px;
      }
    }

    .industries-cater-content {
      h4 {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

.why-work-with-us-content {
  h3 {
    font-size: 30px;
  }
}

// gallerystyle2

.container-box {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 10vmin;
  overflow: hidden;
  // transform: skew(5deg);

  .card {
    flex: 1;
    transition: all 1s ease-in-out;
    height: 55vmin;
    position: relative;

    .desc {
      position: absolute;
      top: 60%;
      color: white;
      background: #fcaf17;
      margin: 0 auto;
      padding: 10px;
      font-size: 14px;
      left: 0;
      right: 0;
      width: 50%;
      border-radius: 10px;
      opacity: 0;
      visibility: hidden;
    }

    .card__head {
      color: black;
      background: #fcaf17;
      padding: 0.5em;
      transform: rotate(-90deg);
      transform-origin: 0% 0%;
      transition: all 0.5s ease-in-out;
      min-width: 100%;
      text-align: center;
      position: absolute;
      bottom: 10%;
      left: 0;
      font-size: 1em;
      white-space: nowrap;
      font-weight: 700;
    }

    &:hover {
      flex-grow: 10;

      img {
        filter: grayscale(0);
      }

      .card__head {
        text-align: center;
        top: calc(100% - 2em);
        color: white;
        background: rgba(0, 0, 0, 0.5);
        font-size: 24px;
        transform: rotate(0deg) skew(-5deg);
        height: 100%;
        font-weight: 600;
      }

      .desc {
        opacity: 1;
        visibility: visible;
        transition: 0.2s;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 1s ease-in-out;
      filter: grayscale(100%);
    }

    &:not(:nth-child(5)) {
      margin-right: 1em;
    }
  }
}

.fooerdmc {
  display: flex;

  position: absolute;

  bottom: 10px;

  left: 20px;

  gap: 1px;

  z-index: 2;
}

.fooerdmc .btngreen {
  background-color: #35bbcb;

  padding: 5px;

  font-size: 12px;

  color: #fff !important;

  font-weight: 600;
}

.fooerdmc .btnblack {
  background-color: #000;

  padding: 5px;

  font-size: 12px;

  color: #fff !important;

  font-weight: 600;
}

// crm-software-development

.crm-software-development-card {
  border: 1px solid #fcaf17;
  padding: 25px;
  border-radius: 10px;
  transition: 0.75s;
  background-color: #fff;
}

.custom-crm-development-head {
  .main-head {
    font-size: 34px;
    font-family: $poppin;
    font-weight: 600;
    text-align: center;

    span {
      color: #fcaf17;
    }
  }

  .desc {
    width: 80%;
    text-align: center;
    margin: 0 auto;
    font-size: 16px;
  }
}

.custom-crm-development-card-content {
  padding: 30px;

  .main-head {
    font-size: 26px;
    color: #fcaf17;
    margin-bottom: 15px;
    font-family: "Poppins", sans-serif;
  }

  .sub-heading {
    font-size: 18px;
    font-family: $roboto;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .desc {
    margin-bottom: 20px;
  }

  .btnall {
    margin-bottom: 30px;

    .btn-fix {
      background: transparent linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%)
        0% 0% no-repeat padding-box;
      color: #fff;
      border-radius: 50px;
      border: unset;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.06);
      font-size: 16px;
      font-weight: 500;
      position: relative;
      white-space: nowrap;
      transition: 0.8s;
      height: 50px;
      padding: 5px 60px 5px 25px;
      width: fit-content;
      cursor: pointer;

      &:hover {
        background: #fff;
        padding: 5px 25px 5px 60px;
      }

      svg {
        background: #fff;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        padding: 10px;
        position: absolute;
        transition: 0.5s;
        right: 10px;
        color: #000;
      }

      &:hover svg {
        background: transparent linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%)
          0% 0% no-repeat padding-box;
        right: calc(100% - 50px);
      }
    }
  }
}

// school management by mukul

.school-mt-banner-sec {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }

  .school-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 30%;
    left: 7%;

    .main-heading {
      color: white;
      font-size: 30px;
      margin-bottom: 20px;
      font-weight: 500;

      span {
        color: #fcaf17;
      }
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;

      background-color: #fcaf17;
      border: 1px solid #fcaf17;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid white;
        background: transparent;
        color: white !important;
      }
    }
  }
}

.Sch-mt-about-us {
  background-color: #f9f9f9;
}

.about-img {
  margin-right: 20px;

  img {
    min-height: 420px;
    margin-top: 10px;
  }
}

.Sch-mt-about-content {
  margin-top: 20px;

  .main-heading {
    font-size: 26px;
    font-weight: 600;
    color: #020202;

    span {
      color: rgb(252 175 23);
    }
  }

  .desc {
    font-size: 14px;
    font-family: $roboto;
  }

  .h-about-button {
    margin-top: 20px;

    .read-more-button {
      background-color: rgb(252, 175, 23);
      border: 1px solid rgb(252, 175, 23);
      border-radius: 5px;
      padding: 8px 25px;
      color: #ffffff;
      font-size: 14px;

      &:hover {
        color: white !important;
      }
    }
  }
}

// sch-software-services

.sch-software-services {
  background-color: #f9f9f9;
}

.sch-software-services-head {
  text-align: center;

  .main-heading {
    font-size: 32px;
    margin-bottom: 25px;

    span {
      color: rgb(252 175 23);
    }
  }

  .desc {
    color: black;
    font-size: 16px;
    width: 80%;
    margin: 0 auto;
  }
}

.sch-software-services-list {
  display: flex;
}

.sch-software-services-card {
  margin-bottom: 30px;

  .sch-software-services-icon {
    background-color: #fcaf17;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    margin-right: 20px;
    transition: all 0.5s linear;
    overflow: hidden;
    transform: rotateY(0deg);
    transition: all 0.5s linear;

    img {
      width: 65px;
      height: 65px;
    }
  }

  .sch-software-services-content {
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 95px 0px rgba(0, 0, 0, 0.09);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    h5 {
      font-size: 17px;
      font-weight: 600;
    }

    .desc {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &:hover .sch-software-services-icon {
    transform: rotateY(360deg);
    transition: all 0.5s linear;
  }
}

.sch-software-modules-head {
  // text-align: center;

  .main-heading {
    font-size: 32px;
    margin-bottom: 15px;

    span {
      color: rgb(252 175 23);
    }
  }

  .desc {
    font-size: 14px;
  }
}

.modules-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.sch-software-modules-card {
  box-shadow: rgba(13, 21, 55, 0.06) 0 5px 50px;
  -webkit-box-shadow: rgba(13, 21, 55, 0.06) 0 5px 50px;
  border-radius: 10px;
  transform: translateY(0px);
  transition: all 0.4s ease;
  min-height: 130px;
  margin-bottom: 20px;
  text-align: center;

  .sch-software-modules-icon {
    text-align: center;
    padding: 10px;

    img {
      width: 45px;
      height: 45px;
    }
  }

  .sch-software-modules-content {
    padding: 5px;

    h5 {
      font-size: 14px;
      font-weight: 600;
    }
  }

  &:hover {
    transform: translateY(-5px);
    cursor: pointer;
    -webkit-box-shadow: 0 20px 20px 0 rgba(2, 19, 79, 0.1);
    box-shadow: 0 20px 20px 0 rgba(2, 19, 79, 0.1);
  }
}

.m-h-424 {
  min-height: 424px !important;
}

.Sch-mt-features-content {
  border: 1px solid #e5e5e5;
  padding: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: auto;

  h4 {
    font-size: 20px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
}

.Sch-mt-features-img {
  img {
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  }
}

.Sch-mt-features-list {
  li {
    margin-bottom: 15px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}

.Sch-software-features {
  .main-box {
    margin: 20px 0px;
  }

  .Sch-software-features-head {
    // text-align: center;

    .main-heading {
      font-size: 32px;
      margin-bottom: 15px;

      span {
        color: rgb(252 175 23);
      }
    }

    .desc {
      font-size: 14px;
    }
  }
}

.sch-software-development-process {
  .sch-process-card {
    color: #000;
    background: linear-gradient(188.28deg, #ffffff 22.17%, #f6f6f7 116.15%);
    box-shadow: 0px 3px 6px rgba(0, 0, 128, 0.1);
    border-radius: 5px;
    padding: 10px;
  }

  .sch-software-development-process-head {
    .main-heading {
      font-size: 32px;
      margin-bottom: 15px;

      span {
        color: rgb(252 175 23);
      }
    }

    .desc {
      font-size: 14px;
    }
  }
}

// sch-process-tabs

.sch-software-process-tabs {
  position: relative;

  .tab-content {
    margin-top: 50px;
    background-color: rgb(255, 249, 238);
    padding: 2%;
    border-radius: 20px;

    .process-tab-contents {
      padding: 10%;
    }
  }

  .step-triangle {
    position: absolute;
    left: 5.2%;
    top: -12%;

    .step-triangle-list {
      display: flex;
      align-items: center;

      li {
        width: 185px;
        margin-left: 5px;

        .step-number {
          position: relative;
          width: 35px;
          height: 35px;
          border-radius: 50px;
          text-align: center;
          font-size: 16px;
          line-height: 35px;
          font-weight: 900;
          color: #f9ca6c;
          background: linear-gradient(
            123.73deg,
            #ffffff 13.95%,
            #f1f2f6 86.04%
          );
          box-shadow: 0px 6.4042px 25.6168px rgba(0, 0, 128, 0.15),
            3.06761px 3.06761px 4.38047px rgba(0, 0, 0, 0.15);
          transform-origin: bottom;
          display: inline-block;

          &::before {
            content: "";
            position: absolute;
            background-color: #fcaf17;
            top: -8px;
            left: -7px;
            width: 145%;
            height: 145%;
            z-index: -1;
            border-radius: 50px;
          }

          &::after {
            content: "";
            position: absolute;
            z-index: -1;
            bottom: -19px;
            left: 52%;
            width: 0%;
            height: 0%;
            transform: translate(-50%, -7%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid #fcaf17;
          }
        }
      }
    }
  }

  .nav-tabs {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    &::before {
      content: "";
      position: absolute;
      box-shadow: 0px 8px 12px rgba(136, 136, 209, 0.15),
        inset 0px 4px 15px rgba(133, 133, 255, 0.06);
      width: 100%;
      height: 17px;
      left: 0px;
      background-color: #fbfbfd;
      z-index: -1;
    }

    .nav-item {
      background-image: url("../images/School-Management//process/dots-bg.png");
      background-position-x: center;
      background-position-y: 20px;
      background-repeat: no-repeat;
      background-size: 3px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 5px;
        width: 10px;
        height: 10px;
        background-color: #fcaf17;
        left: 47%;
        border-radius: 50px;
      }

      &::after {
        content: "";
        width: 60px;
        height: 60px;
      }
    }

    .nav-link {
      color: #000;
      background: linear-gradient(188.28deg, #ffffff 22.17%, #f6f6f7 116.15%);
      box-shadow: 0px 3px 6px rgba(0, 0, 128, 0.1);
      border-radius: 20px;
      width: 150px;
      min-height: 90px;
      padding: 10px;
      font-size: 14px;
      margin: 50px 5px 0px 5px;
      line-height: 20px;
      font-weight: 600;
      transform: scale(1) translateY(0%);
      transition: all 0.5s ease;

      &:hover {
        color: black !important;
      }

      &.active {
        transform: scale(1.15) translateY(50%);
        transition: all 0.5s ease;
      }
    }
  }
}

.sch-software-solutions-advantages {
  .sch-software-solutions-advantages-head {
    .main-heading {
      font-size: 34px;
      margin-bottom: 15px;
      text-align: center;

      span {
        color: rgb(252 175 23);
      }
    }

    .desc {
      font-size: 14px;
    }
  }
}

.sch-software-advantages-img {
  img {
    border-radius: 5px;
    min-height: 402px;
  }
}

.sch-software-advantages-content {
  border: 1px solid #e5e5e5;
  padding: 20px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .sch-software-advantages-list {
    li {
      margin-bottom: 5px;
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
      line-height: 40px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}

.sch-software-development-process .faq-accordion {
  display: none;
}

.sch-process-r {
  display: block;
}

.sch-software-portfolio-head {
  text-align: center;

  .main-heading {
    font-size: 32px;
    margin-bottom: 15px;
    text-align: center;

    span {
      color: rgb(252, 175, 23);
    }
  }
}

// society management by mukul

.society-mt-banner-sec {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }

  .society-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 38%;
    width: 32%;
    left: 7%;

    .main-heading {
      color: white;
      font-size: 30px;
      margin-bottom: 20px;
      font-weight: 500;

      span {
        color: #fcaf17;
      }
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;

      background-color: #fcaf17;
      border: 1px solid #fcaf17;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid white;
        background: transparent;
        color: white !important;
      }
    }
  }
}

.society-mt-about-us {
  background-color: #f9f9f9;

  .society-about-img {
    margin-right: 20px;

    img {
      margin-top: 15px;
    }
  }

  .society-mt-about-content {
    margin-top: 10px;

    .sub-heading {
      font-size: 16px;
      color: #020202;
      font-weight: 600;
      margin-top: 5px;
    }

    .main-heading {
      font-size: 26px;
      font-weight: 600;
      color: #020202;

      span {
        color: rgb(252 175 23);
      }
    }

    .desc {
      font-size: 14px;
      font-family: $roboto;
    }

    .h-about-button {
      margin-top: 10px;

      .read-more-button {
        background-color: rgb(252, 175, 23);
        border: 1px solid rgb(252, 175, 23);
        border-radius: 5px;
        padding: 8px 25px;
        color: #ffffff;
        font-size: 14px;

        &:hover {
          color: white !important;
        }
      }
    }
  }

  .society-mt-about-list-box {
    .society-mt-about-list {
      padding: 10px;

      li {
        font-size: 14px;
        font-weight: 600;
        color: #020202;
        list-style: square;
      }
    }
  }
}

.society-mt-features-head {
  text-align: center;

  .main-heading {
    font-size: 32px;
    margin-bottom: 15px;

    span {
      color: rgb(252, 175, 23);
    }
  }

  .desc {
    font-size: 14px;
  }
}

// real-estate by mukul
.real-estate-about-us {
  .real-estate-about-content {
    margin-top: 20px;

    .main-heading {
      font-size: 28px;
      font-weight: 500;
      color: #020202;
      font-family: $poppin;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 14px;
      font-family: $roboto;
    }

    .h-about-button {
      margin-top: 20px;

      .read-more-button {
        background-color: rgb(252, 175, 23);
        border: 1px solid rgb(252, 175, 23);
        border-radius: 5px;
        padding: 8px 25px;
        color: #ffffff;
        font-size: 14px;

        &:hover {
          color: white !important;
        }
      }
    }

    .real-estate-about-heading {
      font-size: 24px;
    }

    .real-estate-about-list-box {
      .real-estate-about-list {
        padding-top: 10px;

        li {
          border-left: 1px dashed #bebebe;
          padding: 0px 10px 30px 20px;
          position: relative;
          transition: all 0.3s;
          cursor: pointer;

          &::before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: #bebebe;
            border-radius: 50%;
            top: 5px;
            left: -8px;
          }

          &:hover {
            color: #fcaf17;
          }

          &:hover::before {
            background-color: #fcaf17;
          }
        }
      }
    }
  }
}

.real-estate-about-us img {
  animation: 4s ease-in-out infinite floatY;
  transition: 4s;
  max-width: 90%;
}

@keyframes floatY {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.real-est-development-services {
  background-color: #eff3f6;

  .real-est-development-services-head {
    text-align: center;

    .main-heading {
      font-size: 32px;
      margin-bottom: 15px;

      span {
        color: rgb(252, 175, 23);
      }
    }

    .desc {
      font-size: 14px;
      margin: 0 auto;
      width: 80%;
    }
  }
}

.real-est-services-card {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 30px;

  .real-est-services-icon {
    padding: 10px;

    img {
      border-radius: 20px;
    }
  }

  .real-est-services-content {
    padding-top: 10px;

    h4 {
      font-size: 20px;
    }

    .desc {
      font-size: 16px;
      line-height: 25px;
    }
  }
}

.real-est-mngmnt-system {
  position: relative;
  overflow: hidden;
  min-height: 550px;
  background-image: url("../images/Real-Estate/management/real-est-management-system.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // background-attachment: fixed;

  &::after {
    content: "";
    background-color: rgba(38, 38, 38, 0.8);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
  }

  .real-est-mngmnt-content {
    z-index: 2;
    position: relative;

    .heading {
      color: white;
      font-size: 34px;
      font-weight: 600;
    }

    .desc {
      color: white;
      font-size: 18px;
      margin: 20px 0px 30px 0px;
    }

    .real-est-mngmnt-card {
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 15px;
      text-align: center;

      .desc {
        font-size: 16px;
        margin: 0;
        line-height: 25px;
      }

      .real-est-mngmnt-icon {
        margin-bottom: 15px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 20px;
        }
      }

      h5 {
        color: white;
        font-size: 18px;
      }
    }
  }
}

.real-estate-portfolio {
  .real-est-portfolio-card {
    img {
      width: 80%;
      text-align: center;
    }
  }
}

.real-est-mob-app-solutions {
  background-color: #f7f7f7;

  .real-est-mob-app-head {
    text-align: center;

    .main-heading {
      font-size: 34px;

      span {
        color: #fcaf17;
      }
    }
  }

  .real-est-mob-app-card {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    margin-bottom: 25px;

    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      border-color: #bebebe;
    }

    &:hover::before {
      width: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      width: 0;
      height: 2px;
      background-color: #0fb531;
      transition: 0.9s;
    }

    &.mobcard1::before {
      background-color: #0fb531;
    }

    &.mobcard2::before {
      background-color: #4b9efb;
    }

    &.mobcard3::before {
      background-color: #1cccfc;
    }

    &.mobcard4::before {
      background-color: #fd7070;
    }

    &.mobcard5::before {
      background-color: #5648f9;
    }

    &.mobcard6::before {
      background-color: #eca84b;
    }

    .real-est-upper-part {
      display: flex;
      align-items: center;

      .icon {
        width: 60px;
        height: 60px;
        background-image: url("../images/Real-Estate/mobile-app-solutions/real-estate-app-sprite1.png");
        padding-right: 30px;
      }

      .icon.icon1 {
        background-position: 0 0;
      }

      .icon.icon2 {
        background-position: 0 -60px;
      }

      .icon.icon3 {
        background-position: 0 -120px;
      }

      .icon.icon4 {
        background-position: 0 -180px;
      }

      .icon.icon5 {
        background-position: 0 -240px;
      }

      .icon.icon6 {
        background-position: 0 -300px;
      }

      h4 {
        font-size: 18px;
        margin-left: 10px;
      }
    }

    .real-est-mob-content {
      padding: 10px;
    }
  }
}

.real-est-app-development-features {
  .real-est-app-development-head {
    text-align: center;

    .main-heading {
      font-size: 34px;

      span {
        color: #fcaf17;
      }
    }
  }

  .app-development-features-img {
    img {
      animation: 4s ease-in-out infinite floatY;
      transition: 4s;
      max-width: 90%;
    }
  }

  .app-development-left-side {
    margin-bottom: 30px;

    .app-development-left-side-card {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 15px;
      border-radius: 10px;
      margin-bottom: 15px;

      .app-development-left-side-list {
        display: flex;
        align-items: center;

        li {
          margin-right: 5px;
        }

        h3 {
          font-size: 18px;
        }

        .desc {
          font-size: 16px;
          line-height: 23px;
        }

        .icon {
          width: 50px;
          height: 50px;
          position: relative;
          transition: 0.3s;
        }

        .icon-b {
          position: absolute;
          opacity: 1;
          visibility: visible;
        }

        .icon-y {
          position: absolute;
          opacity: 0;
          visibility: hidden;
        }
      }

      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        cursor: pointer;

        .icon-b {
          opacity: 0;
          visibility: hidden;
        }

        .icon-y {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .app-development-right-side {
    margin-bottom: 30px;

    .app-development-right-side-card {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 15px;
      border-radius: 10px;
      margin-bottom: 15px;
      min-height: 95px;

      .app-development-right-side-list {
        display: flex;
        align-items: center;

        li {
          margin-left: 15px;
        }

        h3 {
          font-size: 18px;
        }

        .desc {
          font-size: 16px;
          line-height: 20px;
        }

        .icon {
          width: 50px;
          height: 50px;
          position: relative;
          transition: 0.3s;
        }

        .icon-b {
          position: absolute;
          opacity: 1;
          visibility: visible;
        }

        .icon-y {
          position: absolute;
          opacity: 0;
          visibility: hidden;
        }
      }

      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        cursor: pointer;

        .icon-b {
          opacity: 0;
          visibility: hidden;
        }

        .icon-y {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.on-demand-head-area-re {
  .sub-head {
    color: white;
    font-size: 24px;
  }

  .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: white;
  }
}

.real-est-banner-sec {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }

  .real-est-banner-content {
    position: absolute;
    z-index: 2;
    top: 50%;
    width: 30%;
    left: 7%;

    .main-heading {
      color: white;
      font-size: 28px;
      margin-bottom: 20px;
      font-weight: 500;

      span {
        color: #fcaf17;
      }
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;
      background-color: #fcaf17;
      border: 1px solid #fcaf17;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid white;
        background: transparent;
        color: white !important;
      }
    }
  }
}

// on-demand-tab-pane-area for real estate

.on-demand-head-area-re .sub-head {
  font-size: 24px;
  color: white;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 15px;
}

.on-demand-head-area-re .main-head {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.on-demand-my-tab-re {
  border-bottom: 1px solid #939393 !important;

  &:hover {
    border-bottom: 3px solid white !important;
  }

  .on-demand-tab-head {
    color: white;
    font-family: $poppin;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
  }
}

.admin-content-list-box-re {
  padding: 30px 0px 0px 30px;
}

.admin-content-list-box1 {
  padding: 30px 0px 0px 0px;
}

.admin-content-list-re li {
  color: white;
  line-height: 45px;
  font-size: 18px;
  font-family: $roboto;
}

.admin-content-list-re li svg {
  margin-right: 10px;
  font-size: 20px;
  color: white;
}

.erp-portfolio-area {
  background-color: #f5e9ce;

  .erp-portfolio-left-side {
    background-color: rgb(215 164 64);
    padding: 80px 40px 0px 40px;

    h4 {
      color: white;
      font-size: 32px;
      font-style: italic;
      font-weight: 500;
      font-family: $poppin;
    }

    .sub-heading-area {
      margin-bottom: 15px;
    }

    .quote-erp {
      color: white;
      font-size: 20px;
      font-family: $roboto;
    }

    .connect-erp {
      .desc {
        color: white;
        font-size: 22px;
        font-family: "Open Sans", sans-serif;
        margin-bottom: 30px;
      }
    }
  }

  .custom-erp-development-img {
    display: flex;
    justify-content: center;
    align-items: center;
    // min-height: 500px;
  }
}

// .erp-portfolio-right-side .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction
// {

//   display: none;
// }

// 404 error pages css start--------------------------------------------------------------
section.page_404 {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.four_zero_four_bg h1 {
  font-size: 40px;
}

.modalpop .modal-header {
  padding: 0;
  border-bottom: none;
}

.modalpop .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 8;
}

.modalpop input {
  background-color: #f4f4f4;
  height: 50px;
  border-radius: 4px;
  border-color: #f4f4f4;
}

.modalpop .form-control {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.modalpop .form-control:focus {
  box-shadow: none;
  border-color: #f4f4f4;
}

.modalpop .btnmodasubmit {
  background-color: #fcaf17;
  padding: 7px 35px;
  color: #fff !important;
  font-size: 20px;
}

// manufacturing-page-css
.manufacturing-banner-sec {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }

  .manufacturing-banner-content {
    position: absolute;
    z-index: 2;
    top: 35%;
    width: 40%;
    left: 5%;

    .main-heading {
      color: white;
      font-size: 28px;
      margin-bottom: 20px;
      font-weight: 500;

      span {
        color: #fcaf17;
      }
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;
      background-color: #fcaf17;
      border: 1px solid #fcaf17;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid white;
        background: transparent;
        color: white !important;
      }
    }
  }
}

.manufacturing-about-us {
  .manufacturing-about-content {
    // margin-top: 20px;

    .main-heading {
      font-size: 28px;
      font-weight: 500;
      color: #020202;
      font-family: $poppin;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 14px;
      font-family: $roboto;
    }

    .h-about-button {
      margin-top: 20px;

      .read-more-button {
        background-color: rgb(252, 175, 23);
        border: 1px solid rgb(252, 175, 23);
        border-radius: 5px;
        padding: 8px 25px;
        color: #ffffff;
        font-size: 14px;

        &:hover {
          color: white !important;
        }
      }
    }
  }

  .manufacturing-about-img img {
    animation: 4s ease-in-out infinite floatY;
    transition: 4s;
    max-width: 90%;
  }

  @keyframes floatY {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0px);
    }
  }
}

.manuft-sft-solutions-sec {
  .manuft-sft-solutions-head {
    text-align: center;

    .main-heading-type {
      color: #020202;
      font-size: 34px;
      font-weight: 500;
      margin-bottom: 20px;

      span {
        color: rgb(252, 175, 23);
      }
    }

    .sub-heading {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      font-size: 14px;
      font-family: $roboto;

      span {
        color: rgb(252, 175, 23);
      }
    }
  }

  .manuft-sft-solutions-img {
    img {
      width: 90%;
      animation: 4s ease-in-out infinite floatY;
      transition: 4s;
    }
  }

  .manuft-sft-solutions-content {
    .main-heading {
      font-size: 28px;
      margin-bottom: 20px;
      font-weight: 600;
    }

    .sub-heading {
      font-size: 20px;
    }

    .mft-solutions-list-box {
      .manuft-sol-cnt-list {
        margin-bottom: 20px;

        .content {
          h6 {
            margin-bottom: 0px;
            font-weight: 600;
            font-size: 16px;
          }

          .desc {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.d-f-j-c-s-b {
  display: flex;
  justify-content: space-between;
}

.manuft-sft-services {
  .manuft-sft-solutions-head {
    text-align: center;

    .main-heading-type {
      color: #020202;
      font-size: 34px;
      font-weight: 500;
      margin-bottom: 20px;

      span {
        color: rgb(252, 175, 23);
      }
    }
  }

  .manuft-sft-services-list {
    display: flex;
    align-items: center;

    li {
      img {
        width: 180px;
      }

      .manuft-sft-services-inner-list {
        padding-left: 25px;
        padding-top: 25px;

        h5 {
          margin-bottom: 5px;
          font-size: 20px;
          font-weight: 600;
        }

        .desc {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
}

.our-works-manuft-software {
  .manuft-sft-works-head {
    text-align: center;

    .main-heading-type {
      color: #020202;
      font-size: 34px;
      font-weight: 500;
      margin-bottom: 20px;

      span {
        color: rgb(252, 175, 23);
      }
    }
  }

  .manuft-sft-works-content {
    padding-top: 50px;

    .sub-heading {
      background-color: black;
      color: white;
      font-size: 16px;
      text-align: center;
      display: inline-block;
      padding: 8px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .main-heading {
      font-weight: 600;
      font-size: 24px;
    }

    .desc {
      margin-bottom: 25px;
    }

    .contact-us-buttn {
      background-color: #fcaf17;
      color: white;
      padding: 10px 32px;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 18px;
    }
  }
}

.retail-pos-banner-sec {
  position: relative;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }

  .retail-pos-banner-content {
    position: absolute;
    z-index: 2;
    top: 50%;
    width: 31%;
    left: 7%;

    .main-heading {
      color: white;
      font-size: 28px;
      margin-bottom: 20px;
      font-weight: 500;

      span {
        color: #fcaf17;
      }
    }

    .sub-heading {
      color: white;
      // font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;
      background-color: #fcaf17;
      border: 1px solid #fcaf17;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid white;
        background: transparent;
        color: white !important;
      }
    }
  }
}

.retail-pos-about-us {
  .retail-pos-about-content {
    // margin-top: 20px;

    .main-heading {
      font-size: 26px;
      font-weight: 500;
      color: #020202;
      font-family: $poppin;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 14px;
      font-family: $roboto;
    }

    .h-about-button {
      margin-top: 20px;

      .read-more-button {
        background-color: rgb(252, 175, 23);
        border: 1px solid rgb(252, 175, 23);
        border-radius: 5px;
        padding: 8px 25px;
        color: #ffffff;
        font-size: 14px;

        &:hover {
          color: white !important;
        }
      }
    }
  }

  .retail-pos-about-img img {
    animation: 4s ease-in-out infinite floatY;
    transition: 4s;
    max-width: 90%;
  }

  @keyframes floatY {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0px);
    }
  }
}

.retail-pos-features-sec {
  background-color: #eeece0 !important;

  .retail-pos-features-head {
    text-align: center;

    .main-heading-type {
      color: #020202;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 20px;

      span {
        color: rgb(252, 175, 23);
      }
    }

    .sub-heading {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      font-size: 14px;
      font-family: $roboto;

      span {
        color: rgb(252, 175, 23);
      }
    }
  }

  .retail-pos-features-content {
    padding-top: 50px;

    .main-head {
      font-size: 29px;
      position: relative;
      padding-bottom: 14px;
      width: 94%;
      text-align: center;
      font-weight: 600;
      line-height: 35px;

      &::after {
        content: "";
        position: absolute;
        left: 0px;
        right: 0px;
        margin: 0 auto;
        width: 20%;
        height: 5px;
        background-color: #fcaf17;
        bottom: 0;
      }
    }

    .retail-pos-accord-ion {
      padding-top: 40px;
    }

    .retail-pos-features-card {
      background-color: #ffffff;
      -webkit-box-shadow: -2px 6px 30px rgba(39, 176, 230, 0.08);
      box-shadow: -2px 6px 30px rgba(39, 176, 230, 0.08);
      padding: 15px;
      margin-top: 30px;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      border: 1px solid #ffffff;
      border-radius: 10px;
      min-height: 144px;

      &:hover {
        border-color: #fcaf17;
        transform: translateY(-5px);
        cursor: pointer;

        .retail-pos-features-card-icon {
          background-color: #fcaf17;

          .icon-w {
            opacity: 1;
            visibility: visible;
          }

          .icon-y {
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      .retail-pos-features-card-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        width: 70px;
        line-height: 70px;
        background-color: #ffffff;
        -webkit-box-shadow: -2px 4px 15px rgba(41, 171, 226, 0.12);
        box-shadow: -2px 4px 15px rgba(41, 171, 226, 0.12);
        border-radius: 50px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        margin: 0 auto;
        position: relative;

        .icon-w {
          position: absolute;
          opacity: 0;
          visibility: hidden;
        }

        .icon-y {
          position: absolute;
          opacity: 1;
          visibility: visible;
        }
      }

      .retail-pos-features-card-content {
        .desc {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .retail-pos-features-img {
    text-align: center;
    margin-top: 15%;

    img {
      width: 80%;
      margin-left: 0px;
    }
  }

  .explore-buttn-holder {
    text-align: center;

    margin-top: 40px;

    .explore-buttn {
      background-color: #fcaf17;
      color: white;
      padding: 12px 32px;
      border-radius: 5px;
      font-weight: 600;
      position: relative;

      transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1),
        transform 0.45s cubic-bezier(0.25, 1, 0.33, 1);

      .right-arrw {
        color: white;
        font-size: 20px;
        position: absolute;
        right: 4%;
        top: 50%;
        opacity: 0;
        transform: translateX(0px);
        transition: all 0.45s cubic-bezier(0.25, 1, 0.33, 1);
        margin-top: -9px;
      }

      &:hover {
        color: white !important;

        .right-arrw {
          opacity: 1;
          transform: translateX(6px);
        }
      }
    }
  }

  .retail-pos-accord-ion .accordion-button:focus .accordion-header {
    border-bottom: 0px;
  }

  .retail-pos-accord-ion .accordion-button:not(.collapsed) {
    color: #000;
    font-weight: 600;
    border-color: transparent !important;
    transition: transform 0.45s cubic-bezier(0.3, 0.4, 0.2, 1);
  }

  .retail-pos-accord-ion .accordion-button:not(.collapsed).accordion-header {
    border-color: transparent !important;
  }

  .retail-pos-accord-ion .accordion-header {
    border-bottom: 1px solid #e5e5e5;
  }

  .retail-pos-accord-ion .accordion-button {
    background-color: #fff;
    color: black;
    font-weight: 600;
    font-size: 18px;
    padding: 15px;
  }

  .retail-pos-accord-ion .accordion-button:not(.collapsed) .minus-icon {
    display: block;
  }

  .retail-pos-accord-ion .accordion-button:not(.collapsed) .plus-icon {
    display: none;
  }

  .retail-pos-accord-ion .accordion-button.collapsed .minus-icon {
    display: none;
  }

  .retail-pos-accord-ion .accordion-button.collapsed .plus-icon {
    display: block;
  }
}

.retail-pos-software-importance {
  .retail-pos-software-imp-head {
    text-align: center;

    .main-heading-type {
      color: #020202;
      font-size: 34px;
      font-weight: 600;
      margin-bottom: 15px;

      span {
        color: rgb(252, 175, 23);
      }
    }

    .desc {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      font-size: 16px;
      line-height: 25px;
    }
  }

  // .retail-pos-imp-card {
  //   border-radius: 20px;
  //   position: relative;
  //   border: 1px solid transparent;
  //   margin-bottom: 30px;
  //   padding: 10px;
  //   text-align: center;
  //   min-height: 436px;

  //   &.brder-clr-1 {
  //     border-color: #7584c8;
  //   }
  //   &.brder-clr-2 {
  //     border-color: #90b2a3;
  //   }
  //   &.brder-clr-3 {
  //     border-color: #6d9ebc;
  //   }
  //   &.brder-clr-4 {
  //     border-color: #efd99c;
  //   }

  //   &.brder-clr-5 {
  //     border-color: #94d4ca;
  //   }

  //   &.brder-clr-6 {
  //     border-color: #f48771;
  //   }
  //   &.brder-clr-7 {
  //     border-color: #92ce52;
  //   }
  //   &.brder-clr-8 {
  //     border-color: #8b9047;
  //   }
  //   &.brder-clr-9 {
  //     border-color: #6a7782;
  //   }

  //   .retail-pos-imp-icon {
  //     margin: 0 auto;
  //     text-align: center;

  //     img {
  //       width: 25%;
  //     }
  //   }

  //   .retail-pos-imp-content {
  //     padding-top: 20px;

  //     h3 {
  //       color: #3d3e42;
  //       font-size: 20px;
  //     }

  //     .desc {
  //       font-size: 18px;
  //       line-height: 30px;
  //       width: 100%;
  //     }
  //   }
  // }
  .retail-pos-imp-card {
    border-radius: 20px;
    position: relative;
    border: 1px solid transparent;
    margin-bottom: 30px;
    padding: 10px;
    text-align: center;
    min-height: 380px;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
    transform: scale(0.85);

    // &.brder-clr-1
    // {
    // border-color:#7584c8;
    // }
    // &.brder-clr-2
    // {
    // border-color:#90b2a3;
    // }
    // &.brder-clr-3
    // {
    // border-color:#6d9ebc;
    // }
    // &.brder-clr-4
    // {
    // border-color:#efd99c;
    // }

    // &.brder-clr-5
    // {
    // border-color:#94d4ca;
    // }

    // &.brder-clr-6
    // {
    // border-color:#f48771;
    // }
    // &.brder-clr-7
    // {
    // border-color:#92CE52;
    // }
    // &.brder-clr-8
    // {
    // border-color:#8B9047;
    // }
    // &.brder-clr-9
    // {
    // border-color:#6A7782;
    // }

    .retail-pos-imp-icon {
      margin: 0 auto;
      text-align: center;

      img {
        width: 20%;
      }
    }

    .retail-pos-imp-content {
      padding-top: 20px;

      h3 {
        color: #3d3e42;
        font-size: 20px;
      }

      .desc {
        font-size: 16px;
        line-height: 25px;
        width: 100%;
      }
    }
  }
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-50 {
  padding-top: 50px;
}

// whatsapp-chat-bot-banner

.whatsapp-chatbot-banner-sec {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }
}

.whatsapp-bot-about-us {
  background: linear-gradient(180deg, #f3ecdd, rgba(231, 245, 255, 0));

  .whatsapp-bot-about-us-head {
    text-align: center;

    .main-head {
      font-size: 41px;
      font-weight: 600;
      text-align: center;
      letter-spacing: 1px;

      span {
        color: #fcaf17;
      }
    }

    .h-about-button {
      margin-top: 30px;

      .read-more-button {
        background-color: rgb(252, 175, 23);
        border: 1px solid rgb(252, 175, 23);
        border-radius: 5px;
        padding: 8px 25px;
        color: #ffffff;
        font-size: 14px;

        &:hover {
          color: white !important;
        }
      }
    }
  }
}

.why-you-need-whatsappbot {
  background-color: #2f3134;

  .why-you-need-whatsappbot-head {
    text-align: center;
    color: white;
    // background: #fcaf17;
    border: transparent;
  }
}

.retail-pos-accord-ion .minus-icon {
  position: absolute;
  right: 5%;
  font-size: 30px;
  transition: all 0.4s;
}

.retail-pos-accord-ion .plus-icon {
  position: absolute;
  right: 5%;
  font-size: 30px;
  transition: all 0.4s;
}

.retail-pos-accord-ion .accordion-button:hover {
  color: #fcaf17;
}

.retail-pos-accord-ion .accordion-item {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom: 0px;
}

.retail-pos-accord-ion .accordion-button::after {
  background-image: none;
}

.retail-pos-why-choose-us {
  background-color: #f1f2f2;

  .retail-pos-why-choose-us-head {
    text-align: center;

    .main-heading-type {
      color: #020202;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 20px;

      span {
        color: rgb(252, 175, 23);
      }
    }
  }

  .why-choose-retail-pos-card {
    padding: 15px;
    border-radius: 16px;
    min-height: 274px;
    margin-bottom: 25px;

    &.color-1 {
      background-color: #f9eba6;
    }

    &.color-2 {
      background-color: #b5eecc;
    }

    &.color-3 {
      background-color: #dbe1e1;
    }

    &.color-4 {
      background-color: rgba(239, 157, 16, 0.2588235294);
    }

    &.color-5 {
      background-color: rgba(196, 163, 90, 0.3411764706);
    }

    &.color-6 {
      background-color: rgba(236, 193, 156, 0.6901960784);
    }

    &.color-7 {
      background-color: #d3d9cd;
    }

    &.color-8 {
      background-color: #d2eddd;
    }

    .why-choose-retail-pos-icon {
      text-align: center;
      padding-bottom: 10px;

      img {
        width: 25%;
      }
    }

    .why-choose-retail-pos-content {
      h3 {
        text-align: center;
        font-size: 20px;
        width: 100%;
        margin: 0 auto;
      }

      .desc {
        font-size: 18px;
        line-height: 25px;
        padding: 10px 0px;
        text-align: center;
      }
    }
  }
}

.possible-channels-sec {
  // background-color:#f6f6f6!important;
  background-color: #e7e5e0;
  // border-radius: 10px;

  .possible-channels-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      margin-bottom: 20px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 16px;
    }
  }

  .left-side-whatsapp-content {
    position: relative;
    background-color: white;
    padding: 45px;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
    margin-top: 30% !important;

    .main-heading {
      padding-top: 30px;
    }

    .icon {
      width: 70px;
      height: 70px;
      margin: 0 auto;
      top: 0px;
      position: absolute;
      left: 0;
      right: 0;
      padding: 10px 0px;

      img {
        width: 85%;
      }
    }

    &.whatsapp::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("../images/Whatsapp-Bot/allchannels/whatsapp-left-border.png");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    &.instagram::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("../images/Whatsapp-Bot/allchannels/instagram-left-border.png");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    &.website::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("../images/Whatsapp-Bot/allchannels/website-left-border.png");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    &.fb::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("../images/Whatsapp-Bot/allchannels/instagram-left-border.png");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    &.google::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("../images/Whatsapp-Bot/allchannels/google-left-border.png");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    &.imessage::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("../images/Whatsapp-Bot/allchannels/whatsapp-left-border.png");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    &.viber::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("../images/Whatsapp-Bot/allchannels/viber-left-border.png");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .desc {
      color: black;
      line-height: 25px;
      font-size: 16px;
    }
  }

  .possible-channels-tab-panes {
    //   .nav-tabs {
    //     border-color: transparent !important;
    //     justify-content: center;

    //     .nav-item button
    //     {

    //       background-color: #e8ebf1;
    //       border-radius: 50px;
    //       color: #020202;
    //       padding: 10px 32px!important;
    //       margin: 0px 20px;
    //       font-weight: 600;

    //     &.active {
    //         background-color: #fcaf17;
    //         color: #ffffff !important;

    //     }
    //     &:hover {
    //         background-color: #fcaf17;
    //         color: #ffffff !important;
    //         border-color: transparent;

    //     }

    //     }

    // }

    .tab-content {
      background-color: #f6f6f6 !important;
      border-radius: 10px;
      padding: 40px;

      .left-side-whatsapp-content {
        position: relative;
        background-color: white;
        padding: 45px;
        border-radius: 10px;
        width: 80%;
        margin: 0 auto;
        margin-top: 30% !important;

        &.whatsapp::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-image: url("../images/Whatsapp-Bot/allchannels/whatsapp-left-border.png");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }

        &.instagram::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-image: url("../images/Whatsapp-Bot/allchannels/instagram-left-border.png");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }

        &.website::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-image: url("../images/Whatsapp-Bot/allchannels/website-left-border.png");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }

        &.fb::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-image: url("../images/Whatsapp-Bot/allchannels/instagram-left-border.png");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }

        &.google::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-image: url("../images/Whatsapp-Bot/allchannels/google-left-border.png");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }

        &.imessage::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-image: url("../images/Whatsapp-Bot/allchannels/whatsapp-left-border.png");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }

        &.viber::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-image: url("../images/Whatsapp-Bot/allchannels/viber-left-border.png");
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }

        .desc {
          color: black;
          line-height: 25px;
          font-size: 16px;
        }
      }
    }
  }
}

// whatsapp-chat-bot-banner

.whatsapp-chatbot-banner-sec {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }
}

.whatsapp-bot-about-us {
  background: linear-gradient(180deg, #f3ecdd, rgba(231, 245, 255, 0));

  .whatsapp-bot-about-us-head {
    text-align: center;

    .main-head {
      font-size: 41px;
      font-weight: 600;
      text-align: center;
      letter-spacing: 1px;

      span {
        color: #fcaf17;
      }
    }

    .h-about-button {
      margin-top: 30px;

      .read-more-button {
        background-color: rgb(252, 175, 23);
        border: 1px solid rgb(252, 175, 23);
        border-radius: 5px;
        padding: 8px 25px;
        color: #ffffff;
        font-size: 14px;

        &:hover {
          color: white !important;
        }
      }
    }
  }
}

.why-you-need-whatsappbot {
  background-color: #2f3134;

  .why-you-need-whatsappbot-head {
    text-align: center;
    color: white;

    .main-heading {
      color: white;
    }
  }

  .why-you-need-whatsappbot-card {
    padding: 25px;
    box-shadow: 0px 3px 15px #00000029;
    border-radius: 8px;
    background-color: white;
    text-align: center;
    margin-bottom: 25px;

    .why-you-need-img {
      margin: 10px auto;
    }

    h6 {
      padding: 10px;
      font-size: 18px;
    }
  }
}

.whatsapp-chatbot-benefits {
  background-color: #ecf0f3;

  .whatsapp-chatbot-benefits-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      font-weight: 600;

      span {
        font-weight: 700;
        line-height: 50px;
        text-align: left;
        background: linear-gradient(90.11deg, #fcaf17 0.09%, #020202 99.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .whatsapp-chatbot-benefits-card {
    max-width: 310px;
    border-radius: 24px;
    margin-bottom: 2rem;
    background: #f5f5fa;
    box-shadow: inset -1px -1px 2px 0 #fff,
      inset 1px 1px 2px 0 rgba(170, 170, 204, 0.5019607843137255);
    padding: 25px;
    min-height: 262px;

    .whatsapp-chatbot-benefits-header-box {
      padding: 10px 10px 20px 10px;

      .whatsapp-benefits-header-list {
        display: flex;
        align-items: center;
        // justify-content: space-around;

        li {
          img {
            border: 2px solid;
            border-image-source: linear-gradient(
              135deg,
              rgba(0, 0, 0, 0.05),
              hsla(0, 0%, 100%, 0.05)
            );
            border-radius: 14px;
            padding: 0.5rem;
            box-shadow: 13px 10px 9.981898307800293px 0
                rgba(174, 174, 192, 0.30196078431372547),
              -4.9909491539001465px -4.9909491539001465px 9.981898307800293px 0
                #fff,
              inset 1.9963797330856323px 1.9963797330856323px
                3.9927594661712646px 0 #fff,
              inset 0 1px 3.9927594661712646px 0
                rgba(0, 0, 0, 0.10196078431372549);
          }

          h4 {
            margin-bottom: 0px;
            margin-left: 20px;
            font-size: 20px;
          }

          .desc {
            line-height: 25px;
          }
        }
      }
    }
  }
}

// 404 error pages css end--------------------------------------------------------------

.retail-pos-software-importance
  .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
  /* padding: 20px; */
  padding: 20px 10px;
}

.retail-pos-software-importance
  .swiper-slide.swiper-slide-active
  .retail-pos-imp-card {
  background-color: #fff9ed;
  overflow: hidden;
  transform: scale(1.05);
}

.mockup_webright {
  display: flex;
  justify-content: space-between;

  .content_portflito,
  .img_portflito {
    flex: 1;
  }

  .img_portflito {
    text-align: end;

    .webportfolo {
      position: relative;
      top: 20%;
      z-index: 99;
    }
  }

  .box_bgportfoliyo {
    position: relative;
    // height: 80vh;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      height: 80vh;
      width: 80%;
      background-color: red;
    }
  }
}

// popmodal header css----------------------------------------
// popmodal header css----------------------------------------
.brochurepadding {
  padding: 20px 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.portfolio_cardimg_content .btn-group {
  display: none;
}

.borchureimgheader {
  width: 100%;
  height: 420px;

  img
  {
    object-fit: cover;
    width:100%;
    height: 100%;
  }

}



// popmodal header css----------------------------------------
// popmodal header css----------------------------------------

/************* case-study **************/

.case_section1 {
  background-color: #f8fafc;
}

.case_section1 .case_content {
  box-shadow: 0px 8px 16px 0px rgba(50, 50, 50, 0.04);
  background: #ffffff;
  transition: 0.3s all ease-in-out;
  // padding: 55px 40px 50px;
  text-align: center;
  position: relative;
  min-height: 372px;
  padding: 50px 20px;
}

.case_section1 .case_content h3 {
  font-family: $poppin;
  font-size: 50px;
  font-weight: 500;
  color: #212529;
  -webkit-text-fill-color: transparent !important;
  -webkit-text-stroke: 1px #212529;
}

.case_section1 .case_content h4 {
  font-family: $poppin;
  font-size: 20px;
  font-weight: 600;
  color: #212529;
}

.case_section1 .case_content p {
  font-family: $poppin;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  color: #979595;
}

.case_section1 .tech-used {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.case_section1 .tech-used span {
  font-family: $poppin;
  font-size: 15px;
  font-weight: 400;
  color: #979595;
}

.case_section1 .tech-used img {
  width: 150px;
  height: 75px;
  object-fit: contain;
}

.case_section1 .tech-used1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.case_section1 .tech-used1 span {
  font-family: $poppin;
  font-size: 15px;
  font-weight: 400;
  color: #979595;
}

.case_section1 .tech-used1 img {
  width: 210px;
  height: 75px;
  object-fit: contain;
}

.case_section1 .case_image {
  display: block;
  box-shadow: 0px 8px 16px 0px rgba(50, 50, 50, 0.04);
  background: #ffffff;
  transition: 0.3s all ease-in-out;
  padding: 55px 40px 50px;
  text-align: center;
  position: relative;
  min-height: 372px;
  background: linear-gradient(180deg, rgb(0 0 0 / 1%) 0, #0000001f 100%);
}

.case_section1 .case_image img {
  position: absolute;
  width: 270px;
  height: 200px;
  object-fit: contain;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.case_section1 .case_content:hover {
  background-color: #000000;
}

.case_section1 .case_content:hover h3 {
  color: #fcaf17;
  -webkit-text-stroke: 1px #fcaf17;
}

.case_section1 .case_content:hover h4 {
  color: #fcaf17;
}

.case_section1 .case_content:hover p,
.case_section1 .case_content:hover span {
  color: #ddd9d9;
}

// patient-journey-steps

.patient-journey-steps {
  .patient-journey-steps-head {
    .main-head {
      font-size: 34px;
      font-weight: 600;
      color: #020202;
      font-family: "Poppins", sans-serif;
      text-align: center;
      margin-bottom: 15px;

      span {
        color: #fcaf17;
      }
    }
  }

  #steps_process {
    position: relative;

    .step-process-left-sidebar {
      position: relative;

      .step-process-card {
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.3);
        margin-top: 50px;
        padding: 30px 0px;
        // opacity: .5;
        height: 80vh;

        .main-heading {
          margin-bottom: 15px;
          padding: 0px 30px;
        }

        .desc {
          padding: 0px 30px;
        }

        .play-buttn {
          margin-top: 20px;
          color: #fcaf17;
          font-weight: 600;
          padding: 0px 30px;

          img {
            margin-right: 10px;
          }
        }

        .border-hr {
          width: 100%;
          height: 1px;
          background: #e2e2e2;
          margin: 20px 0;
        }

        .founder-box {
          .founder-name {
            font-size: 18px;
            padding: 0px 30px;
            margin-top: 10px;
          }

          .designation {
            font-size: 16px;
            padding: 0px 30px;
            color: #797979;
          }
        }

        .logo {
          text-align: end;
          padding-right: 30px;

          img {
            width: 60%;
          }
        }

        .d-f-ja-c {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    #steps_image {
      position: sticky;
      height: 100vh;
      top: 0;
      text-align: center;

      img {
        width: 60%;
      }
    }
  }
}

.Value-added-features {
  background: #f6f6f6;

  .Value-added-features-head {
    .main-head {
      font-size: 34px;
      font-weight: 600;
      color: #020202;
      font-family: "Poppins", sans-serif;
      text-align: center;
      margin-bottom: 15px;

      span {
        color: #fcaf17;
      }
    }
  }

  .value-added-features-card {
    transition: all 0.5s;
    min-height: 525px;
    margin-bottom: 25px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 30px 80px rgba(0, 57, 110, 0.05);

    &:hover {
      // transform: translateY(-5px);
      cursor: pointer;
      transition: 0.5s;

      .value-added-features-card-icon {
        img {
          transform: scale(1.07);
        }
      }

      // .value-added-features-card-icon
      // {
      //   background-color: #fcaf17;
      //   color: white;
      //   border-radius: 50%;

      //   .icon-y
      // {
      //   position: absolute;
      //   opacity: 0;
      //   visibility: hidden;
      // }

      // .icon-w
      // {
      //   position: absolute;
      //   opacity: 1;
      //   visibility: visible;
      // }

      // }
    }

    .value-added-features-card-icon {
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      transition: 0.5s;

      img {
        border-radius: 10px 10px 0 0;
        min-height: 297px;
        transform: scale(1);
        transition: 0.5s;
      }
    }

    //     .value-added-features-card-icon
    //     {
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       width: 90px;
    //       height: 90px;
    //       // font-size: 40px;
    //       position: relative;
    //       border-radius: 50%;
    //       text-align: center;
    //       margin-bottom: 25px;
    //       transition: 0.5s;
    //     background-color: white;
    //       border-radius: 30% 70% 47% 53%/30% 30% 70% 70%;
    //       // color: #fcaf17;

    //       img
    //       {
    //         width: 45%;
    //       }

    // .icon-y
    // {
    //   position: absolute;
    //   opacity: 1;
    //   visibility: visible;
    //   left: 0;
    //   margin: 0 auto;
    //   right: 0;
    // }

    // .icon-w
    // {
    //   position: absolute;
    //   opacity: 0;
    //   visibility: hidden;
    //   left: 0;
    //   margin: 0 auto;
    //   right: 0;
    // }

    // //       svg
    // //       {
    // // content: '';
    // // position: absolute;
    // // left: 0;
    // // right: 0;
    // // top:50%;
    // // transform: translateY(-50%);
    // // margin: 0 auto;
    // //       }

    //     }

    .value-added-features-card-content {
      padding: 15px;

      .main-heading {
        font-size: 22px;
        text-transform: uppercase;
      }

      .sub-heading {
        font-size: 18px;
        color: #fcaf17;
      }

      .desc {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}

// casestudy-sec

.breadcrumb-area {
  padding: 20px 0px;

  .breadcrumb-casestudy-box {
    .breadcrumb-casestudy-list {
      display: flex;
      align-items: center;

      li {
        margin-left: 10px;

        a {
          color: #020202;
          font-size: 15px;
          font-weight: 600;

          &:hover {
            color: #fcaf17 !important;
          }
        }
      }
    }
  }
}

// fasto-app-case-study-css

.case-study-banner-sec {
  position: relative;
  z-index: 1;

  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/case-study-1/banner/fasto-banner-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .case-study-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 40%;
    left: 15%;

    .main-heading {
      font-size: 32px;
      margin-bottom: 10px;
      font-weight: 500;
      margin-top: 15px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 14px;
      line-height: 25px;
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;
      background-color: #fcaf17;
      border: 1px solid #fcaf17;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid white;
        background: transparent;
        color: white !important;
      }
    }
  }

  .fasto-banner-img {
    position: absolute;
    z-index: 2;
    top: 5%;
    right: 0%;

    img {
      width: 85%;
    }
  }
}

.about-client-sec {
  background-color: whitesmoke;

  .about-client-main-head {
    .main-heading1 {
      font-size: 40px;
      font-weight: 600;
      position: relative;
      margin-bottom: 20px;
    }
  }

  .about-client-content-box {
    padding-left: 50px;
    padding-top: 15%;
    position: relative;

    .desc {
      font-size: 17px;
      line-height: 25px;
    }
  }

  .about-client-img {
    img {
      border-radius: 10px;
    }
  }
}

.business-model-sec {
  background-color: #f5faff;

  .business-model-main-head {
    .main-heading {
      font-size: 34px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }

    .desc {
      text-align: center;
    }
  }

  .business-model-card {
    padding: 25px 35px;
    border-radius: 5px;
    border-left: 2px solid #dee9fe;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease;
    overflow: hidden;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: #0554f2;
      border-radius: 5px;
      transition: all 0.4s ease;
      z-index: -1;
    }

    &:hover {
      color: white;

      .business-model-list-box .business-model-list li .card-head {
        color: white;
      }

      .business-model-list-box
        .business-model-list
        li
        .business-model-card-img
        .icon-w {
        opacity: 1;
        visibility: visible;
      }

      .business-model-list-box
        .business-model-list
        li
        .business-model-card-img
        .icon-y {
        opacity: 0;
        visibility: hidden;
      }
    }

    &:hover::before {
      width: 100%;
    }

    .business-model-list-box {
      padding-bottom: 20px;
      transition: all 0.4s ease;

      .business-model-list {
        display: flex;
        align-items: center;

        li {
          .business-model-card-img {
            position: relative;

            .icon-b {
              width: 45px;
              opacity: 1;
              visibility: visible;
              transition: all 0.4s ease;
            }

            .icon-w {
              position: absolute;
              top: 0;
              left: 0;
              width: 45px;
              opacity: 0;
              visibility: hidden;
              transition: all 0.4s ease;
            }
          }

          .card-head {
            margin-left: 20px;
            margin-bottom: 0px;
            font-size: 22px;
          }
        }
      }
    }

    .desc {
      font-size: 16px;
      line-height: 25px;
    }
  }
}

// market-research-sec
.market-research-sec {
  background-color: #f6f7f9;

  .market-research-head {
    // margin-top: 3%;

    .main-heading {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }

    .desc {
      font-size: 16px;
      margin-top: 20px;
      text-align: center;
      line-height: 20px;
    }

    .market-research-content-box {
      margin-top: 35px;

      .market-research-content {
        padding: 20px;
        background-color: white;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-bottom: 7px solid #3740aa;
        min-height: 300px;

        .number-area {
          display: block;
          font-size: 50px;
          font-weight: 700;
          line-height: 1.4;
          color: #03228f;
        }

        h5 {
          margin-bottom: 10px;
          font-weight: 600;
        }

        .para {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }

  .market-research-img {
    text-align: center;
    animation: 4s ease-in-out infinite floatY;

    img {
      width: 40%;
    }
  }
}

.technology-sec {
  .technology-head {
    margin-top: 2%;
    background-color: #3740aa;
    padding: 50px 35px 50px 35px;
    border-radius: 10px 10px 10px 10px;

    .main-heading {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;
      color: white;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }

    .desc {
      font-size: 18px;
      margin-top: 15px;
      text-align: center;
      line-height: 30px;
      color: white;
    }
  }

  .technology-content-area {
    position: relative;
    border-top: 1px solid #e1e1e1;
    transition: all 0.3s ease 0s;
    margin-top: 10%;
    margin-left: 5%;

    &::before {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      z-index: 1;
      left: 0;
      top: -7.5px;
    }

    &::after {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      z-index: 1;
      right: 0;
      top: -7.5px;
    }

    .technology-card {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        background: #004fd2;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        top: -7.5px;
        z-index: 1;
      }

      &::after {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        top: 0;
        width: 1px;
        background: #e1e1e1;
        height: 100px;
        z-index: -11;
      }

      .technology-card-content {
        padding-top: 100px;
        text-align: center;

        .number-area {
          font-size: 42px;
          font-weight: 800;
          line-height: 80px;
          text-align: center;
          width: 80px;
          height: 80px;
          color: #ffffff;
          margin: 0 auto;
          border-radius: 50%;
          background-image: linear-gradient(90deg, #004fd2 0%, #a112dc 100%);
        }

        .content-txt {
          padding: 20px 10px 0px 10px;

          .desc {
            line-height: 25px;
            font-size: 16px;
          }
        }
      }
    }

    .technology-card-style1 {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        background: #a112dc;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        top: -7.5px;
        z-index: 1;
      }

      &::after {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        top: 0;
        width: 1px;
        background: #e1e1e1;
        height: 100px;
        z-index: -11;
      }

      .technology-card-content {
        padding-top: 80px;
        text-align: center;

        .number-area {
          font-size: 42px;
          font-weight: 800;
          line-height: 80px;
          text-align: center;
          width: 80px;
          height: 80px;
          color: #ffffff;
          margin: 0 auto;
          border-radius: 50%;
          background-image: linear-gradient(90deg, #004fd2 0%, #a112dc 100%);
        }

        .content-txt {
          padding: 20px 15px 0px 15px;

          .desc {
            line-height: 25px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .market-research-img {
    text-align: center;

    img {
      width: 40%;
    }
  }
}

.challenges-and-solutions-sec {
  background-color: whitesmoke;

  .challenges-solutions-main-head {
    .main-heading {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }
  }

  .challenges-and-solutions-img {
    text-align: center;
    // animation: 4s ease-in-out infinite floatY;
    margin-top: 5%;

    img {
      width: 85%;
    }
  }

  .challenges-solutions-content {
    margin-left: 5%;

    .challenges-solutions-card {
      background: #ffffff;
      box-shadow: 0px 2px 20px rgba(24, 16, 16, 0.07);
      position: relative;
      border-left: 8px solid transparent;
      transition: 0.3s;
      margin-bottom: 20px;
      padding: 20px 20px 20px 30px;

      .number-card {
        content: "";
        position: absolute;
        background-color: #3740aa;
        color: white;
        font-size: 25px;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-weight: 700;
        top: 25%;
        left: -5%;
      }

      .desc {
        line-height: 20px;
        font-size: 14px;
      }

      &:hover {
        border-color: #3740aa;
        transform: translateY(-5px);
      }
    }
  }
}

// results-growth-sec

.results-growth-sec {
  background-color: #ecf2f6;

  .results-growth-head {
    .main-heading {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }
  }

  .results-growth-img {
    margin-right: 5%;
  }

  .results-growth-content {
    .results-growth-content-box {
      margin-top: 15px;
      background-color: white;
      padding: 30px;

      .results-growth-content-list {
        li {
          position: relative;
          padding-left: 35px;
          line-height: 30px;
          margin-bottom: 12px;

          .checkicn {
            content: "";
            position: absolute;
            left: 0;
            top: 5px;
            font-size: 22px;
            color: #414bbe;
          }
        }
      }
    }
  }
}

.future-prospects-sec {
  background-color: #e7e7e7c2;
  min-height: 450px;
  width: 100%;

  .future-prospects-head {
    background-color: white;
    padding: 80px 30px 30px 30px;
    min-height: 450px;

    .main-heading {
      font-size: 28px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }

    .future-prospects-content-box {
      padding-top: 20px;

      .future-prospects-content-list {
        li {
          position: relative;
          padding-left: 35px;
          line-height: 30px;
          margin-bottom: 12px;

          .checkicn {
            content: "";
            position: absolute;
            left: 0;
            top: 5px;
            font-size: 22px;
            color: #414bbe;
          }
        }
      }
    }
  }

  .future-prospects-img {
    margin-left: 5%;
    margin-top: 10%;
  }
}

.conclusion-sec {
  position: relative;
  z-index: 1;

  width: 100%;
  min-height: 250px;
  background-image: url("../images/casestudy/case-study-1/conclusion/conclusion.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1e2c46;
    opacity: 0.8;
    z-index: 1;
  }

  .conclusion-sec-head {
    z-index: 2;

    .main-heading {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;
      color: white;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }
  }

  .conclusion-content-box {
    .desc {
      color: white;
      text-align: center;
      font-size: 18px;
      width: 55%;
      margin: 0 auto;
    }
  }
}

// huddle-heart-css-start-now

.huddle-heart-banner-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/Huddle-heart/banner/huddle-heart-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .huddle-heart-banner-content {
    margin-top: 5%;

    .mt-30 {
      margin-top: 30px;
    }

    .main-heading {
      font-size: 32px;
      margin-bottom: 10px;
      font-weight: 500;
      margin-top: 15px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 14px;
      line-height: 25px;
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;
      background-color: #41b2e6;
      border: 1px solid #41b2e6;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid transparent;
        background: whitesmoke;
        color: #41b2e6 !important;
      }
    }

    .huddle-heart-intro-list-box {
      text-align: center;

      .huddle-heart-intro-list {
        text-align: left;
      }
    }
  }

  .huddle-heart-banner-img {
    text-align: end;

    img {
      margin-top: 5%;
    }
  }
}

// business-model-sec-huddle-heart

.future-prospects-sec {
  background-color: #f5faff;

  .business-model-main-head {
    .main-heading {
      font-size: 34px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }

    .desc {
      text-align: center;
    }
  }

  .business-model-card {
    padding: 25px 35px;
    border-radius: 5px;
    border-left: 2px solid #b2e1f7;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease;
    overflow: hidden;
    z-index: 1;
    min-height: 300px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: #41b2e6;
      border-radius: 5px;
      transition: all 0.4s ease;
      z-index: -1;
    }

    &:hover {
      color: white;

      .business-model-list-box .business-model-list li .card-head {
        color: white;
      }

      .business-model-list-box
        .business-model-list
        li
        .business-model-card-img
        .icon-w {
        opacity: 1;
        visibility: visible;
      }

      .business-model-list-box
        .business-model-list
        li
        .business-model-card-img
        .icon-b {
        opacity: 0;
        visibility: hidden;
      }
    }

    &:hover::before {
      width: 100%;
    }

    .business-model-list-box {
      padding-bottom: 20px;
      transition: all 0.4s ease;

      .business-model-list {
        display: flex;
        align-items: center;

        li {
          .business-model-card-img {
            position: relative;

            .icon-b {
              width: 50px;
              opacity: 1;
              visibility: visible;
              transition: all 0.4s ease;
            }

            .icon-w {
              position: absolute;
              top: 0;
              left: 0;
              width: 45px;
              opacity: 0;
              visibility: hidden;
              transition: all 0.4s ease;
            }
          }

          .card-head {
            margin-left: 20px;
            margin-bottom: 0px;
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }

    .desc {
      font-size: 16px;
      line-height: 25px;
    }
  }
}

// huddle-heart-vision-sec

.huddle-heart-vision-sec {
  background-color: #f6f7f9;

  .market-research-head {
    .main-heading {
      font-size: 28px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }

    .desc {
      font-size: 16px;
      margin-top: 20px;
      text-align: center;
      line-height: 20px;
    }

    .market-research-content-box {
      margin-top: 35px;

      .market-research-content {
        padding: 20px;
        background-color: white;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-bottom: 7px solid #35b6ff;
        min-height: 370px;

        .number-area {
          display: block;
          font-size: 50px;
          font-weight: 700;
          line-height: 1.4;
          color: #e92392;
        }

        h5 {
          margin-bottom: 10px;
          font-weight: 600;
        }

        .para {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }

  .market-research-img {
    text-align: center;
    animation: 4s ease-in-out infinite floatY;
    margin-top: 5%;

    img {
      width: 50%;
    }
  }
}

// huddle-heart-fetaures

.huddle-heart-features-sec {
  .column {
    padding: 0px 20px;
  }

  .sec-title {
    margin-bottom: 50px;

    .main-heading {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }
  }

  .pt-110 {
    padding-top: 110px;
  }

  .pt-60 {
    padding-top: 60px;
  }

  .mb-90 {
    margin-bottom: 90px;
  }

  .huddle-heart-features-card {
    position: relative;
    display: block;
    padding: 20px 25px 50px;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.07);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    cursor: pointer;
    min-height: 454px;

    &:hover {
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);

      &::before {
        transform: scale(1);
        transition: all 300ms ease;
      }

      .read-more-box .more-link {
        background-color: #f74883;
        color: white;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      border-bottom: 2px solid #35b6ff;
      transform: scale(0);
      transition: all 300ms ease;
    }

    .huddle-heart-features-icon {
      margin-bottom: 25px;

      img {
        width: 25%;
      }
    }

    .card-heading {
      font-weight: 600;
    }

    .desc {
      padding-top: 10px;
    }

    .read-more-box {
      position: absolute;
      bottom: -28px;
      left: 0;
      width: 100%;
      display: block;

      .more-link {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 53px;
        border-radius: 50%;
        background: #ffffff;
        font-size: 32px;
        color: #f74883;
        box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05),
          0px 0px 35px 0px rgba(0, 0, 0, 0.05);
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;

        &:hover {
          color: white !important;
        }
      }
    }
  }
}

// improved-safety-sec

.improved-safety-sec {
  .huddle-heart-technology-head {
    .main-heading {
      font-size: 34px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 20px;
      color: black;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }

    .desc {
      font-size: 18px;
      margin-top: 15px;
      text-align: center;
      line-height: 30px;
      color: white;
    }
  }

  .technology-content-area {
    position: relative;
    border-top: 1px solid #e1e1e1;
    transition: all 0.3s ease 0s;
    margin-top: 3%;

    &::before {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      z-index: 1;
      left: 0;
      top: -7.5px;
    }

    &::after {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      z-index: 1;
      right: 0;
      top: -7.5px;
    }

    .technology-card {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        background: #35b6ff;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        top: -7.5px;
        z-index: 1;
      }

      &::after {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        top: 0;
        width: 1px;
        background: #e1e1e1;
        height: 100px;
        z-index: -11;
      }

      .huddle-heart-technology-card-content {
        padding-top: 100px;
        text-align: center;

        .number-area {
          font-size: 42px;
          font-weight: 800;
          line-height: 80px;
          text-align: center;
          width: 80px;
          height: 80px;
          color: #ffffff;
          margin: 0 auto;
          border-radius: 50%;
          background-image: linear-gradient(90deg, #35b6ff 0%, #e92392 100%);
        }

        .content-txt {
          padding: 20px 10px 0px 10px;

          .desc {
            line-height: 25px;
            font-size: 16px;
          }
        }
      }
    }

    .technology-card-style1 {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        background: #e92392;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        top: -7.5px;
        z-index: 1;
      }

      &::after {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        top: 0;
        width: 1px;
        background: #e1e1e1;
        height: 100px;
        z-index: -11;
      }

      .technology-card-content {
        padding-top: 100px;
        text-align: center;

        .number-area {
          font-size: 42px;
          font-weight: 800;
          line-height: 80px;
          text-align: center;
          width: 80px;
          height: 80px;
          color: #ffffff;
          margin: 0 auto;
          border-radius: 50%;
          background-image: linear-gradient(90deg, #35b6ff 0%, #e92392 100%);
        }

        .content-txt {
          padding: 20px 15px 0px 15px;

          .desc {
            line-height: 25px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

// enhanced-communication

.enhanced-communication-sec {
  background-color: #f5fbff;

  .enhanced-communication-main-head {
    .main-heading {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 20px;
      color: black;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }
  }

  .enhanced-img {
    width: 70%;
    min-height: 620px;
    background-color: #282828;
    border-radius: 37px;
    box-shadow: inset 0 -8px 20px rgba(0, 0, 0, 0.05),
      1px 12px 44px rgba(0, 0, 0, 0.01), 2px 23px 64px rgba(0, 0, 0, 0.04);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 95%;
    }

    &::before {
      content: "";
      position: absolute;
      height: 1px;
      background-color: #f74f88;
      width: 100%;
      top: 11%;
      left: -101%;
    }

    &::after {
      content: "";
      position: absolute;
      height: 1px;
      background-color: #f74f88;
      width: 100%;
      top: 11%;
      right: -101%;
    }
  }

  .enhanced-communication-card {
    margin-top: 60%;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    min-height: 348px;
    position: relative;
    transition: all 300ms ease;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      background-color: #f74f88;
      left: 0;
      right: 0;
      width: 1px;
      top: -52%;
      height: 52%;
      margin: 0 auto;
    }

    .enhanced-communication-icon {
      box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05),
        0px 0px 35px 0px rgba(0, 0, 0, 0.05);
      width: 60px;
      height: 60px;
      border-radius: 50%;
      text-align: center;
      line-height: 60px;
      margin-bottom: 15px;

      img {
        width: 60%;
      }
    }

    .desc {
      line-height: 25px;
    }
  }
}

// our-works-sample
.our-works-sample {
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/Huddle-heart/works/work-banner.png");
  background-size: cover;
  // background-attachment:fixed;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.3;
  }
}

// huddle-heart-impacts-sec

.huddle-heart-impacts-sec {
  background-color: whitesmoke;

  .huddle-heart-impacts-img {
    margin-top: 10%;

    img {
      min-height: 300px;
    }
  }

  .huddle-heart-impacts-head {
    .main-heading {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 20px;
      color: black;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }
  }

  .huddle-heart-impacts-card {
    border: 2px dashed #d9d9d9;
    border-radius: 10px;
    text-align: center;
    position: relative;
    padding: 15px;
    z-index: 1;
    min-height: 356px;

    .huddle-heart-impacts-icon {
      width: 75px;
      height: 75px;
      background-color: white;
      box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05),
        0px 0px 35px 0px rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      margin: 0 auto;
      margin-bottom: 15px;
      line-height: 75px;

      img {
        width: 60%;
      }
    }

    .desc {
      font-size: 16px;
      line-height: 25px;
    }
  }
}

// challenges-and-solutions-huddle-heart

.challenges-lessons-huddle-heart {
  background-color: whitesmoke;

  .challenges-lessons-main-head {
    .main-heading {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }
  }

  .challenges-lessons-img {
    text-align: center;
    // margin-top: 5%;

    img {
      width: 100%;
    }
  }

  .challenges-lessons-content {
    margin-top: 8%;

    .challenges-lessons-card {
      background: #ffffff;
      box-shadow: 0px 2px 20px rgba(24, 16, 16, 0.07);
      padding: 15px 15px 15px 40px;
      min-height: 136px;
      border-radius: 5px;
      transition: 0.3s;
      margin-bottom: 25px;
      position: relative;

      .challenges-lessons-icon {
        position: absolute;
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        border-radius: 50%;
        background: #ffffff;
        box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05),
          0px 0px 35px 0px rgba(0, 0, 0, 0.05);
        transition: all 300ms ease;
        line-height: 60px;
        left: -4%;
        top: 30%;

        img {
          width: 60%;
        }
      }

      .card-heading {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .desc {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .challenges-solutions-card {
    background: #ffffff;
    box-shadow: 1px 12px 25px rgba(15, 51, 107, 0.1);
    position: relative;
    // border-left: 8px solid transparent;
    transition: 0.3s;
    margin-bottom: 30px;
    padding: 25px;
    border-radius: 16px;
    min-height: 550px;
    cursor: pointer;

    .main-head {
      text-align: center;
      font-size: 22px;
      font-weight: 600;
    }

    .challenges-solutions-card-icon {
      text-align: center;

      img {
        width: 20%;
      }
    }

    .problem-content {
      padding-top: 20px;

      h6 {
        font-size: 18px;
      }
    }

    .solution-content {
      padding-top: 20px;

      h6 {
        font-size: 18px;
      }
    }

    .number-card {
      content: "";
      position: absolute;
      background-color: #35b6ff;
      color: white;
      font-size: 25px;
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-weight: 700;
      top: 25%;
      left: -5%;
    }

    .desc {
      line-height: 25px;
      font-size: 16px;
    }

    &:hover {
      // border-color: #e92392;
      transform: translateY(-5px);
    }
  }

  .mt_76 {
    margin-top: 76px;
  }
}

// results-growth-sec

.results-growth-huddle-heart {
  background-color: #ecf2f6;

  .results-growth-head {
    .main-heading {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }
  }

  .results-growth-img {
    margin-right: 5%;
  }

  .results-growth-content {
    .results-growth-content-box {
      margin-top: 15px;
      background-color: white;
      padding: 30px;

      .results-growth-content-list {
        li {
          position: relative;
          padding-left: 35px;
          line-height: 30px;
          margin-bottom: 12px;

          .checkicn {
            content: "";
            position: absolute;
            left: 0;
            top: 5px;
            font-size: 22px;
            color: #35b6ff;
          }
        }
      }
    }
  }
}

// future-prospects
.future-prospects-huddle-heart {
  background-color: #e7e7e7c2;
  min-height: 450px;
  width: 100%;

  .future-prospects-head {
    background-color: white;
    padding: 80px 30px 30px 30px;
    min-height: 450px;

    .main-heading {
      font-size: 28px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #fcaf17;
        bottom: 0;
        border-radius: 100px;
      }
    }

    .future-prospects-content-box {
      padding-top: 20px;

      .future-prospects-content-list {
        li {
          position: relative;
          padding-left: 35px;
          line-height: 30px;
          margin-bottom: 12px;

          .checkicn {
            content: "";
            position: absolute;
            left: 0;
            top: 5px;
            font-size: 22px;
            color: #35b6ff;
          }
        }
      }
    }
  }

  .future-prospects-img {
    margin-left: 5%;
    margin-top: 10%;
  }
}

// conclusion

.huddle-heart-conclusion-sec {
  position: relative;
  z-index: 1;

  width: 100%;
  min-height: 250px;
  background-image: url("../images/casestudy/Huddle-heart/conclusion/conclusion.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.7;
    z-index: 1;
  }

  .conclusion-sec-head {
    z-index: 2;

    .main-heading {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;
      color: white;
    }
  }

  .conclusion-content-box {
    .desc {
      color: white;
      text-align: center;
      font-size: 18px;
    }
  }
}

// how-we-design-date

.how-we-design-date {
  .how-we-design-main-heading {
    h1 {
      font-size: 84px;
      color: #1d1e22;
      font-weight: 600;
    }
  }

  .how-we-design-date-img {
    width: 342px;
    height: 712px;
    background-color: #282828;
    border-radius: 37px;
    box-shadow: inset 0 -8px 20px rgba(0, 0, 0, 0.05),
      1px 12px 44px rgba(0, 0, 0, 0.01), 2px 23px 64px rgba(0, 0, 0, 0.04);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 318px;
      height: 686px;
    }
  }

  .how-we-design-date-content {
    padding-top: 144px;

    h3 {
      font-size: 64px;
      color: #1d1e22;
      font-weight: 600;
    }

    .desc {
      font-size: 36px;
      color: #1d1e22;
      line-height: 50px;
      font-weight: 600;
      padding-top: 30px;
    }
  }
}

.profile-banner-sec {
  width: 100%;
  min-height: 550px;
  background-image: linear-gradient(#c390ae, #578ea1);

  .screen-mob {
    width: 80%;
    min-height: 712px;
    background-color: #282828;
    border-radius: 37px;
    box-shadow: inset 0 -8px 20px rgba(0, 0, 0, 0.05),
      1px 12px 44px rgba(0, 0, 0, 0.01), 2px 23px 64px rgba(0, 0, 0, 0.04);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mt-80 {
    margin-top: 80px;
  }
}

.usability-testing-sec {
  .usability-heading {
    h3 {
      font-size: 34px;
      color: #1d1e22;
      font-weight: 600;
    }
  }

  .usability-testing-content {
    .desc {
      font-size: 20px;
      color: #1d1e22;
    }

    .usability-list-box {
      margin-top: 25px;

      .usability-list {
        h5 {
          font-size: 22px;
        }

        li {
          font-size: 18px;
          color: #1d1e22;
        }
      }
    }
  }
}

.huddle-heart-intro-sec {
  .intro-head {
    h4 {
      font-size: 28px;
      line-height: 42px;
    }
  }

  .huddle-heart-intro-content {
    background-image: linear-gradient(45deg, #35b6ff 0%, #e92392 100%);
    padding: 30px;
    border-radius: 20px;
    // margin-top: 1%;

    .main-heading {
      font-size: 22px;
      color: white;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .huddle-heart-intro-list {
      padding-left: 25px;

      li {
        color: #ddd8d8;
        list-style: disc;
        font-size: 14px;
      }
    }

    .desc {
      font-size: 18px;
      color: #1d1e22;
      font-weight: 500;
      margin-top: 10px;
      color: white;
    }
  }

  .huddle-heart-intro-img {
    margin-right: 5%;

    img {
      border-radius: 20px;
      min-height: 547px;
    }

    .main-intro-img {
      position: absolute;
      z-index: 1;

      img {
        width: 40%;
      }
    }

    .heart-shape-img {
      position: absolute;
      z-index: -1;
      width: 35%;
      top: 70px;
      left: 7%;
    }

    .eclipise-shape-img {
      position: absolute;
      z-index: -1;
      top: 100px;
      left: 0%;
      width: 90%;
    }
  }
}

// plywood-bazar css

// plywood-bazar-banner-sec

.plywood-bazar-banner-sec {
  background-color: #f5f5f5;
  min-height: 550px;

  .plywood-bazar-banner-content {
    margin-top: 5%;

    .sub-head {
      color: #000000;
      font-weight: 600;
      font-size: 15px;
    }

    .logo-img img {
      width: 45%;
    }

    .mt-30 {
      margin-top: 30px;
    }

    .main-heading {
      font-size: 26px;
      margin-bottom: 10px;
      font-weight: 500;
      margin-top: 10px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 14px;
      line-height: 25px;
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;
      background-color: #b08218;
      border: 1px solid #b08218;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid #b08218;
        background: transparent;
        color: #b08218 !important;
      }
    }

    .plywood-bazar-intro-list-box {
      text-align: center;

      .plywood-bazar-intro-list {
        text-align: left;

        li {
          font-size: 14px;
        }
      }
    }
  }

  .plywood-bazar-banner-img {
    text-align: end;
    margin-top: 5%;
  }
}

// plywood-bazar-about-us-sec

.plywood-bazar-about-us {
  // background:linear-gradient(90deg,#faf6fd 0%,#f3e5fa 100%);
  background-color: #edf2f9;
  position: relative;

  .about-content-box {
    margin-top: 8%;

    .sub-heading {
      color: #b08218;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .main-heading {
      font-weight: 500;
      font-size: 42px;
      line-height: 56px;
      letter-spacing: 0.02em;
      color: #212529;
    }
  }

  .about-content-list-box {
    padding-top: 10px;

    .about-content-list {
      li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 35px;
        font-size: 15px;
        line-height: 25px;

        h5 {
          font-size: 17px;
          font-weight: 600;
          margin-bottom: 0px;
        }

        .circle-check {
          content: "";
          position: absolute;
          color: #b08218;
          left: 0;
          font-size: 20px;
          top: 0;
          transform: translate(0px, 10px);
        }
      }
    }
  }

  .bg-shape-1 {
    position: absolute;
    top: 0;
    left: 0%;

    img {
      width: 40%;
    }
  }

  .bg-shape-2 {
    position: absolute;
    bottom: 0;
    left: 0%;

    img {
      width: 60%;
    }
  }

  .bg-shape-3 {
    position: absolute;
    bottom: 0%;
    right: 0%;
    text-align: end;

    img {
      width: 60%;
    }
  }
}

// our-client-requiement
.our-client-requirement {
  background-color: #f9f9f9;

  .about-client-area {
    .main-head {
      font-size: 34px;
      color: #b18319;
      font-style: italic;
      // font-weight: 600;
    }

    .desc {
      // color: #b18319;
      font-size: 18px;
      padding-top: 1%;
    }

    .client-hand-shake {
      padding-top: 4%;
    }
  }

  .client-req-area {
    margin-left: 5%;

    .main-head {
      font-size: 34px;
      color: #b18319;
      // font-weight: 600;
    }

    .client-req-list {
      padding-top: 1%;

      li {
        // color: white;
        border-left: 4px solid #c79d3b;
        margin-bottom: 20px;
        padding-left: 15px;
        font-size: 18px;
      }
    }
  }
}

// mission-vision-plywoodbazar

// landing page css

.main-header {
  background-color: #020202;
  padding: 20px 0px;
}

.main-logo img {
  width: 45%;
}

.header-contact-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-contact-area .header-contact-list {
  margin: 0px !important;
  display: flex;
  align-items: center;
}

.a-i-c {
  align-items: center;
}

.header-contact-area .header-contact-list li {
  margin-left: 20px;
}

.header-contact-area .header-contact-list li a {
  color: white;
  font-size: 18px;
  transition: all 0.3s;
}

.header-contact-area .header-contact-list li a svg {
  font-size: 18px;
  margin-right: 5px;
}

.header-contact-area .header-contact-list li a:hover {
  color: #fcaf17;
  text-decoration: none !important;
}

.req-quote-buttn {
  animation: pulse-animation 2s infinite;
  background-color: #fcaf17;
  border-radius: 2px;
  box-shadow: 0 0 0 #fcaf17;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 24px;
  transition: all 0.5s;
  margin-left: 20px;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 #fcaf17;
  }

  100% {
    box-shadow: 0 0 0 20px transparent;
  }
}

// .main-banner-sec {
//   width: 100%;

//   background-color: #000;
//   min-height: 400px;

.mission-or-vision-plywood {
  position: relative;
  background-color: #f6f6f6;
  min-height: 530px;

  .mission-vision-wrapper {
    position: relative;

    .main-banner-sec .main-banner-content .main-heading {
      font-size: 58px;
      font-weight: 600;
      color: white;
      margin-bottom: 10px;
    }

    .main-banner-content .main-heading span {
      color: #fcaf17;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #fcaf17;
    }

    .main-banner-content {
      margin-top: 6%;
      /* padding-left: 2%; */
    }

    .main-banner-content h3 {
      color: #fcaf18;
    }

    .main-banner-img {
      text-align: end;
    }

    .main-banner-img img {
      width: 80%;
    }

    .mt-25 {
      margin-top: 25px;
    }

    .quick-enquiry-buttn {
      background-color: #fcaf18;
      padding: 12px 24px;
      border: 1px solid #fcaf17;
      color: white;
      font-weight: 600;
      border-radius: 5px;
    }

    .quick-enquiry-buttn:hover {
      color: white !important;
    }

    .main-banner-sec .main-banner-content .desc {
      color: #e5e5e5;
    }

    .contact-form-box {
      padding: 25px 25px 15px 25px;
      z-index: 99;
      background: #fff;
      /* box-shadow: 3px 4px 22px #000; */
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      width: 100%;
      margin-top: 12%;
      border-radius: 5px;
      animation: updown 4s ease-in-out infinite;
    }

    @keyframes updown {
      0% {
        transform: translateY(0px);
      }

      50% {
        transform: translateY(-20px);
      }

      100% {
        transform: translateY(0px);
      }
    }

    .contact-form-box .form-head {
      font-size: 18px;
      font-weight: 700;
      text-transform: capitalize;
    }

    .contact-form-box .desc {
      font-size: 14px;
      font-weight: 600;
    }

    .contact-form-box .contact-form-body input {
      font-size: 14px;
    }

    .contact-form-box .contact-form-body textarea {
      font-size: 14px;
    }

    input:focus {
      box-shadow: none !important;
      border-color: #fcaf17 !important;
      outline: none !important;
    }

    .enquiry-now-buttn {
      background-color: #fcaf17;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      font-size: 16px;
      display: block;
      text-align: center;
      transition: all 0.4s;
      position: relative;
    }

    .enquiry-now-buttn::after {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      width: 300px;
      height: 200px;
      background-color: #333;
      border-color: transparent;
      border-radius: 50%;
      transform: translate(-40px, -80px) scale(0.1);
      opacity: 0;
      z-index: -1;
      transition: all 0.5s;
    }

    .enquiry-now-buttn:hover {
      background-color: #020202;
      color: white;
    }

    .message-textarea:focus {
      box-shadow: none !important;
      border-color: #fcaf17 !important;
      outline: none !important;
    }

    .py-40 {
      padding: 40px 0px;
    }

    /* what-we-offer-sec */

    .what-we-offer-sec {
      background-color: #171717;
    }

    .what-we-offer-heading .sub-head {
      color: #fcaf17;
    }

    .what-we-offer-heading .main-head {
      color: white;
      font-size: 26px;
      font-weight: 700;
    }

    .what-we-offer-heading .main-head span {
      color: #fcaf17;
    }

    .what-we-offer-heading-content .desc {
      color: #e5e5e5;
      margin: 0px !important;
    }

    .what-we-offer-card {
      background-color: white;
      border-radius: 5px;
      padding: 25px;
      margin-bottom: 25px;
      min-height: 372px;
    }

    .what-we-offer-icon {
      margin-bottom: 20px;
    }

    .what-we-offer-content h3 {
      font-size: 18px;
      font-weight: 600;
    }

    .get-quote-buttn {
      position: relative;
      background-color: #fcaf17;
      color: white;
      padding: 8px 10px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 600;
    }

    .get-quote-buttn:hover {
      color: white;
    }

    /* .get-quote-buttn:hover::after
{
  opacity: .3;
    transform-origin: 100px 100px;
    transform: scale(1) translate(-10px,-70px);
} */

    .why-choose-us-sec {
      background-color: #000;
    }

    .why-choose-us-heading-area {
      text-align: center;
    }

    .why-choose-us-heading-area .sub-heading {
      font-size: 16px;
      color: #fcaf17;
    }

    .why-choose-us-heading-area .main-heading {
      font-size: 34px;
      font-weight: 700;
      color: white;
    }

    .why-choose-us-heading-area .main-heading span {
      color: #fcaf17;
    }

    .why-choose-us-card {
      border: solid 1px #7e7e7e;
      border-radius: 0px;
      padding: 55px 20px;
      text-align: center;
      background: rgb(38, 38, 38);
      margin-bottom: 20px;
      background: linear-gradient(
        180deg,
        rgb(38, 38, 38) 0%,
        rgb(67, 67, 67) 84%,
        rgb(69, 69, 69) 100%
      );
    }

    .why-choose-us-content h5 {
      color: white;
      margin-top: 25px;
      font-size: 23px;
    }

    .mt-35 {
      margin-top: 35px;
    }

    .app-development-process {
      background-color: #171717;
    }

    .app-development-main-head h3 {
      font-size: 45px;
      color: white;
      text-transform: capitalize;
      font-weight: 700;
      margin-bottom: 50px;
    }

    .app-development-main-head h3 span {
      color: #fcaf17;
    }

    .ptb-100 {
      padding: 80px 0px;
    }

    /* industries-we-have worked with */
    .industries-work-with-main-head h2 {
      color: white;
      font-size: 34px;
    }

    /* success-delivered-sec */
    .success-delivered-sec {
      background-color: #000;
    }

    .success-delivered-main-head .main-heading {
      color: white;
      font-size: 34px;
      font-weight: 700;
    }

    .success-delivered-main-head .sub-heading {
      color: #fcaf17;
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
    }

    .success-delivered-sec .clients-logo {
      margin: 10px 0px;
    }

    .success-delivered-content .desc {
      font-size: 18px;
      color: white;
    }

    /* lets-connect-sec */

    .letconnects-sec {
      width: 100%;
      margin: 0;
      padding: 56px 0;
      overflow: hidden;
      background-image: url("../../assets/images/LandingPage/lets-connect-sec/connect.jpg");
      background-size: cover;
      background-repeat: no-repeat;
    }

    .content-part .cnct-heading {
      width: 100%;
      display: block;
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 15px;
      color: #fff;
      font-weight: 500;
    }

    .content-part .cnct-heading span {
      font-weight: 300;
    }

    .content-part p {
      font-size: 18px;
      line-height: 24px;
      display: block;
      color: #fff;
    }

    .letconnects-sec input {
      margin: 10px 0px;
    }

    .letconnects-sec input[type="submit"] {
      background-color: #ba464a;
      border-color: transparent;
      font-size: 16px;
      border-radius: 5px;
      color: white;
      padding: 7px 20px;
      text-transform: uppercase;
    }

    &::after {
      position: absolute;
      content: "";
      background-color: #c5a766;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: -48px;
    }
  }

  .left-side-box {
    position: relative;
    font-size: 16px;
    padding: 25px;
    border-radius: 10px;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-top: 10%;
  }

  .left-side-box::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    right: -17px;
    top: 40px;
    box-sizing: border-box;
    border: 10px solid black;
    border-color: transparent transparent #fdfdfd #fdfdfd;
    transform-origin: 0 0;
    transform: rotate(-132deg);
    box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 10%);
  }

  .right-side-box {
    position: relative;
    font-size: 16px;
    padding: 25px;
    border-radius: 10px;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-top: 63%;
  }

  .right-side-box::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    right: unset;
    left: 0px;
    top: 20px;
    transform: rotate(42deg);
    box-shadow: -3px 3px 3px 0 rgb(0 0 0 / 6%);
    box-sizing: border-box;
    border: 10px solid black;
    border-color: transparent transparent #fdfdfd #fdfdfd;
    transform-origin: 0 0;
  }

  .middle-box {
    display: flex;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin: 0 auto;
    border: 6px solid #707070;
    background-color: #c5a766;
    color: white;
    position: relative;
    margin-bottom: 60px;
  }

  .middle-box1 {
    display: flex;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin: 0 auto;
    border: 6px solid #707070;
    background-color: #c5a766;
    color: white;
    position: relative;
  }

  .middle-box1::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border: 1px dashed #707070;
    width: 2px;
    height: 62px;
    top: -69px;
    margin: 0 auto;
  }

  .middle-box1::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border: 1px dashed #707070;
    width: 2px;
    height: 62px;
    bottom: -69px;
    margin: 0 auto;
  }

  .mission-vision-left-shape {
    position: absolute;
    left: 0;
    bottom: 30px;
  }

  .mission-vision-right-shape {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.solution-given-sec {
  background-color: #f3f4f8 !important;

  .solution-given-content {
    .sub-heading {
      color: #b08218;
      text-transform: uppercase;
      margin-bottom: 10px;
      font-weight: 600;
    }

    .main-heading {
      color: #b08218;
      font-size: 34px;
    }

    .desc {
      margin-bottom: 25px;
    }

    .solution-given-list-box {
      .solution-given-list {
        padding-left: 15px;
        margin-top: 15px;

        li {
          padding-left: 10px;
          list-style: decimal;

          h5 {
            font-size: 18px;
          }

          &::marker {
            color: #b08218;
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .solution-given-img {
    text-align: end;
    margin-top: 35%;

    img {
      animation: rotatefull 5s infinite ease-in;
    }
  }

  @keyframes rotatefull {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
}

.plywoodbazar-features-sec {
  background-color: #edf2f9;

  ::-webkit-scrollbar {
    width: 4px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b08218 !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .plywoodbazar-features-content {
    .plywoodbazar-features-head {
      .main-heading {
        font-size: 38px;
        color: #b08218;
        margin-bottom: 20px;
      }

      .sub-heading {
        color: #b08218;
      }
    }

    .key-features-list-box {
      height: 525px;
      overflow-y: auto;

      .key-features-list {
        height: 480px;
        overflow-y: auto;

        padding-left: 25px;

        li {
          list-style-type: decimal;
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px dashed #b08218;

          &::marker {
            color: #b08218;
            font-size: 21px;
          }

          .heading {
            font-size: 20px;
            color: #b08218;
            font-weight: 500;
          }
        }
      }
    }
  }

  .plywoodbazar-features-img {
    margin-top: 10%;
    animation: 4s ease-in-out infinite floatY;
    transition: 4s;
  }

  .j-c-sb {
    justify-content: space-between;
  }
}

.plywoodbazar-result-sec {
  background-color: #e7e7e7;

  .plywoodbazar-result-main-head {
    text-align: center;

    .main-heading {
      font-size: 38px;
      color: #b08218;
      margin-bottom: 20px;
    }

    .desc {
      color: black;
      font-weight: 600;
      font-size: 17px;
      width: 80%;
      margin: 0 auto;
    }
  }

  .plywoodbazar-result-card {
    transform: scale(0.85);
    transition: all 0.5s;
  }

  .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
    /* padding: 20px; */
    padding: 20px 10px;
  }

  .swiper-slide.swiper-slide-active .plywoodbazar-result-card {
    overflow: hidden;
    transform: scale(1.05);
    transition: all 0.5s;
  }
}

.our-work-screens {
  background-color: #edf2f9;
  min-height: 500px;
  background-image: url("../images/casestudy/plywoodbazar/our-work-screens/work-screens.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    background-color: #2c2c2c;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 2;
    top: 0;
  }

  .our-work-screens-main-head {
    position: absolute;
    width: 23%;

    .main-heading {
      color: rgb(136 104 30);
      font-size: 48px;
      font-weight: 600;

      span {
        color: #1f173a;
      }
    }
  }
}

// RoCare-India-CaseStudy

.RoCare-banner-sec {
  width: 100%;
  min-height: 550px;
  // background: rgb(245, 245, 245);
  background: linear-gradient(
    90deg,
    rgba(245, 245, 245, 1) 20%,
    rgba(139, 215, 215, 1) 100%
  );

  .RoCare-banner-content {
    margin-top: 8%;

    .sub-head {
      color: #000000;
      font-weight: 600;
      font-size: 17px;
    }

    .logo-img img {
      width: 80%;
    }

    .mt-30 {
      margin-top: 30px;
    }

    .main-heading {
      font-size: 27px;
      margin-bottom: 15px;
      font-weight: 500;
      margin-top: 15px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 16px;
      line-height: 25px;
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;
      background-color: #116566;
      border: 1px solid #116566;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid #116566;
        background: transparent;
        color: #116566 !important;
      }
    }

    .RoCare-intro-list-box {
      text-align: center;

      .RoCare-intro-list {
        text-align: left;

        li {
          font-size: 14px;
        }
      }
    }
  }

  .RoCare-banner-img {
    text-align: end;
    margin-top: 5%;
  }
}

.Rocare-aboutus {
  background-color: #fffaf7;

  .Rocare-aboutus-main-head {
    text-align: center;

    .main-heading {
      text-transform: capitalize;
      font-size: 45px;
      position: relative;

      &::before {
        content: "Service";
        -webkit-text-stroke: 2px #1f1f1f;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        position: absolute;
        font-size: 80px;
        bottom: 0;
        -webkit-text-fill-color: transparent;
        opacity: 0.1;
        z-index: 0;
        width: 100%;
      }
    }
  }
}

.client-requirements-sec {
  background-color: #116566;

  .client-requirements-main-head {
    background-color: white;
    padding: 110px 50px 105px 50px;

    .main-heading {
      text-transform: capitalize;
      font-size: 36px;
      position: relative;
      font-weight: 600;

      &::before {
        content: "Client";
        -webkit-text-stroke: 2px #1f1f1f;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        position: absolute;
        font-size: 70px;
        bottom: 10px;
        -webkit-text-fill-color: transparent;
        opacity: 0.1;
        z-index: 0;
        width: 100%;
      }
    }

    .desc {
      font-size: 18px;
    }
  }

  .client-requirements-img {
    text-align: center;
    padding: 20px 0px;
  }
}

.Rocare-features-sec {
  position: relative;
  width: 100%;
  background-image: url("../images/casestudy/RoCareIndia/features/features-bg.png");
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;
  background-repeat: repeat;

  .position-relative-z-index-1 {
    position: relative;
    z-index: 1;
  }

  .overlay-rocare-features {
    content: "";
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .Rocare-features-main-head {
    text-align: center;

    .main-heading {
      text-transform: uppercase;
      font-size: 34px;
      font-weight: 600;
    }

    .desc {
      color: black;
    }
  }

  .content-heading {
    .features-heading {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 25px;
    }
  }

  .features-content {
    .white-box {
      margin-bottom: 20px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
      padding: 20px;
      background: #fff;
      min-height: 210px;

      h5 {
        margin-bottom: 15px;

        img {
          width: 32px;
          margin-right: 5px;
        }
      }

      .features-list {
        padding-left: 10px;

        li {
          font-size: 17px;
          position: relative;
          margin-top: 6px;
          padding-left: 20px;
          color: black;

          &::before {
            content: "-";
            position: absolute;
            color: #116566;
            left: 0;
            top: 0;
            font-size: 24px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

.rocare-customer-screens {
  background-color: #116566;

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination-bullet-active {
    opacity: 1 !important;
    background: white !important;
    width: 40px !important;
    height: 5px !important;
    margin: 7px 5px !important;
    border-radius: 10px !important;
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    width: 20px;
    height: 5px !important;
    margin: 7px 5px !important;
    background: #ffff !important;
    border-radius: 10px !important;
  }
}

.rocare-challenges-sec {
  .rocare-challenges-content {
    margin-top: 10%;

    .main-heading {
      text-transform: capitalize;
      font-size: 42px;
      position: relative;
      font-weight: 600;
      margin-bottom: 25px;

      &::before {
        content: "Client";
        -webkit-text-stroke: 2px #1f1f1f;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        position: absolute;
        font-size: 75px;
        bottom: 10px;
        -webkit-text-fill-color: transparent;
        opacity: 0.1;
        z-index: 0;
        width: 100%;
      }
    }

    .sub-heading {
      font-size: 18px;
    }

    .desc {
      margin-bottom: 25px;
    }
  }
}

// NikkyPore-casestudy

.NikkyPore-banner-sec {
  width: 100%;
  min-height: 550px;
  background: rgb(245, 245, 245);
  background: linear-gradient(90deg, rgba(245, 245, 245, 1) 0%, #017896b0 100%);

  .NikkyPore-banner-content {
    margin-top: 6%;

    .sub-head {
      color: #000000;
      font-weight: 600;
      font-size: 17px;
    }

    .mt-30 {
      margin-top: 30px;
    }

    .main-heading {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 500;
      margin-top: 15px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 16px;
      line-height: 25px;
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;
      background-color: #017896;
      border: 1px solid #017896;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid #017896;
        background: transparent;
        color: #017896 !important;
      }
    }

    .NikkyPore-intro-list-box {
      text-align: center;

      .NikkyPore-intro-list {
        text-align: left;

        li {
          font-size: 14px;
        }
      }
    }
  }

  .NikkyPore-banner-img {
    text-align: end;
    margin-top: 7%;
    animation: 4s ease-in-out infinite floatY;
  }
}

.Nikkypore-project-summary {
  background-color: #f7f7f7;

  .pb-50 {
    padding-bottom: 50px;
  }

  .Nikkypore-main-head {
    .main-heading {
      font-size: 34px;
      color: #222a41;
      font-weight: 600;
    }

    span {
      color: #017896;
    }
  }

  .Nikkypore-project-summary-img {
    text-align: center;
  }
}

.Nikkypore-goals-objectives {
  background-color: #e9e9e9;

  .Nikkypore-goals-objectives-main-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      // color: white;
      color: #222a41;
      font-weight: 600;
    }

    span {
      color: #222a41;
    }

    .desc {
      line-height: 25px;
      // color: white;
      color: #222a41;
    }
  }

  .goals-obj-content-box {
    margin-top: 20%;

    .goals-obj-content-card {
      padding: 20px;
      border-radius: 10px;
      background: #fff;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      min-height: 168px;
      margin-bottom: 25px;

      h5 {
        font-size: 18px;
      }

      .desc {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  .goals-obj-img-box {
    text-align: center;
  }
}

.Nikkypore-features-sec {
  position: relative;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/NikkyPore/features-sec/featuresbg.png");
  background-size: cover;
  background-attachment: fixed;

  .overlay-Nikkypore-features {
    content: "";
    position: absolute;
    // background: rgb(90 153 241 / 40%);
    background: rgb(233 233 233 / 55%);
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .position-relative-z-index-1 {
    position: relative;
    z-index: 1;
  }

  .features-sec-main-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      font-weight: 600;
    }
  }

  .customer-app-features-content {
    margin-top: 32%;

    .white-box {
      border: 1px solid #dedede;
      padding: 15px;
      margin-bottom: 20px;
      background-color: #fff;
      border-radius: 10px;
      min-height: 100px;
      display: flex;
      align-items: center;

      .featured-list {
        display: flex;
        align-items: center;

        li {
          .inner-featured-list {
            margin-left: 15px;

            h4 {
              font-size: 17px;
              margin-bottom: 5px;
            }

            .desc {
              line-height: 20px;
              font-size: 15px;
            }
          }

          span {
            display: inline-block;
            background: linear-gradient(
              190deg,
              rgb(244 137 4) 0%,
              rgb(90 154 239) 100%
            );
            width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            border-radius: 5px;
            margin-bottom: 0;
            color: white;
          }
        }
      }
    }
  }
}

.challenges-and-solutions-sec {
  .challenges-and-solutions-main-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      // color: #222A41;
      font-weight: 600;
    }

    span {
      color: #222a41;
    }

    .desc {
      line-height: 25px;
    }
  }

  .challenges-sec {
    h4 {
      font-size: 23px;
      font-weight: 600;
    }

    .challenges-content-list {
      margin-top: 5%;

      li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 15px;

        .desc {
          font-size: 15px;
          line-height: 25px;
        }

        svg {
          position: absolute;
          left: 0;
          color: #017896;
          top: 2px;
        }
      }
    }
  }
}

.visual-designs-sec {
  .visual-designs-main-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      font-weight: 600;
    }

    .desc {
      line-height: 25px;
      margin-bottom: 40px;
    }
  }

  .visual-designs-wrapper {
    background-color: #4192a7;
    // background-color: #017896;
    padding: 120px 0px 30px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 100px;
      background: url("../images/casestudy/NikkyPore/visual-designs/portfolio-design.png");
      background-size: cover;
      background-repeat: repeat-x;
    }

    .visual-designs-slide {
      img {
        width: 50%;
      }
    }
  }
}

// deyor-casestudy

.deyor-main-banner-sec {
  // background-color: #f8f8ff;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/Deyor/main-banner/deyor-main-banner-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .deyor-main-banner-content {
    margin-top: 15%;

    .deyor-main-banner-logo {
      margin-bottom: 15px;
    }
h1
{
  font-size: 28px;
  font-weight: 600;
}
    .desc {
      color: #222a41;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
    }

    .deyor-content-list {
      display: flex;
      flex-wrap: wrap;

      li {
        flex-basis: 50%;
        margin-top: 20px;
        font-size: 18px;

        h5 {
          font-size: 18px;
          margin-bottom: 0;
          line-height: 30px;
        }
      }
    }

    .mt-30 {
      margin-top: 30px;
    }

    .visit-site-buttn {
      background-image: linear-gradient(
        to right,
        #fc4f54 0%,
        #ff8a12 50%,
        #fa534f 100%
      );
      padding: 10px 32px;
      color: white;
      position: relative;
      overflow: hidden;
      background-size: 200% auto;
      -webkit-transition: 0.5s;
      transition: all 0.5s;
      border-radius: 5px;
      border:none;

      &:hover {
        background-position: center right;
        color: white !important;
      }
    }
  }
}

.deyor-objective-sec {
  background-color: #f5f5f5;

  .j-c-sb {
    justify-content: space-between;
  }

  .deyor-objective-main-content-box {
    text-align: center;

    .main-heading {
      font-size: 34px;
      font-weight: 600;
      margin-bottom: 0px;
      position: relative;
      padding-bottom: 20px;

      &::after {
        content: "";
        background-color: #c4343c;
        position: absolute;
        width: 72px;
        height: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      span {
        color: #c4343c;
      }
    }

    .desc {
      font-size: 18px;
    }
  }

  .deyor-objective-img {
    margin-top: 10%;
  }

  .deyor-objective-content-box {
    margin-top: 25%;

    .deyor-objective-card {
      padding: 15px;
      background-color: white;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-bottom: 7px solid #c4343c;
      text-align: left;
      min-height: 210px;
      margin-bottom: 20px;

      .number-area {
        display: block;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.4;
        color: #c4343c;
      }

      .desc1 {
        font-size: 16px;
      }
    }
  }

  .deyor-about-us-wrapper {
    cursor: pointer;

    &:hover .deyor-about-us-image-box .deyor-about-us-main-img {
      img {
        transform: scale(1.1);
        transition: all 0.5s ease-in;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }
    }

    &:hover {
      .deyor-about-us-content .main-heading {
        transform: 0.9s ease-in-out;
        -webkit-transform: 0.9s ease-in-out;
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
      }
    }

    .deyor-about-us-image-box {
      position: relative;
      cursor: pointer;

      .deyor-about-us-main-img {
        position: absolute;
        overflow: hidden;

        img {
          // overflow: hidden;
          transform: scale(1);
          transition: all 0.5s ease-in;
        }
      }

      .aboutus-logo {
        position: absolute;
        width: 17%;
        right: 0;
        top: 0;
      }
    }

    .deyor-about-us-content {
      margin-top: 25%;
      cursor: pointer;

      .main-heading {
        font-size: 40px;
        font-weight: 700;
        color: #000;
        margin-bottom: 25px;
        display: inline-block;
        transform: 130ms ease-in-out, -webkit-transform 130ms ease-in-out;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
      }

      .desc {
        font-size: 22px;
        color: #212124;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .deyor-about-us-content-list {
        li {
          position: relative;
          padding-left: 35px;
          font-size: 18px;
          line-height: 35px;
          color: black;
          font-weight: 600;

          .list-bar {
            content: "";
            position: absolute;
            background-color: #e43051;
            width: 16px;
            height: 2px;
            left: 0;
            top: 20px;
          }
        }
      }
    }
  }
}

.deyor-methodology-sec {
  width: 100%;
  min-height: 550px;
  background: url("../images/casestudy/Deyor/methodology/methodology-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .j-c-se {
    justify-content: space-evenly;
  }

  .deyor-methodology-img {
    margin-top: 20%;

    .mt-10 {
      margin-top: 25%;
      width: 90%;
      text-align: center;
      margin-left: 5%;
    }
  }

  .deyor-methodology-main-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      font-weight: 600;
      margin-bottom: 0px;
      position: relative;
      padding-bottom: 20px;

      &::after {
        content: "";
        background-color: #c4343c;
        position: absolute;
        width: 72px;
        height: 4px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      span {
        color: #c4343c;
      }
    }
  }

  .deyor-methodology-content-box {
    .main-heading {
      color: #c4343c;
      font-weight: 600;
    }

    .sub-heading {
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: 600;
    }

    .deyor-features-content-box {
      .deyor-features-content-list {
        .content-list-li {
          margin-bottom: 10px;

          .deyor-features-inner-list {
            display: flex;

            li {
              svg {
                color: #c4343c;
                font-size: 24px;
                margin-right: 10px;
                margin-top: 5px;
              }

              h6 {
                margin-bottom: 0px;
              }

              p {
                font-size: 15px;
                line-height: 25px;
              }
            }
          }
        }
      }
    }

    .implementation-box {
      margin-top: 15%;
      background-color: white;
      padding: 15px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      margin-bottom: 20px;

      h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .deyor-features-content-list {
        .content-list-li {
          margin-bottom: 10px;

          .deyor-features-inner-list {
            display: flex;

            li {
              svg {
                color: #c4343c;
                font-size: 24px;
                margin-right: 10px;
                margin-top: 5px;
              }

              h6 {
                margin-bottom: 0px;
              }

              p {
                font-size: 14px;
                line-height: 25px;
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .outcomes-box {
      margin-top: 5%;
      background-color: white;
      padding: 15px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      min-height: 214px;
      margin-bottom: 20px;

      h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .deyor-features-content-list {
        .content-list-li {
          margin-bottom: 10px;

          .deyor-features-inner-list {
            display: flex;

            li {
              svg {
                color: #c4343c;
                font-size: 24px;
                margin-right: 10px;
                margin-top: 5px;
              }

              h6 {
                margin-bottom: 0px;
              }

              p {
                font-size: 14px;
                line-height: 25px;
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .implementation-box-1 {
      margin-top: 29%;
      background-color: white;
      padding: 15px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .deyor-features-content-list {
        .content-list-li {
          margin-bottom: 10px;

          .deyor-features-inner-list {
            display: flex;

            li {
              svg {
                color: #c4343c;
                font-size: 24px;
                margin-right: 10px;
                margin-top: 5px;
              }

              h6 {
                margin-bottom: 0px;
              }

              p {
                font-size: 14px;
                line-height: 25px;
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .outcomes-box-1 {
      margin-top: 5%;
      background-color: white;
      padding: 15px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .deyor-features-content-list {
        .content-list-li {
          margin-bottom: 10px;

          .deyor-features-inner-list {
            display: flex;

            li {
              svg {
                color: #c4343c;
                font-size: 24px;
                margin-right: 10px;
                margin-top: 5px;
              }

              h6 {
                margin-bottom: 0px;
              }

              p {
                font-size: 14px;
                line-height: 25px;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.deyor-challenges-sec {
  .d-f-fd-cr-deyor {
    display: flex;
    flex-direction: row;
  }

  .j-c-sa {
    display: flex;
    justify-content: space-around;
  }

  .deyor-challenges-wrap-box {
    .deyor-challenges-content-box {
      animation: 4s ease-in-out infinite floatY;
      transition: 4s;
      background-color: #f6f9fb;
      padding: 75px 60px 90px;
    
      position: relative;
      min-height: 550px;
      margin-top: 5%;

      .challenges-content-list {
        margin-top: 10%;

        li {
          .challenges-inner-list {
            display: flex;
            margin-bottom: 20px;

            li {
              svg {
                color: #c4343c;
                font-size: 24px;
                margin-right: 15px;
              }

              h5 {
                font-size: 18px;
                margin-bottom: 0px;
              }

              .desc {
                font-size: 15px;
                line-height: 25px;
              }
            }
          }
        }
      }

      .small-bar {
        background-color: #c4343c;
        width: 47px;
        height: 7px;
        display: block;
        margin-bottom: 15px;
      }

      .main-heading {
        font-size: 30px;
      }
    }
  }
}

.deyor-solutions-sec {
  width: 100%;
  min-height: 550px;
  background-color: #dcdcdc;
  position: relative;

  .d-f-fd-cr-deyor {
    display: flex;
    flex-direction: row;
  }

  .j-c-sa {
    display: flex;
    justify-content: space-around;
  }

  .deyor-solutions-main-head {
    .small-bar {
      background-color: #c4343c;
      width: 47px;
      height: 7px;
      display: block;
      margin-bottom: 15px;
    }

    .main-heading {
      font-size: 34px;
    }
  }

  .deyor-solutions-content-box {
    animation: 4s ease-in-out infinite floatY;
    transition: 4s;
    background-color: #f6f9fb;
    padding: 55px 50px 50px;

    position: relative;
    min-height: 500px;
    margin-top: 16%;

    .small-bar {
      background-color: #c4343c;
      width: 47px;
      height: 7px;
      display: block;
      margin-bottom: 15px;
    }

    .main-heading {
      font-size: 34px;
    }
  }
}

.deyor-work-screens-sec {
  background-color: #f3f3f3;

  .deyor-work-screens-main-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      font-weight: 600;
    }
  }
}

// samagri-casestudy

.samagri-banner-sec {
  background-color: #fff8ed;

  .samagri-banner-shape {
    position: relative;

    img {
      position: absolute;
      width: 36%;
      z-index: 1;
      left: -39px;
    }
  }

  .samagri-banner-img {
    position: relative;
    z-index: 2;
    margin-top: 10%;
  }

  .samagri-banner-content {
    margin-top: 30%;

    .j-c-sb {
      justify-content: space-between;
    }

    .main-heading {
      font-weight: 700;
      font-size: 42px;
      line-height: 50px;

      span {
        color: #c48041;
      }
    }

    .desc {
      font-size: 20px;
      color: #413d2c;
      font-weight: 600;
    }

    .desc1 {
      font-size: 18px;
      color: #413d2c;
      font-weight: 400;
    }

    .samagri-contactus-buttnn {
      background-color: #c48041;
      color: white;
      padding: 12px 32px;
      border: 1px solid transparent;
      transition: all 0.5s;

      &:hover {
        background-color: transparent;
        color: #c48041 !important;
        border-color: #c48041;
      }
    }

    .mt-25 {
      margin-top: 25px;
    }
  }
}

.samagri-aboutus-sec {
  background-color: #f1e9e7;

  .pb-50 {
    padding-bottom: 50px;
  }

  .samagri-aboutus-wrap-box {
    background-color: white;
    padding: 50px;
    justify-content: space-between;

    .aboutus-client-content-list {
      li {
        display: flex;
        align-items: center;
        line-height: 50px;

        .sub-heading {
          font-size: 20px;
          margin-right: 15px;
          font-weight: 600;
        }

        .main-heading {
          font-size: 22px;
          color: #000;
          font-weight: 600;
        }

        span {
          svg {
            color: #c48041;
            font-size: 24px;
            margin-right: 20px;
          }
        }
      }
    }

    .aboutus-our-content {
      .aboutus-our-content-list {
        li {
          line-height: 50px;

          .sub-heading {
            font-size: 22px;
            margin-right: 15px;
            font-weight: 600;

            &:hover {
              color: #c48041 !important;
            }
          }

          .main-heading {
            font-size: 22px;
            color: #000;
            font-weight: 600;

            &:hover {
              color: #c48041 !important;
            }
          }

          span {
            svg {
              color: #c48041;
              font-size: 24px;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

  .samagri-aboutus-wrap-box1 {
    // padding-bottom: 25%;

    .j-c-sb {
      justify-content: space-between;
    }

    .samagri-aboutus-content-box {
      margin-top: 20%;

      .main-heading {
        font-weight: 600;
        font-size: 48px;
        line-height: 60px;
        margin-bottom: 25px;

        span {
          color: #c48041;
        }
      }

      .desc {
        font-size: 20px;
        margin-bottom: 25px;
      }
    }

    .samagri-aboutus-img-box {
      .samagri-logo-img {
        text-align: center;

        img {
          width: 40%;
        }
      }

      .samagri-small-img-box {
        position: relative;

        .sm-1 {
          position: absolute;
          left: 6%;
          top: -75px;
          z-index: 2;
        }

        .sm-2 {
          position: absolute;
          left: 37%;
          z-index: 2;
          top: 80px;
        }
      }
    }

    .samagri-small-img-box1 {
      position: relative;
      margin-top: 55%;

      .sm-3 {
        position: absolute;
        left: 50%;
        z-index: 2;
      }

      .sm-4 {
        position: absolute;
        left: 62%;
        z-index: 1;
      }
    }
  }
}

.samagri-project-highlights {
  background-color: #b99471;

  .samagri-project-highlights-img {
    position: relative;

    .circle {
      .circle-content {
        position: absolute;
        top: 45%;
        left: 80%;

        .main-heading {
          font-size: 48px;
          font-weight: 600;

          span {
            display: block;
            font-size: 48px;
            color: #c48041;
          }
        }
      }

      img {
        position: absolute;
        left: 60%;
        top: 185px;
        width: 80%;
      }
    }
  }

  .samagri-project-highlights-content {
    position: relative;

    .project-highlights-content-list {
      .project-highlights-content-icon {
        width: 115px;
        height: 115px;
        border-radius: 50%;
        background-color: white;
        -webkit-filter: drop-shadow(-24px 29px 25px rgba(12, 12, 13, 0.32));
        filter: drop-shadow(-24px 29px 25px rgba(12, 12, 13, 0.32));
        text-align: center;
        line-height: 115px;
      }

      .project-highlights-content-area {
        padding-left: 20px;

        .desc {
          font-size: 20px;
          line-height: 30px;
          color: #fff;
        }
      }
    }

    .project-highlights-content-list {
      &:nth-child(1) {
        position: absolute;
      }

      &:nth-child(2) {
        position: absolute;
        top: 25%;
        left: 30%;
      }

      &:nth-child(3) {
        position: absolute;
        top: 54%;
        left: 31%;
      }

      &:nth-child(4) {
        position: absolute;
        top: 85%;
        left: 2%;
      }
    }
  }
}

.samagri-work-screens {
  position: relative;
  width: 100%;
  min-height: 1050px;
  background: url("../images/casestudy/Samagri/our-screens/work-screens.png");
  background-size: cover;
  background-repeat: no-repeat;

  .samagri-work-screen-content {
    // position: absolute;
    margin-top: 10%;

    .main-heading {
      font-size: 48px;
      font-weight: 600;

      span {
        color: #c48041;
        display: block;
      }
    }

    .desc {
      line-height: 25px;
    }
  }
}

.samagri-features-sec {
  background-color: #f1e9e7;

  .j-c-sb {
    justify-content: space-between;
  }

  .samagri-features-main-head {
    margin-top: 15%;

    .main-heading {
      color: black;
      font-size: 46px;
      font-weight: 600;

      span {
        color: #c48041;
      }
    }

    .desc {
      font-size: 20px;
    }
  }

  .samagri-features-img {
    margin-top: 25%;
  }

  .samagri-features-card {
    padding: 20px 10px;
    border: 1px solid #e1e1e1;
    border-radius: 20px;
    width: 100%;
    margin-bottom: 25px;
    transition: box-shadow 0.4s ease-in-out;
    background: #fff;
    max-width: 100%;
    min-height: 165px;

    &:hover {
      box-shadow: 0 69px 57px 0 rgb(56 56 84/9%);
      border: 1px solid #fff;
      cursor: pointer;
    }

    .samagri-features-icon {
      margin-bottom: 15px;

      img {
        width: 30%;
      }
    }

    .card-heading {
      font-size: 15px;
      color: black;
      font-weight: 600;
      display: inline-block;
    }
  }
}

.samagri-challenges-sec {
  background-color: #b99471;

  .j-c-sb {
    justify-content: space-between;
  }

  .samagri-challenges-main-head {
    text-align: center;

    .main-heading {
      font-size: 40px;
      font-weight: 600;
      color: white;
    }
  }

  .samagri-challenges-content {
    margin-top: 10%;

    .samagri-challenges-card {
      background-color: white;
      padding: 10px;
      margin-bottom: 20px;

      .samagri-challenges-card-heading {
        background-color: #af6b2d;

        .main-heading {
          text-align: center;
          font-size: 16px;
          color: white;
          padding: 10px;
        }
      }

      .desc {
        padding: 5px;
        line-height: 25px;
      }
    }
  }
}

.samagri-contact-banner {
  width: 100%;
  min-height: 250px;
  background-image: url("../images/casestudy/Samagri/contact-banner/kickstart-banner.webp");
  background-size: cover;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  position: relative;
  padding: 100px 0px;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(30 18 6 / 51%);
    top: 0;
    left: 0;
  }

  .position-relative-z-index-1 {
    position: relative;
    z-index: 1;

    .samagri-contact-banner-content {
      text-align: center;

      .main-heading {
        color: white;
        font-size: 36px;
        margin-bottom: 10px;
      }

      .desc {
        color: white;
      }

      .mt-30 {
        margin-top: 30px;
      }

      .visit-site-buttn {
        background-image: linear-gradient(
          to right,
          #fc4f54 0%,
          #ff8a12 50%,
          #fa534f 100%
        );
        padding: 12px 32px;
        color: white;
        position: relative;
        overflow: hidden;
        background-size: 200% auto;
        -webkit-transition: 0.5s;
        transition: all 0.5s;
        border-radius: 5px;
        font-weight: 600;

        &:hover {
          background-position: center right;
          color: white !important;
        }
      }
    }
  }
}

// Digital-Marketing-css

.digital-mkt-banner-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/DigitalMarketing/main-banner/main-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }

  .digital-mkt-banner-content {
    position: absolute;
    z-index: 2;
    top: 30%;
    width: 40%;
    left: 7%;

    .main-heading {
      color: white;
      font-size: 28px;
      margin-bottom: 15px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      color: white;
      margin-bottom: 30px;
      font-size: 16px;
    }

    .transform-button {
      color: white !important;
      background-color: transparent;
      border: 1px solid #fcaf17;
      padding: 12px 40px;
      transition: all 0.4s ease-in-out;
      font-weight: 600;
      overflow: hidden;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        background-color: #fcaf17;
        color: white;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        transform-origin: 0 50%;
        transition-property: transform;
        transform: scaleX(0);
        transition: all 0.4s ease-in-out;
      }

      &:hover::before {
        border: 1px solid transparent;
        // background: transparent;
        color: white;
        transition: all 0.4s ease-in-out;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
}

.digital-mkt-about-us {
  .digital-mkt-about-content {
    margin-top: 15%;

    .main-heading {
      font-size: 30px;
      font-weight: 600;
      color: #020202;

      span {
        color: rgb(252 175 23);
      }
    }

    .desc {
      font-size: 16px;
      font-family: $roboto;
    }

    .h-about-button {
      margin-top: 20px;

      .read-more-button {
        background-color: rgb(252, 175, 23);
        border: 1px solid rgb(252, 175, 23);
        border-radius: 5px;
        padding: 8px 25px;
        color: #ffffff;
        font-size: 14px;
        transition: all 0.5s;

        &:hover {
          color: white !important;
          transform: translateY(-15px);
          transition: all 0.5s;
        }
      }
    }
  }
}

.digital-mkt-features-sec {
  background-color: #f8f9fe;

  .digital-mkt-features-main-head {
    text-align: center;

    .main-heading {
      font-size: 32px;
      margin-bottom: 15px;
      font-weight: 600;

      span {
        color: #fcaf17;
      }
    }
  }

  .digital-mkt-features-card {
    background-color: #fff;
    border: #dee4fe solid 1px;
    border-radius: 22px;
    padding: 28px;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    margin-bottom: 25px;

    &:hover {
      border-color: #fcaf17;
      box-shadow: 0 20px 25px -3px rgb(252 175 23 / 22%),
        0 8px 10px -2px rgb(252 175 23 / 22%);
      background-color: #fff;
    }

    .main-heading {
      font-size: 22px;
      font-weight: 600;
      position: relative;
      margin-bottom: 20px;
      padding-bottom: 15px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        width: 82px;
        height: 3px;
        background-color: #fcaf17;
        left: 0;
      }
    }
  }
}

.digital-mkt-why-choose-us-sec {
  background-color: #f2f2f2;

  .digital-mkt-why-choose-main-head {
    text-align: center;

    .main-heading {
      font-size: 32px;
      margin-bottom: 15px;
      font-weight: 600;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 16px;
      width: 80%;
      margin: 0 auto;
    }
  }

  .digital-mkt-why-choose-card {
    background-color: white;
    padding: 20px 15px;
    transition: 0.2s ease-in-out;
    margin-bottom: 25px;
    min-height: 290px;

    &:hover {
      transform: translateY(-10px);
      cursor: pointer;
    }

    img {
      width: 50px;
    }

    .digital-mkt-why-choose-content {
      h5 {
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 18px;
      }

      .desc {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}

.digital-mkt-services-sec {
  .pt-15 {
    padding-top: 15px;
  }

  .j-c-sb {
    justify-content: space-between;
  }

  .digital-mkt-services-img {
    position: relative;

    .video-icon {
      position: absolute;
      z-index: 2;
      top: 68%;
      left: 56%;
      cursor: pointer;

      img {
        width: 75%;
      }
    }
  }

  .digital-mkt-services-content {
    margin-top: 5%;

    .main-heading {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .digital-mkt-services-content-list li {
      border-top: 1px dotted #fcaf17;
      padding: 7px;
      font-weight: 600;
      font-size: 16px;

      &:hover {
        color: #fcaf17;
        cursor: pointer;
      }

      svg {
        color: #fcaf17;
        font-size: 20px;
      }
    }
  }
}

.digital-mkt-services-benefits-sec {
  .digital-mkt-services-benefits-main-head {
    text-align: left;

    .main-heading {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 20px;

      span {
        color: #fcaf17;
      }
    }
  }

  .digital-mkt-services-benefits-main-content {
    .desc {
      margin-bottom: 15px;
      font-size: 16px;
    }
  }

  .desc1 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;

    span {
      color: #fcaf17;
    }
  }

  .digital-mkt-benefits-list-box {
    .digital-mkt-benefits-list {
      margin-top: 3%;

      li {
        line-height: 30px;
        font-size: 17px;
        margin-bottom: 25px;

        svg {
          color: #fcaf17;
          font-size: 26px;
          margin-right: 10px;
        }
      }
    }
  }
}

.digital-mkt-tools-sec {
  .digital-mkt-tools-main-head {
    text-align: center;

    .main-heading {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 15px;

      span {
        color: #fcaf17;
      }
    }
  }

  .tools-img {
    text-align: center;

    img {
      width: 100% !important;
      height: 70px !important;
      object-fit: contain;
      margin-bottom: 15px;
    }
  }
}

.digital-mkt-casestudy-sec {
  background-color: #f9f9f9;

  .digital-mkt-casestudy-content {
    .main-heading {
      font-size: 32px;
      font-weight: 600px;
      margin-bottom: 25px;
    }

    .sub-heading {
      font-size: 24px;
      font-weight: 600;
    }

    .desc {
      font-size: 18px;
    }

    .digital-mkt-casestudy-content-list {
      margin-top: 2%;

      li {
        line-height: 35px;
        font-size: 17px;

        svg {
          color: #fcaf17;
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
  }

  .j-c-sb {
    justify-content: space-between;
  }
}

.digital-marketing-services-sec {
  // background: linear-gradient(90deg, #ffebc4, #fff);
  background-color: #f6ebd6;

  .digital-marketing-services-main-head {
    text-align: center;

    .main-heading {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 15px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 16px;
      width: 90%;
      margin: 0 auto;
    }
  }

  .digital-marketing-services-card {
    background-color: white;
    border: 1px solid #e5e5e5;
    padding: 20px 20px 0px 20px;
    transform: scale(1);
    border-radius: 20px;
    transition: all 0.5s;
    margin-bottom: 25px;
    min-height: 291px;

    &:hover {
      .service-btnn {
        background-color: #fcaf17;
        color: white;
        transition: all 0.3s;
      }
    }

    .d-mkt-card-icon {
      position: relative;

      .circle-icon-holder {
        display: block;
        width: 100px;
        height: 100px;
        background-color: #ffedc8;
        text-align: center;
        border-radius: 100%;
        padding: 24px;
        position: relative;
        transition: all 0.4s;
        animation: rotate 6s linear infinite;
        z-index: 1;

        &::after {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          top: 2px;
          left: 15px;
          right: 0;
          bottom: 0;
          background-color: #fcaf17;
          border-radius: 100%;
          animation: rotate 6s linear infinite;
          transition: all 0.4s;
          z-index: 0;
        }
      }

      img {
        width: 65px;
        z-index: 1;
        position: absolute;
        top: 20px;
        left: 20px;
        text-align: center;
        margin: 0 auto;
      }

      @keyframes rotate {
        0% {
          transform: rotate(-360deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    .digital-marketing-services-heading {
      .heading {
        font-size: 21px;
      }
    }

    .digital-marketing-content {
      margin-top: 15px;

      .desc {
        font-size: 15px;
        line-height: 1.6;
        margin-bottom: 15px;
        min-height: 72px;
      }

      .service-btnn {
        border-top: 1px solid #ddd;
        padding: 10px;
        font-size: 18px;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;

      .circle-icon-holder {
        &::after {
          content: "";
          position: absolute;
          width: 100px;
          height: 100px;
          top: 0px;
          left: 0px;
          right: 0;
          bottom: 0;
          background-color: #fcaf17;
          border-radius: 100%;
          z-index: 0;
          transition: all 0.3s;
        }
      }
    }
  }
}







.boost-your-business-sec {
  background-color: #f9f9f9;

  .boost-your-business-content {
    margin-top: 10%;

    .main-heading {
      font-size: 29px;
      font-weight: 600;
      margin-bottom: 15px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      margin-bottom: 15px;
    }

    .percentage {
      span {
        font-size: 42px;
        color: #fcaf17;
        font-weight: 600;
      }
    }

    .percentage-content {
      .desc1 {
        font-size: 15px;
        line-height: 20px;
        margin-left: 15px;
      }
    }
  }
}

.social-media-management-sec {
  background-color: #efefef;

  .social-media-management-content {
    margin-top: 20%;

    .main-heading {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 15px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 18px;
      line-height: 30px;
    }

    .social-media-icons-list {
      display: flex;
      margin-top: 2%;

      li {
        margin-right: 20px;

        &:hover img {
          // transform: scale(1.1);
          transition: all 0.5s;
          animation: bounce 1.5s infinite;
        }

        img {
          width: 50px;
          // transform: scale(1);
        }
      }
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-17px);
    }

    100% {
      transform: translateY(0px);
    }
  }
}

// Turningpoint-casestudy-css

.turning-point-banner-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/Turningpoint/main-banner/turningpoint-main-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .turning-point-banner-content {
    margin-top: 9%;

    .mt-30 {
      margin-top: 30px;
    }

    .main-heading {
      font-size: 28px;
      margin-bottom: 10px;
      font-weight: 500;
      margin-top: 20px;

      span {
        color: #fcaf17;
      }
    }

    .desc {
      font-size: 14px;
      line-height: 25px;
    }

    .sub-heading {
      color: white;
      font-family: $roboto;
      margin-bottom: 30px;
    }

    .transform-button {
      color: white;
      background-color: #0a0a0a;
      border: 1px solid #0a0a0a;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid transparent;
        background: #e4252b;
        color: #fff !important;
      }
    }

    .turning-point-intro-list-box {
      text-align: center;

      .turning-point-intro-list {
        text-align: left;
      }
    }
  }

  .turning-point-banner-img {
    text-align: end;

    img {
      margin-top: 5%;
    }
  }
}

.turning-point-about-client-sec {
  background-color: whitesmoke;

  .turning-point-about-client-main-head {
    .main-heading1 {
      font-size: 40px;
      font-weight: 600;
      position: relative;
      margin-bottom: 20px;
    }
  }

  .turning-point-about-client-content-box {
    padding-left: 50px;
    padding-top: 15%;
    position: relative;

    .desc {
      font-size: 17px;
      line-height: 25px;
    }
  }

  .turning-point-about-client-img {
    img {
      border-radius: 10px;
    }
  }
}

// project-highlights

.turning-point-project-highlights-sec {
  .turning-point-project-highlights-head {
    .main-heading {
      font-size: 34px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 20px;
      color: black;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #e95155;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #e95155;
        bottom: 0;
        border-radius: 100px;
      }
    }

    .desc {
      font-size: 18px;
      margin-top: 15px;
      text-align: center;
      line-height: 30px;
      color: white;
    }
  }

  .project-highlights-content-area {
    position: relative;
    border-top: 1px solid #e1e1e1;
    transition: all 0.3s ease 0s;
    margin-top: 3%;

    &::before {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      z-index: 1;
      left: 0;
      top: -7.5px;
    }

    &::after {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      z-index: 1;
      right: 0;
      top: -7.5px;
    }

    .project-highlights-card {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        background: #0a0a0a;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        top: -7.5px;
        z-index: 1;
      }

      &::after {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        top: 0;
        width: 1px;
        background: #e1e1e1;
        height: 100px;
        z-index: -11;
      }

      .turning-point-project-highlights-card-content {
        padding-top: 100px;
        text-align: center;

        .number-area {
          font-size: 42px;
          font-weight: 800;
          line-height: 80px;
          text-align: center;
          width: 80px;
          height: 80px;
          color: #ffffff;
          margin: 0 auto;
          border-radius: 50%;
          background-image: linear-gradient(90deg, #0a0a0a 0%, #e4252b 100%);
        }

        .content-txt {
          padding: 20px 10px 0px 10px;

          .desc {
            line-height: 25px;
            font-size: 16px;
          }
        }
      }
    }

    .project-highlights-card-style1 {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        background: #e4252b;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        top: -7.5px;
        z-index: 1;
      }

      &::after {
        position: absolute;
        content: "";
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
        top: 0;
        width: 1px;
        background: #e1e1e1;
        height: 100px;
        z-index: -11;
      }

      .project-highlights-card-content {
        padding-top: 100px;
        text-align: center;

        .number-area {
          font-size: 42px;
          font-weight: 800;
          line-height: 80px;
          text-align: center;
          width: 80px;
          height: 80px;
          color: #ffffff;
          margin: 0 auto;
          border-radius: 50%;
          background-image: linear-gradient(90deg, #0a0a0a 0%, #e4252b 100%);
        }

        .content-txt {
          padding: 20px 15px 0px 15px;

          .desc {
            line-height: 25px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

// Turning-point-features-sec

.turning-point-features-sec {
  position: relative;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/casestudy/Turningpoint/features/features-bg.png");
  background-size: cover;
  background-attachment: fixed;

  .overlay-turning-point-features {
    content: "";
    position: absolute;
    background: rgb(233 233 233 / 55%);
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .position-relative-z-index-1 {
    position: relative;
    z-index: 1;
  }

  .features-sec-main-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      font-weight: 600;
    }
  }

  .customer-app-features-content {
    margin-top: 20%;

    .white-box {
      border: 1px solid #dedede;
      padding: 15px;
      margin-bottom: 20px;
      background-color: #fff;
      border-radius: 10px;
      min-height: 100px;
      display: flex;
      align-items: center;

      .featured-list {
        display: flex;
        align-items: center;

        li {
          .inner-featured-list {
            margin-left: 15px;

            h4 {
              font-size: 17px;
              margin-bottom: 5px;
            }

            .desc {
              line-height: 20px;
              font-size: 15px;
            }
          }

          span {
            display: inline-block;
            background: linear-gradient(
              190deg,
              rgb(10 10 10) 0%,
              rgb(233 81 85) 100%
            );
            width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            border-radius: 5px;
            margin-bottom: 0;
            color: white;
          }
        }
      }
    }
  }
}

.turning-point-challenges-and-solutions-sec {
  .challenges-and-solutions-main-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      font-weight: 600;
    }

    span {
      color: #222a41;
    }

    .desc {
      line-height: 25px;
    }
  }

  .challenges-sec {
    margin-top: 15%;

    h4 {
      font-size: 23px;
      font-weight: 600;
    }

    .challenges-content-list {
      .challenges-heading {
        color: #e4252b;
        font-weight: 600;
      }

      .solutions-heading {
        color: #2ed915;
        font-weight: 600;
      }

      li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 15px;

        h6 {
          font-size: 18px;
          font-weight: 600;
        }

        .desc {
          font-size: 14px;
          line-height: 25px;
        }

        svg {
          position: absolute;
          left: 0;
          color: #e4252b;
          top: 2px;
        }
      }
    }
  }
}

.turning-point-conclusion-sec {
  position: relative;
  z-index: 1;

  width: 100%;
  min-height: 250px;
  background-image: url("../images/casestudy/case-study-1/conclusion/conclusion.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1a0b0c;
    opacity: 0.8;
    z-index: 1;
  }

  .turning-point-conclusion-sec-head {
    z-index: 2;

    .main-heading {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      position: relative;
      padding-bottom: 10px;
      color: white;
      margin-bottom: 20px;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 3px;
        background-color: #e4252b;
        bottom: 0;
        border-radius: 100px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 60px;
        right: 0;
        margin: 0 auto;
        width: 20px;
        height: 3px;
        background-color: #e4252b;
        bottom: 0;
        border-radius: 100px;
      }
    }
  }

  .turning-point-conclusion-content-box {
    .desc {
      color: white;
      text-align: center;
      font-size: 16px;
    }
  }
}

.turning-point-visual-designs-sec {
  .turning-point-visual-designs-main-head {
    text-align: center;

    .main-heading {
      font-size: 34px;
      font-weight: 600;
    }

    .desc {
      line-height: 25px;
      margin-bottom: 40px;
    }
  }

  .turning-point-visual-designs-wrapper {
    background-color: #f0686c;
    // background-color: #017896;
    padding: 120px 0px 30px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 100px;
      background: url("../images/casestudy/NikkyPore/visual-designs/portfolio-design.png");
      background-size: cover;
      background-repeat: repeat-x;
    }

    .turning-point-visual-designs-slide {
      text-align: center;

      img {
        width: 50%;
      }
    }
  }
}
.mid_heading {
  font-weight: 600;
  font-size: 17px;
}

.marketplace-banner-sec {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 550px;
  background-image: url("../images/MarketplaceManagement/marketplacebanner.webp");
  background-size: cover;
  background-position: center;

  // &::after {
  //   content: "";
  //   top: 0;
  //   left: 0;
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #000;
  //   opacity: 0.6;
  //   z-index: 1;
  // }

  .marketplace-banner-content {
    position: absolute;
    z-index: 2;
    top: 45%;
    width: 30%;
    left: 5%;

    .main-heading {
      font-size: 30px;
      margin-bottom: 10px;
      color: #000;

      span {
        color: #fcaf17;
      }
    }

    .sub-heading {
      color: #000;
      font-family: $roboto;
      margin-bottom: 30px;
      font-size: 16px;
    }

    .transform-button {
      color: white;
      background-color: #fcaf17;
      border: 1px solid #fcaf17;
      padding: 12px 40px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
        border: 1px solid #000;
        background: transparent;
        color: #000 !important;
      }
    }
  }
}

.marektplace_services_sec {
  background-color: #ebebeb !important;
}




