
.web_services_sec {
    background-color: #fff3dd;
    padding: 40px 0px;
  
    .heading_box {
      text-align: center;
  
      .main_heading {
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 15px;
  
        span {
          color: #fcaf17;
        }
      }
  
      .desc {
        font-size: 16px;
        width: 90%;
        margin: 0 auto;
      }
    }
  
    .pt_40
    {
        padding-top: 40px;
    }
    .card {
      background-color: white;
      border: 1px solid #e5e5e5;
      padding: 20px;
      transform: scale(1);
      border-radius: 20px;
      transition: all 0.5s;
      margin-bottom: 25px;
      min-height: 320px;
  
      .icon {
        position: relative;
        margin-bottom: 20px;
  
        .circle_icon {
          display: block;
          width: 80px;
          height: 80px;
          background-color: #ffedc8;
          text-align: center;
          border-radius: 100%;
          padding: 24px;
          position: relative;
          transition: all 0.4s;
          animation: rotate 6s linear infinite;
          z-index: 1;
  
          &::after {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            top: 2px;
            left: 15px;
            right: 0;
            bottom: 0;
            background-color: #fcaf17;
            border-radius: 100%;
            animation: rotate 6s linear infinite;
            transition: all 0.4s;
            z-index: 0;
          }
        }
  
        img {
          width: 35px;
          z-index: 1;
          position: absolute;
          left: 25px;
          text-align: center;
          margin: 0 auto;
          top: 25px;
        }
  
        @keyframes rotate {
          0% {
            transform: rotate(-360deg);
          }
  
          100% {
            transform: rotate(360deg);
          }
        }
      }
  
     
        .web_heading {
          font-size: 18px;
        }
      
  
      .content {
        margin-top: 15px;
  
        .desc {
          font-size: 15px;
          line-height: 1.6;
          margin-bottom: 15px;
          min-height: 72px;
        }
  
        // .service-btnn {
        //   border-top: 1px solid #ddd;
        //   padding: 10px;
        //   font-size: 18px;
        //   text-transform: uppercase;
        //   text-align: center;
        //   cursor: pointer;
        // }
      }
  
      &:hover {
        cursor: pointer;
  
        .circle_icon {
          &::after {
            content: "";
            position: absolute;
            width: 80px;
            height: 80px;
            top: 0px;
            left: 0px;
            right: 0;
            bottom: 0;
            background-color: #fcaf17;
            border-radius: 100%;
            z-index: 0;
            transition: all 0.3s;
          }
        }
      }
    }
  }
  

  @media only screen and (min-width:320px) and (max-width:767px) {
    .web_services_sec
    {
      padding: 20px 0px 0px 0px;

      .heading_box
      {
          .main_heading
          {
              font-size: 18px;
              margin-bottom: 10px;
          }

          .desc
          {
              font-size: 14px;
  width: 100%;
  margin: 0 auto;
  line-height: 20px;
          }
      }

      .pt_40
      {
        padding-top: 20px;
      }

      .card
      {
padding: 15px;
margin-bottom: 20px;

        .content
{

  .desc
  {
    font-size: 13px;
  }
}

.icon 
{

 .circle_icon
 {
  width: 85px;
  height: 85px;
 }
  img {
    width: 45px;
    z-index: 1;
    position: absolute;
    left: 20px;
    text-align: center;
    margin: 0 auto;
    top: 20px;
  }
}
      }

    }
  }

  
  @media only screen and (min-width:768px) and (max-width:991px) {
    .web_services_sec
    {
      padding: 30px 0px 30px 0px;

      .heading_box
      {
          .main_heading
          {
              font-size: 24px;
              margin-bottom: 10px;
          }

          .desc
          {
              font-size: 14px;
  width: 100%;
  margin: 0 auto;
  line-height: 20px;
          }
      }

      .pt_40
      {
        padding-top: 30px;
      }

      .card
      {
padding: 15px;
margin-bottom: 20px;
min-height: 400px;

        .content
{

  .desc
  {
    font-size: 13px;
  }
}

.icon 
{

 .circle_icon
 {
  width: 85px;
  height: 85px;
 }
  img {
    width: 45px;
    z-index: 1;
    position: absolute;
    left: 20px;
    text-align: center;
    margin: 0 auto;
    top: 20px;
  }
}
      }

    }
  }

  @media only screen and (min-width:992px) and (max-width:1199px) {
    .web_services_sec
    {
      padding: 30px 0px 30px 0px;

      .heading_box
      {
          .main_heading
          {
              font-size: 26px;
              margin-bottom: 10px;
          }

          .desc
          {
              font-size: 14px;
  width: 85%;
  margin: 0 auto;
  line-height: 20px;
          }
      }

      .pt_40
      {
        padding-top: 30px;
      }

      .card
      {
padding: 15px;
margin-bottom: 20px;
min-height: 440px;

        .content
{

  .desc
  {
    font-size: 13px;
  }
}

.icon 
{

 .circle_icon
 {
  width: 85px;
  height: 85px;
 }
  img {
    width: 45px;
    z-index: 1;
    position: absolute;
    left: 20px;
    text-align: center;
    margin: 0 auto;
    top: 20px;
  }
}
      }

    }
  }