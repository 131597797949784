
.about_banner
{
background-color: #fff7e8;
padding: 40px 0px;


.j_c_sb
{
  justify-content: space-between;
}

  .content
{

  .heading
  {
    font-weight: 600;
    color: #020202;
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 41px;

    span
    {
      color: #fcaf17;
    }
  }



  .desc
  {
    font-size: 16px;
    color: #797979;
  }

  .buttn_holder
  {
    margin-top: 25px;

    .buttn
    {
    font-size: 16px;
    font-weight: 500;
   display: inline-block;
    letter-spacing: 0.1px;
    background-color: #fcaf17;
   color: #fff!important;
    border-radius: 5px;
    padding: 10px 24px;
    transition: all 0.3s;
    transform: translateY(0px);



    &:hover
    {
      box-shadow: 0 11px 14px -7px rgba(0,0,0,.25);
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    background-color: #020202;
  

    }
    }
  }
}

.image
{

  width: 100%;
  height: 400px;

  img
  {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
}

@media only screen and (min-width:320px) and (max-width:767px) {
  .about_banner
  {
    padding: 20px 0px;

    .content
    {
      
      .heading
      {
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 10px;
      }

      h5 {
        line-height: 20px;
        font-size: 13px;
      }

      .desc
      {
        font-size: 12px;
        line-height: 20px;
      }

      .buttn_holder
      {
margin-top: 15px;

.buttn
{
  padding: 6px 20px;
  font-size: 13px;
}
      }
    }

    .image
    {
      height: 250px;
    }

    .j_c_sb
    {
      flex-direction: column-reverse;
    }
  }
}


@media only screen and (min-width:768px) and (max-width:1024px) {
  .about_banner
  {
    padding:25px 0px;
    .content
    {
      

      .heading
      {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 10px;
      }

    

      .desc
      {
        font-size: 13px;
        line-height: 20px;
      }

      .buttn_holder
      {
margin-top: 15px;

.buttn
{
  padding: 8px 20px;
  font-size: 14px;
}
      }
    }

    .image
    {
      height: 300px;
    }

   
  }
}

