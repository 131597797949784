.web_tech{
    background-color: #fafafa;
    padding: 40px 0px;
  
    .heading_box
    {
      text-align: center;

  
      .main_heading
      {
        font-size: 36px;
        color: #020202;
        margin-bottom:15px;
        font-weight: 600;
  
  
        span
        {
          color:#fcaf17;
        }
      }
    }

    .pt_30
      {
       padding-top: 30px;
      }
  
    ul {
      .col_auto {
        width: 16.6%;
      }

      
  
      .box {
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 5px 50px #0d15370f;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: auto;
        padding: 20px;
        text-align: center;
        transition: all 0.3s ease;
        min-height: 131px;
        margin-bottom: 25px;
  
        .image {
          width: 100%;
          height: 50px;
          margin-bottom: 1pc;
          margin: 0 auto;

          img
          {
            width: 100%;
            height: 100%;
            object-fit: contain;
          
          }
        }
  
        &:hover {
          box-shadow: 0 20px 20px 0 #02134f1a;
          transform: translateY(-2px);
        }
      }
    }
  }

  @media only screen and (min-width:320px) and (max-width:767px) {
    .web_tech
    {
        padding: 20px 0px;

        .heading_box
        {
            .main_heading
            {
                font-size: 18px;
                margin-bottom: 10px;
            }
  
           
        }

        .pt_30
        {
            padding-top: 15px;
        }

      ul
      {
        .col_auto
        {
            width: 50%;

            .box
            {
                padding: 15px;
                margin-bottom: 20px;
            }
        }
      }
    }
  }
  @media only screen and (min-width:768px) and (max-width:991px) {
    .web_tech
    {
        padding: 30px 0px;

        .heading_box
        {
            .main_heading
            {
                font-size: 26px;
                margin-bottom: 10px;
            }
  
           
        }

        .pt_30
        {
            padding-top: 20px;
        }

      ul
      {
        .col_auto
        {
            width: 33%;

            .box
            {
                padding: 20px;
                margin-bottom: 20px;
            }
        }
      }
    }
  }
  @media only screen and (min-width:992px) and (max-width:1199px) {
    .web_tech
    {
        padding: 30px 0px;

        .heading_box
        {
            .main_heading
            {
                font-size: 28px;
                margin-bottom: 10px;
            }
  
           
        }

       .pt_30
       {
        padding-top: 25px;
       }
    }
  }

 