.success_sec
{

    padding:40px 0px  40px 0px;

    .j_c_sb
    {
        justify-content: space-between;
    }


    .heading_box
    {

        .main_heading
        {
            font-size: 36px;
            font-weight: 600;
color: #212529;
margin-bottom: 25px;
position: relative;

&::after
{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 72px;
    height: 4px;
    background: #ffad0b;

    margin-bottom: -10px;
    position: absolute;
}
        }


    }

    .buttn_holder
    {

        .em_buttn
        {
background: linear-gradient( 270deg, #212529, #fcaf17, rgba(34, 34, 34, 0), rgba(34, 34, 34, 0) );
background-size: 300% 300%;
background-color: #ffad0b;
font-family: "Circular Std";
transition: 0.3s all ease-in-out;
display: inline-block;
color: #fff!important;
font-size: 18px;
padding: 14px 20px;
font-weight: 600;

&:hover
{
    background-position: 99% 50%;
transition: 0.3s all ease-in-out;
}

        }
    }

    .pt_40
    {
        padding-top: 40px;
    }

    .success_card
    {
        

        .image
        {
            position: relative;
            width: 100%;
            background-color: #efefef;
        height: 400px;
        overflow: hidden;

        &::after
        {
            position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #16161440;
    visibility: hidden;
    opacity: 0;
   transition: 0.5s;
        }
       

        img
        {
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding: 25px 0px;
            transform: scale(1);
            transition: all 0.4s ease;
        }

        &:hover
        {

            transition: all 0.4s;

            &::after
            {
                opacity: 1;
                visibility: visible;
            }
            img
            {
                transform: scale(0.95);
                transition: all 0.4s ease;
            }
        }
        }

        .content
        {
padding: 20px 15px;

h5
{
    font-size: 20px;
    font-weight: 600;
    color: #212529;
    margin-bottom: 15px;
}
            .buttns
            {
display: flex;
align-items: center;
gap: 15px;


li
{
    list-style: none;
    a
    {
        
   
    border: 1px solid #b2b2b2;
    border-radius: 20px;
    font-size: 15px;
    color: #333;
    font-weight: 600;
    text-align: center;
    padding: 6px 18px;
    display: block;
    position: relative;

    &::after
    {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        right: 8px;
        top: 50%;
        background-image: url('../../../../assets/images/portfolio/bluts-point.webp');
        background-size: cover;
    }

    }
}
                
            }
        }
    }




}

@media only screen and (min-width:320px) and (max-width:767px) {
    .success_sec
    {
        padding: 20px 0px;

        .heading_box
        {
            .main_heading
            {
                font-size: 20px;
               
            }
        }

        .buttn_holder
        {
            .em_buttn
            {
                font-size: 15px;
                padding: 8px 10px;
            }
        }

        .pt_40
        {
            padding-top: 20px;
        }

        .success_card
        {
            margin-bottom: 15px;
            .image
            {
                height: 300px;

                img
                {
                    padding: 15px 0px;
                }
            }

            .content
            {
                padding: 10px;

                h5
                {
                    font-size: 18px;
                }
            }
        }
    }
}


@media only screen and (min-width:768px) and (max-width:991px) {
    .success_sec
    {
        padding: 0px 0px 30px  0px;

        .heading_box
        {
            .main_heading
            {
                font-size: 24px;
               
            }
        }

        .buttn_holder
        {
            .em_buttn
            {
                font-size: 15px;
                padding: 8px 10px;
            }
        }

        .pt_40
        {
            padding-top: 20px;
        }

        .j_c_e
        {
            display: flex;
            justify-content: flex-end;
        }

        .success_card
        {
            margin-bottom: 15px;
            .image
            {
                height: 350px;

                img
                {
                    padding: 15px 0px;
                }
            }

            .content
            {
                padding: 10px;

                h5
                {
                    font-size: 18px;
                }
            }
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .success_sec
    {
        padding: 0px 0px 30px  0px;

        .heading_box
        {
            .main_heading
            {
                font-size: 28px;
               
            }
        }

        .buttn_holder
        {
            .em_buttn
            {
                font-size: 18px;
                padding: 8px 10px;
            }
        }

        .pt_40
        {
            padding-top: 20px;
        }

        .j_c_e
        {
            display: flex;
            justify-content: flex-end;
        }

        .success_card
        {
            margin-bottom: 15px;
            .image
            {
                height: 350px;

                img
                {
                    padding: 15px 0px;
                }
            }

            .content
            {
                padding: 10px;

                h5
                {
                    font-size: 18px;
                }
            }
        }
    }
}

@media only screen and (min-width:1200px) and (max-width:1440px) {
    .success_sec
    {
        .pt_40
        {
            padding-top: 25px;
        }
    }
}