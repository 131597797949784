.faq_sec
{
    background-color: #fff9ee;
    padding: 50px 0px;

    .heading_box
    {
        text-align: center;

        h2 {
            color: #020202;
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 20px;

            span {
                color: #fcaf17;
              }

             
          }
          .desc
          {
font-size: 16px;
line-height: 20px;
margin: 0 auto;
text-align: center;
          }
    }

    .py_40
    {
        padding: 20px 0px;
    }
}

@media only screen and (max-width:767px) {
    .faq_sec
    {
        padding: 20px 0px 0px 0px;

        .heading_box
        {
            h2
            {
                font-size: 20px;
                margin-bottom: 10px;
            }

            .desc
            {
                font-size: 13px;
            }
        }

        .py_40
        {
            padding: 20px 0px;
        }
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .faq_sec
    {
        padding: 35px 0px 0px 0px;

        .heading_box
        {
            h2
            {
                font-size: 24px;
                margin-bottom: 15px;
            }

            .desc
            {
                font-size: 14px;
            }
        }

        .py_40
        {
            padding: 30px 0px;
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .faq_sec
    {
        padding: 35px 0px 0px 0px;

        .heading_box
        {
            h2
            {
                font-size: 28px;
                margin-bottom: 15px;
            }

            .desc
            {
                font-size: 14px;
            }
        }

        .py_40
        {
            padding: 30px 0px;
        }
    }
}
