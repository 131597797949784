.container_fluid
{
    padding: 0pc 8pc;
}

/* ebslon new landing page swiper css */

.mySwiper1
{
  padding: 0px 20px!important;
}

.mySwiper1 .swiper-button-prev
{
  background: transparent;
  width: 45px !important;
  height: 45px !important;
  border: 1px solid #fff;
  color: #fff !important;
  left: 0px;
  border-radius: 50%;
    
  
  }

.mySwiper1 .swiper-button-prev::after
{
  font-size: 20px!important;
  color: #fff!important;
}
.mySwiper1 .swiper-button-next
{
  background: transparent;
  width: 45px !important;
  height: 45px !important;
  border: 1px solid #fff;
  color: #fff !important;
  right: 0px;
  border-radius: 50%;

}

.mySwiper1 .swiper-button-next::after
{
  font-size: 20px!important;
  color: #fff!important;
}



/* for ebslon landing page new  */


@media only screen and (max-width:767px) {
    .container_fluid
{
    padding: 0pc 1pc;
}

.mySwiper1 .swiper-button-prev
{
  background: transparent;
  width: 40px !important;
  height: 40px !important;
  left: 5px;
  border-radius: 50%;
    
  
  }

.mySwiper1 .swiper-button-prev::after
{
  font-size: 18px!important;
}
.mySwiper1 .swiper-button-next
{
  background: transparent;
  width: 40px !important;
  height: 40px !important;
right: 5px;
  border-radius: 50%;
    
  
  }

.mySwiper1 .swiper-button-next::after
{
  font-size: 18px!important;
}
}
@media only screen and (min-width:768px) and (max-width:991px) {
    .container_fluid
{
    padding: 0pc 2pc;
}
}
@media only screen and (min-width:992px) and (max-width:1199px) {
    .container_fluid
{
    padding: 0pc 3pc;
}
}
@media only screen and (min-width:1200px) and (max-width:1399px) {
    .container_fluid
{
    padding: 0pc 3.5pc;
}
}
@media only screen and (min-width:1400px) and (max-width:1536px) {
    .container_fluid
{
    padding: 0pc 4.5pc;
}
}