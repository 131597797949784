.uiux_benefits
{
    background-color: #1c1e24;
    padding: 50px 0px;

    .heading_box
    {
        text-align: center;

        .main_heading
    {
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 25px;
        color: #fff;

        span
        {
            color: #fcaf17;
        }
    }
    
    }

    .pt_30
    {
        padding-top: 40px;
    }

    .card
{
    margin-bottom: 35px;
    padding: 25px 20px;
    .number
{
    color: #494b50;
    font-size: 50px;
    line-height: 40px;
    font-weight: 600;
}

h5
{
    color: #fff;
    margin-bottom: 25px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 600;
}

.desc
{
    color: #d2d2d3;
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
}
}
}