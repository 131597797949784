html {
  scroll-behavior: smooth;
}

body {
  font-family: $roboto;
  color: #797979;
  line-height: 1.7em;
  font-weight: 400;
  background: $white;
  overflow-x: hidden;
  position: relative;
}

// ::-webkit-scrollbar {
//   width: 0;
// }

.a-i-c {
  display: flex;
  align-items: center;
}

.mb-80 {
  margin-bottom: 60px;
}

.mt-80 {
  margin-top: 60px;
}

.ptb-80 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.ptb-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pt-80 {
  padding-top: 60px;
}

.pb-80 {
  padding-bottom: 60px;
}
a:hover {
  color: #000 !important;
}
.btn {
  &:focus {
    box-shadow: none;
  }
  &-custom {
    font-weight: 500;
    --bs-btn-padding-x: 1pc;
    --bs-btn-padding-y: 10px;
    // letter-spacing: 1px;
  }
  &-hover {
    position: relative;
    overflow: hidden;
    &:before {
      position: absolute;
      background: #ffffff80;
      height: 100%;
      width: 100%;
      top: 0;
      left: 100%;
      content: "";
      border-radius: 3px;
      transition: all 0.5s;
    }
    &:hover:before {
      transition: all 0.5s;
      left: 0;
      transform: translateX(-100%);
    }
  }
  &-blue {
    background-color: $blue;
    color: $white;
    transition: all 0.5s;
    &:hover {
      background-color: $black !important;
      color: $white !important;
      transition: all 0.5s;
    }
  }
  &-black {
    background-color: #363636;
    color: $white;
    &:hover {
      background-color: #363636 !important;
      color: $white !important;
    }
  }
}

.roboto {
  font-family: $roboto !important;
}

.poppin {
  font-family: $poppin !important;
}
.g-10 {
  gap: 10px;
}
.blue {
  color: $blue !important;
}

.blue-bg {
  background-color: $blue !important;
}

.yellow {
  color: $yellow !important;
}

.yellow-bg {
  background-color: $yellow !important;
}

.gray-bg {
  // background-color: #fafafa !important;
  background-color: #fdfdfd !important;
}

.fs-15 {
  font-size: 15px;
}

.list-circle {
  & > li {
    list-style-type: circle;
    list-style-position: inside;
    &::marker {
      color: $yellow;
    }
  }
}

.flex-1 {
  flex: 1;
}

h1.heading {
  font-size: 40px;
  line-height: 47px;
}
h2.heading {
  font-size: 34px;
  line-height: 45px;
}
h3.heading {
  font-size: 34px;
  line-height: 40px;
}
h4.heading {
  font-size: 22px;
  line-height: 32px;
}
h5.heading {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}
h6.heading {
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
}

.heading {
  font-weight: 600;
}

.title-section {
  .heading {
    text-transform: capitalize;
    font-weight: 600;
  }
  .sub {
    width: max-content;
    position: relative;
    letter-spacing: 3px;
  }
}

p {
  line-height: 1.8em;
  margin-bottom: 0px;
  &:last-child {
    margin-bottom: 0;
  }
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.img-position-top {
  object-position: top;
}

.img-fill {
  object-fit: fill;
}

a {
  text-decoration: none;
  color: inherit;
  transition: all 0.5s;
  &:hover {
    transition: all 0.5s;
    color: $yellow;
  }
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

.pb-60 {
  padding-bottom: 60px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
  font-family: $poppin;
}

.btn-sm {
  font-size: 0.875rem;
  // padding: 7px 22px;
  border-radius: 0.2rem;
}

.main-logo {
  height: 50px;
  width: 137px;
  object-fit: contain;
  transition: all 0.5s;
}

.translateX {
  transform: translateX(0) !important;
}

.pointer {
  cursor: pointer;
}

.overlay {
  position: relative;
  &:before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: "";
    z-index: 66;
    pointer-events: none;
  }
}

#scrollUp {
  position: fixed;
  right: 1pc;
  bottom: 1pc;
  opacity: 0;
  .scroll-inner {
    height: 45px;
    width: 45px;
    background: #f16331;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #fff;
    font-size: 22px;
    cursor: pointer;
  }
}

#call-us {
  position: fixed;
  right: 2pc;
  bottom: 1pc;
  filter: drop-shadow(0px 0px 6px gray);
  z-index: 9999;
  opacity: 0;
  &:before {
    content: "";
    position: absolute;
    border: 2px solid #fff;
    bottom: 0;
    right: 0;
    transform: translate(-2px, -2px);
    height: 15px;
    width: 15px;
    background: #128c7e;
    z-index: 0;
  }
  .call-us-inner {
    height: 55px;
    width: 55px;
    background: #128c7e;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    font-size: 30px;
    transform: scaleX(-1);
    i {
      animation: phone 1.5s linear 0s infinite alternate;
    }
  }
}

// #preloader {
//   position: fixed;
//   height: 100vh;
//   width: 100vw;
//   z-index: 99;
//   top: 0;
//   left: 0;
//   background: #f8f9fa;
//   .preloader-inner {
//     background-image: url("../images/loader.gif");
//     background-size: 16pc;
//     background-repeat: no-repeat;
//     background-position: center;
//     height: 100%;
//     width: 100%;
//   }
// }
.line {
  width: 70px;
  background: #fff;
  height: 1px;
}

input:focus-visible {
  outline: 0;
}

.form {
  &.filled-input {
    .form-control,
    .select2-selection--single {
      background-color: #f3f4f8ab !important;
    }
  }
  [class*="col-"] {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .form-check {
    &-label {
      flex: 1;
      color: #000;
      display: flex;
      align-items: center;
      gap: 11px;
      cursor: pointer;
    }
    &:not(.form-check-inline) {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    &.form-check-inline {
      .form-check-input {
        margin-right: 10px;
      }
    }
    input[type="checkbox"] {
      height: 16px;
      width: 16px;
      border: 1px solid #d0d0d0;
      border-radius: 0;
      position: relative;
      background-color: #fff;
      cursor: pointer;
      &.form-check-input:focus {
        border-color: #00000040;
        box-shadow: none;
      }
    }
    input[type="radio"] {
      cursor: pointer;
      &:checked {
        background-color: #ee82c5;
        border-color: #ee82c5;
      }
      &:focus {
        border-color: #ee82c5;
        outline: 0;
        box-shadow: 0 0 0 0.25rem #e256ad40;
      }
    }
  }
  .select2-container--default {
    width: 100% !important;
    .select2-selection--single {
      height: 40px !important;
      border-radius: 0;
      width: 100%;
      padding: 0.375rem 0.75rem;
      background-color: #fff;
      border: 1px solid #ced4da !important;
      .select2-selection__arrow {
        height: 40px !important;
      }
    }
  }
  .form-control {
    border-color: transparent;
    padding: 15px;
    box-shadow: 0 0 20px #00000010;
    &:focus {
      box-shadow: none;
      border-color: $yellow;
      transition: all 0.5s;
      &::placeholder {
        opacity: 0;
        transition: all 0.5s;
      }
    }
    &::placeholder {
      opacity: 1;
      color: #ccc;
      transition: all 0.5s;
      text-transform: capitalize;
    }
  }
  label {
    margin: 0;
    color: #838383;
    line-height: 20px;
    width: 100%;
    font-size: 15px;
  }
}

.address-map {
  margin: 0 10px;
  padding: 8px 60px 8px 8px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
  background-color: white;
  position: absolute;
  top: -5px;
  left: -5px;
  text-align: left;
  z-index: 1;
  p,
  a {
    font-size: 15px;
  }
}

.paddingprotfolittopbotm {
  padding: 60px 0;
}

@media only screen and (max-width: 767px) {
  .ptb-40 {
    padding: 20px 0px !important;
  }
}
