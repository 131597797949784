.uiux_case
{
    background: linear-gradient(0deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,.7)),#dfe7f6;
    padding: 60px 0px 50px 0px;

    .j_c_sb
    {
        gap: 30px;
        justify-content: center;
    }

    .image
    {
        width: 100%;
        height: 476px;

        img
        {
            width: 100%;
            height: 100%;
            
        }
    }

    .content
    {
        span
        {
            background-color: #fcaf17;
            color: #000;
            padding: 5px 15px;
            border-radius: 3px;
        }

        h4
        {
            font-weight: 600;
            font-size: 26px;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        p
        {
            margin-bottom: 15px;
        }
    }
}