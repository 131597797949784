@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
:root {
  --PhoneInputCountryFlag-aspectRatio: 1.333;
}
body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden !important;
}
* {
  list-style: none;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

ul {
  margin: 0;
  padding-left: 0px;
}

.work_sec {
  padding: 50px 0px 60px 0px;
  background-color: #dfdfdf;
  margin-bottom: 40px;

  .heading_box {
    .main_heading {
      font-size: 36px;
      text-align: center;
      text-transform: capitalize;
      font-weight: 600;

      span {
        color: #fcaf17;
      }
    }
  }

  .pt_50 {
    padding-top: 50px;
  }

  .work_img {
    width: 100%;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .work_content {
    background-color: #262626;
    border-radius: 10px;
    padding: 30px 20px;
    transition: all 0.3s;
    cursor: pointer;
    transform: scale(1);
    position: relative;

    .heading {
      color: white;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: 400;

      span {
        color: #fcaf17;
        font-size: 22px;
        text-transform: capitalize;
        letter-spacing: 2px;
        font-weight: 500;
      }
    }

    .desc {
      font-size: 16px;
      color: #e5e5e5;
      font-weight: 400;
      line-height: 25px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 0%;
      background-color: #fcaf17;
      height: 4px;
      left: 0;
      bottom: 0;
      transition: all 0.5s;
    }

    &:hover::after {
      width: 100%;
    }
  }

  .mt_25 {
    margin-top: 25px;
  }

  .demo_buttn {
    background-color: #fcaf17;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    transition: all 0.5s;
    border: 1px solid transparent;
    font-weight: 600;
    cursor: pointer;
  }

  .demo_buttn:hover {
    background-color: #e5e5e5;
    color: black !important;
    border-color: #e5e5e5;
   
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .work_sec 
  {

    padding: 20px 0px;
    margin-bottom: 20px;

    .pt_50
    {
      padding-top: 10px;
    }
    .heading_box 
    {
      .main_heading {
        font-size: 20px;
      }
    }

    .work_img
    {
      width: 90%;
      height: 240px;
      margin: 0 auto;

   

    
    }

    .work_content {
      padding:15px;
      width: 80%;
      margin: 0 auto;
      margin-top: 10px;
  
      .heading
      {
     span
     {
      font-size: 16px;
     }
      }
  
      .desc {
        font-size: 13px;
        line-height: 20px;
      }
    }

    .demo_buttn
    {
      font-size: 13px;
      padding: 5px 12px;
      font-weight: 500;
    }
  
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .work_sec 
  {

    padding: 25px 0px;
    margin-bottom: 20px;

    .pt_50
    {
      padding-top: 10px;
    }
    .heading_box 
    {
      .main_heading {
        font-size: 24px;
      }
    }

    .work_content {
      padding:10px;
  
      .heading
      {
     span
     {
      font-size: 16px;
     }
      }
  
      .desc {
        font-size: 13px;
        line-height: 20px;
      }
    }

    .demo_buttn
    {
      font-size: 13px;
      padding: 5px 12px;
      font-weight: 500;
    }
  
  }


 

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .work_sec {
    padding: 35px 0px;
    margin-bottom: 25px;
    .heading_box .main_heading {
      font-size: 26px;
    }

    .pt_50
    {
      padding-top: 30px;
    }

    .work_content {
      padding: 15px;

      .heading span {
        color: #fcaf17;
        font-size: 20px;
        text-transform: capitalize;
        letter-spacing: 2px;
        font-weight: 500;
      }

      .desc {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .demo_buttn {
      background-color: #fcaf17;
      color: white;
      padding: 6px 12px;
      border-radius: 5px;
      font-size: 14px;
      transition: all 0.5s;
      border: 1px solid transparent;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .demo_buttn {
    background-color: #fcaf17;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    transition: all 0.5s;
    border: 1px solid transparent;
    font-weight: 500;
    cursor: pointer;
  }

  .py-40 {
    padding: 20px 0px;
  }

  .work_sec .heading_box .main_heading {
    font-size: 30px;
  }

  .work_content {
    padding: 15px 10px;
    width: 100%;
  }

  .work_content .heading span {
    color: #fcaf17;
    font-size: 20px;
    text-transform: capitalize;
    letter-spacing: 2px;
    font-weight: 500;
  }

  .work_content .desc {
    font-size: 14px;
    line-height: 20px;
  }
}
