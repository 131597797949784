
.software_aboutus
{
    padding: 50px 0px;


 .pt_40
 {
    padding-top: 30px;
 }

    .heading_box {
        text-align: center;
    
        .main_heading {
          font-size: 32px;
          margin-bottom: 15px;
          font-weight: 600;
    
          span {
            color: #fcaf17;
          }
        }
      }

    .image
    {
        position: relative;
        width: 100%;
        height: 375px;

        img
        {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .content {
       

        .heading {
            font-size: 24px;
            font-weight: 600;
            color: #020202;

            span {
                color: rgb(252, 175, 23);
              }
          }

          .desc {
            font-size: 15px;
            
          }

          .content_list {
            margin-bottom: 20px;
        
            .list {
              li {
                font-size: 14px;
               
                font-weight: 600;
                position: relative;
                padding-left: 20px;
                line-height: 30px;
        
                &::before {
                  content: "";
                  width: 10px;
                  height: 10px;
                  background-color: #fcaf17;
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-radius: 50%;
                  transform: translate(0px, 10px);
                }
              }
            }
          }
        

          .buttn_holder {
            margin-top: 20px;

            .rm_buttn {
                background-color: rgb(252, 175, 23);
                border: 1px solid rgb(252, 175, 23);
                border-radius: 5px;
                padding: 10px 20px;
                color: #ffffff;
                font-size: 14px;
                transition: all 0.5s;

                &:hover {
                    color: white !important;
                    transform: translateY(-15px);
                    transition: all 0.5s;
                  }
              }
          }
      }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .software_aboutus
    {
        padding: 20px 0px 25px  0px;

        .pt_40
        {
            padding-top: 20px;
        }

        .row_reverse
        {
            flex-direction: column-reverse;
        }

        .heading_box
        {
            .main_heading
            {
                font-size: 18px;
                margin-bottom: 0px;
            }
  
          
        }

        .image
        {
            height: 275px;
        }

        .content
        {
            margin-top: 0%;

            .heading
            {
                font-size: 18px;
            }

            .desc
            {
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .software_aboutus
    {
        padding: 25px  0px;

       .pt_40
        {
            padding-top: 20px;
        }

        .row_reverse
        {
            flex-direction: column-reverse;
        }
        

        .heading_box
        {
            .main_heading
            {
                font-size: 22px;
                margin-bottom: 0px;
            }
  
          
        }
       
        .content
        {
          

            .heading
            {
                font-size: 20px;
            }

            .desc
            {
                font-size: 14px;
                line-height: 25px;
            }
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
    .software_aboutus
    {
        padding: 30px  0px;

        .pt_40
        {
            padding-top: 0px;
        }
        

        .heading_box
        {
            .main_heading
            {
                font-size: 26px;
                margin-bottom: 10px;
            }
  
          
        }
        .image
        {
            height: 400px;
        }

        .content
        {
          

            .main_heading
            {
                font-size: 22px;
            }

            .desc
            {
                font-size: 14px;
            }
        }
    }
}

@media only screen and (min-width:1200px) and (max-width:1399px) {
    .software_aboutus
    {
        padding: 40px  0px;

        .pt_40
        {
            padding-top: 0px;
        }
        

        .image
        {
            height: 450px;
        }

        .content
        {
            

            .heading
            {
                font-size: 24px;
            }

            .desc
            {
                font-size: 14px;
            }
        }
    }
}
  