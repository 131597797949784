.uiux_tech
{
    padding: 40px 0px;


    .main_heading
    {
        font-size: 36px;
        font-weight: 600;
        text-align: center;
        color: #262531;
    }
    .pt_30
    {
        padding-top: 25px;
    }
    .image
    {
        
        width: 100%;
        height: 450px;

        img
        {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .uiux_tech
    {
        padding: 20px 0px;
    }
}