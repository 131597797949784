@media only screen and (max-width: 992px) {
  .homebannersectoin {
    padding: 25px 0pc !important;

    .d-flex {
      display: unset !important;
    }

    h1 {
      font-size: 24px;
    }
    h2
    {
      font-size: 26px;
    }
 p {
      font-size: 17px;
      width: 100%;
      margin: 20px 0;
  }

  h6
  {
      font-size: 20px;
      font-weight: 700;
      margin: 10px 0;
  
  }

 .home_banner_img {
    width: 100%;
    height: 400px;
  
}
  }

  

  .homebannersectoin .bannerheading .certificate_img_list li img {
    width: 100px;
    height: 100px;
  }

  .work-flow {
    padding: 0 0pc;
  }

  .bg_contact_pages .contact_left h4 {
    margin-top: 30px;
  }

  .tab-description.portfoliotabs {
    padding: 10px 8px;
  }

  .portfolio-box .portfolio-content {
    bottom: -1px;
    padding: 1pc 5px;
    margin: 0;
  }

  .portfolio-box .portfolio-img img {
    height: 345px;
  }

  .portfolio-box .portfolio-content p {
    font-weight: 500;
    font-size: 13px;
  }

  .ourPortfolio .portfolio_card {
    padding: 40px 9px;
  }

  .ourPortfolio .portfolio_cardimg_content h2 {
    font-size: 24px;
    margin-top: 20px;
    font-weight: 700;
  }

  .portfolio_cardimg img {
    width: 100%;
    height: 320px;
    object-fit: contain;
  }

  .ourPortfolio .portfolio_cardimg_content p {
    font-size: 18px;
    margin: 10px 0;
    color: #000;
    font-weight: 500;
  }

  .otr_lnks_port.gap10 {
    text-align: center;
  }

  .ourPortfolio .otr_lnks_port .btn-casestudy {
    margin-bottom: 20px;
  }

  .chosse_cardsection .col-auto {
    width: 100%;
  }

  .fooerdmc {
    display: none;
  }

  .homrbanner {
    display: none !important;
  }

  .phone_view .title-section {
    padding-bottom: 0 !important;
  }

  .title-section {
    margin-bottom: 0;
  }

  .bg_contact_pages .contact-form-part::after,
  .befoer_borer::before {
    display: none;
  }

  .mb-80 {
    margin-bottom: 0 !important;
  }


  .ptb-80 {
    padding-top: 25px !important;
    padding-bottom: 20px !important;
  }

  .pt-80 {
    padding-top: 40px !important;
  }

  .bootom-bar .navbar {
    padding: 10px;
  }

  .banner .outer-box {
    padding: 1pc;
  }

  .banner .box {
    margin-bottom: 20px;
    display: unset;
  }

  .mobile_nav {
    a {
      padding: 10px;
      border-bottom: 1px solid #ccc;
    }
  }

  .content.flex-1 {
    margin-bottom: 40px;
  }

  .blog2_heading h3 {
    font-size: 23px;
    font-weight: 300;
  }

  .blog2_heading h4 {
    font-size: 25px;
  }

  .blog2_heading p {
    font-size: 16px;
  }

  .worksflosection .dots-line {
    display: none;
  }

  .blog-detail .image {
    height: 190px;
  }

  .blog_listing .blog_listing_img img {
    height: 200px;
  }

  .row.mobile_scrollbar {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 15px;
  }

  .technology-used ul .col-auto {
    width: 33%;
  }

  .technology-used .mobile_technologyspace {
    --bs-gutter-x: 0.3rem;
  }

  .technology-used ul .box {
    margin-bottom: 30px;
    min-height: 119px;

    h6 {
      font-size: 12px;
    }
  }

  .phone_view {
    background-color: #fafafa;
    padding: 60px 0px;
  }

  .phone_view .col {
    flex: 1 1;
    min-width: 350px;
  }

  .row.mobile_scroll_view {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  .phone_view ::-webkit-scrollbar {
    width: 10px !important;
    height: 4px !important;
  }

  .phone_view ::-webkit-scrollbar-track {
    background-color: #e2e1dd;
    border-radius: 0px;
  }

  .phone_view ::-webkit-scrollbar-thumb {
    background: #fcaf17;
    border-radius: 4px;
  }

  .in_expertise_s .col-auto {
    width: 50%;
  }

  .box_indstr p {
    font-size: 13px;
  }

  .our-portfolio .outer-box .box.reverse .right {
    text-align: left;
  }

  .in_expertise_s img {
    height: 35px;
    width: 35px;
  }

  .in_expertise_s .box_indstr {
    margin-bottom: 5px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 0%), 0 -1px 5px 0 rgb(0 0 0 / 12%);
    min-height: 130px;
  }

  .in_expertise_s .box_indstr p {
    font-size: 12px;
  }

  .tab-description {
    background: #fff;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    padding: 25px 20px 35px 20px;
    min-height: 575px;
    margin-bottom: 15px;
    margin-top: 9px;
    position: relative;
  }

  .tab-description h3 {
    font-size: 16px !important;
    position: relative;
  }
  .scroll_right
  {
    display: block;
  }

  .ptb-40 {
    padding: 25px 0px !important;
}

  .tab-description h3::before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 40px;
    background-color: $yellow;
  }

  .tab-description p {
    font-size: 12px;
    line-height: 1.71;
    /* min-height: 277px; */
    text-align: justify;
    margin-bottom: 27px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 6;
    -webkit-line-clamp: 6;
    overflow: hidden;
  }

  .tab-description h6 {
    font-size: 14px;
    margin: 12px 0;
  }

  .tab-description li {
    position: relative;
    margin-bottom: 5px;
    font-size: 11px;
    padding-left: 14px;
    line-height: 18px;
    list-style-type: none !important;
  }

  .tab-description li::after {
    position: absolute;
    content: "";
    left: 0;
    width: 6px;
    height: 6px;
    background-color: #fcaf17;
    top: 11px;
  }

  .consultation .row {
    border-top-right-radius: 10px;
  }

  .consultation .left {
    padding: 10px;
  }

  .blog-box {
    margin-bottom: 30px;
  }

  .blog-box h4 {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
  }

  .pb-80 {
    padding-bottom: 20px !important;
  }

  .mb-80 {
    margin-bottom: 20px;
  }

  .footer-links {
    margin-top: 30px;
  }

  .footer .footer-links .title {
    margin-bottom: 10px;
  }

  .mobile_viewslider {
    background-color: #fafafa;
  }

  .logo_header {
    background: #fff;
    box-shadow: 0 2px 37px 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    text-align: center;
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
  }

  .logo_header img {
    width: 150px;
  }

  .mobile_viewslider .swiper-button-next:after,
  .mobile_viewslider .swiper-button-prev:after {
    position: absolute;
    font-size: 22px !important;
    color: #343434;
    background-color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 13px !important;
    border: 1px solid #cccccc2e;
    border-radius: 57px;
  }

  .testmonail_boxshowmobile {
    padding: 23px 20px;
  }

  .iocnquite {
    font-size: 45px;
    opacity: 0.3;
  }

  .testmonail_boxshowmobile p {
    font-size: 14px;
    color: #000;
    margin-top: 15px;
    text-align: justify;
  }

  .testmonail_boxshowmobile h4 {
    font-size: 20px;
    margin-top: 25px;
    font-style: italic;
  }

  .our-clients {
    overflow: hidden;
    width: 100%;
  }

  .jrny-border .img-col {
    padding: 25px 0;
  }

  .jrny-border .jrny-arrow::before {
    left: 47%;
  }

  .row.row_reverse {
    flex-direction: column-reverse;
  }

  .content_mission h3 {
    margin: 20px 0;
  }

  .topbar {
    padding: 10px 1px;
  }

  .jrny-border .heading {
    font-size: 18px;
  }

  .main-blog .container-fluid {
    padding: 0px 2pc;
  }

  .blog-detail .blog-box h3.heading {
    font-size: 25px;
    line-height: 30px;
  }

  .row.about-card {
    margin-bottom: 30px;
  }

  .center_mobile {
    text-align: center;
  }

  .slider_content {
    background-color: #f6f6f6;
    padding: 29px 13px;
    min-height: 360px;

    h2 {
      position: relative;
    }

    p {
      margin: 10px 0;
    }

    a {
      color: #fcaf17;
      font-size: 19px;
      font-weight: 500;
    }
  }

  .consultation .left {
    margin-bottom: 20px;
  }

  .bg_contact_pages .contact-form-part {
    padding: 15px 10px;

    h3 {
      font-size: 15px;
      line-height: 23px;
    }

    img {
      width: 4pc;
    }
  }

  .title-section.mb-5 {
    margin-bottom: 0 !important;
  }

  .cour_value .icon-head-wrap img {
    width: 35px;
    height: 37px;
  }

  .cour_value .icon-head-wrap h4 {
    font-size: 18px;
  }

  .cour_value .icon-head-wrap p {
    width: 100%;
  }

  .our-key-experties .mt-5 {
    margin-top: 0px !important;
  }

  .mission_value .imgabsolute {
    display: none;
  }

  .blog_container.top-banner {
    margin-bottom: 20px !important;
  }

  .appdevelopemnt .topimghand img {
    display: none;
  }

  .appdevelopemnt .contindevelopment {
    padding-left: 15px;
  }

  .fluidpadding10 .container-fluid {
    padding: 0px 15px;
  }

  .appdevelopemnt .leftapp {
    flex: unset;
  }

  .appdevelopemnt .contindevelopment h2 {
    font-size: 30px;
  }

  .developmentsecib .cardapp {
    padding: 15px;
  }

  .developmentsecib .cardapp h3 {
    font-size: 17px;
    font-weight: 700;
  }

  .fntch_fnl_inn {
    padding: 20px 16px;
  }

  .fntch_fnl_inn .develmntser {
    margin-top: 20px;
  }

  .fntch_fnl_inn .develmntser h3 {
    font-size: 21px !important;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 41px;
  }

  .fntch_fnl_inn img {
    margin-top: 25px;
  }

  .development_servicer img {
    margin-bottom: 30px;
  }

  .freaturesectionapp img {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width:320px) and (max-width:767px) {
  .blog-detail .blog-box .main_heading {
    font-size: 17px!important;
    padding: 10px 0px;
    font-weight: 600;
}

.main-logo
{
  width: 100px!important;
  height: 35px!important;
}

.bootom-bar .navbar
{
  padding: 5px!important;
}
.homebannersectoin
{
  padding: 10px 0pc!important;

  .bannerheading p {
    width: 100%;
    font-size: 11px!important;
    margin: 5px 0;
    line-height: 16px!important;
}

.home_banner_img {

  height: 225px!important;
}
.animate_buttn
{
  margin: 0px!important;
}

.animate_buttn button {
  padding: 6px 20px!important;
  font-size: 13px!important;
  margin-top: 0px!important;
}


h6 {
  font-size: 14px!important;
  margin: 6px 0!important;
}
}
.services_list li {
  font-size: 13px;
  margin-bottom: 0px;
}

.worksflosection .box
{
  padding: 15px;
  min-height: 230px;
}

.worksflosection .process-icon
{
  width: 55px;
  height: 50px;
  margin:0 auto 15px;
}

.worksflosection h4 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 8px;
}

.worksflosection p
{
  font-size: 13px;
}
.case-study ul.tabs
{
  display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0%;
    
}

.case-study ul.tabs li
{
  margin-bottom: 15px;
}

.newportfolyo li
{
  padding:0px 0px!important;
}
.blog-detail .blog-box .blogh2
{
  font-size: 16px!important;
}
.blog-detail .blog-box p {
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 20px;
}
.blog-detail .blog-box h5 {
  font-weight: 600;
  padding: 5px 0px;
  font-size: 15px;
}
.blog-detail ul.tags li {
  color: #000;
  font-size: 12px;
}
.blog-box .image
{
  height: 185px!important;
}
.blog .blog-box p
{
  font-size: 13px;
  line-height: 20px;
}
.blog-box h4 {
  margin: 5px 0!important;
  font-size: 15px!important;
  font-weight: 600;
}
.blog-box ul.tags li
{
  font-size: 12px!important;
}
.bottom-btn
{
  margin-bottom: 10px;
}
.bottom-btn a
{
  font-size:14px!important;
  padding:8px 16px!important;
}

.title-section h1
{
  font-size: 17px!important;
  line-height: 25px!important;
}
.title-section p {
  text-align: center!important;
  font-size: 13px!important;
  line-height: 20px!important;
  margin-bottom: 10px!important;
}
}

@media only screen and (min-width:993px) and (max-width:1199px)
{
  .homebannersectoin {
    padding: 25px 1pc !important;

    .d-flex {
      display: unset !important;
    }

    h1 {
      font-size: 24px;
      padding: 0px;
    }
    h2
    {
      font-size: 22px;
    }
 p {
      font-size: 15px;
      width: 100%;
      margin: 15px 0;
  }

  h6
  {
      font-size: 20px;
      font-weight: 700;
      margin: 10px 0;
  
  }

 .home_banner_img {
    width: 100%;
    height: 400px;
  
}
  }

  

  .homebannersectoin .bannerheading .certificate_img_list li img {
    width: 100px;
    height: 100px;
  }
}


@media only screen and (min-width: 320px) and (max-width: 349px) {
  .topbar .dowload_brochure {
    font-size: 11px !important;
    padding: 3px !important;
  }

  .main-logo {
    height: 40px !important;
  }

  .pl-10 {
    padding-left: 8px;
  }

  .topbar-inner {
    justify-content: space-around !important;
  }

  .topbar-inner p a {
    font-size: 11px !important;
  }

  .topbar .container-fluid {
    padding: 0px 5px !important;
  }
}

@media only screen and (min-width: 350px) and (max-width: 575px) {
  .topbar .dowload_brochure {
    font-size: 12px !important;
    padding: 5px 8px !important;
  }
  .main-logo {
    height: 40px !important;
  }
  .pl-10 {
    padding-left: 10px;
  }

  .topbar-inner {
    justify-content: space-around !important;
  }

  .topbar-inner p a {
    font-size: 12px !important;
  }

  .topbar .container-fluid {
    padding: 0px 8px !important;
  }
}

@media only screen and (max-width: 768px) {
  .homebannersectoin {
    padding: 20px 0pc ;
  }
  .homebannersectoin .home_banner_img {
    width: 225px;
    height: 260px;
  }

  .homebannersectoin .bannerheading .certificate_img_list li img {
    width: 75px;
    height: 75px;
  }

  .topbar .dowload_brochure {
    font-size: 13px;
  }

  .bannerheading {
    h1 {
      font-size: 16px;
      padding: 0;
    }

    h2 {
      font-size: 14px;
    }

    p {
      width: 100%;
      font-size: 13px;
      margin: 10px 0;
    }
  }

  .main-blog .container-fluid {
    padding: 0px 14px;
  }

  .topbar .container-fluid {
    font-size: 13px;
    padding: 0px 15px;
  }

  .title-section .heading {
    font-size: 18px;
    line-height: 30px;
  }

  .services-swiper {
    padding-top: 30px;
  }

  .title-section p {
    text-align: center;
  }

  .services-swiper ul.tabs li:before {
    right: -1pc;
    width: calc(100% + 2pc);
  }

  .title-section {
    padding-bottom: 5px !important;
  }

  .our-team .col-auto {
    width: 50%;
  }

  .our-team .box img {
    width: 162px;
  }

  .carrier_container img {
    height: 38vh;
  }

  .carrier_container h4 {
    font-size: 20px;
    line-height: 30px;
  }

  .our-clients .marquee-items.left .items_logo {
    height: 120px;
  }
  

  .our-clients .marquee-items.right .items_logo {
    height: 120px;
  }

  .btn.btn-learmore {
    position: absolute;
    bottom: 10px;
    left: 20px;
    color: #fcaf17;
    font-weight: 500;
  }

  .footer
  {
    padding:1pc 0 0pc;
  }
}

@media only screen and (max-width: 991px) {
  .mobile_viewslider {
    display: none !important;
  }
  .blog-detail .blog-box .blogh2
{
  font-size: 16px!important;
}
.blog-detail .blog-box p {
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 20px;
}
.blog-detail .blog-box h5 {
  font-weight: 600;
  padding: 5px 0px;
  font-size: 15px;
}
.blog-detail ul.tags li {
  color: #000;
  font-size: 12px;
}
.blog-box .image
{
  height: 240px!important;
}

.title-section.mb-5.title-section.mb-5.text-center{
  margin-bottom: 2rem!important;
}

}

@media only screen and (min-width:1024px) and (max-width:1199px)
{

  .homebannersectoin .home_banner_img {
    width: 390px;
    height: 390px;
  }
  .bootom-bar 
  {
    .navbar-nav 
    {

      
        justify-content: flex-end;
        gap: 0px;
    
      .nav-link {
        font-size: 15px;
        padding: 18px 7px;
    }
    }
  }

  .blog .blog-box p
{
  font-size: 13px;
  line-height: 20px;
}
.blog-box h4 {
  margin: 5px 0!important;
  font-size: 18px!important;
  font-weight: 600;
}
.blog-box ul.tags li
{
  font-size: 12px!important;
}
}

// bhawana media query
/**************Ecommerce*****************/

/**************Ecommerce*****************/

@media (min-width: 768px) and (max-width: 991px) {
  .head-img-2::before {
    margin-left: 28% !important;
  }

  .second-banner {
    .screen_two {
      margin-bottom: 270px;
    }

    .absolute_img2 {
      position: absolute;

      bottom: -204px;
    }
  }

  .third-banner {
    .features {
      min-height: 212px;
    }
  }

  .fourth-banner {
    .box:hover {
      height: 330px;
    }
  }

  .eigth-banner {
    ul {
      margin-bottom: 40px;
    }
  }
}

@media (min-width: 310px) and (max-width: 767px) {
  .first-banner.mb-80 {
    margin-bottom: 0 !important;

    height: 70vh;

    .head-img-1 {
      display: none;
    }

    .head-img-2 {
      display: none;
    }

    h1.heading {
      font-size: 32px;

      line-height: 35px;

      padding-top: 30px;

      text-align: center;
    }

    span {
      font-size: 32px;
    }

    p {
      font-size: 14px;

      color: #fff;

      text-align: center;
    }

    .btn {
      font-size: 14px;

      // margin-left: 25%;
    }
  }

  .title-section .heading {
    line-height: 30px;
  }

  .ecomprebtncenter {
    text-align: center;
  }

  .second-banner.mb-5 {
    margin-bottom: 0 !important;

    .screen_two {
      margin-bottom: 130px;
    }

    .absolute_img2 {
      position: absolute;

      bottom: -109px;
    }

    .content {
      h1 {
        font-size: 28px;

        text-align: center;
      }

      span {
        font-size: 28px;

        text-align: center;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .third-banner.mb-5 {
    margin-bottom: 0 !important;

    .third-title {
      font-size: 28px;
    }

    span {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }

    ul {
      flex-direction: row;

      li.nav-item {
        width: 33%;

        a.nav-link {
          padding: 0;
          font-size: 13px;
        }

        a.nav-link.active {
          padding: 0;
          font-size: 13px;
        }
      }
    }

    .features {
      min-height: 154px;

      padding: 10px;

      img {
        height: 50px;
      }

      h5 {
        font-size: 15px;
      }
    }
  }

  .fourth-banner.mb-5 {
    margin-bottom: 0 !important;

    h3 {
      font-size: 28px;
    }

    span {
      font-size: 28px;
    }

    .box:hover {
      height: 270px;
    }

    .sub-head .heading {
      font-size: 16px;
    }

    .box-description .description {
      font-size: 14px;
    }
  }

  .fifth-banner.mb-5 {
    margin-bottom: 0 !important;

    h3 {
      font-size: 28px;
    }

    span {
      font-size: 28px;
    }

    .content {
      min-height: 270px;

      h3 {
        font-size: 21px;
      }

      h4 {
        font-size: 16px;

        margin-top: 6px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .sixth-banner.mb-5 {
    margin-bottom: 0 !important;

    h3 {
      font-size: 28px;
    }

    span {
      font-size: 28px;
    }

    .inner_content {
      h3 {
        font-size: 17px;
      }

      p {
        font-size: 14px;
      }

      img {
        height: 55px;
      }
    }
  }

  .tenth-banner.mb-5 {
    margin-bottom: 0 !important;

    h3 {
      font-size: 28px;
    }

    span {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }

    .gx-5 {
      --bs-gutter-x: unset;
    }
  }

  .ninth-banner.mb-5 {
    margin-bottom: 0 !important;

    h3 {
      font-size: 28px;
    }

    span {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }

    .accordion-item {
      .accordion-button {
        font-size: 13.5px;

        font-weight: 500;
      }

      .accordion-collapse {
        p {
          font-size: 14px;
        }
      }
    }
  }

  .eleventh-banner {
    .py-5 {
      h3 {
        font-size: 24px;
      }

      p {
        font-size: 17px;
      }

      .btn {
        font-size: 14px;
      }
    }
  }

  .ptb-40
  {
    padding: 20px 0px!important;
  }

  .eigth-banner {

    

    .main_content.pt-5.pb-5
    {
      padding:0px 0px;
    }
    h4 {
      font-size: 20px;

      text-align: center;
    }

    p {
      font-size: 14.5px;

      text-align: center;
    }

    .query_help {
      justify-content: center;
    }

    svg {
      height: 25px;
    }

    span {
      h6 {
        font-size: 11px;
      }

      p {
        font-size: 17px;
      }
    }

    ul {
      text-align: center;

      margin-bottom: 30px;

      h5 {
        font-size: 17.5px;
      }

      li {
        font-size: 15px;
      }
    }

    .Q-form {
      padding: 30px 20px;

      .form-control {
        padding: 11px;

        font-size: 14px;
      }

      .btn {
        font-size: 15px;

        padding: 5px;
      }
    }
  }
}

@media (min-width: 310px) and (max-width: 375px) {
  .third-banner.mb-5 {
    ul {
      flex-direction: row;

      li.nav-item {
        width: 33%;

        a.nav-link {
          padding: 0;
          font-size: 11px;
        }

        a.nav-link.active {
          padding: 0;
          font-size: 10px;
        }
      }
    }
  }

  .pt-80 {
    padding-top: 15px !important;
  }

  .bannerheading h1 {
    font-size: 13px;
  }

  .bannerheading h2 {
    font-size: 15px;
  }

  .bannerheading p {
    width: 100%;
    font-size: 13px;
    margin: 5px 0;
    line-height: 20px;
  }

  .certificate_img_list {
    li {
      img {
        width: 66px;
        height: 66px;
      }
    }
  }

  .homebannersectoin h6 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 10px 0;
  }
}

/**************Grocery*****************/

@media (min-width: 768px) and (max-width: 992px) {
  .gro_first-banner.mb-80 {
    margin-bottom: 0 !important;

    .right-content {
      text-align: center;
    }
  }

  .gro_second-banner {
    h1 {
      text-align: center;
    }

    span {
      text-align: center;
    }
  }

  .gro_third-banner.mb-5 {
    margin-bottom: 0 !important;

    .features {
      min-height: 212px;
    }
  }

  .gro_fifth-banner {
    .modal-content1 {
      min-height: 547px;

      .business_img-box {
        margin-top: 20px;
      }
    }
  }
}

@media (min-width: 310px) and (max-width: 768px) {
  .gro_first-banner.mb-80 {
    margin-bottom: 0 !important;
    height: 70vh;

    .py-5 {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .right-content {
      text-align: center;
    }

    h1.heading {
      font-size: 28px;

      line-height: 36px;

      text-align: center;
    }

    span {
      font-size: 28px;
    }

    p {
      font-size: 14px;

      color: #fff;

      text-align: center;
    }

    .btn {
      font-size: 14px;
      padding: 10px;
    }
  }

  .gro_second-banner {
    h1 {
      font-size: 28px;

      text-align: center;
    }

    span {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }
  }

  .gro_third-banner.mb-5 {
    margin-bottom: 0 !important;

    .third-title {
      font-size: 28px;
    }

    span {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }

    ul {
      flex-direction: row;

      li.nav-item {
        width: 33%;

        a.nav-link {
          padding: 0;
          font-size: 13px;
        }

        a.nav-link.active {
          padding: 0;
          font-size: 13px;
        }
      }
    }

    .features {
      min-height: 165px;

      padding: 10px;

      img {
        height: 50px;
      }

      h5 {
        font-size: 15px;
      }
    }
  }

  .gro_fourth-banner {
    h1.heading {
      font-size: 28px;

      line-height: 36px;
    }

    span {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }

    .borderright {
      border-right: none;
    }

    .bordertop {
      border-top: none;
    }

    .mt-5 {
      margin-top: 5px !important;

      padding: 0;
    }

    .pt-5 {
      padding-top: 10px !important;
    }

    .pb-5 {
      padding-bottom: 30px !important;
    }

    .img-fluid {
      width: 50px;

      height: 50px;

      object-fit: contain;
    }

    .head {
      h3 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .gro_fifth-banner {
    h1.heading {
      font-size: 28px;

      line-height: 36px;
    }

    span {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }

    .business_img-box {
      margin-top: 20px;
    }

    .modal-content1 {
      margin-top: 30px;

      .business-content {
        h4 {
          font-size: 19px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .pt-5 {
      padding-top: 0 !important;
    }
  }

  .gro_sixth-banner {
    h1 {
      font-size: 28px;

      span {
        font-size: 28px;
      }
    }

    p {
      font-size: 14px;
    }
  }

  .gro_eigth-banner {
    .py-5 {
      h3 {
        font-size: 28px;
      }

      p {
        font-size: 18px;
      }

      .btn {
        font-size: 15px;
      }
    }
  }
}

@media (min-width: 310px) and (max-width: 375px) {
  .sliders2 {
    padding: 0px 10px 0 10px;
  }

  .gro_second-banner img {
    height: 275px;
  }

  .gro_third-banner.mb-5 {
    padding-top: 0px !important;

    ul {
      flex-direction: row;

      li.nav-item {
        width: 33%;

        a.nav-link {
          padding: 0;
          font-size: 11px;
        }

        a.nav-link.active {
          padding: 0;
          font-size: 11px;
        }
      }
    }
  }
}

/*************** Edtech ****************/

@media (min-width: 310px) and (max-width: 991px) {
  .sixth-section {
    img {
      margin-top: 0;
    }

    .mobile-reverse {
      display: flex;
      flex-direction: row-reverse;
    }

    .works_section {
      h5 {
        text-align: left;
      }

      .para {
        text-align: left;
      }
    }
  }

  .eigth-banner {
    .border-right {
      border-right: none;
    }

    h4 {
      text-align: center;
    }

    p {
      text-align: center;
    }

    .contact_onfo {
      text-align: center;
    }

    .icon.svgiocn {
      svg {
        margin-bottom: 10px;
      }
    }

    ul {
      text-align: center;
    }
  }
}

@media (min-width: 310px) and (max-width: 768px) {
  .fourth-section {
    ul {
      li {
        width: 25%;

        a.nav-link.active {
          padding: 10px;
        }

        a.nav-link {
          padding: 10px;
        }
      }
    }
  }
}

@media (min-width: 310px) and (max-width: 767px) {
  .first-section.mb-80 {
    height: 70vh;

    h1 {
      font-size: 32px;
      line-height: 35px;
      padding-top: 30px;
      text-align: center;

      span {
        font-size: 32px;
        line-height: 35px;
        padding-top: 30px;
        text-align: center;
      }
    }

    p {
      font-size: 14px;
    }

    .btn {
      font-size: 14px;
    }
  }

  .third-section {
    .third-title {
      font-size: 28px;
      text-align: center;

      span {
        font-size: 28px;
        text-align: center;
      }
    }

    .third-para {
      font-size: 14px;
    }

    li {
      font-size: 14px;
    }
  }

  .fourth-section {
    h3.fourth-title {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }

    ul {
      li {
        width: 25%;

        a.nav-link.active {
          padding: 0px;
          font-size: 14px;
        }

        a.nav-link {
          padding: 0px;
          font-size: 14px;
        }
      }
    }

    .features {
      min-height: 155px;
      padding: 10px;

      img {
        height: 50px;
      }

      h5 {
        font-size: 15px;
      }
    }
  }

  .seventh-section {
    .py-5 {
      h3 {
        font-size: 28px;
      }

      p {
        font-size: 18px;
      }

      .btn {
        font-size: 15px;
      }
    }
  }

  .eigth-section {
    h3.eigth-title {
      font-size: 28px;

      span {
        font-size: 28px;
      }
    }

    p {
      font-size: 14px;
    }

    .head {
      h3 {
        font-size: 15px;
      }

      p {
        font-size: 13px;
      }
    }
  }

  .sixth-section {
    .sixth-title {
      font-size: 28px;

      span {
        font-size: 28px;
      }
    }

    p {
      font-size: 14px;
    }

    .works_section {
      h5 {
        font-size: 17px;
      }

      .para {
        font-size: 14px;
      }
    }

    .right-side {
      h5 {
        font-size: 17px;
      }

      .para {
        font-size: 14px;
      }
    }

    span.number {
      font-size: 13px;
      padding: 6px 11px;
    }

    img {
      height: 500px;
    }

    .right-side {
      h5 {
        font-size: 17px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .tenth-section {
    h3 {
      font-size: 28px;
    }

    span {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }
  }

  .fifth-section {
    h3.title {
      font-size: 28px;

      span {
        font-size: 28px;
      }
    }

    p {
      font-size: 14px;
    }

    .work {
      .images1 {
        height: 350px;
      }

      .images2 {
        height: 400px;
      }
    }

    a.btn {
      padding: 6px 16px;
      font-size: 14px;
      margin-top: 0px;
    }
  }
}

@media (min-width: 310px) and (max-width: 575px) {
  .third-section {
    li {
      font-size: 11px;
    }
  }

  .homebannersectoin .home_banner_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .homebannersectoin .bannerheading .certificate_img_list li img {
    width: 60px;
    height: 60px;
  }

  .fourth-section {
    ul {
      li {
        width: 35%;

        a.nav-link.active {
          padding: 0px;
          font-size: 13px;
        }

        a.nav-link {
          padding: 0px;
          font-size: 13px;
        }
      }
    }
  }

  .sixth-section {
    .right-side {
      .list {
        text-align: right;
      }
    }
  }

  .eigth-section {
    .bordertop {
      border-top: none;
    }

    .borderbottom {
      border-bottom: 1px solid #fcaf17;
    }

    .bordertop1 {
      border-top: 1px solid #fcaf17;
    }

    .borderright {
      border-right: none;
    }

    .borderleft {
      border-left: none;
    }
  }

  .tenth-section {
    .borderbottom {
      border-bottom: none;
    }

    .borderright {
      border-right: none;
    }
  }
}

@media (min-width: 360px) and (max-width: 540px) {
  .fifth-section {
    p {
      margin-bottom: 20px !important;
    }

    .work {
      .images1 {
        height: 230px;
      }

      .images2 {
        height: 185px;
      }
    }
  }

  .seventh-section {
    .py-5 {
      h3 {
        font-size: 21px;
        line-height: 31px;
      }

      p {
        font-size: 17px;
      }
    }
  }
}

@media (min-width: 310px) and (max-width: 360px) {
  .third-section li {
    font-size: 8px;
    padding: 0;
  }

  .third-section li::before {
    position: unset;
    display: none;
  }

  .fourth-section {
    ul {
      li {
        a.nav-link.active {
          font-size: 12px;
        }

        a.nav-link {
          font-size: 12px;
        }
      }
    }
  }

  .fifth-section {
    .work {
      .overlay {
        height: 90%;
      }
    }
  }

  .eigth-banner ul {
    margin-bottom: 20px;
  }
}

// end bhawana

// mukul media-query travel app and job-portal and healthcare

// mukul-responsive
//min-widht:200px and max-width:567px

@media only screen and (min-width: 200px) and (max-width: 575px) {

  .our-clients .marquee-items.left .items_logo 
  {
    // width:220px!important;
    width:100%;
    height:85px!important;
  }

  .our-clients .marquee-items.left .items_logo img {
    width: 100%!important;
    height: 70px!important;

}

  .portfolio-left-area h4 {
    color: white;
    font-size: 26px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-top: 50px;
  }

  .works-list-1 li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }

  .works-list li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }

  .travel-about-us .about-img {
    padding-top: 0px;
  }

  .py-40 {
    padding: 20px 0px;
  }

  .banner-sec
  {
    height: 350px!important;
  }

  .py-5.row
  {
    padding:1rem 0rem!important;
  }

  .travel-banner-content {
    position: absolute;
    top: 25%;
    left: 0%;
    z-index: 1;
    width: 100%;
  }

  .req-demo {
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;
  }

  .travel-banner-content h2 {
    color: white;
    font-size: 18px;
    font-weight: 600;
    font-family: var(--roboto-fonte);
    margin-bottom: 0px;
  }

  .travel-banner-content .desc {
    font-size: 14px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-top: 15px;

    line-height: 20px;
  }

  .about-content {
    margin-bottom: 30px;
  }

  .about-content .main-heading {
    font-size: 19px;
    font-weight: 600;
    color: #020202;
    margin: 10px 0px;
  }

  .about-content .desc {
    font-size: 14px;
  }

  .features-head h2 {
    font-size: 20px;
    font-weight: 600;
    color: #020202;
    font-family: "Roboto", sans-serif;
    text-align: center;
    margin-bottom: 10px;
  }

  .travel-choose-card .heading-part h4 {
    font-size: 13px;
  }

  .travel-choose-card {
    padding: 10px;
  }

  .nav-tabs .nav-item button {
    background-color: #e8ebf1;
    border-radius: 50px;
    color: #020202;
    padding: 5px 10px 5px 7px !important;
    margin: 0px !important;
    margin-right: 5px !important;
    font-size: 11px !important;
    font-weight: 600;
  }

  .portfolio-left-area {
    background-color: rgb(215, 164, 64);
    width: 100% !important;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
  }

  .connect {
    position: absolute;
    top: 25%;
    left: 5%;
  }

  .connect .desc {
    color: white;
    font-size: 18px !important;
    font-family: var(--roboto-fonte);
  }

  .quote {
    position: absolute;
    top: 80%;
    left: 5%;
    color: white;
    font-size: 20px;
    font-family: var(--roboto-fonte);
  }

  .portfolio-area {
    background-color: #f5e9ce;
    width: 100%;
    position: relative;
    min-height: 550px !important;
  }

  .sch-software-process-tabs {
    display: none;
  }

  .sch-software-development-process .faq-accordion {
    display: block;
  }

  .sch-software-development-process
    .faq-accordion
    .accordion
    .accordion-button {
    background-image: none !important;
    font-size: 15px;
  }

  .faq-accordion .accordion .accordion-button {
    background-image: none !important;
    font-size: 11px;
  }

  .faq-accordion .accordion .accordion-header svg {
    content: "";
    position: absolute;
    right: 4%;
    top: 35%;
  }

  .faq-accordion .accordion .accordion-body {
    background-color: rgb(252, 175, 23);
    color: white;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .sch-software-development-process .faq-accordion .accordion .accordion-body {
    background-color: rgb(252, 175, 23);
    color: white;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .features-card {
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s linear;
    min-height: 120px;
    margin-bottom: 25px;
  }

  .features-img img {
    width: 30px;
    height: 30px;
    margin-bottom: 0px;
    text-align: center;
  }

  .features-card h5 {
    padding: 5px;
    font-size: 12px;
    margin-top: 5px;
  }

  .features-head .desc {
    font-size: 12px;
  }

  .job-banner-sec .job-banner-content {
    position: absolute;
    top: 45%;
    left: 0%;
  }

  .job-banner-sec .job-banner-content h1 span {
    display: block;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
  }

  .e-hiring-model-list {
    margin: 10px 0px;
  }

  .e-hiring-model-list:nth-child(1) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(2) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(3) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(4) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(5) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(6) {
    position: relative;
    left: 0%;
  }

  .inner-icon-content h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .inner-icon-content p {
    font-size: 12px;
    line-height: 15px;
  }

  .discuss-specialists-content .desc {
    font-size: 13px;
    line-height: 20px;
  }

  .d-f-fd-cr {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .custom-crm-development-card-content .desc {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .custom-crm-development-card-content .main-head {
    font-size: 26px;
    color: #fcaf17;
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
  }

  .custom-crm-development-head .desc {
    width: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }

  .discuss-specialists-content .lets-talk-button {
    font-size: 12px;
    padding: 5px 16px;
  }

  .discuss-specialists-content .title {
    font-size: 18px;
  }

  .discuss-specialists-content .desc {
    font-size: 14px;
    color: white;
    text-align: center;
    margin: 10px 0px;
  }

  .job-banner-sec .job-banner-content h1 {
    color: white;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }

  .job-banner-sec .job-banner-content .desc {
    font-size: 14px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 30px;
  }

  .job-portal-services-head h2 {
    font-size: 21px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }

  .job-portal-services-head .desc {
    font-size: 11px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    width: 100%;
    line-height: 16px;
  }

  .on-demand-head-area .sub-head {
    font-size: 12px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 15px;
  }

  .pb-40 {
    padding-bottom: 20px;
  }

  .on-demand-head-area .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }

  .on-demand-my-tab .on-demand-tab-head {
    color: white;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 0px;
  }

  .admin-content-list li {
    color: white;
    line-height: 30px;
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
  }

  .admin-content-list-box {
    padding: 15px 0px 0px 0px;
  }

  .admin-content-list li svg {
    margin-right: 5px;
    font-size: 14px;
  }

  .on-demand-my-tab .on-demand-tab-head {
    font-size: 10px;
    margin-bottom: 5px;
  }

  .job-features-tab-pane .ad-features img {
    width: 14px;
    height: 14px;
  }

  .job-features-tab-pane .ad-features {
    position: absolute;
    left: 50.2%;
    top: 5px;
    z-index: 99999;
  }

  .job-features-tab-pane .co-features img {
    width: 14px;
    height: 14px;
  }

  .job-features-tab-pane .co-features {
    position: absolute;
    left: 11%;
    top: 4px;
    z-index: 99999;
  }

  .job-p-features-card .job-p-features-img img {
    width: 25px;
    height: 25px;
  }

  .job-p-features-card .job-p-features-img {
    width: 40px;
    height: 40px;
  }

  .job-p-features-card .job-p-features-content h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 13px;
  }

  .job-p-features-card .job-p-features-content .desc {
    font-size: 10px;
    line-height: 15px;
  }

  .job-p-features-card {
    padding: 5px;
    min-height: 135px;
    margin-bottom: 20px;
  }

  .our-recent-works-head h2 {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .our-recent-works-head .desc {
    font-size: 13px;
    width: 100%;
    line-height: 20px;
  }

  .why-choose-j-p .why-choose-j-p-head h2 {
    font-size: 19px;
    margin-bottom: 10px;
  }

  .why-choose-j-p .why-choose-j-p-head .desc {
    font-size: 12px;
    width: 100%;
    line-height: 15px;
  }

  .e-hiring-model .e-hiring-model-head h2 {
    font-size: 19px;
    margin-bottom: 10px;
  }

  .e-hiring-model .e-hiring-model-head .desc {
    font-size: 12px;
    width: 100%;
    line-height: 16px;
  }

  .e-hiring-model .e-hiring-model-head {
    text-align: center;
    padding: 0px;
  }

  // for healthcare

  .healthcare-banner-sec
  {
    min-height:450px!important;
  }

  .healthcare-banner-sec .healthcare-banner-content {
    position: absolute;
    top: 10%;
    left: 0%;
    z-index: 2;
  }

  .health-app-development-content h4 {
    font-size: 17px;
  }

  .health-about-content .main-heading {
    font-size: 20px;
    font-weight: 600;
    color: #020202;
    margin-top: 10px;
  }

  .health-about-content .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
  }

  .healthcare-banner-sec .healthcare-banner-content .main-heading {
    font-size: 18px;
  }

  .health-app-develop-content .main-heading {
    font-size: 22px;
    font-family: "Poppins", sans-serif;
  }

  .health-app-develop-content .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
    font-weight: 500;
  }

  .health-app-development-content .desc {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 25px;
  }

  .health-app-development-list li {
    position: relative;
    font-size: 14px;
    padding-left: 20px;
    font-family: "Open Sans", sans-serif;
    color: #51555c;
    font-weight: 600;
    line-height: 25px;
  }

  .health-app-develop-content h4 {
    font-size: 19px;
  }

  .growth-banner-content {
    position: relative;
    top: 0%;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .growth-banner-content .main-heading {
    font-size: 18px;
    color: white;
    margin-bottom: 25px;
    font-family: "Poppins", sans-serif;
    padding: 10px 0px;
  }

  .hexagon-main-img {
    position: relative;
    text-align: center;
    margin-bottom: 25px;
  }

  .hexagon-main-img .growth-banner-img-content {
    position: absolute;
    top: 27%;
    left: 20%;
    text-align: center;
    width: 60%;
  }

  .health-choose-us-card:nth-child(2) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-right: 0px;
    border-bottom: 0;
  }

  .mt-5 {
    margin-top: 0px !important;
  }

  .health-choose-us-main-box .free-consult-button {
    background-color: transparent;
    color: #fcaf17;
    border: 2px solid #fcaf17;
    border-radius: 5px;
    padding: 8px 10px;
    font-weight: 500;
    font-size: 14px;
  }

  .health-choose-us-main-box {
    margin-bottom: 25px;
  }

  .discuss-specialists {
    min-height: 200px !important;
  }

  // erpsoftware-responsive-by-mukul
  .erp-banner-sec {
    position: relative;
    min-height: 410px !important;
  }

  .erp-banner-sec .erp-banner-content {
    position: absolute;
    top: 3%;
    left: 0%;
  }

  .erp-banner-sec .erp-banner-content p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 20px;
  }

  .erp-banner-sec
    .erp-banner-content
    .erp-banner-content-list
    .erp-banner-list
    li {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    font-weight: 600;
    position: relative;
    padding-left: 20px;
    line-height: 25px;
  }

  .erp-banner-sec .erp-banner-content .blue-theme-button {
    background-color: #fcaf17;
    color: white;
    padding: 8px 12px;
    font-family: "Open Sans", sans-serif;
    border: 2px solid #fcaf17;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    display: inline-block;
    border-radius: 5px;
    font-size: 14px;
  }

  .erp-about-us .erp-about-content .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
  }

  .explore-erp-services-content .desc {
    margin-bottom: 20px;
    width: 100%;
    font-size: 14px;
  }

  .pb-4 {
    padding-bottom: 0.5rem !important;
  }

  .d-f-fc-r {
    display: flex;
    flex-direction: column-reverse;
  }

  .erp-development-services-card {
    margin-bottom: 35px;
    min-height: 180px;
  }

  .explore-erp-services-img {
    margin-bottom: 25px;
  }

  .erp-developed-modules-card {
    padding: 10px;
  }

  .erp-developed-modules-card .erp-developed-modules-content h4 {
    font-size: 14px;
  }

  .erp-developed-modules-card .erp-developed-modules-content .desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .border-right-0 {
    border-right: 1px solid #fcaf17;
  }

  .border-bottom-1 {
    border-bottom: 1px solid #fcaf17;
  }

  .explore-erp-services-content {
    padding-top: 0px;
  }

  .erp-banner-sec .erp-banner-content h1 {
    color: #fff;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 25px;
  }

  .erp-development-services-head .main-heading-type {
    color: #020202;
    font-size: 20px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
    line-height: 25px;
  }

  .erp-development-services-head .sub-heading {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-align: left;
  }

  .erp-about-us .erp-about-img img {
    width: 100%;
  }

  .erp-about-us .erp-about-content .main-heading {
    font-size: 20px;
    font-weight: 600;
    color: #020202;
  }

  .erp-achievements-sec p .let-discuss-button {
    background-color: white;
    color: #020202;
    padding: 8px 20px;
    font-weight: 600;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }

  .erp-achievements-sec .erp-achievements-head h2 {
    color: white;
    font-size: 18px;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }

  .software-development-item .software-development-content .desc {
    font-size: 16px;
    line-height: 25px;
  }

  .software-development-content h4 {
    font-size: 18px;
  }

  .software-development-item span {
    position: absolute;
    top: 30px;
  }

  .erp-achievements-sec p {
    text-align: center;
    margin-top: 25px;
    padding-bottom: 25px;
  }

  .erp-achievements-sec .erp-achievements-card span {
    color: white;
    font-size: 50px;
    font-weight: 700;
  }

  .erp-achievements-sec .erp-achievements-card h4 {
    color: white;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    margin-top: 15px;
  }

  // crm responsive 200 to 567px

  .crm-banner-sec
  {
    min-height:300px!important;
  }

  .crm-banner-sec .Crm-banner-content {
    position: absolute;
    z-index: 2;
    top: 5%;
    width: 100%;
    left: 0%;
  }

  .crm-banner-sec .Crm-banner-content .main-heading {
    color: white;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .crm-banner-sec .Crm-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 17px;
  }
  .crm-banner-sec .Crm-banner-content .transform-button {
    padding: 6px 12px;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    font-size: 14px;
  }

  .about-img img {
    min-height: 0px;

  }

  .about-img {
    margin-right: 0px;
  }

  .Crm-about-content .main-heading {
    font-size: 17px;
    font-weight: 600;
    color: #020202;
  }

  .why-you-need-crm .Crm-app-development-content .main-heading {
    font-size: 22px;
    position: relative;
  }

  .why-you-need-crm .Crm-app-development-content .desc {
    color: black;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .why-you-need-card {
    padding: 10px;
  }

  .why-you-need-card .why-you-need-content h4 {
    font-size: 16px;
    font-weight: 600;
  }

  .why-you-need-card .why-you-need-content .desc {
    font-size: 12px;
  }

  .crm-software-features .crm-software-features-head .main-heading {
    font-size: 22px;
    position: relative;
  }

  .crm-software-features .crm-software-features-head .desc {
    color: black;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  .crm-software-features
    .crm-software-features-card
    .crm-software-features-icon {
    text-align: center;
    margin-bottom: 15px;
  }

  .crm-software-features .crm-software-features-content .desc {
    color: white;
    font-size: 10px;
    font-family: "Open Sans", sans-serif;
    opacity: 0;
    transform: translateY(0.625rem);
    transition: all 0.125s ease-in-out;
    line-height: 15px;
  }

  .crm-software-features
    .crm-software-features-card
    .crm-software-features-icon
    img {
    width: 60px;
    height: 60px;
  }

  .crm-software-features
    .crm-software-features-card:hover
    .crm-software-features-content {
    opacity: 1;
    visibility: visible;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    min-height: 220px;
    max-height: 220px;
    overflow: scroll;
  }

  .crm-software-features .crm-software-features-card .title {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
  }

  .crm-software-features .crm-software-features-card {
    position: relative;
    padding: 10px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 35px;
    min-height: 100px;
    max-height: 100px;
  }

  .crm-software-features .crm-software-features-card:hover {
    position: relative;
    padding: 10px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 35px;
    min-height: 200px;
    max-height: 200px;
  }

  .crm-software-solutions-sec .crm-development-services-head .main-heading {
    font-size: 22px;
  }

  .crm-software-solutions-sec .crm-development-services-head .desc {
    color: black;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .container-box .card .card__head {
    color: black;
    background: #fcaf17;
    padding: 0.5em;
    transform: rotate(-90deg);
    transform-origin: 0% 0%;
    transition: all 0.5s ease-in-out;
    /* min-width: 100%; */
    text-align: center;
    position: absolute;
    bottom: -50%;
    /* visibility: hidden; */
    height: 100px;
    /* opacity: 0; */
    left: 0px;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 700;
    width: 210px;
  }

  .why-work-with-us-content h3 {
    font-size: 20px;
  }

  .why-work-with-us-content .desc {
    font-size: 14px;
  }

  .why-work-with-us-img {
    margin-bottom: 20px;
  }

  .d-f-fc-r {
    display: flex;
    flex-direction: column-reverse;
  }

  .custom-crm-development-head .main-head {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: left;
  }

  .custom-crm-development-head {
    text-align: left;
  }

  .crm-development-services-head .main-heading {
    color: #020202;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .btnall .btn-fix {
    background: transparent linear-gradient(91deg, #fcaf17 0%, #f4cf86 100%) 0%
      0% no-repeat padding-box;
    color: #fff;
    border-radius: 50px;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.06);
    font-size: 14px;
    font-weight: 500;
    position: relative;
    white-space: nowrap;
    transition: 0.8s;
    height: 45px;
    padding: 5px 60px 5px 25px;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
  }

  .crm-development-services-sec .col-xl-6:nth-child(2) {
    margin-top: 0px;
  }

  .crm-development-services-sec .col-xl-6:nth-child(3) {
    margin-top: 0px;
  }

  .crm-development-services-card {
    padding: 10px;
    min-height: 300px;
  }

  .crm-development-services-card .crm-development-services-icon img {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
  }

  .crm-development-services-head .desc {
    font-size: 13px;
    margin-bottom: 20px;
    line-height: 20px;
  }

  .erp-development-services-head {
    text-align: center;
  }

  .Sch-mt-about-content .desc {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }

  // school management 200px responsive css
  .sch-software-portfolio-head {
    text-align: left;
  }

  .school-mt-banner-sec .school-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 47%;
    width: 100%;
    left: 0%;
  }

  .school-mt-banner-sec .school-mt-banner-content .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .school-mt-banner-sec .school-mt-banner-content .sub-heading {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
  }

  .school-mt-banner-sec .school-mt-banner-content .transform-button {
    font-size: 14px;
    padding: 10px;
  }

  .Sch-mt-about-content .main-heading {
    font-size: 19px;
  }

  .sch-software-services-list {
    display: inline;
  }

  .sch-software-services-head .main-heading {
    font-size: 19px;
    margin-bottom: 0px;
  }

  .sch-software-services-card .sch-software-services-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin: 0 auto;
    margin-bottom: -25px;
  }

  .sch-software-services-card .sch-software-services-icon img {
    width: 35px;
    height: 35px;
  }

  .sch-software-services-card .sch-software-services-content {
    padding: 10px;
  }

  .sch-software-services-card .sch-software-services-content h5 {
    font-size: 14px;
    font-weight: 500;
    padding-top: 20px;
  }

  .sch-software-services-card .sch-software-services-content .desc {
    font-size: 12px;
    line-height: 16px;
  }

  .sch-software-modules-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .sch-software-modules-head .desc {
    font-size: 12px;
    line-height: 16px;
  }

  .sch-software-modules-card {
    min-height: 100px;
  }

  .sch-software-modules-card .sch-software-modules-content h5 {
    font-size: 13px;
    font-weight: 500;
  }

  .Sch-software-features .Sch-software-features-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .Sch-software-features .Sch-software-features-head .desc {
    font-size: 12px;
    line-height: 17px;
  }

  .Sch-mt-features-img img {
    border-radius: 5px;
    margin-bottom: 25px;
  }

  .Sch-mt-features-content {
    border: 1px solid #e5e5e5;
    padding: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: auto;
    margin-bottom: 25px;
  }

  .Sch-software-features .main-box {
    margin: 0px;
  }

  .sch-software-development-process
    .sch-software-development-process-head
    .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .sch-process-r {
    display: none;
  }

  .sch-software-solutions-advantages
    .sch-software-solutions-advantages-head
    .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  .sch-software-advantages-img img {
    border-radius: 5px;
    min-height: auto;
  }

  .sch-software-advantages-content {
    border-radius: 0px;
  }

  .sch-software-advantages-content .sch-software-advantages-list li {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    line-height: 30px;
  }

  .sch-software-portfolio-head .main-heading {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
  }

  // society-management-responsive-css-200px


  .society-mt-banner-sec
  {
    min-height: 350px!important;
  }
  .society-mt-banner-sec .society-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 20%;
    width: 100%;
    left: 0%;
  }

  .society-mt-banner-sec .society-mt-banner-content .main-heading {
    color: white;
    font-size: 17px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .society-mt-banner-sec .society-mt-banner-content .sub-heading {
  
    font-size: 13px;
    margin-bottom: 10px;
    line-height: 20px;
    text-align: left;
  }

  .society-mt-banner-sec .society-mt-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 8px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }

  .society-mt-about-us .society-mt-about-content .main-heading {
    font-size: 18px;
    color: #020202;
  }

  .society-mt-about-list-box {
    padding-left: 20px;
  }

  .society-mt-about-us .society-mt-about-content .h-about-button {
    margin-top: 10px;
    text-align: center;
  }

  .society-mt-features-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .society-mt-features-head .desc {
    font-size: 12px;
    line-height: 18px;
  }

  .erp-development-services-card h4 {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }

  .real-est-banner-sec .real-est-banner-content .sub-heading
  {
    font-size:13px;
  }

  // real-estate-responsive200px

  .real-estate-about-us .real-estate-about-content .main-heading {
    font-size: 18px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }

  .real-est-services-card .real-est-services-content h4 {
    font-size: 17px;
  }

  .real-estate-about-us
    .real-estate-about-content
    .real-estate-about-list-box
    .real-estate-about-list
    li {
    border-left: 1px dashed #bebebe;
    padding: 0px 10px 15px 20px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
  }

  .on-demand-my-tab-re .on-demand-tab-head {
    color: white;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
  }

  .admin-content-list-re li {
    color: white;
    line-height: 45px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }

  .admin-content-list-box-re {
    padding: 20px 0px 0px 15px;
  }

  .real-est-services-card .real-est-services-content .desc {
    font-size: 14px;
    line-height: 22px;
  }

  .real-est-development-services .real-est-development-services-head {
    text-align: left;
  }

  .real-est-app-development-features
    .app-development-left-side
    .app-development-left-side-card {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0px;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  .real-est-development-services
    .real-est-development-services-head
    .main-heading {
    font-size: 22px;
    margin-bottom: 0px;
  }

  .real-est-development-services .real-est-development-services-head .desc {
    font-size: 14px;
    width: 100%;
  }

  .real-est-app-development-features .real-est-app-development-head {
    text-align: left;
  }

  .real-est-app-development-features
    .real-est-app-development-head
    .main-heading {
    font-size: 23px;
  }

  .real-est-mngmnt-system .real-est-mngmnt-content .heading {
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  }

  .real-est-mngmnt-system .real-est-mngmnt-content .desc {
    color: white;
    font-size: 14px;
    margin: 15px 0px 20px 0px;
    line-height: 25px;
  }

  .on-demand-head-area-re .sub-head {
    color: white;
    font-size: 18px;
  }

  .on-demand-head-area-re .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 300 !important;
    line-height: 22px;
    color: white;
  }
  .real-est-banner-sec
  {
    min-height: 350px!important;
  }
  .real-est-banner-sec .real-est-banner-content {
    position: absolute;
    z-index: 2;
    top: 25%;
    width: 100%;
    left: 0%;
  }

  .real-est-banner-sec .real-est-banner-content .main-heading {
    color: white;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .real-est-banner-sec .real-est-banner-content .transform-button
  {
    padding: 8px 16px;
    font-size: 14px;
  }

  .real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card h5 {
    color: white;
    font-size: 14px;
  }

  .real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    text-align: center;
  }

  .real-est-mngmnt-system .real-est-mngmnt-content .real-est-mngmnt-card .desc {
    font-size: 12px;
    margin: 0;
    line-height: 17px;
  }

  .real-est-mngmnt-system
    .real-est-mngmnt-content
    .real-est-mngmnt-card
    .real-est-mngmnt-icon {
    margin-bottom: 10px;
  }

  .real-est-mngmnt-system
    .real-est-mngmnt-content
    .real-est-mngmnt-card
    .real-est-mngmnt-icon
    img {
    width: 60px;
    height: 60px;
  }

  .real-est-app-development-features
    .app-development-left-side
    .app-development-left-side-card
    .app-development-left-side-list
    .desc {
    font-size: 14px;
    line-height: 17px;
  }

  .real-est-app-development-features
    .app-development-left-side
    .app-development-left-side-card
    .app-development-left-side-list
    h3 {
    font-size: 16px;
  }

  .real-est-app-development-features
    .app-development-right-side
    .app-development-right-side-card
    .app-development-right-side-list
    .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .real-est-app-development-features
    .app-development-right-side
    .app-development-right-side-card
    .app-development-right-side-list
    h3 {
    font-size: 16px;
  }

  .real-est-app-development-features
    .app-development-right-side
    .app-development-right-side-card {
    padding: 10px 10px;
    margin-bottom: 0px;
  }

  .erp-portfolio-area .erp-portfolio-left-side {
    background-color: rgb(215, 164, 64);
    padding: 20px 15px;
  }

  .erp-portfolio-area .erp-portfolio-left-side h4 {
    color: white;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }

  .erp-portfolio-area .erp-portfolio-left-side .sub-heading-area {
    margin-bottom: 15px;
  }

  .erp-portfolio-area .erp-portfolio-left-side .connect-erp .desc {
    color: white;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
  }

  .quote-erp .explore-more-button {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    padding: 12px 32px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
  }

  // manufacturing-200px

 

  .manufacturing-banner-sec .manufacturing-banner-content {
    position: absolute;
    z-index: 2;
    top: 35%;
    width: 100%;
    left: 0%;
  }

  .healthcare-banner-sec .healthcare-banner-content .sub-heading
  {
    font-size: 13px;
  }

  .manufacturing-banner-sec .manufacturing-banner-content .main-heading {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .manufacturing-banner-sec .manufacturing-banner-content .sub-heading {
    margin-bottom: 20px;
    line-height: 20px;
    font-size: 14px;
  }

  .manufacturing-about-us .manufacturing-about-content .main-heading {
    font-size: 22px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
    padding-top: 15px;
  }

  .manuft-sft-solutions-sec .manuft-sft-solutions-head {
    text-align: left;
  }

  .manuft-sft-solutions-sec .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .manuft-sft-solutions-sec .manuft-sft-solutions-content .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .manuft-sft-solutions-sec .manuft-sft-solutions-content .sub-heading {
    font-size: 16px;
  }

  .d-f-fd-cr1 {
    display: flex;
    flex-direction: column-reverse;
  }

  .manuft-sft-services .manuft-sft-solutions-head {
    text-align: left;
  }

  .manuft-sft-services .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .manuft-sft-services
    .manuft-sft-services-list
    li
    .manuft-sft-services-inner-list
    h5 {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
  }

  .manuft-sft-services
    .manuft-sft-services-list
    li
    .manuft-sft-services-inner-list
    .desc {
    font-size: 15px;
    line-height: 20px;
  }

  .our-works-manuft-software .manuft-sft-works-head {
    text-align: left;
  }

  .our-works-manuft-software .manuft-sft-works-head .main-heading-type {
    color: #020202;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .our-works-manuft-software .manuft-sft-works-content .main-heading {
    font-weight: 600;
    font-size: 18px;
  }

  .our-works-manuft-software .manuft-sft-works-content .desc {
    margin-bottom: 20px;
    line-height: 20px;
    font-size: 14px;
  }

  .manuft-sft-works-content {
    margin-bottom: 20px;
  }

  .our-works-manuft-software .manuft-sft-works-content {
    padding-top: 0px;
  }

  .our-works-manuft-software .manuft-sft-works-content .contact-us-buttn {
    background-color: #fcaf17;
    color: white;
    padding: 10px 32px;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
  }

  .our-works-manuft-software .manuft-sft-works-content .sub-heading {
    background-color: black;
    color: white;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    padding: 5px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .discuss-specialists-content {
    z-index: 10;
    margin: 0 auto;
    padding: 10px 0px;
    text-align: center;
  }

  // retail-pos-200px

  .retail-pos-banner-sec .retail-pos-banner-content {
    position: absolute;
    z-index: 2;
    top: 56%;
    width: 95%;
    left: 0%;
  }

  .retail-pos-banner-sec .retail-pos-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    line-height: 25px;
  }

  .retail-pos-about-img {
    text-align: center;
  }

  .retail-pos-banner-sec .retail-pos-banner-content .main-heading {
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .retail-pos-banner-sec .retail-pos-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }

  .retail-pos-about-us .retail-pos-about-content .main-heading {
    font-size: 20px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }

  .retail-pos-software-importance .retail-pos-software-imp-head {
    text-align: left;
  }

  .retail-pos-software-importance
    .retail-pos-software-imp-head
    .main-heading-type {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .retail-pos-software-importance .retail-pos-software-imp-head .desc {
    width: 100%;
    text-align: left;
    font-size: 16px;
    line-height: 25px;
  }

  .retail-pos-software-importance
    .retail-pos-imp-card
    .retail-pos-imp-content
    .desc {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }

  .retail-pos-software-importance
    .retail-pos-imp-card
    .retail-pos-imp-content
    h3 {
    color: #3d3e42;
    font-size: 18px;
    margin-bottom: 5px;
    padding-top: 0px;
  }

  .retail-pos-software-importance
    .retail-pos-imp-card
    .retail-pos-imp-icon
    img {
    width: 20%;
  }

  .retail-pos-features-sec .retail-pos-features-head .main-heading-type {
    color: #020202;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .retail-pos-software-importance .retail-pos-imp-card {
    min-height: 330px;
  }

  .retail-pos-features-sec .retail-pos-features-head {
    text-align: left;
  }

  .retail-pos-features-sec .retail-pos-features-content {
    padding-top: 10px;
  }

  .retail-pos-features-sec .retail-pos-features-content .main-head {
    font-size: 21px;
    position: relative;
    padding-bottom: 15px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    line-height: 25px;
  }

  .retail-pos-features-sec .retail-pos-features-content .retail-pos-accord-ion {
    padding-top: 25px;
    margin-bottom: 25px;
  }

  .retail-pos-accord-ion .accordion-button {
    background-color: #fff;
    color: black;
    font-weight: 600;
    font-size: 16px;
    padding: 15px;
  }

  .retail-pos-accord-ion .plus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }

  .retail-pos-accord-ion .accordion-body {
    box-shadow: 0 90px 70px 0 rgba(0, 0, 0, 0.04),
      0 40px 35px 0 rgba(0, 0, 0, 0.03), 0 25px 15px 0 rgba(0, 0, 0, 0.03),
      0 11px 7px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.03) !important;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: #fcaf17;
    border: transparent;
  }

  .retail-pos-accord-ion .minus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }

  .retail-pos-features-img {
    text-align: center;
  }

  .pb-60 {
    padding-bottom: 40px;
  }

  .retail-pos-features-sec .explore-buttn-holder {
    text-align: center;
    margin-top: 30px;
  }

  .retail-pos-why-choose-us .retail-pos-why-choose-us-head {
    text-align: left;
  }

  .retail-pos-why-choose-us .retail-pos-why-choose-us-head .main-heading-type {
    color: #020202;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .retail-pos-why-choose-us
    .why-choose-retail-pos-card
    .why-choose-retail-pos-content
    .desc {
    font-size: 18px;
    line-height: 25px;
    padding: 15px 0px;
    text-align: center;
  }

  .whatsapp-bot-about-us .whatsapp-bot-about-us-head .main-head {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
  }
  .pt-50 {
    padding-top: 20px;
}

  .whatsapp-bot-about-big-img {
    margin-bottom: 20px;
  }

  .whatsapp-bot-about-small-img {
    text-align: center;
  }

  .whatsapp-bot-about-small-img img {
    width: 55%;
  }

  .why-you-need-whatsappbot .why-you-need-whatsappbot-head .main-heading {
    color: white;
    font-size: 20px;
  }

  .why-you-need-whatsappbot-head {
    .desc {
      font-size: 16px;
      line-height: 25px;
    }
  }

  .why-you-need-whatsappbot .why-you-need-whatsappbot-card {
    padding: 10px;
    margin-bottom: 20px;
    min-height: 160px;
  }

  .why-you-need-whatsappbot .why-you-need-whatsappbot-card h6 {
    padding: 5px;
    font-size: 14px;
  }

  .why-you-need-img img {
    width: 45%;
  }

  .whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-head .main-heading {
    font-size: 23px;
    font-weight: 600;
    /* line-height: 10px; */
  }

  .whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-card {
    max-width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    min-height: 210px;
  }

  .whatsapp-chatbot-benefits-card .desc {
    line-height: 20px;
  }

  .possible-channels-sec .possible-channels-head .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .possible-channels-sec .possible-channels-head .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .possible-channels-sec .possible-channels-tab-panes .tab-content {
    background-color: #f6f6f6 !important;
    border-radius: 10px;
    padding: 0px;
  }

  .possible-channels-sec
    .possible-channels-tab-panes
    .tab-content
    .left-side-whatsapp-content {
    position: relative;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    margin-top: 10% !important;
    margin-bottom: 25px;
  }

  .possible-channels-sec
    .possible-channels-tab-panes
    .tab-content
    .left-side-whatsapp-content
    .desc {
    color: black;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
  }

  .possible-channels-sec
    .possible-channels-tab-panes
    .nav-tabs
    .nav-item
    button {
    background-color: #e8ebf1;
    border-radius: 25px;
    color: #020202;
    padding: 8px 20px !important;
    font-weight: 600;
    font-size: 13px !important;
    margin: 0px 10px 10px 0px !important;
  }

  // whatsapp-bot-value-add-css

  .Value-added-features .Value-added-features-head .main-head {
    font-size: 24px;
    margin-bottom: 0px;
  }

  .possible-channels-sec .left-side-whatsapp-content {
    margin-top: 10% !important;
    margin-bottom: 5%;
  }

  .possible-channels-sec .left-side-whatsapp-content .main-heading {
    padding-top: 20px;
  }

  .possible-channels-sec .left-side-whatsapp-content .icon img {
    width: 70%;
  }

  .possible-channels-sec .left-side-whatsapp-content .desc {
    line-height: 20px;
    font-size: 14px;
  }

  .newportfolyo .card_portfoliyo .portfolicardview {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
  }

  .newportfolyo .card_portfoliyo p {
    font-size: 16px;
    margin: 10px 0;
    line-height: 25px;
  }

  .newportfolyo .card_portfoliyo h3 {
    font-size: 24px;
  }

  .newportfolyo .leftsidecontnt .text_right {
    width: 100%;
    text-align: center;
  }

  .newportfolyo .card_portfoliyo .leftcardportfoli {
    padding: 0px;
    margin-top: 5%;
  }

  .btnall.text-end {
    display: flex;
    justify-content: center;
  }

  .newportfolyo .card_portfoliyo .text_left {
    text-align: center;
    margin-left: 0%;
  }

  .newportfolyo li {
    padding: 37px 0;
    min-height: 100%;
  }

  .newportfolyo .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newportfolyo .card_portfoliyo .portfolicardview img {
    width: 80%;
    z-index: 999;
    position: relative;
    margin-top: 21%;
  }

  .newportfolyo .card_portfoliyo .portfolicardview::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }

  // mobilevalaresponsive

  .newportfolyom .card_portfoliyom .portfolicardviewm {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
    text-align: center;
  }

  .newportfolyom .card_portfoliyom p {
    font-size: 16px;
    margin: 10px 0;
    line-height: 25px;
  }

  .newportfolyom .card_portfoliyom h3 {
    font-size: 24px;
  }

  .newportfolyom .leftsidecontntm .text_right {
    width: 100%;
    text-align: center;
  }

  .newportfolyom .card_portfoliyom .leftcardportfolim {
    padding: 0px;
    margin-top: 5%;
    height: unset;
  }

  .btnall.text-end {
    display: flex;
    justify-content: center;
  }

  .newportfolyom .card_portfoliyom .text_left {
    text-align: center;
    margin-left: 0%;
  }

  .newportfolyom li {
    padding: 37px 0;
    min-height: 100%;
  }

  .newportfolyom .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newportfolyom .card_portfoliyom .portfolicardviewm img {
    width: 50%;
    z-index: 999;
    position: relative;
    margin-top: 12%;
  }

  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }

  .newportfolyom .leftsidecontntm .portfolicardviewm {
    text-align: center;
  }

  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }

  .f-d-cr {
    flex-direction: column-reverse;
  }

  .case-study ul.tabs li {
    cursor: pointer;
    border: 1px solid lightgray;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    transition: all 0.5s;
    font-size: 10px;
    padding: 5px 10px;
    margin-left: 10px;
  }

  .text-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
  }

  .title-section .desc {
    line-height: 25px;
    font-size: 16px;
  }

  // casestudy1

  .case-study-banner-sec .case-study-banner-content {
    position: absolute;
    z-index: 2;
    top: 10%;
    width: 100%;
    left: 0%;
    text-align: center;
  }

  .case-study-banner-sec .fasto-banner-img {
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 0%;
    text-align: center;
  }

  .case-study-banner-sec .fasto-banner-img img {
    width: 90%;
  }

  .case-study-banner-sec .case-study-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .case-study-banner-sec .case-study-banner-content .breadcrumb-casestudy-box {
    margin-bottom: 5px;
  }

  .case-study-banner-sec .case-study-banner-content .desc {
    font-size: 16px;
    line-height: 20px;
  }

  .about-client-sec .about-client-content-box .desc {
    font-size: 17px;
    line-height: 25px;
  }

  .about-client-sec .about-client-main-head .main-heading {
    font-size: 22px;
  }

  .about-client-sec .about-client-content-box {
    padding: 15px;
    position: relative;
  }

  .about-client-sec .about-client-content-box .right-quote {
    position: absolute;
    left: 5%;
    top: 0%;
    width: 10%;
  }

  .about-client-sec .about-client-main-head .main-heading1 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-bottom: 5px;
  }

  .about-client-sec .about-client-content-box .left-quote {
    position: absolute;
    right: 5%;
    bottom: 2%;
    width: 10%;
  }

  .business-model-sec .business-model-main-head .main-heading {
    font-size: 22px;
  }

  .business-model-sec .business-model-main-head .desc {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .business-model-sec .business-model-card {
    padding: 15px;
    border-radius: 5px;
    min-height: 200px;
    cursor: pointer;
    margin-bottom: 25px;
  }

  .market-research-sec .market-research-head .main-heading {
    font-size: 22px;
  }

  .market-research-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
  }

  .market-research-sec
    .market-research-head
    .market-research-content-box
    .market-research-content {
    padding: 15px;
    min-height: 250px;
    margin-bottom: 25px;
  }

  .market-research-sec .market-research-img {
    margin-top: 30px;
  }

  .technology-sec
    .technology-content-area
    .technology-card
    .technology-card-content {
    border-top: 1px solid #e5e5e5;
    padding-top: 80px;
  }

  .technology-card-style1 .technology-card-content {
    padding-top: 80px;
    text-align: center;
    margin: 25px 0px;
    border-top: 1px solid #e5e5e5;
  }

  .market-research-sec
    .market-research-head
    .market-research-content
    .market-research-content-list
    li {
    position: relative;
    padding-left: 35px;
    line-height: 25px;
    margin-bottom: 12px;
    font-size: 14px;
  }

  .challenges-and-solutions-sec
    .challenges-solutions-content
    .challenges-solutions-card
    .number-card {
    content: "";
    position: absolute;
    left: -9%;
  }

  .market-research-sec .market-research-head .market-research-content h5 {
    font-size: 16px;
  }

  .market-research-sec .market-research-head .market-research-content {
    padding-top: 20px;
    margin-bottom: 35px;
  }

  .technology-sec .technology-head .main-heading {
    font-size: 24px;
  }

  .technology-sec .technology-head .desc {
    font-size: 14px;
    margin-top: 15px;
  }

  .technology-sec .technology-head .market-research-content h5 {
    font-size: 16px;
  }

  .technology-sec .technology-head .market-research-content {
    padding-top: 20px;
  }

  .technology-sec
    .technology-head
    .market-research-content
    .market-research-content-list
    li {
    position: relative;
    padding-left: 35px;
    line-height: 20px;
    margin-bottom: 12px;
    font-size: 14px;
  }

  .challenges-and-solutions-sec .challenges-solutions-main-head .main-heading {
    font-size: 24px;
    padding-bottom: 15px;
  }

  .challenges-and-solutions-sec .challenges-solutions-main-head {
    margin-top: 0%;
    margin-bottom: 5%;
  }

  .challenges-and-solutions-sec .challenges-and-solutions-img img {
    margin-left: 0%;
  }

  .results-growth-sec .results-growth-head .main-heading {
    font-size: 24px;
  }

  .results-growth-sec .results-growth-content .results-growth-content-box {
    margin-top: 20px;
    background-color: white;
    padding: 15px;
  }

  .results-growth-sec
    .results-growth-content
    .results-growth-content-box
    .results-growth-content-list
    li {
    line-height: 20px;
    font-size: 14px;
  }

  .future-prospects-sec .future-prospects-head .main-heading {
    font-size: 24px;
  }

  .future-prospects-sec .future-prospects-head {
    background-color: white;
    min-height: 300px;
    padding: 25px;
  }

  .future-prospects-sec .future-prospects-head .future-prospects-content-box {
    padding-top: 15px;
  }

  .future-prospects-sec
    .future-prospects-head
    .future-prospects-content-box
    .future-prospects-content-list
    li {
    line-height: 25px;
    font-size: 14px;
  }

  .future-prospects-sec .future-prospects-img {
    margin-left: 0%;
    margin-top: 2%;
  }

  .conclusion-sec .conclusion-sec-head .main-heading {
    font-size: 24px;
  }

  .conclusion-sec .conclusion-content-box {
    padding: 15px;
    margin-top: 10px;
  }

  .conclusion-sec .conclusion-content-box .desc {
    line-height: 25px;
    font-size: 16px;
  }

  // huddle-heart

  .huddle-heart-banner-sec .huddle-heart-banner-content {
    margin-top: 5%;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .mt-30 {
    margin-top: 15px;
  }

  .huddle-heart-banner-sec .case-study-banner-content img {
    width: 50%;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .transform-button {
    color: white;
    background-color: #41b2e6;
    border: 1px solid #41b2e6;
    padding: 10px 24px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 14px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-img {
    position: absolute;
    z-index: 2;
    top: 45%;
    right: 0%;
    text-align: center;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }

  .huddle-heart-vision-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
  }

  .huddle-heart-vision-sec .market-research-head .main-heading {
    font-size: 20px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content img {
    width: 40%;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-img img {
    width: 100%;
    margin-top: 25%;
  }

  .huddle-heart-banner-sec
    .huddle-heart-banner-content
    .huddle-heart-intro-list
    li {
    font-size: 14px;
    line-height: 20px;
  }

  .huddle-heart-vision-sec
    .market-research-head
    .market-research-content-box
    .market-research-content {
    padding: 10px;
    min-height: 300px;
    margin-bottom: 25px;
    border-bottom: 3px solid #35b6ff;
  }

  .huddle-heart-vision-sec .market-research-img {
    margin-top: 8%;
  }

  .huddle-heart-features-sec .pt-110 {
    padding-top: 0px;
  }

  .huddle-heart-features-sec .sec-title .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }

  .huddle-heart-features-sec .column {
    padding: 0px 10px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card {
    min-height: 400px;
    margin-bottom: 40px;
    padding: 15px 15px 10px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card .card-heading {
    font-weight: 600;
    font-size: 18px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card .desc {
    padding-top: 10px;
    font-size: 14px;
    line-height: 25px;
  }

  .enhanced-communication-sec .enhanced-communication-main-head .main-heading {
    font-size: 20px;
    padding-bottom: 15px;
  }

  .enhanced-communication-sec .enhanced-communication-card::before {
    display: none;
  }

  .enhanced-communication-sec .enhanced-communication-card {
    margin-top: 0%;
    margin-bottom: 35px;
    padding: 20px;
    min-height: 348px;
    position: relative;
  }

  .enhanced-communication-sec .enhanced-img::after {
    display: none;
  }

  .enhanced-communication-sec .enhanced-img::before {
    display: none;
  }

  .enhanced-communication-sec .enhanced-img {
    width: 70%;
    min-height: 450px;
    position: relative;
    margin-bottom: 10%;
  }

  .enhanced-communication-card h5 {
    font-size: 18px;
  }

  .improved-safety-sec .huddle-heart-technology-head .main-heading {
    font-size: 20px;
    padding-top: 10px;
  }

  .huddle-heart-impacts-sec .huddle-heart-impacts-head .main-heading {
    font-size: 20px;
    padding-bottom: 10px;
  }

  .huddle-heart-impacts-sec .huddle-heart-impacts-card {
    padding: 10px;
    min-height: 356px;
    margin-bottom: 30px;
  }

  .huddle-heart-imapcts-img {
    margin-bottom: 5%;
  }

  .challenges-lessons-huddle-heart .challenges-lessons-main-head .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }

  .challenges-lessons-huddle-heart .challenges-lessons-img {
    margin-bottom: 15%;
  }

  .challenges-lessons-huddle-heart
    .challenges-lessons-content
    .challenges-lessons-card
    .challenges-lessons-icon {
    position: absolute;
    left: 0;
    top: -12%;
    right: 0;
    margin: 0 auto;
  }

  .challenges-lessons-huddle-heart
    .challenges-lessons-content
    .challenges-lessons-card {
    margin-bottom: 45px;
    padding: 40px 15px 15px 15px;
  }

  .profile-banner-sec .screen-mob {
    width: 70%;
    min-height: 450px;
  }

  .screen-mob img {
    width: 96%;
  }

  .profile-banner-sec .mt-80 {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .future-prospects-sec .business-model-main-head .main-heading {
    font-size: 21px;
    padding-bottom: 10px;
  }

  .future-prospects-sec
    .business-model-card
    .business-model-list-box
    .business-model-list
    li
    .card-head {
    font-size: 18px;
  }

  .future-prospects-sec .business-model-card {
    min-height: 285px;
    margin-bottom: 25px;
    padding: 10px 20px;
  }

  // plywood-bazar-casestudy-200px

  .plywood-bazar-banner-sec .plywood-bazar-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }

  .plywood-bazar-banner-sec .plywood-bazar-banner-content {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .plywood-bazar-banner-sec .plywood-bazar-banner-img {
    text-align: end;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .plywood-bazar-about-us .about-content-box .main-heading {
    font-weight: 500;
    font-size: 28px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #212529;
  }

  .plywood-bazar-about-us .about-content-list-box .about-content-list li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    line-height: 20px;
  }

  .plywood-bazar-about-us .about-content-box .sub-heading {
    font-size: 16px;
  }

  .mission-or-vision-plywood .mission-vision-right-shape {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }

  .mission-or-vision-plywood .left-side-box {
    margin-bottom: 25px;
  }

  .mission-or-vision-plywood .mission-vision-left-shape {
    position: absolute;
    left: 0;
    bottom: 30px;
    display: none;
  }

  .mission-or-vision-plywood .mission-vision-wrapper::after {
    position: absolute;
    bottom: 24%;
  }

  .mission-or-vision-plywood .right-side-box {
    margin-top: 50%;
  }

  .our-client-requirement .about-client-area .main-head {
    font-size: 26px;
  }

  .our-client-requirement .about-client-area .desc {
    font-size: 16px;
    padding-top: 0%;
    line-height: 25px;
  }

  .our-client-requirement .client-req-area .main-head {
    font-size: 26px;
    font-weight: 500;
  }

  .our-client-requirement .client-req-area .client-req-list li {
    border-left: 2px solid #f7c553;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 20px;
  }

  .solution-given-sec .solution-given-content .main-heading {
    color: #b08218;
    font-size: 22px;
    font-weight: 500;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list
    li
    .heading {
    font-size: 20px;

    font-weight: 500;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list
    li::marker {
    font-size: 20px;
  }

  .solution-given-sec .solution-given-content .sub-heading {
    color: #b08218;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .plywoodbazar-features-head
    .sub-heading {
    font-size: 14px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .plywoodbazar-features-head
    .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list {
    height: 480px;
    overflow-y: auto;
    padding-left: 30px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list
    li {
    list-style-type: decimal;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  }

  .plywoodbazar-result-sec .plywoodbazar-result-main-head .desc {
    color: black;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    line-height: 20px;
  }

  .plywoodbazar-result-sec .plywoodbazar-result-main-head .main-heading {
    font-size: 26px;
    color: #b08218;
    margin-bottom: 10px;
  }

  .our-work-screens .our-work-screens-main-head {
    display: none;
  }

  //  Rocare-casestudy-200px

  .RoCare-banner-sec .RoCare-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .RoCare-banner-sec .RoCare-banner-content .transform-button {
    padding: 8px 24px;
    font-size: 14px;
  }

  .RoCare-banner-sec .RoCare-banner-content .sub-head {
    color: #000000;
    font-size: 15px;
  }

  .RoCare-banner-sec .RoCare-banner-content .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .RoCare-banner-sec .RoCare-banner-content .mt-30 {
    margin-top: 20px;
  }

  .Rocare-aboutus .Rocare-aboutus-main-head {
    text-align: center;
    margin-top: 10%;
  }

  .Rocare-aboutus .Rocare-aboutus-main-head .main-heading {
    font-size: 26px;
  }

  .Rocare-aboutus .Rocare-aboutus-main-head .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .Rocare-aboutus .Rocare-aboutus-main-head .main-heading::before {
    content: "Service";
    -webkit-text-stroke: 2px #1f1f1f;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    font-size: 70px;
    bottom: 0px;
    -webkit-text-fill-color: transparent;
    opacity: 0.1;
    z-index: 0;
    width: 100%;
  }

  .client-requirements-sec .client-requirements-main-head {
    background-color: white;
    padding: 70px 10px 20px 10px;
  }

  .client-requirements-sec .client-requirements-main-head .main-heading {
    font-size: 24px;
  }

  .client-requirements-sec .client-requirements-main-head .desc {
    font-size: 14px;
    line-height: 25px;
  }

  .client-requirements-sec
    .client-requirements-main-head
    .main-heading::before {
    position: absolute;
    font-size: 70px;
    bottom: 0px;
  }

  .Rocare-features-sec .content-heading .features-heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .Rocare-features-sec .features-content .white-box {
    padding: 15px;
  }

  .Rocare-features-sec .features-content .white-box h5 {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .Rocare-features-sec .features-content .white-box h5 img {
    width: 28px;
    margin-right: 5px;
  }

  .Rocare-features-sec .features-content .white-box .features-list li {
    font-size: 15px;
    line-height: 25px;
  }

  .Rocare-customer-slide {
    text-align: center;
  }

  .Rocare-customer-slide img {
    width: 80%;
  }

  .Rocare-features-sec .Rocare-features-main-head .main-heading {
    font-size: 26px;
  }

  .rocare-challenges-sec .rocare-challenges-content .main-heading {
    text-transform: capitalize;
    font-size: 26px;
    position: relative;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .rocare-challenges-sec .rocare-challenges-content .sub-heading {
    font-size: 17px;
  }

  .padding-zero {
    padding: 0px;
  }

  // Nikkypore-casestudy-200px

  .NikkyPore-banner-sec .NikkyPore-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .mt-30 {
    margin-top: 15px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .sub-head {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .desc {
    font-size: 14px;
    line-height: 25px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content {
    margin-top: 4%;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-img {
    text-align: end;
    margin-top: 10%;
    animation: 4s ease-in-out infinite floatY;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .transform-button {
    padding: 10px 24px;
    font-size: 15px;
  }

  .Nikkypore-project-summary .Nikkypore-main-head .main-heading {
    font-size: 24px;
  }

  .Nikkypore-goals-objectives
    .Nikkypore-goals-objectives-main-head
    .main-heading {
    font-size: 24px;
  }

  .Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card {
    min-height: 100%;
  }

  .Nikkypore-goals-objectives .goals-obj-content-box {
    margin-top: 5%;
  }

  .Nikkypore-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
  }

  .mt-3 {
    margin-top: 0px !important;
  }

  .Nikkypore-features-sec .customer-app-features-content {
    margin-top: 0%;
  }

  .challenges-and-solutions-sec
    .challenges-sec
    .challenges-content-list
    li
    .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .challenges-and-solutions-sec .challenges-solutions-img {
    margin-bottom: 5%;
  }

  .challenges-and-solutions-sec .challenges-sec h4 {
    font-size: 20px;
  }

  .challenges-and-solutions-sec
    .challenges-and-solutions-main-head
    .main-heading {
    font-size: 22px;
  }

  .visual-designs-sec .visual-designs-main-head .main-heading {
    font-size: 24px;
  }

  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide {
    margin: 0 auto;
    text-align: center;
  }

  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide img {
    width: 80%;
  }

  .visual-designs-sec .visual-designs-wrapper {
    padding: 0px;
  }

  .visual-designs-sec .visual-designs-wrapper::before {
    display: none;
  }

  .visual-designs-sec .visual-designs-main-head .desc {
    margin-bottom: 0px;
  }

  // Deyor-Casestudy-200px

  .deyor-main-banner-sec .deyor-main-banner-content {
    padding-left: 0%;
    margin-top: 10%;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .desc {
    color: #222a41;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-main-banner-logo {
    margin-bottom: 15px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li {
    flex-basis: 50%;
    margin-top: 10px;
    font-size: 16px;
  }

  .deyor-main-banner-sec {
    min-height: 470px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li h5 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 25px;
  }

  .deyor-about-us .deyor-about-us-main-head .main-heading {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .deyor-about-us {
    padding: 20px 0px 20px 0px;
  }

  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box {
    position: relative;
    cursor: pointer;
    margin-bottom: 80%;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-content
    .main-heading {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .desc {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-content
    .deyor-about-us-content-list
    li {
    font-size: 16px;
    line-height: 30px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-content
    .deyor-about-us-content-list
    li
    .list-bar {
    position: absolute;
    left: 0;
    top: 15px;
  }

  .deyor-features-sec .deyor-features-main-head .main-heading {
    font-size: 26px;
    font-weight: 600;
  }

  .deyor-features-sec .first-child-bg-img {
    display: none;
  }

  .deyor-features-sec .deyor-features-content {
    margin-top: 0%;
    padding-left: 10px;
    margin-left: 0px;
    background: #fff;
    z-index: 99;
    width: 100%;
    position: relative;
  }

  .deyor-features-sec .deyor-features-content .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .deyor-features-sec .deyor-features-content .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 7px;
    display: block;
    margin-bottom: 10px;
  }

  .deyor-features-sec .feature-user2 .second-child-bg-img {
    display: none;
  }

  .deyor-features-sec .feature-user3 {
    position: relative;
    padding-top: 25px;
  }

  .deyor-features-sec .deyor-features-content1 {
    margin-top: 10%;
  }

  .deyor-features-sec .deyor-features-content1 .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .deyor-features-sec .feature-user3 .third-child-bg-img {
    display: none;
  }

  .deyor-features-sec .feature-user2 {
    padding-top: 20px;
    position: relative;
  }

  .deyor-objective-sec .deyor-objective-img {
    margin-top: 2%;
  }

  .deyor-objective-sec .deyor-objective-content-box {
    margin-top: 10%;
  }

  .deyor-objective-sec .deyor-objective-main-content-box .main-heading {
    font-size: 24px;
    padding-bottom: 10px;
  }

  .deyor-methodology-sec .deyor-methodology-content-box .sub-heading {
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 600;
  }

  .deyor-methodology-sec .deyor-methodology-main-head .main-heading {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
    position: relative;
    padding-bottom: 10px;
  }

  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 {
    margin-top: 10%;
  }

  .deyor-methodology-sec .deyor-methodology-img .mt-10 {
    margin-top: 10%;
    width: 100%;
    text-align: center;
    margin-left: 0%;
    margin-bottom: 10%;
  }

  .deyor-methodology-sec .deyor-methodology-img {
    margin-top: 10%;
  }

  .d-f-fd-cr {
    display: flex;
    flex-direction: column-reverse;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box {
    padding: 25px 15px 20px;
    margin-top: 10%;
    margin-right: 0%;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .main-heading {
    font-size: 26px;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }

  .deyor-solutions-sec .deyor-solutions-main-head .main-heading {
    font-size: 26px;
  }

  .deyor-solutions-sec .deyor-solutions-content-box {
    padding: 25px 15px 20px;
    margin-top: 10%;
    margin-left: 0%;
  }

  .deyor-solutions-sec .deyor-solutions-content-box .main-heading {
    font-size: 26px;
  }

  .deyor-solutions-sec .deyor-solutions-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }

  .deyor-work-screens-sec .deyor-work-screens-main-head .main-heading {
    font-size: 20px;
  }

  .deyor-work-screens-sec .deyor-work-screens-main-head {
    text-align: left;
  }

  // samagri-200px-responsive
  .samagri-banner-sec .samagri-banner-shape img {
    position: absolute;
    width: 36%;
    z-index: 1;
    left: 35px;
  }

  .samagri-banner-sec .samagri-banner-content .main-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
  }

  .samagri-banner-sec .samagri-banner-content .desc {
    font-size: 18px;
    color: #413d2c;
    font-weight: 600;
    line-height: 30px;
  }

  .samagri-banner-sec .samagri-banner-content .desc1 {
    font-size: 16px;
    color: #413d2c;
    font-weight: 400;
    line-height: 25px;
  }

  .samagri-banner-sec .samagri-banner-content {
    margin-top: 10%;
    padding-bottom: 10%;
  }

  .samagri-aboutus-sec .samagri-aboutus-wrap-box {
    background-color: white;
    padding: 10px;
    justify-content: space-between;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    span
    svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li {
    display: flex;
    align-items: start;
    line-height: 35px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    span
    svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li {
    line-height: 35px;
  }

  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box {
    margin-top: 0%;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-content-box
    .main-heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-content-box
    .desc {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-img-box
    .samagri-logo-img
    img {
    width: 60%;
  }

  .samagri-project-highlights .samagri-project-highlights-img .circle img {
    position: absolute;
    left: 40%;
    top: 160px;
    width: 60%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-img
    .circle
    .circle-content {
    position: absolute;
    top: 47%;
    left: 60%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-img
    .circle
    .circle-content
    .main-heading
    span {
    display: block;
    font-size: 26px;
    color: #c48041;
  }

  .samagri-project-highlights
    .samagri-project-highlights-img
    .circle
    .circle-content
    .main-heading {
    font-size: 24px;
    font-weight: 600;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(1) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(2) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(3) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(4) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(-24px 29px 25px rgba(12, 12, 13, 0.32));
    text-align: center;
    line-height: 75px;
    margin-bottom: 20px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-icon
    img {
    width: 45%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-area {
    padding-left: 0px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-area
    .desc {
    font-size: 16px;
    line-height: 25px;
    color: #fff;
  }

  .samagri-work-screens {
    position: relative;
    width: 100%;
    min-height: 350px;
  }

  .samagri-work-screens .samagri-work-screen-content .main-heading {
    font-size: 22px;
    font-weight: 600;
  }

  .samagri-work-screens .samagri-work-screen-content {
    margin-top: 0%;
  }

  .samagri-work-screens .samagri-work-screen-content .desc {
    line-height: 20px;
    font-size: 13px;
    width: 70%;
  }

  .samagri-features-sec .samagri-features-main-head {
    margin-top: 5%;
  }

  .samagri-features-sec .samagri-features-main-head .main-heading {
    color: black;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .samagri-features-sec .samagri-features-card {
    padding: 15px 10px;
    margin-bottom: 20px;
    min-height: 140px;
  }

  .samagri-features-sec .samagri-features-card .card-heading {
    font-size: 14px;
    margin-bottom: 0px !important;
  }

  .samagri-features-sec .samagri-features-img {
    margin-top: 10%;
  }

  .samagri-challenges-sec .samagri-challenges-main-head .main-heading {
    font-size: 22px;
    color: white;
  }

  .samagri-challenges-sec .samagri-challenges-content {
    margin-top: 0%;
  }

  .samagri-challenges-sec
    .samagri-challenges-content
    .samagri-challenges-card
    .samagri-challenges-card-heading
    .main-heading {
    text-align: center;
    font-size: 14px;
    color: white;
    padding: 10px;
  }

  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card {
    padding: 5px;
  }

  .samagri-challenges-sec
    .samagri-challenges-content
    .samagri-challenges-card
    .desc {
    padding: 5px;
    line-height: 20px;
    font-size: 14px;
  }

  .samagri-challenges-sec .samagri-challenges-img {
    margin-bottom: 5%;
  }

  .samagri-contact-banner
    .position-relative-z-index-1
    .samagri-contact-banner-content
    .main-heading {
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .samagri-contact-banner
    .position-relative-z-index-1
    .samagri-contact-banner-content
    .desc {
    color: white;
    font-size: 14px;
    line-height: 20px;
  }

  .samagri-contact-banner {
    padding: 70px 0px;
  }

  // turning-point-200px

  .turning-point-banner-sec .turning-point-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 15px;
  }

  .turning-point-banner-sec .turning-point-banner-content {
    margin-top: 5%;
  }

  .turning-point-banner-sec .turning-point-banner-img img {
    margin-top: 5%;
    padding: 5% 0%;
  }

  .turning-point-banner-sec .turning-point-banner-content .transform-button {
    padding: 10px 24px;
    font-weight: 500;
  }

  .turning-point-project-highlights-sec
    .turning-point-project-highlights-head
    .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }

  .turning-point-project-highlights-sec
    .project-highlights-content-area
    .project-highlights-card-style1 {
    position: relative;
    margin-top: 10%;
    border-top: 1px solid #eee;
  }

  .turning-point-project-highlights-sec
    .project-highlights-content-area
    .project-highlights-card {
    position: relative;
    margin-top: 5%;
  }

  .turning-point-features-sec .customer-app-features-content {
    margin-top: 0%;
  }

  .turning-point-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .turning-point-challenges-and-solutions-sec
    .challenges-and-solutions-main-head
    .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .turning-point-challenges-and-solutions-sec .challenges-sec {
    margin-top: 5%;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-main-head
    .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-wrapper::before {
    display: none;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-wrapper
    .turning-point-visual-designs-slide
    img {
    width: 65%;
  }

  .turning-point-conclusion-sec
    .turning-point-conclusion-sec-head
    .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .turning-point-conclusion-sec .turning-point-conclusion-content-box .desc {
    color: white;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper {
    background-color: #f0686c;
    padding: 20px 0px 20px;
    position: relative;
  }

  // digital-marketing-page-200px-responsivecss

  .topbar-inner p {
    line-height: normal;
    font-size: 12px;
  }
  .digital-mkt-banner-sec
  {
    min-height:360px!important;
  }

  .digital-mkt-banner-sec .digital-mkt-banner-content {
    position: absolute;
    z-index: 2;
    top: 5%;
    width: 100%;
    left: 0%;
  }

  .digital-mkt-banner-sec .digital-mkt-banner-content .main-heading {
    color: white;
    font-size: 17px;
    margin-bottom: 10px;
  }
  .digital-mkt-banner-sec .digital-mkt-banner-content .desc {
    color: white;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .digital-mkt-banner-sec .digital-mkt-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 25px;
  }



  .digital-mkt-banner-sec .digital-mkt-banner-content .transform-button {
    color: white !important;
    padding: 8px 10px;
    font-weight: 400;
    font-size: 14px;
  }
}

// end-200px

// end of min-width:200px and max-width:567px

@media only screen and (min-width: 576px) and (max-width: 992px) {
  .portfolio-left-area h4 {
    color: white;
    font-size: 34px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-top: 50px;
  }

  .portfolio-left-area .sub-heading-area {
    margin-bottom: 30px;
    margin-left: 50px;
  }

  .works-list-1 li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }

  .works-list li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }

  .travel-about-us .about-img {
    padding-top: 0px;
  }

  .py-40 {
    padding: 20px 0px;
  }

  .travel-banner-content {
    position: absolute;
    top: 55%;
    left: 0%;
    width: 60%;
    z-index: 1;
  }

  .travel-banner-content .desc {
    font-family: 18px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-top: 10px;
    font-size: 15px;
    line-height: 25px;
  }

  .travel-choose-card .heading-part h4 {
    color: #020202;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    background: white;
  }

  .travel-banner-content h2 {
    color: white;
    font-size: 26px;
    font-weight: 600;
    font-family: var(--roboto-fonte);
    margin-bottom: 0px;
  }

  .about-content .main-heading {
    font-size: 20px;
    font-weight: 600;
    color: #020202;
    margin-top: 10px;
  }

  .about-content .desc {
    font-size: 14px;
  }

  .features-head h2 {
    font-size: 24px;
    font-weight: 700;
    color: #020202;
    font-family: "Roboto", sans-serif;
    text-align: center;
    margin-bottom: 15px;
  }

  .nav-tabs .nav-item button {
    background-color: #e8ebf1;
    border-radius: 50px;
    color: #020202;
    padding: 8px 20px 8px 20px !important;
    margin: 0px !important;
    margin-right: 20px !important;
    font-size: 13px !important;
    font-weight: 600;
  }

  .works-list li {
    margin-right: 34px;
    margin-bottom: 10px;
  }

  .portfolio-left-area {
    background-color: rgb(215, 164, 64);
    width: 100% !important;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
  }

  .connect {
    position: absolute;
    top: 25%;
    left: 5%;
  }

  .connect .desc {
    color: white;
    font-size: 20px;
    font-family: var(--roboto-fonte);
  }

  .quote {
    position: absolute;
    top: 80%;
    left: 5%;
    color: white;
    font-size: 20px;
    font-family: var(--roboto-fonte);
  }

  .portfolio-area {
    background-color: #f5e9ce;
    width: 100%;
    position: relative;
    min-height: 550px !important;
  }

  .sch-software-process-tabs {
    display: none;
  }

  .sch-software-development-process .faq-accordion {
    display: block;
  }

  .sch-software-development-process
    .faq-accordion
    .accordion
    .accordion-button {
    background-image: none !important;
    font-size: 15px;
  }

  .faq-accordion .accordion .accordion-button {
    background-image: none !important;
    font-size: 12px;
  }

  .faq-accordion .accordion .accordion-header svg {
    content: "";
    position: absolute;
    right: 4%;
    top: 35%;
  }

  .faq-accordion .accordion .accordion-body {
    background-color: rgb(252, 175, 23);
    color: white;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .features-card {
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s linear;
    min-height: 115px;
    margin-bottom: 25px;
    width: 85%;
  }

  .features-img img {
    width: 30px;
    height: 30px;
    margin-bottom: 0px;
    text-align: center;
  }

  .features-card h5 {
    padding: 5px;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 500;
  }

  .features-head .desc {
    font-size: 12px;
  }

  .job-banner-sec .job-banner-content {
    position: absolute;
    top: 33%;
    left: 0%;
  }

  .e-hiring-model-list {
    margin: 10px 0px;
  }

  .e-hiring-model-list:nth-child(1) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(2) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(3) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(4) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(5) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(6) {
    position: relative;
    left: 0%;
  }

  .inner-icon-content h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .inner-icon-content p {
    font-size: 12px;
    line-height: 15px;
  }

  .discuss-specialists-content .desc {
    font-size: 13px;
  }

  .discuss-specialists-content .lets-talk-button {
    font-size: 14px;
    padding: 8px 32px;
  }

  .discuss-specialists-content .title {
    font-size: 22px;
  }

  .job-banner-sec .job-banner-content h1 {
    color: white;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }

  .job-banner-sec .job-banner-content .desc {
    font-size: 16px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 40px;
    width: 70%;
  }

  .job-portal-services-head h2 {
    font-size: 21px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }

  .job-portal-services-head .desc {
    font-size: 12px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    // width: 100%;
    line-height: 16px;
  }

  .on-demand-head-area .sub-head {
    font-size: 12px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 15px;
  }

  .pb-40 {
    padding-bottom: 20px;
  }

  .on-demand-head-area .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }

  .on-demand-my-tab .on-demand-tab-head {
    color: white;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 0px;
  }

  .admin-content-list li {
    color: white;
    line-height: 30px;
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
  }

  .admin-content-list-box {
    padding: 15px 0px 0px 0px;
  }

  .admin-content-list li svg {
    margin-right: 5px;
    font-size: 14px;
  }

  .on-demand-my-tab .on-demand-tab-head {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .job-features-tab-pane .ad-features img {
    width: 14px;
    height: 14px;
  }

  .job-features-tab-pane .ad-features {
    position: absolute;
    left: 51.2%;
    top: 5px;
    z-index: 99999;
  }

  .job-features-tab-pane .co-features img {
    width: 14px;
    height: 14px;
  }

  .job-features-tab-pane .co-features {
    position: absolute;
    left: 30%;
    top: 7px;
    z-index: 99999;
  }

  .job-p-features-card .job-p-features-content h4 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
  }

  .job-p-features-card .job-p-features-content .desc {
    line-height: 20px;
  }

  .job-p-features-card {
    padding: 5px;
    min-height: 200px;
    margin-bottom: 20px;
  }

  .our-recent-works-head h2 {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .our-recent-works-head .desc {
    font-size: 13px;
    width: 100%;
    line-height: 20px;
  }

  .why-choose-j-p .why-choose-j-p-head h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .why-choose-j-p .why-choose-j-p-head .desc {
    font-size: 12px;
    //    width: 100%;
    line-height: 15px;
  }

  .e-hiring-model .e-hiring-model-head h2 {
    font-size: 19px;
    margin-bottom: 10px;
  }

  .e-hiring-model .e-hiring-model-head .desc {
    font-size: 12px;
    width: 100%;
    line-height: 16px;
  }

  .e-hiring-model .e-hiring-model-head {
    text-align: center;
    padding: 0px;
  }

  .job-services-card {
    min-height: 310px;
    margin-bottom: 25px;
  }

  .our-recent-works-card .our-recent-works-content p {
    color: white;
    margin-bottom: 20px;
    font-size: 13px;
  }

  .our-recent-works-card .our-recent-works-content h4 {
    color: white;
    margin-bottom: 0px;
    font-size: 18px;
  }

  .recent-work-bottom .desc {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .why-choose-jp-card .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
  }

  // for healthcare

  .health-app-development-list li {
    position: relative;
    font-size: 14px;
    padding-left: 20px;
    font-family: "Open Sans", sans-serif;
    color: #51555c;
    font-weight: 600;
  }

  .health-choose-us-card .health-choose-us-list .title {
    color: #020202;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }

  .healthcare-banner-sec .healthcare-banner-content {
    position: absolute;
    top: 50%;
    left: 2%;
    width: 52%;
    z-index: 2;
  }

  .healthcare-banner-sec .healthcare-banner-content .main-heading {
    font-size: 32px;
  }

  .health-app-develop-content .main-heading {
    font-size: 31px;
    font-family: "Poppins", sans-serif;
  }

  .health-app-develop-content .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
    font-weight: 500;
  }

  .growth-banner-content {
    position: relative;
    top: 0%;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .growth-banner-content .main-heading {
    font-size: 26px;
    color: white;
    margin-bottom: 30px;
    font-family: "Poppins", sans-serif;
    padding: 10px 0px;
  }

  .hexagon-main-img {
    position: relative;
    text-align: center;
    margin-bottom: 0px;
  }

  .hexagon-main-img .growth-banner-img-content {
    position: absolute;
    top: 27%;
    left: 20%;
    text-align: center;
    width: 60%;
  }

  .health-choose-us-card:nth-child(2) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-right: 0px;
    border-bottom: 0;
  }

  .health-choose-us-main-box .free-consult-button {
    background-color: transparent;
    color: #fcaf17;
    border: 2px solid #fcaf17;
    border-radius: 5px;
    padding: 12px 20px;
    font-weight: 600;
  }

  .health-choose-us-main-box {
    margin-bottom: 25px;
  }

  .discuss-specialists {
    min-height: 200px !important;
  }

  .health-choose-us-card:nth-child(4) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 5px;
    border-right: 1px solid #e5e5e5;
    border-top: 0;
  }

  .health-choose-us-card:nth-child(1) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 5px;
    border-right: 0;
    border-bottom: 0;
  }

  .health-choose-us-card:nth-child(2) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-right: 1px solid #e5e5e5;
  }

  .health-choose-us-card:nth-child(5) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-top: 0;
    border-right: 0;
  }

  .health-choose-us-card:nth-child(3) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-top-right-radius: 5px;
    border-right: 0;
  }

  .hexagon-main-img .growth-banner-img-content .desc {
    font-size: 11px;
    color: white;
    line-height: 20px;
    margin-top: 15px;
  }

  // erpsoftware-responsive-by-mukul

  .erp-banner-sec .erp-banner-content {
    position: absolute;
    top: 16%;
    left: 5% !important;
    width: 80%;
  }

  .why-choose-erp-content .desc {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
  }

  .why-choose-erp-content h4 {
    font-size: 15px;
  }

  .why-choose-erp-card {
    position: relative;
    border-right: 1px solid #fcaf17;
    padding: 15px;
  }

  .erp-banner-sec .erp-banner-content h1 {
    color: white;
    font-size: 32px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
    font-weight: 800;
    line-height: 35px;
  }

  .erp-development-services-head .main-heading-type {
    color: #020202;
    font-size: 21px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
    line-height: 25px;
  }

  .erp-development-services-head .sub-heading {
    font-size: 12px;
    line-height: 20px;
    width: 90%;
  }

  .erp-achievements-sec .erp-achievements-head h2 {
    color: white;
    font-size: 32px;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }

  .software-development-item .software-development-content .desc {
    font-size: 16px;
    line-height: 25px;
  }

  .software-development-content h4 {
    font-size: 18px;
  }

  .software-development-item span {
    position: absolute;
    top: 30px;
  }

  .erp-achievements-sec p {
    text-align: center;
    margin-top: 25px;
    padding-bottom: 25px;
  }

  .erp-achievements-sec .erp-achievements-card span {
    color: white;
    font-size: 50px;
    font-weight: 700;
  }

  .erp-achievements-sec .erp-achievements-card h4 {
    color: white;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    margin-top: 15px;
  }

  .erp-banner-sec .erp-banner-content h1 {
    color: white;
    font-size: 30px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
    font-weight: 700;
  }

  .erp-banner-sec .erp-banner-content p {
    color: #020202;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    margin-bottom: 30px;
  }

  .erp-banner-sec {
    background-position: left !important;
  }

  .erp-banner-sec .erp-banner-content h1 {
    color: #000;
    font-size: 28px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .erp-achievements-sec {
    min-height: 0px !important;
  }

  .explore-erp-services-content {
    padding-top: 0px;
  }

  .d-f-fc-r {
    display: flex;
    flex-direction: row;
  }

  .explore-erp-services-img {
    margin-bottom: 30px;
  }

  .erp-developed-modules-card {
    padding: 10px;
  }

  .erp-developed-modules-card .erp-developed-modules-content h4 {
    font-size: 14px;
  }

  .erp-developed-modules-card .erp-developed-modules-content .desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .explore-erp-development-list li {
    margin-bottom: 5px;
    font-size: 11px;
  }

  .explore-erp-services-content .main-heading {
    font-size: 16px;
  }

  .explore-erp-services-content .desc {
    margin-bottom: 15px;
    width: 100%;
    font-size: 12px;
  }

  .explore-erp-development-list li img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  .custom-crm-development-head .desc {
    width: 90%;
    text-align: center;
    margin: 0 auto;
    font-size: 13px;
    line-height: 20px;
  }

  .custom-crm-development-head .desc {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    line-height: 25px;
  }

  .crm-software-solutions-sec .crm-development-services-head .main-heading {
    font-size: 23px;
  }

  .crm-software-solutions-sec .crm-development-services-head .desc {
    color: black;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
  }

  .crm-software-features .crm-software-features-head .main-heading {
    font-size: 26px;
    position: relative;
    padding-bottom: 15px;
  }

  .crm-software-features .crm-software-features-head .desc {
    color: black;
    font-size: 13px;
    width: 100%;
    line-height: 20px;
  }

  .crm-software-features .crm-software-features-card .title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  .why-you-need-crm .Crm-app-development-content .main-heading {
    font-size: 23px;
    position: relative;
  }

  .why-you-need-crm .Crm-app-development-content .desc {
    color: black;
    font-size: 13px;
    width: 100%;
  }

  .crm-banner-sec .Crm-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 65%;
    left: 5%;
  }

  .crm-banner-sec .Crm-banner-content .main-heading {
    color: white;
    font-size: 23px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  .school-mt-banner-sec .school-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 62%;
    left: 2%;
  }

  .school-mt-banner-sec .school-mt-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .school-mt-banner-sec .school-mt-banner-content .sub-heading {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
  }

  .school-mt-banner-sec .school-mt-banner-content .transform-button {
    font-size: 14px;
    padding: 10px;
  }

  .Sch-mt-about-content .main-heading {
    font-size: 19px;
  }

  .sch-software-services-head .main-heading {
    font-size: 19px;
    margin-bottom: 0px;
  }

  .sch-software-services-card .sch-software-services-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }

  .sch-software-services-card .sch-software-services-icon img {
    width: 35px;
    height: 35px;
  }

  .sch-software-services-card .sch-software-services-content {
    padding: 10px;
  }

  .sch-software-services-card .sch-software-services-content h5 {
    font-size: 14px;
    font-weight: 500;
  }

  .sch-software-services-card .sch-software-services-content .desc {
    font-size: 12px;
    line-height: 16px;
  }

  .sch-software-modules-head .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .sch-software-modules-head .desc {
    font-size: 12px;
    line-height: 16px;
  }

  .sch-software-modules-card {
    min-height: 120px;
  }

  .sch-software-modules-card .sch-software-modules-content h5 {
    font-size: 14px;
    font-weight: 500;
  }

  .Sch-software-features .Sch-software-features-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .Sch-software-features .Sch-software-features-head .desc {
    font-size: 12px;
    line-height: 17px;
  }

  .Sch-mt-features-img img {
    border-radius: 5px;
    margin-bottom: 25px;
  }

  .Sch-mt-features-content {
    border: 1px solid #e5e5e5;
    padding: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: auto;
    margin-bottom: 25px;
  }

  .Sch-software-features .main-box {
    margin: 0px;
  }

  .sch-software-development-process
    .sch-software-development-process-head
    .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .sch-process-r {
    display: none;
  }

  .sch-software-solutions-advantages
    .sch-software-solutions-advantages-head
    .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  .sch-software-advantages-img img {
    border-radius: 5px;
    min-height: auto;
    margin-bottom: 25px;
  }

  .sch-software-advantages-content {
    border-radius: 0px;
  }

  .sch-software-advantages-content .sch-software-advantages-list li {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    line-height: 30px;
  }

  .d-f-fd-cr {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .sch-software-portfolio-head .main-heading {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
  }

  // school management 200px responsive css
  .sch-software-portfolio-head {
    text-align: left;
  }

  .school-mt-banner-sec .school-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 50%;
    width: 100%;
    left: 0%;
  }

  .school-mt-banner-sec .school-mt-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .school-mt-banner-sec .school-mt-banner-content .sub-heading {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
  }

  .school-mt-banner-sec .school-mt-banner-content .transform-button {
    font-size: 14px;
    padding: 10px;
  }

  .Sch-mt-about-content .main-heading {
    font-size: 19px;
  }

  .sch-software-services-head .main-heading {
    font-size: 19px;
    margin-bottom: 0px;
  }

  .sch-software-services-card .sch-software-services-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }

  .sch-software-services-card .sch-software-services-icon img {
    width: 35px;
    height: 35px;
  }

  .sch-software-services-card .sch-software-services-content {
    padding: 10px;
  }

  .sch-software-services-card .sch-software-services-content h5 {
    font-size: 14px;
    font-weight: 500;
  }

  .sch-software-services-card .sch-software-services-content .desc {
    font-size: 12px;
    line-height: 16px;
  }

  .sch-software-modules-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .sch-software-modules-head .desc {
    font-size: 12px;
    line-height: 16px;
  }

  .sch-software-modules-card {
    min-height: 100px;
  }

  .sch-software-modules-card .sch-software-modules-content h5 {
    font-size: 13px;
    font-weight: 500;
  }

  .Sch-software-features .Sch-software-features-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .Sch-software-features .Sch-software-features-head .desc {
    font-size: 12px;
    line-height: 17px;
  }

  .Sch-mt-features-img img {
    border-radius: 5px;
    margin-bottom: 25px;
  }

  .Sch-mt-features-content {
    border: 1px solid #e5e5e5;
    padding: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: auto;
    margin-bottom: 25px;
  }

  .Sch-software-features .main-box {
    margin: 0px;
  }

  .sch-software-development-process
    .sch-software-development-process-head
    .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .sch-process-r {
    display: none;
  }

  .sch-software-solutions-advantages
    .sch-software-solutions-advantages-head
    .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  .sch-software-advantages-img img {
    border-radius: 5px;
    min-height: auto;
  }

  .sch-software-advantages-content {
    border-radius: 0px;
  }

  .sch-software-advantages-content .sch-software-advantages-list li {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    line-height: 30px;
  }

  .sch-software-portfolio-head .main-heading {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
  }

  // society-management-responsive-css-200px

  .society-mt-banner-sec .society-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 45%;
    width: 55%;
    left: 0%;
  }

  .society-mt-banner-sec .society-mt-banner-content .main-heading {
    color: white;
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .society-mt-banner-sec .society-mt-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }

  .society-mt-banner-sec .society-mt-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 8px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }

  .society-mt-about-us .society-mt-about-content .main-heading {
    font-size: 18px;
    color: #020202;
  }

  .society-mt-about-list-box {
    padding-left: 20px;
  }

  .society-mt-about-us .society-mt-about-content .h-about-button {
    margin-top: 10px;
    // text-align: center;
  }

  .society-mt-features-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .society-mt-features-head .desc {
    font-size: 12px;
    line-height: 18px;
  }

  .erp-development-services-card h4 {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }

  // real-est-cssforipad

  .real-est-banner-sec .real-est-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .real-est-banner-sec .real-est-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 50%;
    left: 4%;
  }

  .real-estate-about-us .real-estate-about-content .main-heading {
    font-size: 23px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }

  .real-estate-about-us .real-estate-about-content .real-estate-about-heading {
    font-size: 20px;
  }

  .real-estate-about-us
    .real-estate-about-content
    .real-estate-about-list-box
    .real-estate-about-list
    li {
    border-left: 1px dashed #bebebe;
    padding: 0px 10px 15px 20px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
  }

  .real-est-development-services .real-est-development-services-head {
    text-align: left;
  }

  .real-est-development-services
    .real-est-development-services-head
    .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .real-est-development-services .real-est-development-services-head .desc {
    font-size: 14px;
    margin: 0 auto;
    width: 100%;
  }

  .real-est-services-card {
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 25px;
    min-height: 282px;
  }

  .real-est-services-card .real-est-services-content h4 {
    font-size: 17px;
  }

  .real-est-services-card .real-est-services-content .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .real-est-services-card .real-est-services-icon {
    padding: 0px;
    padding-bottom: 5px;
  }

  .on-demand-head-area-re .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 25px;
  }

  .on-demand-head-area-re .sub-head {
    font-size: 20px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 10px;
  }

  .real-est-app-development-features
    .app-development-right-side
    .app-development-right-side-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    min-height: 95px;
  }

  .real-est-app-development-features
    .app-development-left-side
    .app-development-left-side-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  .real-est-app-development-features .real-est-app-development-head {
    text-align: left;
  }

  .real-est-app-development-features
    .real-est-app-development-head
    .main-heading {
    font-size: 26px;
  }

  .real-est-app-development-features
    .app-development-left-side
    .app-development-left-side-card
    .app-development-left-side-list
    li {
    margin-right: 85px;
  }

  .app-development-features-img {
    text-align: center;
  }

  .real-est-mngmnt-system .real-est-mngmnt-content .heading {
    color: white;
    font-size: 26px;
    font-weight: 600;
    line-height: 35px;
  }

  .real-est-mngmnt-system .real-est-mngmnt-content .desc {
    color: white;
    font-size: 16px;
    margin: 10px 0px 20px 0px;
  }

  .real-est-app-development-features .app-development-right-side {
    margin-bottom: 0px;
  }

  .erp-portfolio-area .erp-portfolio-left-side {
    background-color: rgb(215, 164, 64);
    padding: 20px 15px;
  }

  .erp-portfolio-area .erp-portfolio-left-side h4 {
    color: white;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }

  .erp-portfolio-area .erp-portfolio-left-side .sub-heading-area {
    margin-bottom: 15px;
  }

  .erp-portfolio-area .erp-portfolio-left-side .connect-erp .desc {
    color: white;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
  }

  .quote-erp .explore-more-button {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    padding: 12px 32px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
  }

  // manufacturing-above 568px

  .manufacturing-banner-sec .manufacturing-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 58%;
    left: 0%;
  }

  .manufacturing-banner-sec .manufacturing-banner-content .main-heading {
    font-size: 21px;
    margin-bottom: 5px;
  }

  .manufacturing-banner-sec .manufacturing-banner-content .sub-heading {
    margin-bottom: 20px;
    line-height: 25px;
  }

  .manufacturing-about-us .manufacturing-about-content .main-heading {
    font-size: 22px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
    padding-top: 15px;
  }

  .manuft-sft-solutions-sec .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .manuft-sft-solutions-sec .manuft-sft-solutions-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .manuft-sft-solutions-sec
    .manuft-sft-solutions-content
    .mft-solutions-list-box
    .manuft-sol-cnt-list
    .content
    h6 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 14px;
  }

  .manuft-sft-services-inner-list h5 {
    font-size: 18px;
  }

  .manuft-sft-solutions-sec .manuft-sft-solutions-content .sub-heading {
    font-size: 16px;
  }

  .mft-sol-inner-list li image {
    width: 80px;
  }

  .manuft-sft-solutions-sec
    .manuft-sft-solutions-content
    .manuft-solutions-left-cnt
    .manuft-sol-cnt-list
    li
    .mft-sol-inner-list
    h6 {
    font-size: 13px;
    margin-bottom: 0px;
  }

  .manuft-sft-solutions-content .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .manuft-sft-services-inner-list .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .manuft-sft-services .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .manuft-sft-services
    .manuft-sft-services-list
    li
    .manuft-sft-services-inner-list
    h5 {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
  }

  .manuft-sft-services
    .manuft-sft-services-list
    li
    .manuft-sft-services-inner-list
    .desc {
    font-size: 15px;
    line-height: 20px;
  }

  .our-works-manuft-software .manuft-sft-works-head {
    text-align: left;
  }

  .our-works-manuft-software .manuft-sft-works-head .main-heading-type {
    color: #020202;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .our-works-manuft-software .manuft-sft-works-content .main-heading {
    font-weight: 600;
    font-size: 20px;
  }

  .our-works-manuft-software .manuft-sft-works-content .desc {
    margin-bottom: 20px;
    line-height: 25px;
  }

  .manuft-sft-works-content {
    margin-bottom: 20px;
  }

  .our-works-manuft-software .manuft-sft-works-content .sub-heading {
    background-color: black;
    color: white;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    padding: 5px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .discuss-specialists-content {
    z-index: 10;
    margin: 0 auto;
    padding: 10px 0px;
    text-align: center;
  }

  // retail-pos-568px

  .retail-pos-banner-sec .retail-pos-banner-content {
    position: absolute;
    z-index: 2;
    top: 60%;
    width: 55%;
    left: 5%;
  }

  .retail-pos-banner-sec .retail-pos-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    line-height: 25px;
  }

  .retail-pos-about-img {
    text-align: center;
  }

  .retail-pos-banner-sec .retail-pos-banner-content .main-heading {
    color: white;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .retail-pos-banner-sec .retail-pos-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }

  .retail-pos-about-us .retail-pos-about-content .main-heading {
    font-size: 24px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }

  .retail-pos-software-importance .retail-pos-software-imp-head {
    text-align: left;
  }

  .retail-pos-software-importance
    .retail-pos-software-imp-head
    .main-heading-type {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .retail-pos-software-importance .retail-pos-software-imp-head .desc {
    width: 100%;
    text-align: left;
    font-size: 16px;
    line-height: 25px;
  }

  .retail-pos-software-importance .retail-pos-imp-card {
    border-radius: 20px;
    min-height: 200px;
    position: relative;
    border: 1px solid transparent;
    margin-top: 0px;
    text-align: center;
  }

  .retail-pos-software-importance
    .retail-pos-imp-card
    .retail-pos-imp-content
    .desc {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }

  .retail-pos-software-importance
    .retail-pos-imp-card
    .retail-pos-imp-content
    h3 {
    color: #3d3e42;
    font-size: 16px;
    margin-bottom: 5px;
    padding-top: 0px;
    margin-top: 35px;
  }

  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-content {
    padding-top: 20px;
    text-align: left;
  }

  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-icon {
    content: "";
    position: absolute;
    top: 1%;
    left: 0%;
    right: 0%;
    margin: 0 auto;
  }

  .retail-pos-software-importance
    .retail-pos-imp-card
    .retail-pos-imp-icon
    img {
    width: 25%;
  }

  .retail-pos-features-sec .retail-pos-features-head .main-heading-type {
    color: #020202;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .retail-pos-features-sec .retail-pos-features-head {
    text-align: center;
  }

  .retail-pos-features-sec .retail-pos-features-content {
    padding-top: 10px;
  }

  .retail-pos-features-sec .retail-pos-features-content .main-head {
    font-size: 21px;
    position: relative;
    padding-bottom: 15px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    line-height: 25px;
  }

  .retail-pos-features-sec .retail-pos-features-content .retail-pos-accord-ion {
    padding-top: 25px;
    margin-bottom: 25px;
  }

  .retail-pos-accord-ion .accordion-button {
    background-color: #fff;
    color: black;
    font-weight: 600;
    font-size: 16px;
    padding: 15px;
  }

  .retail-pos-accord-ion .plus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }

  .retail-pos-accord-ion .accordion-body {
    box-shadow: 0 90px 70px 0 rgba(0, 0, 0, 0.04),
      0 40px 35px 0 rgba(0, 0, 0, 0.03), 0 25px 15px 0 rgba(0, 0, 0, 0.03),
      0 11px 7px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.03) !important;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: #fcaf17;
    border: transparent;
  }

  .retail-pos-accord-ion .minus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }

  .retail-pos-features-img {
    text-align: center;
    padding-top: 30px;
  }

  .pb-60 {
    padding-bottom: 40px;
  }

  .retail-pos-features-sec .explore-buttn-holder .explore-buttn {
    padding: 10px 20px;
  }

  .retail-pos-features-sec .explore-buttn-holder {
    text-align: center;
    margin-top: 30px;
  }

  .retail-pos-why-choose-us .retail-pos-why-choose-us-head {
    text-align: center;
  }

  .retail-pos-why-choose-us .retail-pos-why-choose-us-head .main-heading-type {
    color: #020202;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .retail-pos-why-choose-us .why-choose-retail-pos-card {
    padding: 15px;
    border-radius: 16px;
    margin-bottom: 25px;
    min-height: 237px;
  }

  .retail-pos-why-choose-us
    .why-choose-retail-pos-card
    .why-choose-retail-pos-content
    h3 {
    text-align: center;
    font-size: 20px;
    width: 60%;
    margin: 0 auto;
  }

  .retail-pos-why-choose-us
    .why-choose-retail-pos-card
    .why-choose-retail-pos-icon
    img {
    width: 20%;
  }

  .retail-pos-why-choose-us
    .why-choose-retail-pos-card
    .why-choose-retail-pos-content
    .desc {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  // whatsapp-bot-568px

  .whatsapp-bot-about-us .whatsapp-bot-about-us-head .main-head {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
  }

  .whatsapp-bot-about-big-img {
    margin-bottom: 20px;
  }

  .whatsapp-bot-about-small-img {
    text-align: center;
  }

  .whatsapp-bot-about-small-img img {
    width: 55%;
  }

  .why-you-need-whatsappbot .why-you-need-whatsappbot-head .main-heading {
    color: white;
    font-size: 24px;
  }

  .why-you-need-whatsappbot-head {
    .desc {
      font-size: 16px;
      line-height: 25px;
    }
  }

  .why-you-need-whatsappbot .why-you-need-whatsappbot-card {
    padding: 10px;
    margin-bottom: 20px;
    min-height: 160px;
  }

  .why-you-need-whatsappbot .why-you-need-whatsappbot-card h6 {
    padding: 5px;
    font-size: 16px;
  }

  .why-you-need-img img {
    width: 25%;
  }

  .whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-head .main-heading {
    font-size: 23px;
    font-weight: 600;
    /* line-height: 10px; */
  }

  .whatsapp-chatbot-benefits .whatsapp-chatbot-benefits-card {
    max-width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    min-height: 210px;
  }

  .whatsapp-chatbot-benefits-card .desc {
    line-height: 20px;
  }

  .possible-channels-sec .possible-channels-head .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .possible-channels-sec .possible-channels-head .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .possible-channels-sec .possible-channels-tab-panes .tab-content {
    background-color: #f6f6f6 !important;
    border-radius: 10px;
    padding: 20px;
  }

  .possible-channels-sec
    .possible-channels-tab-panes
    .tab-content
    .left-side-whatsapp-content {
    position: relative;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    width: 92%;
    margin: 0 auto;
    margin-top: 50% !important;
    margin-bottom: 25px;
  }

  .btnall .btn-fix {
    display: block;
    margin: 0 auto;
    height: 50px;
  }

  .fntch_fnl_inn .develmntser .btnall {
    margin-top: 0pc;
  }

  .fntch_fnl_inn .develmntser {
    margin-top: 20px;
    text-align: center;
  }

  .possible-channels-sec
    .possible-channels-tab-panes
    .tab-content
    .left-side-whatsapp-content
    .desc {
    color: black;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
  }

  .possible-channels-sec
    .possible-channels-tab-panes
    .nav-tabs
    .nav-item
    button {
    background-color: #e8ebf1;
    border-radius: 25px;
    color: #020202;
    padding: 5px 15px !important;
    font-weight: 600;
    font-size: 13px !important;
    margin: 0px 10px 10px 0px !important;
  }

  .newportfolyo .card_portfoliyo .portfolicardviewm img {
    width: 50%;
    z-index: 999;
    position: relative;
    margin-top: 8%;
  }

  .Value-added-features
    .value-added-features-card
    .value-added-features-card-content
    .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .Value-added-features
    .value-added-features-card
    .value-added-features-card-icon
    img {
    border-radius: 10px 10px 0 0;
    min-height: 250px;
    transform: scale(1);
    transition: 0.5s;
  }

  .Value-added-features
    .value-added-features-card
    .value-added-features-card-content
    .main-heading {
    font-size: 20px;
  }

  .Value-added-features
    .value-added-features-card
    .value-added-features-card-content
    .sub-heading {
    font-size: 16px;
    color: #fcaf17;
  }

  .Value-added-features .value-added-features-card {
    min-height: 450px;
  }

  .Value-added-features .Value-added-features-head .main-head {
    font-size: 26px;
    margin-bottom: 0px;
  }

  .pt-50 {
    padding-top: 20px;
  }

  .possible-channels-sec .left-side-whatsapp-content .main-heading {
    padding-top: 35px;
    font-size: 18px;
    margin-bottom: 5px;
  }

  .possible-channels-sec .left-side-whatsapp-content {
    position: relative;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
    margin-top: 30% !important;
  }

  .possible-channels-sec .left-side-whatsapp-content .icon img {
    width: 70%;
  }

  .possible-channels-sec .left-side-whatsapp-content .desc {
    line-height: 20px;
    font-size: 14px;
  }

  .possible-channels-big-img img {
    width: 90%;
  }

  // portfolio/work cssby mukul
  .newportfolyo .card_portfoliyo .portfolicardview {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
  }

  .newportfolyo .card_portfoliyo p {
    font-size: 18px;
    margin: 10px 0;
    line-height: 30px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .newportfolyo .card_portfoliyo h3 {
    font-size: 24px;
  }

  .newportfolyo .leftsidecontnt .text_right {
    width: 100%;
    text-align: center;
  }

  .newportfolyo .card_portfoliyo .leftcardportfoli {
    padding: 0px;
    margin-top: 5%;
  }

  .btnall.text-end {
    display: flex;
    justify-content: center;
  }

  .newportfolyo .card_portfoliyo .text_left {
    text-align: center;
    margin-left: 0%;
  }

  .newportfolyo li {
    padding: 30px 0;
    min-height: 100%;
  }

  .newportfolyo .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newportfolyo .card_portfoliyo .portfolicardview img {
    width: 80%;
    z-index: 999;
    position: relative;
    margin-top: 8%;
  }

  .newportfolyo .card_portfoliyo .portfolicardview::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }

  // mobilevalaresponsive

  .newportfolyom .card_portfoliyom .portfolicardviewm {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
    text-align: center;
  }

  .newportfolyom .card_portfoliyom p {
    font-size: 20px;
    line-height: 30px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .newportfolyom .card_portfoliyom h3 {
    font-size: 28px;
  }

  .newportfolyom .leftsidecontntm .text_right {
    width: 100%;
    text-align: center;
  }

  .newportfolyom .card_portfoliyom .leftcardportfolim {
    padding: 0px;
    margin-top: 0%;
    height: unset;
  }

  .btnall.text-end {
    display: flex;
    justify-content: center;
  }

  .newportfolyom .card_portfoliyom .text_left {
    text-align: center;
    margin-left: 0%;
  }

  .newportfolyom li {
    padding: 37px 0;
    min-height: 100%;
  }

  .newportfolyom .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newportfolyom .card_portfoliyom .portfolicardviewm img {
    width: 45%;
    z-index: 999;
    position: relative;
    margin-top: 2%;
  }

  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }

  .newportfolyom .leftsidecontntm .portfolicardviewm {
    text-align: center;
  }

  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }

  .f-d-cr {
    flex-direction: column-reverse;
  }

  .case-study ul.tabs li {
    /* padding: 10px 1pc; */
    cursor: pointer;
    border: 1px solid lightgray;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    transition: all 0.5s;
    font-size: 16px;
    padding: 10px 20px;
    max-width: 100%;
    margin-left: 15px;
  }

  // casestudy1

  .case-study-banner-sec .case-study-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 60%;
    left: 5%;
  }

  .case-study-banner-sec .case-study-banner-content .main-heading {
    font-size: 26px;
    font-weight: 500;
  }

  .case-study-banner-sec .case-study-banner-content .breadcrumb-casestudy-box {
    margin-bottom: 5px;
  }

  .case-study-banner-sec .case-study-banner-content .desc {
    font-size: 18px;
    line-height: 25px;
  }

  .about-client-sec .about-client-content-box .desc {
    font-size: 17px;
    line-height: 25px;
  }

  .about-client-sec .about-client-main-head .main-heading {
    font-size: 22px;
  }

  .about-client-sec .about-client-content-box {
    padding: 15px;
    position: relative;
  }

  .about-client-sec .about-client-content-box .right-quote {
    position: absolute;
    left: 5%;
    top: 0%;
    width: 10%;
  }

  .about-client-sec .about-client-main-head .main-heading1 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-bottom: 5px;
  }

  .about-client-sec .about-client-content-box .left-quote {
    position: absolute;
    right: 5%;
    bottom: 2%;
    width: 10%;
  }

  .business-model-sec .business-model-main-head .main-heading {
    font-size: 22px;
  }

  .business-model-sec .business-model-main-head .desc {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .business-model-sec .business-model-card .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .business-model-sec .business-model-card {
    padding: 15px;
    border-radius: 5px;
    min-height: 200px;
    cursor: pointer;
    margin-bottom: 25px;
  }

  .business-model-sec
    .business-model-card
    .business-model-list-box
    .business-model-list
    li
    .card-head {
    margin-left: 10px;
    margin-bottom: 0px;
    font-size: 16px;
  }

  .market-research-sec
    .market-research-head
    .market-research-content-box
    .market-research-content
    .para {
    font-size: 14px;
    line-height: 20px;
  }

  .market-research-sec .market-research-head .main-heading {
    font-size: 22px;
  }

  .market-research-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
  }

  .market-research-sec
    .market-research-head
    .market-research-content-box
    .market-research-content {
    padding: 15px;
    min-height: 250px;
    margin-bottom: 25px;
  }

  .market-research-sec .market-research-img {
    margin-top: 30px;
  }

  .technology-sec
    .technology-content-area
    .technology-card
    .technology-card-content {
    border-top: 1px solid #e5e5e5;
    padding-top: 80px;
  }

  .technology-card-style1 .technology-card-content {
    padding-top: 80px;
    text-align: center;
    margin: 25px 0px;
    border-top: 1px solid #e5e5e5;
  }

  .market-research-sec
    .market-research-head
    .market-research-content
    .market-research-content-list
    li {
    position: relative;
    padding-left: 35px;
    line-height: 25px;
    margin-bottom: 12px;
    font-size: 14px;
  }

  .challenges-and-solutions-sec
    .challenges-solutions-content
    .challenges-solutions-card
    .number-card {
    content: "";
    position: absolute;
    left: -5%;
  }

  .market-research-sec .market-research-head .market-research-content h5 {
    font-size: 16px;
  }

  .market-research-sec .market-research-head .market-research-content {
    padding-top: 20px;
    margin-bottom: 35px;
  }

  .technology-sec .technology-head .main-heading {
    font-size: 24px;
  }

  .technology-sec .technology-head .desc {
    font-size: 16px;
    margin-top: 20px;
    line-height: 25px;
  }

  .technology-sec .technology-head .market-research-content h5 {
    font-size: 16px;
  }

  .technology-sec .technology-head .market-research-content {
    padding-top: 20px;
  }

  .technology-sec
    .technology-head
    .market-research-content
    .market-research-content-list
    li {
    position: relative;
    padding-left: 35px;
    line-height: 20px;
    margin-bottom: 12px;
    font-size: 14px;
  }

  .challenges-and-solutions-sec .challenges-solutions-main-head .main-heading {
    font-size: 24px;
    padding-bottom: 15px;
  }

  .challenges-and-solutions-sec .challenges-solutions-main-head {
    margin-top: 2%;
    margin-bottom: 0%;
  }

  .challenges-and-solutions-sec .challenges-and-solutions-img img {
    margin-left: 0%;
  }

  .results-growth-sec .results-growth-head .main-heading {
    font-size: 24px;
  }

  .results-growth-sec .results-growth-content .results-growth-content-box {
    margin-top: 20px;
    background-color: white;
    padding: 15px;
  }

  .results-growth-sec
    .results-growth-content
    .results-growth-content-box
    .results-growth-content-list
    li {
    line-height: 25px;
    font-size: 16px;
  }

  .future-prospects-sec .future-prospects-head .main-heading {
    font-size: 24px;
  }

  .future-prospects-sec .future-prospects-head {
    background-color: white;
    min-height: 300px;
    padding: 25px;
  }

  .future-prospects-sec .future-prospects-head .future-prospects-content-box {
    padding-top: 15px;
  }

  .future-prospects-sec
    .future-prospects-head
    .future-prospects-content-box
    .future-prospects-content-list
    li {
    line-height: 25px;
    font-size: 14px;
  }

  .future-prospects-sec
    .future-prospects-head
    .future-prospects-content-box
    .future-prospects-content-list
    li
    .checkicn {
    content: "";
    position: absolute;
    left: 7px;
    top: 4px;
    font-size: 18px;
    color: #414bbe;
    transform: translate(0px, 0px);
  }

  .future-prospects-sec .future-prospects-img {
    margin-left: 0%;
    margin-top: 2%;
  }

  .conclusion-sec .conclusion-sec-head .main-heading {
    font-size: 24px;
  }

  .conclusion-sec .conclusion-content-box {
    padding: 15px;
    margin-top: 10px;
  }

  .conclusion-sec .conclusion-content-box .desc {
    line-height: 25px;
    font-size: 16px;
    margin-top: 25px;
  }

  .results-growth-sec
    .results-growth-content
    .results-growth-content-box
    .results-growth-content-list
    li
    .checkicn {
    content: "";
    position: absolute;
    left: 7px;
    top: 4px;
    font-size: 18px;
    color: #414bbe;
    transform: translate(0px, 0px);
  }

  .technology-sec .technology-head {
    background-color: #3740aa;
    padding: 30px 15px 30px 15px;
    border-radius: 10px 10px 10px 10px;
    min-height: 300px;
    width: 400px;
    margin: 0 auto;
  }

  // huddle-heart-responsive-568pxto992px

  // huddle-heart

  .huddle-heart-banner-sec {
    min-height: 350px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .mt-30 {
    margin-top: 15px;
  }

  .huddle-heart-banner-sec .case-study-banner-content img {
    width: 50%;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .transform-button {
    color: white;
    background-color: #41b2e6;
    border: 1px solid #41b2e6;
    padding: 10px 24px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 14px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }

  .huddle-heart-vision-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
  }

  .huddle-heart-vision-sec .market-research-head .main-heading {
    font-size: 20px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content img {
    width: 45%;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-img img {
    margin-top: 10%;
  }

  .huddle-heart-banner-sec
    .huddle-heart-banner-content
    .huddle-heart-intro-list
    li {
    font-size: 14px;
    line-height: 20px;
  }

  .huddle-heart-vision-sec
    .market-research-head
    .market-research-content-box
    .market-research-content {
    padding: 10px;
    min-height: 300px;
    margin-bottom: 25px;
    border-bottom: 3px solid #35b6ff;
  }

  .huddle-heart-vision-sec .market-research-img {
    margin-top: 8%;
  }

  .huddle-heart-features-sec .pt-110 {
    padding-top: 0px;
  }

  .huddle-heart-features-sec .sec-title .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }

  .huddle-heart-features-sec .column {
    padding: 0px 10px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card {
    min-height: 400px;
    margin-bottom: 40px;
    padding: 15px 15px 10px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card .card-heading {
    font-weight: 600;
    font-size: 18px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card .desc {
    padding-top: 10px;
    font-size: 14px;
    line-height: 25px;
  }

  .enhanced-communication-sec .enhanced-communication-main-head .main-heading {
    font-size: 20px;
    padding-bottom: 15px;
  }

  .enhanced-communication-sec .enhanced-communication-card::before {
    display: none;
  }

  .enhanced-communication-sec .enhanced-communication-card {
    margin-top: 0%;
    margin-bottom: 35px;
    padding: 20px;
    min-height: 348px;
    position: relative;
  }

  .enhanced-communication-sec .enhanced-img::after {
    display: none;
  }

  .enhanced-communication-sec .enhanced-img::before {
    display: none;
  }

  .enhanced-communication-sec .enhanced-img {
    width: 70%;
    min-height: 540px;
    position: relative;
    margin-bottom: 10%;
  }

  .enhanced-communication-card h5 {
    font-size: 18px;
  }

  .improved-safety-sec .huddle-heart-technology-head .main-heading {
    font-size: 20px;
    padding-top: 10px;
  }

  .huddle-heart-impacts-sec .huddle-heart-impacts-head .main-heading {
    font-size: 20px;
    padding-bottom: 10px;
  }

  .huddle-heart-impacts-sec .huddle-heart-impacts-card {
    padding: 10px;
    min-height: 356px;
    margin-bottom: 30px;
  }

  .huddle-heart-imapcts-img {
    margin-bottom: 5%;
  }

  .challenges-lessons-huddle-heart .challenges-lessons-main-head .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }

  .challenges-lessons-huddle-heart .challenges-lessons-img {
    margin-bottom: 5%;
  }

  .challenges-lessons-huddle-heart
    .challenges-lessons-content
    .challenges-lessons-card
    .challenges-lessons-icon {
    position: absolute;
    left: 0;
    top: -20%;
    right: 0;
    margin: 0 auto;
  }

  .challenges-lessons-huddle-heart
    .challenges-lessons-content
    .challenges-lessons-card {
    margin-bottom: 45px;
    padding: 30px 15px 15px 15px;
  }

  .profile-banner-sec .screen-mob {
    width: 90%;
    min-height: 712px;
  }

  .profile-banner-sec .mt-80 {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .future-prospects-sec .business-model-main-head .main-heading {
    font-size: 21px;
    padding-bottom: 10px;
  }

  .future-prospects-sec
    .business-model-card
    .business-model-list-box
    .business-model-list
    li
    .card-head {
    font-size: 18px;
  }

  .future-prospects-sec .business-model-card {
    min-height: 285px;
    margin-bottom: 25px;
  }

  .eigth-banner ul {
    list-style-type: disc;
    padding-left: 0px;
  }

  // plywood-bazar-casestudy

  .plywood-bazar-banner-sec {
    min-height: 450px;
  }

  .plywood-bazar-banner-sec .plywood-bazar-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }

  .plywood-bazar-banner-sec .plywood-bazar-banner-content {
    margin-top: 5%;
  }

  .plywood-bazar-about-us .bg-shape-3 {
    position: absolute;
    bottom: 0%;
    right: 0%;
    text-align: end;
    display: none;
  }

  .plywood-bazar-about-us .bg-shape-1 img {
    width: 40%;
    display: none;
  }

  .plywood-bazar-about-us .bg-shape-2 img {
    width: 19%;
    display: none;
  }

  .plywood-bazar-banner-sec .plywood-bazar-banner-img {
    text-align: end;
    margin-top: 20%;
  }

  .plywood-bazar-about-us .about-content-box .main-heading {
    font-weight: 500;
    font-size: 28px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #212529;
  }

  .plywood-bazar-about-us .about-content-list-box .about-content-list li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    line-height: 20px;
  }

  .plywood-bazar-about-us .about-content-box .sub-heading {
    font-size: 16px;
  }

  .mission-or-vision-plywood .mission-vision-right-shape {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }

  .mission-or-vision-plywood .left-side-box {
    margin-bottom: 25px;
    padding: 10px;
  }

  .mission-or-vision-plywood .left-side-box .desc {
    font-size: 14px;
    line-height: 25px;
  }

  .mission-or-vision-plywood .mission-vision-left-shape {
    position: absolute;
    left: 0;
    bottom: 30px;
    display: none;
  }

  .mission-or-vision-plywood .mission-vision-wrapper::after {
    position: absolute;
    bottom: -27%;
  }

  .mission-or-vision-plywood .right-side-box {
    margin-top: 100%;
    padding: 10px;
  }

  .mission-or-vision-plywood .right-side-box .desc {
    font-size: 14px;
    line-height: 25px;
  }

  .our-client-requirement .about-client-area .main-head {
    font-size: 26px;
  }

  .our-client-requirement .about-client-area .desc {
    font-size: 16px;
    padding-top: 0%;
    line-height: 25px;
  }

  .our-client-requirement .client-req-area .main-head {
    font-size: 26px;
    font-weight: 500;
  }

  .our-client-requirement .client-req-area .client-req-list li {
    border-left: 3px solid #f7c553;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 20px;
  }

  .solution-given-sec .solution-given-content .main-heading {
    color: #b08218;
    font-size: 22px;
    font-weight: 500;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list
    li
    .heading {
    font-size: 20px;

    font-weight: 500;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list
    li::marker {
    font-size: 20px;
  }

  .solution-given-sec .solution-given-content .sub-heading {
    color: #b08218;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .plywoodbazar-features-head
    .sub-heading {
    font-size: 14px;
  }

  .our-work-screens .our-work-screens-main-head {
    position: absolute;
    width: 34%;
    margin-top: 14%;
  }

  .our-work-screens .our-work-screens-main-head .main-heading {
    font-size: 26px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .plywoodbazar-features-head
    .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list {
    height: 480px;
    overflow-y: auto;
    padding-left: 30px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list
    li {
    list-style-type: decimal;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  }

  .plywoodbazar-result-sec .plywoodbazar-result-main-head .desc {
    color: black;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    line-height: 20px;
  }

  .plywoodbazar-result-sec .plywoodbazar-result-main-head .main-heading {
    font-size: 26px;
    color: #b08218;
    margin-bottom: 10px;
  }

  // rocare-casestudy-568px

  .RoCare-banner-sec {
    min-height: 500px;
  }

  .RoCare-banner-sec .RoCare-banner-img {
    margin-top: 20%;
  }

  .RoCare-banner-sec .RoCare-banner-content .main-heading {
    font-size: 23px;
    margin-bottom: 10px;
  }

  .RoCare-banner-sec .RoCare-banner-content {
    margin-top: 6%;
  }

  .Rocare-aboutus .Rocare-aboutus-main-head {
    margin-top: 5%;
  }

  .client-requirements-sec .client-requirements-main-head {
    background-color: white;
    padding: 50px 15px 22px 15px;
  }

  .j-c-sb {
    justify-content: space-between;
  }

  .client-requirements-img img {
    width: 75%;
  }

  .client-requirements-sec .client-requirements-main-head .main-heading {
    font-size: 22px;
  }

  .client-requirements-sec .client-requirements-main-head .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .client-requirements-sec
    .client-requirements-main-head
    .main-heading::before {
    font-size: 60px;
    bottom: 0px;
  }

  .Rocare-features-sec .content-heading .features-heading {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .rocare-challenges-sec .rocare-challenges-content {
    margin-top: 12%;
  }

  // Nikkypore-casestudy-568px

  .NikkyPore-banner-sec {
    min-height: 470px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .main-heading {
    font-size: 21px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .mt-30 {
    margin-top: 15px;
  }

  .Nikkypore-project-summary .pb-50 {
    padding-bottom: 10px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .sub-head {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .desc {
    font-size: 14px;
    line-height: 25px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content {
    margin-top: 4%;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-img {
    text-align: end;
    margin-top: 20%;
    animation: 4s ease-in-out infinite floatY;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .transform-button {
    padding: 10px 24px;
    font-size: 15px;
  }

  .Nikkypore-project-summary .Nikkypore-main-head .main-heading {
    font-size: 24px;
  }

  .Nikkypore-goals-objectives
    .Nikkypore-goals-objectives-main-head
    .main-heading {
    font-size: 24px;
  }

  .Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card {
    min-height: 100%;
  }

  .Nikkypore-goals-objectives .goals-obj-content-box {
    margin-top: 0%;
  }

  .Nikkypore-goals-objectives .goals-obj-img-box {
    margin: 2% 0%;
  }

  .Nikkypore-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
  }

  .mt-3 {
    margin-top: 0px !important;
  }

  .Nikkypore-features-sec .customer-app-features-content {
    margin-top: 0%;
  }

  .Nikkypore-features-sec .customer-app-features-img {
    text-align: center;
  }

  .challenges-and-solutions-sec
    .challenges-sec
    .challenges-content-list
    li
    .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .challenges-and-solutions-sec .challenges-solutions-img {
    margin-bottom: 5%;
    text-align: center;
  }

  .challenges-and-solutions-sec .challenges-solutions-content {
    margin-bottom: 4%;
  }

  .challenges-and-solutions-sec .challenges-sec h4 {
    font-size: 20px;
  }

  .challenges-and-solutions-sec
    .challenges-and-solutions-main-head
    .main-heading {
    font-size: 22px;
  }

  .visual-designs-sec .visual-designs-main-head .main-heading {
    font-size: 24px;
  }

  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide {
    margin: 0 auto;
    text-align: center;
  }

  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide img {
    width: 80%;
  }

  .visual-designs-sec .visual-designs-wrapper {
    padding: 0px;
  }

  .visual-designs-sec .visual-designs-wrapper::before {
    display: none;
  }

  .visual-designs-sec .visual-designs-main-head .desc {
    margin-bottom: 0px;
  }

  // Deyor-Casestudy-568px-to-992px

  .deyor-main-banner-sec .deyor-main-banner-content {
    padding-left: 0%;
    margin-top: 5%;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .desc {
    color: #222a41;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-main-banner-logo {
    margin-bottom: 15px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li {
    flex-basis: 50%;
    margin-top: 10px;
    font-size: 16px;
  }

  .deyor-main-banner-sec {
    min-height: 350px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li h5 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 25px;
  }

  .deyor-about-us .deyor-about-us-main-head .main-heading {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .deyor-about-us {
    padding: 20px 0px 20px 0px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-image-box
    .aboutus-logo {
    position: absolute;
    width: 24.3%;
    right: 0;
    top: 0;
  }

  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box {
    position: relative;
    cursor: pointer;
    margin-bottom: 80%;
  }

  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content {
    margin-top: 2%;
    cursor: pointer;
  }

  .deyor-objective-sec .deyor-objective-content-box {
    margin-top: 10%;
  }

  .deyor-objective-sec
    .deyor-objective-content-box
    .deyor-objective-card
    .number-area {
    display: block;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
    color: #c4343c;
  }

  .deyor-objective-sec .deyor-objective-content-box .deyor-objective-card {
    padding: 10px;
    min-height: 170px;
    margin-bottom: 20px;
  }

  .deyor-objective-sec
    .deyor-objective-content-box
    .deyor-objective-card
    .desc1 {
    font-size: 14px;
    line-height: 20px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-content
    .main-heading {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .desc {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-content
    .deyor-about-us-content-list
    li {
    font-size: 18px;
    line-height: 30px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-content
    .deyor-about-us-content-list
    li
    .list-bar {
    position: absolute;
    left: 0;
    top: 15px;
  }

  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box {
    margin-top: 5%;
    background-color: white;
    padding: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
  }

  .deyor-methodology-sec .deyor-methodology-img {
    margin-top: 5%;
    text-align: center;
  }

  .deyor-methodology-sec .deyor-methodology-img .mt-10 {
    margin-top: 5%;
    width: 70%;
    text-align: center;
    margin-left: 0%;
  }

  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 {
    margin-top: 5%;
  }

  .d-f-fd-cr {
    display: flex;
    flex-direction: column-reverse;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box {
    margin-top: 5%;
    margin-right: 0%;
    min-height: 430px;
    margin-bottom: 5%;
    padding: 45px 50px 40px;
  }

  .deyor-challenges-sec .deyor-challenges-img {
    text-align: center;
  }

  .deyor-challenges-sec .deyor-challenges-img img {
    width: 80%;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .desc {
    font-size: 18px;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .main-heading {
    font-size: 26px;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }

  .deyor-solutions-sec .deyor-solutions-main-head .main-heading {
    font-size: 26px;
  }

  .deyor-solutions-sec .deyor-solutions-img {
    text-align: center;
  }

  .deyor-solutions-sec .deyor-solutions-img img {
    width: 80%;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .challenges-content-list {
    margin-top: 5%;
  }

  .deyor-solutions-sec .deyor-solutions-content-box {
    margin-top: 5%;
    margin-left: 0%;
    min-height: 400px;
    margin-bottom: 0%;
    padding: 50px 50px 50px;
  }

  .deyor-solutions-sec .deyor-solutions-content-box .desc {
    font-size: 18px;
  }

  .deyor-solutions-sec .deyor-solutions-content-box .main-heading {
    font-size: 26px;
  }

  .deyor-solutions-sec .deyor-solutions-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }

  .deyor-work-screens-sec .deyor-work-screens-main-head .main-heading {
    font-size: 24px;
  }

  // samagri-568px-responsive
  .samagri-banner-sec .samagri-banner-shape img {
    position: absolute;
    width: 36%;
    z-index: 1;
    left: 35px;
  }

  .samagri-banner-sec .samagri-banner-content .main-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
  }

  .samagri-banner-sec .samagri-banner-content .desc {
    font-size: 18px;
    color: #413d2c;
    font-weight: 600;
    line-height: 30px;
  }

  .samagri-banner-sec .samagri-banner-content .desc1 {
    font-size: 16px;
    color: #413d2c;
    font-weight: 400;
    line-height: 25px;
  }

  .samagri-banner-sec .samagri-banner-content {
    margin-top: 10%;
    padding-bottom: 10%;
  }

  .samagri-aboutus-sec .samagri-aboutus-wrap-box {
    background-color: white;
    padding: 10px;
    justify-content: space-between;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    span
    svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li {
    display: flex;
    align-items: start;
    line-height: 35px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    span
    svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li {
    line-height: 35px;
  }

  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box {
    margin-top: 0%;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-content-box
    .main-heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-content-box
    .desc {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-img-box
    .samagri-logo-img
    img {
    width: 60%;
  }

  .samagri-project-highlights .samagri-project-highlights-img .circle img {
    position: absolute;
    left: 40%;
    top: 160px;
    width: 60%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-img
    .circle
    .circle-content {
    position: absolute;
    top: 43%;
    left: 60%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(1) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(2) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(3) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(4) {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(-24px 29px 25px rgba(12, 12, 13, 0.32));
    text-align: center;
    line-height: 75px;
    margin-bottom: 20px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-icon
    img {
    width: 45%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-area {
    padding-left: 0px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-area
    .desc {
    font-size: 16px;
    line-height: 25px;
    color: #fff;
  }

  .samagri-work-screens {
    position: relative;
    width: 100%;
    min-height: 350px;
  }

  .samagri-work-screens .samagri-work-screen-content .main-heading {
    font-size: 22px;
    font-weight: 600;
  }

  .samagri-work-screens .samagri-work-screen-content {
    margin-top: 0%;
  }

  .samagri-work-screens .samagri-work-screen-content .desc {
    line-height: 20px;
    font-size: 13px;
    width: 70%;
  }

  .samagri-features-sec .samagri-features-main-head {
    margin-top: 5%;
  }

  .samagri-features-sec .samagri-features-main-head .main-heading {
    color: black;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .samagri-features-sec .samagri-features-card {
    padding: 15px 10px;
    margin-bottom: 20px;
    min-height: 140px;
  }

  .samagri-features-sec .samagri-features-card .card-heading {
    font-size: 14px;
    margin-bottom: 0px !important;
  }

  .samagri-features-sec .samagri-features-img {
    margin-top: 10%;
  }

  .samagri-challenges-sec .samagri-challenges-main-head .main-heading {
    font-size: 22px;
    color: white;
  }

  .samagri-challenges-sec .samagri-challenges-content {
    margin-top: 0%;
  }

  .samagri-challenges-sec
    .samagri-challenges-content
    .samagri-challenges-card
    .samagri-challenges-card-heading
    .main-heading {
    text-align: center;
    font-size: 14px;
    color: white;
    padding: 10px;
  }

  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card {
    padding: 5px;
  }

  .samagri-challenges-sec
    .samagri-challenges-content
    .samagri-challenges-card
    .desc {
    padding: 5px;
    line-height: 20px;
    font-size: 14px;
  }

  .samagri-challenges-sec .samagri-challenges-img {
    margin-bottom: 5%;
  }

  .samagri-contact-banner
    .position-relative-z-index-1
    .samagri-contact-banner-content
    .main-heading {
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .samagri-contact-banner
    .position-relative-z-index-1
    .samagri-contact-banner-content
    .desc {
    color: white;
    font-size: 14px;
    line-height: 20px;
  }

  .samagri-contact-banner {
    padding: 50px 0px;
  }

  // turning-point-568px

  .turning-point-banner-sec {
    min-height: 450px;
  }

  .turning-point-banner-sec .turning-point-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 15px;
  }

  .turning-point-banner-sec .turning-point-banner-content {
    margin-top: 5%;
  }

  .turning-point-banner-sec .turning-point-banner-img img {
    margin-top: 25%;
  }

  .turning-point-banner-sec .turning-point-banner-content .transform-button {
    padding: 10px 24px;
    font-weight: 500;
  }

  .turning-point-about-client-sec .turning-point-about-client-content-box {
    padding-left: 20px;
    padding-top: 0%;
    position: relative;
  }

  .turning-point-about-client-sec
    .turning-point-about-client-main-head
    .main-heading1 {
    font-size: 26px;
    font-weight: 600;
    position: relative;
    margin-bottom: 10px;
  }

  .turning-point-about-client-sec .turning-point-about-client-img {
    margin-top: 2%;
  }

  .turning-point-about-client-sec
    .turning-point-about-client-content-box
    .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .turning-point-project-highlights-sec
    .turning-point-project-highlights-head
    .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }

  .mt-20 {
    margin-top: 20px;
    border-top: 1px solid #eee;
  }

  .turning-point-features-sec .customer-app-features-content {
    margin-top: 0%;
  }

  .turning-point-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .turning-point-challenges-and-solutions-sec
    .challenges-and-solutions-main-head
    .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .turning-point-challenges-and-solutions-sec .challenges-sec {
    margin-top: 5%;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-main-head
    .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-wrapper::before {
    display: none;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-wrapper
    .turning-point-visual-designs-slide
    img {
    width: 65%;
  }

  .turning-point-conclusion-sec
    .turning-point-conclusion-sec-head
    .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .turning-point-conclusion-sec .turning-point-conclusion-content-box .desc {
    font-size: 16px;
    line-height: 25px;
  }

  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper {
    background-color: #f0686c;
    padding: 20px 0px 20px;
    position: relative;
  }

  .turning-point-features-sec
    .position-relative-z-index-1
    .customer-app-features-img {
    text-align: center;
  }

  .turning-point-challenges-and-solutions-sec .challenges-solutions-img {
    text-align: center;
  }
}

// end of min-width:568px and max-width:992px

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .sch-software-portfolio-head .main-heading {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
  }

  .portfolio-left-area h4 {
    color: white;
    font-size: 38px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-top: 70px;
  }

  .portfolio-left-area .sub-heading-area {
    margin-bottom: 30px;
    margin-left: 80px;
  }

  .works-list li {
    margin-right: 113px;
    margin-bottom: 10px;
  }

  .desc-button {
    margin-top: 25px;
  }

  .works-list-1 li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }

  .works-list li .inner-works-list h5 {
    font-size: 18px;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }

  .travel-about-us .about-img {
    padding-top: 0px;
  }

  .py-40 {
    padding: 20px 0px;
  }

  .travel-banner-content {
    position: absolute;
    top: 54%;
    left: 3%;
    z-index: 1;
    width: 60%;
  }

  .travel-banner-content h2 {
    color: white;
    font-size: 32px;
    font-weight: 600;
    font-family: var(--roboto-fonte);
    margin-bottom: 0px;
  }

  .about-content .main-heading {
    font-size: 26px;
    font-weight: 700;
    color: #020202;
    margin: 10px 0px;
  }

  .about-content .desc {
    font-size: 14px;
  }

  .features-head h2 {
    font-size: 24px;
    font-weight: 700;
    color: #020202;
    font-family: "Roboto", sans-serif;
    text-align: center;
    margin-bottom: 15px;
  }

  .nav-tabs .nav-item button {
    background-color: #e8ebf1;
    border-radius: 50px;
    color: #020202;
    padding: 12px 32px !important;
    margin: 0px !important;
    margin-right: 5px !important;
    font-size: 12px !important;
    font-weight: 600;
  }

  .portfolio-left-area {
    background-color: rgb(215, 164, 64);
    width: 100% !important;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
  }

  .connect {
    position: absolute;
    top: 25%;
    left: 5%;
  }

  .connect .desc {
    color: white;
    font-size: 20px;
    font-family: var(--roboto-fonte);
  }

  .quote {
    position: absolute;
    top: 80%;
    left: 5%;
    color: white;
    font-size: 20px;
    font-family: var(--roboto-fonte);
  }

  .portfolio-area {
    background-color: #f5e9ce;
    width: 100%;
    position: relative;
    min-height: 550px !important;
  }

  .sch-software-process-tabs {
    display: none;
  }

  .sch-software-development-process .faq-accordion {
    display: block;
  }

  .sch-software-development-process
    .faq-accordion
    .accordion
    .accordion-button {
    background-image: none !important;
    font-size: 15px;
  }

  .faq-accordion .accordion .accordion-button {
    background-image: none !important;
    font-size: 14px;
  }

  .faq-accordion .accordion .accordion-header svg {
    content: "";
    position: absolute;
    right: 4%;
    top: 35%;
  }

  .faq-accordion .accordion .accordion-body {
    background-color: rgb(252, 175, 23);
    color: white;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .features-card {
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.08);
    background-color: white;
    padding: 16px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s linear;
    min-height: 100px;
    margin-bottom: 25px;
  }

  .features-img img {
    width: 30px;
    height: 30px;
    margin-bottom: 0px;
    text-align: center;
  }

  .features-card h5 {
    padding: 5px;
    font-size: 12px;
    margin-top: 5px;
  }

  .features-head .desc {
    font-size: 12px;
  }

  .job-banner-sec .job-banner-content {
    position: absolute;
    top: 33%;
    left: 0%;
  }

  .e-hiring-model-list {
    margin: 10px 0px;
  }

  .e-hiring-model-list:nth-child(1) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(2) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(3) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(4) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(5) {
    position: relative;
    left: 0%;
  }

  .e-hiring-model-list:nth-child(6) {
    position: relative;
    left: 0%;
  }

  .inner-icon-content h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .inner-icon-content p {
    font-size: 12px;
    line-height: 15px;
  }

  .discuss-specialists-content .desc {
    font-size: 13px;
  }

  .discuss-specialists-content .lets-talk-button {
    font-size: 14px;
    padding: 8px 32px;
  }

  .discuss-specialists-content .title {
    font-size: 22px;
  }

  .job-banner-sec .job-banner-content h1 {
    color: white;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }

  .job-banner-sec .job-banner-content .desc {
    font-size: 16px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 40px;
    width: 70%;
  }

  .job-portal-services-head h2 {
    font-size: 26px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
  }

  .job-portal-services-head .desc {
    font-size: 12px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    // width: 100%;
    line-height: 16px;
  }

  .on-demand-head-area .sub-head {
    font-size: 12px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 15px;
  }

  .pb-40 {
    padding-bottom: 20px;
  }

  .on-demand-head-area .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }

  .on-demand-my-tab .on-demand-tab-head {
    color: white;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 0px;
  }

  .admin-content-list li {
    color: white;
    line-height: 30px;
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
  }

  .admin-content-list-box {
    padding: 15px 0px 0px 0px;
  }

  .admin-content-list li svg {
    margin-right: 5px;
    font-size: 14px;
  }

  .on-demand-my-tab .on-demand-tab-head {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .job-features-tab-pane .ad-features img {
    width: 16px;
    height: 16px;
  }

  .job-features-tab-pane .ad-features {
    position: absolute;
    left: 51%;
    top: 10px;
    z-index: 1;
  }

  .job-features-tab-pane .co-features img {
    width: 16px;
    height: 16px;
  }

  .job-features-tab-pane .co-features {
    position: absolute;
    left: 31.5%;
    top: 10px;
    z-index: 1;
  }

  .job-p-features-card .job-p-features-content h4 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
  }

  .job-p-features-card .job-p-features-content .desc {
    line-height: 20px;
  }

  .job-p-features-card {
    padding: 5px;
    min-height: 200px;
    margin-bottom: 20px;
  }

  .our-recent-works-head h2 {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .our-recent-works-head .desc {
    font-size: 13px;
    width: 100%;
    line-height: 20px;
  }

  .why-choose-j-p .why-choose-j-p-head h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .why-choose-j-p .why-choose-j-p-head .desc {
    font-size: 12px;
    //    width: 100%;
    line-height: 15px;
  }

  .e-hiring-model .e-hiring-model-head h2 {
    font-size: 19px;
    margin-bottom: 10px;
  }

  .e-hiring-model .e-hiring-model-head .desc {
    font-size: 12px;
    width: 100%;
    line-height: 16px;
  }

  .e-hiring-model .e-hiring-model-head {
    text-align: center;
    padding: 0px;
  }

  .job-services-card {
    min-height: 310px;
    margin-bottom: 25px;
  }

  .our-recent-works-card .our-recent-works-content p {
    color: white;
    margin-bottom: 20px;
    font-size: 13px;
  }

  .our-recent-works-card .our-recent-works-content h4 {
    color: white;
    margin-bottom: 0px;
    font-size: 18px;
  }

  .recent-work-bottom .desc {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .why-choose-jp-card .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
  }

  .e-hiring-model-img {
    text-align: center;
  }

  .e-hiring-model .e-hiring-model-head h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  // for healthcare

  .healthcare-banner-sec .healthcare-banner-content {
    position: absolute;
    top: 50%;
    left: 2%;
    width: 52%;
    z-index: 2;
  }

  .healthcare-banner-sec .healthcare-banner-content .main-heading {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .healthcare-based-solutions-list-box {
    border: 1px solid #e5e5e5;
    padding: 25px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: 0px;
  }

  .healthcare-based-solutions-img img {
    padding: 20px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: 402px;
  }

  .health-app-develop-content .main-heading {
    font-size: 31px;
    font-family: "Poppins", sans-serif;
  }

  .health-app-develop-content .desc {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
    font-weight: 500;
  }

  .growth-banner-content {
    position: relative;
    top: 35%;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .growth-banner-content .main-heading {
    font-size: 24px;
    color: white;
    // margin-bottom: 30px;
    font-family: "Poppins", sans-serif;
    padding: 10px 0px;
  }

  .hexagon-main-img {
    position: relative;
    text-align: center;
    margin-bottom: 0px;
  }

  .hexagon-main-img .growth-banner-img-content {
    position: absolute;
    top: 27%;
    left: 20%;
    text-align: center;
    width: 60%;
  }

  .health-choose-us-card:nth-child(2) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-right: 0px;
    border-bottom: 0;
  }

  .health-choose-us-main-box .free-consult-button {
    background-color: transparent;
    color: #fcaf17;
    border: 2px solid #fcaf17;
    border-radius: 5px;
    padding: 12px 20px;
    font-weight: 600;
  }

  .health-choose-us-main-box {
    margin-bottom: 25px;
  }

  .discuss-specialists {
    min-height: 200px !important;
  }

  .health-choose-us-card:nth-child(4) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 5px;
    border-right: 1px solid #e5e5e5;
    border-top: 0;
  }

  .health-choose-us-card:nth-child(1) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 5px;
    border-right: 0;
    border-bottom: 0;
  }

  .health-choose-us-card:nth-child(2) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-right: 1px solid #e5e5e5;
  }

  .health-choose-us-card:nth-child(5) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-top: 0;
    border-right: 0;
  }

  .health-choose-us-card:nth-child(3) {
    border: 1px solid #e5e5e5;
    background-color: white;
    padding: 25px;
    border-top-left-radius: 0px;
    border-top-right-radius: 5px;
    border-right: 0;
  }

  .hexagon-main-img .growth-banner-img-content .desc {
    font-size: 11px;
    color: white;
    line-height: 15px;
    margin-top: 10px;
  }

  .explore-erp-services-content {
    padding-top: 0px;
  }

  .growth-banner-content .free-consult-button {
    background-color: transparent;
    color: #fcaf17;
    border: 2px solid #fcaf17;
    border-radius: 5px;
    padding: 12px 20px;
    font-weight: 600;
  }

  .explore-erp-services-img {
    margin-bottom: 30px;
  }

  .erp-developed-modules-card {
    padding: 10px;
  }

  .erp-developed-modules-card .erp-developed-modules-content h4 {
    font-size: 14px;
  }

  .erp-developed-modules-card .erp-developed-modules-content .desc {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }

  .erp-banner-sec .erp-banner-content {
    position: absolute;
    top: 22%;
    left: 0%;
  }

  .explore-erp-development-list li {
    margin-bottom: 10px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }

  .erp-about-us .erp-about-img {
    margin-bottom: 25px;
    text-align: center;
  }

  .crm-banner-sec .Crm-banner-content {
    position: absolute;
    z-index: 2;
    top: 52%;
    width: 60%;
    left: 5%;
  }

  .crm-software-features .crm-software-features-card {
    position: relative;
    padding: 5px;
    transition: all 0.15s ease-in-out;
    margin-bottom: 20px;
    min-height: 180px;
  }

  .why-you-need-crm .Crm-app-development-content .main-heading {
    font-size: 30px;
    position: relative;
  }

  .why-you-need-crm .Crm-app-development-content .desc {
    color: black;
    font-size: 14px;
    width: 80%;
    margin: 0 auto;
  }

  .crm-software-features .crm-software-features-head .main-heading {
    font-size: 30px;
    position: relative;
    padding-bottom: 5px;
  }

  .crm-software-features .crm-software-features-head .desc {
    color: black;
    font-size: 13px;
    width: 80%;
    margin: 0 auto;
    line-height: 20px;
  }

  .crm-software-solutions-sec .crm-development-services-head .main-heading {
    font-size: 30px;
  }

  .crm-software-solutions-sec .crm-development-services-head .desc {
    color: black;
    width: 85%;
    margin: 0 auto;
    font-size: 13px;
    line-height: 20px;
  }

  .erp-development-services-head .main-heading-type {
    color: #020202;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .erp-development-services-head .sub-heading {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
  }

  .custom-crm-development-head .main-head {
    font-size: 26px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: center;
  }

  .custom-crm-development-head .desc {
    width: 80%;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
  }

  .school-mt-banner-sec .school-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 50%;
    width: 100%;
    left: 0%;
  }

  .school-mt-banner-sec .school-mt-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .school-mt-banner-sec .school-mt-banner-content .sub-heading {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
  }

  .school-mt-banner-sec .school-mt-banner-content .transform-button {
    font-size: 14px;
    padding: 10px;
  }

  .Sch-mt-about-content .main-heading {
    font-size: 19px;
  }

  .sch-software-services-head .main-heading {
    font-size: 19px;
    margin-bottom: 0px;
  }

  .sch-software-services-card .sch-software-services-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }

  .sch-software-services-card .sch-software-services-icon img {
    width: 35px;
    height: 35px;
  }

  .sch-software-services-card .sch-software-services-content {
    padding: 10px;
  }

  .sch-software-services-card .sch-software-services-content h5 {
    font-size: 14px;
    font-weight: 500;
  }

  .sch-software-services-card .sch-software-services-content .desc {
    font-size: 12px;
    line-height: 16px;
  }

  .sch-software-modules-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .sch-software-modules-head .desc {
    font-size: 12px;
    line-height: 16px;
  }

  .sch-software-modules-card .sch-software-modules-content h5 {
    font-size: 13px;
    font-weight: 500;
  }

  .Sch-software-features .Sch-software-features-head .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .Sch-software-features .Sch-software-features-head .desc {
    font-size: 12px;
    line-height: 17px;
  }

  .Sch-mt-features-img img {
    border-radius: 5px;
    margin-bottom: 25px;
  }

  .Sch-mt-features-content {
    border: 1px solid #e5e5e5;
    padding: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    min-height: auto;
    margin-bottom: 25px;
  }

  .Sch-software-features .main-box {
    margin: 0px;
  }

  .sch-software-development-process
    .sch-software-development-process-head
    .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .sch-process-r {
    display: none;
  }

  .sch-software-solutions-advantages
    .sch-software-solutions-advantages-head
    .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }

  .sch-software-advantages-img img {
    border-radius: 5px;
    min-height: auto;
  }

  .sch-software-advantages-content {
    border-radius: 0px;
  }

  .sch-software-advantages-content .sch-software-advantages-list li {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    line-height: 30px;
  }

  .sch-software-portfolio-head .main-heading {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
  }

  .society-mt-banner-sec .society-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 60%;
    width: 55%;
    left: 0%;
  }

  .erp-development-services-card {
    padding: 10px;
    min-height: 160px;
  }

  .erp-development-services-card .desc {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }

  .society-mt-banner-sec .society-mt-banner-content .main-heading {
    color: white;
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .society-mt-banner-sec .society-mt-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }

  .society-mt-banner-sec .society-mt-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 8px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }

  .society-mt-about-us .society-mt-about-content .main-heading {
    font-size: 18px;
    color: #020202;
  }

  .society-mt-about-list-box {
    padding-left: 20px;
  }

  .society-mt-about-us .society-mt-about-content .h-about-button {
    margin-top: 10px;
    // text-align: center;
  }

  .society-mt-features-head .main-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .society-mt-features-head .desc {
    font-size: 12px;
    line-height: 18px;
  }

  .erp-development-services-card h4 {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }

  // real-est-cssfor ipadpro

  .real-est-banner-sec .real-est-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .real-est-banner-sec .real-est-banner-content {
    position: absolute;
    z-index: 2;
    top: 45%;
    width: 50%;
    left: 4%;
  }

  .real-estate-about-img {
    text-align: center;
  }

  .real-estate-about-us .real-estate-about-content .main-heading {
    font-size: 23px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
  }

  .real-estate-about-us .real-estate-about-content .real-estate-about-heading {
    font-size: 20px;
  }

  .real-estate-about-us
    .real-estate-about-content
    .real-estate-about-list-box
    .real-estate-about-list
    li {
    border-left: 1px dashed #bebebe;
    padding: 0px 10px 15px 20px;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
  }

  .real-est-development-services .real-est-development-services-head {
    text-align: left;
  }

  .real-est-development-services
    .real-est-development-services-head
    .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .real-est-development-services .real-est-development-services-head .desc {
    font-size: 14px;
    margin: 0 auto;
    width: 100%;
  }

  .real-est-services-card {
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 25px;
    min-height: 282px;
  }

  .real-est-services-card .real-est-services-content h4 {
    font-size: 17px;
  }

  .real-est-services-card .real-est-services-content .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .real-est-services-card .real-est-services-icon {
    padding: 0px;
    padding-bottom: 5px;
  }

  .on-demand-head-area-re .main-head {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 25px;
  }

  .on-demand-head-area-re .sub-head {
    font-size: 20px;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 10px;
  }

  .real-est-app-development-features
    .app-development-right-side
    .app-development-right-side-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    min-height: 95px;
  }

  .real-est-app-development-features
    .app-development-left-side
    .app-development-left-side-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  .real-est-app-development-features .real-est-app-development-head {
    text-align: left;
  }

  .real-est-app-development-features
    .real-est-app-development-head
    .main-heading {
    font-size: 26px;
  }

  .real-est-app-development-features
    .app-development-left-side
    .app-development-left-side-card
    .app-development-left-side-list
    li {
    margin-right: 155px;
  }

  .app-development-features-img {
    text-align: center;
  }

  .real-est-mngmnt-system .real-est-mngmnt-content .heading {
    color: white;
    font-size: 26px;
    font-weight: 600;
    line-height: 35px;
  }

  .real-est-mngmnt-system .real-est-mngmnt-content .desc {
    color: white;
    font-size: 16px;
    margin: 10px 0px 20px 0px;
  }

  .real-est-app-development-features .app-development-right-side {
    margin-bottom: 0px;
  }

  .erp-portfolio-area .erp-portfolio-left-side {
    background-color: rgb(215, 164, 64);
    padding: 60px;
  }

  .erp-portfolio-area .erp-portfolio-left-side h4 {
    color: white;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }

  .erp-portfolio-area .erp-portfolio-left-side .sub-heading-area {
    margin-bottom: 15px;
  }

  .erp-portfolio-area .erp-portfolio-left-side .connect-erp .desc {
    color: white;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    width: 80%;
  }

  .discuss-specialists-content .desc {
    font-size: 16px;
    color: white;
    text-align: center;
    margin: 20px 0px;
  }

  .quote-erp .explore-more-button {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    padding: 12px 32px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
  }

  // manufacturing-above 993px

  .manufacturing-banner-sec .manufacturing-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 50%;
    left: 0%;
  }

  .whatsapp-bot-about-us .whatsapp-bot-about-us-head .main-head {
    font-size: 32px;
  }

  .manufacturing-banner-sec .manufacturing-banner-content .main-heading {
    font-size: 21px;
    margin-bottom: 5px;
  }

  .manufacturing-banner-sec .manufacturing-banner-content .sub-heading {
    margin-bottom: 20px;
    line-height: 25px;
  }

  .manufacturing-about-us .manufacturing-about-content .main-heading {
    font-size: 22px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
    padding-top: 15px;
  }

  .manuft-sft-solutions-sec .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .manuft-sft-solutions-sec .manuft-sft-solutions-content .main-heading {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .manuft-sft-solutions-sec
    .manuft-sft-solutions-content
    .mft-solutions-list-box
    .manuft-sol-cnt-list
    .content
    h6 {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 14px;
  }

  .manuft-sft-services-inner-list h5 {
    font-size: 18px;
  }

  .manuft-sft-solutions-sec .manuft-sft-solutions-content .sub-heading {
    font-size: 16px;
  }

  .mft-sol-inner-list li image {
    width: 80px;
  }

  .manuft-sft-solutions-sec
    .manuft-sft-solutions-content
    .manuft-solutions-left-cnt
    .manuft-sol-cnt-list
    li
    .mft-sol-inner-list
    h6 {
    font-size: 13px;
    margin-bottom: 0px;
  }

  .manuft-sft-solutions-content .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .manuft-sft-services-inner-list .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .manuft-sft-services .manuft-sft-solutions-head .main-heading-type {
    color: #020202;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  .manuft-sft-services
    .manuft-sft-services-list
    li
    .manuft-sft-services-inner-list
    h5 {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
  }

  .manuft-sft-services
    .manuft-sft-services-list
    li
    .manuft-sft-services-inner-list
    .desc {
    font-size: 15px;
    line-height: 20px;
  }

  .our-works-manuft-software .manuft-sft-works-head {
    text-align: left;
  }

  .our-works-manuft-software .manuft-sft-works-head .main-heading-type {
    color: #020202;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .our-works-manuft-software .manuft-sft-works-content .main-heading {
    font-weight: 600;
    font-size: 20px;
  }

  .our-works-manuft-software .manuft-sft-works-content .desc {
    margin-bottom: 20px;
    line-height: 25px;
  }

  .manuft-sft-works-content {
    margin-bottom: 20px;
  }

  .our-works-manuft-software .manuft-sft-works-content .sub-heading {
    background-color: black;
    color: white;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    padding: 5px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .discuss-specialists-content {
    z-index: 10;
    margin: 0 auto;
    padding: 10px 0px;
    text-align: center;
  }

  // retail-pos-993px

  .retail-pos-banner-sec .retail-pos-banner-content {
    position: absolute;
    z-index: 2;
    top: 56%;
    width: 50%;
    left: 5%;
  }

  .retail-pos-banner-sec .retail-pos-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    line-height: 25px;
  }

  .retail-pos-about-img {
    text-align: center;
  }

  .retail-pos-banner-sec .retail-pos-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .retail-pos-banner-sec .retail-pos-banner-content .transform-button {
    color: white;
    background-color: #fcaf17;
    border: 1px solid #fcaf17;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }

  .retail-pos-about-us .retail-pos-about-content .main-heading {
    font-size: 26px;
    font-weight: 500;
    color: #020202;
    font-family: "Poppins", sans-serif;
    padding-top: 20px;
  }

  .retail-pos-software-importance .retail-pos-software-imp-head {
    text-align: left;
  }

  .retail-pos-software-importance
    .retail-pos-software-imp-head
    .main-heading-type {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .retail-pos-software-importance .retail-pos-software-imp-head .desc {
    width: 100%;
    text-align: left;
    font-size: 16px;
    line-height: 25px;
  }

  .retail-pos-software-importance .retail-pos-imp-card {
    padding: 35px 20px 25px 40px;
    border-radius: 20px;
    min-height: 200px;
    position: relative;
    border: 1px solid transparent;
    margin-right: 0px;
    margin-bottom: 45px;
    margin-top: 25px;
    text-align: center;
  }

  .retail-pos-software-importance
    .retail-pos-imp-card
    .retail-pos-imp-content
    .desc {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }

  .retail-pos-software-importance
    .retail-pos-imp-card
    .retail-pos-imp-content
    h3 {
    color: #3d3e42;
    font-size: 18px;
    margin-bottom: 5px;
    padding-top: 10px;
  }

  .retail-pos-software-importance .retail-pos-imp-card .retail-pos-imp-icon {
    content: "";
    position: absolute;
    top: -25%;
    left: 0%;
    right: 0%;
    margin: 0 auto;
  }

  .retail-pos-software-importance
    .retail-pos-imp-card
    .retail-pos-imp-icon
    img {
    width: 18%;
  }

  .retail-pos-features-sec .retail-pos-features-head .main-heading-type {
    color: #020202;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .retail-pos-features-sec .retail-pos-features-head {
    text-align: center;
  }

  .retail-pos-features-sec .retail-pos-features-content {
    padding-top: 50px;
  }

  .retail-pos-features-sec .retail-pos-features-content .main-head {
    font-size: 21px;
    position: relative;
    padding-bottom: 15px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    line-height: 25px;
  }

  .retail-pos-features-sec .retail-pos-features-content .retail-pos-accord-ion {
    padding-top: 25px;
    margin-bottom: 25px;
  }

  .retail-pos-accord-ion .accordion-button {
    background-color: #fff;
    color: black;
    font-weight: 600;
    font-size: 16px;
    padding: 15px;
  }

  .retail-pos-accord-ion .plus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }

  .retail-pos-accord-ion .accordion-body {
    box-shadow: 0 90px 70px 0 rgba(0, 0, 0, 0.04),
      0 40px 35px 0 rgba(0, 0, 0, 0.03), 0 25px 15px 0 rgba(0, 0, 0, 0.03),
      0 11px 7px 0 rgba(0, 0, 0, 0.03), 0 2px 5px 0 rgba(0, 0, 0, 0.03) !important;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: #fcaf17;
    border: transparent;
  }

  .retail-pos-accord-ion .minus-icon {
    position: absolute;
    right: 5%;
    font-size: 24px;
    transition: all 0.4s;
  }

  .retail-pos-features-img {
    text-align: center;
    padding-top: 0px;
  }

  .pb-60 {
    padding-bottom: 40px;
  }

  .retail-pos-features-sec .explore-buttn-holder .explore-buttn {
    padding: 10px 20px;
  }

  .retail-pos-features-sec .explore-buttn-holder {
    text-align: center;
    margin-top: 40px;
  }

  .retail-pos-why-choose-us .retail-pos-why-choose-us-head {
    text-align: center;
  }

  .retail-pos-why-choose-us .retail-pos-why-choose-us-head .main-heading-type {
    color: #020202;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .retail-pos-why-choose-us .why-choose-retail-pos-card {
    padding: 20px;
    border-radius: 16px;
    // min-height: 390px;
    margin-bottom: 25px;
  }

  .retail-pos-why-choose-us
    .why-choose-retail-pos-card
    .why-choose-retail-pos-content
    h3 {
    text-align: center;
    font-size: 24px;
    width: 50%;
    margin: 0 auto;
  }

  .retail-pos-why-choose-us
    .why-choose-retail-pos-card
    .why-choose-retail-pos-content
    .desc {
    font-size: 18px;
    line-height: 25px;
    padding: 15px 0px;
    text-align: center;
  }

  // portfolio/work cssby mukul
  .newportfolyo .card_portfoliyo .portfolicardview {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
    text-align: center;
  }

  .newportfolyo .card_portfoliyo p {
    font-size: 20px;
    margin: 10px 0;
    line-height: 30px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .newportfolyo .card_portfoliyo h3 {
    font-size: 32px;
  }

  .newportfolyo .leftsidecontnt .text_right {
    width: 100%;
    text-align: center;
  }

  .newportfolyo .card_portfoliyo .leftcardportfoli {
    padding: 0px;
    margin-top: 5%;
  }

  .btnall.text-end {
    display: flex;
    justify-content: center;
  }

  .newportfolyo .card_portfoliyo .text_left {
    text-align: center;
    margin-left: 0%;
  }

  .newportfolyo li {
    padding: 30px 0;
    min-height: 100%;
  }

  .newportfolyo .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newportfolyo .card_portfoliyo .portfolicardview img {
    width: 50%;
    z-index: 999;
    position: relative;
    margin-top: 2%;
  }

  .newportfolyo .card_portfoliyo .portfolicardview::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }

  // mobilevalaresponsive

  .newportfolyom .card_portfoliyom .portfolicardviewm {
    position: relative;
    min-height: 340px;
    margin: 5% 0%;
    text-align: center;
  }

  .newportfolyom .card_portfoliyom p {
    font-size: 20px;
    line-height: 30px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .newportfolyom .card_portfoliyom h3 {
    font-size: 28px;
  }

  .newportfolyom .leftsidecontntm .text_right {
    width: 100%;
    text-align: center;
  }

  .newportfolyom .card_portfoliyom .leftcardportfolim {
    padding: 0px;
    margin-top: 0%;
    height: unset;
  }

  .btnall.text-end {
    display: flex;
    justify-content: center;
  }

  .newportfolyom .card_portfoliyom .text_left {
    text-align: center;
    margin-left: 0%;
  }

  .newportfolyom li {
    padding: 37px 0;
    min-height: 100%;
  }

  .newportfolyom .flex_end {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newportfolyom .card_portfoliyom .portfolicardviewm img {
    width: 45%;
    z-index: 999;
    position: relative;
    margin-top: 2%;
  }

  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }

  .newportfolyom .leftsidecontntm .portfolicardviewm {
    text-align: center;
  }

  .newportfolyom .card_portfoliyom .portfolicardviewm::before {
    content: "";
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
  }

  .f-d-cr {
    flex-direction: column-reverse;
  }

  // casestudy1

  .case-study-banner-sec .case-study-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 80%;
    left: 5%;
  }

  .case-study-banner-sec .case-study-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .case-study-banner-sec .case-study-banner-content .breadcrumb-casestudy-box {
    margin-bottom: 5px;
  }

  .case-study-banner-sec .case-study-banner-content .desc {
    font-size: 16px;
    line-height: 20px;
  }

  .about-client-sec .about-client-content-box .desc {
    font-size: 17px;
    line-height: 25px;
  }

  .about-client-sec .about-client-main-head .main-heading {
    font-size: 22px;
  }

  .about-client-sec .about-client-content-box {
    padding: 15px;
    position: relative;
  }

  .about-client-sec .about-client-content-box .right-quote {
    position: absolute;
    left: 5%;
    top: 0%;
    width: 10%;
  }

  .about-client-sec .about-client-main-head .main-heading1 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-bottom: 5px;
  }

  .about-client-sec .about-client-content-box .left-quote {
    position: absolute;
    right: 5%;
    bottom: 2%;
    width: 10%;
  }

  .business-model-sec .business-model-main-head .main-heading {
    font-size: 22px;
  }

  .business-model-sec .business-model-main-head .desc {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .business-model-sec .business-model-card {
    padding: 15px;
    border-radius: 5px;
    min-height: 200px;
    cursor: pointer;
    margin-bottom: 25px;
  }

  .market-research-sec .market-research-head .main-heading {
    font-size: 22px;
  }

  .market-research-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
  }

  .market-research-sec
    .market-research-head
    .market-research-content-box
    .market-research-content {
    padding: 15px;
    min-height: 250px;
    margin-bottom: 25px;
  }

  .market-research-sec .market-research-img {
    margin-top: 30px;
  }

  .technology-sec
    .technology-content-area
    .technology-card
    .technology-card-content {
    border-top: 1px solid #e5e5e5;
    padding-top: 80px;
  }

  .technology-card-style1 .technology-card-content {
    margin: 0px 0px;
  }

  .improved-safety-sec
    .technology-content-area
    .technology-card
    .huddle-heart-technology-card-content
    .content-txt
    .desc {
    line-height: 20px;
    font-size: 14px;
  }

  .market-research-sec
    .market-research-head
    .market-research-content
    .market-research-content-list
    li {
    position: relative;
    padding-left: 35px;
    line-height: 25px;
    margin-bottom: 12px;
    font-size: 14px;
  }

  .improved-safety-sec
    .technology-content-area
    .technology-card-style1
    .technology-card-content
    .content-txt {
    padding: 0px;
  }

  .technology-card-content .content-txt h5 {
    padding: 5px 0px;
    font-size: 16px;
  }

  .huddle-heart-technology-card-content .content-txt h5 {
    padding: 5px 0px;
    font-size: 16px;
  }

  .huddle-heart-impacts-sec .huddle-heart-impacts-card .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .challenges-and-solutions-sec
    .challenges-solutions-content
    .challenges-solutions-card
    .number-card {
    content: "";
    position: absolute;
    left: -9%;
  }

  .market-research-sec .market-research-head .market-research-content h5 {
    font-size: 16px;
  }

  .market-research-sec .market-research-head .market-research-content {
    padding-top: 20px;
    margin-bottom: 35px;
  }

  .technology-sec .technology-head .main-heading {
    font-size: 24px;
  }

  .technology-sec .technology-head .desc {
    font-size: 14px;
    margin-top: 15px;
  }

  .technology-sec .technology-head .market-research-content h5 {
    font-size: 16px;
  }

  .technology-sec .technology-head .market-research-content {
    padding-top: 20px;
  }

  .technology-sec
    .technology-head
    .market-research-content
    .market-research-content-list
    li {
    position: relative;
    padding-left: 35px;
    line-height: 20px;
    margin-bottom: 12px;
    font-size: 14px;
  }

  .challenges-and-solutions-sec .challenges-solutions-main-head .main-heading {
    font-size: 24px;
    padding-bottom: 15px;
  }

  .challenges-and-solutions-sec .challenges-solutions-main-head {
    margin-top: 0%;
    margin-bottom: 5%;
  }

  .challenges-and-solutions-sec .challenges-and-solutions-img img {
    margin-left: 0%;
  }

  .results-growth-sec .results-growth-head .main-heading {
    font-size: 24px;
  }

  .results-growth-sec .results-growth-content .results-growth-content-box {
    margin-top: 20px;
    background-color: white;
    padding: 15px;
  }

  .results-growth-sec
    .results-growth-content
    .results-growth-content-box
    .results-growth-content-list
    li {
    line-height: 20px;
    font-size: 14px;
  }

  .future-prospects-sec .future-prospects-head .main-heading {
    font-size: 24px;
  }

  .future-prospects-sec .future-prospects-head {
    background-color: white;
    min-height: 300px;
    padding: 25px;
  }

  .future-prospects-sec .future-prospects-head .future-prospects-content-box {
    padding-top: 15px;
  }

  .future-prospects-sec
    .future-prospects-head
    .future-prospects-content-box
    .future-prospects-content-list
    li {
    line-height: 25px;
    font-size: 14px;
  }

  .future-prospects-sec .future-prospects-img {
    margin-left: 0%;
    margin-top: 2%;
  }

  .conclusion-sec .conclusion-sec-head .main-heading {
    font-size: 24px;
  }

  .conclusion-sec .conclusion-content-box {
    padding: 15px;
    margin-top: 10px;
  }

  .conclusion-sec .conclusion-content-box .desc {
    line-height: 25px;
    font-size: 16px;
  }

  // huddle-heart-responsive-568pxto992px

  // huddle-heart

  .huddle-heart-banner-sec {
    min-height: 350px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content {
    margin-top: 5%;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .mt-30 {
    margin-top: 15px;
  }

  .huddle-heart-banner-sec .case-study-banner-content img {
    width: 50%;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .transform-button {
    color: white;
    background-color: #41b2e6;
    border: 1px solid #41b2e6;
    padding: 10px 24px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 14px;
  }

  .huddle-heart-vision-sec
    .market-research-head
    .market-research-content-box
    .market-research-content
    h5 {
    font-size: 16px;
  }

  .huddle-heart-vision-sec .market-research-img img {
    width: 60%;
  }

  .huddle-heart-vision-sec
    .market-research-head
    .market-research-content-box
    .market-research-content
    .para {
    font-size: 14px;
    line-height: 20px;
  }

  .huddle-heart-vision-sec
    .market-research-head
    .market-research-content-box
    .market-research-content
    .number-area {
    font-size: 32px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }

  .huddle-heart-vision-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
  }

  .huddle-heart-vision-sec .market-research-head .main-heading {
    font-size: 20px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content img {
    width: 40%;
  }

  .huddle-heart-banner-sec
    .huddle-heart-banner-content
    .huddle-heart-intro-list
    li {
    font-size: 14px;
    line-height: 20px;
  }

  .huddle-heart-vision-sec
    .market-research-head
    .market-research-content-box
    .market-research-content {
    padding: 10px;
    min-height: 300px;
    margin-bottom: 25px;
    border-bottom: 3px solid #35b6ff;
  }

  .huddle-heart-vision-sec .market-research-img {
    margin-top: 10%;
  }

  .huddle-heart-features-sec .pt-110 {
    padding-top: 0px;
  }

  .huddle-heart-features-sec .sec-title .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
  }

  .huddle-heart-features-sec .column {
    padding: 0px 10px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card {
    min-height: 320px;
    margin-bottom: 40px;
    padding: 15px 15px 40px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card .card-heading {
    font-weight: 600;
    font-size: 18px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card .desc {
    padding-top: 10px;
    font-size: 14px;
    line-height: 25px;
  }

  .enhanced-communication-sec .enhanced-communication-main-head .main-heading {
    font-size: 26px;
    padding-bottom: 15px;
  }

  .enhanced-communication-sec .enhanced-communication-card::before {
    display: none;
  }

  .enhanced-communication-sec .enhanced-communication-card {
    margin-top: 25%;
    padding: 15px;
    min-height: 325px;
    position: relative;
  }

  .enhanced-communication-sec .enhanced-communication-card .desc {
    line-height: 20px;
    font-size: 14px;
  }

  .enhanced-communication-sec .enhanced-img::after {
    display: none;
  }

  .enhanced-communication-sec .enhanced-img::before {
    display: none;
  }

  .enhanced-communication-sec .enhanced-img {
    width: 70%;
    min-height: 450px;
    position: relative;
    margin-bottom: 10%;
  }

  .enhanced-communication-card h5 {
    font-size: 18px;
  }

  .improved-safety-sec .huddle-heart-technology-head .main-heading {
    font-size: 26px;
    padding-top: 10px;
  }

  .huddle-heart-impacts-sec .huddle-heart-impacts-head .main-heading {
    font-size: 26px;
    padding-bottom: 10px;
  }

  .huddle-heart-impacts-sec .huddle-heart-impacts-card {
    padding: 10px;
    min-height: 356px;
    margin-bottom: 30px;
  }

  .huddle-heart-imapcts-img {
    margin-bottom: 0%;
    margin-top: 25%;
  }

  .huddle-heart-imapcts-img img {
    min-height: 260px;
  }

  .challenges-lessons-huddle-heart .challenges-lessons-main-head .main-heading {
    font-size: 30px;
    padding-bottom: 10px;
  }

  .challenges-lessons-huddle-heart .challenges-lessons-img {
    margin-top: 20%;
  }

  .challenges-lessons-huddle-heart
    .challenges-lessons-content
    .challenges-lessons-card
    .challenges-lessons-icon {
    position: absolute;
    left: 0;
    top: -20%;
    right: 0;
    margin: 0 auto;
  }

  .challenges-lessons-huddle-heart
    .challenges-lessons-content
    .challenges-lessons-card {
    margin-bottom: 45px;
    padding: 30px 15px 15px 15px;
  }

  .profile-banner-sec .screen-mob {
    width: 100%;
    min-height: 650px;
  }

  .profile-banner-sec .screen-mob img {
    width: 96%;
  }

  .future-prospects-sec .business-model-main-head .main-heading {
    font-size: 26px;
    padding-bottom: 10px;
  }

  .future-prospects-sec
    .business-model-card
    .business-model-list-box
    .business-model-list
    li
    .card-head {
    font-size: 18px;
  }

  .future-prospects-sec .business-model-card {
    min-height: 300px;
    margin-bottom: 25px;
    padding: 10px 15px;
  }

  // plywood-bazar-casestudy

  .plywood-bazar-banner-sec {
    min-height: 500px;
  }

  .plywood-bazar-banner-sec .plywood-bazar-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }

  .j-c-sb {
    justify-content: space-between;
  }

  .plywood-bazar-banner-sec .plywood-bazar-banner-content {
    margin-top: 5%;
    // margin-bottom: 5%;
  }

  .plywood-bazar-about-us .bg-shape-3 {
    position: absolute;
    bottom: 0%;
    right: 0%;
    text-align: end;
    display: none;
  }

  .plywood-bazar-about-us .bg-shape-1 img {
    width: 40%;
    display: none;
  }

  .plywood-bazar-about-us .bg-shape-2 img {
    width: 19%;
    display: none;
  }

  .plywood-bazar-banner-sec .plywood-bazar-banner-img {
    text-align: end;
    margin-top: 12%;
  }

  .plywood-bazar-about-us .about-content-box .main-heading {
    font-weight: 500;
    font-size: 28px;
    line-height: 25px;
    letter-spacing: 0.02em;
    color: #212529;
  }

  .about-us-img img {
    width: 80%;
  }

  .plywood-bazar-about-us .about-content-box {
    margin-top: 5%;
  }

  .plywood-bazar-about-us .about-content-list-box .about-content-list li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    line-height: 25px;
  }

  .plywood-bazar-about-us .about-content-box .sub-heading {
    font-size: 16px;
  }

  .mission-or-vision-plywood .mission-vision-right-shape {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }

  .mission-or-vision-plywood .left-side-box {
    margin-bottom: 25px;
    padding: 15px;
  }

  .mission-or-vision-plywood .left-side-box .desc {
    font-size: 16px;
    line-height: 25px;
  }

  .mission-or-vision-plywood .mission-vision-left-shape {
    position: absolute;
    left: 0;
    bottom: 30px;
    display: none;
  }

  .mission-or-vision-plywood .mission-vision-wrapper::after {
    position: absolute;
    bottom: -27%;
  }

  .mission-or-vision-plywood .right-side-box {
    margin-top: 78%;
    padding: 15px;
  }

  .mission-or-vision-plywood .right-side-box .desc {
    font-size: 16px;
    line-height: 25px;
  }

  .our-client-requirement .about-client-area .main-head {
    font-size: 26px;
  }

  .our-client-requirement .about-client-area .desc {
    font-size: 16px;
    padding-top: 0%;
    line-height: 25px;
  }

  .our-client-requirement .client-req-area .main-head {
    font-size: 26px;
    font-weight: 500;
  }

  .our-client-requirement .client-req-area .client-req-list li {
    border-left: 3px solid #f7c553;
    margin-bottom: 15px;
    padding-left: 10px;
    font-size: 16px;
    line-height: 25px;
  }

  .solution-given-sec .solution-given-content .main-heading {
    color: #b08218;
    font-size: 22px;
    font-weight: 500;
  }

  .solution-given-sec .solution-given-img {
    text-align: center;
    margin-top: 30%;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list
    li
    .heading {
    font-size: 20px;

    font-weight: 500;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list
    li::marker {
    font-size: 20px;
  }

  .plywoodbazar-features-sec .plywoodbazar-features-img {
    margin-top: 30%;
  }

  .solution-given-sec .solution-given-content .sub-heading {
    color: #b08218;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .plywoodbazar-features-head
    .sub-heading {
    font-size: 14px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .plywoodbazar-features-head
    .main-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list {
    height: 480px;
    overflow-y: auto;
    padding-left: 30px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list
    li {
    list-style-type: decimal;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  }

  .plywoodbazar-result-sec .plywoodbazar-result-main-head .desc {
    color: black;
    font-weight: 600;
    font-size: 14px;
    width: 80%;
    line-height: 20px;
    margin: 0 auto;
  }

  .plywoodbazar-result-sec .plywoodbazar-result-main-head .main-heading {
    font-size: 36px;
    color: #b08218;
    margin-bottom: 10px;
  }

  .our-work-screens .our-work-screens-main-head {
    position: absolute;
    width: 31%;
  }

  .our-work-screens .our-work-screens-main-head .main-heading {
    font-size: 34px;
  }

  // rocare-casestudy-568px

  .RoCare-banner-sec {
    min-height: 500px;
  }

  .RoCare-banner-sec .RoCare-banner-img {
    margin-top: 7%;
  }

  .RoCare-banner-sec .RoCare-banner-content .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .RoCare-banner-sec .RoCare-banner-content {
    margin-top: 4%;
  }

  .Rocare-aboutus .Rocare-aboutus-main-head {
    margin-top: 5%;
  }

  .client-requirements-sec .client-requirements-main-head {
    background-color: white;
    padding: 77px 15px 40px 15px;
  }

  .j-c-sb {
    justify-content: space-between;
  }

  .Rocare-features-sec .content-heading .features-heading {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .rocare-challenges-sec .rocare-challenges-content {
    margin-top: 12%;
  }

  // Nikkypore-casestudy-993pxto1365px

  .NikkyPore-banner-sec {
    min-height: 470px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .main-heading {
    font-size: 21px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-top: 10px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .mt-30 {
    margin-top: 15px;
  }

  .Nikkypore-project-summary .pb-50 {
    padding-bottom: 10px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .sub-head {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .desc {
    font-size: 14px;
    line-height: 25px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content {
    margin-top: 4%;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-img {
    text-align: end;
    margin-top: 7%;
    animation: 4s ease-in-out infinite floatY;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content .transform-button {
    padding: 10px 24px;
    font-size: 15px;
  }

  .Nikkypore-project-summary .Nikkypore-main-head .main-heading {
    font-size: 24px;
  }

  .Nikkypore-goals-objectives
    .Nikkypore-goals-objectives-main-head
    .main-heading {
    font-size: 24px;
  }

  .Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card {
    padding: 10px;
    min-height: 168px;
    margin-bottom: 20px;
  }

  .Nikkypore-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
  }

  .Nikkypore-goals-objectives
    .goals-obj-content-box
    .goals-obj-content-card
    .desc {
    color: #000;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  .mt-3 {
    margin-top: 0px !important;
  }

  .Nikkypore-features-sec .customer-app-features-content {
    margin-top: 0%;
  }

  .Nikkypore-features-sec .customer-app-features-content .white-box {
    padding: 10px;
    margin-bottom: 20px;
  }

  .Nikkypore-features-sec .customer-app-features-img {
    text-align: center;
  }

  .challenges-and-solutions-sec
    .challenges-sec
    .challenges-content-list
    li
    .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .challenges-and-solutions-sec .challenges-solutions-img {
    margin-top: 15%;
    text-align: center;
  }

  .challenges-and-solutions-sec .challenges-sec .challenges-content-list {
    margin-top: 0%;
  }

  .challenges-and-solutions-sec .challenges-solutions-content {
    margin-bottom: 4%;
  }

  .challenges-and-solutions-sec .challenges-sec h4 {
    font-size: 20px;
  }

  .challenges-and-solutions-sec
    .challenges-and-solutions-main-head
    .main-heading {
    font-size: 22px;
  }

  .visual-designs-sec .visual-designs-main-head .main-heading {
    font-size: 24px;
  }

  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide {
    margin: 0 auto;
    text-align: center;
  }

  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide img {
    width: 80%;
  }

  .visual-designs-sec .visual-designs-wrapper {
    padding: 0px;
  }

  .visual-designs-sec .visual-designs-wrapper::before {
    display: none;
  }

  .visual-designs-sec .visual-designs-main-head .desc {
    margin-bottom: 0px;
  }

  // Deyor-Casestudy-993px-to-1365px

  .deyor-main-banner-sec .deyor-main-banner-content {
    padding-left: 0%;
    margin-top: 6%;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .desc {
    color: #222a41;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-main-banner-logo {
    margin-bottom: 15px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li {
    flex-basis: 50%;
    margin-top: 10px;
    font-size: 16px;
  }

  .deyor-main-banner-sec {
    min-height: 380px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li h5 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 25px;
  }

  .deyor-about-us .deyor-about-us-main-head .main-heading {
    font-size: 30px;
  }

  .deyor-about-us {
    padding: 20px 0px 20px 0px;
    min-height: 570px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-image-box
    .aboutus-logo {
    position: absolute;
    width: 20%;
    right: -19%;
    top: 0;
  }

  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box {
    position: relative;
    cursor: pointer;
    margin-bottom: 0%;
  }

  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content {
    margin-top: 20%;
    cursor: pointer;
  }

  .deyor-features-sec .feature-user2 .deyor-features-img {
    text-align: center;
    margin-top: 5%;
  }

  .deyor-features-sec .feature-user3 .deyor-features-img {
    text-align: center;
    margin-top: 5%;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-content
    .main-heading {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .desc {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .deyor-features-sec .deyor-features-main-head .main-heading {
    font-size: 32px;
    font-weight: 600;
  }

  .deyor-features-sec .first-child-bg-img {
    display: none;
  }

  .deyor-features-sec .deyor-features-content {
    margin-top: 0%;
    padding-left: 1%;
    margin-left: 0px;
    background: #fff;
    z-index: 99;
    width: 100%;
    position: relative;
  }

  .deyor-features-sec .deyor-features-content .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .deyor-features-sec .deyor-features-content .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 7px;
    display: block;
    margin-bottom: 10px;
  }

  .deyor-features-sec .feature-user2 .second-child-bg-img {
    display: none;
  }

  .deyor-features-sec .feature-user3 {
    position: relative;
    padding-top: 25px;
  }

  .deyor-features-sec .deyor-features-content1 {
    margin-top: 10%;
  }

  .deyor-features-sec .deyor-features-content1 .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .deyor-features-sec .feature-user3 .third-child-bg-img {
    display: none;
  }

  .deyor-features-sec .feature-user2 {
    padding-top: 20px;
    position: relative;
  }

  .d-f-fd-cr {
    display: flex;
    flex-direction: column-reverse;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box {
    margin-top: 5%;
    margin-right: 0%;
    min-height: 400px;
    margin-bottom: 5%;
    padding: 65px 40px 50px;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .desc {
    font-size: 18px;
  }

  .deyor-challenges-sec .deyor-challenges-img {
    text-align: center;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .main-heading {
    font-size: 26px;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }

  .deyor-solutions-sec .deyor-solutions-main-head .main-heading {
    font-size: 26px;
  }

  .deyor-solutions-sec .deyor-solutions-img {
    text-align: center;
  }

  .deyor-solutions-sec .deyor-solutions-content-box {
    margin-top: 5%;
    margin-left: 0%;
    min-height: 400px;
    margin-bottom: 0%;
    padding: 65px 40px 50px;
  }

  .deyor-solutions-sec .deyor-solutions-content-box .desc {
    font-size: 18px;
  }

  .deyor-solutions-sec .deyor-solutions-content-box .main-heading {
    font-size: 26px;
  }

  .deyor-solutions-sec .deyor-solutions-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }

  .deyor-work-screens-sec .deyor-work-screens-main-head .main-heading {
    font-size: 30px;
  }

  // samagri-1025px-responsive
  .samagri-banner-sec .samagri-banner-shape img {
    position: absolute;
    width: 36%;
    z-index: 1;
    left: 35px;
  }

  .samagri-banner-sec .samagri-banner-content .main-heading {
    font-size: 26px;
    line-height: 35px;
  }

  .samagri-banner-sec .samagri-banner-content .desc {
    font-size: 18px;
    color: #413d2c;
    font-weight: 600;
    line-height: 30px;
  }

  .samagri-banner-sec .samagri-banner-content .desc1 {
    font-size: 16px;
    color: #413d2c;
    font-weight: 400;
    line-height: 25px;
  }

  .samagri-banner-sec .samagri-banner-content {
    margin-top: 10%;
    padding-bottom: 10%;
  }

  .samagri-aboutus-sec .samagri-aboutus-wrap-box {
    background-color: white;
    padding: 10px;
    justify-content: space-between;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    span
    svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li {
    display: flex;
    align-items: start;
    line-height: 35px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    span
    svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li {
    line-height: 35px;
  }

  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box {
    margin-top: 25%;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-content-box
    .main-heading {
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 15px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-content-box
    .desc {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-img-box
    .samagri-logo-img
    img {
    width: 60%;
  }

  .samagri-project-highlights .samagri-project-highlights-img .circle img {
    position: absolute;
    left: 40%;
    top: 160px;
    width: 60%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-img
    .circle
    .circle-content {
    position: absolute;
    top: 46%;
    left: 60%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-img
    .circle
    .circle-content
    .main-heading {
    font-size: 24px;
    font-weight: 600;
  }

  .samagri-project-highlights
    .samagri-project-highlights-img
    .circle
    .circle-content
    .main-heading
    span {
    display: block;
    font-size: 28px;
    color: #c48041;
  }

  .samagri-project-highlights {
    background-color: #b99471;
    min-height: 800px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(1) {
    position: absolute;
    left: -10%;
    top: -5%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(2) {
    position: absolute;
    top: 30%;
    left: 5%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(3) {
    position: absolute;
    top: 62%;
    left: 5%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(4) {
    position: absolute;
    top: 96%;
    left: -8%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(-24px 29px 25px rgba(12, 12, 13, 0.32));
    text-align: center;
    line-height: 75px;
    margin-bottom: 10px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-icon
    img {
    width: 45%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-area {
    padding-left: 0px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-area
    .desc {
    font-size: 14px;
    line-height: 25px;
    color: #fff;
  }

  .samagri-work-screens {
    position: relative;
    width: 100%;
    min-height: 350px;
  }

  .samagri-work-screens .samagri-work-screen-content .main-heading {
    font-size: 22px;
    font-weight: 600;
  }

  .samagri-work-screens .samagri-work-screen-content {
    margin-top: 0%;
  }

  .samagri-work-screens .samagri-work-screen-content .desc {
    line-height: 20px;
    font-size: 13px;
    width: 70%;
  }

  .samagri-features-sec .samagri-features-main-head {
    margin-top: 5%;
  }

  .samagri-features-sec .samagri-features-main-head .main-heading {
    color: black;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .samagri-features-sec .samagri-features-card {
    padding: 15px 10px;
    margin-bottom: 15px;
    min-height: 135px;
  }

  .samagri-features-sec .samagri-features-card .card-heading {
    font-size: 14px;
    margin-bottom: 0px !important;
  }

  .samagri-features-sec .samagri-features-img {
    margin-top: 15%;
  }

  .samagri-challenges-sec .samagri-challenges-main-head .main-heading {
    font-size: 22px;
    color: white;
  }

  .samagri-challenges-sec .samagri-challenges-content {
    margin-top: 0%;
  }

  .samagri-challenges-sec
    .samagri-challenges-content
    .samagri-challenges-card
    .samagri-challenges-card-heading
    .main-heading {
    text-align: center;
    font-size: 14px;
    color: white;
    padding: 10px;
  }

  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card {
    padding: 5px;
  }

  .samagri-challenges-sec
    .samagri-challenges-content
    .samagri-challenges-card
    .desc {
    padding: 5px;
    line-height: 20px;
    font-size: 14px;
  }

  .samagri-challenges-sec .samagri-challenges-img {
    margin-bottom: 0%;
    margin-top: 15%;
  }

  .samagri-contact-banner
    .position-relative-z-index-1
    .samagri-contact-banner-content
    .main-heading {
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .samagri-contact-banner
    .position-relative-z-index-1
    .samagri-contact-banner-content
    .desc {
    color: white;
    font-size: 14px;
    line-height: 20px;
  }

  .samagri-contact-banner {
    padding: 50px 0px;
  }

  // Deyor-Casestudy-993px-to-1024px

  .deyor-main-banner-sec .deyor-main-banner-content {
    padding-left: 0%;
    margin-top: 5%;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .desc {
    color: #222a41;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-main-banner-logo {
    margin-bottom: 15px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li {
    flex-basis: 50%;
    margin-top: 10px;
    font-size: 16px;
  }

  .deyor-main-banner-sec {
    min-height: 350px;
  }

  .deyor-main-banner-sec .deyor-main-banner-content .deyor-content-list li h5 {
    font-size: 16px;
    margin-bottom: 0;
    line-height: 25px;
  }

  .deyor-about-us .deyor-about-us-main-head .main-heading {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .deyor-about-us {
    padding: 20px 0px 20px 0px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-image-box
    .aboutus-logo {
    position: absolute;
    width: 24.3%;
    right: 0;
    top: 0;
  }

  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-image-box {
    position: relative;
    cursor: pointer;
    margin-bottom: 80%;
  }

  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content {
    margin-top: 2%;
    cursor: pointer;
  }

  .deyor-objective-sec .deyor-objective-content-box {
    margin-top: 12%;
  }

  .deyor-objective-sec
    .deyor-objective-content-box
    .deyor-objective-card
    .number-area {
    display: block;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
    color: #c4343c;
  }

  .deyor-objective-sec .deyor-objective-content-box .deyor-objective-card {
    padding: 10px;
    min-height: 170px;
    margin-bottom: 20px;
  }

  .deyor-objective-sec
    .deyor-objective-content-box
    .deyor-objective-card
    .desc1 {
    font-size: 14px;
    line-height: 20px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-content
    .main-heading {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .deyor-about-us .deyor-about-us-wrapper .deyor-about-us-content .desc {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-content
    .deyor-about-us-content-list
    li {
    font-size: 18px;
    line-height: 30px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-content
    .deyor-about-us-content-list
    li
    .list-bar {
    position: absolute;
    left: 0;
    top: 15px;
  }

  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box {
    margin-top: 5%;
    background-color: white;
    padding: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 20px;
  }

  .deyor-methodology-sec .deyor-methodology-img {
    margin-top: 50%;
    text-align: center;
  }

  .deyor-methodology-sec .deyor-methodology-img .mt-10 {
    margin-top: 70%;
    width: 100%;
    text-align: center;
    margin-left: 0%;
  }

  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .deyor-methodology-sec
    .deyor-methodology-content-box
    .implementation-box-1
    h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .deyor-methodology-sec
    .deyor-methodology-content-box
    .implementation-box
    .deyor-features-content-list
    .content-list-li
    .deyor-features-inner-list
    li
    p {
    font-size: 14px;
    line-height: 20px;
  }

  .deyor-methodology-sec
    .deyor-methodology-content-box
    .implementation-box-1
    .deyor-features-content-list
    .content-list-li
    .deyor-features-inner-list
    li
    p {
    font-size: 14px;
    line-height: 20px;
  }

  .deyor-methodology-sec .deyor-methodology-content-box .outcomes-box h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .deyor-methodology-sec .deyor-methodology-content-box .outcomes-box-1 h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .deyor-methodology-sec
    .deyor-methodology-content-box
    .outcomes-box
    .deyor-features-content-list
    .content-list-li
    .deyor-features-inner-list
    li
    p {
    font-size: 14px;
    line-height: 20px;
  }

  .deyor-methodology-sec
    .deyor-methodology-content-box
    .outcomes-box-1
    .deyor-features-content-list
    .content-list-li
    .deyor-features-inner-list
    li
    p {
    font-size: 14px;
    line-height: 20px;
  }

  .deyor-methodology-sec .deyor-methodology-content-box .implementation-box-1 {
    margin-top: 7%;
  }

  .deyor-methodology-sec .deyor-methodology-content-box .sub-heading {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
  }

  .deyor-challenges-sec .d-f-fd-cr-deyor {
    display: flex;
    flex-direction: row;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box {
    margin-top: 5%;
    margin-right: 0%;
    min-height: 430px;
    margin-bottom: 5%;
    padding: 45px 50px 40px;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .challenges-content-list
    li
    .challenges-inner-list
    li
    .desc {
    font-size: 14px;
    line-height: 20px;
  }

  .deyor-challenges-sec .deyor-challenges-img {
    text-align: center;
    margin-top: 10%;
  }

  .deyor-challenges-sec .deyor-challenges-img img {
    width: 100%;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .desc {
    font-size: 18px;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .main-heading {
    font-size: 26px;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }

  .deyor-solutions-sec .d-f-fd-cr-deyor {
    display: flex;
    flex-direction: row;
  }

  .deyor-solutions-sec .deyor-solutions-main-head .main-heading {
    font-size: 26px;
  }

  .deyor-solutions-sec .deyor-solutions-img {
    text-align: center;
  }

  .deyor-solutions-sec .deyor-solutions-img img {
    width: 80%;
  }

  .deyor-challenges-sec
    .deyor-challenges-wrap-box
    .deyor-challenges-content-box
    .challenges-content-list {
    margin-top: 5%;
  }

  .deyor-solutions-sec .deyor-solutions-content-box {
    margin-top: 5%;
    margin-left: 0%;
    min-height: 400px;
    margin-bottom: 0%;
    padding: 50px 50px 50px;
  }

  .deyor-solutions-sec .deyor-solutions-content-box .desc {
    font-size: 18px;
  }

  .deyor-solutions-sec .deyor-solutions-content-box .main-heading {
    font-size: 26px;
  }

  .deyor-solutions-sec .deyor-solutions-content-box .small-bar {
    background-color: #c4343c;
    width: 47px;
    height: 5px;
    display: block;
    margin-bottom: 10px;
  }

  .deyor-work-screens-sec .deyor-work-screens-main-head .main-heading {
    font-size: 24px;
  }

  // turning-point-993px

  .turning-point-banner-sec {
    min-height: 450px;
  }

  .turning-point-banner-sec .turning-point-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 15px;
  }

  .turning-point-banner-sec .turning-point-banner-content {
    margin-top: 5%;
  }

  .turning-point-banner-sec .turning-point-banner-img img {
    margin-top: 10%;
  }

  .turning-point-banner-sec .turning-point-banner-content .transform-button {
    padding: 10px 24px;
    font-weight: 500;
  }

  .turning-point-about-client-sec .turning-point-about-client-content-box {
    padding-left: 20px;
    padding-top: 8%;
    position: relative;
  }

  .turning-point-about-client-sec
    .turning-point-about-client-main-head
    .main-heading1 {
    font-size: 26px;
    font-weight: 600;
    position: relative;
    margin-bottom: 10px;
  }

  .turning-point-about-client-sec .turning-point-about-client-img {
    margin-top: 2%;
  }

  .turning-point-project-highlights-sec
    .turning-point-project-highlights-head
    .main-heading {
    font-size: 26px;
    padding-bottom: 10px;
  }

  .turning-point-project-highlights-sec
    .project-highlights-content-area
    .project-highlights-card
    .turning-point-project-highlights-card-content
    .content-txt
    h5 {
    font-size: 17px;
  }

  .turning-point-project-highlights-sec
    .project-highlights-content-area
    .project-highlights-card-style1
    .turning-point-project-highlights-card-content
    .content-txt
    h5 {
    font-size: 17px;
  }

  .turning-point-features-sec .customer-app-features-content {
    margin-top: 0%;
  }

  .turning-point-features-sec .features-sec-main-head .main-heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .turning-point-features-sec .customer-app-features-content .white-box {
    min-height: 177px;
  }

  .turning-point-features-sec
    .position-relative-z-index-1
    .customer-app-features-img {
    text-align: center;
    margin-top: 25%;
  }

  .turning-point-challenges-and-solutions-sec .challenges-solutions-img {
    text-align: center;
    margin-top: 25%;
  }

  .turning-point-challenges-and-solutions-sec
    .challenges-and-solutions-main-head
    .main-heading {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .turning-point-challenges-and-solutions-sec .challenges-sec {
    margin-top: 5%;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-main-head
    .main-heading {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-wrapper::before {
    display: none;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-wrapper
    .turning-point-visual-designs-slide
    img {
    width: 65%;
  }

  .turning-point-conclusion-sec
    .turning-point-conclusion-sec-head
    .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .turning-point-conclusion-sec .turning-point-conclusion-content-box .desc {
    font-size: 15px;
    line-height: 25px;
  }

  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper {
    background-color: #f0686c;
    padding: 20px 0px 20px;
    position: relative;
  }

  // extra css

  .main-blog .container-fluid {
    padding: 0px 2pc;
  }

  .homebannersectoin {
    padding: 25px 2pc;
  }

  .pt-80 {
    padding-top: 40px !important;
  }

  .homebannersectoin h3 {
    font-size: 24px;
    font-weight: 600;
    padding: 0px 0;
  }

  .homebannersectoin h4 {
    color: #f8b418;
    font-size: 26px;
  }

  .homebannersectoin p {
    font-size: 15px;
    width: 100%;
    color: #000;
    font-weight: 600;
    margin: 15px 0;
  }

  .newportfolyo .card_portfoliyo .portfolicardview::before {
    height: 70vh;
  }

  .newportfolyo .card_portfoliyo .text_left {
    margin-left: 1%;
    text-align: end;
  }

  .newportfolyo .leftsidecontnt .text_right {
    width: 100%;
  }

  .newportfolyo .card_portfoliyo .portfolicardview img {
    width: 100%;
    z-index: 999;
    position: relative;
    margin-top: 19%;
  }

  h1.heading {
    font-size: 28px !important;
    line-height: 30px !important;
  }

  .pb-80 {
    padding-bottom: 20px !important;
  }

  .mb-80 {
    margin-bottom: 30px !important;
  }

  .services_list li {
    list-style: disc;
    font-size: 13px;
    margin-bottom: 7px;
    line-height: 20px;
  }

  .worksflosection .box {
    padding: 15px 10px;
    min-height: 285px;
  }

  .worksflosection .process-icon {
    height: 70px;
    margin: 0 auto 15px;
  }

  .worksflosection h4 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .worksflosection p {
    font-size: 14px;
    line-height: 20px;
  }

  .in_expertise_s .box_indstr p {
    color: #222;
    font-size: 13px;
    font-weight: 600;
  }

  .in_expertise_s .box_indstr {
    margin-bottom: 15px;
    padding: 15px 0;
  }

  .our-portfolio-section-container
    .our_port_section
    .appent_left_section
    .slider_portfliucontent {
    width: 90%;
    padding-top: 0%;
  }

  .our-portfolio-section-container .our_port_section .appent_left_section {
    height: 60vh;
  }

  .our-portfolio-section-container .our_port_section .appent_right_section {
    height: 60vh;
  }

  .our-portfolio-section-container .our_port_section {
    height: 60vh !important;
    background-color: #ccc;
  }

  .ptb-80 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .appent_left_section .slider_portfliucontent h3 {
    color: #fff;
    font-size: 40px;
  }

  .appent_left_section .slider_portfliucontent p {
    color: #fff;
    font-size: 20px;
    margin-bottom: 25px;
    line-height: 35px;
  }

  .tabs_customersays .container-fluid {
    padding: 0px 2pc !important;
  }

  .mb-5 {
    margin-bottom: 2rem !important;
  }

  .testimontab_body {
    background-color: #fafafa;
    min-height: 476px;
    padding: 40px;
    position: relative;
  }

  .our-portfolio-section-container
    .our_port_section
    .appent_right_section
    .centermockupimg {
    position: absolute;
    top: 10%;
    margin: auto;
    right: 36%;
  }
}

@media only screen and (min-width:1200px) and (max-width:1536px) {

  .navbar_top.navbar
  {
    padding: 5px 12px!important;
  }
  .bootom-bar .navbar-nav .nav-link
  {
    padding: 15px 12px!important;
  }
  .homebannersectoin
  {
    padding:20px 1pc!important;

    h1
    {
      font-size: 28px;
      padding: 0px;
    }

    h2
    {
      font-size: 28px;
    }

    p {
      font-size: 15px;
      width: 100%;
      margin: 15px 0;
  }



  .home_banner_img
  {
    width:430px;
    height: 430px;
  }
 
  }

  .submit_buttn {
    
    padding: 10px 32px!important;
    font-size: 16px!important;
    border-radius: 5px!important;
}


.services-swiper ul.tabs li {
  padding: 10px 1pc!important;
}
.services-swiper .tab-description ul li
{
  font-size: 15px!important;
}
  .ptb-80
  {
    padding: 30px 0px!important;
  }
  .mb-80
  {
    margin-bottom: 30px!important;
  }
  .pt-80
  {
    padding-top: 30px!important;
  }

  h1.heading {
    font-size: 30px!important;
    line-height: 40px!important;
  }

  .our-clients .marquee-items.left .items_logo {
    width: 100%;
    height: 150px;
    padding: 10px;
}
.consultation .row
{
  padding: 1.6rem!important;
}

.consultation .left
{
  padding: 0pc!important;
}

.df_jcsb
{
  display: inline!important;
}
.form.row
{
  padding: 0px!important;
}

.right.h-100 
{
  height: 80%!important;
}

h3.heading {
  font-size: 34px!important;
  line-height: 40px!important;
}

.worksflosection .box
{
  min-height: 260px!important;
}

.worksflosection .process-icon {
  height: 60px;
  margin: 0 auto 18px;
  width: 60px;
  font-size: 1.6rem;
}

.worksflosection h4
{
  font-size: 16px!important;
  margin-bottom:10px!important;
}

.worksflosection p
{
  font-size: 13px!important;
}

.blog .blog-box p
{
  font-size: 13px;
  line-height: 20px;
}

.blog-box ul.tags li
{
  font-size: 12px!important;
}

.blog-box h4 {
  margin: 5px 0!important;
  font-size: 18px!important;
  font-weight: 600;
}

}


//  start of min-width:1366px

@media only screen and (min-width: 1366px) and (max-width: 1367px) {
  .crm-banner-sec .Crm-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 40%;
    left: 7%;
  }

  .why-you-need-card {
    min-height: 370px;
  }

  .manufacturing-banner-sec .manufacturing-banner-content .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .crm-banner-sec .Crm-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  .manufacturing-banner-sec .manufacturing-banner-content {
    position: absolute;
    z-index: 2;
    top: 60%;
    width: 35%;
    left: 7%;
  }

  .possible-channels-sec
    .possible-channels-tab-panes
    .nav-tabs
    .nav-item
    button {
    background-color: #e8ebf1;
    border-radius: 50px;
    color: #020202;
    padding: 8px 20px !important;
    margin: 0px 20px;
    font-weight: 600;
  }

  .fntch_fnl_inn .develmntser h3 {
    font-size: 30px;
    font-weight: 600;
    color: white;
  }

  .why-you-need-whatsappbot .why-you-need-whatsappbot-card {
    padding: 25px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1607843137);
    border-radius: 8px;
    background-color: white;
    text-align: center;
    margin-bottom: 25px;
    min-height: 222px;
  }

  .whatsapp-bot-about-us .whatsapp-bot-about-us-head {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }

  .crm-development-services-sec .col-xl-6:nth-child(2) {
    margin-top: 50px;
  }

  .crm-development-services-sec .col-xl-6:nth-child(3) {
    margin-top: -35px;
  }

  .crm-development-services-card .crm-development-services-icon img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    margin-bottom: 0px;
  }

  .crm-development-services-card {
    min-height: 376px;
  }

  .society-mt-banner-sec .society-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 35%;
    width: 35%;
    left: 7%;
  }

  .society-mt-banner-sec .society-mt-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .school-mt-banner-sec .school-mt-banner-content .main-heading {
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .school-mt-banner-sec .school-mt-banner-content .sub-heading {
    color: white;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    font-size: 17px;
  }

  .school-mt-banner-sec .school-mt-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 35%;
    left: 7%;
  }

  .sch-software-process-tabs .nav-tabs .nav-link {
    color: #000;
    background: linear-gradient(188.28deg, #ffffff 22.17%, #f6f6f7 116.15%);
    box-shadow: 0px 3px 6px rgba(0, 0, 128, 0.1);
    border-radius: 20px;
    width: 120px;
    min-height: 90px;
    padding: 10px;
    font-size: 14px;
    margin: 50px 5px 0px 5px;
    line-height: 20px;
    font-weight: 600;
    transform: scale(1) translateY(0%);
    transition: all 0.5s ease;
  }

  .sch-software-process-tabs .step-triangle .step-triangle-list li {
    width: 158.5px;
    margin-left: 5px;
  }

  .portfolio-left-area .quote {
    position: absolute;
    bottom: 4%;
    left: 12%;
    color: white;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
  }

  .portfolio-left-area {
    width: 42.6%;
  }

  .explore-more-button {
    font-size: 20px;
  }

  .travel-choose-card .heading-part h4 {
    font-size: 16px;
  }

  .travel-banner-content {
    position: absolute;
    top: 50%;
    left: 5%;
    z-index: 1;
    width: 35%;
  }

  .travel-banner-content h2 {
    font-size: 26px;
  }

  .travel-banner-content .desc {
    margin-top: 10px;
    font-size: 18px;
  }

  .job-features-tab-pane .co-features {
    position: absolute;
    left: 29%;
    top: 2.5%;
    z-index: 99999;
  }

  .developmentsecib .cardapp {
    padding: 40px 30px;
    margin-bottom: 20px;
    border-radius: 20px;
    min-height: 597px;
  }

  .freaturesectionapp img {
    margin-top: 60%;
    width: 100%;
  }

  .freaturesectionapp li {
    padding: 18px;
    margin-bottom: 30px;
    border-radius: 20px;
    border: 1px solid #d1d1d1;
    transition: all 0.5s;
    min-height: 200px;
  }

  .list_feature li p {
    font-size: 14px;
  }

  .premium_section .list_feature li .fe_hd h2 {
    font-size: 17px;
    margin-bottom: 0;
    font-weight: 600;
    margin-left: 0px;
  }

  .premium_section .list_feature li p {
    color: #000;
    font-size: 15px;
    line-height: 20px;
  }

  .premium_section .list_feature li {
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 20px;
    border: 1px solid #d1d1d1;
    transition: all 0.5s;
    min-height: 215px;
  }

  .premium_section .list_feature li .fe_hd {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
  }

  .retail-pos-banner-sec .retail-pos-banner-content .main-heading {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .retail-pos-banner-sec .retail-pos-banner-content {
    position: absolute;
    z-index: 2;
    top: 55%;
    width: 35%;
    left: 7%;
  }

  .real-est-banner-sec .real-est-banner-content .main-heading {
    color: white;
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .real-est-banner-sec .real-est-banner-content {
    position: absolute;
    z-index: 2;
    top: 45%;
    width: 30%;
    left: 7%;
  }

  .app-development-features-img {
    margin-top: 40%;
    text-align: center;
  }

  .py-40 {
    padding: 20px 0px;
  }

  .real-est-mngmnt-system .real-est-mngmnt-content .desc {
    color: white;
    font-size: 18px;
    margin: 10px 0px 20px 0px;
  }

  .explore-erp-development-list li {
    margin-bottom: 15px;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
  }

  .erp-portfolio-area .erp-portfolio-left-side {
    background-color: rgb(215, 164, 64);
    padding: 60px 40px 20px 40px;
  }

  // casestudy1

  .case-study-banner-sec .case-study-banner-content {
    position: absolute;
    z-index: 2;
    top: 40%;
    width: 80%;
    left: 5%;
  }

  .case-study-banner-sec .case-study-banner-content .main-heading {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .case-study-banner-sec .case-study-banner-content .breadcrumb-casestudy-box {
    margin-bottom: 5px;
  }

  .case-study-banner-sec .case-study-banner-content .desc {
    font-size: 16px;
    line-height: 20px;
  }

  .about-client-sec .about-client-content-box .desc {
    font-size: 17px;
    line-height: 25px;
  }

  .about-client-sec .about-client-main-head .main-heading {
    font-size: 22px;
  }

  .about-client-sec .about-client-content-box {
    padding: 15px;
    position: relative;
    margin-top: 10%;
  }

  .about-client-sec .about-client-content-box .right-quote {
    position: absolute;
    left: 5%;
    top: 0%;
    width: 10%;
  }

  .about-client-sec .about-client-main-head .main-heading1 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-bottom: 5px;
  }

  .about-client-sec .about-client-content-box .left-quote {
    position: absolute;
    right: 5%;
    bottom: 2%;
    width: 10%;
  }

  .business-model-sec .business-model-main-head .main-heading {
    font-size: 22px;
  }

  .business-model-sec .business-model-main-head .desc {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }

  .business-model-sec .business-model-card {
    padding: 15px;
    border-radius: 5px;
    min-height: 200px;
    cursor: pointer;
    margin-bottom: 25px;
  }

  .market-research-sec .market-research-head .main-heading {
    font-size: 22px;
  }

  .market-research-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
  }

  .market-research-sec
    .market-research-head
    .market-research-content-box
    .market-research-content {
    padding: 15px;
    min-height: 320px;
    margin-bottom: 25px;
  }

  .market-research-sec .market-research-img {
    margin-top: 30px;
  }

  .technology-sec
    .technology-content-area
    .technology-card
    .technology-card-content {
    border-top: 1px solid #e5e5e5;
    padding-top: 80px;
  }

  .market-research-sec
    .market-research-head
    .market-research-content
    .market-research-content-list
    li {
    position: relative;
    padding-left: 35px;
    line-height: 25px;
    margin-bottom: 12px;
    font-size: 14px;
  }

  .challenges-and-solutions-sec
    .challenges-solutions-content
    .challenges-solutions-card
    .number-card {
    content: "";
    position: absolute;
    left: -9%;
  }

  .market-research-sec .market-research-head .market-research-content h5 {
    font-size: 20px;
  }

  .market-research-sec .market-research-head .market-research-content {
    padding-top: 20px;
    margin-bottom: 35px;
  }

  .technology-sec .technology-head .main-heading {
    font-size: 24px;
  }

  .technology-sec .technology-head .desc {
    font-size: 14px;
    margin-top: 15px;
  }

  .technology-sec .technology-head .market-research-content h5 {
    font-size: 16px;
  }

  .technology-sec .technology-head .market-research-content {
    padding-top: 20px;
  }

  .technology-sec
    .technology-head
    .market-research-content
    .market-research-content-list
    li {
    position: relative;
    padding-left: 35px;
    line-height: 20px;
    margin-bottom: 12px;
    font-size: 14px;
  }

  .challenges-and-solutions-sec .challenges-solutions-main-head .main-heading {
    font-size: 24px;
    padding-bottom: 15px;
  }

  .challenges-and-solutions-sec .challenges-solutions-main-head {
    margin-top: 0%;
    margin-bottom: 0%;
  }

  .challenges-and-solutions-sec .challenges-and-solutions-img img {
    margin-left: 0%;
  }

  .results-growth-sec .results-growth-head .main-heading {
    font-size: 24px;
  }

  .results-growth-sec .results-growth-content .results-growth-content-box {
    margin-top: 20px;
    background-color: white;
    padding: 15px;
  }

  .results-growth-sec
    .results-growth-content
    .results-growth-content-box
    .results-growth-content-list
    li {
    line-height: 20px;
    font-size: 14px;
  }

  .future-prospects-sec .future-prospects-head .main-heading {
    font-size: 24px;
  }

  .future-prospects-sec .future-prospects-head {
    background-color: white;
    min-height: 300px;
    padding: 25px;
  }

  .future-prospects-sec .future-prospects-head .future-prospects-content-box {
    padding-top: 15px;
  }

  .future-prospects-sec
    .future-prospects-head
    .future-prospects-content-box
    .future-prospects-content-list
    li {
    line-height: 25px;
    font-size: 14px;
  }

  .future-prospects-sec .future-prospects-img {
    margin-left: 0%;
    margin-top: 2%;
  }

  .conclusion-sec .conclusion-sec-head .main-heading {
    font-size: 24px;
  }

  .conclusion-sec .conclusion-content-box {
    padding: 15px;
    margin-top: 10px;
  }

  .conclusion-sec .conclusion-content-box .desc {
    line-height: 25px;
    font-size: 16px;
  }

  .future-prospects-sec {
    background-color: rgba(231, 231, 231, 0.7607843137);
    min-height: auto;
    width: 100%;
  }

  // huddle-heart

  .profile-banner-sec .screen-mob {
    width: 80%;
    min-height: 620px;
  }

  .screen-mob img {
    width: 96%;
  }

  .huddle-heart-imapcts-img {
    margin-top: 15%;
  }

  .huddle-heart-imapcts-img img {
    min-height: 270px;
  }

  .improved-safety-sec
    .technology-content-area
    .technology-card
    .huddle-heart-technology-card-content
    .content-txt
    .desc {
    line-height: 20px;
    font-size: 14px;
  }

  .technology-card-content .content-txt h5 {
    padding: 5px 0px;
    font-size: 16px;
  }

  .huddle-heart-technology-card-content .content-txt h5 {
    padding: 5px 0px;
    font-size: 16px;
  }

  .enhanced-communication-sec .enhanced-communication-main-head .main-heading {
    font-size: 26px;
    padding-bottom: 15px;
  }

  .enhanced-communication-sec .enhanced-communication-card::before {
    display: none;
  }

  .enhanced-communication-sec .enhanced-communication-card {
    margin-top: 30%;
    padding: 15px;
    min-height: 325px;
    position: relative;
  }

  .enhanced-communication-sec .enhanced-communication-card .desc {
    line-height: 20px;
    font-size: 14px;
  }

  .enhanced-communication-sec .enhanced-img::after {
    display: none;
  }

  .enhanced-communication-sec .enhanced-img::before {
    display: none;
  }

  .enhanced-communication-sec .enhanced-img {
    width: 70%;
    min-height: 530px;
    position: relative;
    margin-bottom: 10%;
  }

  .huddle-heart-features-sec .sec-title .main-heading {
    font-size: 24px;
    padding-bottom: 10px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card {
    min-height: 320px;
    margin-bottom: 40px;
    padding: 15px 15px 40px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card .card-heading {
    font-weight: 600;
    font-size: 18px;
  }

  .huddle-heart-features-sec .huddle-heart-features-card .desc {
    padding-top: 10px;
    font-size: 14px;
    line-height: 25px;
  }

  .huddle-heart-vision-sec .market-research-head .desc {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
  }

  .huddle-heart-vision-sec .market-research-head .main-heading {
    font-size: 20px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content img {
    width: 40%;
  }

  .huddle-heart-banner-sec
    .huddle-heart-banner-content
    .huddle-heart-intro-list
    li {
    font-size: 14px;
    line-height: 20px;
  }

  .huddle-heart-vision-sec
    .market-research-head
    .market-research-content-box
    .market-research-content {
    padding: 10px;
    min-height: 300px;
    margin-bottom: 25px;
    border-bottom: 3px solid #35b6ff;
  }

  .huddle-heart-vision-sec .market-research-img {
    margin-top: 10%;
  }

  .huddle-heart-vision-sec
    .market-research-head
    .market-research-content-box
    .market-research-content
    h5 {
    font-size: 16px;
  }

  .huddle-heart-vision-sec
    .market-research-head
    .market-research-content-box
    .market-research-content
    .para {
    font-size: 14px;
    line-height: 20px;
  }

  .huddle-heart-vision-sec
    .market-research-head
    .market-research-content-box
    .market-research-content
    .number-area {
    font-size: 32px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content {
    margin-top: 10%;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .mt-30 {
    margin-top: 15px;
  }

  .huddle-heart-banner-sec .case-study-banner-content img {
    width: 50%;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .transform-button {
    color: white;
    background-color: #41b2e6;
    border: 1px solid #41b2e6;
    padding: 10px 24px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    font-size: 14px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 10px;
  }

  .huddle-heart-banner-sec .huddle-heart-banner-img img {
    margin-top: 5%;
    width: 70%;
  }

  // plywoodbazar-case-study-1366px

  .plywood-bazar-banner-sec .plywood-bazar-banner-img {
    text-align: end;
    margin-top: 8%;
  }

  .plywood-bazar-about-us .bg-shape-1 {
    position: absolute;
    top: 0;
    left: 0%;
    display: none;
  }

  .plywood-bazar-about-us .bg-shape-2 {
    position: absolute;
    bottom: 0;
    left: 0%;
    display: none;
  }

  .plywood-bazar-about-us .bg-shape-3 {
    position: absolute;
    bottom: 0%;
    right: 0%;
    text-align: end;
    display: none;
  }

  .mission-or-vision-plywood .mission-vision-left-shape {
    position: absolute;
    left: 0;
    bottom: 30px;
    display: none;
  }

  .mission-or-vision-plywood .mission-vision-right-shape {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
  }

  .mission-or-vision-plywood .mission-vision-wrapper::after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -98px;
  }

  .plywoodbazar-features-sec
    .plywoodbazar-features-content
    .key-features-list-box
    .key-features-list
    li {
    list-style-type: decimal;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  }

  .RoCare-banner-sec .RoCare-banner-content .main-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .client-requirements-sec .client-requirements-main-head {
    padding: 100px 50px 50px 50px;
  }

  .Rocare-aboutus .Rocare-aboutus-main-head {
    text-align: center;
    margin-top: 4%;
  }

  // Nikkepore-casestudy-1366px
  .NikkyPore-banner-sec {
    min-height: 570px;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-content {
    margin-top: 5%;
  }

  .NikkyPore-banner-sec .NikkyPore-banner-img {
    margin-top: 9%;
  }

  .Nikkypore-goals-objectives .goals-obj-content-box .goals-obj-content-card {
    padding: 10px;
    min-height: 125px;
    margin-bottom: 20px;
  }

  .Nikkypore-goals-objectives .goals-obj-content-box {
    margin-top: 25%;
  }

  .mt-3 {
    margin-top: 0px !important;
  }

  .challenges-and-solutions-sec
    .challenges-and-solutions-main-head
    .main-heading {
    font-size: 26px;
  }

  .challenges-and-solutions-sec .challenges-sec h4 {
    font-size: 20px;
  }

  .challenges-and-solutions-sec .challenges-solutions-img {
    margin-top: 10%;
  }

  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide img {
    width: 80%;
  }

  .visual-designs-sec .visual-designs-wrapper {
    background-color: #4192a7;
    padding: 0px;
    position: relative;
  }

  .visual-designs-sec .visual-designs-wrapper .visual-designs-slide {
    text-align: center;
    margin: 0 auto;
  }

  .visual-designs-sec .visual-designs-wrapper::before {
    display: none;
  }

  // deyor-casestudy-1366px
  .deyor-about-us {
    background-color: #f5f5f5;
    padding: 35px 0px 160px 0px;
  }

  .deyor-about-us
    .deyor-about-us-wrapper
    .deyor-about-us-image-box
    .aboutus-logo {
    position: absolute;
    width: 18%;
    right: -14%;
    top: 0;
  }

  .deyor-features-sec .feature-user3 .third-child-bg-img {
    position: absolute;
    left: 5%;
    top: -23%;
  }

  // samagri-1366px-responsive
  .samagri-banner-sec .samagri-banner-shape img {
    position: absolute;
    width: 36%;
    z-index: 1;
    left: 35px;
  }

  .samagri-banner-sec .samagri-banner-content .main-heading {
    font-size: 26px;
    line-height: 35px;
  }

  .samagri-banner-sec .samagri-banner-content .desc {
    font-size: 18px;
    color: #413d2c;
    font-weight: 600;
    line-height: 30px;
  }

  .samagri-banner-sec .samagri-banner-content .desc1 {
    font-size: 16px;
    color: #413d2c;
    font-weight: 400;
    line-height: 25px;
  }

  .samagri-banner-sec .samagri-banner-content {
    margin-top: 10%;
    padding-bottom: 10%;
  }

  .samagri-aboutus-sec .samagri-aboutus-wrap-box {
    background-color: white;
    padding: 10px;
    justify-content: space-between;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    span
    svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li {
    display: flex;
    align-items: start;
    line-height: 35px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-client-content-list
    li
    .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    .main-heading {
    font-size: 14px;
    color: #000;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    .sub-heading {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li
    span
    svg {
    color: #c48041;
    font-size: 24px;
    margin-right: 10px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box
    .aboutus-our-content
    .aboutus-our-content-list
    li {
    line-height: 35px;
  }

  .samagri-aboutus-sec .samagri-aboutus-wrap-box1 .samagri-aboutus-content-box {
    margin-top: 25%;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-content-box
    .main-heading {
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 15px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-content-box
    .desc {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .samagri-aboutus-sec
    .samagri-aboutus-wrap-box1
    .samagri-aboutus-img-box
    .samagri-logo-img
    img {
    width: 60%;
  }

  .samagri-project-highlights .samagri-project-highlights-img .circle img {
    position: absolute;
    left: 40%;
    top: 200px;
    width: 60%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-img
    .circle
    .circle-content {
    position: absolute;
    top: 46%;
    left: 60%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-img
    .circle
    .circle-content
    .main-heading {
    font-size: 28px;
    font-weight: 600;
  }

  .samagri-project-highlights
    .samagri-project-highlights-img
    .circle
    .circle-content
    .main-heading
    span {
    display: block;
    font-size: 32px;
    color: #c48041;
  }

  .samagri-project-highlights {
    background-color: #b99471;
    min-height: 800px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(1) {
    position: absolute;
    left: -10%;
    top: -5%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(2) {
    position: absolute;
    top: 30%;
    left: 5%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(3) {
    position: absolute;
    top: 62%;
    left: 5%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list:nth-child(4) {
    position: absolute;
    top: 96%;
    left: -8%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-icon {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: white;
    filter: drop-shadow(-24px 29px 25px rgba(12, 12, 13, 0.32));
    text-align: center;
    line-height: 75px;
    margin-bottom: 10px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-icon
    img {
    width: 45%;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-area {
    padding-left: 0px;
  }

  .samagri-project-highlights
    .samagri-project-highlights-content
    .project-highlights-content-list
    .project-highlights-content-area
    .desc {
    font-size: 14px;
    line-height: 25px;
    color: #fff;
  }

  .samagri-work-screens {
    position: relative;
    width: 100%;
    min-height: 350px;
  }

  .samagri-work-screens .samagri-work-screen-content .main-heading {
    font-size: 22px;
    font-weight: 600;
  }

  .samagri-work-screens .samagri-work-screen-content {
    margin-top: 0%;
  }

  .samagri-work-screens .samagri-work-screen-content .desc {
    line-height: 20px;
    font-size: 13px;
    width: 70%;
  }

  .samagri-features-sec .samagri-features-main-head {
    margin-top: 5%;
  }

  .samagri-features-sec .samagri-features-main-head .main-heading {
    color: black;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0;
  }

  .samagri-features-sec .samagri-features-card {
    padding: 15px 10px;
    margin-bottom: 15px;
    min-height: 135px;
  }

  .samagri-features-sec .samagri-features-card .card-heading {
    font-size: 14px;
    margin-bottom: 0px !important;
  }

  .samagri-features-sec .samagri-features-img {
    margin-top: 15%;
  }

  .samagri-challenges-sec .samagri-challenges-main-head .main-heading {
    font-size: 22px;
    color: white;
  }

  .samagri-challenges-sec .samagri-challenges-content {
    margin-top: 0%;
  }

  .samagri-challenges-sec
    .samagri-challenges-content
    .samagri-challenges-card
    .samagri-challenges-card-heading
    .main-heading {
    text-align: center;
    font-size: 14px;
    color: white;
    padding: 10px;
  }

  .samagri-challenges-sec .samagri-challenges-content .samagri-challenges-card {
    padding: 5px;
  }

  .samagri-challenges-sec
    .samagri-challenges-content
    .samagri-challenges-card
    .desc {
    padding: 5px;
    line-height: 20px;
    font-size: 14px;
  }

  .samagri-challenges-sec .samagri-challenges-img {
    margin-bottom: 0%;
    margin-top: 15%;
  }

  .samagri-contact-banner
    .position-relative-z-index-1
    .samagri-contact-banner-content
    .main-heading {
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .samagri-contact-banner
    .position-relative-z-index-1
    .samagri-contact-banner-content
    .desc {
    color: white;
    font-size: 14px;
    line-height: 20px;
  }

  .samagri-contact-banner {
    padding: 50px 0px;
  }

  // turning-point-1366px

  .turning-point-banner-sec .turning-point-banner-content .main-heading {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 15px;
  }

  .turning-point-banner-sec .turning-point-banner-content {
    margin-top: 10%;
  }

  .turning-point-banner-sec .turning-point-banner-img img {
    margin-top: 5%;
  }

  .turning-point-banner-sec .turning-point-banner-content .transform-button {
    padding: 10px 24px;
    font-weight: 500;
  }

  .turning-point-about-client-sec .turning-point-about-client-content-box {
    padding-left: 20px;
    padding-top: 8%;
    position: relative;
  }

  .turning-point-about-client-sec
    .turning-point-about-client-main-head
    .main-heading1 {
    font-size: 26px;
    font-weight: 600;
    position: relative;
    margin-bottom: 10px;
  }

  .turning-point-about-client-sec .turning-point-about-client-img {
    margin-top: 2%;
  }

  .turning-point-project-highlights-sec
    .turning-point-project-highlights-head
    .main-heading {
    font-size: 26px;
    padding-bottom: 10px;
  }

  .turning-point-project-highlights-sec
    .project-highlights-content-area
    .project-highlights-card
    .turning-point-project-highlights-card-content
    .content-txt
    h5 {
    font-size: 17px;
  }

  .turning-point-project-highlights-sec
    .project-highlights-content-area
    .project-highlights-card-style1
    .turning-point-project-highlights-card-content
    .content-txt
    h5 {
    font-size: 17px;
  }

  .turning-point-features-sec .customer-app-features-content {
    margin-top: 0%;
  }

  .turning-point-features-sec .features-sec-main-head .main-heading {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .turning-point-features-sec .customer-app-features-content .white-box {
    min-height: 177px;
  }

  .turning-point-features-sec
    .position-relative-z-index-1
    .customer-app-features-img {
    text-align: center;
    margin-top: 25%;
  }

  .turning-point-challenges-and-solutions-sec .challenges-solutions-img {
    text-align: center;
    margin-top: 5%;
  }

  .turning-point-challenges-and-solutions-sec
    .challenges-and-solutions-main-head
    .main-heading {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .turning-point-challenges-and-solutions-sec .challenges-sec {
    margin-top: 5%;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-main-head
    .main-heading {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-wrapper::before {
    display: none;
  }

  .turning-point-visual-designs-sec
    .turning-point-visual-designs-wrapper
    .turning-point-visual-designs-slide
    img {
    width: 65%;
  }

  .turning-point-conclusion-sec
    .turning-point-conclusion-sec-head
    .main-heading {
    font-size: 22px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .turning-point-visual-designs-sec .turning-point-visual-designs-wrapper {
    background-color: #f0686c;
    padding: 20px 0px 20px;
    position: relative;
  }
}

