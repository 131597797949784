.why_choose
{
    padding: 60px 0px;

    .main_heading
{
    font-size: 32px;
    font-weight: 700;
    color: #212529;
    text-align: center;
    margin-bottom: 30px;
}

.pt_40
{
    padding-top: 40px;
}

.why_card
{

    margin-bottom: 30px;

    .icon
    {
        
        width: 65px;
        height: 65px;
    }

    .content
    {

        h5
        {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 20px;
        }

        p
        {
            font-size: 16px;
        }
    }
}
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .why_choose
    {
        padding: 20px 0px;

        .main_heading
        {
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: 600;
        }

        .pt_40
        {
            padding-top: 20px;
        }

       .why_card
    {
        margin-bottom: 20px;
        .content
        {

            h5
            {
                font-size: 15px;
    margin-bottom: 10px;
            }

            p
            {
                font-size: 13px;
            }
        }
    }
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .why_choose
    {
        padding: 30px 0px;

        .main_heading
        {
            font-size: 24px;
            margin-bottom: 15px;
            font-weight: 600;
        }

        .pt_40
        {
            padding-top: 20px;
        }

       .why_card
    {
        margin-bottom: 20px;
        .content
        {

            h5
            {
                font-size: 16px;
    margin-bottom: 10px;
            }

            p
            {
                font-size: 14px;
            }
        }
    }
    }
}

@media only screen and (min-width:992px) and (max-width:1399px) {
    .why_choose
    {
        padding: 40px 0px;

        .main_heading {
            font-size: 30px;
            margin-bottom: 20px;
        }

       
    }
}