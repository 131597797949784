.header_box
{

    border-bottom: 1px solid #f1f1f1;
    background-color: #fff;
   


    .main_header1 {
        width: 100%;
        padding: 15px 0px;
    
        .main_logo
        {
            width: 123px;
            height: 45px;
    
            img {
               width: 100%;
               height: 100%;
              }
        }
    
        .header_contact_area {
            display: flex;
            justify-content:flex-end;
            align-items: center;
    
            .quote_buttn {
                animation: pulse-animation 2s infinite;
                background-color: #fcaf17;
                border-radius: 30px;
                box-shadow: 0 0 0 #fcaf17;
                color: #fff !important;
                font-size: 14px;
                font-weight: 600;
                padding: 8px 24px;
                transition: all 0.5s;
                cursor: pointer;
              }
              
              @keyframes pulse-animation {
                0% {
                  box-shadow: 0 0 0 0 #fcaf17;
                }
                100% {
                  box-shadow: 0 0 0 20px transparent;
                }
              }
          }
        
        
          
         
      }
}


@media only screen and (min-width:320px) and (max-width:767px) {
    .header_box
    {
        .main_header1
        {
            padding: 10px 0px;
            .main_logo
        {
            width: 100px;
            height: 35px;
        }

        .header_contact_area
        {
            .quote_buttn
            {
    border-radius: 20px;
    font-size: 13px;
    padding: 6px 15px;

            }
        }
        }
    }
}