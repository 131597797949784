.industries_sec
{
    padding: 60px 0px;
    background-color: #171717;


    .main_heading
    {
        font-size: 32px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
    }

    .desc
    {
        color: #fff;
        text-align: center;
    }
    
    .pt_50
    {
        padding-top: 50px;
    }

    .move_up
    {
        margin-top: 40px;
    }
    .card
    {

margin-bottom: 25px;
position: relative;

        .image
        {
           
            width: 100%;
            height: 390px;
            border: 2px solid #FFFFFF6B;
            border-radius: 12px;
            transition: all 0.4s ease;
            cursor: pointer;


           

            img
            {
                border-radius: 12px;
                transition: all 0.4s ease;
                transform: scale(1);
                width: 100%;
                height: 100%;
                
            }

            &:hover
            {
                border-color: transparent;

               
                img
                {
                    transition: all 0.4s ease;
            transform: scale(1.02);
                }
            }
        }

        
        .content
        {
            
            position: absolute;
            background-color: rgb(10 9 7 / 71%);
            width: 100%;
            height: 100%;
            top: 0;
            transition: all 0.4s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            opacity: 0;
            visibility: hidden;

            h5
            {
                // padding: 15px;
                color: #fff;
                text-align: center;
                font-weight: 600;
                font-size: 22px;
                letter-spacing: 0.5px;
            }
        }
       
        &:hover
        {
            .content
            {
                visibility: visible;
                opacity: 1;
            }
        }

       
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .industries_sec
    {
        padding: 20px 0px;

        .main_heading
        {
            font-size: 18px;
            margin-bottom: 10px;
            font-weight: 500;
        }

        .desc
        {
            font-size: 14px;
        }
        .pt_50
        {
            padding-top: 20px;
        }
        .move_up
        {
            margin-top: 0px;
        }

        .card
        {

            .image
            {
                width: 90%;
                margin: 0 auto;
            }
           .content
           {
            opacity: 1;
            visibility: visible;
            h5
            {
                
                   
                    font-size: 18px;
                margin-bottom: 0px;
            }
           }
        }
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .industries_sec
    {
        padding: 30px 0px;

        .main_heading
        {
            font-size: 22px;
            margin-bottom: 10px;
            font-weight: 500;
        }

        .desc
        {
            font-size: 14px;
        }
        .pt_50
        {
            padding-top: 30px;
        }

        .card
        {

           
            h5
            {
                
                   
                    font-size: 16px;
                margin-bottom: 0px;
            }
        }
    }
}

@media only screen and (min-width:992px) and (max-width:1399px) {
    .industries_sec
    {
        padding: 40px 0px;

        .main_heading
        {
            font-size: 28px;
            margin-bottom: 15px;
            font-weight: 500;
        }

        .desc
        {
            font-size: 14px;
        }
        .pt_50
        {
            padding-top: 35px;
        }

        .card
        {
            h5
            {
                
                    padding: 15px 10px 5px 10px;
                    font-size: 16px;
                margin-bottom: 0px;
            }
        }
    }
}