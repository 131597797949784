.roadblock_sec
{
    background-color:#d5a74a;
    padding: 60px 0px;
    min-height: 400px;

   
}

.card_sec
{
    margin: -280px 0px 25px;

    .j_c_c
    {
        justify-content: center;
    }
    .card
    {
        background-color: #262531;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    min-height: 400px;
    border-radius: 6px;
    padding: 30px;

    h5
    {
        color: #FEBE10;
        font-weight: 600;
        font-size: 24px;
        border-bottom: 6px solid #FEBE10;
        line-height: 35px;
        padding-bottom: 12px;
        width: fit-content;
        margin-bottom: 25px;
    }

  .list
  {
    li
    {
        font-size: 14px;
        color: #fff;
        line-height: 25px;
        margin-bottom: 15px;
    }
  }
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .roadblock_sec
    {
        padding: 20px 0px;
    }

    .card_sec
    {
        margin: -370px 0px 25px;

        .card
        {
            padding: 15px;
            min-height: 360px;
            margin-bottom: 15px;

            h5
            {
                line-height: 35px;
                padding-bottom: 5px;
                margin-bottom: 15px;
            }
        }
    }
}