.uiux_services
{
    padding: 50px 0px;

    .heading_box
    {
        text-align: center;

        .main_heading
    {
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 25px;

        span
        {
            color: #fcaf17;
        }
    }
    
    }

    .pt_30
    {
        padding-top: 30px;
    }
    .card
    {
        background-color: #f5f5f5;
        padding: 35px 25px 20px 45px;
        margin-bottom: 25px;
        min-height: 441px;

        h5
        {
            font-size: 17px;
            margin-bottom: 20px;
        }

        .list

        {
            li
            {
                list-style-type: square;
                color: #494b50;
                line-height: 25px;
                font-size: 15px;
                margin-bottom: 15px;

                &::marker
                {
                    color: #fcaf17;
                    font-size: 18px;
                }
            }
        }
    }
}